import { Dispatch } from 'redux';
import { ADD, INIT, UPDATE } from './actionTypes';
import { composeSlices } from './composeSlices';
import { createDatasetSlice } from './createDatasetSlice';
import { createPersistDataSlice } from './createPersistDataSlice';
import { createApiCallActions } from './createApiCallSlice';
import { createClearStateSlice } from './createClearStateSlice';

type Dispatcher = (dispatch: Dispatch<any>) => (params: any) => void;
type Sagas = { [actionType: string]: any };
type Reducers<T> = {
  [actionType: string]: (state: T, action: any) => T | void;
};

export type CreatePersistApiDataSliceOptions<T> = {
  storeName: string;
  actionName: string;
  initialState?: T;
  api: (data?: any, state?: any) => Promise<unknown>;
  extraReducers?: Reducers<T>;
  extraSagas?: Sagas;
  selectors?: { [key: string]: (state: T) => any };
  dispatchers?: { [key: string]: Dispatcher };
  addPayloadMapFn?: (payload) => any;
};

export function createPersistApiDataSlice<T>(
  options: CreatePersistApiDataSliceOptions<T>
) {
  const {
    storeName,
    actionName,
    api,
    initialState,
    addPayloadMapFn,
    extraReducers,
  } = options;

  const initSlice = createDatasetSlice<T>({
    actionName: `${actionName}_${INIT}`,
    datasetHashKey: actionName,
    api,
    storeName,
  });

  const updateSlice = createDatasetSlice<T>({
    actionName: `${actionName}_${UPDATE}`,
    datasetHashKey: actionName,
    api,
    storeName,
  });
  const clearValueSlice = createClearStateSlice<T>({
    datasetHashKey: actionName,
    storeName,
    initialState,
  });

  const { successAction } = createApiCallActions(updateSlice.name);
  const persistStateSlice = createPersistDataSlice<T>({
    actionName: `${actionName}_${ADD}`,
    datasetHashKey: actionName,
    payloadMapFn: addPayloadMapFn,
    extraActions: [successAction],
    storeName,
  });

  return composeSlices(
    {
      add: persistStateSlice,
      update: updateSlice,
      init: initSlice,
      clear: clearValueSlice,
    },
    {
      initialState,
      extraReducers,
      middleware: persistStateSlice.middleware,
      selectors: options.selectors || {},
      dispatchers: options.dispatchers || {},
    }
  );
}
