import { Components } from '@mui/material/styles';
import MuiCssBaseline from './global';
import MuiAlert from './alert';
import MuiAvatar from './avatar';
import MuiButton from './button';
import MuiCheckbox from './checkbox';
import MuiCard from './card';
import MuiContainer from './container';
import MuiDialog from './dialog';
import MuiForm from './form';
import MuiIconButton from './iconButton';
import MuiList from './list';
import MuiLinearProgress from './progressbar';
import MuiSnackbar from './snackbar';
import MuiSvgIcon from './icon';
import MuiTabs from './tabs';
import MuiTable from './table';
import MuiTooltip from './tooltip';
import MuiInput from './input';
import MuiLink from './link';
import MuiPaper from './paper';
import MuiBreadcrumbs from './breadCrumbs';

export const components = {
  MuiCssBaseline,
  MuiAvatar,
  MuiButton,
  MuiCheckbox,
  MuiContainer,
  MuiIconButton,
  MuiLinearProgress,
  MuiPaper,
  MuiBreadcrumbs,
  MuiSnackbar,
  MuiSvgIcon,
  MuiLink,
  MuiTooltip,
  ...MuiAlert,
  ...MuiCard,
  ...MuiDialog,
  ...MuiForm,
  ...MuiInput,
  ...MuiList,
  ...MuiTable,
  ...MuiTabs,
} as Partial<Components>;
