interface Item {
  id: string;
  name: string;
  sector: string;
  description: string;
  createdBy: string;
  modifiedBy: string;
  status: string;
  solutionIds?: string[];
  cluster?: Cluster;
  clusters?: Cluster[];
  observability?: string;
}

interface Cluster {
  id: string;
  name: string;
  status: string;
}

export const getParenLabelAndChildren = (
  items,
  solutionSites
): {
  parentId: string;
  parentLabel: string;
  children: { label: string; value: string }[];
}[] => {
  const data = items?.map((item: Item) => {
    if (!item.clusters) {
      return null;
    }
    const parentLabel = item.name;
    const parentId = item.id;
    let children: { label: string; value: string }[] = [];
    if (item?.clusters) {
      const existingSiteClusters = solutionSites.find(
        (site) => site.id === item.id
      )?.clusters;
      let existingSiteClustersId: string[] = [];
      existingSiteClustersId = existingSiteClusters?.map(
        (cluster) => cluster.id
      );
      children = item.clusters
        .filter(
          (cluster: Cluster) => !existingSiteClustersId?.includes(cluster.id)
        )
        .map((cluster: Cluster) => ({
          label: cluster.name,
          value: cluster.id,
        }));
    }
    return { parentId, parentLabel, children };
  });
  return data.filter((item) => item !== null && item.children.length > 0);
};

export const getParenLabelChildren = (
  items
): {
  parentId: string;
  parentLabel: string;
  children: { label: string; value: string }[];
}[] => {
  const data = items?.map((item: Item) => {
    if (!item.clusters) {
      return null;
    }
    const parentLabel = item.name;
    const parentId = item.id;
    let children: { label: string; value: string }[] = [];
    if (item?.clusters) {
      children = item.clusters.map((cluster: Cluster) => ({
        label: cluster.name,
        value: cluster.id,
      }));
    }
    return { parentId, parentLabel, children };
  });
  return data.filter((item) => item !== null && item.children.length > 0);
};
