import React from 'react';
import { TIMEZONES_MAP } from '../constants';

type Props = {
  value?: string;
};

function OtpListTimezone({ value }: Props) {
  return <>{TIMEZONES_MAP[value as string]}</>;
}

export default OtpListTimezone;
