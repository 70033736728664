import { createPersistApiDataSlice } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import { generateApiPath, loadDataFromStorage } from 'src/utils';
import {
  API_DEVICES,
  API_DEVICES_IOTH,
  SOLUTIONS_STORE,
  SOLUTIONS_STORE_DEVICES_PERSIST,
} from '../constants';
import { SolutionDeviceItem, SolutionType } from '../types';
import { mapSolutionDevicesPersistData, mapSolutionDevicesData } from './utils';

const api = ({ solutionId, devices, filterString = '' }, { solutions }) => {
  const currDevices = solutions.devicesPersist.data;
  const reqDevices = devices || currDevices;
  const solutionType = loadDataFromStorage('solutionType');
  const urlPath =
    solutionType.toUpperCase() === SolutionType.ioTH.toUpperCase()
      ? API_DEVICES_IOTH
      : API_DEVICES;

  const requests = reqDevices.map(({ id }) =>
    pepApiRequest({
      method: RequestMethod.Get,
      url: generateApiPath(urlPath, { solutionId }),
      queryParams: {
        filter:
          solutionType?.toUpperCase() === SolutionType.ioTH.toUpperCase()
            ? filterString
            : `id eq '${id}'`,
      },
    })
  );
  if (filterString)
    return Promise.all(requests).then((response) => mapSolutionDevicesData);
  return Promise.all(requests).then((response) =>
    mapSolutionDevicesPersistData(response, currDevices)
  );
};

const solutionDevicesPersistSlice = createPersistApiDataSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTIONS_STORE_DEVICES_PERSIST,
  addPayloadMapFn: (item: SolutionDeviceItem): SolutionDeviceItem => ({
    ...item,
    lastAccessed: new Date().toString(),
  }),
});

export default solutionDevicesPersistSlice;
