import { RouteProps } from 'src/router';
import ApplicationList from './ApplicationList';
import * as ROUTES from './constants';
import UserDetails from './UserDetails';

export const routes: RouteProps[] = [
  {
    index: true,
    component: UserDetails,
    meta: {
      title: 'User List',
    },
  },
  {
    path: ROUTES.APPLICATION_LIST,
    component: ApplicationList,
    meta: {
      title: 'Application List',
    },
  },
];
