import { useEffect, useState } from 'react';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { generateApiPath } from 'src/utils';
import { useStoreUnitActions } from 'src/redux/helpers';
import { useParams } from 'react-router-dom';
import { API_WORKLOAD_CONFIG_UPDATE } from '../../constants';
import { SolutionEdgeWorkloadItem } from '../../types';
import SolutionEdgeWorkloadEditModal from './SolutionEdgeWorkloadEditModal';
import solutionsStore, {
  useCurrentSolutionName,
  useSolutionSitesList,
} from '../../redux';

import { getParenLabelChildren } from '../../SolutionSites/components/SolutionSiteOptions';
import { getSolutionWorkloadEditParams } from '../utils';

type Props = {
  item: SolutionEdgeWorkloadItem;
  open: boolean;
  onClose: ModalCloseHandler;
};

function SolutionEdgeWorkloadEditContainer({ item, open, onClose }: Props) {
  const { solutionId } = useParams();
  const solutionName = useCurrentSolutionName();
  const [data, sitesLoading] = useSolutionSitesList();
  const [optionsData, setOptionsData] = useState<
    {
      parentId: string;
      parentLabel: string;
      children: { label: string; value: string }[];
    }[]
  >();

  useEffect(() => {
    const response = getParenLabelChildren(data);
    setOptionsData(response);
  }, [data]);

  const gitTargetNamespace = `${solutionName}-ns`;
  const { name: repoName, gitFlag, deploymentPaused, deploymentStatus } = item;
  const { showAlert } = useAlert();
  const actions = useStoreUnitActions(solutionsStore);

  useEffect(() => {
    actions.sites(solutionId);
  }, []);

  const [handleSubmit, loading] = useStatefulApi<SolutionEdgeWorkloadItem>(
    (params) =>
      pepApiRequest({
        method: RequestMethod.Put,
        url: generateApiPath(API_WORKLOAD_CONFIG_UPDATE, {
          solutionId,
          repoName,
        }),
        params: {
          WorkloadConfiguration: {
            ...getSolutionWorkloadEditParams(
              params,
              gitTargetNamespace,
              optionsData
            ),
            gitFlag,
            deploymentPaused,
            deploymentStatus,
          },
        },
      }),
    () => {
      showAlert({
        type: AlertType.Info,
        title: `${repoName} is saved`,
        text: 'Git Repo has been modified successfully',
      });
      onClose(null, ModalCloseReason.completeAction);
      actions.edgeWorkloadConfigurations(solutionId);
    }
  );

  return (
    <SolutionEdgeWorkloadEditModal
      item={item}
      title={'Edit Git Repository'}
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      confirmBtnText={'Save'}
      loading={loading}
    />
  );
}

export default SolutionEdgeWorkloadEditContainer;
