import React from 'react';
import Box from '@mui/material/Box';
import { SxProps } from 'src/theme/types';

type Props = {
  children: React.ReactNode;
};

const style: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
  position: 'fixed',
  maxWidth: ({ spacing }) => spacing(60),
  bottom: ({ spacing }) => spacing(2.5),
  right: ({ spacing }) => spacing(2.5),
  zIndex: ({ zIndex }) => zIndex.snackbar,
};

function AlertsContainer({ children }: Props) {
  return <Box sx={style}>{children}</Box>;
}

export default AlertsContainer;
