import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import Select, { getSelectOptions } from 'src/components/Select';
import { nanToUndefined } from 'src/utils';
import { SolutionFilesWhitelistField } from '../types';
import { ALLOWED_EXTENSION_OPTIONS } from './constants';

export const whitelistFileFormFields = [
  {
    name: SolutionFilesWhitelistField.Extension,
    label: 'File extension',
    placeholder: 'Select file extension',
    Component: Select,
    options: getSelectOptions(ALLOWED_EXTENSION_OPTIONS),
    validation: Yup.string().required('File extension is required'),
    required: true,
  },
  {
    name: SolutionFilesWhitelistField.MaxFileSize,
    label: 'Maximum file size (kb)',
    placeholder: 'Enter max file size',
    Component: TextField,
    validation: Yup.number()
      .transform(nanToUndefined)
      .required('Max file size value is required'),
    type: 'number',
    required: true,
  },
];
