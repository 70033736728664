import { createDatasetSlice } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import { generateApiPath, loadDataFromStorage } from 'src/utils';
import {
  API_DEVICE_GROUP_ITEM,
  API_DEVICE_GROUP_ITEM_IOTH,
  SOLUTIONS_STORE,
  SOLUTIONS_STORE_DEVICE_GROUP_DETAILS,
} from '../constants';
import { mapSolutionDeviceGroupDetails } from './utils';
import { SolutionType } from '../types';

const api = ({ solutionId, companyId, groupId }) => {
  const solutionType = loadDataFromStorage('solutionType');
  return pepApiRequest({
    method: RequestMethod.Get,
    url: generateApiPath(
      solutionType.toUpperCase() === SolutionType.ioTH.toUpperCase()
        ? API_DEVICE_GROUP_ITEM_IOTH
        : API_DEVICE_GROUP_ITEM,
      {
        solutionId,
        companyId,
        groupId,
      }
    ),
  }).then(mapSolutionDeviceGroupDetails);
};

const solutionDeviceGroupsSlice = createDatasetSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTIONS_STORE_DEVICE_GROUP_DETAILS,
});

export default solutionDeviceGroupsSlice;
