import {
  SolutionDigitalTwinModel,
  SolutionIoTCentralInstance,
} from './solutionDeviceConfig';
import { SolutionSourceImageRepositoryItem } from './solutionEdgeWorkload';
import { SolutionSettings } from './solutionSettings';

export enum SolutionFeature {
  Mender = 'mender',
  SourceStorage = 'sourceStorage',
  DataIngestion = 'dataIngestion',
}

export enum SubscriptionType {
  Messages = 'messages',
  Files = 'files',
}

export enum SolutionLocation {
  centralus = 'centralus',
  eastus = 'eastus',
  eastus2 = 'eastus2',
  southcentralus = 'southcentralus',
  westus = 'westus',
}

export enum SolutionItemStatus {
  Error = 'error',
  NotStarted = 'notStarted',
  Pending = 'pending',
  WaitingForManualSetup = 'waitingForManualSetup',
  Succeeded = 'succeeded',
  Suspended = 'suspended',
  Deleting = 'deleting',
  Deleted = 'deleted',
}

export enum DeviceType {
  IoT = 'IoT',
  IoTEdge = 'IoTEdge',
}

export enum SolutionFileSubscriptionType {
  EDAPStorageAccount = 'edapStorageAccount',
  PEPsenseStorageAccount = 'pePsenseStorageAccount',
}

export enum SolutionTelemetryProcessingType {
  Kafka = 'kafka',
  Eventhub = 'eventHub',
  Servicebus = 'serviceBus',
}

export type SolutionAdxInstance = {
  databaseName: string;
};

export type SolutionItem = {
  id: string;
  solutionDisplayName: string;
  ioTCentralName?: string;
  location?: SolutionLocation;
  costCenter: string;
  isMenderEnabled?: boolean;
  releasePrefix: string;
  isSmsAccountCreation?: boolean;
  isDataIngested?: boolean;
  isFileSubscriptionService?: boolean;
  solutionOAuthClientId: string[];
  status: SolutionItemStatus;
  adxInstance?: any;
  adGroup: string;
  solutionID?: string;
  project: string;
  namespace: string;
  businessOwner?: string;
  retentionDays?: string;
  lastStatusChange?: string;
  deviceType?: DeviceType;
  solutionType?: SolutionType;
  notificationPreference?: any[];
  kibanaSpaceType?: KibanaSpaceType;
  kibanaSpaceUrl?: string;
  CachePeriod?: string;
  UnlimitedCache?: boolean;
  RetentionPeriod?: string;
  UnlimitedRetention?: boolean;
  UnlimitedCacheArr?: string[];
  UnlimitedRetentionArr?: string[];
  solutionConsumerFeatures?: SolutionConsumerFeatures;
  solutionIndustrialFeatures?: SolutionIndustrialFeatures;
  subscriptionType?: SubscriptionType;
  fileSubscription?: SolutionFileSubscriptionType;
  telemetryStreamProcessing?: SolutionTelemetryProcessingType;
};

export enum SolutionItemField {
  displayName = 'solutionDisplayName',
  IoTCentralName = 'ioTCentralName',
  IoTHubName = 'ioTHubName',
  Location = 'location',
  CostCenter = 'costCenter',
  ClientId = 'solutionOAuthClientId',
  Status = 'status',
  ADGroup = 'adGroup',
  Project = 'project',
  Namespace = 'namespace',
  Action = 'action',
  BusinessOwner = 'businessOwner',
  kibanaSpace = 'kibanaSpace',
  KibanaSpaceType = 'kibanaSpaceType',
  deviceType = 'deviceType',
  IsMenderEnabled = 'isMenderEnabled',
  CreateSmsAccount = 'isSmsAccountCreation',
  SolutionType = 'solutionType',
  CachePeriod = 'CachePeriod',
  UnlimitedCache = 'UnlimitedCacheArr',
  RetentionPeriod = 'RetentionPeriod',
  UnlimitedRetention = 'UnlimitedRetentionArr',
  NewConsumer = 'NewConsumer',
  ReleasePrefix = 'ReleasePrefix',
  SolutionConsumerFeatures = 'solutionConsumerFeatures',
  SolutionIndustrialFeatures = 'solutionIndustrialFeatures',
  SubscriptionType = 'subscriptionType',
  FileSubscriptionType = 'fileSubscription',
  TelemetryStreamProcessingType = 'telemetryStreamProcessing',
}

export enum SolutionType {
  Consumer = 'consumer',
  Industrial = 'industrial',
  NewConsumer = 'newConsumer',
  ioTH = 'ioTH',
}

export enum KibanaSpaceType {
  Global = 'Global',
  Confidential = 'Confidential',
}

export type KibanaMonitoringSpace = {
  type: KibanaSpaceType;
  name: string;
  url: string;
};

export type SiteClusters = {
  siteId: string;
  clusterIds: string;
  edgeServiceUrl: string;
};

export type SolutionDetailsItem = SolutionItem & {
  contextDigitalTwinsModels: SolutionDigitalTwinModel[];
  deviceDigitalTwinsModels: SolutionDigitalTwinModel[];
  ioTCentralInstance: SolutionIoTCentralInstance;
  features: SolutionSettings;
  industrialWorkloadInformation: SolutionSourceImageRepositoryItem;
  kibanaSpace: KibanaMonitoringSpace;
  siteClusters: SiteClusters[];
};

export enum SolutionConsumerFeatures {
  IsMenderEnabled = 'isMenderEnabled',
  IsDataIngested = 'isDataIngested',
  IsSmsAccountCreation = 'isSmsAccountCreation',
  IsFileSubscriptionService = 'isFileSubscriptionService',
  ReleasePrefix = 'releasePrefix',
}

export enum SolutionIndustrialFeatures {
  IsDataIngested = 'isDataIngested',
  IsSmsAccountCreation = 'isSmsAccountCreation',
  IsSubscriptionService = 'isSubscriptionService',
  IsTelemetryStreamProcessing = 'isTelemetryStreamProcessing',
  IsAlarmEventProcessing = 'isAlarmEventProcessing',
}
