import { generatePath } from 'react-router-dom';
import { createDatasetSlice, ResponseMapping } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import {
  API_PEPSENCE_IMAGE_REPOSITORY,
  SOLUTIONS_STORE,
  SOLUTION_STORE_PEPSENCE_IMAGE_REPO_DETAILS,
} from '../constants';

const api = (solutionId: string) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: generatePath(API_PEPSENCE_IMAGE_REPOSITORY, { solutionId }),
  });

const solutionFileSubscriptionSlice = createDatasetSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTION_STORE_PEPSENCE_IMAGE_REPO_DETAILS,
  responseMapping: ResponseMapping.Default,
});

export default solutionFileSubscriptionSlice;
