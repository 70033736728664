import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';

type Props = ButtonProps & Record<string, any>;

function DataTableWrapperActionButton(props: Props) {
  return (
    <Button
      data-testid="data-table-wrapper-action"
      color="primary"
      size="small"
      variant="outlined"
      {...props}
    />
  );
}

export default DataTableWrapperActionButton;
