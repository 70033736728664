import { useEffect, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import Link from '@mui/material/Link';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { useUserRoles } from 'src/auth';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { generateApiPath } from 'src/utils';
import { useStoreUnitActions } from 'src/redux/helpers';
import {
  API_WORKLOAD_CONFIG_CREATE,
  Sites,
  SOLUTION_DETAILS,
} from '../../constants';
import SolutionEdgeWorkloadEditModal from './SolutionEdgeWorkloadEditModal';
import solutionsStore, {
  useCurrentSolutionName,
  useSolutionSitesList,
} from '../../redux';
import { SolutionEdgeWorkloadRepositoryType } from '../../types';

import { getParenLabelChildren } from '../../SolutionSites/components/SolutionSiteOptions';
import { getSolutionWorkloadEditParams } from '../utils';

type Props = {
  open: boolean;
  onClose: ModalCloseHandler;
  repositoryType: SolutionEdgeWorkloadRepositoryType;
};

function SolutionEdgeWorkloadCreateContainer({
  open,
  onClose,
  repositoryType,
}: Props) {
  const navigate = useNavigate();
  const { solutionId, solutionType } = useParams();
  const solutionName = useCurrentSolutionName();
  const [data, sitesLoading] = useSolutionSitesList();
  const [optionsData, setOptionsData] = useState<
    {
      parentId: string;
      parentLabel: string;
      children: { label: string; value: string }[];
    }[]
  >();

  useEffect(() => {
    const response = getParenLabelChildren(data);
    setOptionsData(response);
  }, [data]);

  const gitTargetNamespace = `${solutionName}-ns`;
  const { showAlert } = useAlert();
  const { isPlatformOwner } = useUserRoles();
  const actions = useStoreUnitActions(solutionsStore);
  const goto = () => {
    navigate(
      generatePath(`/solutions/${SOLUTION_DETAILS}/${Sites}`, {
        solutionId,
        solutionType,
      }),
      { replace: true }
    );
  };

  const [handleSubmit, loading] = useStatefulApi(
    (params) =>
      pepApiRequest({
        method: RequestMethod.Post,
        url: generateApiPath(API_WORKLOAD_CONFIG_CREATE, { solutionId }),
        params: {
          WorkloadConfiguration: {
            ...getSolutionWorkloadEditParams(
              params,
              gitTargetNamespace,
              optionsData
            ),
            gitFlag: repositoryType,
            deploymentPaused: true,
          },
        },
        skipErrorCodes: [412],
      }),
    (res, params) => {
      showAlert({
        type: AlertType.Success,
        title: `${params.name} is configured`,
        text: 'Git Repo has been configured successfully',
      });
      onClose(null, ModalCloseReason.completeAction);
      actions.edgeWorkloadConfigurations(solutionId);
    },
    (err) => {
      if (typeof err === 'object') {
        if (err?.status === 412) {
          showAlert({
            type: AlertType.Error,
            title: 'Git configuration failed',
            text: (
              <>
                The container resouce limit is not set for one or more sites
                selected.{' '}
                {isPlatformOwner && (
                  <>
                    <Link onClick={goto}>Click here</Link> to set.
                  </>
                )}
              </>
            ),
          });
        }
      }
      onClose(null, ModalCloseReason.completeAction);
    }
  );

  return (
    <SolutionEdgeWorkloadEditModal
      title="Configure Git Repository"
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      loading={loading}
      repositoryType={repositoryType}
    />
  );
}

export default SolutionEdgeWorkloadCreateContainer;
