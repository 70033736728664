import Badge from 'src/components/Badge';
import { SiteItemStatus as Status } from '../types';

type StatusMapItem = {
  color?: 'success' | 'info' | 'error' | 'warning';
  label: string;
};

type Props = {
  value?: boolean | string;
};
function SiteItemStatus({ value = true }: Props) {
  const statusMap: Record<Status, StatusMapItem> = {
    [Status.Active]: { color: 'success', label: 'Active' },
    [Status.InProgress]: { color: 'info', label: 'InProgress' },
    [Status.Deleted]: { label: 'Deleted' },
  };

  return (
    <>
      <Badge {...statusMap[value.toString()]} />
    </>
  );
}

export default SiteItemStatus;
