import { Querys } from '../ruleConfiguration/interfaces';

export enum SolutionDeviceItemField {
  DeviceId = 'id',
  DisplayName = 'displayName',
  CompanyName = 'companyName',
  ModelId = 'modelId',
  ModelDisplayName = 'modelDisplayName',
  Enabled = 'enabled',
  Provisioned = 'provisioned',
  Simulated = 'simulated',
  HostOsVersion = 'hostOSVersion',
  MenderStatus = 'menderStatus',
  DeploymentName = 'deploymentName',
  LastDeploymentAbortedDate = 'lastDeploymentAbortedDate',
  LastAccessed = 'lastAccessed',
  ConnectionState = 'connectionState',
  IotEdge = 'iotEdge',
  Etag = 'etag',
}

export enum SolutionDeviceComponentsField {
  id = 'id',
  Component = 'component',
  ModelId = 'modelId',
  Components = 'Components',
  ComponentName = 'ComponentName',
  ModuleName = 'ModuleName',
  CommandName = 'CommandName',
  DisplayName = 'DisplayName',
  CommandType = 'CommandType',
  RequestSchema = 'RequestSchema',
  ResponseSchema = 'ResponseSchema',
}

export enum SolutionDeviceModulesField {
  ModuleId = 'moduleId',
  CloudToDeviceMessageCount = 'cloudToDeviceMessageCount',
  ManagedBy = 'managedBy',
  ConnectionState = 'connectionState',
  Etag = 'etag',
  ConnectionStateUpdatedTime = 'connectionStateUpdatedTime',
  LastActivityTime = 'lastActivityTime',
  DeviceId = 'deviceId',
  Modules = 'Modules',
  ModuleType = 'moduleType',
  ModelId = 'modelId',
}
export enum SolutionDeviceRuleManagementField {
  Id = 'id',
  Name = 'Name',
  Enabled = 'Enabled',
  DeviceTemplate = 'TemplateId',
}

export type SolutionDeviceComponents = {
  id: string;
  component: string;
  modelId: string;
  componentName: string;
  moduleName: string;
  commandName: string;
  displayName: string;
  commandType: string;
  requestSchema: string;
  responseAchema: string;
};

export type SolutionDeviceModules = {
  moduleId: string;
  cloudToDeviceMessageCount: string;
  managedBy: string;
  deviceId: string;
  generationId: string;
  etag: string;
  connectionState: string;
  connectionStateUpdatedTime: string;
  lastActivityTime: string;
  moduleType: string;
};
export type SolutionDeviceRulesManagement = {
  Id: string;
  Name: string;
  SuccessEvent: string;
  Expression: string;
  ErrorMessage: string;
  SolutionId: string;
  TemplateId: string;
  Enabled: string;
  RuleExpressionType: string;
  ErrorType: string;
};

export enum SolutionSpecificRuleManagementField {
  TelemetryName = 'Telemetry',
  OperatorName = 'Operator',
  AggregationName = 'Aggregation',
  ConditionValue = 'Value',
  PropertyName = 'Property',
  FilterOperatorName = 'Condition',
  ValueName = 'Value',
}

export type SolutionDeviceSpecificRulesManagement = {
  Id: string;
  Name: string;
  Expression: string;
  ConditionQuery: string;
  TargetDeviceQuery: string;
  SuccessEvent: string;
  ErrorMessage: string;
  SolutionId: string;
  TemplateId: string;
  Enabled: boolean;
  RuleExpressionType: string;
  ErrorType: string;
  TimeAggregation: boolean;
  TimeAggregationValueInMinutes: number;
  TargetUrl: string;
  ActionType: string[];
  ConditionQueryOperator: string;
  SasToken: string;
  ConditionQueryInfo: Querys[];
  TargetDeviceInfo: Querys[];
};

export type SolutionDevicesSpecificRulesManagementList = {
  data: SolutionDeviceSpecificRulesManagement[];
};

export type SolutionDeviceItem = {
  id: string;
  displayName: string;
  deviceType: string;
  companyId?: string;
  companyName?: string;
  connectionState?: string;
  menderId?: string;
  modelId?: string;
  modelDisplayName?: string;
  deviceCertInfo?: any;
  etag: string;
  enabled: boolean;
  provisioned: boolean;
  simulated: boolean;
  hostOSVersion: string;
  menderStatus: string;
  deploymentName: string;
  lastDeploymentAbortedDate: string;
  lastAccessed?: string;
  iotEdge: boolean;
};

export enum SolutionMenderStatus {
  Pending = 'pending',
  Rejected = 'rejected',
  Accepted = 'accepted',
}

export enum SolutionLogLevel {
  Emergency = 0,
  Alert = 1,
  Critical = 2,
  Error = 3,
  Warning = 4,
  Notice = 5,
  Informational = 6,
  Debug = 7,
}

export type SolutionDevices = {
  data?: SolutionDeviceItem[];
  skipToken?: string;
  hasNextPage?: boolean;
  sequence?: number;
};
export type SolutionDevicesModulesList = {
  data: SolutionDeviceModules[];
};
export type SolutionDevicesRulesManagementList = {
  data: SolutionDeviceRulesManagement[];
};
export type SolutionDevicesComponetsList = {
  [x: string]: any;
  data: SolutionDeviceComponents[];
};
export enum SolutionDeviceAssignTemplateField {
  CompatibleOnly = 'compatibleOnly',
  ModelId = 'modelId',
}
export enum SolutionCommandAssignTemplateField {
  CommandInputValue = 'commandInputValue',
}

export type SolutionDeviceCertDetailsItem = {
  id: string;
  deviceID: string;
  solutionID: string;
  certThumbprint: string;
  certExpiryDate: string;
  lastUpdatedAt: string;
  certRenewalState: number;
  requestType: number;
  isRevoked: boolean;
  serialNumber: string;
  commonName: string;
  createdAt: string;
};

export enum SolutionDeviceCertDetailsField {
  CertId = 'id',
  DeviceId = 'deviceID',
  SolutionId = 'solutionID',
  CertThumbprint = 'certThumbprint',
  CertExpiryDate = 'certExpiryDate',
  CreatedAt = 'certIssueDate',
  FinishedAt = 'certFinishedDate',
  LastUpdatedAt = 'lastUpdatedAt',
  CertRenewalState = 'certRenewalState',
  CertificateStatus = 'certificateStatus',
  IsRevoked = 'isRevoked',
  SerialNumber = 'serialNumber',
  CommonName = 'commonName',
  createdAt = 'createdAt',
}

export type SolutionDeviceCertAuditLogsItem = {
  id: string;
  deviceID: string;
  solutionID: string;
  certThumbprint: string;
  certExpiryDate: string;
  createdAt: string;
  createdBy?: string;
  lastUpdatedAt?: string;
  certRenewalState?: number;
  requestType: number;
  remark?: string;
  isRevoked: boolean;
  jobType: number;
  serialNumber: string;
  commonName: string;
};

export enum SolutionDeviceCertAuditLogsField {
  CertId = 'id',
  DeviceId = 'deviceID',
  SolutionId = 'solutionID',
  CertThumbprint = 'certThumbprint',
  CertExpiryDate = 'certExpiryDate',
  CreatedAt = 'createdAt',
  LastUpdatedAt = 'lastUpdatedAt',
  CertRenewalState = 'certRenewalState',
  CertRenewalRemark = 'remark',
  IsRevoked = 'isRevoked',
  SerialNumber = 'serialNumber',
  CommonName = 'commonName',
}

export enum CertRenwalState {
  None = 0,
  RenewalInitiated = 1,
  RequestAcknowledged = 2,
  CertIssued = 3,
  CertRenewed = 4,
  CertRenewalFailed = 5,
}

export enum JobType {
  Provisioned = 0,
  RenewalProcessing = 1,
  Revoked = 2,
}

export enum CertificateType {
  IOTC = 'iotc',
  Mender = 'mender',
}

export enum CertRequestType {
  IOTC = 0,
  Mender = 1,
}

export type SolutionDeviceObservPropsItem = {
  MBFILEPATH: string;
  MBFILENAME: string;
  MBROTATEFILE: number;
  MBNUMOFFILE: number;
  MBPERIOD: number;
  MBENABLED: boolean;
};

export enum SolutionDeviceObservPropsField {
  FilePath = 'MBFILEPATH',
  FileName = 'MBFILENAME',
  RotateFile = 'MBROTATEFILE',
  NumOfFile = 'MBNUMOFFILE',
  Frequency = 'MBPERIOD',
  Enabled = 'MBENABLED',
}

export type SolutionModuleLogsItem = {
  tail: number;
  since: number;
  until: number;
  loglevel: number;
  regex: string;
};

export enum SolutionModuleLogs {
  Tail = 'tail',
  Since = 'since',
  Until = 'until',
  LogLevel = 'loglevel',
  RegexCondition = 'regex',
}

export enum SolutionConnection {
  Connected = 'Connected',
  DisConnected = 'Disconnected',
}
