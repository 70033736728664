import { Box, Typography } from '@mui/material';
import {
  SolutionEdgeWorkloadItem,
  SolutionEdgeWorkloadStatus as Status,
} from 'src/pages/Solutions/types/solutionEdgeWorkload';
import { useSiteClusterOptionsist } from '../../redux';

type Props = {
  value?: any;
  item?: SolutionEdgeWorkloadItem;
};

const SolutionSiteClusterInfo = ({ value }: Props) => {
  const [optionsData, optionsDataLoading] = useSiteClusterOptionsist();

  return (
    <Box>
      {value.length > 0 ? (
        value[0]?.clusterIds?.map((id, index) => {
          const child = optionsData?.clustersDetail?.find(
            (child) => child.clusterId === id
          );

          return (
            <div key={index} style={{ gap: '2rem' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body3bold">
                  {`Cluster ${index + 1} : `}
                </Typography>
                &nbsp;
                <Typography fontSize="16px" fontFamily="Roboto">
                  {child ? child?.clusterName : '-'}
                </Typography>
              </Box>
            </div>
          );
        })
      ) : (
        <Typography>-</Typography>
      )}
    </Box>
  );
};

export default SolutionSiteClusterInfo;
