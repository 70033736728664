export enum SiteItemStatus {
  Active = 'active',
  InProgress = 'inProgress',
  Deleted = 'deleted',
}

export type SiteItem = {
  id: number;
  name: string;
  sector: string;
  description: string;
  createdBy: string;
  projects: string[];
  modifiedby: string;
  status: SiteItemStatus;
  observability: string;
  edgeServiceUrl: string;
};

export type SiteEdgeServiceItem = {
  edgeServiceUrl?: string;
};

export enum SiteItemField {
  SiteName = 'name',
  Sector = 'sector',
  Description = 'description',
  CreatedBy = 'createdBy',
  Solutions = 'solutions',
  Status = 'status',
  Projects = 'projects',
  ModifiedBy = 'modifiedby',
  Observability = 'observability',
  EdgeServiceUrl = 'edgeServiceUrl'
}

export type SiteDetailsItem = SiteItem;

export enum SiteNamespace {
  CoreAndCommon = 'coreandcommon-ns',
  KubeSystem = 'kube-system',
}

export type clusterDetail = {
  clusterId: string;
  clusterLabels: any;
  clusterName: string;
  clusterStatus: string;
  nodeNamespaces: string;
  observability: string;
};

export type SiteClusterItem = {
  clustersDetail: clusterDetail[];
};
