import { createDatasetConcatSlice } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import { generateApiPath, loadDataFromStorage } from 'src/utils';
import { INFINITE_SCROLL_PAGE_SIZE } from 'src/components/DataTable';
import {
  API_DEVICE_GROUPS,
  API_DEVICE_GROUPS_IOTH,
  SOLUTIONS_STORE,
  SOLUTIONS_STORE_DEVICE_GROUPS,
} from '../constants';
import { mapSolutionDeviceGroups } from './utils';
import { SolutionType } from '../types';

const api = ({
  solutionId,
  companyId,
  skipToken = '',
  pageSize = INFINITE_SCROLL_PAGE_SIZE,
}) => {
  const solutionType = loadDataFromStorage('solutionType');
  return pepApiRequest({
    method: RequestMethod.Get,
    url: generateApiPath(
      solutionType.toUpperCase() === SolutionType.ioTH.toUpperCase()
        ? API_DEVICE_GROUPS_IOTH
        : API_DEVICE_GROUPS,
      {
        solutionId,
        companyId,
      }
    ),
    queryParams: { pageSize, skipToken },
    skipEncodeUri: true,
  }).then(mapSolutionDeviceGroups);
};
const solutionDeviceGroupsSlice = createDatasetConcatSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTIONS_STORE_DEVICE_GROUPS,
});

export default solutionDeviceGroupsSlice;
