import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { PageCardLoader } from 'src/components/PageCardLayout';
import FieldsSet from 'src/components/FieldsSet';
import { saveDataToStorage } from 'src/utils';
import { useUserRoles } from 'src/auth';
import { useModal } from 'src/hooks';
import { useStoreUnitActions } from 'src/redux/helpers';
import {
  SolutionItemField,
  SolutionType,
  SolutionNamespaceRscLimitItem,
} from '../../types';
import SolutionsItemStatus from '../../components/SolutionItemStatus';
import solutionsStore, {
  useSolutionDefaultNamespaceRscLimit,
  useSolutionDetails,
} from '../../redux';
import { SOLUTION_EDIT as ROUTE_EDIT_SOLUTION } from '../../constants';
import { useSolutionUserRoles } from '../../hooks';
import SolutionSuspension from './SolutionSuspension';
import SolutionNamespaceQuotaDefaultEditModal from './SolutionNamespaceQuotaDefaultEditModal';
import SolutionFeaturesTitle from './SolutionFeaturesTitle';

const cellStyle = {
  border: 0,
  height: 'auto',
  padding: '0 0 2rem 0',
  wordWrap: 'break-word',
  fontSize: '16px',
};
const LabelStyle = {
  ...cellStyle,
  fontWeight: '500',
};

const fields = [
  {
    id: SolutionItemField.displayName,
    label: 'Display name',
  },
  {
    id: SolutionItemField.IoTCentralName,
    label: 'IoT Central name',
  },
  {
    id: SolutionItemField.Location,
    label: 'Location',
  },
  {
    id: SolutionItemField.CostCenter,
    label: 'Cost center',
  },
  {
    id: SolutionItemField.ClientId,
    label: 'Solution App/Client ID',
  },
  {
    id: SolutionItemField.BusinessOwner,
    label: 'Business Owner',
  },
  {
    id: SolutionItemField.deviceType,
    label: 'Device Type',
  },
  {
    id: SolutionItemField.Status,
    label: 'Status',
    Component: SolutionsItemStatus,
  },
  {
    id: SolutionItemField.SolutionConsumerFeatures,
    label: 'Features',
    Component: SolutionFeaturesTitle,
  },
];

const newConsumerfields = [
  {
    id: SolutionItemField.displayName,
    label: 'Display name',
  },
  {
    id: SolutionItemField.Location,
    label: 'Location',
  },
  {
    id: SolutionItemField.CostCenter,
    label: 'Cost center',
  },
  {
    id: SolutionItemField.ClientId,
    label: 'Solution App/Client ID',
  },
  {
    id: SolutionItemField.BusinessOwner,
    label: 'Business Owner',
  },
  {
    id: SolutionItemField.deviceType,
    label: 'Device Type',
  },
  {
    id: SolutionItemField.Status,
    label: 'Status',
    Component: SolutionsItemStatus,
  },
  {
    id: SolutionItemField.SolutionConsumerFeatures,
    label: 'Features',
    Component: SolutionFeaturesTitle,
  },
];

const siteFields = [
  {
    id: SolutionItemField.displayName,
    label: 'Name',
  },
  {
    id: SolutionItemField.CostCenter,
    label: 'Cost center',
  },
  {
    id: SolutionItemField.ClientId,
    label: 'Solution App/Client ID',
  },
  {
    id: SolutionItemField.BusinessOwner,
    label: 'Business Owner',
  },
  {
    id: SolutionItemField.Status,
    label: 'Status',
    Component: SolutionsItemStatus,
  },
  {
    id: SolutionItemField.ADGroup,
    label: 'AD Group',
  },
  {
    id: SolutionItemField.Project,
    label: 'Project',
  },
  {
    id: SolutionItemField.Namespace,
    label: 'Namespace',
  },
  {
    id: SolutionItemField.SolutionIndustrialFeatures,
    label: 'Features',
    Component: SolutionFeaturesTitle,
  },
];

const getFields = (solutionType) => {
  switch (solutionType.toUpperCase()) {
    case SolutionType.ioTH.toUpperCase():
      return newConsumerfields;
    case SolutionType.Consumer.toUpperCase():
      return fields;
    default:
      return siteFields;
  }
};
function SolutionDetailsTab() {
  const [data, loading] = useSolutionDetails();
  const [namespaceQuotaData, loadingQuotaData] =
    useSolutionDefaultNamespaceRscLimit();
  const { solutionId, solutionType } = useParams();
  const { isPlatformOwner, isPlatformEngineer } = useUserRoles();
  const { isSolutionEditor } = useSolutionUserRoles(solutionId);
  const isValidUser = isSolutionEditor || isPlatformEngineer;
  const isUserAllowed = isPlatformOwner || isPlatformEngineer;
  const actions = useStoreUnitActions(solutionsStore);
  const solutionData = {
    ...data,
    solutionOAuthClientId: data?.solutionOAuthClientId.toString(),
  };
  const solNamespaceQuotaAction = namespaceQuotaData ? 'Edit' : 'Create';
  const newSolNamespaceQuota: SolutionNamespaceRscLimitItem = {
    id: namespaceQuotaData?.id ?? '',
    name: namespaceQuotaData?.name ?? '',
    requestsCpu: namespaceQuotaData?.requestsCpu
      ? namespaceQuotaData?.requestsCpu.replace(/\D/g, '')
      : '',
    requestsMemory: namespaceQuotaData?.requestsMemory
      ? namespaceQuotaData?.requestsMemory.replace(/\D/g, '')
      : '',
    limitsCpu: namespaceQuotaData?.limitsCpu
      ? namespaceQuotaData?.limitsCpu.replace(/\D/g, '')
      : '',
    limitsMemory: namespaceQuotaData?.limitsMemory
      ? namespaceQuotaData?.limitsMemory.replace(/\D/g, '')
      : '',
    requestsStorage: namespaceQuotaData?.requestsStorage
      ? namespaceQuotaData?.requestsStorage.replace(/\D/g, '')
      : '',
  };

  const [EditNamespaceRscLimitDetails, showEditNamespaceRscLimitDetails] =
    useModal(SolutionNamespaceQuotaDefaultEditModal, {
      solutionId,
      data: newSolNamespaceQuota,
      solNamespaceQuotaAction,
    });

  useEffect(() => {
    saveDataToStorage('solutionType', solutionType);
    if (solutionType === SolutionType.Industrial) {
      actions.defaultNamespaceRscLimit(solutionId);
    }
  }, [solutionType]);

  return (
    <Box>
      <Box data-testid="solution-details">
        <Box
          style={{ display: 'flex', justifyContent: 'flex-end' }}
          justifyContent="row"
          alignItems="start"
          mb={2}
          gap={1}
        >
          <Button
            size="small"
            variant="outlined"
            startIcon={<EditIcon />}
            disabled={!isValidUser}
            component={Link}
            to={ROUTE_EDIT_SOLUTION}
          >
            Edit
          </Button>
          <SolutionSuspension />
        </Box>
        <FieldsSet
          data={solutionData}
          fields={getFields(solutionType)}
          sx={{
            wordBreak: 'break-word',
            gap: 2,
          }}
        />
        {loading && <PageCardLoader />}
      </Box>
      {solutionType === SolutionType.Industrial && (
        <Box
          data-testid="solution-namespace-quota-details"
          sx={{ minHeight: '20rem' }}
        >
          <Typography
            variant="body1"
            style={{
              fontSize: '18px',
              fontWeight: 500,
              marginTop: '30px',
              fontFamily: 'Stolzl',
              color: '#242424',
            }}
          >
            Default quota limits
          </Typography>
          <Box textAlign="right" mb={2}>
            <Button
              size="small"
              variant="outlined"
              startIcon={
                solNamespaceQuotaAction === 'Edit' ? <EditIcon /> : <></>
              }
              disabled={!isUserAllowed}
              onClick={showEditNamespaceRscLimitDetails}
            >
              {solNamespaceQuotaAction}
            </Button>
          </Box>
          {namespaceQuotaData ? (
            <>
              <Box
                sx={{
                  maxWidth: '64rem',
                }}
              >
                <Typography
                  variant="body2"
                  style={{
                    fontWeight: 700,
                    fontSize: '18px',
                    marginBottom: '25px',
                  }}
                >
                  Namespace level
                </Typography>
                <Box>
                  <Box sx={{ display: 'flex', gap: 19, flexDirection: 'row' }}>
                    <Typography sx={[cellStyle, LabelStyle]}>
                      CPU Reservation
                    </Typography>
                    <Typography sx={cellStyle}>
                      {newSolNamespaceQuota?.requestsCpu === '' ||
                      newSolNamespaceQuota?.requestsCpu === undefined
                        ? '-'
                        : `${newSolNamespaceQuota?.requestsCpu}mCPUs`}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', gap: 16, flexDirection: 'row' }}>
                    <Typography sx={LabelStyle}>Memory Reservation</Typography>
                    <Typography sx={cellStyle}>
                      {newSolNamespaceQuota?.requestsMemory === '' ||
                      newSolNamespaceQuota?.requestsMemory === undefined
                        ? '-'
                        : `${newSolNamespaceQuota?.requestsMemory}MiB`}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', gap: 24, flexDirection: 'row' }}>
                    <Typography sx={LabelStyle}>CPU Limit</Typography>
                    <Typography sx={cellStyle}>
                      {newSolNamespaceQuota?.limitsCpu === '' ||
                      newSolNamespaceQuota?.limitsCpu === undefined
                        ? '-'
                        : `${newSolNamespaceQuota?.limitsCpu}mCPUs`}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', gap: 21, flexDirection: 'row' }}>
                    <Typography sx={LabelStyle}>Memory Limit</Typography>
                    <Typography sx={cellStyle}>
                      {newSolNamespaceQuota?.limitsMemory === '' ||
                      newSolNamespaceQuota?.limitsMemory === undefined
                        ? '-'
                        : `${newSolNamespaceQuota?.limitsMemory}MiB`}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Table
                sx={{
                  maxWidth: '63rem',
                  '& .MuiTableRow-root:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                <Typography
                  variant="body2"
                  style={{
                    fontWeight: 700,
                    fontSize: '18px',
                    marginBottom: '25px',
                    marginTop: '12px',
                  }}
                >
                  Storage level
                </Typography>
                <TableBody>
                  <TableRow>
                    <TableCell sx={LabelStyle}>Limit</TableCell>
                    <TableCell sx={cellStyle}>
                      {newSolNamespaceQuota?.requestsStorage === '' ||
                      newSolNamespaceQuota?.requestsStorage === undefined
                        ? '-'
                        : `${newSolNamespaceQuota?.requestsStorage}MiB`}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </>
          ) : (
            <Typography variant="body2" color="#A5A5A5" align="center">
              No data
            </Typography>
          )}
          {EditNamespaceRscLimitDetails}
          {loadingQuotaData && <PageCardLoader />}
        </Box>
      )}
    </Box>
  );
}

export default SolutionDetailsTab;
