import { RouteProps } from 'src/router';
import { UserRole } from 'src/auth';
import * as ROUTES from '../constants';
import SiteDetailsContent from './components/SiteDetailTab';
import SiteUsers from '../SiteUsers';
import SiteDevices from './SiteDevices/SiteDevices';
import SiteEdit from '../SiteEdit';
import { useCurrentSiteName } from '../redux';
import SiteConfigurations from '../SiteConfigurations';

export const routes: RouteProps[] = [
  {
    index: true,
    component: SiteDevices,
    meta: {
      title: 'Universal Edge',
    },
  },
  {
    path: ROUTES.SITE_CONFIGURATIONS,
    component: SiteConfigurations,
    allowedUserRoles: [
      UserRole.PlatformOwner,
      UserRole.PlatformAdmin,
      UserRole.PlatformEngineer,
      UserRole.PlatformReader,
    ],
    meta: {
      title: 'Configurations',
    },
  },
  {
    path: ROUTES.SITE_EDIT,
    component: SiteEdit,
    meta: {
      title: 'Edit Site',
      hideTabs: true,
    },
  },
];
