import { SolutionJobItem, SolutionJobType, SolutionType } from '../types';

import { JOB_IOTH_TYPE_OPTIONS, JOB_TYPE_OPTIONS } from './constants';

export const GetSolutionJobItemRequestBody = (
  solutionType: string,
  propertyOptions: any,
  propertiesData: any,
  commandData: any,
  commandOptions: any,
  jobItem?: SolutionJobItem
) => {
  const {
    organization,
    propertyId,
    value,
    commandValue,
    invokeCompany,
    runLater,
    propertyUpdate,
    type,
    modelId,
    ...rest
  } = jobItem || {};
  let name: string | '' = '';
  let componentName: string | '' = '';
  let propertyValue: string | undefined = value;
  const object: any = [];
  type requestType = { data: dataType[] };
  type dataType = {
    componentName: string;
    object: [];
  };
  const propertyObject: any = {};
  const removeDuplicates = (arr) => {
    const unique: any = [];
    arr.forEach((element) => {
      if (!unique.includes(element.componentName)) {
        unique.push(element.componentName);
      }
    });
    return unique;
  };
  function listToObject(list, propertyNames) {
    const obj = {};
    if (propertyNames) {
      obj[propertyNames] = {};
      Object.entries(list).forEach((value, index) => {
        Object.assign(obj[propertyNames], list[index]);
      });
    } else {
      Object.entries(list).forEach((value, index) => {
        Object.assign(obj, list[index]);
      });
    }
    return obj;
  }
  const finalData: requestType = { data: [] };
  if (solutionType.toUpperCase() === SolutionType.ioTH.toUpperCase()) {
    if (type === SolutionJobType.TemplateMigration) {
      return {
        type,
        organizations: [organization],
        modelId,
        updateTwin: {
          etag: '*',
          tags: {
            modelId,
          },
        },
        startJobAfterSaving: !runLater,
        displayName: jobItem?.displayName,
        group: jobItem?.group,
      };
    }
    if (type === SolutionJobType.ScheduleUpdateTwin) {
      if (jobItem?.propertyUpdate && jobItem?.propertyUpdate.length !== 0) {
        for (
          let index = 0;
          index < jobItem?.propertyUpdate.length;
          index += 1
        ) {
          const object: any = [];
          const element = jobItem?.propertyUpdate[index];
          const requestData: dataType = { componentName: '', object: [] };
          name = propertyOptions?.filter(
            (a) =>
              a.value?.replaceAll(' ', '') === element?.propertyName ||
              a.label?.replaceAll(' ', '') === element?.propertyName
          )[0].label;

          const property = propertiesData?.filter(
            (a) =>
              a.id === element?.propertyName || a.name === element?.propertyName
          )[0];
          requestData.componentName = property.component;
          componentName = property.component;
          if (
            property.schema === 'Enum' &&
            propertiesData?.filter((a) => a.id === element?.propertyName)[0]
              ?.enums &&
            propertiesData?.filter((a) => a.id === element?.propertyName)[0]
              ?.enums[element?.propertyValue]
          )
            propertyValue = propertiesData
              ?.filter((a) => a.id === element?.propertyName)[0]
              ?.enums?.find(
                (a) => a.value === Number(element?.propertyValue)
              )?.label;
          else
            propertyValue =
              property.schema === 'integer'
                ? Number(element?.propertyValue)
                : element?.propertyValue;

          object.push({ [name.replaceAll(' ', '')]: propertyValue });
          requestData.object = object;
          finalData.data.push(requestData);
        }
        const componentNames = removeDuplicates(finalData.data);
        for (let index = 0; index < componentNames.length; index += 1) {
          const componentNewName =
            componentNames[index] === 'DefaultComponent'
              ? ''
              : componentNames[index];
          const sameComponentNames = finalData.data.filter(
            (a) => a.componentName === componentNames[index]
          );

          const newObject: any = [];
          const newTempObject: any = {};
          const tempObject: any = [];
          let subComponentName: any = '';
          let subName: any = '';
          sameComponentNames.forEach((element: any) => {
            if (
              Object.keys(element.object[0])[0] &&
              Object.keys(element.object[0])[0].includes('/') &&
              Object.keys(element.object[0])[0].split('/')
            ) {
              const elementName: any = Object.keys(element.object[0]);
              [subComponentName, subName] = Object.keys(
                element.object[0]
              )[0].split('/');
              const subValue: any = element.object[0][elementName];
              tempObject.push({ [subName]: subValue });
            } else newObject.push(element.object[0]);
          });
          if (subComponentName) {
            Object.assign(
              newTempObject,
              listToObject(tempObject, subComponentName)
            );
            Object.assign(propertyObject, {
              [componentNewName]: newTempObject,
            });
          } else
            Object.assign(
              propertyObject,
              listToObject(newObject, componentNewName)
            );
        }
      } else {
        const object: any = [];
        name = propertyOptions?.filter(
          (a) =>
            a.value?.replaceAll(' ', '') === jobItem?.propertyName ||
            a.label?.replaceAll(' ', '') === jobItem?.propertyName
        )[0]?.label;
        const property = propertiesData?.filter(
          (a) =>
            a.id === jobItem?.propertyName || a.name === jobItem?.propertyName
        )[0];
        componentName =
          property.component === 'DefaultComponent' ? '' : property.component;
        object.push({ [name]: value });
        Object.assign(propertyObject, listToObject(object, componentName));
      }
      return {
        type,
        organizations: [organization],
        updateTwin: {
          etag: '*',
          properties: {
            desired: propertyObject,
          },
        },
        startJobAfterSaving: !runLater,
        displayName: jobItem?.displayName,
        group: jobItem?.group,
      };
    }
    if (type === SolutionJobType.ScheduleDeviceMethod) {
      const component = commandData?.find(
        (e) => e?.id === invokeCompany || e?.name === invokeCompany
      ).component;
      const commandName = commandOptions?.filter(
        (a) => a.value === invokeCompany || a.label === invokeCompany
      )[0].label;
      if (
        commandData?.find(
          (e) => e?.id === invokeCompany || e?.name === invokeCompany
        )?.requestSchemaType?.fields &&
        commandData?.find(
          (e) => e?.id === invokeCompany || e?.name === invokeCompany
        )?.requestSchemaType?.fields.length !== 0
      ) {
        for (
          let index = 0;
          index <
          commandData?.find(
            (e) => e?.id === invokeCompany || e?.name === invokeCompany
          )?.requestSchemaType?.fields.length;
          index += 1
        ) {
          const element = commandData?.find(
            (e) => e?.id === invokeCompany || e?.name === invokeCompany
          )?.requestSchemaType?.fields[index];
          name = element.name;
          componentName = element.component;
          propertyValue = jobItem ? jobItem[element.name] : '';
          object.push({ [name]: propertyValue });
        }
      }
      return {
        type,
        organizations: [organization],
        cloudToDeviceMethod: {
          methodName:
            component === 'DefaultComponent'
              ? commandName
              : `${component}*${commandName}`,

          payload: Object.assign({}, ...object),
          responseTimeoutInSeconds: 10,
          connectionTimeoutInSeconds: 10,
        },
        updateTwin: {},
        startJobAfterSaving: !runLater,
        displayName: jobItem?.displayName,
        group: jobItem?.group,
      };
    }
    if (type === SolutionJobType.HostOsDeployment) {
      return {
        type,
        organizations: [organization],
        startJobAfterSaving: !runLater,
        ...rest,
      };
    }
  }

  return {
    type,
    organizations: [organization],
    modelId,
    startJobAfterSaving: !runLater,
    ...rest,
  };
};

export const composeSolutionJobsAlertText = (text: string, start?: boolean) => {
  const subtext = start ? 'and run' : '';
  return text.replace('{run}', subtext);
};

export const getSolutionJobOptions = (menderEnabled?: boolean) =>
  JOB_TYPE_OPTIONS.filter(
    ({ value }) => menderEnabled || value !== SolutionJobType.HostOsDeployment
  );
export const getSolutionIOTHJobOptions = (menderEnabled?: boolean) =>
  JOB_IOTH_TYPE_OPTIONS.filter(
    ({ value }) => menderEnabled || value !== SolutionJobType.HostOsDeployment
  );
