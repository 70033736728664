import { Box } from "@mui/system";
import { useParams } from "react-router-dom";
import { useSolutionDetails } from "../../redux";
import { SolutionItem } from "../../types";


type Props = {
    item?: SolutionItem;
};
function RetentionDaysDetails({ item }: Props) {
    const { solutionId } = useParams();
    const [data] = useSolutionDetails();
    let RetentionUTCDate;
    const one_day = 1000 * 60 * 60 * 24;
    if (solutionId) {
        const lastStatusChange = data?.lastStatusChange;
        const retentionDays = Number(data?.retentionDays);
        const lastStatusCahangedate = new Date(lastStatusChange || '');
        const UTCDate = new Date().toUTCString();
        const currentDate = Number(new Date(UTCDate));
        const RetentionEndDate = lastStatusCahangedate.setUTCDate(lastStatusCahangedate.getUTCDate() + retentionDays);
        RetentionUTCDate = Math.round((RetentionEndDate - currentDate) / one_day);
    }
    else {
        const lastStatusChange = item?.lastStatusChange;
        const retentionDays = Number(item?.retentionDays);
        const lastStatusCahangedate = new Date(lastStatusChange || '');
        const UTCDate = new Date().toUTCString();
        const currentDate = Number(new Date(UTCDate));
        const RetentionEndDate = lastStatusCahangedate.setUTCDate(lastStatusCahangedate.getUTCDate() + retentionDays);
        RetentionUTCDate = Math.round((RetentionEndDate - currentDate) / one_day);
    }

    return (
        <Box sx={{ fontFamily: 'Stolzl', fontSize: 'x-small' }}>Deleting in {RetentionUTCDate} Days</Box>
    );
}

export default RetentionDaysDetails;