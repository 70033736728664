import React from 'react';
import { SolutionJobType as JobType } from '../../types';

type Props = {
  value?: JobType;
};

function SolutionJobType({ value }: Props) {
  const jobsMap: Record<JobType, string> = {
    [JobType.Command]: 'Command',
    [JobType.TemplateMigration]: 'Template migration',
    [JobType.HostOsDeployment]: 'Host OS update',
    [JobType.ScheduleUpdateTwin]: 'Property Update',
    [JobType.ScheduleDeviceMethod]: 'Invoke Command',
  };

  return jobsMap[value as JobType] ? (
    <>{jobsMap[value as JobType]}</>
  ) : (
    <span>–</span>
  );
}

export default SolutionJobType;
