import { oktaAuth } from '../auth';
import { ContentType, RequestParams } from './types';
import { apiRequest } from './api';
import { PEP_API_PATH } from './constants';

export const pepApiRequest = ({ headers, url, ...rest }: RequestParams) => {
  const Authorization = `Bearer ${oktaAuth.getAccessToken()}`;

  return apiRequest({
    contentType: ContentType.JSON,
    headers: {
      Authorization,
      ...headers,
    },
    url: PEP_API_PATH + url,
    ...rest,
  });
};
