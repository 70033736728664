import { useState } from 'react';
import isArray from 'lodash/isArray';
import { useSyncState } from 'src/hooks';
import { Option, OptionValue } from 'src/components/SelectOption';
import { getDisplayValue, getSearchResult } from './utils';

export const useFiltering = <T extends OptionValue>(
  options: Option<T>[],
  initialValue?: T | T[],
  multiple?: boolean
) => {
  const isSingle = !multiple && !isArray(initialValue);
  const [searchValue, setSearchValue] = useState(
    isSingle ? getDisplayValue(options, initialValue) : ''
  );
  const [filteredOptions, setFilteredOptions] = useSyncState(options);

  const onResetFilter = (value: string) => {
    setSearchValue(getDisplayValue(options, value));
    setFilteredOptions(options);
  };

  const onFilter = (searchStr: string) => {
    setSearchValue(searchStr);
    setFilteredOptions(getSearchResult<T>(searchStr, options));
  };

  return {
    searchValue,
    filteredOptions,
    onFilter,
    onResetFilter,
  };
};
