import React, { useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Grid, IconButton, Paper, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { SxProps } from 'src/theme/types';
import ModalDialog, { ModalCloseHandler } from 'src/components/ModalDialog';
import { useForm } from 'src/components/Form';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { getSolutionModuleLogFields } from './formfields';
import { API_SOLUTIONS_IOTHUB_MODULE_LOGS } from '../constants';

type Props = {
  moduleId: any;
  open: boolean;
  onClose: ModalCloseHandler;
};

const modalStyle: SxProps = {
  '.MuiDialogContent-root': {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
};

const containerStyle = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#F3F2F1',
  border: '1px solid #B5B5B5',
  maxWidth: '1200px',
  height: '200px',
  width: '1116px',
  overflow: 'auto',
  padding: theme.spacing(1),
  textAlign: 'left',
  boxShadow: 'none',
}));

const iconStyle = {
  color: '#0047BA',
};

function SolutionModuleLogsModal({ moduleId, open, onClose }: Props) {
  const { solutionId, solutionName } = useParams();
  const [responseData, setResponseData] = useState<any>([]);
  const [isCopied, setIsCopied] = useState('');

  const getFilterParams = (params: any) => {
    const filtersData = params;
    return {
      schemaVersion: '1.0',
      items: [
        {
          id: moduleId.startsWith('$') ? moduleId.replace('$', '') : moduleId,
          filter: Object.fromEntries(
            Object.entries(filtersData).filter(([key, value]) => value !== '')
          ),
        },
      ],
      encoding: 'none',
      contentType: 'text',
    };
  };

  const [handleSubmit, loading] = useStatefulApi((params) =>
    pepApiRequest({
      method: RequestMethod.Post,
      url: generatePath(API_SOLUTIONS_IOTHUB_MODULE_LOGS, {
        SolutionId: solutionId,
        TenantId: 'all',
        DeviceId: solutionName,
      }),
      params: {
        componentName: '',
        moduleName: '$edgeAgent',
        commandName: 'GetModuleLogs',
        payload: JSON.stringify(getFilterParams(params), (key, value) => {
          if (typeof value === 'number') {
            return value;
          }
          return value;
        }),
      },
    }).then((response) => {
      setResponseData(response);
    })
  );

  const [form, onSubmit, setValue] = useForm({
    fields: getSolutionModuleLogFields,
    sx: containerStyle,
    onSubmit: handleSubmit,
  });

  const handleClear = () => {
    setValue('tail', '');
    setValue('since', '');
    setValue('until', '');
    setValue('loglevel', '');
    setValue('regex', '');
  };

  const handleCopy = (text?: any) => {
    navigator.clipboard.writeText(text ?? '').then(() => {
      setIsCopied(text ?? '');
    });
  };

  return (
    <ModalDialog
      open={open}
      title="View module logs"
      onClose={onClose}
      cancelBtnText="Close"
      showConfirmBtn={false}
      sx={modalStyle}
      maxWidth="lg"
      fullWidth
      loading={loading}
    >
      <Typography sx={{ fontWeight: 'medium', fontSize: '18px' }}>
        Filter
      </Typography>
      {form}
      <Box
        style={{ display: 'flex', justifyContent: 'flex-end' }}
        justifyContent="row"
        alignItems="start"
        gap={1}
        mt={1}
      >
        <Button size="small" variant="outlined" onClick={handleClear}>
          Clear
        </Button>
        <Button size="small" variant="contained" onClick={onSubmit}>
          Apply
        </Button>
      </Box>
      <Typography sx={{ fontWeight: 'medium', fontSize: '18px' }}>
        Logs
      </Typography>
      <Box>
        <Box display="flex">
          <Grid container>
            <Grid item>
              <Item>
                <Typography fontSize="10px">
                  {responseData?.value?.payload[0]?.payload ?? ''}
                </Typography>
              </Item>
            </Grid>
            <Grid item>
              <IconButton
                title={isCopied ? 'Copied to clipboard' : 'Copy'}
                onClick={() =>
                  handleCopy(responseData?.value?.payload[0]?.payload)
                }
              >
                <ContentCopyIcon sx={iconStyle}></ContentCopyIcon>
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </ModalDialog>
  );
}

export default SolutionModuleLogsModal;
