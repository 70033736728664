import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import Select from 'src/components/Select';
import TextFieldWithInfo from 'src/components/TextField';
import { OtpItemField } from './types';
import { getTimezoneOptions } from './utils';

export const getOtpEditFormFields = (key?: string) => [
  {
    name: OtpItemField.Key,
    label: 'Key',
    placeholder: 'Enter the name of the key',
    Component: TextFieldWithInfo,
    validation: Yup.string().required('Key is required'),
    showinfoicon: true,
    infotitle: 'Name should contain alphanumeric, numbers',
    sx: { width: ({ spacing }) => spacing(42), paddingRight: '10px' },
    disabled: !!key,
    required: true,
  },
  {
    name: OtpItemField.UtcTimeShift,
    label: 'UTC time offset',
    placeholder: 'Select the value',
    Component: Select,
    showInfoIcon: true,
    infoTitle: 'Select the local timezone of the device',
    options: getTimezoneOptions(),
    validation: Yup.string().required('UTC time offset is required'),
    required: true,
  },
  {
    name: OtpItemField.RecipientEmail,
    label: 'Deliver to email',
    placeholder: 'Enter the valid PepsiCo email id',
    Component: TextField,
    validation: Yup.string()
      .required('Email is required')
      .email('Must be a valid email'),
    type: 'email',
    required: true,
  },
];
