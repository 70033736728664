import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Card, { CardProps } from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { SxProps } from 'src/theme/types';

const cardStyle: SxProps = {
  cursor: 'pointer',
  display: 'inline-flex',
  alignItems: 'flex-start',
  textAlign: 'left',
  textDecoration: 'none',
  justifyContent: 'flex-start',
  mx: 1,
  mb: 2,
  p: 0,
  '&.Mui-disabled': {
    bgcolor: 'grayscale.200',
  },
  '&:not(:hover)': {
    boxShadow: 'none',
  },
};

const iconStyle: SxProps = {
  height: ({ spacing }) => spacing(15),
  paddingTop: '17%',
  color: 'neutral.main',
};

const textStyle: SxProps = {
  overflow: 'hidden',
  width: '250px',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  '.MuiTypography': {
    '&-root': {
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
    },
    '&-body2': {
      color: 'neutral.main',
    },
  },
};

type Props = CardProps<any> & {
  title?: string;
  description?: string;
  Icon: React.JSXElementConstructor<SvgIconProps>;
};

function MonitoringCardButton({
  component = Button,
  title,
  description,
  Icon,
  sx,
  ...rest
}: Props) {
  return (
    <Card
      data-testid="card-button"
      component={component}
      sx={{ ...cardStyle, ...sx } as SxProps}
      {...rest}
    >
      <Box
        alignItems="center"
        sx={{ backgroundColor: 'grayscale.300' }}
        justifyContent="center"
        display={'flex'}
        flexDirection={'column'}
      >
        <Box sx={{ ...iconStyle }} data-testid="icon-wrapper">
          <Icon sx={{ height: '20px', width: '30px' }} data-testid="icon" />
        </Box>
        <Box sx={{ p: ({ spacing }) => spacing(1), bgcolor: '#ffffff' }}>
          <Box sx={textStyle}>
            <Typography variant="h4" gutterBottom>
              {title}
            </Typography>
            <Box style={{ color: 'blue' }}>
              <MoreVertIcon />
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

export default MonitoringCardButton;
