import { createDatasetSlice, ResponseMapping } from 'src/redux/helpers';
import { generatePath } from 'react-router-dom';
import { pepApiRequest, RequestMethod } from 'src/services';
import { API_SITE, SITES_STORE, SITES_STORE_DETAILS } from '../constants';

const api = (siteId: string) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: generatePath(API_SITE, { siteId }),
  });

const siteDetailsSlice = createDatasetSlice({
  api,
  storeName: SITES_STORE,
  actionName: SITES_STORE_DETAILS,
  responseMapping: ResponseMapping.Default,
});

export default siteDetailsSlice;
