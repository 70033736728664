export enum SolutionDeviceDeploymentStatus {
  Finished = 'finished',
  InProgress = 'inprogress',
  Pending = 'pending',
  Scheduled = 'scheduled',
}

export enum SolutionDeviceDeploymentLogItemStatus {
  AlreadyInstalled = 'already-installed',
  Aborted = 'aborted',
  Decommissioned = 'decommissioned',
  Downloading = 'downloading',
  Failure = 'failure',
  Installing = 'installing',
  NoArtifact = 'noartifact',
  Pause = 'pause',
  PauseBeforeInstalling = 'pause_before_installing',
  PauseBeforeCommitting = 'pause_before_committing',
  PauseBeforeRebooting = 'pause_before_rebooting',
  Pending = 'pending',
  Rebooting = 'rebooting',
  Skipped = 'skipped',
  Success = 'success',
}

export type SolutionDeviceDeploymentLogItem = {
  hostOSVersion: string;
  started: string;
  finished: number;
  targetRelease?: string;
  status: SolutionDeviceDeploymentLogItemStatus;
};

export type SolutionDeviceReleaseItem = {
  id: string;
  modified: string;
  name: string;
  signed: boolean;
  size: number;
};

export enum SolutionDeviceDeploymentLogField {
  HostOsVersion = 'hostOsVersion',
  Started = 'started',
  Finished = 'finished',
  TargetRelease = 'targetRelease',
  DeploymentStatus = 'deploymentStatus',
}

export type SolutionDeviceBulkDeploymentDetails = {
  status: SolutionDeviceDeploymentStatus;
  device_count: number;
  retries: number;
};

export type SolutionDeviceBulkDeploymentLogItem = {
  deviceId: string;
  started: string;
  finished: number;
  status: SolutionDeviceDeploymentLogItemStatus;
};

export type SolutionDeviceBulkDeploymentData = {
  details: SolutionDeviceBulkDeploymentDetails;
  statistics: Record<SolutionDeviceDeploymentLogItemStatus, number>;
  logs: SolutionDeviceBulkDeploymentLogItem[];
};

export enum SolutionDeviceBulkDeploymentLogField {
  DeviceName = 'deviceId',
  Started = 'started',
  Finished = 'finished',
  DeploymentStatus = 'deploymentStatus',
}
