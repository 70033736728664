import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { getRowKey, isAllSelected, isItemSelected } from '../utils';
import {
  Row,
  Column,
  SelectedItems,
  SelectRowHandler,
  SelectAllHandler,
  ToggleGroupHandler,
  GroupRowsMeta,
  SortHandler,
  SortOrder,
} from '../types';
import DataTableRow from './DataTableRow';
import DataTableHead from './DataTableHead';

type Props<TRow> = {
  data: TRow[];
  columns: Column<TRow>[];
  groupRows: GroupRowsMeta;
  selectedItems: SelectedItems;
  onSelectRow: SelectRowHandler<TRow>;
  onSelectAll: SelectAllHandler;
  onToggleGroup: ToggleGroupHandler<TRow>;
  onSort: SortHandler;
  sortBy?: string;
  sortOrder?: SortOrder;
};

function DataTableContent<TRow extends Row>({
  data,
  columns,
  groupRows,
  selectedItems,
  onSelectRow,
  onSelectAll,
  onToggleGroup,
  ...rest
}: Props<TRow>) {
  return (
    <Box overflow="auto" width="100%">
      <Table sx={{ width: 'auto' }}>
        <DataTableHead
          columns={columns}
          onSelectAll={onSelectAll}
          allSelected={isAllSelected(data, selectedItems)}
          {...rest}
        />
        <TableBody data-testid="data-table-body">
          {data.map((row) => {
            const rowKey = getRowKey(row);
            const rowGroupData = groupRows[rowKey];

            return (
              <DataTableRow<TRow>
                key={rowKey}
                row={row}
                columns={columns}
                isGroup={!!rowGroupData}
                expandable={rowGroupData?.isExpandable}
                expanded={rowGroupData?.isExpanded}
                selected={isItemSelected(rowKey, selectedItems)}
                onSelect={onSelectRow}
                onToggleGroup={onToggleGroup}
              />
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
}

export default DataTableContent;
