// routes
export const NEW_SITE = `new`;
export const SITE_DETAILS = ':siteId';
export const SITE_EDIT = 'edit';
export const SITE_CONFIGURATIONS = 'configurations';
export const SITE_DEVICES = 'devices';
export const SITE_SOLUTIONS = 'solutions';

// api
export const API_SITES_ALL = '/site';
export const API_SITE = `${API_SITES_ALL}/:siteId`;
export const API_CREATE_CLUSTER = `${API_SITES_ALL}/:siteId/cluster`;
export const API_SITES_SOLUTIONS = '/site/:siteId/solutions';
export const API_SITE_RANCHER_COMMAND = '/site/commands/:siteId';
export const API_SITE_CLUSTER_STATUS = '/site/:siteId/clusterstatus';
export const API_SITE_CLUSTERS = '/site/:siteId/clusters';
export const API_SITE_CLUSTER_NAMESPACE_QUOTAS_DETAIL =
  '/site/:siteId/cluster/:clusterId/namespace-resource-quotas';
export const API_SITES_CREATE_EDGE_SERVICE = `${API_SITE}/edgeService`;
export const API_SITE_WORKLOAD_CONFIGURATIONS = `${API_SITE}/GetAllWorkloadConfigurations`;
export const API_SITE_WORKLOAD_CONFIG_CREATE = `${API_SITE}/CreateWorkloadConfiguration`;
export const API_SITE_WORKLOAD_CONFIG_DELETE = `${API_SITE}/DeleteWorkloadConfiguration/:repoName`;
export const API_SITE_WORKLOAD_CONFIG_UPDATE = `${API_SITE}/UpdateWorkloadConfiguration`;
export const API_SITE_NODE_NAMESPACE = '/site/:siteId/nodenamespace';
export const API_SITE_UPDATE_SITE_STATUS = '/site/:siteId/status';

// redux
export const SITES_STORE = 'sites';
export const SITES_STORE_LIST = 'list';
export const SITES_STORE_DETAILS = 'details';
export const SITES_STORE_EDGE_WORKLOAD_CONFIGURATIONS =
  'edgeWorkloadConfigurations';
export const SITES_STORE_SOLUTIONS = 'solutions';
export const SITES_STORE_CLUSTER_DETAILS = 'clusterDetails';
