import React from 'react';

type Props = {
  value?: string;
};

function SolutionWorkloadMessage({ value = '' }: Props) {
  return (
    <>
      {value !== '' ? (
        <div style={{ color: '#DB1E36', fontSize: '13px' }}>{value}</div>
      ) : (
        '-'
      )}
    </>
  );
}

export default SolutionWorkloadMessage;
