import { useState, useEffect } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import SpeedIcon from '@mui/icons-material/Speed';
import { pepApiRequest, RequestMethod } from 'src/services';
import Box from '@mui/material/Box';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { styled } from '@mui/material/styles';
import { useUserRoles } from 'src/auth';
import {
  SiteDeviceProjectItem,
  SiteItem,
  SiteNamespace,
  SiteItemStatus as SiteStatus,
} from 'src/pages/Sites/types';
import SiteItemStatus from 'src/pages/Sites/components/SiteItemStatus';
import { API_SOLUTION_SITES_CLUSTERS_RES_SETTING } from 'src/pages/Solutions/constants';
import { SolutionNamespaceRscLimitItem } from 'src/pages/Solutions/types';
import { useModal } from 'src/hooks';
import { PageCardLoader } from 'src/components/PageCardLayout';
import { isModalComplete } from 'src/components/ModalDialog';
import ClusterDetails from './ClusterDetails';
import NodesList from './NodesList';
import NameSpacesList from './NameSpacesList';
import SiteClusterNamespaceRscLimitViewModal from './components/SiteClusterNamespaceRscLimitViewModal';
import SiteClusterNamespaceRscLimitEditModal from './components/SiteClusterNamespaceRscLimitEditModal';

type Props = {
  data: SiteItem | undefined;
  cluster: any;
  clusterDetail: any;
};

function SiteClusterComponent({ data, cluster, clusterDetail }: Props) {
  const [isCopied, setIsCopied] = useState('');
  const [namespaceQuotaData, setNamespaceQuotaData] = useState<
    SolutionNamespaceRscLimitItem | undefined
  >();
  const [namespaceQuotaLoader, setNamespaceQuotaLoader] = useState(false);
  const { siteId } = useParams();
  const { isPlatformOwner, isPlatformEngineer, isPlatformReader } =
    useUserRoles();
  const isValidUser = isPlatformOwner || isPlatformEngineer || isPlatformReader;

  const iconStyle = {
    color: '#0047BA',
  };

  const getNamespaces: any = (data) => {
    const nameSpaces: SiteDeviceProjectItem[] = [];
    data.map((namespace) => nameSpaces.push({ nameSpace: namespace }));
    return nameSpaces;
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#300A24',
    maxWidth: '800px',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: 'white',
  }));

  const handleCopy = (text?: string) => {
    navigator.clipboard.writeText(text ?? '').then(() => {
      setIsCopied(text ?? '');
    });
  };

  const getClusterLabel = (label) => {
    const labels = Object.entries(label);
    const formatedLabel = labels?.map(([key, value]) => `${key}:${value}`);
    return formatedLabel;
  };

  const onClose = (event, reason) => {
    isModalComplete(reason);
  };

  const [NamespaceRscLimitDialog, showNamespaceRscLimitDialog] = useModal(
    SiteClusterNamespaceRscLimitViewModal,
    {
      siteId,
      clusterId: cluster.clusterId,
      clusterName: cluster.clusterName,
      onClose,
    }
  );
  const [EditNamespaceRscLimitDialog, showEditNamespaceRscLimitDialog] =
    useModal(SiteClusterNamespaceRscLimitEditModal, {
      siteId,
      title: `Add Quota for Core and Common : ${cluster.clusterName}`,
      clusterId: cluster.clusterId,
      clusterName: cluster.clusterName,
      onClose,
      setNamespaceQuotaData,
    });

  useEffect(() => {
    if (clusterDetail && data && cluster.clusterStatus === SiteStatus.Active) {
      setNamespaceQuotaLoader(true);
      pepApiRequest({
        method: RequestMethod.Post,
        url: generatePath(API_SOLUTION_SITES_CLUSTERS_RES_SETTING, {
          siteId,
          namespaceName: SiteNamespace.CoreAndCommon,
        }),
        params: { clusterIds: [cluster.clusterId] },
      }).then((response) => {
        setNamespaceQuotaData(response[0]);
        setNamespaceQuotaLoader(false);
      });
    }
  }, [cluster]);

  return (
    <>
      <Accordion
        sx={{
          borderRadius: '5px',
          border: '1px solid #E8E8E8',
          boxShadow: 'none',
          marginTop: '25px',
          '&:before': {
            display: 'none',
          },
        }}
        defaultExpanded
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color="primary" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            padding: '9px',
            '.MuiAccordionSummary-content.Mui-expanded': { margin: '12px 0' },
          }}
        >
          <Typography variant="h4" fontWeight="200" sx={{ paddingLeft: '8px' }}>
            {cluster?.clusterName}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: 'rgba(165, 165, 165, 0.07)' }}>
          {clusterDetail &&
            data &&
            cluster.clusterStatus === SiteStatus.Active && (
              <>
                <Box
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                  justifyContent="row"
                  alignItems="start"
                  mb={2}
                  mt={1}
                  gap={1}
                >
                  <Button
                    size="small"
                    variant="outlined"
                    startIcon={
                      namespaceQuotaData?.limitsCpu ? (
                        <SpeedIcon />
                      ) : (
                        <AddIcon />
                      )
                    }
                    disabled={!isValidUser}
                    onClick={
                      namespaceQuotaData?.limitsCpu
                        ? showNamespaceRscLimitDialog
                        : showEditNamespaceRscLimitDialog
                    }
                  >
                    {namespaceQuotaData?.limitsCpu ? 'View' : 'Add'} Quota for
                    Core and common
                  </Button>
                </Box>
                <ClusterDetails
                  status={cluster?.clusterStatus}
                  label={
                    cluster?.clusterLabels
                      ? getClusterLabel(cluster?.clusterLabels)
                      : '-'
                  }
                  observability={cluster?.observability}
                />
              </>
            )}
          {clusterDetail && cluster?.clusterStatus === SiteStatus.Active && (
            <>
              <NodesList data={cluster?.nodeNamespaces?.Nodes} />
              <NameSpacesList
                data={
                  cluster?.nodeNamespaces?.Namespaces
                    ? getNamespaces(cluster?.nodeNamespaces?.Namespaces)
                    : []
                }
              />
            </>
          )}
          {cluster.clusterStatus !== SiteStatus.Active && (
            <Box alignItems="center">
              <Box display="flex" gap={25}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    paddingTop: '10px',
                  }}
                >
                  Status
                </Typography>
                <Box sx={{ paddingBottom: '12px', paddingTop: '10px' }}>
                  <SiteItemStatus
                    value={
                      cluster?.clusterStatus === 'inprogress'
                        ? 'inProgress'
                        : cluster?.clusterStatus
                    }
                  />
                </Box>
              </Box>
              <Box display="flex" gap={10}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                  }}
                >
                  Rancher command snippet
                </Typography>
                <Box>
                  <Box display="flex">
                    <Grid container spacing={1} columns={12}>
                      <Grid item xs={11}>
                        <Item>
                          <span style={{ wordBreak: 'break-word' }}>
                            {cluster.clusterCommands?.insecureCommand ??
                              'Loading...'}
                          </span>
                        </Item>
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          title={isCopied ? 'Copied to clipboard' : 'Copy'}
                          onClick={() =>
                            handleCopy(
                              cluster?.clusterCommands?.insecureCommand
                            )
                          }
                        >
                          <ContentCopyIcon sx={iconStyle}></ContentCopyIcon>
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Box>
                  <p>
                    <InfoOutlinedIcon sx={iconStyle}></InfoOutlinedIcon> Use the
                    above command to register this cluster to rancher portal.
                  </p>
                </Box>
              </Box>
            </Box>
          )}
          {NamespaceRscLimitDialog}
          {EditNamespaceRscLimitDialog}
          {namespaceQuotaLoader && <PageCardLoader />}
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default SiteClusterComponent;
