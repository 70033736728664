import { baseTheme } from '../baseTheme';

const { palette, spacing, typography } = baseTheme;
const borderWidth = spacing(0.2);

export default {
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        '.MuiAutocomplete-inputRoot': {
          paddingTop: spacing(0.5),
          paddingBottom: spacing(0.6),
        },
        '.MuiAutocomplete-clearIndicator, .MuiAutocomplete-popupIndicator': {
          color: palette.neutral.dark,
        },
        '&.Mui-disabled': {
          backgroundColor: palette.neutral.light,
        },
      },
      listbox: {
        padding: 0,
        '.MuiAutocomplete-option': {
          paddingTop: spacing(1),
          paddingBottom: spacing(1),
          '&[aria-selected=true]:not(:hover)': {
            backgroundColor: palette.primary.main,
            color: palette.white,
          },
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        ...typography.body2,
        width: '100%',
      },
      adornedEnd: {
        '.MuiSelect-select.MuiSelect-outlined.MuiInputBase-inputAdornedEnd': {
          paddingRight: spacing(6),
        },
        '.MuiCircularProgress-root': {
          color: 'currentColor',
          marginRight: spacing(1),
        },
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      root: {
        '&.MuiInput-underline': {
          ':before': {
            borderBottom: '1px solid',
            borderBottomColor: palette.neutral.light,
          },
          input: {
            ...typography.body3md,
            padding: spacing(0.8, 0),
          },
        },
        '&:hover.MuiInput-underline:before': {
          borderBottomColor: palette.neutral.main,
        },
      },
    },
  },
  MuiInputLabel: {
    defaultProps: {
      variant: 'outlined',
    },
    styleOverrides: {
      root: {
        ...typography.body4,
        '& .MuiInputLabel-asterisk': {
          color: palette.error.main,
        },
      },
    },
  },
  MuiInputAdornment: {
    defaultProps: {
      position: 'start',
    },
    styleOverrides: {
      root: {
        color: palette.neutral.main,
        ...typography.body2,
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        color: palette.black,
        '& fieldset': {
          borderColor: palette.neutral.dark,
          '& legend': {
            fontSize: typography.small1.fontSize,
          },
        },
        ':not(.Mui-disabled):not(.Mui-focused):hover': {
          '& fieldset.MuiOutlinedInput-notchedOutline': {
            borderWidth,
            borderColor: palette.primary.light,
          },
        },
        '&.Mui-focused': {
          color: palette.primary.dark,
          input: {
            color: palette.black,
          },
          '& fieldset.MuiOutlinedInput-notchedOutline': {
            borderWidth,
            borderColor: palette.primary.dark,
          },
        },
        '&.Mui-error': {
          input: {
            color: palette.black,
          },
          '& fieldset.MuiOutlinedInput-notchedOutline': {
            borderWidth,
          },
        },
        '.MuiSelect-icon': {
          color: palette.neutral.dark,
        },
        '&.Mui-disabled': {
          backgroundColor: palette.neutral.light,
          '& fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: palette.neutral.dark,
          },
        },
      },
      input: {
        fontSize: typography.body2.fontSize,
        padding: spacing(1.3, 1.4),
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      select: {
        '&.Mui-disabled': {
          backgroundColor: palette.neutral.light,
        },
      },
    },
  },
};
