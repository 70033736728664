import { SolutionSourceImageRepoContainerType } from '../types';

export const SOURCE_IMG_CONTAINER_REPO_TYPE_OPTIONS = [
  {
    value: SolutionSourceImageRepoContainerType.Acr,
    label: 'ACR',
  },
  {
    value: SolutionSourceImageRepoContainerType.Ecr,
    label: 'ECR',
  },
];
