import React from 'react';
import { useParams } from 'react-router-dom';
import DeviceHubOutlined from '@mui/icons-material/DeviceHubOutlined';
import Typography from '@mui/material/Typography';
import CardButton, { CardButtonsContainer } from 'src/components/CardButton';
import { ReactComponent as MenderLogo } from 'src/assets/icons/mender-logo.svg';
import { navigateToExternalUrl } from 'src/utils';
import { useSolutionDetails } from '../redux';
import { MENDER_RELEASES_URL } from './constants';
import { SolutionType } from '../types';

const style = {
  '.MuiTypography-root.MuiTypography-body2': {
    whiteSpace: 'normal',
  },
};

function SolutionDeviceExtResources() {
  const [data] = useSolutionDetails();
  const navigateTo = (link?: string) => link && navigateToExternalUrl(link);
  const { solutionType } = useParams();

  return (
    <>
      <Typography
        data-testid="solution-device-ext-res-header"
        my={2}
        variant="h4"
      >
        External configuration resources
      </Typography>
      <CardButtonsContainer sx={style}>
        <CardButton
          data-testid="solution-device-ext-res-delta-artifcts-link"
          title="Delta Artifacts"
          description="Upload Artifacts, Configure Releases, and Create Deployments"
          icon={MenderLogo}
          onClick={() => navigateTo(MENDER_RELEASES_URL)}
        />
        {solutionType !== SolutionType.ioTH && (
          <CardButton
            data-testid="solution-device-ext-res-device-maintnce-link"
            title="Device maintenance"
            description="Navigate to external IoT Central solution instance"
            icon={DeviceHubOutlined}
            onClick={() => navigateTo(data?.ioTCentralInstance?.url)}
          />
        )}
      </CardButtonsContainer>
    </>
  );
}

export default SolutionDeviceExtResources;
