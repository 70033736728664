import { DependencyList, EffectCallback, Ref, useEffect, useRef } from 'react';

export enum ObserverPosition {
  Bottom = 'bottom',
  Top = 'top',
}

const positionMarginsMap = {
  [ObserverPosition.Bottom]: '-100% 0% 0% 0%',
  [ObserverPosition.Top]: '0% 0% -100% 0%',
};

export const useScrollObserver = <T extends HTMLElement = HTMLDivElement>(
  callback: EffectCallback,
  deps?: DependencyList,
  position?: ObserverPosition
): Ref<T> => {
  const elementRef = useRef(null);
  const config = {
    rootMargin: positionMarginsMap[position || ObserverPosition.Bottom],
    threshold: 0,
  };
  const observerCallback = ([target]: IntersectionObserverEntry[]) => {
    if (!target.isIntersecting) {
      callback();
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, config);
    const { current } = elementRef;

    if (current) {
      observer.observe(current);
    }

    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, deps);

  return elementRef;
};
