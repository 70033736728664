import { useContext } from 'react';
import Box from '@mui/material/Box';
import { SxProps } from 'src/theme/types';
import HeaderContext from '../HeaderContext';
import HeaderMenuGroup from './HeaderMenuGroup';
import HeaderSearch from './HeaderSearch';

const getStyles = ({ backgroundColor, color }): SxProps => ({
  backgroundColor,
  color,
  display: 'flex',
  justifyContent: 'flex-end',
  minHeight: ({ spacing }) => spacing(6),
  px: ({ spacing }) => spacing(1.6),
  borderBottom: 1,
  borderColor: ({ alpha, palette }) => alpha(palette.neutral.main, 0.1),
  zIndex: ({ zIndex }) => zIndex.appBar,
  position: 'sticky',
  top: 0,
});

function HeaderContent() {
  const context = useContext(HeaderContext);

  return (
    <Box data-testid="header" sx={getStyles(context)}>
      <HeaderSearch />
      <HeaderMenuGroup />
    </Box>
  );
}

export default HeaderContent;
