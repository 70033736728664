import { ForwardedRef, forwardRef, useState } from 'react';
import { Box, InputAdornment, Tooltip } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import InfoOutlined from '@mui/icons-material/InfoOutlined';

interface MaskedInputProps {
  onClickPasteIcon?: any;
  showInfoIcon?: boolean;
  infoTitle?: any;
}

function MaskedInput(
  props: MaskedInputProps & TextFieldProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const { onClickPasteIcon, showInfoIcon, infoTitle, ...rest } = props;
  const [masked, setMasked] = useState(true);
  const Icon = masked ? VisibilityOutlinedIcon : VisibilityOffOutlinedIcon;

  const toggleVisibility = () => {
    setMasked((prev) => !prev);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <TextField
        ref={ref}
        variant="outlined"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                data-testid="masked-input-paste-btn"
                disabled={props.disabled}
                onClick={onClickPasteIcon}
                size="small"
                title="Paste"
              >
                <ContentPasteOutlinedIcon />
              </IconButton>
              <IconButton
                data-testid="masked-input-btn"
                disabled={props.disabled}
                onClick={toggleVisibility}
                size="small"
                title={masked ? 'View' : 'Hide'}
              >
                <Icon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={rest?.sx ? { ...rest.sx } : { minWidth: '-webkit-fill-available' }}
        {...props}
        type={masked ? 'password' : 'text'}
      />
      {showInfoIcon && (
        <Tooltip
          title={infoTitle}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: '#24242499',
                color: '#fff',
                border: 'none',
              },
            },
            arrow: {
              sx: {
                '::before': {
                  border: 'none',
                  backgroundColor: '#24242499',
                },
              },
            },
          }}
        >
          <InfoOutlined color="info" sx={{ fontSize: '20px' }} />
        </Tooltip>
      )}
    </Box>
  );
}

export default forwardRef(MaskedInput);
