import { baseTheme } from '../baseTheme';

const { spacing, typography, palette } = baseTheme;

export default {
  MuiAlert: {
    defaultProps: {
      variant: 'filled',
      onClose: () => ({}),
    },
    styleOverrides: {
      root: {
        ...typography.body2,
        borderRadius: 5,
        padding: spacing(1, 1.5),
        width: '100%',
        minHeight: spacing(10),
        '&.MuiAlert-filledSuccess': {
          backgroundColor: palette.success.main,
          border: `${spacing(0.1)} solid`,
          borderColor: palette.success.main,
          color: palette.white,
        },
        '&.MuiAlert-filledNeutral': {
          backgroundColor: palette.grayscale[200],
          border: `${spacing(0.1)} solid`,
          borderColor: palette.neutral.light,
          color: palette.black,
        },
        '& .MuiAlert-icon': {
          padding: spacing(0.4, 0),
          opacity: 0.5,
        },
        '& .MuiAlert-action': {
          paddingTop: 0,
          paddingLeft: spacing(3),
        },
        '& .MuiLink-root': {
          color: 'inherit',
          textDecorationColor: 'inherit',
          fontWeight: 500,
        },
      },
      message: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
  MuiAlertTitle: {
    styleOverrides: {
      root: {
        ...typography.h4,
      },
    },
  },
};
