import { useSelector } from 'react-redux';
import { State } from 'src/redux/types';
import { ApplicationItem, ApplicationRoles } from '../types';

export const useApplicationList = (): [
  ApplicationItem[],
  boolean | undefined
] => {
  const { data, loading } = useSelector(
    (state: State) => state.users.applicationList
  );

  return [data || [], loading];
};

export const useApplicationRoles = (): [
  ApplicationRoles | undefined,
  boolean | undefined
] => {
  const { data, loading } = useSelector(
    (state: State) => state.users.applicationRoles
  );

  return [data || undefined, loading];
};
