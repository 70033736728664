import { useModal } from 'src/hooks/modalHooks';
import { DataTableActions } from 'src/components/DataTable';
import { SolutionSignatureWhitelistItem } from '../../types';
import SignatureWhitelistEditModal from './SignatureWhitelistEditModal';
import SignatureWhitelistDeleteModal from './SignatureWhitelistDeleteModal';
import { useSolutionUserRoles } from '../../hooks';

type Props = {
  item?: SolutionSignatureWhitelistItem;
};

function SignatureWhitelistAction({ item }: Props) {
  const { isSolutionEditor } = useSolutionUserRoles();
  const [DeleteDialog, handleDelete] = useModal(SignatureWhitelistDeleteModal, {
    item,
  });
  const [EditDialog, handleEdit] = useModal(SignatureWhitelistEditModal, {
    item,
    title: 'Edit OS signature',
  });

  return (
    <DataTableActions
      canEdit={isSolutionEditor}
      canDelete={isSolutionEditor}
      onEdit={handleEdit}
      onDelete={handleDelete}
    >
      {EditDialog}
      {DeleteDialog}
    </DataTableActions>
  );
}

export default SignatureWhitelistAction;
