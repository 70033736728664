import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { isRoutePublic } from 'src/router';
import PageWrapper from '../PageWrapper';
import FullScreenLoader from '../FullScreenLoader';

type Props = {
  children: ReactNode;
};

function AppWrapper({ children }: Props) {
  const { pathname } = useLocation();
  const { authState } = useOktaAuth();
  let Wrapper;

  if (isRoutePublic(pathname)) {
    Wrapper = ({ children }) => <>{children}</>;
  } else if (authState?.isAuthenticated) {
    Wrapper = PageWrapper;
  } else {
    Wrapper = FullScreenLoader;
  }

  return <Wrapper data-testid="app-wrapper">{children}</Wrapper>;
}

export default AppWrapper;
