import { generatePath } from 'react-router-dom';
import { createDatasetSlice, ResponseMapping } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import {
  API_SOLUTION_NAMESPACE_RSC_QUOTAS_DETAIL,
  SOLUTION_STORE_NAMESPACE_RSC_QUOTAS_DETAIL,
  SOLUTIONS_STORE,
} from '../constants';
import { mapSolutionNamespaceRscQuotasDetailData } from './utils';

const api = ({ solutionId, clusterId }) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: generatePath(API_SOLUTION_NAMESPACE_RSC_QUOTAS_DETAIL, {
      solutionId,
      clusterId,
    }),
  }).then((response) =>
    mapSolutionNamespaceRscQuotasDetailData(response, clusterId)
  );

const namespaceRscQuotasDetail = createDatasetSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTION_STORE_NAMESPACE_RSC_QUOTAS_DETAIL,
  responseMapping: ResponseMapping.Default,
});

export default namespaceRscQuotasDetail;
