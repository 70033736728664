import { useEffect, useRef } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';

export const useAsyncOktaAuthCheck = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const pendingLogin = useRef(false);

  useEffect(() => {
    const handleLogin = () => {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin
      );

      if (!pendingLogin.current) {
        pendingLogin.current = true;
        oktaAuth.setOriginalUri(originalUri);
        oktaAuth.signInWithRedirect();
      }
    };

    if (authState) {
      if (authState.isAuthenticated) {
        pendingLogin.current = false;
      } else {
        handleLogin();
      }
    }
  }, [authState, oktaAuth]);

  if (!authState || !authState.isAuthenticated) {
    return null;
  }

  return authState.isAuthenticated;
};
