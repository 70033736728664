import { createDatasetSlice, ResponseMapping } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import {
  API_SITE_CLUSTERS,
  SITES_STORE,
  SITES_STORE_CLUSTER_DETAILS,
} from 'src/pages/Sites/constants';
import { generateApiPath } from 'src/utils';

const api = (siteId: string) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: generateApiPath(API_SITE_CLUSTERS, { siteId }),
  });

const sitesClusterOptionsSlice = createDatasetSlice({
  api,
  storeName: SITES_STORE,
  actionName: SITES_STORE_CLUSTER_DETAILS,
  responseMapping: ResponseMapping.Default,
});

export default sitesClusterOptionsSlice;
