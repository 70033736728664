import React from 'react';
import { useSolutionDevicePropertyMeta } from '../../redux';
import { SolutionDeviceGroupFilter } from '../../types';

type Props = {
  item?: SolutionDeviceGroupFilter;
  value?: string;
};

export default function SolutionDeviceGroupFiltersOperatorCell({
  item,
  value,
}: Props) {
  const { operators } = useSolutionDevicePropertyMeta(item?.property);

  return <>{operators?.find((operator) => operator.value === value)?.label}</>;
}
