import React, { useState, useEffect, useRef } from 'react';
import {
  Accordion,
  Box,
  FormControlLabel,
  Switch,
  createTheme,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { RequestMethod, pepApiRequest, useStatefulApi } from 'src/services';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import { generateApiPath } from 'src/utils';
import ModalDialog, {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { useParams } from 'react-router-dom';
import { camelCase } from 'lodash';
import { useStoreUnitActions } from 'src/redux/helpers';
import RulesConditionsCard from './RulesConditionsCard';
import {
  Workflow,
  RuleRequest,
  Rule,
  Filter,
  Condition,
  Querys,
  Operators,
  Aggregations,
} from './interfaces';
import TargetDevicesCard from './TargetDevicesCard';
import ActionCard from './ActionCard';
import solutionsStore, {
  useSolutionDeviceGroupProperties,
  useSolutionRuleTelemtryProperties,
} from '../redux';
import './rule.css';
import {
  API_SOLUTION_RULES_MANAGEMENT,
  API_SOLUTION_RULES_MANAGEMENT_UPDATE,
} from '../constants';
import { SolutionDeviceSpecificRulesManagement } from '../types';
import {
  RULE_TARGET_URL_PATTERN,
  SUBSCRIPTION_SAS_PATTERN,
} from '../SolutionSubscriptions/constants';

const emptyRule = (): Rule => {
  const emptyRule = {
    name: '',
    errorMessage: 'not enough or more co2 level',
    errorType: 0,
    solutionId: '',
    templateId: '',
    enabled: false,
    conditionQuery: [],
    targetDeviceQuery: [],
    actionType: [],
    targetURL: '',
    conditionQueryOperator: '',
    expression: '',
    successEvent: 'Carbon',
    conditions: [],
    timeAggregation: false,
    timeAggregationValueInMinutes: 0,
    filters: [],
    telemetry: '',
    SasToken: '',
  };
  return emptyRule;
};
const emptyRuleRequest = (): RuleRequest => {
  const emptyRule = {
    name: '',
    errorMessage: 'not enough or more co2 level',
    errorType: 0,
    solutionId: '',
    templateId: '',
    enabled: false,
    conditionQuery: [],
    targetDeviceQuery: [],
    actionType: [],
    targetUrl: '',
    conditionQueryOperator: '',
    expression: '',
    successEvent: 'Carbon',
    SasToken: '',
    timeAggregation: false,
    timeAggregationValueInMinutes: 0,
    telemetry: '',
  };
  return emptyRule;
};
const clearRuleForm = (prevRule: Rule): Rule => {
  const clearRuleForm = {
    ...prevRule,
    ErrorType: 0,
    RuleExpressionType: '',
    Filters: [],
    Conditions: [],
    expression: '',
    successEvent: 'Carbon',
    errorMessage: 'not enough or more co2 level',
    telemetry: '',
  };
  return clearRuleForm;
};
type Props = {
  item: SolutionDeviceSpecificRulesManagement;
  editRuleValue: boolean;
  open: boolean;
  onClose: ModalCloseHandler;
};

function RuleForm({ item, editRuleValue, open, onClose }: Props) {
  const firstInputRef = useRef<HTMLInputElement>(null);
  const [workflow, setWorkflow] = useState<Workflow>({
    Rules: [emptyRule()],
  });

  const [error, setError] = useState<string | null>(null);
  const [enableValue, setEnableValue] = useState(item?.Enabled || false);
  const [ruleName, setRuleName] = useState<string | null>(item?.Name || '');
  const { solutionId } = useParams();
  const [properties, propertiesLoading] = useSolutionDeviceGroupProperties();
  const isEditRuleModal = editRuleValue;
  const ruleId: string = item ? item.Id : '';
  const [telemetryValues, telemetryLoading] =
    useSolutionRuleTelemtryProperties();

  const actions = useStoreUnitActions(solutionsStore);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        // Scroll to the top of the modal content
        const modalContent = document.querySelector('.MuiDialogContent-root');
        if (modalContent) {
          modalContent.scrollTop = 0;
        }

        // Focus on the first input field
        firstInputRef.current?.focus();
      }, 0);
    }
  }, [open]);

  useEffect(() => {
    if (isEditRuleModal) {
      setWorkflow({ Rules: [editRuleData()] });
    }
  }, [isEditRuleModal]);

  const fetchTargetFilter = (item: SolutionDeviceSpecificRulesManagement) => {
    const filterArray: Filter[] = [];
    const targetInfo = camelizeKeys(item.TargetDeviceInfo);
    targetInfo.forEach((filter) => {
      const data: Filter = {
        Property: filter.property.id,
        Condition: filter.operatorValue,
        Value: filter.filterValue,
        ValueLabel: filter.filterValue,
      };
      data.Property = filter.property.id;
      data.Value = filter.filterValue;
      data.Condition = filter.operatorValue;
      filterArray.push(data);
    });
    return filterArray;
  };

  const camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => camelizeKeys(v));
    }
    if (obj != null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };
  const fetchConditonFilter = (
    item: SolutionDeviceSpecificRulesManagement
  ): any => {
    const conditionArray: Condition[] = [];
    const ConditionInfo = camelizeKeys(item.ConditionQueryInfo);
    ConditionInfo.forEach((cond) => {
      const conditionQuery = telemetryValues?.filter(
        (property) => property?.id === cond.property.id
      )[0];
      const data: Condition = {
        Telemetry: cond.property.id,
        Operator: cond.operatorValue,
        Value: cond.filterValue,
        Aggregation: cond.aggregationValue,
        ValueLabel: cond.filterValue,
        IsValue: cond.isValue,
        dropDownValue:
          conditionQuery?.schema === 'Enum' && !cond.IsValue
            ? conditionQuery.enums?.find((e) => e.label === cond.filterValue)
                ?.value
            : '',
        ValueType: cond.IsValue ? 'text' : 'dropdown',
      };
      data.Telemetry = cond.property.id;
      data.Value = cond.filterValue;
      data.Operator = cond.operatorValue;
      data.Aggregation = cond.aggregationValue;
      data.IsValue = cond.isValue;
      data.ValueType = cond.isValue ? 'text' : 'dropdown';
      data.dropDownValue =
        conditionQuery?.schema === 'Enum' && !cond.IsValue
          ? conditionQuery.enums?.find((e) => e.label === cond.filterValue)
              ?.value
          : '';
      conditionArray.push(data);
    });
    return conditionArray;
  };

  const editRuleData = (): Rule => {
    const editRuleData = {
      name: item.Name,
      errorMessage: item.ErrorMessage,
      solutionId: item.SolutionId,
      templateId: item.TemplateId,
      enabled: item.Enabled,
      conditionQuery: item.ConditionQueryInfo,
      targetDeviceQuery: item.TargetDeviceInfo,
      actionType: item.ActionType.map((v) => v.toLowerCase()),
      targetURL: item.TargetUrl,
      conditionQueryOperator: item.ConditionQueryOperator,
      expression: item.Expression,
      successEvent: item.SuccessEvent,
      conditions: fetchConditonFilter(item),
      timeAggregation: item.TimeAggregation,
      timeAggregationValueInMinutes: item.TimeAggregationValueInMinutes,
      filters: fetchTargetFilter(item),
      telemetry: '',
      SasToken: item.SasToken,
      errorType: 0,
    };
    return editRuleData;
  };

  useEffect(() => {
    handleGenericRuleChange(0, 'solutionId')(solutionId);
  }, [solutionId]);

  const fetchConditonQuery = (ruleRequest: Rule): any => {
    const querys: Querys[] = [];

    ruleRequest.conditions.forEach((filter: Condition) => {
      const data: Querys = {
        property: {
          component: '',
          contentType: '',
          displayName: '',
          enums: [],
          id: '',
          moduleName: '',
          name: '',
          operators: [],
          path: '',
          schema: '',
          aggregationFunctions: [],
          writable: false,
        },
        filterValue: '',
        operatorValue: Operators.equals,
        aggregationValue: Aggregations.Average,
        IsValue: true,
      };
      const conditionQuery = telemetryValues?.filter(
        (property) => property?.id === filter?.Telemetry
      )[0];

      data.property = conditionQuery;
      data.filterValue =
        conditionQuery.schema === 'Enum' && filter.ValueType === 'dropdown'
          ? conditionQuery.enums?.find(
              (e) => e.value === Number(filter.dropDownValue)
            )?.label
          : filter.Value;
      data.operatorValue = filter.Operator;
      data.aggregationValue = filter.Aggregation;
      data.IsValue = !(filter?.ValueType && filter?.ValueType === 'dropdown');
      querys.push(data);
    });
    return querys;
  };

  const fetchTargetQuery = (ruleRequest: Rule): any => {
    const querys: Querys[] = [];
    ruleRequest.filters.forEach((filter) => {
      const data: Querys = {
        property: {
          component: '',
          contentType: '',
          displayName: '',
          enums: [],
          id: '',
          moduleName: '',
          name: '',
          operators: [],
          path: '',
          schema: '',
          aggregationFunctions: [],
          writable: false,
        },
        filterValue: '',
        operatorValue: Operators.equals,
      };
      const targetDeviceQuery = properties?.filter(
        (property) => property?.id === filter?.Property
      )[0];
      data.property = targetDeviceQuery;
      data.filterValue = filter.Value;
      data.operatorValue = filter.Condition;
      querys.push(data);
    });
    return querys;
  };
  const { showAlert } = useAlert();

  const theme = createTheme();
  theme.typography.fontFamily = 'Stolzl';
  theme.typography.body1.fontSize = '2rem';
  theme.typography.body2.fontSize = '1.8rem';

  const handleGenericRuleChange =
    (index: number, field: keyof Rule) => (value: any) => {
      setWorkflow((prevWorkflow) => ({
        ...prevWorkflow,
        Rules: prevWorkflow.Rules.map((rule, i) =>
          i === index ? { ...rule, [field]: value } : rule
        ),
      }));
    };

  const handleTemplateChange = (index: number) => (value: any) => {
    handleGenericRuleChange(index, 'templateId')(value);
    handleClearRuleMetadata(index);
  };
  const handleClearRuleMetadata = (index: number) => {
    setWorkflow((prevWorkflow) => ({
      ...prevWorkflow,
      Rules: prevWorkflow.Rules.map((rule, i) =>
        i === index ? clearRuleForm(rule) : rule
      ),
    }));
  };

  const buildExpression = (rule: Rule, index: number) => {
    const deviceFilters = rule.filters
      .map((filter: Filter) => {
        const value = `'input.' + ${filter.Property} + ' ' + ${filter.Condition} + ' ' + ${filter.Value}`;
        return value;
      })
      .join(' AND ');
    const conditions = rule.conditions
      .map((condition) => {
        const prefix = rule.timeAggregation
          ? `'input.' + ${condition.Aggregation}`
          : 'input.';
        return `${prefix} + ${condition.Telemetry} + ' ' + ${condition.Operator} + ' ' + ${condition.Value}`;
      })
      .join(`' ' + ${rule.conditionQueryOperator} + ' '`);
    let expression;
    if (deviceFilters) {
      expression = deviceFilters;
      if (conditions) {
        expression = `${expression} + ' AND ' +${conditions}`;
      }
    } else {
      expression = conditions;
    }
    rule.expression = expression;
    handleGenericRuleChange(index, 'expression')(rule.expression);
    return rule.expression;
  };

  const checkFiltersHaveValue = (filters: Filter[]) => {
    let isValid = true;
    filters.forEach((filter) => {
      if (filter.Condition === undefined) isValid = false;
      if (filter.Property === undefined || filter.Property === '')
        isValid = false;
      if (filter.Value === undefined || filter.Value === '') isValid = false;
    });
    return isValid;
  };
  const checkConditionsHaveValue = (conditions: Condition[]) => {
    let isValid = true;
    if (conditions.length === 0) isValid = false;
    conditions.forEach((filter) => {
      if (filter.Operator === undefined) isValid = false;
      if (filter.Telemetry === undefined || filter.Telemetry === '')
        isValid = false;
      if (filter.ValueType === 'dropdown' && filter.dropDownValue === '')
        isValid = false;
      if (
        filter.ValueType !== 'dropdown' &&
        (filter.Value === undefined || filter.Value === '')
      )
        isValid = false;
    });
    return isValid;
  };
  const checkValidFlow = (rule: Rule, index: number) => {
    if (
      !(
        rule.actionType.length !== 0 &&
        rule.name &&
        rule.conditionQueryOperator
      )
    ) {
      return false;
    }
    if (!checkConditionsHaveValue(rule.conditions)) {
      return false;
    }
    if (!checkFiltersHaveValue(rule.filters)) {
      return false;
    }

    if (rule.timeAggregation && rule.timeWindow === 0) return false;
    if (rule.actionType.includes('targeturl') && rule.SasToken === '')
      return false;
    if (rule.actionType.includes('targeturl') && rule.targetURL === '')
      return false;
    return true;
  };

  const checkFormatValidFlow = (rule: Rule, index: number) => {
    if (
      rule.targetURL &&
      rule.targetURL.match(RULE_TARGET_URL_PATTERN) === null
    )
      return false;
    if (
      rule.actionType.includes('targeturl') &&
      rule.SasToken &&
      rule.SasToken.match(SUBSCRIPTION_SAS_PATTERN) === null
    )
      return false;
    return true;
  };
  const validateExpression = (ruleExpression: string) => {
    try {
      setError(null);
      const logicExpressionRegex = /^.+$/;
      const isValidExpression = logicExpressionRegex.test(ruleExpression);
      return isValidExpression;
    } catch (error) {
      setError('Invalid Expression');
      return false;
    }
  };

  const [onSubmit, loading] = useStatefulApi(
    ({ companyId, ...params }) => {
      setError(null);
      const workflowCopy = { ...workflow };
      const isWorkflowValid = workflowCopy.Rules.map((rule, index) =>
        buildExpression(rule, index)
      ).every((expression) => validateExpression(expression));

      const isValidWorkflow = workflowCopy.Rules.map((rule, index) =>
        checkValidFlow(rule, index)
      );
      const isValidFormatWorkflow = workflowCopy.Rules.map((rule, index) =>
        checkFormatValidFlow(rule, index)
      );
      if (!isValidFormatWorkflow[0]) {
        return setError(
          'Please enter Target URL and SAS token in expected format.'
        );
      }
      if (!isWorkflowValid || !isValidWorkflow[0]) {
        return setError('Please enter all the required fields.');
      }

      const rule: RuleRequest = emptyRuleRequest();
      workflow.Rules.forEach((ruleRequest) => {
        rule.actionType = ruleRequest.actionType;
        rule.enabled = ruleRequest.enabled;
        rule.conditionQuery = fetchConditonQuery(ruleRequest);
        rule.errorMessage = ruleRequest.errorMessage;
        rule.errorType = ruleRequest.errorType;
        rule.targetUrl = ruleRequest.targetURL;
        rule.name = ruleRequest.name;
        rule.expression = ruleRequest.expression;
        rule.successEvent = ruleRequest.successEvent;
        rule.solutionId = ruleRequest.solutionId;
        rule.templateId = ruleRequest.templateId;
        rule.targetDeviceQuery = fetchTargetQuery(ruleRequest);
        rule.timeAggregation = ruleRequest.timeAggregation;
        rule.timeAggregationValueInMinutes = ruleRequest.timeWindow!;
        rule.conditionQueryOperator = ruleRequest.conditionQueryOperator;
        rule.SasToken = ruleRequest.SasToken;
        rule.conditionQueryOperator = ruleRequest.conditionQueryOperator;
      });

      let apiRequest;
      if (isEditRuleModal) {
        apiRequest = pepApiRequest({
          method: RequestMethod.Put,
          url: generateApiPath(API_SOLUTION_RULES_MANAGEMENT_UPDATE, {
            ruleId,
          }),
          params: rule,
        });
      } else {
        apiRequest = pepApiRequest({
          method: RequestMethod.Post,
          url: generateApiPath(API_SOLUTION_RULES_MANAGEMENT, {}),
          params: rule,
        });
      }
      return apiRequest;
    },
    () => {
      showAlert({
        type: AlertType.Success,
        title: isEditRuleModal
          ? 'Rule is Updated'
          : 'New rule has been created',
        text: isEditRuleModal
          ? 'Rule has been successfully Updated'
          : 'Rule has been successfully created.',
      });

      onClose(null, ModalCloseReason.completeAction);
      actions.rulesManagement(solutionId);
    },
    (error: any) => {
      if (error.status !== 400)
        showAlert({
          type: AlertType.Error,
          title: isEditRuleModal ? 'Rule update Error' : 'Rule Creation Error',
          text: isEditRuleModal
            ? 'Error on updating Rule'
            : 'Error on creation of Rule',
        });
    }
  );

  return (
    <ModalDialog
      open={open}
      title={isEditRuleModal ? 'Edit Rule' : 'Create a new Rule'}
      onClose={onClose}
      onConfirm={onSubmit}
      confirmBtnText="Save"
      maxWidth="lg"
      loading={error != null ? false : loading}
      fullWidth
    >
      {/* <ThemeProvider theme={theme}>
        <Stack> */}
      <form className="mb-6">
        {error && (
          <div
            style={{
              fontSize: '2rem',
              paddingBottom: '1rem',
              color: '#DB1E36',
            }}
          >
            {error}
          </div>
        )}
        {workflow.Rules.map((rule, index) => (
          <div key={index}>
            <Box position="relative" minHeight="50rem">
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <TextField
                  error={error !== null && ruleName === null}
                  id={`RuleName${index}`}
                  name="Name"
                  value={ruleName}
                  onChange={(event) => {
                    setRuleName(event?.target.value);
                    handleGenericRuleChange(index, 'name')(event?.target.value);
                  }}
                  label="Name"
                  placeholder="Enter the name of the rule"
                  sx={{
                    width: ({ spacing }) => spacing(65),
                  }}
                  style={{ marginRight: '2rem', color: 'red' }}
                  required={true}
                  inputRef={index === 0 ? firstInputRef : null}
                  InputLabelProps={{ shrink: true }}
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={enableValue}
                      onChange={(event) => {
                        setEnableValue(event.target.checked);
                        handleGenericRuleChange(
                          index,
                          'enabled'
                        )(event?.target.checked);
                      }}
                    />
                  }
                  label="Enable"
                  sx={{ fontSize: '16px' }}
                />
              </div>
              <Accordion
                sx={{
                  marginTop: '25px',
                  borderTopLeftRadius: '5px',
                  borderTopRightRadius: '5px',
                  boxShadow: 'none',
                  '& .MuiAccordion-root': {
                    marginBottom: '20px',
                    borderRadius: '0',
                    borderBottomLeftRadius: '5px',
                    borderBottomRightRadius: '5px',
                    boxShadow: '0 1px 1px 2px rgba(0, 0, 0, 0.1)',
                  },
                }}
              >
                <div>
                  <TargetDevicesCard
                    item={item}
                    rule={rule}
                    isEditRuleModal={isEditRuleModal}
                    error={error}
                    filters={rule.filters}
                    onChangeTemplate={handleTemplateChange(index)}
                    updateFilters={handleGenericRuleChange(index, 'filters')}
                  />
                  <RulesConditionsCard
                    item={item}
                    rule={rule}
                    isEditRuleModal={isEditRuleModal}
                    error={error}
                    conditionQueryOperator={rule?.conditionQueryOperator}
                    onChangeTriggerTheRuleIf={handleGenericRuleChange(
                      index,
                      'conditionQueryOperator'
                    )}
                    timeAggregation={rule?.timeAggregation}
                    onChangeTimeAggregation={handleGenericRuleChange(
                      index,
                      'timeAggregation'
                    )}
                    timeWindow={rule?.timeWindow}
                    onChangeTimeWindow={handleGenericRuleChange(
                      index,
                      'timeWindow'
                    )}
                    conditions={rule.conditions}
                    updateConditions={handleGenericRuleChange(
                      index,
                      'conditions'
                    )}
                  />

                  <ActionCard
                    rule={rule}
                    isEditRuleModal={isEditRuleModal}
                    error={error}
                    actionType={handleGenericRuleChange(index, 'actionType')}
                    actionTargetURL={handleGenericRuleChange(
                      index,
                      'targetURL'
                    )}
                    actionSASToken={handleGenericRuleChange(index, 'SasToken')}
                  />
                </div>
              </Accordion>
            </Box>
          </div>
        ))}
      </form>
    </ModalDialog>
  );
}

export default RuleForm;
