import * as Yup from 'yup';
import Select from 'src/components/Select';
import { SOLUTION_DEVICE_AUTHORISE_OPTIONS } from 'src/pages/Solutions/SolutionDevices/constants';
import { SolutionDeviceItemField } from '../../types';

export const solutionDevicesAuthoriseField = [
  {
    name: SolutionDeviceItemField.MenderStatus,
    label: 'Status',
    placeholder: 'Select a value',
    Component: Select,
    options: SOLUTION_DEVICE_AUTHORISE_OPTIONS,
    validation: Yup.string().required('Status is required'),
    fullWidth: true,
    required: true,
  },
];
