import { createDatasetSlice } from 'src/redux/helpers';
import { RequestMethod, pepApiRequest } from 'src/services';
import { generateApiPath } from 'src/utils';
import {
  API_SOLUTION_DASHBOARD_DETAILS,
  SOLUTIONS_STORE,
  SOLUTIONS_STORE_DASHBOARDS,
} from '../constants';
import { mapSolutionDashboardsData } from './utils';

const api = (solutionId: string) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: generateApiPath(API_SOLUTION_DASHBOARD_DETAILS, {
      solutionId,
    }),
  }).then((items) => mapSolutionDashboardsData(items));

const solutionsDashboardsSlice = createDatasetSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTIONS_STORE_DASHBOARDS,
});

export default solutionsDashboardsSlice;
