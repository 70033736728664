import isArray from 'lodash/isArray';
import { addOrRemoveValFromArr } from 'src/utils';
import { Option, OptionValue } from 'src/components/SelectOption';

export const isSelected = <T>(value: T, selectedValue?: T | T[]) => {
  if (isArray(selectedValue)) {
    return selectedValue.includes(value);
  }
  return selectedValue === value;
};

export const getUpdatedSelection = <T>(prevValue: T | T[], value: T) =>
  isArray(prevValue) ? addOrRemoveValFromArr(prevValue, value) : value;

export const getSelectOptions = (values: OptionValue[]): Option[] =>
  values.map((value) => ({ value, label: value.toString() }));

export const getDisplayValue = (
  options: Option[],
  value?: OptionValue
): string => options.find((option) => option.value === value)?.label ?? '';

export const getSearchResult = <T extends OptionValue>(
  searchStr: string,
  options: Option<T>[]
): Option<T>[] => {
  const searchStrLowerCase = searchStr.toLowerCase();
  const findSearchStr = (value: string | number) =>
    value.toString().toLowerCase().includes(searchStrLowerCase);

  return options?.filter(
    ({ label, value }) => findSearchStr(label) || findSearchStr(value)
  );
};
