import React from 'react';
import Badge from 'src/components/Badge';
import { SolutionDockerImagesStatus as Status } from '../../types/solutionEdgeWorkload';

type StatusMapItem = {
  color?: 'success' | 'info' | 'error' | 'default';
  label: string;
};

type Props = {
  value?: Status;
};

function SolutionDockerImagesStatus({ value = Status.Succeeded }: Props) {
  const statusMap: Record<Status, StatusMapItem> = {
    [Status.InProgress]: { color: 'info', label: 'InProgress' },
    [Status.Succeeded]: { color: 'success', label: 'Succeeded' },
    [Status.Failed]: { color: 'error', label: 'Failed' },
  };

  return <Badge {...statusMap[value]} />;
}

export default SolutionDockerImagesStatus;
