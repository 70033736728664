import { createDatasetSlice, ResponseMapping } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import { generateApiPath, loadDataFromStorage } from 'src/utils';
import {
  API_DT_DEVICE_MODEL_ITEM,
  API_DT_CONTEXT_MODEL_ITEM,
  SOLUTIONS_STORE,
  SOLUTIONS_STORE_DEVICE_CONFIG,
  API_DT_CONTEXT_MODEL_ITEM_IOTH,
  API_DT_DEVICE_MODEL_ITEM_IOTH,
} from '../constants';
import { SolutionDeviceConfigModelType, SolutionType } from '../types';

const api = ({ solutionId, modelId, modelType }) => {
  const solutionType = loadDataFromStorage('solutionType');
  if (solutionType.toUpperCase() === SolutionType.ioTH.toUpperCase()) {
    return pepApiRequest({
      method: RequestMethod.Get,
      url: generateApiPath(
        modelType === SolutionDeviceConfigModelType.ContextModel
          ? API_DT_CONTEXT_MODEL_ITEM_IOTH
          : API_DT_DEVICE_MODEL_ITEM_IOTH,
        { solutionId, modelId, companyId: '1' }
      ),
    }).then((resp) => {
      let dataResponse;
      if( resp.deploymentManifest ) {
        const manifest = JSON.parse(resp.deploymentManifest)
        const depManifest = [manifest]
        dataResponse = [...resp.rootDigitalTwinsModels, ...depManifest];
      } else {
        dataResponse = resp.rootDigitalTwinsModels;
      }
      return dataResponse;
    });
  }
  return pepApiRequest({
    method: RequestMethod.Get,
    url: generateApiPath(
      modelType === SolutionDeviceConfigModelType.ContextModel
        ? API_DT_CONTEXT_MODEL_ITEM
        : API_DT_DEVICE_MODEL_ITEM,
      { solutionId, modelId }
    ),
  }).then((resp) => resp);
};
const solutionDeviceConfigSlice = createDatasetSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTIONS_STORE_DEVICE_CONFIG,
  responseMapping: ResponseMapping.Default,
});

export default solutionDeviceConfigSlice;
