import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getActionsColConfig } from 'src/components/DataTable';
import { useStoreUnitActions } from 'src/redux/helpers';
import {
  SolutionEdgeWorkloadItem,
  SolutionEdgeWorkloadItemField,
  SolutionEdgeWorkloadRepositoryType,
  SolutionEdgeWorkloadStatus as StatusType,
} from 'src/pages/Solutions/types';
import SolutionEdgeWorkloadActions from './components/SiteEdgeWorkloadActions';
import SolutionEdgeWorkloadRepositoryList from './components/SiteEdgeWorkloadRepositoryList';
import SolutionEdgeWorkloadStatus from './components/SiteEdgeWorkloadStatus';
import sitesStore, { useSiteEdgeWorkloadConfigList } from '../redux';

const columns = [
  {
    id: SolutionEdgeWorkloadItemField.Name,
    title: 'Name',
  },
  {
    id: SolutionEdgeWorkloadItemField.Description,
    title: 'Description',
  },
  {
    id: SolutionEdgeWorkloadItemField.RepositoryURL,
    title: 'Repository URL',
  },
  {
    id: SolutionEdgeWorkloadItemField.BranchName,
    title: 'Branch',
  },
  {
    id: SolutionEdgeWorkloadItemField.Path,
    title: 'Path',
  },
  {
    id: SolutionEdgeWorkloadItemField.DeploymentStatusData,
    title: 'Status',
    Component: SolutionEdgeWorkloadStatus,
  },

  getActionsColConfig(SolutionEdgeWorkloadActions),
];

function SiteConfigurations() {
  const { siteId } = useParams();
  const [data, loading] = useSiteEdgeWorkloadConfigList();
  const siteEdgeData: SolutionEdgeWorkloadItem[] = [];
  data?.map((name) => {
    const item: SolutionEdgeWorkloadItem = {
      ...name,
      deploymentStatusData: JSON.stringify({
        status:
          name.deploymentStatus === 'Deployment Paused'
            ? name.deploymentStatus.replace(
                'Deployment Paused',
                StatusType.paused
              )
            : name.deploymentStatus,
        errorMsg: name.deploymentMessage,
      }),
    };
    siteEdgeData.push(item);
    return null;
  });
  const actions = useStoreUnitActions(sitesStore);

  useEffect(() => {
    actions.edgeWorkloadConfigurations(siteId);
  }, [siteId]);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          style={{
            fontSize: '18px',
            marginTop: '10px',
            fontFamily: 'Stolzl',
            color: '#242424',
          }}
        >
          PEPsense Core and Common
        </Typography>
      </Box>
      <br />
      <SolutionEdgeWorkloadRepositoryList
        title="Git repository"
        data={siteEdgeData}
        columns={columns}
        repositoryType={SolutionEdgeWorkloadRepositoryType.PepsenseEdge}
        loading={loading}
      />
    </>
  );
}

export default SiteConfigurations;
