import React from 'react';
import Button from '@mui/material/Button';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import NoContentWrapper from './components/NoContentWrapper';

function NoContent() {
  const { showAlert } = useAlert();

  return (
    <NoContentWrapper
      data-testid="no-content"
      title="An error occured"
      subtitle="The page content is currently under construction, please try later."
    >
      <Button
        variant="contained"
        onClick={() =>
          showAlert({
            type: AlertType.Error,
            text: 'The page content is still under construction',
          })
        }
      >
        Retry
      </Button>
    </NoContentWrapper>
  );
}

export default NoContent;
