import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useStoreUnitActions } from 'src/redux/helpers';
import ModalDialog from 'src/components/ModalDialog';
import { useForm } from 'src/components/Form';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { generateApiPath, loadDataFromStorage } from 'src/utils';
import { mapDevicePropertiesToRequestFormat } from '../utils';
import { SolutionDeviceItem, SolutionType } from '../../types';
import solutionsStore, { useSolutionDeviceObservProperties } from '../../redux';
import {
  API_DEVICES_OBSERVABILITY_CONFIG,
  API_DEVICES_OBSERVABILITY_CONFIG_IOTH_PUT,
} from '../../constants';
import { getDeviceObservPropsFormFields } from '../formFields';

type Props = {
  item: SolutionDeviceItem;
  open: boolean;
  onClose: () => void;
};

function SolutionDeviceObservabilityConfigModal({
  item,
  open,
  onClose,
}: Props) {
  const { showAlert } = useAlert();
  const { solutionId } = useParams();
  const actions = useStoreUnitActions(solutionsStore);
  const deviceId = item?.id;
  const companyId = item?.companyId;
  const [data, loading] = useSolutionDeviceObservProperties();

  const [modifyProperties, uploading] = useStatefulApi(
    (params) => {
      const solutionType = loadDataFromStorage('solutionType');
      if (solutionType.toUpperCase() === SolutionType.ioTH.toUpperCase()) {
        return pepApiRequest({
          method: RequestMethod.Put,
          url: generateApiPath(API_DEVICES_OBSERVABILITY_CONFIG_IOTH_PUT, {
            solutionId,
            companyId,
            deviceId,
          }),
          params: mapDevicePropertiesToRequestFormat(params),
        });
      }
      return pepApiRequest({
        method: RequestMethod.Put,
        url: generateApiPath(API_DEVICES_OBSERVABILITY_CONFIG, {
          solutionId,
          companyId,
          deviceId,
        }),
        params: mapDevicePropertiesToRequestFormat(params),
      });
    },
    () => {
      showAlert({
        type: AlertType.Success,
        title: 'Modifications Started Successfully ',
        text: 'Device Observability Configurations are being modified to the devices selected',
      });
      onClose();
    }
  );

  const [form, handleSubmit] = useForm({
    fields: getDeviceObservPropsFormFields(),
    values: data,
    onSubmit: modifyProperties,
  });

  useEffect(() => {
    actions.deviceObservProperties({ solutionId, companyId, deviceId });
  }, [solutionId, deviceId]);

  return (
    <ModalDialog
      open={open}
      title="Modify Observability Configurations"
      loading={loading || uploading}
      onClose={onClose}
      onConfirm={handleSubmit}
      confirmBtnText="Modify"
      maxWidth="sm"
      fullWidth
    >
      {form}
    </ModalDialog>
  );
}

export default SolutionDeviceObservabilityConfigModal;
