import { generatePath, useParams } from 'react-router-dom';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import ModalDialog, {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { API_SOLUTION_SITES_DELETE } from '../../constants';

type Props = {
  id: string;
  name: string;
  open: boolean;
  onClose: ModalCloseHandler;
};

function SolutionSiteDeleteModal({ id, name, open, onClose }: Props) {
  const { showAlert } = useAlert();
  const { solutionId } = useParams();
  const [handleRemove, loading] = useStatefulApi(
    (params) =>
      pepApiRequest({
        method: RequestMethod.Put,
        url: generatePath(API_SOLUTION_SITES_DELETE, { solutionId }),
        params: { SiteIds: [id] },
      }),
    () => {
      showAlert({
        type: AlertType.Info,
        title: `${name} is deleted.`,
        text: 'Site has been deleted successfully.',
      });
      onClose(null, ModalCloseReason.completeAction);
    }
  );

  return (
    <ModalDialog
      open={open}
      title="Delete Site"
      loading={loading}
      onClose={onClose}
      onConfirm={handleRemove}
      cancelBtnText="No"
      confirmBtnText="Yes"
    >
      Are you sure you want to delete this site from solution?
      <br />
      <b> {name} </b>
    </ModalDialog>
  );
}

export default SolutionSiteDeleteModal;
