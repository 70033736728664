import { useEffect } from 'react';
import { generatePath } from 'react-router-dom';
import ModalDialog, {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { useAlert, AlertType } from 'src/components/AlertsProvider';
import { useForm } from 'src/components/Form';
import { useStoreUnitActions } from 'src/redux/helpers';
import { EditApplicationFormFields } from '../formFields';
import { ApplicationItem } from '../../types';
import { API_APPLICATION_EDIT_ROLES } from '../../constants';
import usersStore, { useApplicationRoles } from '../../redux';

type Props = {
  item: ApplicationItem;
  open: boolean;
  onClose: ModalCloseHandler;
};

function ApplicationListEditModal({ item, open, onClose }: Props) {
  const actions = useStoreUnitActions(usersStore);
  useEffect(() => {
    actions.applicationRoles();
  }, []);

  const id = item?.id;

  const { showAlert } = useAlert();
  const [onSubmit, loading] = useStatefulApi(
    (params) =>
      pepApiRequest({
        method: RequestMethod.Put,
        url: generatePath(API_APPLICATION_EDIT_ROLES, { id }),
        params,
      }),
    () => {
      showAlert({
        type: AlertType.Success,
        title: 'Application is updated',
        text: 'Application has been successfully updated.',
      });
      onClose(null, ModalCloseReason.completeAction);
      actions.applicationList();
    }
  );
  const [data] = useApplicationRoles();
  let options: any;
  if (data) {
    options = data[0].applicationRoleInformation.map((item) => {
      const { name: value, description: label, ...rest } = item;
      return { value, label, ...rest };
    });
  }
  const [form, handleSubmit] = useForm({
    fields: EditApplicationFormFields(options, item?.AsignRoles),
    values: item,
    onSubmit,
  });

  return (
    <ModalDialog
      open={open}
      title={`Edit Application: ${item?.id}`}
      loading={loading}
      onClose={onClose}
      onConfirm={handleSubmit}
      confirmBtnText="Submit"
      maxWidth="sm"
      fullWidth
    >
      {form}
    </ModalDialog>
  );
}

export default ApplicationListEditModal;
