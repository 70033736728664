import React, { useContext } from 'react';
import MuiTabs, { TabsProps } from '@mui/material/Tabs';
import { Context } from './TabsContext';

function Tabs(props: TabsProps) {
  const { index, onChange } = useContext(Context);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    onChange(newValue);
  };

  return <MuiTabs onChange={handleChange} value={index} {...props} />;
}

export default Tabs;
