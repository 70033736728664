import { useParams } from 'react-router-dom';
import ModalDialog, {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { generateApiPath } from 'src/utils';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import { useStoreUnitActions } from 'src/redux/helpers';
import {
  API_SOLUTION_UPDATE_STATUS,
  API_SOLUTION_UPDATE_STATUS_IOTH,
} from '../../constants';
import solutionsStore from '../../redux';
import { SolutionType } from '../../types';

type Props = {
  open: boolean;
  onClose: ModalCloseHandler;
};

function SolutionSuspendModal({ open, onClose }: Props) {
  const { solutionId, solutionType } = useParams();
  const { showAlert } = useAlert();
  const actions = useStoreUnitActions(solutionsStore);

  const [handleSubmit, loading] = useStatefulApi(
    () =>
      pepApiRequest({
        method: RequestMethod.Put,
        url: generateApiPath(
          solutionType === SolutionType.ioTH
            ? API_SOLUTION_UPDATE_STATUS_IOTH
            : API_SOLUTION_UPDATE_STATUS,
          {
            solutionId,
          }
        ),
        params: {
          status: 'Suspended',
        },
      }),
    () => {
      showAlert({
        type: AlertType.Warning,
        title: 'Solution has been Suspended',
        text: 'Solution will lead to deletion state after the retention period',
      });
      onClose(null, ModalCloseReason.completeAction);
      actions.details(solutionId);
    }
  );

  return (
    <>
      <ModalDialog
        open={open}
        title="Suspend Solution"
        onClose={onClose}
        onConfirm={handleSubmit}
        loading={loading}
        cancelBtnText="Cancel"
        confirmBtnText="Suspend"
      >
        <b> Are you sure you want to suspend this solution ?</b>
        <br></br>
        <br></br>
        <b>Note:</b>
        {solutionType === SolutionType.Industrial ? (
          <>
            When the suspension is initiated, the universal edge associated with
            this solution is disabled until the retention period expires. After
            completing the retention period, a suspended solution will move to
            the deleted state, which is irreversible{' '}
          </>
        ) : (
          <>
            When the suspension is initiated, the devices associated with this
            solution are disabled until the retention period expires. After
            completing the retention period, a suspended solution will move to
            the deleted state, which is irreversible
          </>
        )}
      </ModalDialog>
    </>
  );
}

export default SolutionSuspendModal;
