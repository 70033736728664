import { SolutionNamespaceRscLimitItem } from '../types';

export const getSetClusterNamespaceRscLimitParams = (
  params: SolutionNamespaceRscLimitItem,
  clusterId: string | undefined,
  clusterName: string | undefined
) => {
  const newParams = {
    clusterId,
    clusterName,
    requestsCpu: params.requestsCpu?.concat('m'),
    requestsMemory: params.requestsMemory?.concat('Mi'),
    limitsCpu: params.limitsCpu?.concat('m'),
    limitsMemory: params.limitsMemory?.concat('Mi'),
    requestsStorage: params.requestsStorage?.concat('Mi'),
  };

  return newParams;
};

export const getSetNamespaceRscLimitParams = (
  params: SolutionNamespaceRscLimitItem
) => {
  const newParams = {
    requestsCpu: params.requestsCpu?.concat('m'),
    requestsMemory: params.requestsMemory?.concat('Mi'),
    limitsCpu: params.limitsCpu?.concat('m'),
    limitsMemory: params.limitsMemory?.concat('Mi'),
    limitsGpu: params.limitsGpu,
    requestsStorage: params.requestsStorage?.concat('Mi'),
  };
  const values = JSON.stringify(newParams);

  return {
    metadata: {
      annotations: {
        'field.cattle.io/containerDefaultResourceLimit': values,
      },
    },
  };
};

export function numericInputValidation(input) {
  return input.replace(/[^0-9]/g, '');
}

export const getOutputFormat = (data, checkedItems) => {
  const output: { SiteClusters: { SiteId: string; ClusterIds: string[] }[] } = {
    SiteClusters: [],
  };
  data.forEach((parent) => {
    const clusterIds: string[] = [];
    parent.children.forEach((child) => {
      const childkey = child.value;
      if (checkedItems[childkey]) {
        clusterIds.push(child.value);
      }
    });
    if (clusterIds.length > 0 && parent.children.length > 0) {
      output.SiteClusters.push({
        SiteId: parent.parentId,
        ClusterIds: clusterIds,
      });
    }
  });
  return output;
};

export const getSiteClusterOutput = (data, checkedItems) => {
  const output: { SiteClusters: { SiteId: string; ClusterIds: string[] }[] } = {
    SiteClusters: [],
  };

  if (checkedItems && checkedItems.length > 0) {
    checkedItems.forEach((item) => {
      const clusterData: string[] = item.clusterIds;
      output.SiteClusters.push({
        SiteId: item.siteId,
        ClusterIds: clusterData,
      });
    });
  }

  data.forEach((parent) => {
    const clusterIds: string[] = [];
    parent.children.forEach((child) => {
      const childkey = child.value;
      if (checkedItems[childkey]) {
        clusterIds.push(child.value);
      }
    });
    if (clusterIds.length > 0 && parent.children.length > 0) {
      output.SiteClusters.push({
        SiteId: parent.parentId,
        ClusterIds: clusterIds,
      });
    }
  });
  return output;
};

// gives memory limits by converting Gi to Mib currently
export const getProcessedUsedQuotaLimits = (value = '') => {
  let used = Number(value.replace(/[^0-9]/g, ''));
  if (value.includes('Gi')) {
    used *= 1024;
  }

  return used;
};
