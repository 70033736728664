import React, { ComponentType } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

type IconProps = {
  component: ComponentType<SvgIconProps>;
  color: 'success' | 'error';
};

type Props = {
  value?: boolean;
};

function DataTableBoolCell({ value }: Props) {
  const iconProps: IconProps = value
    ? {
        component: CheckIcon,
        color: 'success',
      }
    : {
        component: ClearIcon,
        color: 'error',
      };

  return <SvgIcon fontSize="small" {...iconProps} />;
}

export default DataTableBoolCell;
