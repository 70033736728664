import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import SearchBar from '../../SearchBar';
import HeaderContext from '../HeaderContext';

// TODO attach real search results
const options = ['option 1', 'option 2', 'option 3', 'option 4', 'option 5'];

type Props = {
  onFocusChange?: (active: boolean) => void;
};

function HeaderSearch(props: Props) {
  const { color } = useContext(HeaderContext);

  return (
    <Box display="flex" alignItems="center">
      <SearchBar color={color} options={options} {...props} disabled />
    </Box>
  );
}

export default HeaderSearch;
