import { useState } from 'react';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import {
  SolutionConsumerFeatures,
  SolutionFileSubscriptionItem,
  SolutionType,
  SubscriptionType,
} from '../../types';
import { useSolutionDetails } from '../../redux';
import SolutionFeatureInformation from '../../components/SolutionFeatureInfomation';

const cellStyle = {
  border: 0,
  height: 'auto',
  padding: '10px 20px 10px 0px',
};
const LabelStyle = {
  fontWeight: 'bold',
  width: '23%',
};

interface FileSubscriptionProps {
  storageEnabled?: boolean;
  data: SolutionFileSubscriptionItem;
}

export const FileSubscription = ({
  storageEnabled = false,
  data,
}: FileSubscriptionProps) => {
  const { name, destination, folderStructure } = data;
  const [solutionDetails] = useSolutionDetails();
  const { solutionType } = useParams();
  const isFileSubscriptionService =
    solutionDetails?.solutionConsumerFeatures?.includes(
      SolutionConsumerFeatures.IsFileSubscriptionService
    ) || solutionDetails?.subscriptionType?.includes(SubscriptionType.Files);

  const [showConnectionString, setShowConnectionString] = useState(false);
  const VisibilityIcon = showConnectionString
    ? VisibilityOffOutlinedIcon
    : VisibilityOutlinedIcon;
  const handleShowConnectionString = () => {
    setShowConnectionString((prevShowPassword: any) => !prevShowPassword);
  };

  return (
    <Box>
      {!isFileSubscriptionService && solutionType !== SolutionType.ioTH ? (
        <SolutionFeatureInformation
          featureInfo="File subscription is disabled since this feature was not opted
            during solution onboarding. To start accessing it, please edit the
            solution and select the feature."
        />
      ) : (
        <Table>
          <TableBody>
            <TableRow>
              <TableCell sx={[cellStyle, LabelStyle]}>
                Subscription Name
              </TableCell>
              <TableCell sx={cellStyle}>{name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={[cellStyle, LabelStyle]}>
                Source Storage Account
              </TableCell>
              <TableCell sx={cellStyle}>
                {storageEnabled ? 'Enabled' : 'Disabled'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={[cellStyle, LabelStyle]}>
                Destination Storage Type
              </TableCell>
              <TableCell sx={cellStyle}>
                {destination?.destinationType}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={[cellStyle, LabelStyle]}>
                Destination Folder Format
              </TableCell>
              <TableCell sx={cellStyle}>{folderStructure}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={[cellStyle, LabelStyle]}>
                Connection String
              </TableCell>
              <TableCell
                sx={{
                  ...cellStyle,
                  wordBreak: 'break-all',
                  overflow: 'visible',
                }}
              >
                {showConnectionString
                  ? destination?.destinationURL
                  : '*'.repeat(50)}
                &nbsp;
                {destination?.destinationURL && (
                  <IconButton
                    onClick={handleShowConnectionString}
                    size="small"
                    title={showConnectionString ? 'Hide' : 'View'}
                  >
                    <VisibilityIcon
                      sx={{ color: '#0047BA', fontSize: '20px' }}
                    />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </Box>
  );
};
