import React from 'react';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';
import Box, { BoxProps } from '@mui/material/Box';

type Props<T extends FieldValues> = Omit<BoxProps, 'onSubmit'> & {
  contextProps: UseFormReturn<T>;
  onSubmit?: (values: any) => void;
};

function FormContainer<T extends FieldValues = FieldValues>({
  children,
  contextProps,
  onSubmit,
  ...rest
}: Props<T>) {
  return (
    <FormProvider<T> {...contextProps}>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        flexDirection="column"
        gap={2}
        onSubmit={onSubmit}
        {...rest}
      >
        {children}
      </Box>
    </FormProvider>
  );
}

export default FormContainer;
