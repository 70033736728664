import { CardButtonsContainer } from 'src/components/CardButton';
import { ReactComponent as AKSLogo } from 'src/assets/icons/AKS.svg';
import { ReactComponent as InfraLogo } from 'src/assets/icons/infraspecific.svg';
import { ReactComponent as AuditLogo } from 'src/assets/icons/audit.svg';
// import { ReactComponent as ObservabilityLogo } from 'src/assets/icons/observabilityMonitor.svg';
import { ReactComponent as SolutionLogo } from 'src/assets/icons/solutions .svg';
import PageCardLayout from 'src/components/PageCardLayout';
import MonitoringCardButton from './MonitoringCardButton';

function MonitorDashboards() {
  return (
    <PageCardLayout title="Monitoring">
      <CardButtonsContainer>
        <MonitoringCardButton
          title="AKS Dashboard"
          Icon={AKSLogo}
          onClick={() =>
            window.open(process.env.REACT_APP_MONITORING_DASHBOARD)
          }
        />
        <MonitoringCardButton
          title="Solution Specific Workload"
          Icon={SolutionLogo}
          onClick={() =>
            window.open(
              'https://pepsicodev.kb.southcentralus.azure.elastic-cloud.com:9243/s/it_monitoring_poweruser/app/dashboards#/view/850a1c10-b918-11ee-80d2-cd4bf4579276'
            )
          }
        />
        <MonitoringCardButton
          title="Infra Specific Workload Dashboard"
          Icon={InfraLogo}
          onClick={() =>
            window.open(
              'https://pepsicodev.kb.southcentralus.azure.elastic-cloud.com:9243/s/it_monitoring_poweruser/app/dashboards#/view/20f16c20-b9c6-11ee-91d5-5983ea8f3de5'
            )
          }
        />
        <MonitoringCardButton
          title="Audit logs"
          Icon={AuditLogo}
          onClick={() =>
            window.open(process.env.REACT_APP_AUDIT_LOGS_DASHBOARD)
          }
        />
        {/* <MonitoringCardButton
          title="Device Observability Dashboard"
          icon={ObservabilityLogo}
          onClick={() =>
            window.open(
              'https://pepsicodev.kb.southcentralus.azure.elastic-cloud.com:9243/s/it_monitoring_poweruser/app/dashboards#/view/f67eaa70-32c0-11ee-8161-1db155e55a04'
            )
          }
        /> */}
      </CardButtonsContainer>
    </PageCardLayout>
  );
}

export default MonitorDashboards;
