import React from 'react';
import compact from 'lodash/compact';
import DateFormattedValue from 'src/components/DateFormattedValue';
import FieldsSet, { FieldDataItem } from 'src/components/FieldsSet';
import ModalDialog, { ModalCloseHandler } from 'src/components/ModalDialog';
import {
  SolutionJobItem,
  SolutionJobItemField,
  SolutionJobType,
} from '../../types';

type Props = {
  item?: SolutionJobItem;
  loading?: boolean;
  open: boolean;
  onClose: ModalCloseHandler;
  onSubmit: (item?: SolutionJobItem) => void;
  title: string;
  goBack: () => void;
};

const getFields = (item?: SolutionJobItem): FieldDataItem[] => {
  const isTemplateMirgation = item?.type === SolutionJobType.TemplateMigration;
  const isHostOsUpdate = item?.type === SolutionJobType.HostOsDeployment;
  const isCommandInvoke = item?.type === SolutionJobType.ScheduleDeviceMethod;

  return compact([
    {
      id: SolutionJobItemField.DisplayName,
      label: 'Job name',
    },
    {
      id: SolutionJobItemField.Company,
      label: 'Company',
    },
    {
      id: SolutionJobItemField.Group,
      label: 'Device group',
    },
    {
      id: SolutionJobItemField.Type,
      label: 'Job type',
    },
    isTemplateMirgation && {
      id: SolutionJobItemField.ModelId,
      label: 'Template',
    },
    isCommandInvoke && {
      id: SolutionJobItemField.InvokeCompany,
      label: 'Command',
    },
    isHostOsUpdate && {
      id: SolutionJobItemField.ArtifactName,
      label: 'Release',
    },
    isHostOsUpdate &&
      item?.runLater && {
        id: SolutionJobItemField.StartTime,
        label: 'Start time',
        Component: DateFormattedValue,
      },
  ]);
};

function SolutionJobReviewStep({
  item,
  loading,
  open,
  title,
  onClose,
  onSubmit,
  goBack,
}: Props) {
  return (
    <ModalDialog
      open={open}
      title={title}
      loading={loading}
      onCancel={goBack}
      onClose={onClose}
      cancelBtnText="Back"
      onConfirm={() => onSubmit(item)}
      confirmBtnText={item?.runLater ? 'Save and exit' : 'Save and run'}
      fullWidth
    >
      <FieldsSet fields={getFields(item)} data={item} gap={2} />
    </ModalDialog>
  );
}

export default SolutionJobReviewStep;
