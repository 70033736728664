import React from 'react';
import { useParams } from 'react-router-dom';

type Props = {
  title?: string;
};

function SolutionDevicesModulesTitle({ title }: Props) {
  const { solutionName } = useParams();
  return <>{solutionName || title}</>;
}

export default SolutionDevicesModulesTitle;
