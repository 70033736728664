import React from 'react';
import Box from '@mui/material/Box';
import { CompanySolutionItem } from '../../types';

type Props = {
  value: CompanySolutionItem[];
};

function CompanyDetailsSolutions({ value }: Props) {
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {value.map(({ id, solutionDisplayName }) => (
        <Box key={id}>{solutionDisplayName}</Box>
      ))}
    </Box>
  );
}

export default CompanyDetailsSolutions;
