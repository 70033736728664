import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ROOT } from '../constants';
import { RouteProps } from '../types';
import { getExactPath } from '../utils';
import SecureRoute from './SecureRoute';

type Props = {
  routes: RouteProps[];
  fallbackUrl?: string;
};

function NestedRouter({ routes, fallbackUrl = ROOT }: Props) {
  return (
    <Routes>
      {routes.map(({ path = '', exact, index, ...route }) => (
        <Route
          key={getExactPath(path, exact)}
          index={index}
          path={path ? getExactPath(path, exact) : undefined}
          element={
            <SecureRoute isPublic fallbackUrl={fallbackUrl} {...route} />
          }
        />
      ))}
    </Routes>
  );
}

export default NestedRouter;
