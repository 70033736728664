import Box from '@mui/system/Box';
import Badge from 'src/components/Badge';

type StatusMapItem = {
  color?: 'success' | 'info' | 'error' | 'warning';
  label: string;
};

type Props = {
  value?: number;
};
function SolutionDeviceCertRenewalStatus({ value = 0 }: Props) {
  const statusMap: Record<number, StatusMapItem> = {
    0: { label: 'Not started' },
    1: { color: 'warning', label: 'Renewal Initiated' },
    2: { color: 'warning', label: 'Request Acknowledged' },
    3: { color: 'warning', label: 'Issued' },
    4: { color: 'success', label: 'Renewed' },
    5: { color: 'error', label: 'Renewal Failed' },
  };

  return (
    <Box>
      <Badge {...statusMap[value]} />
    </Box>
  );
}

export default SolutionDeviceCertRenewalStatus;
