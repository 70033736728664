export type SolutionSettings = {
  menderSetup: SolutionMenderSettings;
  storageSetup: SolutionSourceStorageAccountSettings;
  dataIngestion?: boolean;
  fileSubscriptionServiceSetup?: SolutionFileSubscription;
  isMessageSubscriptionService?: boolean;
  isAlarmEventsStreamProcessing?: boolean;
  telemetryStreamProcessingSetup?: SolutionTelemetryProcessing;
};

export type SolutionTelemetryProcessing = {
  isTelemetryStreamProcessing?: boolean;
  streamProcessingType?: string;
};

export type SolutionFileSubscription = {
  fileSubscriptionService?: boolean;
  subscriptionType?: string;
};

export type SolutionMenderSettings = {
  menderEnabled?: boolean;
  releasePrefix?: string;
};

export enum SolutionMenderSettingsFields {
  Enabled = 'menderEnabled',
  ReleasePrefix = 'releasePrefix',
}

export type SolutionSourceStorageAccountSettings = {
  storageEnabled?: boolean;
  sourceConnectionString?: string;
  solutionBlobStorageName?: string;
};

export enum SolutionStorageAccountSettingsFields {
  Enabled = 'storageEnabled',
}

export type SolutionAdxTablesData = {
  adxTables: string[];
};

export type SolutionAdxTablesItem = {
  data: SolutionAdxTablesData;
  loading?: boolean;
};

export enum SolutionAdxTablesFields {
  AdxTables = 'adxTables',
}
