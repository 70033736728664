import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/system/createTheme';
import noContentLogo from 'src/assets/images/no-content-logo.svg';

const styles: BoxProps = {
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  px: 1.6,
};

const logoStyles = {
  backgroundSize: 'contain',
  boxShadow: 'none',
  border: 'none',
  width: ({ spacing }: Theme) => spacing(50),
  height: ({ spacing }: Theme) => spacing(10),
  mb: 3,
};

type Props = BoxProps & {
  children?: React.ReactNode;
  title?: string;
  subtitle?: string;
};

function NoContentWrapper({ children, title, subtitle, ...rest }: Props) {
  return (
    <Box {...styles} {...rest}>
      <CardMedia sx={logoStyles} image={noContentLogo} />
      {title && (
        <Typography variant="h4" marginBottom={2}>
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography variant="body1" marginBottom={2}>
          {subtitle}
        </Typography>
      )}
      {children}
    </Box>
  );
}

export default NoContentWrapper;
