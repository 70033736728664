export const TIMEZONES_MAP = {
  '-12:00:00': 'UTC−12:00',
  '-11:00:00': 'UTC−11:00',
  '-10:00:00': 'UTC−10:00',
  '-09:30:00': 'UTC−9:30',
  '-08:00:00': 'UTC−8:00',
  '-07:00:00': 'UTC−7:00',
  '-06:00:00': 'UTC−6:00',
  '-05:00:00': 'UTC−5:00',
  '-04:00:00': 'UTC−4:00',
  '-03:30:00': 'UTC−3:30',
  '-03:00:00': 'UTC−3:00',
  '-02:00:00': 'UTC−2:00',
  '-01:00:00': 'UTC−1:00',
  '00:00:00': 'UTC∓0:00',
  '01:00:00': 'UTC+1:00',
  '02:00:00': 'UTC+2:00',
  '03:00:00': 'UTC+3:00',
  '03:30:00': 'UTC+3:30',
  '04:00:00': 'UTC+4:00',
  '04:30:00': 'UTC+4:30',
  '05:00:00': 'UTC+5:00',
  '05:30:00': 'UTC+5:30',
  '05:45:00': 'UTC+5:45',
  '06:00:00': 'UTC+6:00',
  '06:30:00': 'UTC+6:30',
  '07:00:00': 'UTC+7:00',
  '08:00:00': 'UTC+8:00',
  '08:45:00': 'UTC+8:45',
  '09:00:00': 'UTC+9:00',
  '09:30:00': 'UTC+9:30',
  '10:00:00': 'UTC+10:00',
  '10:30:00': 'UTC+10:30',
  '11:00:00': 'UTC+11:00',
  '12:00:00': 'UTC+12:00',
  '12:45:00': 'UTC+12:45',
  '13:00:00': 'UTC+13:00',
  '14:00:00': 'UTC+14:00',
};
