import { createDatasetSlice, ResponseMapping } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import {
  API_COMPANIES,
  COMPANIES_STORE,
  COMPANIES_STORE_LIST,
} from '../constants';

const api = (queryParams) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: API_COMPANIES,
    queryParams,
  });

const companiesListSlice = createDatasetSlice({
  api,
  storeName: COMPANIES_STORE,
  actionName: COMPANIES_STORE_LIST,
  responseMapping: ResponseMapping.Default,
});

export default companiesListSlice;
