import React, { ReactNode, useContext } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { Context } from './TabsContext';

type Props = BoxProps & {
  children?: ReactNode;
  index: number;
};

function TabPanel({ children, index, ...rest }: Props) {
  const { index: contextIndex } = useContext(Context);
  const showTab = index === contextIndex;

  return showTab ? <Box {...rest}>{children}</Box> : null;
}

export default TabPanel;
