import { Operators, SchemaTypes } from './interfaces';

const LOCATION_OPERATOR: Operators[] = [Operators.in, Operators.notIn];
const COMMON_OPERATORS: Operators[] = [Operators.equals, Operators.notEquals];
const NUMERIC_OPERATORS: Operators[] = COMMON_OPERATORS.concat([
  Operators.greater,
  Operators.equalOrGreater,
  Operators.less,
  Operators.equalOrLess,
]);
const ENUM_OPERATORS: Operators[] = COMMON_OPERATORS;
const STRING_OPERATORS: Operators[] = COMMON_OPERATORS.concat([
  Operators.contains,
  Operators.notContains,
]);

const TYPEKEY = '@type';

export const getEnumKeys = <T extends object>(
  enumToDeconstruct: T
): Array<keyof typeof enumToDeconstruct> =>
  Object.keys(enumToDeconstruct) as Array<keyof typeof enumToDeconstruct>;

export const getKeyByValue = (object: any, value: any) =>
  Object.keys(object)[Object.values(object).indexOf(value)];

export function getOperatorsEnum(schema?: SchemaTypes): Operators[] {
  switch (schema) {
    case SchemaTypes.Map:
    case SchemaTypes.Object:
      return []; // no operators supported
    case SchemaTypes.Geopoint:
      return LOCATION_OPERATOR;
    case SchemaTypes.Enum:
      return ENUM_OPERATORS;
    case SchemaTypes.String:
      return STRING_OPERATORS;
    case SchemaTypes.Date:
    case SchemaTypes.DateTime:
    case SchemaTypes.Time:
    case SchemaTypes.Double:
    case SchemaTypes.Float:
    case SchemaTypes.Integer:
    case SchemaTypes.Long:
    case SchemaTypes.Duration:
      return NUMERIC_OPERATORS;
    default:
      // common operators apply to any schema type.
      return COMMON_OPERATORS;
  }
}

export const flattenSchemaForFields = (object: any[]): [] => {
  const filterOptions = ['Property', 'Telemetry'];
  const flattenObject = (item: any): any[] => {
    const stack: any = [];
    const result: any = [];
    const type = item[TYPEKEY];
    stack.push(item);
    while (stack.length > 0) {
      const current = stack.pop();
      if (
        current &&
        typeof current.schema === 'object' &&
        current.schema !== null
      ) {
        current.schema.fields.forEach((element: any) => {
          element.name = `${current.name} + '.' + ${element.name}`;
          stack.push({ ...element });
        });
      } else {
        result.push({ ...current, [TYPEKEY]: type });
      }
    }
    return result;
  };

  return object
    .filter((item) => item[TYPEKEY] === 'Interface')
    .reduce(
      (accum, item) => (item.contents ? accum.concat(item.contents) : accum),
      []
    )
    .filter((item: any) =>
      Array.isArray(item[TYPEKEY])
        ? item[TYPEKEY].some((x) => filterOptions.includes(x))
        : filterOptions.includes(item[TYPEKEY])
    )
    .flatMap((item: any) => {
      if (
        typeof item.schema === 'object' &&
        item.schema !== null &&
        item.schema[TYPEKEY] === 'Object'
      ) {
        return flattenObject(item);
      }
      return item;
    });
};
