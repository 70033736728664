import { ChangeEvent } from 'react';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import Switch from 'src/components/Switch';
import CheckboxGroup from 'src/components/CheckboxGroup';
import AutoCompleteWithFreeText from 'src/components/AutoComplete/AutoCompleteWithFreeText';
import { nanToUndefined } from 'src/utils';
import {
  SolutionAdxTablesFields,
  SolutionItemField,
  SolutionMenderSettingsFields,
  SolutionStorageAccountSettingsFields,
} from '../types';

export const getSolutionSettingsMenderFormFields = (
  menderEnabled?: boolean,
  isPlatformReader?: boolean,
  isConsumerSolution?: boolean,
  onMenderEnabledChange?: (event: ChangeEvent<HTMLInputElement>) => void
) => [
  {
    name: SolutionMenderSettingsFields.Enabled,
    label: 'Enable Mender',
    Component: Switch,
    disabled: isPlatformReader || (!menderEnabled && isConsumerSolution),
    type: 'checkbox',
    onChange: onMenderEnabledChange,
    labelStyle: { color: '#7B7B7B', opacity: '80%' },
  },
  {
    name: SolutionMenderSettingsFields.ReleasePrefix,
    label: 'Release prefix',
    Component: TextField,
    placeholder: 'Enter release prefix',
    disabled: !menderEnabled || isPlatformReader,
    required: menderEnabled,
    validation: menderEnabled
      ? Yup.string().required('Release prefix name is required')
      : undefined,
  },
];

export const getSolutionSettingsStorageAccountFormFields = (
  onSourceStorageEnabledChange?: (event: ChangeEvent<HTMLInputElement>) => void
) => [
  {
    name: SolutionStorageAccountSettingsFields.Enabled,
    label: 'Enable Source Storage',
    Component: Switch,
    type: 'checkbox',
    onChange: onSourceStorageEnabledChange,
  },
];

export const getSolutionSettingsCacheRetentionFormFields = (
  cachePeriod?: number,
  retentionPeriod?: number,
  disableCacheLimit?: boolean,
  disableRetentionLimit?: boolean,
  handleCacheLimit?: (event: ChangeEvent<HTMLInputElement>) => void,
  handleRetentionLimit?: (event: ChangeEvent<HTMLInputElement>) => void,
  handleUnlimitedCache?: (event: ChangeEvent<HTMLInputElement>) => void,
  handleUnlimitedRetention?: (event: ChangeEvent<HTMLInputElement>) => void,
  disableCacheRetention?: boolean,
  isPlatformReader?: boolean,
  isDataIngested?: boolean,
  isConsumerSolution?: boolean
) => [
  {
    name: SolutionItemField.CachePeriod,
    label: 'Cache Period',
    placeholder: 'Enter the values in days',
    value: disableCacheLimit ? '' : cachePeriod,
    initialValue: '30',
    onChange: handleCacheLimit,
    InputProps: { inputProps: { min: 1 } },
    Component: TextField,
    validation: disableCacheLimit
      ? Yup.string().notRequired()
      : Yup.number()
          .transform(nanToUndefined)
          .min(1, 'Cache period must be in range 1-365')
          .max(365, 'Cache period must be in range 1-365')
          .required('Cache Period field is required'),
    type: disableCacheLimit ? 'text' : 'number',
    required: !disableCacheLimit,
    disabled: disableCacheLimit || isPlatformReader || isDataIngested,
  },
  {
    name: SolutionItemField.UnlimitedCache,
    Component: CheckboxGroup,
    type: 'checkbox-group',
    required: false,
    labelStyle: { mr: 3 },
    onChange: handleUnlimitedCache,
    disabled: disableCacheRetention || isPlatformReader || isDataIngested,
    options: [
      { label: 'Unlimited days for Cache period', value: 'UnlimitedCache' },
    ],
  },
  {
    name: SolutionItemField.RetentionPeriod,
    label: 'Retention Period',
    placeholder: 'Enter the values in days',
    value: disableRetentionLimit ? '' : retentionPeriod,
    initialValue: '30',
    onChange: handleRetentionLimit,
    InputProps: { inputProps: { min: 1 } },
    Component: TextField,
    validation: disableRetentionLimit
      ? Yup.string().notRequired()
      : Yup.number()
          .transform(nanToUndefined)
          .min(1, 'Retention Period must be in range 1-365')
          .max(365, 'Retention Period must be in range 1-365')
          .required('Retention Period field is required'),
    type: disableRetentionLimit ? 'text' : 'number',
    required: !disableRetentionLimit,
    disabled: disableRetentionLimit || isPlatformReader || isDataIngested,
  },
  {
    name: SolutionItemField.UnlimitedRetention,
    Component: CheckboxGroup,
    type: 'checkbox-group',
    required: false,
    onChange: handleUnlimitedRetention,
    labelStyle: { mr: 3 },
    disabled: disableCacheRetention || isPlatformReader || isDataIngested,
    options: [
      {
        label: 'Unlimited days for Retention period',
        value: 'UnlimitedRetention',
      },
    ],
  },
];

export const getSolutionSettingsADXTableFormFields = (
  adxTables: string[],
  isPlatformReader?: boolean
) => [
  {
    name: SolutionAdxTablesFields.AdxTables,
    label: 'Table name',
    helperText: 'Type the table name and press ENTER',
    Component: AutoCompleteWithFreeText,
    options: [],
    value: adxTables,
    validation: Yup.array()
      .max(40, 'Tables should not be more than 40')
      .required('Atleast one table name is required')
      .nullable(),
    selectedOptions: adxTables,
    autocompleteRows: 5,
    disabled: isPlatformReader,
    multiple: true,
  },
];
