import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import {
  SolutionEdgeWorkloadItem,
  SolutionEdgeWorkloadStatus as Status,
} from '../../types/solutionEdgeWorkload';
import { useSolutionSitesList } from '../../redux';
import { getParenLabelChildren } from '../../SolutionSites/components/SolutionSiteOptions';

type Props = {
  value?: any;
  item?: SolutionEdgeWorkloadItem;
};

const SolutionSiteClusterInfo = ({ value }: Props) => {
  const [data, sitesLoading] = useSolutionSitesList();
  const [optionsData, setOptionsData] = useState<
    {
      parentId: string;
      parentLabel: string;
      children: { label: string; value: string }[];
    }[]
  >();

  useEffect(() => {
    const response = getParenLabelChildren(data);
    setOptionsData(response);
  }, [data]);

  return (
    <Box>
      {value.length > 0 ? (
        value?.map((site, index) => {
          const parent = optionsData?.find(
            (label) => label.parentId === site.siteId
          );

          return (
            <div key={index} style={{ gap: '2rem' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body3bold">
                  {`Site ${index + 1} : `}
                </Typography>
                &nbsp;
                <Typography fontSize="16px" fontFamily="Roboto">
                  {parent ? parent?.parentLabel : '-'}
                </Typography>
              </Box>
              {site?.clusterIds.map((id, subIndex) => {
                const child = parent?.children.find(
                  (child) => child.value === id
                );
                return (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CircleIcon
                      color="primary"
                      sx={{
                        fontSize: '8px',
                        fontFamily: 'Roboto',
                        marginLeft: 1,
                        marginRight: 1,
                      }}
                    />
                    <Typography variant="body3bold">
                      {`Cluster ${subIndex + 1} : `}
                    </Typography>
                    &nbsp;
                    <Typography fontSize="16px">
                      {child ? child?.label : '-'}
                    </Typography>
                  </Box>
                );
              })}
            </div>
          );
        })
      ) : (
        <Typography>-</Typography>
      )}
    </Box>
  );
};

export default SolutionSiteClusterInfo;
