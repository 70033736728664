import Box from '@mui/system/Box';
import Badge from 'src/components/Badge';
import RetentionDaysDetails from '../SolutionDetails/components/RetentionDaysDetails';
import { SolutionItem, SolutionItemStatus as Status } from '../types';

type StatusMapItem = {
  color?: 'success' | 'info' | 'error' | 'warning';
  label: string;
};

type Props = {
  value?: Status;
  item?: SolutionItem;
};
function SolutionItemStatus({ value = Status.NotStarted, item }: Props) {
  const statusMap: Record<Status, StatusMapItem> = {
    [Status.NotStarted]: { label: 'Not started' },
    [Status.Error]: { color: 'error', label: 'Error' },
    [Status.WaitingForManualSetup]: { color: 'info', label: 'Manual setup' },
    [Status.Pending]: { color: 'info', label: 'Pending' },
    [Status.Succeeded]: { color: 'success', label: 'Succeeded' },
    [Status.Suspended]: { color: 'warning', label: 'Suspended' },
    [Status.Deleting]: { label: 'Deleting' },
    [Status.Deleted]: { label: 'Deleted' },
  };

  return (
    <Box>
      <Badge {...statusMap[value]} />
      {value === Status.Suspended && (
        <RetentionDaysDetails item={item}></RetentionDaysDetails>
      )}
    </Box>
  );
}

export default SolutionItemStatus;
