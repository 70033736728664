import React, { SyntheticEvent } from 'react';
import Box from '@mui/material/Box';
import { OptionValue, Option } from 'src/components/SelectOption';
import { getDisplayValue } from '../utils';
import AutoCompleteBoxDisplayValueItem from './AutoCompleteBoxDisplayValueItem';

type Props<T> = {
  value: T[];
  options: Option<T>[];
  onDelete: (event: SyntheticEvent, value: T) => void;
};

const displayValueStyle = {
  maxHeight: '13rem',
  overflow: 'auto',
  overflowX: 'hidden',
};

function AutoCompleteBoxDisplayValue<T extends OptionValue>({
  options,
  value,
  onDelete,
}: Props<T>) {
  return (
    <Box data-testid="autocomplete-box-display-value" sx={displayValueStyle}>
      {value.map((item) => (
        <AutoCompleteBoxDisplayValueItem
          key={item}
          label={getDisplayValue(options, item)}
          value={item}
          onDelete={onDelete}
        />
      ))}
    </Box>
  );
}

export default AutoCompleteBoxDisplayValue;
