export * from './timezones';

// api
export const API_OTP_KEYS = '/management/otp/keys';
export const API_OTP_KEY_ITEM = `${API_OTP_KEYS}/:key`;
export const API_OTP_SEND_EMAIL = '/management/otp/send-requests';

// redux
export const OTP_STORE = 'otp';
export const OTP_STORE_LIST = 'list';
