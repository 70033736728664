import { useEffect } from 'react';
import ModalDialog, {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { useForm } from 'src/components/Form';
import { useAlert, AlertType } from 'src/components/AlertsProvider';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { useStoreUnitActions } from 'src/redux/helpers';
import { Option } from 'src/components/SelectOption';
import usersStore, { useApplicationRoles } from '../../redux';
import { ApplicationItem } from '../../types';
import { NewApplicationFormFields } from '../formFields';
import { API_APPLICATION_ROLES } from '../../constants';

type Props = {
  item: ApplicationItem;
  open: boolean;
  onClose: ModalCloseHandler;
};

function ApplicationRoleAssignModal({ item, open, onClose }: Props) {
  const actions = useStoreUnitActions(usersStore);
  useEffect(() => {
    actions.applicationRoles();
  }, []);
  const { showAlert } = useAlert();

  const [data] = useApplicationRoles();
  let options: Option[] = [];
  if (data) {
    options = data[0].applicationRoleInformation.map((item) => {
      const { name: value, description: label, ...rest } = item;
      return { value, label, ...rest };
    });
  }

  const [onSubmit, optionloading] = useStatefulApi(
    (params) =>
      pepApiRequest({
        method: RequestMethod.Post,
        url: API_APPLICATION_ROLES,
        params,
      }),
    () => {
      showAlert({
        type: AlertType.Success,
        title: 'Application created',
        text: 'Application has been successfully created.',
      });
      onClose(null, ModalCloseReason.completeAction);
      actions.applicationList();
    }
  );

  const [form, handleSubmit] = useForm({
    fields: NewApplicationFormFields(options),
    values: item,
    onSubmit,
  });

  return (
    <ModalDialog
      open={open}
      title="Add Application"
      loading={optionloading}
      onClose={onClose}
      onConfirm={handleSubmit}
      confirmBtnText="Add"
      maxWidth="sm"
      fullWidth
    >
      {form}
    </ModalDialog>
  );
}

export default ApplicationRoleAssignModal;
