import { SolutionJobType } from '../types';

export const JOB_TYPE_OPTIONS = [
  {
    value: SolutionJobType.TemplateMigration,
    label: 'Template migration',
  },
  {
    value: SolutionJobType.HostOsDeployment,
    label: 'Host OS update',
  },
];

export const JOB_IOTH_TYPE_OPTIONS = [
  {
    value: SolutionJobType.TemplateMigration,
    label: 'Template migration',
  },
  {
    value: SolutionJobType.HostOsDeployment,
    label: 'Host OS update',
  },
  {
    value: SolutionJobType.ScheduleDeviceMethod,
    label: 'Invoke command',
  },
  {
    value: SolutionJobType.ScheduleUpdateTwin,
    label: 'Property update',
  },
];
