import { useParams } from 'react-router-dom';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { RequestMethod, pepApiRequest, useStatefulApi } from 'src/services';
import { generateApiPath, loadDataFromStorage } from 'src/utils';
import { API_DEVICE_GROUPS, API_DEVICE_GROUPS_IOTH } from '../../constants';
import SolutionDeviceGroupEditModal from './SolutionDeviceGroupEditModal';
import { useSolutionDeviceGroupProperties } from '../../redux';
import { mapDeviceGroupToRequestFormat } from '../utils';
import { SolutionType } from '../../types';

type Props = {
  open: boolean;
  onClose: ModalCloseHandler;
};

export default function SolutionDeviceGroupCreateContainer({
  open,
  onClose,
}: Props) {
  const { solutionId } = useParams();
  const { showAlert } = useAlert();
  const [properties] = useSolutionDeviceGroupProperties();

  const [onSubmit, loading] = useStatefulApi(
    ({ companyId, ...params }) => {
      const solutionType = loadDataFromStorage('solutionType');
      const requestCompanyId = companyId !== '' ? companyId : 'All';
      const apiRequest = pepApiRequest({
        method: RequestMethod.Post,
        url: generateApiPath(
          solutionType.toUpperCase() === SolutionType.ioTH.toUpperCase()
            ? API_DEVICE_GROUPS_IOTH
            : API_DEVICE_GROUPS,
          {
            companyId: requestCompanyId,
            solutionId,
          }
        ),
        params: mapDeviceGroupToRequestFormat(params, properties),
      });

      return apiRequest;
    },
    () => {
      showAlert({
        type: AlertType.Success,
        title: 'New device group has been created',
        text: 'Solution device group has been successfully created.',
      });
      onClose(null, ModalCloseReason.completeAction);
    }
  );

  return (
    <SolutionDeviceGroupEditModal
      properties={properties}
      open={open}
      title="Create new device group"
      onClose={onClose}
      onSubmit={onSubmit}
      loading={loading}
    />
  );
}
