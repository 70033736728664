import { ON_FAILURE, ON_REQUEST, ON_SUCCESS } from './actionTypes';
import { SliceParams } from './types';
import { applyMappingToApi, getHashFunc, getSliceNamePrefix } from './utils';
import { createApiCallSlice } from './createApiCallSlice';

export function datasetSliceReducer({
  actionName,
  datasetHashKey,
  initialState,
  reducers,
}) {
  const hashFunc = getHashFunc(actionName, datasetHashKey);
  return {
    [ON_REQUEST](state, action) {
      const key = hashFunc(action.payload);
      state[key] = { ...state[key], loading: true };
    },
    [ON_SUCCESS](state, action) {
      const key = hashFunc(action.meta.identifier);
      state[key] = {
        loading: false,
        ...action.payload,
      };
    },
    [ON_FAILURE](state, action) {
      const key = hashFunc(action.meta.identifier);
      state[key] = { ...initialState, loading: false, error: action.error };
    },
    ...reducers,
  };
}

export function createDatasetSlice<T>({
  api,
  storeName,
  actionName,
  datasetHashKey,
  initialState = {} as T,
  reducers,
  responseMapping,
  ...rest
}: SliceParams<T>) {
  return createApiCallSlice({
    name: getSliceNamePrefix(storeName, actionName),
    reducers: datasetSliceReducer({
      actionName,
      datasetHashKey,
      initialState,
      reducers,
    }),
    api: applyMappingToApi(api, responseMapping),
    initialState,
    ...rest,
  });
}
