import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SolutionsDeviceConfigUpload from './components/SolutionDeviceConfigUpload';
import SolutionDeviceConfigFiles from './components/SolutionDeviceConfigFiles';

function SolutionDeviceConfig() {
  return (
    <Box position="relative">
      <Typography
        data-testid="solution-device-config-files-header"
        my={2}
        variant="h4"
      >
        Device configuration files
      </Typography>
      <Box mb={4}>
        <SolutionsDeviceConfigUpload />
        <SolutionDeviceConfigFiles />
      </Box>
    </Box>
  );
}

export default SolutionDeviceConfig;
