import { createStoreUnit } from 'src/redux/helpers';
import adxMappedTables from './solutionAdxMappedTablesSlice';
import adlsSubscriptionById from './solutionAdlsSubscriptionSlice';
import alertRules from './solutionAlertRulesSlice';
import companies from './solutionCompaniesSlice';
import cosmosSubscriptionById from './solutionCosmosSubscriptionSlice';
import defaultNamespaceRscLimit from './solutionDefaultNamespaceRscLimitSlice';
import details from './solutionDetailsSlice';
import deviceModules from './solutionsDeviceModulesSlice';
import rulesManagement from './solutionsRulesManagmentSlice';
import deviceComponents from './solutionsDeviceComponentsSlice';
import dashboard from './solutionsDashboardsSlice';
import dashboardDeviceGroups from './solutionsDashboardDeviceGroupsSlice';
import devices from './solutionDevicesSlice';
import devicesPersist from './solutionDevicesPersistSlice';
import deviceBulkDeploymentDetails from './SolutionDeviceBulkDeploymentSlice';
import deviceConfigFile from './solutionDeviceConfigFileSlice';
import deviceDetails from './solutionDeviceDetailsSlice';
import deviceObservProperties from './solutionDeviceObservPropertiesSlice';
import deviceCertificateDetails from './solutionDeviceCertDetailsSlice';
import deviceCertificateAuditLogs from './solutionDeviceCertAuditLogsSlice';
import deviceDeploymentReleases from './solutionDeviceDepReleasesSlice';
import deviceDeploymentLog from './solutionDeviceDepLogSlice';
import deviceGroups from './solutionDeviceGroupsSlice';
import deviceGroupDetails from './solutionDeviceGroupDetailsSlice';
import deviceGroupProperties from './solutionDevicePropertiesSlice';
import edgeWorkloadConfigurations from './solutionEdgeWorkloadConfigSlice';
import filesWhitelist from './solutionFilesWhitelistSlice';
import jobs from './solutionJobsSlice';
import list from './solutionsListSlice';
import namespaceRscLimit from './solutionNamespaceRscLimitSlice';
import namespaceRscQuotasDetail from './solutionNamespaceRscQuotasDetailSlice';
import pepsenceImageRepoDetails from './solutionPepsenceImageRepoDetailsSlice';
import signatureWhitelist from './solutionSignatureWhitelistSlice';
import fileSubscriptionById from './solutionFileSubscriptionSlice';
import subscriptions from './solutionSubscriptionsSlice';
import users from './solutionUsersSlice';
import sites from './solutionSiteSlice';
import { SOLUTIONS_STORE } from '../constants';
import solutionWorkloadStatus from './solutionWorkloadStatusSlice';
import specificRulesManagement from './solutionsSpecificRulesSlice';
import ruleTelemtryProperties from './solutionTelemtrySlice';
import clusterRscLimit from './solutionClusterNamespaceRscLimitSlice';
import deviceProperties from './solutionDeviceCommandPropertiesSlice';
import jobsDevices from './solutionJobsDevicesSlice';

const solutionsStore = createStoreUnit({
  name: SOLUTIONS_STORE,
  slices: {
    adxMappedTables,
    adlsSubscriptionById,
    alertRules,
    companies,
    clusterRscLimit,
    cosmosSubscriptionById,
    defaultNamespaceRscLimit,
    details,
    devices,
    devicesPersist,
    deviceBulkDeploymentDetails,
    deviceDetails,
    deviceObservProperties,
    deviceCertificateDetails,
    deviceDeploymentLog,
    deviceCertificateAuditLogs,
    deviceDeploymentReleases,
    deviceConfigFile,
    deviceGroups,
    deviceGroupDetails,
    deviceGroupProperties,
    edgeWorkloadConfigurations,
    solutionWorkloadStatus,
    filesWhitelist,
    fileSubscriptionById,
    jobs,
    list,
    namespaceRscLimit,
    namespaceRscQuotasDetail,
    pepsenceImageRepoDetails,
    signatureWhitelist,
    sites,
    subscriptions,
    users,
    deviceModules,
    dashboard,
    dashboardDeviceGroups,
    rulesManagement,
    specificRulesManagement,
    deviceComponents,
    ruleTelemtryProperties,
    deviceProperties,
    jobsDevices,
  },
});

export default solutionsStore;
