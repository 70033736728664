import { Button, TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import Select from 'src/components/Select';
import { Box } from '@mui/system';
import { SolutionDeviceItemField, SolutionMenderStatus } from '../../types';


const provisionedMenu = [
  { value: 'true', label: 'True' },
  { value: 'false', label: 'False' },
]; const menderStatus = [
  { value: SolutionMenderStatus.Accepted, label: 'Accepted' },
  { value: SolutionMenderStatus.Pending, label: 'Pending' },
  { value: SolutionMenderStatus.Rejected, label: 'Rejected' },
];
const connectionStatusMenu = [
  { value: 'connected', label: 'Connected' },
  { value: 'disconnected', label: 'Disconnected' },
];
const Filter = (Props) => {
  const handleColumnChange = (event: any, index) => {
    Props.components[index].selectedColumn = event.target.value;
    Props.components[index].searchValue = '';
    Props.onChange(Props.components);
  };
  const handleValueChange = (event: any, index) => {
    Props.components[index].searchValue = event.target.value.toString();
    Props.onChange(Props.components);
  };

  const removeFilterHandler = (index) => {
    Props.removeFilterInputFields(index);
  };
  const newColumns = Props.columns.map((item) => {
    const { id: value, title: label, ...rest } = item;
    const disabled = Props.components.some((x) => x.selectedColumn === value);
    return { value, label, disabled, ...rest };
  });

  const renderOptions = (selectedColumn: string) => {
    switch (selectedColumn.toUpperCase()) {
      case SolutionDeviceItemField.MenderStatus.toUpperCase():
        return menderStatus;
      case SolutionDeviceItemField.ConnectionState.toUpperCase():
        return connectionStatusMenu;
      case SolutionDeviceItemField.Provisioned.toUpperCase():
        return provisionedMenu;
      default: break;
    }
    return [];
  }
  return Props.components.map((item, index) => (
    <Box
      display="flex"
      key={index}
      justifyContent="center"
      alignItems="center"
      sx={{
        margin: ({ spacing }) => spacing(2),
        maxWidth: ({ spacing }) => spacing(40),
      }}
    >
      <Button sx={{ ml: ({ spacing }) => spacing(-3) }}>
        <ClearIcon
          onClick={() => {
            removeFilterHandler(index);
          }}
        />
      </Button>
      <Select
        sx={{
          width: ({ spacing }) => spacing(33),
          mr: ({ spacing }) => spacing(1),
        }}
        label="Select Column"
        value={item.selectedColumn}
        options={newColumns}
        onChange={(event) => handleColumnChange(event, index)}
      ></Select>
      {Props.components[index].selectedColumn.toUpperCase() === SolutionDeviceItemField.Provisioned.toUpperCase() ||
      Props.components[index].selectedColumn.toUpperCase() === SolutionDeviceItemField.MenderStatus.toUpperCase() ||
        Props.components[index].selectedColumn.toUpperCase() === SolutionDeviceItemField.ConnectionState.toUpperCase() ? (
        <Select
          sx={{ width: ({ spacing }) => spacing(26) }}
          value={item.searchValue}
          label="Select Value"
          options={renderOptions(Props.components[index].selectedColumn)}
          onChange={(event) => handleValueChange(event, index)}
        ></Select>
      ) : (
        <TextField
          label="Filter Value"
          sx={{ width: ({ spacing }) => spacing(26) }}
          value={item.searchValue}
          onChange={(event) => handleValueChange(event, index)}
          placeholder="FilterValue"
        ></TextField>
      )}
    </Box >
  ));
};

export default Filter;
