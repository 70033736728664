import { useState, useCallback, useMemo, useEffect } from 'react';
import { useForm } from 'src/components/Form';
import ModalDialog, { ModalCloseHandler } from 'src/components/ModalDialog';
import { useStoreUnitActions } from 'src/redux/helpers';
import { Box, Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import { getWorkloadConfigEditFormFields } from '../formFields';
import {
  SolutionEdgeWorkloadItem,
  SolutionEdgeWorkloadRepositoryType,
} from '../../types';
import solutionsStore, {
  useCurrentSolutionName,
  useSolutionSitesList,
} from '../../redux';
import { getParenLabelChildren } from '../../SolutionSites/components/SolutionSiteOptions';

type Props = {
  item?: SolutionEdgeWorkloadItem;
  title: string;
  loading?: boolean;
  open: boolean;
  onClose: ModalCloseHandler;
  onSubmit: (values: SolutionEdgeWorkloadItem) => void;
  confirmBtnText?: string;
  repositoryType?: string;
};

const containerStyle = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  height: '500px',
  overflowY: 'auto',
  padding: '10px',
  alignItems: 'center',
};

function SolutionEdgeWorkloadEditModal({
  item,
  title,
  loading,
  open,
  onClose,
  onSubmit,
  repositoryType,
  confirmBtnText = 'Configure',
}: Props) {
  const [data, sitesLoading] = useSolutionSitesList();
  const siteOptions = data.map((site) => ({
    value: site.id,
    label: site.name,
  }));
  const solutionName = useCurrentSolutionName();
  const targetNamespace = `${solutionName}-ns`;
  const { solutionId } = useParams();
  const actions = useStoreUnitActions(solutionsStore);
  const [optionsData, setOptionsData] = useState<
    {
      parentId: string;
      parentLabel: string;
      children: { label: string; value: string }[];
    }[]
  >();

  useEffect(() => {
    const response = getParenLabelChildren(data);
    setOptionsData(response);
  }, [data]);

  const [customParams, setCustomParams] = useState({});
  const [selectedSiteIds, setSelectedSiteIds] = useState({});

  const clusterData = item?.siteClusters?.reduce((result, cluster) => {
    result.push(cluster.siteId);
    cluster.clusterIds.forEach((id) => {
      result.push(id);
    });
    return result;
  }, []);

  useEffect(() => {
    setSelectedSiteIds(clusterData);
  }, [item?.siteClusters]);
  // const selectedSiteIds = clusterData;

  const gitRepoType =
    repositoryType === SolutionEdgeWorkloadRepositoryType.SolutionEdge ||
    item?.gitFlag === SolutionEdgeWorkloadRepositoryType.SolutionEdge;
  const [gitAuthentication, setGitAuthentication] = useState(
    item?.gitAuthentication
  );
  const [helmAuthentication, setHelmAuthentication] = useState(
    item?.helmAuthentication
  );
  const [tlsCertVerification, setTlsCertVerification] = useState(
    item?.tlsCertificationVerification
  );

  const handleAssignSiteChange = (value) => {
    setCustomParams(value);
  };

  const onChange = useCallback(
    ({
      gitAuthentication,
      helmAuthentication,
      tlsCertificationVerification,
    }: SolutionEdgeWorkloadItem) => {
      setGitAuthentication(gitAuthentication);
      setHelmAuthentication(helmAuthentication);
      setTlsCertVerification(tlsCertificationVerification);
    },
    []
  );
  const fields = useMemo(
    () =>
      getWorkloadConfigEditFormFields(
        !!item,
        siteOptions,
        selectedSiteIds,
        sitesLoading,
        gitAuthentication,
        helmAuthentication,
        tlsCertVerification,
        targetNamespace,
        gitRepoType,
        optionsData,
        handleAssignSiteChange
      ),
    [
      !!item,
      siteOptions,
      selectedSiteIds,
      sitesLoading,
      gitAuthentication,
      helmAuthentication,
      tlsCertVerification,
      targetNamespace,
      gitRepoType,
      optionsData,
    ]
  );

  const [form, handleSubmit] = useForm({
    visibleValuesOnly: true,
    values: item,
    fields,
    sx: containerStyle,
    onChange,
    onSubmit,
  });

  useEffect(() => {
    actions.sites(solutionId);
  }, []);

  return (
    <ModalDialog
      open={open}
      title={title}
      confirmBtnText={confirmBtnText}
      loading={loading || sitesLoading}
      onClose={onClose}
      maxWidth="md"
      titleStyle={{ height: '67px' }}
      actionsStyle={{ height: '87px' }}
      showCancelBtn={false}
      showConfirmBtn={false}
      contentStyle={{ paddingRight: '0px' }}
      fullWidth
    >
      {form}
      <Box textAlign="right" mt={3} style={{ paddingRight: '20px' }}>
        <Button onClick={onClose} variant="outlined" sx={{ mr: 2 }}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained">
          {!item ? 'Configure' : 'Save'}
        </Button>
      </Box>
    </ModalDialog>
  );
}

export default SolutionEdgeWorkloadEditModal;
