import React from 'react';
import Badge from 'src/components/Badge';
import { SolutionWorkloadStatus as Status } from 'src/pages/Solutions/types/solutionEdgeWorkload';

type StatusMapItem = {
  color?: 'success' | 'error' | 'info' | 'default';
  label: string;
};

type Props = {
  value?: string;
};

function SolutionWorkloadStatus({ value = Status.Ready }: Props) {
  const statusValue = value.toLowerCase();
  const statusMap: Record<Status, StatusMapItem> = {
    [Status.True]: { color: 'success', label: Status.True },
    [Status.False]: { color: 'error', label: Status.False },
    [Status.Ready]: { color: 'success', label: Status.Ready },
    [Status.Failed]: { color: 'error', label: Status.Failed },
    [Status.Updating]: { color: 'info', label: Status.Updating },
    [Status.Modified]: { color: 'info', label: Status.Modified },
    [Status.ErrApplied]: { color: 'error', label: 'Err Applied' },
    [Status.Missing]: { color: 'default', label: Status.Missing },
    [Status.InProgress]: { color: 'info', label: Status.InProgress },
    [Status.Pending]: { color: 'info', label: Status.Pending },
  };
  return (
    <>
      <Badge {...statusMap[statusValue.toString()]} />
    </>
  );
}

export default SolutionWorkloadStatus;
