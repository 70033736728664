import { generatePath } from 'react-router-dom';
import { createDatasetSlice, ResponseMapping } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import {
  API_DEVICE_DEP_LOG,
  SOLUTIONS_STORE,
  SOLUTIONS_STORE_DEVICE_DEP_LOG,
} from '../constants';

const api = ({ deviceId }) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: generatePath(API_DEVICE_DEP_LOG, {
      deviceId,
    }),
  });

const solutionDeviceDepLogSlice = createDatasetSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTIONS_STORE_DEVICE_DEP_LOG,
  responseMapping: ResponseMapping.Default,
});

export default solutionDeviceDepLogSlice;
