import React from 'react';
import Box from '@mui/material/Box';
import DoneIcon from '@mui/icons-material/Done';
import Avatar from '@mui/material/Avatar';
import { SxProps } from 'src/theme/types';

type Props = {
  isActive: boolean;
  label: string;
};

const getWrapperStyle = (isActive: boolean): SxProps => ({
  position: 'relative',
  display: 'flex',
  mb: 1,
  gap: 4,
  color: isActive ? 'primary.main' : 'neutral.main',
  alignItems: 'center',
  '&:not(:last-child):after': {
    content: '""',
    height: ({ spacing }) => spacing(1),
    width: ({ spacing }) => spacing(0.1),
    backgroundColor: 'neutral.main',
    position: 'absolute',
    bottom: ({ spacing }) => spacing(-1),
    left: ({ spacing }) => spacing(1.7),
  },
});

const getIndicatorStyle = (isActive: boolean): SxProps => ({
  width: ({ spacing }) => spacing(3.5),
  height: ({ spacing }) => spacing(3.5),
  color: isActive ? 'primary.main' : 'neutral.main',
  background: isActive ? 'primary.main' : 'transparent',
  border: 1,
});

function StatusBarItem({ isActive, label, ...rest }: Props) {
  return (
    <Box sx={getWrapperStyle(isActive)} {...rest}>
      <Avatar sx={getIndicatorStyle(isActive)}>
        {isActive && (
          <DoneIcon data-testid="status-bar-active-icon" color="secondary" />
        )}
      </Avatar>
      {label}
    </Box>
  );
}

export default StatusBarItem;
