import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DataTableContext from 'src/components/DataTable/components/DataTableContext';
import PageCardLayout from 'src/components/PageCardLayout';
import { useStoreUnitActions } from 'src/redux/helpers';
import { CardHeader, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DataTable, {
  getActionsColConfig,
  useTableQueryParams,
} from 'src/components/DataTable';
import SolutionDevicesModulesAction from './SolutionDevicesModulesAction';
import solutionsStore, {
  useSolutionComponentsDetails,
  useSolutionDeviceGroupWritableProperties,
  useSolutionModulesDetails,
} from '../redux';
import {
  SolutionDeviceModulesField,
  SolutionDeviceComponentsField,
} from '../types';
import SolutionDevicesComponentsAction from '../SolutionDevicesComponents/SolutionDevicesComponentsAction';

const columns = [
  {
    id: SolutionDeviceModulesField.ModuleId,
    title: 'Module Id',
  },
  {
    id: SolutionDeviceModulesField.ModuleType,
    title: 'Module Type',
  },
  {
    id: SolutionDeviceModulesField.ConnectionState,
    title: 'Connection State',
  },
  {
    id: SolutionDeviceModulesField.CloudToDeviceMessageCount,
    title: 'Cloud To Device Message Count',
  },
  {
    id: SolutionDeviceModulesField.ModelId,
    title: 'Model Id',
  },
  getActionsColConfig(SolutionDevicesModulesAction),
];

const sdkColumns = [
  {
    id: SolutionDeviceComponentsField.Component,
    title: 'Component Name',
  },
  {
    id: SolutionDeviceComponentsField.id,
    title: 'Component ID',
  },
  getActionsColConfig(SolutionDevicesComponentsAction),
];

function SolutionDevicesModules() {
  const { solutionName, iotEdge, modelId, solutionId } = useParams();
  const { paging } = useTableQueryParams();
  const navigate = useNavigate();
  const actions = useStoreUnitActions(solutionsStore);

  useEffect(() => {
    if (iotEdge === 'true') {
      actions.deviceModules({
        solutionId,
        deviceId: solutionName,
      });
      actions.deviceComponents({
        solutionId,
        deviceId: solutionName,
        modelId,
      });
      actions.deviceGroupProperties({
        solutionId,
        modelId,
      });
      actions.deviceProperties({
        solutionId,
        deviceId: solutionName,
      });
    } else {
      actions.deviceComponents({
        solutionId,
        deviceId: solutionName,
        modelId,
      });
      actions.deviceGroupProperties({
        solutionId,
        modelId,
      });
      actions.deviceProperties({
        solutionId,
        deviceId: solutionName,
      });
    }
  }, []);

  const [data, loading] = useSolutionModulesDetails();
  const [sdkData, sdkloading] = useSolutionComponentsDetails();
  const [compData, compDataLoading] =
    useSolutionDeviceGroupWritableProperties();
  const combinedData = [...sdkData, ...compData];
  const componentData = combinedData.filter(
    (data, index) =>
      combinedData.findIndex((obj) => obj.component === data.component) ===
      index
  );

  return (
    <DataTableContext>
      <CardHeader
        data-testid="solution-device-header"
        title={
          <>
            <ArrowBackIcon
              sx={{ cursor: 'pointer' }}
              color="primary"
              fontSize="small"
            />
            <Typography
              style={{
                marginTop: '-2rem',
                paddingLeft: '3.5rem',
                fontFamily: 'Stolzl',
                fontSize: '18px',
              }}
            >
              {`View device details - ${solutionName}`}
            </Typography>
          </>
        }
        sx={{
          boxShadow: '3px 3px 5px #E8E8E8',
          backgroundColor: '#fff',
          border: '0.1rem solid #E8E8E8',
        }}
        onClick={() => navigate(-1)}
      />
      <PageCardLayout>
        <Typography
          style={{
            paddingTop: '1rem',
            paddingBottom: '2rem',
            fontFamily: 'Stolzl',
          }}
        >
          {iotEdge === 'true'
            ? SolutionDeviceModulesField.Modules
            : SolutionDeviceComponentsField.Components}
        </Typography>
        {iotEdge === 'true' ? (
          <DataTable
            loading={loading}
            data={data}
            columns={columns}
            sortable={false}
            {...paging}
          />
        ) : (
          <DataTable
            loading={sdkloading}
            data={componentData}
            columns={sdkColumns}
            sortable={false}
            {...paging}
          />
        )}
      </PageCardLayout>
    </DataTableContext>
  );
}
export default SolutionDevicesModules;
