import { SolutionEdgeWorkloadAuthType } from 'src/pages/Solutions/types';

export const getEdgeWorkloadEditParams = (
  params: any,
  gitFlag?: string | undefined
) => {
  if (params.gitAuthentication !== SolutionEdgeWorkloadAuthType.HTTPBased) {
    const updatedParams = params;
    delete updatedParams.gitUsername;
    delete updatedParams.gitPassword;
    return {
      WorkloadConfiguration: { ...updatedParams },
    };
  }

  return {
    WorkloadConfiguration: { ...params },
  };
};

export const getSiteWorkloadEditParams = (params: any, siteId?: string) => {
  const clusterId = params.siteClusters;
  delete params.siteClusters;
  return {
    ...params,
    SiteClusters: [
      {
        SiteId: siteId,
        ClusterIds: clusterId,
      },
    ],
  };
};
