import React from 'react';
import { format as formatDate } from 'date-fns';
import { Card, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import DateFormattedValue from 'src/components/DateFormattedValue';
import ModalDialog, { ModalCloseHandler } from 'src/components/ModalDialog';
import { SxProps } from 'src/theme/types';
import { baseTheme } from 'src/theme/baseTheme';
import { useScrollObserver } from 'src/hooks';
import { useStoreUnitActions } from 'src/redux/helpers';
import DataTableContext from 'src/components/DataTable/components/DataTableContext';
import DataTable, {
  DataTableActions,
  DataTableWrapper,
  useTableQueryParams,
} from 'src/components/DataTable';
import { PageCardSection } from 'src/components/PageCardLayout';
import { Box } from '@mui/system';
import { DATE_TIME_DISPLAY_FORMAT } from 'src/constants';
import SolutionJobStatus from './SolutionJobStatus';
import { SolutionJobItem, SolutionJobType } from '../../types';
import solutionsStore, { useSolutionJobsDevice } from '../../redux';

type Props = {
  item?: SolutionJobItem;
  open: boolean;
  onClose: ModalCloseHandler;
};

function SolutionJobViewContainer({ item, open, onClose }: Props) {
  const [jobsDeviceData, jobsDeviceDataloading] = useSolutionJobsDevice();
  const { paging } = useTableQueryParams();
  const { pageIndex, pageSize } = paging;
  const { solutionId } = useParams();
  const actions = useStoreUnitActions(solutionsStore);
  const DateFormatted = (value: string | undefined): string =>
    value
      ? formatDate(new Date(value), DATE_TIME_DISPLAY_FORMAT)
      : DATE_TIME_DISPLAY_FORMAT;

  const startTime = DateFormatted(item?.createdTime);
  const endTime = DateFormatted(item?.endTime);
  const timeTaken =
    new Date(endTime).getTime() - new Date(startTime).getTime() === 0
      ? new Date(endTime).getTime() - new Date(startTime).getTime()
      : (new Date(endTime).getTime() - new Date(startTime).getTime()) / 1000;

  const keys = item?.properties ? Object.keys(item.properties) : [];
  const values: string[] = item?.properties
    ? Object.values(item.properties)
    : [];

  const commandKeys =
    item?.commandPayload && JSON.parse(item.commandPayload) !== null
      ? Object.keys(JSON.parse(item.commandPayload))
      : [];
  const commandValues =
    item?.commandPayload && JSON.parse(item.commandPayload) !== null
      ? Object.values(JSON.parse(item.commandPayload))
      : [];

  const commandMethodName = item?.cloudToDeviceMethod?.methodName?.replace(
    '*',
    ' - '
  );
  const devicesColumns = [
    { id: 'deviceId', title: 'Device ID' },
    {
      id: 'status',
      title: 'Status',
      Component: SolutionJobStatus,
    },
    {
      id: 'endTimeUtc',
      title: 'End Time',
      Component: DateFormattedValue,
    },
  ];

  const { breakpoints } = baseTheme;
  const getCardWidth = (
    breakpoint: string,
    direction: string,
    percent: number
  ) => ({
    [breakpoints[direction](breakpoint)]: {
      width: `calc(${percent}% - 2rem)`,
    },
  });

  const tableStyle = {
    minHeight: 0,
  };
  const numberStyle: SxProps = {
    minWidth: '40%',
    mr: 3,
  };
  const labelStyle: SxProps = {
    minWidth: '40%',
    mr: 3,
    fontSize: '35px',
    textAlign: 'center',
    fontWeight: 'bold',
    paddingBottom: '1rem',
  };
  const CompletedStyle: SxProps = {
    minWidth: '40%',
    mr: 3,
    fontSize: '40px',
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#91C24C',
    paddingBottom: '3rem',
  };
  const FailedStyle: SxProps = {
    minWidth: '40%',
    mr: 3,
    fontSize: '40px',
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#DB1E36',
    paddingBottom: '3rem',
  };
  const PendingStyle: SxProps = {
    minWidth: '40%',
    mr: 3,
    fontSize: '40px',
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#0047BA',
    paddingBottom: '3rem',
  };

  const cardStyle: SxProps = {
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'flex-start',
    textAlign: 'right',
    textDecoration: 'none',
    justifyContent: 'flex-start',
    mx: 2,
    mb: 2,
    p: 4,

    marginRight: '3rem',
    '&.Mui-disabled': {
      bgcolor: 'grayscale.200',
    },
    '&:not(:hover)': {
      boxShadow: 'none',
    },
    ...getCardWidth('lg', 'up', 25),
    ...getCardWidth('lg', 'down', 25),
  };
  const style: SxProps = {
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'flex-start',
    textAlign: 'right',
    textDecoration: 'none',
    justifyContent: 'flex-start',
    mx: 2,
    mb: 2,
    p: 4,
    marginRight: '-1rem',
    '&.Mui-disabled': {
      bgcolor: 'grayscale.200',
    },
    '&:not(:hover)': {
      boxShadow: 'none',
    },
    ...getCardWidth('lg', 'up', 110),
    ...getCardWidth('lg', 'down', 40),
  };
  const getTitle = () => {
    let title = '';
    switch (item?.type) {
      case SolutionJobType.ScheduleUpdateTwin:
        title = 'Property details';
        break;
      case SolutionJobType.ScheduleDeviceMethod:
        title = 'Invoke command';
        break;
      case SolutionJobType.TemplateMigration:
        title = 'Template migration';
        break;
      default:
        break;
    }
    return title;
  };

  const scrollElementRef = useScrollObserver(
    () =>
      !jobsDeviceDataloading &&
      jobsDeviceData.sequence &&
      actions.jobsDevices({
        jobId: item?.id,
        solutionId,
        sequence: jobsDeviceData.sequence,
        pageIndex,
        pageSize,
      }),
    [solutionId, jobsDeviceDataloading, jobsDeviceData.sequence]
  );

  return (
    <>
      <ModalDialog
        open={open}
        title="View Job details"
        onClose={onClose}
        maxWidth="lg"
        cancelBtnText="Close"
        fullWidth
        showConfirmBtn={false}
        loading={jobsDeviceDataloading}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <PageCardSection>
            <Typography
              sx={{
                mx: 1,
                mb: 2,
                mt: 2,
                fontWeight: 'bold',
                marginLeft: '20px',
              }}
            >
              Results
            </Typography>
            <Card sx={cardStyle} variant="outlined">
              <Box>
                <div style={{ paddingTop: '1rem' }}>
                  <Typography sx={CompletedStyle}>
                    {item?.deviceJobStatistics?.succeededCount <= 9 &&
                    item?.deviceJobStatistics?.succeededCount !== 0
                      ? `0${item?.deviceJobStatistics?.succeededCount}`
                      : item?.deviceJobStatistics?.succeededCount}
                  </Typography>
                  <Typography sx={numberStyle}>Completed</Typography>
                </div>
              </Box>
            </Card>
            <Card sx={cardStyle} variant="outlined">
              <Box>
                <div style={{ paddingTop: '1rem' }}>
                  <Typography sx={PendingStyle}>
                    {item?.deviceJobStatistics?.pendingCount <= 9 &&
                    item?.deviceJobStatistics?.pendingCount !== 0
                      ? `0${item?.deviceJobStatistics?.pendingCount}`
                      : item?.deviceJobStatistics?.pendingCount}
                  </Typography>
                  <Typography sx={numberStyle}>Pending</Typography>
                </div>
              </Box>
            </Card>
            <Card sx={cardStyle} variant="outlined">
              <Box>
                <div style={{ paddingTop: '1rem' }}>
                  <Typography sx={FailedStyle}>
                    {item?.deviceJobStatistics?.failedCount <= 9 &&
                    item?.deviceJobStatistics?.failedCount !== 0
                      ? `0${item?.deviceJobStatistics?.failedCount}`
                      : item?.deviceJobStatistics?.failedCount}
                  </Typography>
                  <Typography sx={numberStyle}>Failed</Typography>
                </div>
              </Box>
            </Card>
          </PageCardSection>
          {startTime >= '0' && endTime >= '0' && (
            <PageCardSection>
              <Typography
                sx={{
                  mx: 1,
                  mb: 2,
                  mt: 2,
                  fontWeight: 'bold',
                  marginLeft: '20px',
                }}
              >
                Durations
              </Typography>
              <Card sx={style} variant="outlined">
                <Box>
                  <div style={{ paddingTop: '1rem' }}>
                    <Typography sx={labelStyle}>{timeTaken}</Typography>
                    <Typography sx={numberStyle}>Seconds</Typography>
                  </div>
                </Box>
                <Box>
                  <Typography
                    sx={{ minWidth: '40%', mr: 3, paddingBottom: '2rem' }}
                  >
                    Start
                  </Typography>
                  <Typography
                    sx={{ minWidth: '40%', mr: 3, paddingBottom: '2rem' }}
                  >
                    End
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{ minWidth: '40%', mr: 2, paddingBottom: '2rem' }}
                  >
                    {startTime}
                  </Typography>

                  <Typography
                    sx={{ minWidth: '40%', mr: 2, paddingBottom: '2rem' }}
                  >
                    {endTime}
                  </Typography>
                </Box>
              </Card>
            </PageCardSection>
          )}
        </div>

        <PageCardSection title={getTitle()} mt={2}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {item?.type === SolutionJobType.ScheduleUpdateTwin ? (
              <>
                <Box>
                  {keys?.map((key) => (
                    <Typography
                      sx={{ minWidth: '40%', mr: 1, paddingBottom: '2rem' }}
                    >
                      {key}:
                    </Typography>
                  ))}
                </Box>
                <Box>
                  {values?.map((key) => (
                    <Typography
                      sx={{ minWidth: '40%', mr: 2, paddingBottom: '2rem' }}
                    >
                      {key}
                    </Typography>
                  ))}
                </Box>
              </>
            ) : (
              <>
                {commandMethodName && (
                  <Box>
                    <Typography
                      sx={{ minWidth: '40%', mr: 2, paddingBottom: '2rem' }}
                    >
                      {commandMethodName}
                    </Typography>
                  </Box>
                )}
                <Box>
                  {commandKeys?.map((key) => (
                    <Typography
                      sx={{ minWidth: '40%', mr: 1, paddingBottom: '2rem' }}
                    >
                      {key}:
                    </Typography>
                  ))}
                </Box>
                <Box>
                  {commandValues?.map((key: any) => (
                    <Typography
                      sx={{ minWidth: '40%', mr: 2, paddingBottom: '2rem' }}
                    >
                      {key}
                    </Typography>
                  ))}
                </Box>
                <>
                  {item?.modelId && (
                    <>
                      <Box>
                        <Typography
                          sx={{ minWidth: '40%', mr: 1, paddingBottom: '2rem' }}
                        >
                          ModelId
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          sx={{ minWidth: '40%', mr: 2, paddingBottom: '2rem' }}
                        >
                          :{item?.modelId}
                        </Typography>
                      </Box>
                    </>
                  )}
                </>
              </>
            )}
          </div>
        </PageCardSection>
        <DataTableWrapper searchBar={false}>
          <PageCardSection title="Devices" mb={0}>
            <DataTable
              ref={scrollElementRef}
              data={jobsDeviceData?.values}
              columns={devicesColumns}
              sx={tableStyle}
            />
          </PageCardSection>
        </DataTableWrapper>
      </ModalDialog>
    </>
  );
}

export default SolutionJobViewContainer;
