type Props = {
  value?: any;
  defaultValue?: string;
};

function TimeFormattedValue({ value, defaultValue }: Props) {
  const currentDate = new Date();
  const previousDate = new Date(value);

  const timeDifferenceInSeconds =
    currentDate.getTime() - previousDate.getTime();
  const seconds = Math.floor(timeDifferenceInSeconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  let timeAgo: any;

  if (days > 0) {
    timeAgo = `${days} day${days === 1 ? '' : 's'} ago`;
  } else if (hours > 0) {
    timeAgo = `${hours} hour${hours === 1 ? '' : 's'} ago`;
  } else if (minutes > 0) {
    timeAgo = `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
  } else {
    timeAgo = `${seconds} second${seconds === 1 ? '' : 's'} ago`;
  }

  return <span>{value ? timeAgo : defaultValue}</span>;
}

export default TimeFormattedValue;
