import { Box, Typography, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { PageCardLoader } from 'src/components/PageCardLayout';
import { SiteNamespaceRscQuotasDetailItem } from 'src/pages/Sites/types/siteNamespace';
import { isModalComplete } from 'src/components/ModalDialog';
import { useUserRoles } from 'src/auth';
import { useModal } from 'src/hooks';
import SiteNamespaceQuotasUsage from './SiteNamespaceQuotasUsage';
import SiteClusterNamespaceRscLimitEditModal from './SiteClusterNamespaceRscLimitEditModal';

const cellStyle = {
  border: 0,
  height: 'auto',
  padding: '0px 5px 20px 0px',
  lineHeight: '2rem',
  fontSize: '16px',
};
const LabelStyle = {
  ...cellStyle,
  fontWeight: 'bold',
  width: '70%',
};

interface SiteNamespaceRscLimitDetailsProps {
  data: SiteNamespaceRscQuotasDetailItem | undefined;
  rscLimitData: any[] | undefined;
  siteId: string;
  namespaceName: string | undefined;
  loading: boolean | undefined;
  payload: any;
}

export const SiteNamespaceRscLimitDetails = ({
  data = {},
  rscLimitData = [],
  siteId,
  namespaceName,
  loading,
  payload,
}: SiteNamespaceRscLimitDetailsProps) => {
  const { isPlatformOwner, isPlatformEngineer } = useUserRoles();
  const isResourceEditor = isPlatformOwner || isPlatformEngineer;
  const item = rscLimitData
    ? {
        ...rscLimitData[0],
        ...data,
      }
    : {};
  const newData: SiteNamespaceRscQuotasDetailItem = {
    ...item,
    requestsCpuStatus: [
      item?.requestsCpu ? item?.requestsCpu?.replace('m', 'mCPUs') : '–',
      item?.usedRequestsCpu
        ? item?.usedRequestsCpu?.replace('m', 'mCPUs')
        : '0',
    ],
    requestsMemoryStatus: [
      item?.requestsMemory ? item?.requestsMemory?.replace('mi', 'MiB') : '–',
      item?.usedRequestsMemory
        ? item?.usedRequestsMemory?.replace('mi', 'MiB').replace('Gi', 'GiB')
        : '0',
    ],
    limitsCpuStatus: [
      item?.limitsCpu ? item?.limitsCpu?.replace('m', 'mCPUs') : '–',
      item?.usedLimitsCpu ? item?.usedLimitsCpu?.replace('m', 'mCPUs') : '0',
    ],
    limitsMemoryStatus: [
      item?.limitsMemory ? item?.limitsMemory?.replace('m', 'mCPUs') : '–',
      item?.usedLimitsMemory
        ? item?.usedLimitsMemory?.replace('mi', 'MiB').replace('Gi', 'GiB')
        : '0',
    ],
    requestsStorageStatus: [
      item?.requestsStorage
        ? item?.requestsStorage?.replace('m', 'mCPUs')
        : '–',
      item?.usedRequestsStorage
        ? item?.usedRequestsStorage?.replace('mi', 'MiB').replace('Gi', 'GiB')
        : '0',
    ],
    limitsCpu: item?.limitsCpu ? item?.limitsCpu?.replace('m', 'mCPUs') : '–',
    limitsMemory: item?.limitsMemory
      ? item?.limitsMemory?.replace('mi', 'MiB')
      : '–',
    requestsCpu: item?.requestsCpu
      ? item?.requestsCpu?.replace('m', 'mCPUs')
      : '–',
    requestsMemory: item?.requestsMemory
      ? item?.requestsMemory?.replace('mi', 'MiB')
      : '–',
    requestsStorage: item?.requestsStorage
      ? item?.requestsStorage?.replace('mi', 'MiB')
      : '–',
    payload,
    siteId,
  };

  const onClose = (event, reason) => {
    isModalComplete(reason);
  };

  const [EditNamespaceRscLimitDialog, showEditNamespaceRscLimitDialog] =
    useModal(SiteClusterNamespaceRscLimitEditModal, {
      siteId,
      title: `Edit Quota for Core and Common : ${newData.clusterName}`,
      clusterId: newData.clusterId,
      clusterName: newData.clusterName,
      onClose,
    });

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Box textAlign="right" mb={2}>
        <Button
          size="small"
          variant="outlined"
          startIcon={<EditIcon />}
          disabled={!isResourceEditor}
          onClick={showEditNamespaceRscLimitDialog}
        >
          Edit
          {EditNamespaceRscLimitDialog}
        </Button>
      </Box>
      <Box
        sx={{
          overflowY: 'auto',
          flexGrow: 1,
        }}
      >
        {/* Namespace Level Section */}
        <Typography
          variant="body2"
          sx={{
            fontWeight: 700,
            fontSize: '18px',
            marginBottom: '25px',
          }}
        >
          Namespace level
        </Typography>

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: '40px',
            '@media (max-width: 900px)': {
              gridTemplateColumns: 'repeat(2, 1fr)',
            },
            '@media (max-width: 600px)': {
              gridTemplateColumns: 'repeat(1, 1fr)',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography sx={LabelStyle}>CPU Limit (milicores)</Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: 'auto',
              }}
            >
              <SiteNamespaceQuotasUsage value={newData.limitsCpuStatus} />
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography sx={LabelStyle}>CPU Reservation (milicores)</Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: 'auto',
              }}
            >
              <SiteNamespaceQuotasUsage value={newData.requestsCpuStatus} />
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography sx={LabelStyle}>Memory Limit (MiB)</Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: 'auto',
              }}
            >
              <SiteNamespaceQuotasUsage value={newData.limitsMemoryStatus} />
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography sx={LabelStyle}>Memory Reservation (MiB)</Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: 'auto',
              }}
            >
              <SiteNamespaceQuotasUsage value={newData.requestsMemoryStatus} />
            </Box>
          </Box>
        </Box>

        {/* Storage Level Section */}
        <Typography
          variant="body2"
          sx={{
            fontWeight: 700,
            fontSize: '18px',
            margin: '25px 0 12px',
          }}
        >
          Storage level
        </Typography>

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: '40px',
            '@media (max-width: 900px)': {
              gridTemplateColumns: 'repeat(2, 1fr)',
            },
            '@media (max-width: 600px)': {
              gridTemplateColumns: 'repeat(1, 1fr)',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography sx={LabelStyle}>Storage Limit (MiB)</Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: 'auto',
              }}
            >
              <SiteNamespaceQuotasUsage value={newData.requestsStorageStatus} />
            </Box>
          </Box>
        </Box>
      </Box>
      {loading && <PageCardLoader />}
    </Box>
  );
};
