import { useParams } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { useSelector } from 'react-redux';
import { State } from 'src/redux/types';
import { useSolutionFeatures } from 'src/pages/Solutions/hooks';
import {
  UserFeature,
  UserIdentity,
  UserProfile,
  UserRole,
  UserRolesQueryParams,
} from './types';

export const useUserIdentity = (): UserIdentity =>
  useSelector((state: State) => state.auth);

const initialUserProfile: UserProfile = {
  pepappiotroles: [],
};

export const useUserAuthProfile = (): UserProfile => {
  const { authState } = useOktaAuth();

  return (authState?.idToken?.claims || initialUserProfile) as UserProfile;
};

export const useUserPlatformRole = (): UserRole | undefined => {
  const { pepappiotroles } = useUserAuthProfile();
  const [userPlatformRole] = pepappiotroles;

  return userPlatformRole;
};

export const useUserSolutionRole = (solutionId?: string): UserRole | null => {
  const { solutionsOwner, solutionsUser } = useUserIdentity();

  if (solutionId) {
    if (solutionsOwner.includes(solutionId)) {
      return UserRole.SolutionOwner;
    }
    if (solutionsUser.includes(solutionId)) {
      return UserRole.SolutionUser;
    }
  }
  return null;
};

export const useUserTenantRole = (companyId?: string): UserRole | null => {
  const { tenantsOwner, tenantsUser } = useUserIdentity();

  if (companyId) {
    if (tenantsOwner.includes(companyId)) {
      return UserRole.TenantOwner;
    }
    if (tenantsUser.includes(companyId)) {
      return UserRole.TenantUser;
    }
  }
  return null;
};

export const useUserRoles = (params?: UserRolesQueryParams) => {
  const queryParams = useParams<UserRolesQueryParams>();
  const { companyId, solutionId } = params || queryParams;
  const platformRole = useUserPlatformRole();
  const solutionRole = useUserSolutionRole(solutionId);
  const tenantRole = useUserTenantRole(companyId);

  return {
    isPlatformOwner: [UserRole.PlatformAdmin, UserRole.PlatformOwner].includes(
      platformRole as UserRole
    ),
    isPlatformUser: platformRole === UserRole.PlatformUser,
    isPlatformReader: platformRole === UserRole.PlatformReader,
    isPlatformEngineer: platformRole === UserRole.PlatformEngineer,
    isSolutionOwner: solutionRole === UserRole.SolutionOwner,
    isSolutionUser: solutionRole === UserRole.SolutionUser,
    isTenantOwner: tenantRole === UserRole.TenantOwner,
    isTenantUser: tenantRole === UserRole.TenantUser,
  };
};

export const useUserRolesArr = (): UserRole[] => {
  const { companyId, solutionId } = useParams<UserRolesQueryParams>();

  return [
    useUserPlatformRole(),
    useUserSolutionRole(solutionId),
    useUserTenantRole(companyId),
  ].filter((role) => role) as UserRole[];
};

export const useUserFeaturesArr = (): [UserFeature[], boolean?] =>
  useSolutionFeatures();
