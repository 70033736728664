import React from 'react';
import Box from '@mui/material/Box';
import StatusBarItem from './components/StatusBarItem';
import { StatusValue, StatusItem } from './types';

type Props = {
  options: StatusItem[];
  value: StatusValue;
};

function StatusBar({ value, options, ...rest }: Props) {
  return (
    <Box data-testid="status-bar" {...rest}>
      {options.map(({ label, value: status }) => (
        <StatusBarItem
          data-testid={`status-bar-item-${status}`}
          key={status}
          isActive={status === value}
          label={label}
        />
      ))}
    </Box>
  );
}

export default StatusBar;
