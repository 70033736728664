import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Add from '@mui/icons-material/Add';
import { useModal } from 'src/hooks';
import DataTable, {
  DataTableWrapper,
  DataTableWrapperActionButton,
  getActionsColConfig,
  useTableQueryParams,
} from 'src/components/DataTable';
import { isModalComplete } from 'src/components/ModalDialog';
import { useStoreUnitActions } from 'src/redux/helpers';
import { useUserRoles } from 'src/auth';
import solutionsStore, { useSolutionUsers } from '../redux';
import { SolutionType, SolutionUserItemField } from '../types';
import SolutionUsersAction from './components/SolutionUsersAction';
import SolutionUsersRole from './components/SolutionUsersRole';
import SolutionUsersAssignModal from './components/SolutionUsersAssignModal';
import SolutionUsersPhoneNumber from './components/SolutionUsersPhoneNumber';

const columns = [
  {
    id: SolutionUserItemField.Email,
    title: 'Email address',
  },
  {
    id: SolutionUserItemField.Role,
    title: 'Role',
    Component: SolutionUsersRole,
  },
  {
    id: SolutionUserItemField.PhoneNumber,
    title: 'Phone Number',
    Component: SolutionUsersPhoneNumber,
  },
  {
    id: SolutionUserItemField.NotificationPreferList,
    title: 'Notification preference',
  },
  getActionsColConfig(SolutionUsersAction),
];

export default function SolutionUsers() {
  const { solutionId, solutionType } = useParams();
  const actions = useStoreUnitActions(solutionsStore);
  const { isPlatformReader } = useUserRoles();
  const [data, loading] = useSolutionUsers();
  const { paging } = useTableQueryParams();
  const [AssignNewUserDialog, showAssignNewUserDialog] = useModal(
    SolutionUsersAssignModal,
    {
      onClose: (event, reason) =>
        isModalComplete(reason) && actions.users(solutionId),
    }
  );
  const updatedData = data?.map((item) => {
    if (item.notificationPreference) {
      const notificationPreference: number[] = [];
      const notificationPreferListArray = item.notificationPreference.map(
        (notificationPrefer) => {
          if (notificationPrefer === 'email') {
            notificationPreference.push(1);
            return 'Email';
          }
          if (notificationPrefer === 'sms') {
            notificationPreference.push(2);
            return 'SMS';
          }
          notificationPreference.push(3);
          return 'Whatsapp';
        }
      );

      const notificationPreferList =
        notificationPreferListArray.length > 0
          ? notificationPreferListArray.toString()
          : '–';
      return {
        ...item,
        notificationPreferList: [notificationPreferList],
        notificationPreference,
        phoneNumber: item.phoneNumber ? item.phoneNumber : '–',
      };
    }
    return item;
  });

  useEffect(() => {
    actions.users(solutionId);
  }, [solutionId]);

  return (
    <DataTableWrapper
      title="Users"
      url="/help-center/docs/IndustrialSetup/UserOnboarding/UserOnboardingProcess/"
      showUrl={solutionType === SolutionType.Industrial}
      infoTitle="Help center"
      actionsPanel={
        <DataTableWrapperActionButton
          onClick={showAssignNewUserDialog}
          startIcon={<Add />}
          disabled={isPlatformReader}
        >
          Assign user
        </DataTableWrapperActionButton>
      }
    >
      <DataTable
        sx={{ minHeight: 0 }}
        data={updatedData}
        columns={columns}
        loading={loading}
        {...paging}
      />
      {AssignNewUserDialog}
    </DataTableWrapper>
  );
}
