import { useParams } from 'react-router-dom';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import ModalDialog, {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { useStoreUnitActions } from 'src/redux/helpers';
import { generateApiPath, loadDataFromStorage } from 'src/utils';
import { API_DEVICES_DELETE, API_DEVICES_DELETE_IOTH } from '../../constants';
import solutionsStore from '../../redux';
import { SolutionDeviceItem, SolutionType } from '../../types';

type Props = {
  selectedItems: SolutionDeviceItem[];
  open: boolean;
  onClose: ModalCloseHandler;
};
export default function SolutionDeviceDeleteModel({
  open,
  selectedItems,
  onClose,
}: Props) {
  const { showAlert } = useAlert();
  const { solutionId, solutionType } = useParams();
  const { devices } = useStoreUnitActions(solutionsStore);
  const itemIds = selectedItems.map((x) => x.id);
  const [onSubmit, loading] = useStatefulApi(
    () => {
      const solutionType = loadDataFromStorage('solutionType');
      return pepApiRequest({
        method: RequestMethod.Post,
        url: generateApiPath(
          solutionType.toUpperCase() === SolutionType.ioTH.toUpperCase()
            ? API_DEVICES_DELETE_IOTH
            : API_DEVICES_DELETE,
          {
            solutionId,
          }
        ),
        params: { deviceIds: itemIds },
      });
    },
    (response: any, params) => {
      let alertText = 'Device/Devices are deleted permanently.';
      if (
        solutionType === SolutionType.ioTH &&
        response.devicesDeleted &&
        response.devicesNotDeleted
      ) {
        if (
          response.devicesDeleted.length > 0 &&
          response.devicesNotDeleted.length === 0
        ) {
          alertText = `${response.devicesDeleted
            .toString()
            .replaceAll(',', ', ')} are deleted permanently.`;
        }
        if (
          response.devicesDeleted.length === 0 &&
          response.devicesNotDeleted.length > 0
        ) {
          alertText = `${response.devicesNotDeleted
            .toString()
            .replaceAll(',', ', ')} are not deleted.`;
        }
        if (
          response.devicesDeleted.length > 0 &&
          response.devicesNotDeleted.length > 0
        ) {
          alertText = `${response.devicesDeleted
            .toString()
            .replaceAll(
              ',',
              ', '
            )} are deleted permanently. ${response.devicesNotDeleted
            .toString()
            .replaceAll(',', ', ')} are not deleted.`;
        }
      }
      showAlert({
        type: AlertType.Info,
        title: 'Device/Devices deleted',
        text: alertText,
      });
      onClose(null, ModalCloseReason.completeAction);
      devices.init({ solutionId });
    }
  );

  return (
    <ModalDialog
      open={open}
      title="Delete Device(s)"
      onClose={onClose}
      confirmBtnText="Yes"
      cancelBtnText="No"
      onConfirm={onSubmit}
      loading={loading}
    >
      {`Device/Devices will be permanently deleted from Database and
            will not be reachable in future. Are you really sure you want to delete these
            devices?`}
    </ModalDialog>
  );
}
