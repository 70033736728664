import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { AlertTitle, Box, Typography } from '@mui/material';
import Alert from 'src/components/Alert';
import ModalDialog, { ModalCloseHandler } from 'src/components/ModalDialog';
import { useStoreUnitActions } from 'src/redux/helpers';
import { useForm } from 'src/components/Form';
import {
  API_ERROR_EVENT,
  ErrorResponseBody,
  RequestMethod,
  pepApiRequest,
} from 'src/services';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import { PageCardLoader } from 'src/components/PageCardLayout';
import { generateApiPath } from 'src/utils';
import { solutionsStore } from 'src/pages/Solutions';
import isArray from 'lodash/isArray';
import { useSolutionNamespaceRscLimit } from 'src/pages/Solutions/redux';
import {
  SolutionNamespaceRscLimitItem,
  SolutionNamespaceRscQuotasDetailItem,
} from 'src/pages/Solutions/types';
import { SiteNamespace } from 'src/pages/Sites/types';
import { API_SITE_CLUSTER_NAMESPACE_QUOTAS_DETAIL } from 'src/pages/Sites/constants';
import { mapSiteNamespaceRscQuotasDetailData } from 'src/pages/Sites/redux/utils';
import { API_SOLUTION_SITES_CLUSTERS_RES_SETTING } from 'src/pages/Solutions/constants';
import {
  getSetClusterNamespaceRscLimitParams,
  numericInputValidation,
} from 'src/pages/Solutions/SolutionSites/utils';
import { getSolutionClusterNamespaceRscLimitFields } from 'src/pages/Solutions/SolutionSites/formFields';
import { generatePath } from 'react-router-dom';

type Props = {
  siteId: string;
  title: string;
  clusterId: string;
  clusterName: string;
  open: boolean;
  onClose: ModalCloseHandler;
  setNamespaceQuotaData?: React.Dispatch<
    React.SetStateAction<SolutionNamespaceRscLimitItem | undefined>
  >;
};

const containerStyle = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  minHeight: '40rem',
  justifyContent: 'flex-start',
};

function SiteClusterNamespaceRscLimitEditModal({
  siteId,
  title,
  clusterId,
  clusterName = '',
  open,
  onClose,
  setNamespaceQuotaData,
}: Props) {
  const { showAlert } = useAlert();
  const actions = useStoreUnitActions(solutionsStore);
  const [clusterQuotadata, loading] = useSolutionNamespaceRscLimit();
  const [namespaceRscQuotasData, setNamespaceRscQuotasData] = useState<
    SolutionNamespaceRscQuotasDetailItem | undefined
  >();
  const [formData, setFormData] = useState({});
  const [namespaceQuotaLoader, setNamespaceQuotaLoader] = useState(false);
  const [error, setError] = useState<{ title: string; message: string } | null>(
    null
  );
  const [showError, setShowError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const namespaceName = SiteNamespace.CoreAndCommon;

  const processNamespaceQuotaData = (quotaDetail, quotaData) => ({
    ...quotaData,
    requestsCpu: quotaDetail?.requestsCpu?.replace(/\D/g, '') || '',
    requestsMemory: quotaDetail?.requestsMemory?.replace(/\D/g, '') || '',
    limitsCpu: quotaDetail?.limitsCpu?.replace(/\D/g, '') || '',
    limitsMemory: quotaDetail?.limitsMemory?.replace(/\D/g, '') || '',
    requestsStorage: quotaDetail?.requestsStorage?.replace(/\D/g, '') || '',
  });

  useEffect(() => {
    actions.clusterRscLimit({
      siteId,
      namespaceName,
      clusterIds: { clusterIds: [clusterId] },
    });
  }, [clusterId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (clusterId) {
          setNamespaceQuotaLoader(true);
          const apiUrl = generatePath(
            API_SITE_CLUSTER_NAMESPACE_QUOTAS_DETAIL,
            {
              siteId,
              clusterId,
            }
          );
          try {
            const response = await pepApiRequest({
              method: RequestMethod.Get,
              url: apiUrl,
            });
            setNamespaceRscQuotasData(
              mapSiteNamespaceRscQuotasDetailData(response, clusterId)
            );
            setNamespaceQuotaLoader(false);
            return;
          } catch (error) {
            setNamespaceQuotaLoader(false);
          }
        }
      } catch (error) {
        console.error('Error in getting namespace quotas details:', error);
      }
    };
    fetchData();
  }, [clusterId]);

  useEffect(() => {
    if (clusterQuotadata) {
      const updatedFormData = processNamespaceQuotaData(
        clusterQuotadata?.[0],
        namespaceRscQuotasData
      );

      setFormData((prevFormData) => {
        const hasChanged =
          JSON.stringify(prevFormData) !== JSON.stringify(updatedFormData);
        return hasChanged ? updatedFormData : prevFormData;
      });
    }
  }, [namespaceRscQuotasData, clusterQuotadata]);

  const handleApiError = ({ detail }: CustomEvent<ErrorResponseBody>) => {
    const { errors } = detail;
    if (isArray(errors)) {
      const { title, detail: message } = errors[0];
      setError({ title, message });
    }
  };

  useEffect(() => {
    document.addEventListener(API_ERROR_EVENT, handleApiError as EventListener);
    return () => {
      document.removeEventListener(
        API_ERROR_EVENT,
        handleApiError as EventListener
      );
    };
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: numericInputValidation(value),
    }));
  };

  const onSubmit = (params) => {
    setSubmitting(true);
    pepApiRequest({
      method: RequestMethod.Put,
      url: generateApiPath(API_SOLUTION_SITES_CLUSTERS_RES_SETTING, {
        siteId,
        namespaceName,
      }),
      skipErrorAlert: true,
      skipCustomAlert: true,
      params: getSetClusterNamespaceRscLimitParams(
        params,
        clusterId,
        clusterName
      ),
    })
      .then(() => {
        setSubmitting(false);
        setShowError(false);
        setError(null);
        showAlert({
          type: AlertType.Info,
          title: 'Quota limits saved',
          text: `Quota limits modified for the ${clusterName} successfully.`,
        });
        onClose();
        actions.clusterRscLimit({
          siteId,
          namespaceName,
          clusterIds: { clusterIds: [clusterId] },
        });
        pepApiRequest({
          method: RequestMethod.Post,
          url: generatePath(API_SOLUTION_SITES_CLUSTERS_RES_SETTING, {
            siteId,
            namespaceName: SiteNamespace.CoreAndCommon,
          }),
          params: { clusterIds: [clusterId] },
        }).then((response) => {
          if (setNamespaceQuotaData) {
            setNamespaceQuotaData(response[0]);
          }
        });
      })
      .catch(() => {
        setSubmitting(false);
        setShowError(true);
      });
  };

  const fields = useMemo(
    () => getSolutionClusterNamespaceRscLimitFields(handleChange, formData),
    [formData]
  );

  const [form, handleSubmit] = useForm({
    values: formData,
    fields,
    sx: containerStyle,
    onSubmit,
  });

  const handleClose = () => {
    onClose();
    setError(null);
  };

  return (
    <ModalDialog
      open={open}
      title={title}
      showCancelBtn={true}
      cancelBtnText={'Back'}
      confirmBtnText={'Save'}
      loading={namespaceQuotaLoader || loading}
      onConfirm={handleSubmit}
      onClose={handleClose}
      maxWidth="xl"
      fullWidth
      titleStyle={{ fontWeight: '500' }}
    >
      <Box>
        {error && (
          <Alert
            onClose={handleClose}
            color="error"
            sx={{
              minHeight: '0px',
              '.MuiAlert-icon': { paddingTop: '15px' },
              '.MuiAlert-action': { paddingTop: '12px' },
            }}
          >
            {error.title && (
              <AlertTitle sx={{ fontFamily: 'Roboto', fontSize: '18px' }}>
                {error.title}
              </AlertTitle>
            )}
            <Typography sx={{ fontFamily: 'Roboto', fontSize: '16px' }}>
              {error.message}
            </Typography>
          </Alert>
        )}
      </Box>
      {submitting && <PageCardLoader />}
      {form}
    </ModalDialog>
  );
}

export default SiteClusterNamespaceRscLimitEditModal;
