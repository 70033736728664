import { DataTableActions } from 'src/components/DataTable';
import { isModalComplete } from 'src/components/ModalDialog';
import { useModal } from 'src/hooks';
import { useStoreUnitActions } from 'src/redux/helpers';
import { useUserRoles } from 'src/auth';
import usersStore from '../../redux';
import { ApplicationItem } from '../../types';
import ApplicationListDeleteModal from './ApplicationListDeleteModal';
import ApplicationListEditModal from './ApplicationListEditModal';

type Props = {
  item?: ApplicationItem;
};

function ApplicationListAction({ item }: Props) {
  const actions = useStoreUnitActions(usersStore);
  const { isPlatformReader } = useUserRoles();
  const onClose = (event, reason) =>
    isModalComplete(reason) && actions.applicationList(item?.id);

  const [EditDialog, handleEdit] = useModal(ApplicationListEditModal, {
    item,
    onClose,
  });

  const [DeleteDialog, handleDelete] = useModal(ApplicationListDeleteModal, {
    id: item?.id,
    onClose,
  });

  return (
    <DataTableActions
      onEdit={handleEdit}
      onDelete={handleDelete}
      canDelete={!isPlatformReader}
      canEdit={!isPlatformReader}
    >
      {EditDialog}
      {DeleteDialog}
    </DataTableActions>
  );
}

export default ApplicationListAction;
