import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PaginationDisplayedItems from './components/PaginationDisplayedItems';
import PaginationPageSizeSelector from './components/PaginationPageSizeSelector';
import { getPageCount } from './utils';

type Props = BoxProps & {
  pageIndex: number;
  goToPage: (index: number) => void;
  pageSize: number;
  pageSizeOptions: number[];
  onPageSizeChange: (value: number) => void;
  totalItems: number;
};

function Pagination({
  pageIndex,
  pageSize,
  pageSizeOptions,
  totalItems,
  goToPage,
  onPageSizeChange,
  ...rest
}: Props) {
  const totalPages = getPageCount(pageSize, totalItems);
  const isPrevPageDisabled = pageIndex < 1;
  const isNextPageDisabled = pageIndex >= totalPages - 1;
  const goToFirstPage = () => goToPage(0);
  const goToLastPage = () => goToPage(totalPages - 1);
  const goToPreviousPage = () => goToPage(pageIndex - 1);
  const goToNextPage = () => goToPage(pageIndex + 1);

  return (
    <Box data-testid="pagination" textAlign="right" {...rest}>
      <IconButton
        data-testid="pagination-first-page-btn"
        disabled={isPrevPageDisabled}
        onClick={goToFirstPage}
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        data-testid="pagination-prev-page-btn"
        disabled={isPrevPageDisabled}
        onClick={goToPreviousPage}
      >
        <NavigateBeforeIcon />
      </IconButton>
      <PaginationPageSizeSelector
        onChange={onPageSizeChange}
        options={pageSizeOptions}
        value={pageSize}
      />
      <IconButton
        data-testid="pagination-next-page-btn"
        disabled={isNextPageDisabled}
        onClick={goToNextPage}
      >
        <NavigateNextIcon />
      </IconButton>
      <IconButton
        data-testid="pagination-last-page-btn"
        disabled={isNextPageDisabled}
        onClick={goToLastPage}
      >
        <LastPageIcon />
      </IconButton>
      <PaginationDisplayedItems
        pageSize={pageSize}
        pageIndex={pageIndex}
        totalItems={totalItems}
      />
    </Box>
  );
}

export default Pagination;
