import React, {
  ChangeEventHandler,
  ForwardedRef,
  forwardRef,
  ReactNode,
} from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Tooltip } from '@mui/material';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { addOrRemoveValFromArr, updateEventTargetValue } from 'src/utils';
import { Value, Option } from './types';

type Props = FormControlProps & {
  label?: ReactNode;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  options?: Option[];
  value?: Value[];
  helperText?: ReactNode;
  labelStyle?: any;
  showTooltip?: boolean;
};

function CheckboxGroup(
  {
    id,
    label,
    onChange,
    options = [],
    value = [],
    error,
    helperText,
    labelStyle,
    showTooltip,
    ...rest
  }: Props,
  ref: ForwardedRef<HTMLInputElement>
) {
  const handleChange = (event, newValue) => {
    if (onChange) {
      newValue = addOrRemoveValFromArr<Value>(value, newValue);
      onChange(updateEventTargetValue(event, id, newValue));
    }
  };

  return (
    <FormControl
      data-testid="checkbox-group"
      ref={ref}
      error={error}
      variant="standard"
      sx={{ gap: 1 }}
      {...rest}
    >
      {label && (
        <FormLabel data-testid="checkbox-group-label">{label}</FormLabel>
      )}
      <FormGroup>
        {options.map(({ label, value: optionValue, disabled, tooltipInfo }) => (
          <FormControlLabel
            control={
              <Checkbox
                data-testid={`checkbox-group-item-${optionValue}`}
                name={label}
                checked={value?.includes(optionValue)}
                disabled={disabled}
                onClick={(event) => handleChange(event, optionValue)}
                value={optionValue}
              />
            }
            key={optionValue}
            label={
              showTooltip ? (
                <div>
                  {label}
                  <Tooltip
                    title={tooltipInfo}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: '#24242499',
                          color: '#fff',
                          border: 'none',
                        },
                      },
                      arrow: {
                        sx: {
                          '::before': {
                            border: 'none',
                            backgroundColor: '#24242499',
                          },
                        },
                      },
                    }}
                  >
                    <InfoOutlined
                      fontSize="small"
                      color="info"
                      sx={{ ml: 1 }}
                    />
                  </Tooltip>
                </div>
              ) : (
                label
              )
            }
            sx={labelStyle ?? { mr: 6 }}
          />
        ))}
      </FormGroup>
      {helperText && (
        <FormHelperText data-testid="checkbox-group-helper-text">
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default forwardRef(CheckboxGroup);
