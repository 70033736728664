import { useParams } from 'react-router-dom';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { generateApiPath } from 'src/utils';
import { useStoreUnitActions } from 'src/redux/helpers';
import { API_SOLUTION_COSMOS_SUBSCRIPTION_UPDATE } from '../../constants';
import { SolutionCosmosSubscriptionItem } from '../../types';
import {
  getCosmosSubscriptionItemReqParams,
  mapCosmosApiToView,
} from '../utils';
import solutionsStore from '../../redux';
import SolutionCosmosSubscriptionEditModal from './SolutionCosmosSubscriptionEditModal';

type Props = {
  item: SolutionCosmosSubscriptionItem;
  open: boolean;
  onClose: ModalCloseHandler;
};

function SolutionCosmosSubscriptionEditContainer({
  item,
  open,
  onClose,
}: Props) {
  const { showAlert } = useAlert();
  const { solutionId } = useParams();
  const actions = useStoreUnitActions(solutionsStore);
  const [handleSubmit, loading] =
    useStatefulApi<SolutionCosmosSubscriptionItem>(
      (params) =>
        pepApiRequest({
          method: RequestMethod.Put,
          url: generateApiPath(API_SOLUTION_COSMOS_SUBSCRIPTION_UPDATE, {
            solutionId: item?.solutionId,
            subscriptionId: item?.id,
          }),
          params: getCosmosSubscriptionItemReqParams(params),
        }),
      () => {
        showAlert({
          type: AlertType.Info,
          title: 'Cosmos subscription saved',
          text: 'Cosmos subscription modified successfully.',
        });
        onClose(null, ModalCloseReason.completeAction);
        actions.cosmosSubscriptionById(solutionId);
      }
    );

  const cosmosItem: any = mapCosmosApiToView(item);

  return (
    <SolutionCosmosSubscriptionEditModal
      item={cosmosItem}
      title={`Edit cosmos subscription`}
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      loading={loading}
    />
  );
}

export default SolutionCosmosSubscriptionEditContainer;
