import React from 'react';
import { useLocation } from 'react-router-dom';
import * as ROUTES from 'src/router/constants';
import {
  routes as rootRoutes,
  RouteProps,
  getRoute,
  slicePath,
} from 'src/router';
import { BreadcrumbsItems } from 'src/components/BreadCrumbs';

const getBreadcrumbsItem = (
  pathName: string,
  routes: RouteProps[],
  rootPath?: string
) => {
  const route = getRoute(pathName, routes, rootPath);
  const { children, path, meta } = route || {};
  const { title, TitleComponent } = meta || {};
  const pathTitle = title || slicePath(pathName, -1).replace('/', '');

  return {
    children,
    path: path && pathName,
    name: TitleComponent ? <TitleComponent title={pathTitle} /> : pathTitle,
  };
};

const recAddBreadcrumbsItems = ({ items, index, pathname, routes }) => {
  const currPath = slicePath(pathname, 0, index + 1);
  const parentPath = slicePath(pathname, 0, index);
  const item = getBreadcrumbsItem(currPath, routes, parentPath);
  items.push(item);

  if (item.children) {
    recAddBreadcrumbsItems({
      routes: item.children,
      index: index + 1,
      items,
      pathname,
    });
  }
};

export const useBreadcrumbsConfig = (
  routes?: RouteProps[]
): BreadcrumbsItems => {
  const { pathname } = useLocation();
  const rootPath = slicePath(pathname, 0, 1);
  const items = [
    getBreadcrumbsItem(ROUTES.DASHBOARD, rootRoutes),
    getBreadcrumbsItem(rootPath, rootRoutes),
  ];

  if (routes) {
    recAddBreadcrumbsItems({
      index: 1,
      items,
      pathname,
      routes,
    });
  }

  return items.filter(({ path }) => path);
};
