import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import { CompanyItemField } from '../types';

export const newCompanyFormFields = [
  {
    name: CompanyItemField.Name,
    label: 'Company name',
    placeholder: 'Enter company name',
    Component: TextField,
    validation: Yup.string().required('Company display name field is required'),
    required: true,
  },
];
