import { all } from 'redux-saga/effects';
import { authStore } from 'src/auth';
import { solutionsStore } from 'src/pages/Solutions';
import { companiesStore } from 'src/pages/Companies';
import { otpStore } from 'src/pages/Otp';
import { usersStore } from 'src/pages/Users';
import { sitesStore } from 'src/pages/Sites';

export default function* rootSaga() {
  yield all([
    authStore.saga(),
    solutionsStore.saga(),
    companiesStore.saga(),
    otpStore.saga(),
    usersStore.saga(),
    sitesStore.saga(),
  ]);
}
