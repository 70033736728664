import {
  DependencyList,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

export const useUnmountEffect = (callback: () => void) =>
  useEffect(() => callback, []);

export const useSyncState = <T = any>(
  initialState: T,
  deps?: DependencyList
): [T, Dispatch<SetStateAction<T>>] => {
  const [state, setState] = useState<T>(initialState);

  useEffect(() => {
    setState(initialState);
  }, deps ?? [initialState]);

  return [state, setState];
};
