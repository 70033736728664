import { createDatasetSlice } from 'src/redux/helpers';
import { RequestMethod, pepApiRequest } from 'src/services';
import { generateApiPath } from 'src/utils';
import {
  API_SOLUTION_DASHBOARD_DEVICEGROUPS_COMPANY,
  SOLUTIONS_STORE,
  SOLUTIONS_STORE_DASHBOARDS_DEVICEGROUPS,
} from '../constants';
import { mapSolutionDashboardDeviceGroupData } from './utils';

const api = (companyId: string) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: generateApiPath(API_SOLUTION_DASHBOARD_DEVICEGROUPS_COMPANY, {
      companyId,
    }),
  }).then((items) => mapSolutionDashboardDeviceGroupData(items));

const solutionsDashboardsSlice = createDatasetSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTIONS_STORE_DASHBOARDS_DEVICEGROUPS,
});

export default solutionsDashboardsSlice;
