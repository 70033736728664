export enum SolutionDashboardComponentsField {
  Id = 'Id',
  SolutionId = 'SolutionId',
}

export enum DashboardSummary {
  dashboardId = 'dashboardId',
  name = 'name',
}

export type SolutionDashboardComponentField = {
  id: string;
  solutionId: string;
  dashboardSummary: DashboardSummary;
};

export type DashboardsSummary = {
  dashboardId;
  name;
};

export type DashboardSummaryList = {
  data: DashboardsSummary[];
};

export type DashboardsDeviceGroup = {
  id: string;
  solutionId: string;
  modelId: string;
  displayName: string;
  description: string;
  filters: string;
  companyId: string;
};

export type DashboardsDeviceGroupList = {
  [id: string]: any;
  data: DashboardsDeviceGroup[];
};

export const DASHBOARD_INTERVAL_SIZE = [
  {
    value: '1m',
    label: '1 minutes',
  },
  {
    value: '2m',
    label: '2 minutes',
  },
  {
    value: '5m',
    label: '5 minutes',
  },
  {
    value: '10m',
    label: '10 minutes',
  },
  {
    value: '15m',
    label: '15 minutes',
  },
  {
    value: '20m',
    label: '20 minutes',
  },
  {
    value: '30m',
    label: '30 minutes',
  },
  {
    value: '60m',
    label: '60 minutes',
  },
];

export const DASHBOARD_Aggregate = [
  {
    value: 'avg',
    label: 'avg',
  },
  {
    value: 'count',
    label: 'count',
  },

  {
    value: 'max',
    label: 'max',
  },
  {
    value: 'min',
    label: 'min',
  },
  {
    value: 'sum',
    label: 'sum',
  },
];
