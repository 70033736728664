import React from 'react';
import ModalDialog, { ModalCloseHandler } from 'src/components/ModalDialog';
import { loadDataFromStorage } from 'src/utils';
import { SolutionType } from '../../types';

type Props = {
  open: boolean;
  openDeviceDetails: () => void;
  onClose: ModalCloseHandler;
};

function SolutionDevicesNavToDetailsModal({
  open,
  onClose,
  openDeviceDetails,
}: Props) {
  const onConfirm = () => {
    openDeviceDetails();
    onClose();
  };
  const solutionType = loadDataFromStorage('solutionType');
  return (
    <ModalDialog
      open={true}
      title={
        solutionType === SolutionType.ioTH
          ? 'Navigate to Device Details'
          : 'Navigate to IoT Central'
      }
      text={
        solutionType === SolutionType.ioTH
          ? 'Are you sure to open device Details'
          : 'Are you sure to open IoT Central device page?'
      }
      onClose={onClose}
      onConfirm={onConfirm}
    />
  );
}

export default SolutionDevicesNavToDetailsModal;
