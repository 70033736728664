import React from 'react';
import { LinearProgress, Box, Typography } from '@mui/material';

interface LinearProgressWithLabelProps {
  actual?: number | null;
  used?: number | null;
  message?: string;
  thickness?: number;
  width?: number | string;
}

const LinearProgressWithLabel: React.FC<LinearProgressWithLabelProps> = ({
  actual,
  used,
  message = 'No Data',
  thickness = 10,
  width = '75%',
}) => {
  if (actual == null || used == null) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        width={width}
        mt={2}
        mb={2}
      >
        <LinearProgress
          variant="determinate"
          value={0}
          sx={{
            height: thickness,
            width: '100%',
            borderRadius: thickness / 2,
            backgroundColor: '#E0E0E0',
          }}
        />
        <Typography variant="caption" component="div" color="black" mt={1}>
          {message}
        </Typography>
      </Box>
    );
  }

  if (actual === 0 && used === 0) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        width={width}
        mt={2}
        mb={2}
      >
        <LinearProgress
          variant="determinate"
          value={0}
          sx={{
            height: thickness,
            width: '100%',
            borderRadius: thickness / 2,
            backgroundColor: '#E0E0E0',
          }}
        />
        <Typography variant="caption" component="div" color="black" mt={1}>
          No Capacity
        </Typography>
      </Box>
    );
  }

  let percentage = (used / actual) * 100;

  // Set the color based on the percentage
  let color = '#0047BA'; // Default color (up to 75%)
  if (percentage > 75 && percentage < 95) {
    color = '#FFA500'; // Warning color (between 75% and 95%)
  } else if (percentage >= 95) {
    color = '#FF0000'; // Red color (95% and above)
  }

  // If used exceeds actual, set percentage to 100% and color to red
  if (used > actual) {
    percentage = 100; // Cap the percentage at 100%
    color = '#FF0000'; // Full red for over capacity
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      width={width}
      mt={2}
      mb={2}
    >
      {/* Progress bar */}
      <LinearProgress
        variant="determinate"
        value={percentage}
        sx={{
          height: thickness,
          width: '100%',
          borderRadius: thickness / 2,
          backgroundColor: '#E0E0E0',
          '& .MuiLinearProgress-bar': {
            backgroundColor: color,
            borderRadius: thickness / 2,
          },
        }}
      />

      {/* Message below the bar */}
      <Typography variant="caption" component="div" color="black" mt={1}>
        {message}
      </Typography>
    </Box>
  );
};

export default LinearProgressWithLabel;
