import { createDatasetSlice, ResponseMapping } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import { generateApiPath } from 'src/utils';
import {
  API_EDGEWORKLOAD_DEPLOYMENT_STATUS,
  SOLUTIONS_STORE,
  SOLUTIONS_STORE_SOLUTION_WORKLOAD_STATUS,
} from '../constants';

const api = (name: any) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: generateApiPath(API_EDGEWORKLOAD_DEPLOYMENT_STATUS, { name }),
  });

const solutionWorkloadStatus = createDatasetSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTIONS_STORE_SOLUTION_WORKLOAD_STATUS,
  responseMapping: ResponseMapping.Default,
});

export default solutionWorkloadStatus;
