import React from 'react';
import range from 'lodash/range';
import DataTableBlankRow from './DataTableBlankRow';
import { BACKDROP_ROWS_COUNT } from '../constants';

type Props = {
  rowsCount: number;
};

function DataTableBackdropRows({ rowsCount }: Props) {
  const showBackdrop = rowsCount < BACKDROP_ROWS_COUNT;

  return (
    <>
      {showBackdrop &&
        range(BACKDROP_ROWS_COUNT - rowsCount).map((index) => (
          <DataTableBlankRow key={index} />
        ))}
    </>
  );
}

export default DataTableBackdropRows;
