import React from 'react';
import { useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import { generateApiPath } from 'src/utils';
import { useStoreUnitActions } from 'src/redux/helpers';
import { DataTableActionsWrapper } from 'src/components/DataTable';
import { SolutionDeviceItem } from '../../types';
import * as ROUTES from '../../constants';
import solutionsStore from '../../redux';

type Props = {
  item?: SolutionDeviceItem;
};

function SolutionDevicesHostOsCell({ item }: Props) {
  const { devicesPersist } = useStoreUnitActions(solutionsStore);
  const navigate = useNavigate();
  const handleNavigate = () => {
    devicesPersist.add(item);
    navigate(
      generateApiPath(ROUTES.SOLUTION_DEVICE_DETAILS, { deviceId: item?.id })
    );
  };

  return (
    <DataTableActionsWrapper>
      {item && item.hostOSVersion ? (
        <Link title="Device deployment" onClick={handleNavigate}>
          {item.hostOSVersion}
        </Link>
      ) : (
        <span>–</span>
      )}
    </DataTableActionsWrapper>
  );
}

export default SolutionDevicesHostOsCell;
