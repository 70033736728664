export type SolutionCompanyItem = {
  id: string;
  name: string;
  companyId?: string;
  oAuthClientId?: string;
};

export enum SolutionCompanyField {
  Id = 'id',
  Name = 'name',
  Company = 'companyId',
  ClientId = 'oAuthClientId',
}
