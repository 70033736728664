import { baseTheme } from '../baseTheme';

const { palette } = baseTheme;

export default {
  defaultProps: {
    variant: 'body2',
  },
  styleOverrides: {
    root: {
      cursor: 'pointer',
      padding: 0,
      disabled: {
        color: palette.neutral.main,
      },
      '&:hover': {
        backgroundColor: 'transparent',
        color: palette.primary.light,
        textDecoration: 'underline',
      },
      '&:active': {
        color: palette.primary.dark,
      },
    },
  },
};
