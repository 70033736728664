import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { ReactNode } from 'react';
import { PageCardLoader } from 'src/components/PageCardLayout';
import { ModalCloseHandler } from './types';

type Props = Omit<DialogProps, 'onClose'> & {
  title: string;
  text?: string;
  loading?: boolean;
  onClose?: ModalCloseHandler;
  onCancel?: (value: any) => void;
  onConfirm?: (value: any) => void;
  cancelBtnText?: string;
  confirmBtnText?: string;
  extraActions?: ReactNode;
  showConfirmBtn?: boolean;
  showCancelBtn?: boolean;
  stopPropagation?: boolean;
  titleStyle?: any;
  actionsStyle?: any;
  contentStyle?: any;
  disabled?: boolean;
};

function ModalDialog({
  children,
  extraActions,
  loading,
  open,
  title,
  text,
  onCancel,
  onClick,
  onClose,
  onConfirm,
  cancelBtnText = 'Cancel',
  confirmBtnText = 'Ok',
  showCancelBtn = true,
  showConfirmBtn = true,
  stopPropagation = true,
  titleStyle = {},
  actionsStyle = {},
  contentStyle = {},
  disabled = false,
  ...rest
}: Props) {
  const showActions = extraActions || showCancelBtn || showConfirmBtn;

  const handleClick = (event) => {
    if (stopPropagation) {
      event.stopPropagation();
    }
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose as (event, reason?: string) => void}
      onClick={handleClick}
      data-testid="modal-dialog"
      {...rest}
    >
      <DialogTitle style={titleStyle} data-testid="modal-dialog-title">
        {title}
        <IconButton
          data-testid="modal-dialog-close-btn"
          onClick={onClose}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <Box position="relative">
        <DialogContent style={contentStyle}>
          {text}
          {children}
        </DialogContent>
        {showActions && (
          <DialogActions
            data-testid="modal-dialog-actions"
            style={actionsStyle}
          >
            {showCancelBtn && (
              <Button
                data-testid="modal-dialog-cancel-btn"
                variant="outlined"
                onClick={onCancel || onClose}
              >
                {cancelBtnText}
              </Button>
            )}
            {showConfirmBtn && (
              <Button
                data-testid="modal-dialog-confirm-btn"
                variant="contained"
                onClick={onConfirm}
                autoFocus
                disabled={disabled}
              >
                {confirmBtnText}
              </Button>
            )}
            {extraActions}
          </DialogActions>
        )}
        {loading && <PageCardLoader data-testid="modal-dialog-loader" />}
      </Box>
    </Dialog>
  );
}

export default ModalDialog;
