import { isArray } from 'lodash';
import { useEffect, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import ModalDialog, {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { useStoreUnitActions } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { useForm } from 'src/components/Form/hooks';
import { Option } from 'src/components/SelectOption';
import solutionsStore from '../../redux';
import {
  API_ALL_SOURCE_DOCKER_IMAGES,
  API_SOURCE_DOCKER_IMAGES,
} from '../../constants';
import { SourcePullImageFormFields } from './formFields';
import {
  SolutionSourceImageRepoContainerType,
  SolutionSourceImageRepositoryItem,
} from '../../types';

type Props = {
  item?: SolutionSourceImageRepositoryItem;
  open: boolean;
  onClose: ModalCloseHandler;
};

function SolutionSourcePullImageConfig({ item, open, onClose }: Props) {
  const { showAlert } = useAlert();
  const { solutionId } = useParams();
  const actions = useStoreUnitActions(solutionsStore);
  const [imagesOptions, setOptions] = useState<Option[]>([]);
  const [loadingImages, setImageLoading] = useState<boolean>(false);
  const [onSubmit, loading] = useStatefulApi(
    (params) =>
      pepApiRequest({
        method: RequestMethod.Post,
        url: generatePath(API_SOURCE_DOCKER_IMAGES, { solutionId }),
        params,
      }),
    () => {
      showAlert({
        type: AlertType.Info,
        title: 'Images are pulled',
        text: 'Selected images are pulled successfully',
      });
      onClose(null, ModalCloseReason.completeAction);
      actions.details(solutionId);
    }
  );
  const [canSelectImage, setCanSelectImage] = useState(true);
  const getDockerImages = () => {
    setImageLoading(true);
    pepApiRequest({
      method: RequestMethod.Get,
      url: generatePath(API_ALL_SOURCE_DOCKER_IMAGES, { solutionId }),
    }).then((images) => {
      if (!isArray(images)) {
        setImageLoading(false);
        return;
      }
      const imagesOptions = images.map((imageName) => {
        const option: Option = {
          label: imageName,
          value: imageName,
        };
        return option;
      });
      setOptions(imagesOptions);
      setImageLoading(false);
    });
  };
  useEffect(() => {
    if (
      item?.containerRepositoryType === SolutionSourceImageRepoContainerType.Ecr
    ) {
      getDockerImages();
    } else if (
      item?.AcrClientId === '' ||
      item?.AcrClientId === undefined ||
      item?.AcrClientSecret === '' ||
      item?.AcrClientSecret === undefined
    ) {
      setCanSelectImage(false);
    } else {
      getDockerImages();
    }
  }, []);

  const [form, handleSubmit] = useForm({
    fields: SourcePullImageFormFields(imagesOptions, canSelectImage),
    fieldProps: { sx: { height: '40rem' } },
    onSubmit,
  });

  return (
    <ModalDialog
      open={open}
      title="Pull docker images"
      loading={loading || loadingImages}
      onClose={onClose}
      onConfirm={handleSubmit}
      confirmBtnText="Submit"
      maxWidth="sm"
      fullWidth
    >
      {form}
    </ModalDialog>
  );
}

export default SolutionSourcePullImageConfig;
