// routes
export const USERDETAILS = 'User';
export const APPLICATION_LIST = 'Application-List';

// api
export const API_APPLICATION = '/PlatformRoles/serviceRoles';
export const API_APPLICATION_ROLES = '/PlatformRoles/applicationroles';
export const API_APPLICATION_ITEM = `${API_APPLICATION}/:id`;
export const API_APPLICATION_EDIT_ROLES = `${API_APPLICATION_ROLES}/:id`;

// redux
export const USERS_STORE = 'users';
export const APPLICATION_STORE_LIST = 'applicationList';
export const USERS_STORE_LIST = 'userslist';
export const APPLICATION_ROLES = 'applicationRoles';
