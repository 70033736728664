import { all } from 'redux-saga/effects';
import map from 'lodash/map';
import isObjectLike from 'lodash/isObjectLike';
import pickBy from 'lodash/pickBy';
import set from 'lodash/set';
import mapValues from 'lodash/mapValues';
import reduce from 'lodash/reduce';
import { createReducer } from '@reduxjs/toolkit';
import { composeReducers } from './composeReducers';

export function composeSagas(list: any[]) {
  // eslint-disable-next-line func-names
  return function* () {
    yield all(map(list, (saga) => saga && saga()));
  };
}

const mergeSliceProp = (prop: any, slices: any, extra: any) => ({
  ...reduce(slices, (acc, s) => ({ ...acc, ...(s[prop] || {}) }), {}),
  ...(extra || {}),
});
const mapSliceProp = (prop: any, slices: any, extra: any) => ({
  ...reduce(
    slices,
    (acc, value, key) => (value?.[prop] ? set(acc, [key], value[prop]) : acc),
    {}
  ),
  ...extra,
});
export function composeSlices(rawSlices: any, extra: any) {
  const slices = pickBy(rawSlices, isObjectLike);
  const { extraReducers, selectors, initialState, dispatchers, ...rest } =
    extra;
  const allInitialState = mergeSliceProp(
    'initialState',
    slices,
    initialState || {}
  );
  const extraReducer = createReducer(undefined, extraReducers || {});
  const reducer = composeReducers(
    allInitialState,
    ...map(slices, (s) => s.reducer),
    extraReducer
  );

  return {
    initialState: allInitialState,
    reducer,
    *saga() {
      yield all(
        map(slices, (s) => s.saga && s.saga()).concat(
          map(extra.extraSagas || {}, (s) => s)
        )
      );
    },
    actions: mapValues(slices, (s) => s.actions),
    selectors: mapSliceProp('selectors', slices, selectors),
    dispatchers: mapSliceProp('dispatchers', slices, dispatchers),
    middlewares: map(slices, (s) => s.middleware).filter(
      (middleware) => middleware
    ),
    ...rest,
  };
}
