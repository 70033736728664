import React, { SyntheticEvent } from 'react';
import Chip from '@mui/material/Chip';
import { OptionValue } from 'src/components/SelectOption';

type Props<T> = {
  label?: string;
  value: T;
  onDelete: (event: SyntheticEvent, value: T) => void;
};

function AutoCompleteBoxDisplayValueItem<T extends OptionValue>({
  label,
  value,
  onDelete,
}: Props<T>) {
  const handleDelete = (event) => {
    onDelete(event, value);
  };

  return <Chip sx={{ mr: 1, mb: 1 }} label={label} onDelete={handleDelete} />;
}

export default AutoCompleteBoxDisplayValueItem;
