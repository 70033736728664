import { useContext, useEffect } from 'react';
import isFunction from 'lodash/isFunction';
import { RowContext } from '../components/DataTableRowContext';
import { RowActionHandler } from '../types';

export const useRowClickHandler = (callback?: RowActionHandler) => {
  const [, setCallback] = useContext(RowContext);

  useEffect(() => {
    if (isFunction(callback)) {
      setCallback(() => callback);
    }
  }, [setCallback]);
};
