import React from 'react';
import FieldsSet from 'src/components/FieldsSet';
import { PageCardSection } from 'src/components/PageCardLayout';
import SolutionDeviceDeploymentNotification from './SoluitonDeviceDeploymentNotification';
import { SolutionDeviceItemField, SolutionDeviceItem } from '../../types';

const fields = [
  {
    id: SolutionDeviceItemField.HostOsVersion,
    label: 'Host OS version',
  },
  {
    id: SolutionDeviceItemField.DisplayName,
    label: 'Device name',
  },
  {
    id: SolutionDeviceItemField.DeviceId,
    label: 'Device ID',
  },
];

type Props = {
  item?: SolutionDeviceItem;
};

function SolutionDeviceDeploymentDetails({ item }: Props) {
  return (
    <PageCardSection>
      <SolutionDeviceDeploymentNotification>
        Current details:
      </SolutionDeviceDeploymentNotification>
      <FieldsSet data={item} fields={fields} gap={2} />
    </PageCardSection>
  );
}

export default SolutionDeviceDeploymentDetails;
