import { Radio, RadioGroup } from '@mui/material';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import { ChangeEventHandler, ForwardedRef, forwardRef, ReactNode } from 'react';
import { Option } from './types';

type Props = FormControlProps & {
  label?: ReactNode;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  options?: Option[];
  value?: string;
  helperText?: ReactNode;
};

function RadioButtonGroup(
  {
    id,
    label,
    onChange,
    options = [],
    value = '',
    error,
    helperText,
    ...rest
  }: Props,
  ref: ForwardedRef<HTMLInputElement>
) {
  const handleChange = (event, newValue) => {
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <FormControl
      data-testid="radio-button-group"
      ref={ref}
      error={error}
      variant="standard"
      sx={{ gap: 1 }}
      {...rest}
    >
      {label && (
        <FormLabel data-testid="radio-button-group-label">{label}</FormLabel>
      )}
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        {options.map(({ label, value: optionValue, disabled }) => (
          <FormControlLabel
            key={optionValue}
            value={optionValue}
            control={<Radio />}
            sx={{
              '&.Mui-disabled': {
                color: '#A5A5A5',
                '& .MuiFormControlLabel-label': {
                  color: '#A5A5A5',
                },
              },
            }}
            disabled={disabled}
            label={label}
          />
        ))}
      </RadioGroup>
      {helperText && (
        <FormHelperText data-testid="radio-button-group-helper-text">
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default forwardRef(RadioButtonGroup);
