import { SolutionNamespaceRscLimitItem } from '../types';

export const getSetDefaultNamespaceRscLimitParams = (
  params: SolutionNamespaceRscLimitItem
) => {
  const newParams = {
    requestsCpu: params.requestsCpu?.concat('m'),
    requestsMemory: params.requestsMemory?.concat('Mi'),
    limitsCpu: params.limitsCpu?.concat('m'),
    limitsMemory: params.limitsMemory?.concat('Mi'),
    requestsStorage: params.requestsStorage?.concat('Mi'),
  };
  const values = JSON.stringify(newParams);

  return {
    metadata: {
      annotations: {
        FieldCattleIoContainerDefaultResourceLimit: values,
      },
    },
  };
};
