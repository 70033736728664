import { generateApiPath, loadDataFromStorage } from 'src/utils';
import { createDatasetConcatSlice } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import { INFINITE_SCROLL_PAGE_SIZE } from 'src/components/DataTable';
import {
  API_DEVICE_GROUP_DEVICES,
  API_DEVICE_GROUP_DEVICES_IOTH,
  API_DEVICES,
  API_DEVICES_IOTH,
  SOLUTIONS_STORE,
  SOLUTIONS_STORE_DEVICES,
} from '../constants';
import { mapSolutionDevicesData } from './utils';
import { SolutionType } from '../types';

const api = ({
  groupId,
  solutionId,
  filterString = '',
  skipToken = '',
  pageSize = INFINITE_SCROLL_PAGE_SIZE,
  sequence = '',
}) => {
  const solutionType = loadDataFromStorage('solutionType');
  const urlPath =
    solutionType.toUpperCase() === SolutionType.ioTH.toUpperCase()
      ? API_DEVICES_IOTH
      : API_DEVICES;

  const groupDevicesURL =
    solutionType.toUpperCase() === SolutionType.ioTH.toUpperCase()
      ? API_DEVICE_GROUP_DEVICES_IOTH
      : API_DEVICE_GROUP_DEVICES;
  return pepApiRequest({
    method: RequestMethod.Get,
    url: generateApiPath(groupId ? groupDevicesURL : urlPath, {
      groupId,
      solutionId,
    }),
    queryParams: { filter: filterString, pageSize, skipToken, sequence },
    skipEncodeUri: true,
  }).then(mapSolutionDevicesData);
};

const solutionsDevicesSlice = createDatasetConcatSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTIONS_STORE_DEVICES,
});

export default solutionsDevicesSlice;
