import TextField from '@mui/material/TextField';
import compact from 'lodash/compact';
import { Option } from 'src/components/SelectOption';
import { SxProps } from 'src/theme/types';
import Select from 'src/components/Select/Select';
import AutoCompleteWithFreeText from 'src/components/AutoComplete/AutoCompleteWithFreeText';
import Switch from 'src/components/Switch';
import * as Yup from 'yup';
import {
  SolutionEdgeWorkloadAuthType,
  SolutionEdgeWorkloadCertVerifytype,
  SolutionEdgeWorkloadItemField,
} from 'src/pages/Solutions/types';
import {
  EDGEWORKLOAD_AUTH_OPTIONS,
  EDGEWORKLOAD_CERTVERIFY_OPTIONS,
  EDGEWORKLOAD_NAMESPACE_OPTIONS,
} from './constants';
import { SiteNamespace } from '../types';

const inputStyle: SxProps = { width: ({ spacing }) => spacing(40) };

export const getWorkloadConfigEditFormFields = (
  isEditMode: boolean,
  gitAuthentication?: string,
  helmAuthentication?: string,
  tlsCertVerification?: string,
  clusterOptions?: Option[],
  selectedClusters?: any,
  clusterOptionsLoading?: boolean
) =>
  compact([
    {
      name: SolutionEdgeWorkloadItemField.Name,
      label: 'Name',
      Component: TextField,
      placeholder: 'Enter name',
      required: true,
      validation: Yup.string().required('Repository name is required'),
      sx: { width: ({ spacing }) => spacing(24) },
      disabled: isEditMode,
    },

    {
      name: SolutionEdgeWorkloadItemField.Description,
      label: 'Description',
      Component: TextField,
      placeholder: 'Enter repository description',
      required: false,
      sx: { width: ({ spacing }) => spacing(56) },
    },
    {
      name: SolutionEdgeWorkloadItemField.RepositoryURL,
      label: 'Repository URL',
      Component: TextField,
      required: true,
      validation: Yup.string().required('Repository URL is required'),
      sx: inputStyle,
    },
    {
      name: SolutionEdgeWorkloadItemField.BranchName,
      label: 'Branch Name',
      Component: TextField,
      required: true,
      validation: Yup.string().required('Branch Name is required'),
      sx: inputStyle,
    },
    {
      name: SolutionEdgeWorkloadItemField.GitAuthentication,
      label: 'Git Authentication',
      Component: Select,
      placeholder: 'Select the option',
      initialValue: SolutionEdgeWorkloadAuthType.None,
      required: false,
      options: EDGEWORKLOAD_AUTH_OPTIONS,
      sx: inputStyle,
    },
    gitAuthentication === SolutionEdgeWorkloadAuthType.HTTPBased && {
      name: SolutionEdgeWorkloadItemField.GitUserName,
      label: 'UserName',
      Component: TextField,
      placeholder: 'Enter the username',
      required: false,
      sx: inputStyle,
    },
    gitAuthentication === SolutionEdgeWorkloadAuthType.HTTPBased && {
      name: SolutionEdgeWorkloadItemField.GitPassword,
      label: 'Password',
      Component: TextField,
      placeholder: 'Enter the password',
      required: false,
      sx: inputStyle,
    },
    gitAuthentication === SolutionEdgeWorkloadAuthType.SSHBased && {
      name: SolutionEdgeWorkloadItemField.GitPublicKey,
      label: 'Public Key',
      Component: TextField,
      placeholder: 'Enter the Public Key',
      required: false,
      sx: inputStyle,
    },
    gitAuthentication === SolutionEdgeWorkloadAuthType.SSHBased && {
      name: SolutionEdgeWorkloadItemField.GitPrivateKey,
      label: 'Private Key',
      Component: TextField,
      placeholder: 'Enter the Private Key',
      required: false,
      sx: inputStyle,
    },
    {
      name: SolutionEdgeWorkloadItemField.HelmAuthentication,
      label: 'Helm Authentication',
      Component: Select,
      placeholder: 'Select the option',
      initialValue: SolutionEdgeWorkloadAuthType.None,
      required: false,
      options: EDGEWORKLOAD_AUTH_OPTIONS,
      sx: inputStyle,
    },
    helmAuthentication === SolutionEdgeWorkloadAuthType.HTTPBased && {
      name: SolutionEdgeWorkloadItemField.HelmUserName,
      label: 'User Name',
      Component: TextField,
      placeholder: 'Enter the username',
      required: false,
      sx: inputStyle,
    },
    helmAuthentication === SolutionEdgeWorkloadAuthType.HTTPBased && {
      name: SolutionEdgeWorkloadItemField.HelmPassword,
      label: 'Password',
      Component: TextField,
      placeholder: 'Enter the password',
      required: false,
      sx: inputStyle,
    },
    helmAuthentication === SolutionEdgeWorkloadAuthType.SSHBased && {
      name: SolutionEdgeWorkloadItemField.HelmPublickey,
      label: 'Public Key',
      Component: TextField,
      placeholder: 'Enter the Public Key',
      required: false,
      sx: inputStyle,
    },
    helmAuthentication === SolutionEdgeWorkloadAuthType.SSHBased && {
      name: SolutionEdgeWorkloadItemField.HelmPrivatekey,
      label: 'Private Key',
      Component: TextField,
      placeholder: 'Enter the Private Key',
      required: false,
      sx: inputStyle,
    },
    {
      name: SolutionEdgeWorkloadItemField.TlsCertVerification,
      label: 'TLS Certificate Verification',
      Component: Select,
      placeholder: 'Select the option',
      initialValue: SolutionEdgeWorkloadCertVerifytype.ValidCert,
      required: false,
      options: EDGEWORKLOAD_CERTVERIFY_OPTIONS,
      sx: inputStyle,
    },
    tlsCertVerification ===
      SolutionEdgeWorkloadCertVerifytype.AdditionalCert && {
      name: SolutionEdgeWorkloadItemField.Certificates,
      label: 'Certificate',
      Component: TextField,
      placeholder: 'Enter the Certificate',
      validation: Yup.string().required('Certificate field is required'),
      required: false,
      sx: inputStyle,
    },
    {
      name: SolutionEdgeWorkloadItemField.Path,
      label: 'Path',
      Component: TextField,
      placeholder: 'Enter the Path',
      required: false,
      sx: inputStyle,
    },
    {
      name: SolutionEdgeWorkloadItemField.TargetNamespace,
      label: 'Target Namespace',
      Component: Select,
      initialValue: SiteNamespace.CoreAndCommon,
      options: EDGEWORKLOAD_NAMESPACE_OPTIONS,
      validation: Yup.string().required('TargetNamespace is required'),
      required: true,
      sx: inputStyle,
    },
    {
      name: SolutionEdgeWorkloadItemField.SiteClusters,
      label: 'Cluster',
      placeholder: 'Select the clusters',
      Component: AutoCompleteWithFreeText,
      multiple: true,
      options: clusterOptions,
      loading: clusterOptionsLoading,
      value: selectedClusters,
      selectedOptions: selectedClusters,
      validation: Yup.array()
        .nullable()
        .required('Cluster is required')
        .min(1, 'At least 1 cluster must be selected'),
      freeSolo: false,
      required: true,
      sx: inputStyle,
    },
    {
      name: SolutionEdgeWorkloadItemField.KeepResources,
      label: 'Keep resources after deletion',
      Component: Switch,
      initialValue: true,
      type: 'checkbox',
      required: false,
      labelStyle: { color: '#7B7B7B', opacity: '80%' },
      sx: inputStyle,
      showInfoIcon: true,
      infoTitle:
        'When enabled above, resources will be kept when deleting a GitRepo or Bundle - only Helm release secrets will be deleted.',
    },
  ]);
