import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { generateApiPath } from 'src/utils';
import {
  API_CREATE_ADLS_SUBSCRIPTIONS,
  API_SAVE_FILE_SUBSCRIPTIONS,
  API_SOLUTION_COSMOS_SUBSCRIPTION_CREATE,
  API_SUBSCRIPTIONS,
  API_SUBSCRIPTIONS_IOTH,
} from '../../constants';
import { SolutionSubscriptionType, SolutionType } from '../../types';
import {
  getAdlsSubscriptionItemReqParams,
  getCosmosSubscriptionItemReqParams,
  getFileSubscriptionItemReqParams,
  getSubscriptionItemReqParams,
} from '../utils';
import SolutionSubscriptionEditModal from './SolutionSubscriptionEditModal';

type Props = {
  open: boolean;
  onClose: ModalCloseHandler;
};

function SolutionSubscriptionCreateContainer({ open, onClose }: Props) {
  const { solutionId, solutionType } = useParams();
  const { showAlert } = useAlert();
  const [subscriptionType, setSubscriptionType] = useState(
    SolutionSubscriptionType.Messages
  );

  const getTitleMessage = (subscriptionType: any) => {
    if (subscriptionType === SolutionSubscriptionType.Files) {
      return 'File subscription is added.';
    }
    if (subscriptionType === SolutionSubscriptionType.ADLS) {
      return 'ADLS subscription is added';
    }
    if (subscriptionType === SolutionSubscriptionType.Cosmos) {
      return 'Cosmos subscription created';
    }
    return 'Message subscription is added.';
  };

  const getSubcriptionApi = () => {
    if (subscriptionType === SolutionSubscriptionType.Files) {
      return API_SAVE_FILE_SUBSCRIPTIONS;
    }
    if (subscriptionType === SolutionSubscriptionType.Messages) {
      return solutionType?.toUpperCase() === SolutionType.ioTH.toUpperCase()
        ? API_SUBSCRIPTIONS_IOTH
        : API_SUBSCRIPTIONS;
    }
    if (subscriptionType === SolutionSubscriptionType.Cosmos) {
      return API_SOLUTION_COSMOS_SUBSCRIPTION_CREATE;
    }
    return API_CREATE_ADLS_SUBSCRIPTIONS;
  };

  const getSubscriptionSpecificParams = (params) => {
    if (subscriptionType === SolutionSubscriptionType.Files) {
      return getFileSubscriptionItemReqParams(params);
    }
    if (subscriptionType === SolutionSubscriptionType.Messages) {
      return getSubscriptionItemReqParams(params);
    }
    if (subscriptionType === SolutionSubscriptionType.Cosmos) {
      return getCosmosSubscriptionItemReqParams(params);
    }
    return getAdlsSubscriptionItemReqParams(params);
  };

  const [handleSubmit, loading] = useStatefulApi(
    (params) =>
      pepApiRequest({
        method: RequestMethod.Post,
        url: generateApiPath(getSubcriptionApi(), { solutionId }),
        params: getSubscriptionSpecificParams(params),
      }),
    () => {
      showAlert({
        type: AlertType.Success,
        title: getTitleMessage(subscriptionType),
        text:
          subscriptionType === SolutionSubscriptionType.Cosmos
            ? 'New cosmos subscription has been created successfully.'
            : 'New subscription has been created successfully.',
      });
      onClose(null, ModalCloseReason.completeAction);
    }
  );

  return (
    <SolutionSubscriptionEditModal
      title="Create new subscription"
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      loading={loading}
      subscriptionType={subscriptionType}
      setSubscriptionType={setSubscriptionType}
    />
  );
}

export default SolutionSubscriptionCreateContainer;
