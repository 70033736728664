import React, { useState } from 'react';
import SideBarNav from './components/SideBarNav';
import SideBarFooter from './components/SideBarFooter';
import SideBarLogo from './components/SideBarLogo';
import SideBarContainer from './components/SideBarContainer';
import SideBarDivider from './components/SideBarDivider';

function SideBar() {
  const [open, setOpen] = useState(false);

  return (
    <SideBarContainer open={open}>
      <SideBarLogo open={open} />
      <SideBarDivider />
      <SideBarNav open={open} />
      <SideBarDivider />
      <SideBarFooter open={open} toggleOpen={setOpen} />
    </SideBarContainer>
  );
}

export default SideBar;
