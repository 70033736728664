import { ForwardedRef, forwardRef, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

function MoreMenu({ actions }, ref: ForwardedRef<HTMLInputElement>) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRowClick = (callback: () => void) => {
    setAnchorEl(null);
    callback();
  };

  return (
    <>
      <IconButton
        aria-label="more"
        onClick={handleClick}
        aria-haspopup="true"
        aria-controls="more-menu"
        title="More"
        sx={{ marginLeft: '1px !important' }}
      >
        <MoreVertIcon sx={{ fontSize: '18px' }} />
      </IconButton>
      <Menu
        sx={{
          ml: ({ spacing }) => spacing(-10),
        }}
        anchorEl={anchorEl}
        keepMounted
        onClose={handleClose}
        open={open}
        ref={ref}
      >
        {actions?.map((action, index) => (
          <MenuItem
            sx={{
              width: ({ spacing }) => spacing(30),
            }}
            key={index}
            style={{ display: `${action.sx}` }}
            onClick={() => {
              handleRowClick(action.onClick());
              handleClose();
            }}
            disabled={action.disabled || false}
          >
            {action.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default forwardRef(MoreMenu);
