import React from 'react';
import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon';
import { keyframes } from '@emotion/react';
import { ReactComponent as LoaderLogo } from 'src/assets/icons/loader.svg';

const spinnerKeyframes = keyframes({
  from: {
    transform: 'rotate(0deg)',
  },
  to: {
    transform: 'rotate(360deg)',
  },
});

const boxStyle = {
  display: 'flex',
  justifyContent: 'center',
  my: 7,
};

const spinnerStyle = {
  animation: `${spinnerKeyframes} 2s linear infinite`,
};

function SearchBarLoader() {
  return (
    <Box sx={boxStyle}>
      <SvgIcon
        sx={spinnerStyle}
        fontSize="large"
        color="primary"
        component={LoaderLogo}
      />
    </Box>
  );
}

export default SearchBarLoader;
