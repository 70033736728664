import { InfoOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

type Props = {
  title?: any;
};

function GuidedTooltip({ title }: Props) {
  return (
    <Tooltip
      title={title}
      placement="right-start"
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: '#24242499',
            color: '#fff',
            border: 'none',
            fontSize: '12px',
            fontWeight: 'normal',
            padding: '10px',
          },
        },
        arrow: {
          sx: {
            '::before': {
              border: 'none',
              backgroundColor: '#24242499',
            },
          },
        },
      }}
    >
      <InfoOutlined
        sx={{ fontSize: '20px', color: '#0047BA', marginBottom: '2px' }}
      />
    </Tooltip>
  );
}

export default GuidedTooltip;
