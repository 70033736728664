import isNil from 'lodash/isNil';
import {
  UserFeature,
  UserRole,
  useUserFeaturesArr,
  useUserRolesArr,
} from 'src/auth';
import { RouteProps } from '../types';
import { routes } from '../routes';
import { isFeatureAllowed, isUserRoleAllowed } from '../utils';

export const useUserRoutes = <T extends RouteProps = RouteProps>(
  useNavLinks?: boolean,
  useDashboardLinks?: boolean
): T[] => {
  const userRoles = useUserRolesArr();

  return routes.filter(
    ({ meta, allowedUserRoles }) =>
      isUserRoleAllowed(userRoles, allowedUserRoles) &&
      ((!useNavLinks && !useDashboardLinks) ||
        (useNavLinks && meta?.navLink) ||
        (useDashboardLinks && meta?.dashboardLink))
  ) as T[];
};

export const useAllowedUserRolesCheck = (allowedUserRoles?: UserRole[]) => {
  const userRoles = useUserRolesArr();
  return isUserRoleAllowed(userRoles, allowedUserRoles);
};

export const useAllowedFeaturesCheck = (allowedFeatures?: UserFeature[]) => {
  const [userFeatures, isLoading] = useUserFeaturesArr();
  const isAllowed = isFeatureAllowed(userFeatures, allowedFeatures);
  const isStarted = !isNil(isLoading);

  return isAllowed || isLoading || !isStarted;
};
