import qs from 'qs';

const LEADING_QUESTION_MARK_REGEX = /[^?](\S)*/g;

export function parseQueryString(locationSearch: string) {
  const [queryString] = locationSearch.match(LEADING_QUESTION_MARK_REGEX) || [];
  return qs.parse(queryString, {
    decoder: (str: string, decoder: any, charset: any) => {
      if (charset === 'iso-8859-1') {
        // unescape never throws, no try...catch needed:
        return str.replace(/%[0-9a-f]{2}/gi, unescape);
      }

      // utf-8
      try {
        return decodeURIComponent(str);
      } catch (e) {
        return str;
      }
    },
  });
}

export function stringifyQuery(
  query: Record<string, any>,
  excludeFields: string[] = [],
  baseSearch = ''
) {
  return Object.keys(query)
    .filter((filterKey) => !excludeFields.some((field) => field === filterKey))
    .reduce((result, filterKey: any) => {
      const filterSearchParam = `${filterKey}=${query[filterKey]}`;

      return result ? `${result}&${filterSearchParam}` : filterSearchParam;
    }, baseSearch);
}

export function encodeQuery(
  ...args: [Record<string, any>, string[]?, string?]
) {
  return encodeURI(stringifyQuery(...args));
}
