import ModalDialog, {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { useForm } from 'src/components/Form';
import { useAlert, AlertType } from 'src/components/AlertsProvider';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { generatePath, useParams } from 'react-router-dom';
import { SolutionUserItem } from '../../types';
import { API_SOLUTION_USERS } from '../../constants';
import { solutionUserAssignFormFields } from '../formFields';

type Props = {
  item: SolutionUserItem;
  open: boolean;
  onClose: ModalCloseHandler;
};

const containerStyle = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
};

function SolutionUsersAssignModal({ item, open, onClose }: Props) {
  const { showAlert } = useAlert();
  const { solutionId, solutionType } = useParams();

  const [onSubmit, loading] = useStatefulApi(
    (params) =>
      pepApiRequest({
        method: RequestMethod.Post,
        url: generatePath(API_SOLUTION_USERS, { solutionId }),
        params: {
          ...params,
          PhoneNumber: params.mobileNumber
            ? params.countryCode + params.mobileNumber
            : '',
        },
      }),
    () => {
      showAlert({
        type: AlertType.Success,
        title: 'New User assigned',
        text: 'New User has been assigned successfully.',
      });
      onClose(null, ModalCloseReason.completeAction);
    }
  );

  const [form, handleSubmit] = useForm({
    fields: solutionUserAssignFormFields(solutionType),
    values: item,
    sx: containerStyle,
    onSubmit,
  });

  return (
    <ModalDialog
      open={open}
      title="Assign user"
      loading={loading}
      onClose={onClose}
      onConfirm={handleSubmit}
      confirmBtnText="Assign"
    >
      {form}
    </ModalDialog>
  );
}

export default SolutionUsersAssignModal;
