import { SolutionSourceImageRepositoryItem } from '../types';

export const getSolutionSourceImageRepositoryParams = (
  params: SolutionSourceImageRepositoryItem
) => {
  const serverUrlValue = params.acrServerUrl || params.ecrServerUrl;
  delete params.acrServerUrl;
  delete params.ecrServerUrl;

  return {
    ...params,
    containerRepositoryType: Number(params.containerRepositoryType),
    serverUrl: serverUrlValue,
  };
};
