import { SyntheticEvent } from 'react';
import { UseFormReturn } from 'react-hook-form';
import * as Yup from 'yup';
import isArray from 'lodash/isArray';
import TextField from '@mui/material/TextField';
import { SxProps } from 'src/theme/types';
import AutoComplete from 'src/components/AutoComplete';
import Select from 'src/components/Select';
import { Option } from 'src/components/SelectOption';
import {
  SolutionDeviceGroupFilterField,
  SolutionDeviceGroupItemField,
  SolutionDeviceGroupPropertyItem,
} from '../types';

const inputStyle: SxProps = { width: ({ spacing }) => spacing(35) };

type MainFieldsProps = {
  companyOptions: Option[];
  companyLoading?: boolean;
  companySelectDisabled?: boolean;
  templateOptions: Option[];
  templateLoading?: boolean;
  onTemplateChange: (event: SyntheticEvent, context: UseFormReturn) => void;
};

type FilterFieldProps = {
  onPropertyChange?: (event: SyntheticEvent) => void;
  propertyOptions?: Option[];
  propertiesLoading?: boolean;
  selectedProperty?: SolutionDeviceGroupPropertyItem | undefined;
  operatorOptions?: Option[];
  filterValueOptions?: Option[] | null;
};

export const getDeviceGroupMainFields = ({
  companyOptions,
  companyLoading,
  companySelectDisabled,
  templateOptions,
  templateLoading,
  onTemplateChange,
}: MainFieldsProps) => [
  {
    name: SolutionDeviceGroupItemField.DisplayName,
    label: 'Group name',
    placeholder: 'Enter device group name',
    Component: TextField,
    validation: Yup.string().required('Group name is required'),
    required: true,
  },
  {
    name: SolutionDeviceGroupItemField.Description,
    label: 'Description',
    placeholder: 'Add description',
    Component: TextField,
  },
  {
    name: SolutionDeviceGroupItemField.CompanyId,
    label: 'Company',
    placeholder: 'Choose company',
    Component: AutoComplete,
    options: companyOptions,
    loading: companyLoading,
    disabled: companySelectDisabled,
  },
  {
    name: SolutionDeviceGroupItemField.Template,
    label: 'Template',
    placeholder: 'Choose template',
    Component: Select,
    options: templateOptions,
    loading: templateLoading,
    onChange: onTemplateChange,
    validation: Yup.string().required('Template is required'),
    required: true,
  },
];

export const getDeviceGroupFilterFields = ({
  onPropertyChange,
  propertyOptions = [],
  selectedProperty = undefined,
  propertiesLoading,
  operatorOptions = [],
  filterValueOptions = [],
}: FilterFieldProps) => [
  {
    name: SolutionDeviceGroupFilterField.Property,
    label: 'Name',
    placeholder: 'Select a property',
    Component: Select,
    options: propertyOptions,
    loading: propertiesLoading,
    onChange: onPropertyChange,
    validation: Yup.string().required('Property name is required'),
    required: true,
    sx: inputStyle,
  },
  {
    name: SolutionDeviceGroupFilterField.OperatorValue,
    label: 'Operator',
    placeholder: 'Select an operator',
    Component: Select,
    options: operatorOptions,
    validation: Yup.string().required('Operator is required'),
    required: true,
    sx: inputStyle,
  },
  {
    name: SolutionDeviceGroupFilterField.FilterValue,
    label: 'Value',
    placeholder: 'Select or enter a value',
    Component: isArray(filterValueOptions) ? Select : TextField,
    type:
      selectedProperty?.schema?.toLowerCase() === 'string' ? 'text' : 'number',
    options: filterValueOptions || undefined,
    validation: Yup.string().required('Value is required'),
    required: true,
    sx: inputStyle,
  },
];
