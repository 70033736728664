import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DataTable, { DataTableBoolCell } from 'src/components/DataTable';
import { loadDataFromStorage } from 'src/utils';
import FieldsSet from 'src/components/FieldsSet';
import DataTableContext from 'src/components/DataTable/components/DataTableContext';
import ModalDialog, { ModalCloseHandler } from 'src/components/ModalDialog';
import { PageCardSection } from 'src/components/PageCardLayout';
import { useStoreUnitActions } from 'src/redux/helpers';
import solutionsStore, {
  useSolutionDeviceGroupDetails,
  useSolutionDeviceGroupProperties,
  useSolutionsDevicesList,
} from '../../redux';
import {
  SolutionDeviceGroupFilterField,
  SolutionDeviceGroupItem,
  SolutionDeviceGroupItemField,
  SolutionDeviceItemField,
  SolutionType,
} from '../../types';
import SolutionDeviceGroupFiltersOperatorCell from './SolutionDeviceGroupFiltersOperatorCell';
import SolutionDeviceGroupFiltersValueCell from './SolutionDeviceGroupFiltersValueCell';
import SolutionConnectionStatus from '../../SolutionDevices/components/SolutionConnectionStatus';

type Props = {
  item: SolutionDeviceGroupItem;
  open: boolean;
  onClose: ModalCloseHandler;
};

const fields = [
  { id: SolutionDeviceGroupItemField.DisplayName, label: 'Group name' },
  { id: SolutionDeviceGroupItemField.Description, label: 'Description' },
  { id: SolutionDeviceGroupItemField.CompanyName, label: 'Company' },
  { id: SolutionDeviceGroupItemField.Template, label: 'Template' },
];

const filtersColumns = [
  { id: SolutionDeviceGroupFilterField.PropertyName, title: 'Name' },
  {
    id: SolutionDeviceGroupFilterField.OperatorValue,
    title: 'Operator',
    Component: SolutionDeviceGroupFiltersOperatorCell,
  },
  {
    id: SolutionDeviceGroupFilterField.FilterValue,
    title: 'Value',
    Component: SolutionDeviceGroupFiltersValueCell,
  },
];

const devicesColumns = [
  { id: SolutionDeviceItemField.DisplayName, title: 'Device name' },
  { id: SolutionDeviceItemField.DeviceId, title: 'Device ID' },
  {
    id: SolutionDeviceItemField.Simulated,
    title: 'Simulated',
    Component: DataTableBoolCell,
  },
  { id: SolutionDeviceItemField.CompanyName, title: 'Company' },
  { id: SolutionDeviceItemField.ModelId, title: 'Template' },
];

const iotHDevicesColumns = [
  { id: SolutionDeviceItemField.DeviceId, title: 'Device ID' },
  {
    id: SolutionDeviceItemField.ConnectionState,
    title: 'Connection State',
    Component: SolutionConnectionStatus,
  },
];

const tableStyle = {
  minHeight: 0,
};

export default function SolutionDeviceGroupDetailsContainer({
  item,
  open,
  onClose,
}: Props) {
  const { solutionId } = useParams();
  const actions = useStoreUnitActions(solutionsStore);
  const [details, detailsLoading] = useSolutionDeviceGroupDetails();
  const [devices, devicesLoading] = useSolutionsDevicesList();
  const [, propertiesLoading] = useSolutionDeviceGroupProperties();
  const { filters = [], modelId } = details || {};
  const solutionType = loadDataFromStorage('solutionType');

  useEffect(() => {
    const params = { solutionId, groupId: item.id };
    actions.deviceGroupDetails(params);
    actions.devices.init(params);
    return () => actions.devices.clear();
  }, [solutionId, item]);
  useEffect(() => {
    if (modelId && filters.length) {
      actions.deviceGroupProperties({ solutionId, modelId });
    }
  }, [solutionId, modelId, filters]);

  return (
    <DataTableContext searchValue="">
      <ModalDialog
        open={open}
        onClose={onClose}
        onConfirm={onClose}
        loading={detailsLoading || devicesLoading || propertiesLoading}
        showCancelBtn={false}
        title={`View device group: ${item.displayName}`}
        confirmBtnText="Close"
        maxWidth="lg"
        fullWidth
      >
        <FieldsSet data={details} fields={fields} gap={2} maxWidth="auto" />
        <PageCardSection title="Device query" mt={4}>
          <DataTable data={filters} columns={filtersColumns} sx={tableStyle} />
        </PageCardSection>
        <PageCardSection title="Device query results" mb={0}>
          <DataTable
            data={devices}
            columns={
              solutionType.toUpperCase() === SolutionType.ioTH.toUpperCase()
                ? iotHDevicesColumns
                : devicesColumns
            }
            sx={tableStyle}
          />
        </PageCardSection>
      </ModalDialog>
    </DataTableContext>
  );
}
