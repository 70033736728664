import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import Select from 'src/components/Select';
import { isArray } from 'lodash';
import { Option } from 'src/components/SelectOption';
import AutoComplete from 'src/components/AutoComplete';
import DateTimePicker from 'src/components/DateTimePicker';
import Switch from 'src/components/Switch';
import { isValidDateStr, isFutureDateStr, nanToUndefined } from 'src/utils';
import { SolutionJobItemField, SolutionType } from '../types';
import { getSolutionIOTHJobOptions, getSolutionJobOptions } from './utils';
import GetChildPropertyUpdateFields from './SolutionJobPropertyChildFields';

type getJobEditFormFieldsParams = {
  canEditType?: boolean;
  companyOptions: Option[];
  companiesLoading?: boolean;
  deviceGroupOptions: Option[];
  deviceGroupsLoading?: boolean;
  menderEnabled?: boolean;
  solutionType: string;
};

export const getJobEditFormFields = ({
  canEditType,
  companyOptions,
  companiesLoading,
  deviceGroupOptions,
  deviceGroupsLoading,
  menderEnabled,
  solutionType,
}: getJobEditFormFieldsParams) => [
  {
    name: SolutionJobItemField.DisplayName,
    label: 'Job name',
    placeholder: 'Enter job name',
    Component: TextField,
    validation: Yup.string().required('Job name is required'),
    required: true,
  },
  {
    name: SolutionJobItemField.Company,
    label: 'Company',
    placeholder: 'Choose company',
    Component: AutoComplete,
    loading: companiesLoading,
    options: companyOptions,
    validation: Yup.string().required('Company is required'),
    required: true,
  },
  {
    name: SolutionJobItemField.Group,
    label: 'Device group',
    placeholder: 'Choose device group',
    Component: Select,
    loading: deviceGroupsLoading,
    options: deviceGroupOptions,
    validation: Yup.string().required('Device group is required'),
    required: true,
  },
  {
    name: SolutionJobItemField.Type,
    label: 'Job type',
    placeholder: 'Choose job type',
    Component: Select,
    options:
      solutionType.toUpperCase() === SolutionType.ioTH.toUpperCase()
        ? getSolutionIOTHJobOptions(menderEnabled)
        : getSolutionJobOptions(menderEnabled),
    validation: Yup.string().required('Job type is required'),
    required: true,
    disabled: !canEditType,
    description: 'Choose the appropriate job type.',
  },
];

const solutionJobRunLaterField = {
  name: SolutionJobItemField.RunLater,
  label: 'Run later',
  Component: Switch,
  type: 'checkbox',
};

export const getJobTempMigrationFields = ([options, loading]: [
  Option[],
  boolean?
]) => [
  {
    name: SolutionJobItemField.ModelId,
    label: 'Template',
    placeholder: 'Choose the template',
    Component: Select,
    validation: Yup.string().required('Template selection is required'),
    required: true,
    options,
    loading,
  },
  solutionJobRunLaterField,
];

export const getJobInvokeCommandUpdateFields = (
  commandData: any,
  state: any,
  commandOptions: Option[],
  Commandloading?: boolean
) => [
  {
    name: SolutionJobItemField.InvokeCompany,
    label: 'Command',
    placeholder: 'Enter job name',
    Component: Select,
    validation: Yup.string().required('Command selection is required'),
    options: commandOptions,
    loading: Commandloading,
    required: true,
    sx: { width: ({ spacing }) => spacing(35) },
  },

  commandData?.length !== undefined &&
    commandData?.length !== 0 &&
    state?.invokeCompany &&
    (typeof commandData?.find((e) => e?.id === state?.invokeCompany)
      ?.requestSchemaType === 'object' ||
      typeof commandData?.find((e) => e?.name === state?.invokeCompany)
        ?.requestSchemaType === 'object') &&
    (commandData?.find((e) => e?.id === state?.invokeCompany)?.requestSchemaType
      ?.fields ||
      commandData?.find((e) => e?.name === state?.invokeCompany)
        ?.requestSchemaType?.fields) &&
    (commandData?.find((e) => e?.id === state?.invokeCompany)?.requestSchemaType
      ?.fields[0]?.name ||
      commandData?.find((e) => e?.name === state?.invokeCompany)
        ?.requestSchemaType?.fields[0]?.name) && {
      name: commandData?.find(
        (e) =>
          e?.id === state?.invokeCompany || e?.name === state?.invokeCompany
      )?.requestSchemaType?.fields[0].name,
      label: commandData?.find(
        (e) =>
          e?.id === state?.invokeCompany || e?.name === state?.invokeCompany
      )?.requestSchemaType?.fields[0].name,
      placeholder: 'Enter the value',
      Component: TextField,
      validation: Yup.string().required('Value is required'),
      value:
        state?.commandPayload &&
        JSON.parse(state?.commandPayload) &&
        JSON.parse(state?.commandPayload)[
          commandData?.find(
            (e) =>
              e?.id === state?.invokeCompany || e?.name === state?.invokeCompany
          )?.requestSchemaType?.fields[0].name
        ],
      required: true,
      sx: { width: ({ spacing }) => spacing(35) },
    },
  commandData?.length !== undefined &&
    commandData?.length !== 0 &&
    state?.invokeCompany &&
    typeof commandData?.find(
      (e) => e?.id === state?.invokeCompany || e?.name === state?.invokeCompany
    )?.requestSchemaType === 'object' &&
    commandData?.find(
      (e) => e?.id === state?.invokeCompany || e?.name === state?.invokeCompany
    )?.requestSchemaType?.fields &&
    commandData?.find(
      (e) => e?.id === state?.invokeCompany || e?.name === state?.invokeCompany
    )?.requestSchemaType?.fields[1]?.name && {
      name: commandData?.find(
        (e) =>
          e?.id === state?.invokeCompany || e?.name === state?.invokeCompany
      )?.requestSchemaType?.fields[1].name,
      label: commandData?.find(
        (e) =>
          e?.id === state?.invokeCompany || e?.name === state?.invokeCompany
      )?.requestSchemaType?.fields[1].name,
      placeholder: 'Enter the value',
      Component: TextField,
      value:
        state?.commandPayload &&
        JSON.parse(state?.commandPayload) &&
        JSON.parse(state?.commandPayload)[
          commandData?.find(
            (e) =>
              e?.id === state?.invokeCompany || e?.name === state?.invokeCompany
          )?.requestSchemaType?.fields[1].name
        ],
      validation: Yup.string().required('Value is required'),
      required: true,
      sx: { width: ({ spacing }) => spacing(35) },
    },
  commandData?.length !== undefined &&
    commandData?.length !== 0 &&
    state?.invokeCompany &&
    typeof commandData?.find(
      (e) => e?.id === state?.invokeCompany || e?.name === state?.invokeCompany
    )?.requestSchemaType === 'object' &&
    commandData?.find(
      (e) => e?.id === state?.invokeCompany || e?.name === state?.invokeCompany
    )?.requestSchemaType?.fields &&
    commandData?.find(
      (e) => e?.id === state?.invokeCompany || e?.name === state?.invokeCompany
    )?.requestSchemaType?.fields[2]?.name && {
      name: commandData?.find(
        (e) =>
          e?.id === state?.invokeCompany || e?.name === state?.invokeCompany
      )?.requestSchemaType?.fields[2].name,
      label: commandData?.find(
        (e) =>
          e?.id === state?.invokeCompany || e?.name === state?.invokeCompany
      )?.requestSchemaType?.fields[2].name,
      placeholder: 'Enter the value',
      Component: TextField,
      value:
        state?.commandPayload &&
        JSON.parse(state?.commandPayload) &&
        JSON.parse(state?.commandPayload)[
          commandData?.find(
            (e) =>
              e?.id === state?.invokeCompany || e?.name === state?.invokeCompany
          )?.requestSchemaType?.fields[2].name
        ],
      validation: Yup.string().required('Value is required'),
      required: true,
      sx: { width: ({ spacing }) => spacing(35) },
    },
  commandData?.length !== undefined &&
    commandData?.length !== 0 &&
    state?.invokeCompany &&
    typeof commandData?.find(
      (e) => e?.id === state?.invokeCompany || e?.name === state?.invokeCompany
    )?.requestSchemaType === 'object' &&
    commandData?.find(
      (e) => e?.id === state?.invokeCompany || e?.name === state?.invokeCompany
    )?.requestSchemaType?.fields[3] &&
    commandData?.find(
      (e) => e?.id === state?.invokeCompany || e?.name === state?.invokeCompany
    )?.requestSchemaType?.fields[3]?.name && {
      name: commandData?.find(
        (e) =>
          e?.id === state?.invokeCompany || e?.name === state?.invokeCompany
      )?.requestSchemaType?.fields[3].name,
      label: commandData?.find(
        (e) =>
          e?.id === state?.invokeCompany || e?.name === state?.invokeCompany
      )?.requestSchemaType?.fields[3].name,
      placeholder: 'Enter the value',
      Component: TextField,
      value:
        state?.commandPayload &&
        JSON.parse(state?.commandPayload) &&
        JSON.parse(state?.commandPayload)[
          commandData?.find(
            (e) =>
              e?.id === state?.invokeCompany || e?.name === state?.invokeCompany
          )?.requestSchemaType?.fields[3].name
        ],
      validation: Yup.string().required('Value is required'),
      required: true,
      sx: { width: ({ spacing }) => spacing(35) },
    },
  solutionJobRunLaterField,
];

export const getJobPropertyUpdateFields = (
  onPropertyChange: any,
  propertyData: any,
  propertyOptions: Option[],
  filterValueOptions: Option[] | null,
  propertiesLoading?: boolean
) => [
  {
    name: SolutionJobItemField.PropertyUpdate,
    appendLabel: 'Add more Property',
    childFields: getPropertyChildFields(
      onPropertyChange,
      propertyData,
      propertyOptions,
      filterValueOptions,
      propertiesLoading
    ),
    RowComponent: GetChildPropertyUpdateFields,
  },
  solutionJobRunLaterField,
];

type getJobHostOsUpdateFieldsParams = {
  releaseOptions: Option[];
  releasesLoading: boolean | undefined;
  runLater?: boolean;
};

export const getJobCommandFields = (
  onCommandChange,
  companyOptions,
  loading
) => [
  {
    name: SolutionJobItemField.InvokeCompany,
    label: 'Command',
    placeholder: 'Enter job name',
    Component: Select,
    validation: Yup.string().required('Property selection is required'),
    options: companyOptions,
    onChange: onCommandChange,
    loading,
    required: true,
    sx: { width: ({ spacing }) => spacing(35) },
  },
];

export const getPropertyChildFields = (
  onPropertyChange,
  propertyData,
  propertyOptions,
  filterValueOptions,
  propertiesLoading
): any => [
  {
    name: SolutionJobItemField.PropertyName,
    label: 'Property Name',
    placeholder: 'Enter job name',
    Component: Select,
    validation: Yup.string().required('Property selection is required'),
    options: propertyOptions,
    onChange: onPropertyChange,
    loading: propertiesLoading,
    required: true,
    sx: { width: ({ spacing }) => spacing(35) },
  },
  {
    name: SolutionJobItemField.PropertyValue,
    label: 'Value',
    placeholder: 'Enter a value',
    options: isArray(filterValueOptions) ? filterValueOptions : '',
    Component: isArray(filterValueOptions) ? Select : TextField,
    InputProps: { inputProps: { min: 1 } },
    type: propertyData && propertyData.schema === 'integer' ? 'number' : 'text',
    validation:
      propertyData && propertyData.schema === 'integer'
        ? Yup.number().transform(nanToUndefined).min(1, 'Enter a valid number')
        : Yup.string().required('Value is required'),
    required: true,
    sx: { width: ({ spacing }) => spacing(35) },
  },
];

export const getJobHostOsUpdateFields = ({
  releaseOptions,
  releasesLoading,
  runLater,
}: getJobHostOsUpdateFieldsParams) => [
  {
    name: SolutionJobItemField.ArtifactName,
    label: 'Release',
    placeholder: 'Select a compatible release',
    Component: Select,
    validation: Yup.string().required('Release selection is required'),
    required: true,
    options: releaseOptions,
    loading: releasesLoading,
  },
  solutionJobRunLaterField,
  runLater && {
    name: SolutionJobItemField.StartTime,
    label: 'Enter date and time',
    placeholder: 'mm/dd/yyyy hh:mm',
    Component: DateTimePicker,
    validation: Yup.string()
      .required('If starting later, start time is required')
      .test('validDate', 'Enter correct date and time', isValidDateStr)
      .test('futureDate', 'Enter future date and time', isFutureDateStr),
    required: true,
    sx: { maxWidth: '26rem' },
  },
];
