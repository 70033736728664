import React, { ReactNode } from 'react';
import Box from '@mui/material/Box';
import { SxProps } from 'src/theme/types';
import Header from 'src/components/Header';
import SideBar from 'src/components/SideBar';

const wrapperStyle: SxProps = {
  backgroundColor: ({ alpha, palette }) => alpha(palette.neutral.main, 0.07),
  height: '100vh',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
};

const containerStyle: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  flex: '1 0',
  mx: 2,
  my: 3,
};

type Props = {
  children: ReactNode;
};

function PageWrapper({ children }: Props) {
  return (
    <Box data-testid="page-wrapper" display="flex">
      <SideBar />
      <Box sx={wrapperStyle}>
        <Header />
        <Box sx={containerStyle}>{children}</Box>
      </Box>
    </Box>
  );
}

export default PageWrapper;
