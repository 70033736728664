import { createDatasetSlice, ResponseMapping } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import {
  API_APPLICATION,
  USERS_STORE,
  APPLICATION_STORE_LIST,
} from '../constants';

const api = (queryParams) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: API_APPLICATION,
    queryParams,
  });

const applicationListSlice = createDatasetSlice({
  api,
  storeName: USERS_STORE,
  actionName: APPLICATION_STORE_LIST,
  responseMapping: ResponseMapping.Default,
});
export default applicationListSlice;
