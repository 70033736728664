export enum SolutionEdgeWorkloadAuthType {
  HTTPBased = 'http',
  SSHBased = 'ssh',
  None = 'none',
}

export enum SolutionEdgeWorkloadCertVerifytype {
  ValidCert = 'Valid Certificate',
  AdditionalCert = 'Additional Certificate',
  AnyCerticate = 'Any Certificate',
}

export enum SolutionEdgeWorkloadRepositoryType {
  PepsenseEdge = 'PepsenseEdge',
  SolutionEdge = 'SolutionEdge',
}

export type SolutionEdgeWorkloadItem = {
  id: string;
  siteIds: string[];
  name: string;
  description: string;
  repositoryURL: string;
  branchName: string;
  gitAuthentication?: string;
  gitUsername?: string;
  gitPassword?: string;
  gitPublickey?: string;
  gitPrivatekey?: string;
  helmAuthentication?: string;
  helmUsername?: string;
  helmPassword?: string;
  helmPublickey?: string;
  helmPrivatekey?: string;
  tlsCertificationVerification?: string;
  certificates?: string;
  path?: string;
  siteName?: string;
  targetNamespace: string;
  gitFlag?: string;
  deploymentStatus?: string;
  deploymentMessage?: string;
  deploymentPaused?: boolean;
  deploymentStatusData?: string;
  keepResources?: boolean;
  siteClusters?: any;
};

export type SolutionEdgeWorkloadConfigList = {
  data?: SolutionEdgeWorkloadItem[];
  loading?: boolean;
};

export enum SolutionEdgeWorkloadItemField {
  Name = 'name',
  Description = 'description',
  RepositoryURL = 'repositoryURL',
  BranchName = 'branchName',
  GitAuthentication = 'gitAuthentication',
  GitUserName = 'gitUsername',
  GitPassword = 'gitPassword',
  GitPublicKey = 'gitPublickey',
  GitPrivateKey = 'gitPrivatekey',
  HelmAuthentication = 'helmAuthentication',
  HelmUserName = 'helmUsername',
  HelmPassword = 'helmPassword',
  HelmPublickey = 'helmPublickey',
  HelmPrivatekey = 'helmPrivatekey',
  TlsCertVerification = 'tlsCertificationVerification',
  Certificates = 'certificates',
  Path = 'path',
  SiteName = 'siteName',
  TargetNamespace = 'targetNamespace',
  DeploymentStatus = 'deploymentStatus',
  DeploymentMessage = 'deploymentMessage',
  DeploymentStatusData = 'deploymentStatusData',
  KeepResources = 'keepResources',
  SiteClusters = 'siteClusters',
}

export enum SolutionEdgeWorkloadStatus {
  paused = 'paused',
  active = 'active',
  notReady = 'notready',
  modified = 'modified',
  failed = 'failed',
  errApplied = 'errapplied',
  gitUpdating = 'gitupdating',
  waitapplied = 'waitapplied',
  outofsync = 'outofsync',
}

export type SolutionEdgeWorkloadStatusData = {
  status?: string;
  errorMsg?: string;
};

export type SolutionSourceImageRepositoryItem = {
  AcrClientId?: string;
  AcrClientSecret?: string;
  containerRepositoryType?: SolutionSourceImageRepoContainerType;
  ecrAccesKey?: string;
  ecrSecretKey?: string;
  ecrRegionEndpoint?: string;
  serverUrl?: string;
  acrServerUrl?: string;
  ecrServerUrl?: string;
  userName?: string;
  ImagePullSecret?: string;
  ImagePullSecretKey?: string;
  sourceDockerImages?: SolutionSourcePullImageItem[];
};

export enum SolutionSourceImageRepositoryItemField {
  Name = 'name',
  acrServerUrl = 'acrServerUrl',
  ecrServerUrl = 'ecrServerUrl',
  serverUrl = 'serverUrl',
  UserName = 'userName',
  ImagePullSecret = 'ImagePullSecret',
  ImagePullSecretKey = 'ImagePullSecretKey',
  AcrClientId = 'AcrClientId',
  AcrClientSecret = 'AcrClientSecret',
  ContainerRepositoryType = 'containerRepositoryType',
  EcrAccesKey = 'ecrAccesKey',
  EcrSecretKey = 'ecrSecretKey',
  EcrRegionEndpoint = 'ecrRegionEndpoint',
}

export enum SolutionSourceImageRepoContainerType {
  Acr = '0',
  Ecr = '1',
}

export type SolutionPepsenceImageRepoItem = {
  imagePullSecrets: string;
  imageUrl: string;
};

export type SolutionPepsenceImageRepoData = {
  data?: SolutionPepsenceImageRepoItem;
  loading?: boolean;
};

export enum SolutionDockerImagesStatus {
  InProgress = 'InProgress',
  Failed = 'Failed',
  Succeeded = 'Succeeded',
}

export type SolutionSourcePullImageItem = {
  imageName: string;
  status: SolutionDockerImagesStatus;
  requestedDate: string;
  tag: string;
  failedReason?: string;
};

export enum SolutionSourcePullImageItemField {
  Images = 'images',
}

export enum SolutionSourcePullDockerImageItemField {
  ImageName = 'imageName',
  Status = 'status',
  RequestedDate = 'requestedDate',
  Tag = 'tag',
  FailedReason = 'failedReason',
}

export enum DeploymentStatus {
  Resources = 'resources',
  Conditions = 'conditions',
}

export type SolutionWorkloadStatusList = {
  data?: SolutionWorkloadStatusItem;
  loading?: boolean;
};

export type SolutionWorkloadStatusItem = {
  status?: SolutionStatus;
};

export type SolutionStatus = {
  conditions?: SolutionWorkloadStatusConditionsItem[];
  resources?: SolutionWorkloadStatusResourcesItem[];
};

export type SolutionWorkloadStatusResourcesItem = {
  name?: string;
  namespace?: string;
  kind?: string;
  apiVersion?: string;
  id?: string;
  state?: string;
};

export enum SolutionWorkloadStatusResourcesField {
  Name = 'name',
  Namespace = 'namespace',
  Kind = 'kind',
  ApiVersion = 'apiVersion',
  Id = 'id',
  State = 'state',
}

export enum SolutionWorkloadStatusConditionsField {
  LastUpdateTime = 'lastUpdateTime',
  Status = 'status',
  Type = 'type',
  Message = 'message',
}

export type SolutionWorkloadStatusConditionsItem = {
  lastUpdateTime?: string;
  status?: string;
  type?: string;
  message?: string;
};

export enum SolutionWorkloadStatus {
  True = 'true',
  False = 'false',
  Ready = 'ready',
  Failed = 'failed',
  Updating = 'updating',
  Modified = 'modified',
  ErrApplied = 'errapplied',
  Missing = 'missing',
  InProgress = 'in-progress',
  Pending = 'pending',
}
