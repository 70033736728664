import React from 'react';
import Typography from '@mui/material/Typography';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import CardButton, { CardButtonsContainer } from 'src/components/CardButton';
import { useModal } from 'src/hooks';
import { loadDataFromStorage } from 'src/utils';
import { FormField } from 'src/components/Form';
import SolutionsDeviceConfigUploadModal from './SolutionDeviceConfigUploadModal';
import { useSolutionUserRoles } from '../../hooks';
import { SolutionDeviceConfigModelType, SolutionType } from '../../types';
import {
  API_DT_CONTEXT_MODEL,
  API_DT_DEVICE_MODEL,
  API_DT_DEVICE_MODEL_IOTH,
} from '../../constants';
import {
  contextModelUploadFormFields,
  getDeviceModelUploadFormFields,
  getDeviceModelUploadFormFieldsIotH,
  getEdgeDeviceModelUploadFormFieldsIotH,
} from '../formFields';

type TemplateTypeMeta = {
  key: SolutionDeviceConfigModelType;
  title: string;
  description: string;
  api: string;
  formFields: FormField[];
};

const cardStyle = {
  '.MuiTypography-root.MuiTypography-body2': {
    whiteSpace: 'normal',
  },
};

function SolutionsDeviceConfigFilesUpload() {
  const { isSolutionEditor } = useSolutionUserRoles();
  const [uploadDialog, showUploadDialog] = useModal(
    SolutionsDeviceConfigUploadModal
  );
  const solutionType = loadDataFromStorage('solutionType');
  const templateTypesMap: TemplateTypeMeta[] = [
    {
      key: SolutionDeviceConfigModelType.DeviceTemplate,
      title: 'Device template',
      description:
        'Templates for simple devices leveraging the Azure IoT Device SDK',
      api:
        solutionType?.toUpperCase() === SolutionType.ioTH.toUpperCase()
          ? API_DT_DEVICE_MODEL_IOTH
          : API_DT_DEVICE_MODEL,
      formFields: getDeviceModelUploadFormFields(false),
    },
    {
      key: SolutionDeviceConfigModelType.EdgeDeviceTemplate,
      title: 'Edge device template',
      description:
        'Templates for edge devices leveraging the Azure IoT Edge runtime',
      api:
        solutionType?.toUpperCase() === SolutionType.ioTH.toUpperCase()
          ? API_DT_DEVICE_MODEL_IOTH
          : API_DT_DEVICE_MODEL,
      formFields: getDeviceModelUploadFormFields(true),
    },
    {
      key: SolutionDeviceConfigModelType.ContextModel,
      title: 'Context model',
      description:
        'DTDLv2 models used in Azure Digital Twins to describe the solutions context graph',
      api: API_DT_CONTEXT_MODEL,
      formFields: contextModelUploadFormFields,
    },
  ];

  const templateIOTHTypesMap: TemplateTypeMeta[] = [
    {
      key: SolutionDeviceConfigModelType.DeviceTemplate,
      title: 'Device template',
      description:
        'Templates for simple devices leveraging the Azure IoT Device SDK',
      api:
        solutionType?.toUpperCase() === SolutionType.ioTH.toUpperCase()
          ? API_DT_DEVICE_MODEL_IOTH
          : API_DT_DEVICE_MODEL,
      formFields: getDeviceModelUploadFormFieldsIotH(false),
    },
    {
      key: SolutionDeviceConfigModelType.EdgeDeviceTemplate,
      title: 'Edge device template',
      description:
        'Templates for edge devices leveraging the Azure IoT Edge runtime',
      api:
        solutionType?.toUpperCase() === SolutionType.ioTH.toUpperCase()
          ? API_DT_DEVICE_MODEL_IOTH
          : API_DT_DEVICE_MODEL,
      formFields: getEdgeDeviceModelUploadFormFieldsIotH(true),
    },
  ];
  return (
    <>
      <Typography variant="body2">Select a tile to upload files.</Typography>
      <CardButtonsContainer>
        {solutionType?.toUpperCase() === SolutionType.ioTH.toUpperCase()
          ? templateIOTHTypesMap.map(({ key, description, ...item }) => (
              <CardButton
                data-testid={`solution-device-config-upload-btn-${key}`}
                disabled={!isSolutionEditor}
                key={key}
                title={item.title}
                description={description}
                sx={cardStyle}
                icon={InsertDriveFileOutlinedIcon}
                onClick={() => showUploadDialog(item)}
              />
            ))
          : templateTypesMap.map(({ key, description, ...item }) => (
              <CardButton
                data-testid={`solution-device-config-upload-btn-${key}`}
                disabled={!isSolutionEditor}
                key={key}
                title={item.title}
                description={description}
                sx={cardStyle}
                icon={InsertDriveFileOutlinedIcon}
                onClick={() => showUploadDialog(item)}
              />
            ))}
      </CardButtonsContainer>
      {uploadDialog}
    </>
  );
}

export default SolutionsDeviceConfigFilesUpload;
