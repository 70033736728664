import React from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useUserAuthProfile } from 'src/auth';
import { getNameInitials } from '../utils';

const style = {
  py: 2,
  '&.Mui-disabled': {
    opacity: 0.9,
  },
};

function HeaderUserMenuProfileInfo() {
  const { FirstName, LastName, email } = useUserAuthProfile();
  const fullName = `${FirstName} ${LastName}`;

  return (
    <MenuItem data-testid="user-menu-profile-info" sx={style} disabled divider>
      <ListItemAvatar>
        <Avatar alt={fullName}>{getNameInitials(fullName)}</Avatar>
      </ListItemAvatar>
      <Box>
        {fullName}
        <Typography display="block" variant="body3">
          {email}
        </Typography>
      </Box>
    </MenuItem>
  );
}

export default HeaderUserMenuProfileInfo;
