import React from 'react';
import { format as formatDate } from 'date-fns';
import { DATE_TIME_DISPLAY_FORMAT } from 'src/constants';

type Props = {
  defaultValue?: string;
  value?: string;
  format?: string;
};

function DateFormattedValue({
  defaultValue,
  value,
  format = DATE_TIME_DISPLAY_FORMAT,
}: Props) {
  return <>{value ? formatDate(new Date(value), format) : defaultValue}</>;
}

export default DateFormattedValue;
