import { createDatasetSlice, ResponseMapping } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import { generateApiPath } from 'src/utils';
import {
  API_DEVICES_CERT_DETAILS,
  SOLUTIONS_STORE,
  SOLUTIONS_STORE_DEVICE_CERT_DETAILS,
} from '../constants';
import { mapSolutionDeviceCertDetails } from './utils';

const api = ({ solutionId, deviceId, type }) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: generateApiPath(API_DEVICES_CERT_DETAILS, { solutionId, deviceId }),
  }).then((response) => mapSolutionDeviceCertDetails(response, type));

const solutionDeviceCertDetailsSlice = createDatasetSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTIONS_STORE_DEVICE_CERT_DETAILS,
  responseMapping: ResponseMapping.Default,
});

export default solutionDeviceCertDetailsSlice;
