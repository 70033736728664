import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Form from 'src/components/Form';
import { newCompanyFormFields } from '../formFields';

const style = {
  width: ({ spacing }) => spacing(55),
  mb: 11,
};

type Props = {
  onCancel: () => void;
  onSubmit: (values: Record<string, string>) => void;
};

function NewCompanyForm({ onCancel, onSubmit }: Props) {
  return (
    <Form
      fields={newCompanyFormFields}
      fieldProps={{ sx: style }}
      onSubmit={onSubmit}
    >
      <Box textAlign="right" mt={3}>
        <Button onClick={onCancel} variant="outlined" sx={{ mr: 2 }}>
          Cancel
        </Button>
        <Button type="submit" variant="contained">
          Create
        </Button>
      </Box>
    </Form>
  );
}

export default NewCompanyForm;
