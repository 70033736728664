import {
  SolutionADLSFormat,
  SolutionStorageAccount,
  SolutionSubscribeTo,
  SolutionSubscriptionType,
} from '../types';

export const SUBSCRIPTION_SAS_PATTERN =
  /^SharedAccessSignature sr=\S+&sig=\S+&se=\S+&skn=\S+/;

export const RULE_TARGET_URL_PATTERN =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
export const SUBSCRIBE_TO_OPTIONS = [
  {
    value: SolutionSubscribeTo.Alarm,
    label: 'Alarm',
  },
  {
    value: SolutionSubscribeTo.Event,
    label: 'Event',
  },
  {
    value: SolutionSubscribeTo.PlatformEvent,
    label: 'Platform event',
  },
];

export const SUBSCRIBE_TO_OPT_INDUSTRIAL = [
  ...SUBSCRIBE_TO_OPTIONS,
  {
    value: SolutionSubscribeTo.Telemetry,
    label: 'Telemetry',
  },
];

export const SUBSCRIBE_FILTER_OPERATOR_OPTIONS = [
  {
    value: '=',
    label: 'Equals',
  },
  {
    value: '<>',
    label: 'Not Equals',
  },
  {
    value: 'like',
    label: 'Contains',
  },
];

export const SUBSCRIBE_FILTER_CONDITION_OPTIONS = [
  {
    value: 'And',
    label: 'All the conditions are valid',
  },
  {
    value: 'Or',
    label: 'Any of the condition is valid',
  },
];

export const SUBSCIPTION_TYPE_OPTIONS = [
  {
    value: SolutionSubscriptionType.Messages,
    label: 'Messages',
  },
  {
    value: SolutionSubscriptionType.Files,
    label: 'Files',
  },
];

export const INDUSTRIAL_SUBSCRIPTION_TYPE_OPTIONS = [
  ...SUBSCIPTION_TYPE_OPTIONS,
  {
    value: SolutionSubscriptionType.ADLS,
    label: 'ADLS',
  },
  {
    value: SolutionSubscriptionType.Cosmos,
    label: 'Cosmos',
  },
];
export const STORAGE_ACCOUNT_OPTIONS = [
  {
    value: SolutionStorageAccount.AzureBlob,
    label: 'Azure Blob',
  },
  {
    value: SolutionStorageAccount.AWS_S3,
    label: 'AWS S3',
  },
  {
    value: SolutionStorageAccount.Ftp,
    label: 'FTP',
  },
];

export const ADLS_FORMAT_OPTIONS = [
  {
    value: SolutionADLSFormat.JSON,
    label: 'JSON',
  },
  {
    value: SolutionADLSFormat.AVRO,
    label: 'Avro',
    disabled: true,
  },
  {
    value: SolutionADLSFormat.PARQUET,
    label: 'Parquet',
    disabled: true,
  },
];
