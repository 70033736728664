import { useState, useCallback, useMemo, useEffect } from 'react';
import { useForm } from 'src/components/Form';
import ModalDialog, { ModalCloseHandler } from 'src/components/ModalDialog';
import { Box, Button } from '@mui/material';
import { generatePath, useParams } from 'react-router-dom';
import {
  SolutionEdgeWorkloadItem,
  SolutionEdgeWorkloadRepositoryType,
} from 'src/pages/Solutions/types/solutionEdgeWorkload';
import { Option } from 'src/components/CheckboxGroup/types';
import { pepApiRequest, RequestMethod } from 'src/services';
import { useStoreUnitActions } from 'src/redux/helpers';
import { getWorkloadConfigEditFormFields } from '../formFields';
import { API_SITE_CLUSTERS } from '../../constants';
import sitesStore, { useSiteClusterOptionsist } from '../../redux';

type Props = {
  item?: SolutionEdgeWorkloadItem;
  title: string;
  loading?: boolean;
  open: boolean;
  onClose: ModalCloseHandler;
  onSubmit: (values: SolutionEdgeWorkloadItem) => void;
  confirmBtnText?: string;
  repositoryType?: string;
};

const containerStyle = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  height: '500px',
  overflowY: 'auto',
  padding: '10px',
  alignItems: 'center',
};

function SolutionEdgeWorkloadEditModal({
  item,
  title,
  loading,
  open,
  onClose,
  onSubmit,
  repositoryType,
  confirmBtnText = 'Configure',
}: Props) {
  const { siteId } = useParams();
  const [clusterOptions, clusterOptionsLoading] = useSiteClusterOptionsist();
  const selectedClusters = item?.siteClusters;
  const activeClusters = clusterOptions?.clustersDetail?.filter(
    (x) => x.clusterStatus === 'active'
  );
  const clusterOptionList = activeClusters?.map((item) => ({
    label: item.clusterName,
    value: item.clusterId,
  }));

  const [gitAuthentication, setGitAuthentication] = useState(
    item?.gitAuthentication
  );
  const [helmAuthentication, setHelmAuthentication] = useState(
    item?.helmAuthentication
  );
  const [tlsCertVerification, setTlsCertVerification] = useState(
    item?.tlsCertificationVerification
  );

  const onChange = useCallback(
    ({
      gitAuthentication,
      helmAuthentication,
      tlsCertificationVerification,
    }: SolutionEdgeWorkloadItem) => {
      setGitAuthentication(gitAuthentication);
      setHelmAuthentication(helmAuthentication);
      setTlsCertVerification(tlsCertificationVerification);
    },
    []
  );
  const fields = useMemo(
    () =>
      getWorkloadConfigEditFormFields(
        !!item,
        gitAuthentication,
        helmAuthentication,
        tlsCertVerification,
        clusterOptionList,
        selectedClusters,
        clusterOptionsLoading
      ),
    [
      !!item,
      gitAuthentication,
      helmAuthentication,
      tlsCertVerification,
      clusterOptions,
      selectedClusters,
      clusterOptionsLoading,
    ]
  );

  const [form, handleSubmit] = useForm({
    visibleValuesOnly: true,
    values: item,
    fields,
    sx: containerStyle,
    onChange,
    onSubmit,
  });

  return (
    <ModalDialog
      open={open}
      title={title}
      confirmBtnText={confirmBtnText}
      loading={loading}
      onClose={onClose}
      maxWidth="md"
      titleStyle={{ height: '67px' }}
      actionsStyle={{ height: '87px' }}
      showCancelBtn={false}
      showConfirmBtn={false}
      contentStyle={{ paddingRight: '0px' }}
      fullWidth
    >
      {form}
      <Box textAlign="right" mt={3} style={{ paddingRight: '20px' }}>
        <Button onClick={onClose} variant="outlined" sx={{ mr: 2 }}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained">
          {!item ? 'Configure' : 'Save'}
        </Button>
      </Box>
    </ModalDialog>
  );
}

export default SolutionEdgeWorkloadEditModal;
