import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Add from '@mui/icons-material/Add';
import DataTable, {
  DataTableWrapperActionButton,
  getActionsColConfig,
  useTableQueryParams,
} from 'src/components/DataTable';
import { useStoreUnitActions } from 'src/redux/helpers/createStoreUnit';
import { useUserRoles } from 'src/auth';
import DataTableContext from 'src/components/DataTable/components/DataTableContext';
import PageCardLayout from 'src/components/PageCardLayout';
import DataTableSearchBar from 'src/components/DataTable/components/DataTableSearchBar';
import { Box, IconButton } from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import sitesStore from '../redux/sitesStore';
import { useSitesList } from '../redux';
import SiteItemStatus from '../components/SiteItemStatus';
import SitesListAction from './components/SitesListAction';
import { SiteItemField } from '../types';
import { NEW_SITE as ROUTE_NEW_SITE } from '../constants';

const columns = [
  { id: SiteItemField.SiteName, title: 'Site Name' },
  { id: SiteItemField.Sector, title: 'Sector' },
  { id: SiteItemField.Description, title: 'Description' },
  {
    id: SiteItemField.Status,
    title: 'Status',
    Component: SiteItemStatus,
  },
  getActionsColConfig(SitesListAction),
];

function SitesList() {
  const navigate = useNavigate();
  const [data, loading] = useSitesList();
  const actions = useStoreUnitActions(sitesStore);
  const { paging, sorting } = useTableQueryParams();
  const { isPlatformReader } = useUserRoles();

  useEffect(() => {
    actions.list();
  }, []);

  return (
    <DataTableContext>
      <PageCardLayout
        title="Sites"
        actionsPanel={
          <>
            <Box>
              <IconButton
                size="small"
                title="Help center"
                onClick={() =>
                  navigate(
                    '/help-center/docs/IndustrialSetup/SiteManagement/SiteOnboarding/'
                  )
                }
              >
                <HelpOutlineOutlinedIcon fontSize="small" />
              </IconButton>
            </Box>
            <DataTableSearchBar />
            <DataTableWrapperActionButton
              component={Link}
              to={ROUTE_NEW_SITE}
              startIcon={<Add />}
              disabled={isPlatformReader}
            >
              New Site
            </DataTableWrapperActionButton>
          </>
        }
      >
        <DataTable
          data={data}
          columns={columns}
          loading={loading}
          {...paging}
          {...sorting}
        />
      </PageCardLayout>
    </DataTableContext>
  );
}

export default SitesList;
