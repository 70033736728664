import { useDispatch } from 'react-redux';
import { useMemo } from 'react';
import isFunction from 'lodash/isFunction';
import { createDatasetSlice } from './createDatasetSlice';
import { ON_FAILURE, ON_REQUEST, ON_SUCCESS } from './actionTypes';
import { SliceParams } from './types';

export function datasetUnitReducer(initialState, extraReducers) {
  return {
    [ON_REQUEST](state) {
      return { ...state, loading: true };
    },
    [ON_SUCCESS](state, action) {
      return {
        loading: false,
        ...action.payload,
      };
    },
    [ON_FAILURE](state, action) {
      return { ...initialState, loading: false, error: action.error };
    },
    ...extraReducers,
  };
}

export function createDatasetUnit<T>({
  initialState,
  reducers,
  ...params
}: SliceParams<T>) {
  return createDatasetSlice<T>({
    reducers: datasetUnitReducer(initialState, reducers),
    initialState,
    ...params,
  });
}

export const getDatasetUnitAction = (store) => (dispatch) => {
  if (isFunction(store.dispatchers)) {
    return store.dispatchers(dispatch);
  }
  return null;
};

export const useDatasetUnitAction = (store) => {
  const dispatcher = useDispatch();
  return useMemo(
    () => getDatasetUnitAction(store)(dispatcher),
    [store, dispatcher]
  );
};
