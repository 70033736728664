import { useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import Add from '@mui/icons-material/Add';
import { DeleteOutline, Edit } from '@mui/icons-material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { isModalComplete } from 'src/components/ModalDialog';
import { useStoreUnitActions } from 'src/redux/helpers';
import {
  DataTableWrapper,
  DataTableWrapperActionButton,
} from 'src/components/DataTable';
import { useModal } from 'src/hooks';
import { useUserRoles } from 'src/auth';
import GuidedTooltip from 'src/components/GuidedTour/guidedTooltip';
import SolutionSourceImageRepoConfig from './components/SolutionSourceImageRepoCreateContainer';
import { useSolutionDetails } from '../redux/selectors';
import solutionsStore from '../redux';
import SolutionSourceImageRepoEditContainer from './components/SolutionSourceImageRepoEditContainer';
import SolutionSourceImageRepoDeleteModal from './components/SolutionSourceImageRepoDeleteModal';
import {
  SolutionSourceImageRepoContainerType,
  SolutionSourceImageRepositoryItem,
} from '../types/solutionEdgeWorkload';
import SolutionDockerImages from './components/SolutionDockerImages';
import SolutionPepsenceImageRepoDetails from './components/SolutionPepsenceImageRepoDetails';
import { useSolutionUserRoles } from '../hooks';

type Props = {
  item?: SolutionSourceImageRepositoryItem;
};

function SolutionSourceImageRepository({ item }: Props) {
  const cellStyle = {
    border: 0,
    height: 'auto',
    padding: '10px 20px 10px 0px',
  };
  const LabelStyle = {
    fontWeight: 'bold',
    width: '23%',
  };

  const [data, loading] = useSolutionDetails();
  const { solutionId } = useParams();
  const actions = useStoreUnitActions(solutionsStore);
  const [showImagePullSecret, setShowImagePullSecret] = useState(false);
  const [showImageACRClientSecret, setShowImageACRClientSecret] =
    useState(false);
  const [showEcrAccessKey, setShowEcrAccessKey] = useState(false);
  const [showEcrSecretKey, setShowEcrSecretKey] = useState(false);
  const VisibilityIcon = showImagePullSecret
    ? VisibilityOffOutlinedIcon
    : VisibilityOutlinedIcon;
  const ClientSecretVisibilityIcon = showImageACRClientSecret
    ? VisibilityOffOutlinedIcon
    : VisibilityOutlinedIcon;
  const EcrAccessKeyVisibilityIcon = showEcrAccessKey
    ? VisibilityOffOutlinedIcon
    : VisibilityOutlinedIcon;
  const EcrSecretKeyVisibilityIcon = showEcrSecretKey
    ? VisibilityOffOutlinedIcon
    : VisibilityOutlinedIcon;
  const handleShowImagePullSecret = () => {
    setShowImagePullSecret((prevShowPassword: any) => !prevShowPassword);
  };
  const handleShowImageACRClientSecret = () => {
    setShowImageACRClientSecret((prevShowPassword: any) => !prevShowPassword);
  };
  const handleShowEcrAccessKey = () => {
    setShowEcrAccessKey((prevShowPassword: any) => !prevShowPassword);
  };
  const handleShowEcrSecretKey = () => {
    setShowEcrSecretKey((prevShowPassword: any) => !prevShowPassword);
  };
  const dataLength: any =
    data?.industrialWorkloadInformation?.ImagePullSecret?.length;
  const dataLengthClientSecret: any =
    data?.industrialWorkloadInformation?.AcrClientSecret?.length;
  const { isPlatformReader, isPlatformEngineer } = useUserRoles();
  const { isSolutionOwner } = useSolutionUserRoles();

  useEffect(() => {
    actions.details(solutionId);
  }, [solutionId]);

  const [
    CreateSourceImageRepositoryDialog,
    showCreateSourceImageRepositoryDialog,
  ] = useModal(SolutionSourceImageRepoConfig, {
    onClose: (reason) => isModalComplete(reason),
  });

  const repodata = data?.industrialWorkloadInformation;
  const [EditSourceImageRepoDialog, showEditSourceImageRepoDialog] = useModal(
    SolutionSourceImageRepoEditContainer,
    {
      item: repodata,
      onClose: (reason) =>
        isModalComplete(reason) && actions.details(solutionId),
    }
  );

  const [DeleteSourceImageRepoDialog, showDeleteSourceImageRepoDialog] =
    useModal(SolutionSourceImageRepoDeleteModal, {
      item,
      onClose: (reason) =>
        isModalComplete(reason) && actions.details(solutionId),
    });

  return (
    <Box>
      <DataTableWrapper searchBar={false} my={3} mb={4}>
        <Typography
          style={{
            fontWeight: 'bold',
            fontSize: '17px',
            paddingRight: '12px',
          }}
        >
          Source Image Repository &nbsp;&nbsp;
          <GuidedTooltip title="Source image repository is used to configure applications container registry which will be used to list and pull container images into PEPsense system" />
        </Typography>
        {!data?.industrialWorkloadInformation?.serverUrl && (
          <>
            <Box
              style={{ display: 'flex', justifyContent: 'flex-end' }}
              justifyContent="row"
              alignItems="start"
            >
              <DataTableWrapperActionButton
                startIcon={<Add />}
                onClick={showCreateSourceImageRepositoryDialog}
                disabled={isPlatformReader || isPlatformEngineer}
                className="configureNew"
              >
                Configure New
              </DataTableWrapperActionButton>
            </Box>
            <Box>
              <Typography variant="body2" color="#A5A5A5" align="center">
                No data to display
              </Typography>
            </Box>
          </>
        )}
        {CreateSourceImageRepositoryDialog}
      </DataTableWrapper>
      {data?.industrialWorkloadInformation?.serverUrl && (
        <>
          <Box
            style={{ display: 'flex', justifyContent: 'flex-end' }}
            justifyContent="row"
            alignItems="start"
          >
            <DataTableWrapperActionButton
              startIcon={<Edit />}
              onClick={showEditSourceImageRepoDialog}
              style={{ marginRight: '15px' }}
              disabled={isPlatformReader || isPlatformEngineer}
            >
              Edit
            </DataTableWrapperActionButton>
            <DataTableWrapperActionButton
              startIcon={<DeleteOutline />}
              onClick={showDeleteSourceImageRepoDialog}
              color="error"
              sx={{ borderColor: '#DB1E37' }}
              disabled={isPlatformReader || isPlatformEngineer}
            >
              Delete
            </DataTableWrapperActionButton>
          </Box>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={[cellStyle, LabelStyle]}>Type</TableCell>
                <TableCell sx={cellStyle}>
                  {data?.industrialWorkloadInformation
                    ?.containerRepositoryType ===
                  SolutionSourceImageRepoContainerType.Acr
                    ? 'ACR'
                    : 'ECR'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={[cellStyle, LabelStyle]}>Server Url</TableCell>
                <TableCell sx={cellStyle}>
                  {data?.industrialWorkloadInformation?.serverUrl}
                </TableCell>
              </TableRow>
              {data?.industrialWorkloadInformation?.containerRepositoryType ===
                SolutionSourceImageRepoContainerType.Acr && (
                <>
                  <TableRow>
                    <TableCell sx={[cellStyle, LabelStyle]}>
                      Pull secret name
                    </TableCell>
                    <TableCell sx={cellStyle}>
                      {data?.industrialWorkloadInformation?.userName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={[cellStyle, LabelStyle]}>
                      Pull Secret
                    </TableCell>
                    <TableCell sx={cellStyle}>
                      {showImagePullSecret
                        ? data?.industrialWorkloadInformation?.ImagePullSecret
                        : '*'.repeat(dataLength)}{' '}
                      &nbsp;
                      {dataLength > 0 && (
                        <IconButton
                          onClick={handleShowImagePullSecret}
                          size="small"
                          title={showImagePullSecret ? 'Hide' : 'View'}
                        >
                          <VisibilityIcon
                            sx={{ color: '#0047BA', fontSize: '20px' }}
                          />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                  {data?.industrialWorkloadInformation?.AcrClientId && (
                    <TableRow>
                      <TableCell sx={[cellStyle, LabelStyle]}>
                        Client ID
                      </TableCell>
                      <TableCell sx={cellStyle}>
                        {data?.industrialWorkloadInformation?.AcrClientId}
                      </TableCell>
                    </TableRow>
                  )}
                  {data?.industrialWorkloadInformation?.AcrClientSecret && (
                    <TableRow>
                      <TableCell sx={[cellStyle, LabelStyle]}>
                        Client Secret
                      </TableCell>
                      <TableCell sx={cellStyle}>
                        {showImageACRClientSecret
                          ? data?.industrialWorkloadInformation?.AcrClientSecret
                          : '*'.repeat(dataLengthClientSecret)}{' '}
                        &nbsp;
                        {dataLengthClientSecret > 0 && (
                          <ClientSecretVisibilityIcon
                            sx={{ color: '#0047BA', fontSize: '20px' }}
                            onClick={handleShowImageACRClientSecret}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </>
              )}
              {data?.industrialWorkloadInformation?.containerRepositoryType ===
                SolutionSourceImageRepoContainerType.Ecr && (
                <>
                  <TableRow>
                    <TableCell sx={[cellStyle, LabelStyle]}>
                      Access Key
                    </TableCell>
                    <TableCell sx={cellStyle}>
                      {showEcrAccessKey
                        ? data?.industrialWorkloadInformation?.ecrAccesKey
                        : '*'.repeat(
                            data?.industrialWorkloadInformation?.ecrAccesKey
                              ?.length || 0
                          )}{' '}
                      &nbsp;
                      <EcrAccessKeyVisibilityIcon
                        sx={{ color: '#0047BA', fontSize: '20px' }}
                        onClick={handleShowEcrAccessKey}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={[cellStyle, LabelStyle]}>
                      Secret key
                    </TableCell>
                    <TableCell sx={cellStyle}>
                      {showEcrSecretKey
                        ? data?.industrialWorkloadInformation?.ecrSecretKey
                        : '*'.repeat(
                            data?.industrialWorkloadInformation?.ecrSecretKey
                              ?.length || 0
                          )}{' '}
                      &nbsp;
                      <EcrSecretKeyVisibilityIcon
                        sx={{ color: '#0047BA', fontSize: '20px' }}
                        onClick={handleShowEcrSecretKey}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={[cellStyle, LabelStyle]}>
                      Region endpoint
                    </TableCell>
                    <TableCell sx={cellStyle}>
                      {data?.industrialWorkloadInformation?.ecrRegionEndpoint}
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
          <SolutionDockerImages
            item={data.industrialWorkloadInformation}
            loading={loading}
          />
        </>
      )}
      {<SolutionPepsenceImageRepoDetails />}
      {EditSourceImageRepoDialog}
      {DeleteSourceImageRepoDialog}
    </Box>
  );
}

export default SolutionSourceImageRepository;
