import React from 'react';
import { generatePath, useParams } from 'react-router-dom';
import ModalDialog, {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { useStoreUnitActions } from 'src/redux/helpers';
import { companiesStore } from 'src/pages/Companies';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import { API_SOLUTION_DELETE_CONTRACT } from '../../constants';

type Props = {
  item: any;
  open: boolean;
  onClose: ModalCloseHandler;
};

function SolutionCompaniesDeleteModal({ item, open, onClose }: Props) {
  const { showAlert } = useAlert();
  const { solutionId } = useParams();
  const companyId = item.id;
  const actions = useStoreUnitActions(companiesStore);
  const [onDeleteClick, loading] = useStatefulApi(
    () =>
      pepApiRequest({
        method: RequestMethod.Delete,
        url: generatePath(API_SOLUTION_DELETE_CONTRACT, {
          solutionId,
          companyId,
        }),
      }),
    () => {
      showAlert({
        type: AlertType.Success,
        title: 'Contracts Delete Success',
        text: 'Companies assigned for current solution have been deleted successfully.',
      });
      onClose(null, ModalCloseReason.completeAction);
    }
  );
  return (
    <ModalDialog
      open={open}
      title="Delete Company"
      loading={loading}
      onConfirm={onDeleteClick}
      onClose={onClose}
      confirmBtnText="Yes"
      cancelBtnText="No"
    >
      Are you sure you want to delete this company?
      <b>{item.name}</b>
    </ModalDialog>
  );
}

export default SolutionCompaniesDeleteModal;
