import { createDatasetSlice, ResponseMapping } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import {
  API_APPLICATION_ROLES,
  USERS_STORE,
  APPLICATION_ROLES,
} from '../constants';

const api = (queryParams) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: API_APPLICATION_ROLES,
    queryParams,
  });

const applicationRolesSlice = createDatasetSlice({
  api,
  storeName: USERS_STORE,
  actionName: APPLICATION_ROLES,
  responseMapping: ResponseMapping.Default,
});
export default applicationRolesSlice;
