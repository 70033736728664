import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { generateApiPath, loadDataFromStorage } from 'src/utils';
import PageCardLayout from 'src/components/PageCardLayout';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { useStoreUnitActions } from 'src/redux/helpers';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import EditSolutionForm from './components/EditSolutionForm';
import {
  API_SOLUTION_UPDATE_CONSUMER,
  API_SOLUTION_UPDATE_CONSUMER_IOTH,
  API_SOLUTION_UPDATE_INDUSTRIAL,
} from '../constants';
import solutionsStore, { useSolutionDetails } from '../redux';
import { SolutionType } from '../types';
import { getSolutionEditParams } from './utils';

function EditSolution() {
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const { solutionId, solutionType } = useParams();
  const type = loadDataFromStorage('solutionType');
  const goBack = () => navigate(-1);
  const [item, loading] = useSolutionDetails();
  const displayName = item?.solutionDisplayName;
  const actions = useStoreUnitActions(solutionsStore);
  const disableCacheRetention =
    item?.adxInstance?.databaseName === (null || undefined);
  const urlPath =
    type.toUpperCase() === SolutionType.ioTH.toUpperCase()
      ? API_SOLUTION_UPDATE_CONSUMER_IOTH
      : API_SOLUTION_UPDATE_CONSUMER;

  const [handleSubmit, saving] = useStatefulApi(
    (params) =>
      pepApiRequest({
        method: RequestMethod.Put,
        url: generateApiPath(
          solutionType === SolutionType.Industrial
            ? API_SOLUTION_UPDATE_INDUSTRIAL
            : urlPath,
          {
            solutionId,
          }
        ),
        params: getSolutionEditParams(params, disableCacheRetention),
      }),
    () => {
      showAlert({
        type: AlertType.Info,
        title: 'Solution saved.',
        text: 'Solution has been modified successfully.',
      });
      goBack();
      actions.details(solutionId);
    }
  );

  return (
    <PageCardLayout
      loading={loading || saving}
      title={displayName && `Edit solution: ${displayName}`}
    >
      <EditSolutionForm
        item={item}
        onCancel={goBack}
        onSubmit={handleSubmit}
        confirmBtnText="Save"
        disableCacheRetention={disableCacheRetention}
      />
    </PageCardLayout>
  );
}

export default EditSolution;
