import { useLocation, useNavigate } from 'react-router-dom';
import Tab from '@mui/material/Tab';
import Tabs, { TabsContext } from 'src/components/Tabs';
import PageCardLayout from 'src/components/PageCardLayout';
import { useUserRolesArr } from 'src/auth';
import { RouteProps } from '../types';
import {
  getExactPath,
  getRootPath,
  getRoute,
  getRouteIndex,
  getRouteTabId,
  isUserRoleAllowed,
  isTabsPanelVisible,
} from '../utils';
import NestedRouter from './NestedRouter';

type Props = {
  routes: RouteProps[];
  rootPath?: string;
};

function RoutableTabsLayout({ routes, rootPath }: Props) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const userRoles = useUserRolesArr();
  const tabRoutes = routes.filter(({ meta }) => !meta?.hideTabs);
  const routeRootPath = rootPath || getRootPath(pathname, routes);
  const currentTabIndex = getRouteIndex(pathname, tabRoutes, routeRootPath);
  const currentRoute = getRoute(pathname, routes, routeRootPath) as RouteProps;
  const showTabs = isTabsPanelVisible(currentRoute, pathname, routeRootPath);

  const onTabChange = (index: number) => {
    const { path = '' } = tabRoutes[index];
    navigate(path);
  };

  return showTabs ? (
    <TabsContext index={currentTabIndex} onChange={onTabChange}>
      <PageCardLayout
        data-testid="routable-tabs-layout"
        headerProps={{ display: 'block', p: 0 }}
        actionsPanel={
          <Tabs>
            {tabRoutes.map(({ path = '', exact, meta, allowedUserRoles }) => (
              <Tab
                data-testid={getRouteTabId('routable-tab-link', path)}
                key={getExactPath(path, exact)}
                label={meta?.title}
                disabled={
                  !isUserRoleAllowed(userRoles, allowedUserRoles) ||
                  meta?.disabled
                }
              />
            ))}
          </Tabs>
        }
      >
        <NestedRouter routes={routes} />
      </PageCardLayout>
    </TabsContext>
  ) : (
    <NestedRouter routes={routes} />
  );
}

export default RoutableTabsLayout;
