import { RouteProps } from 'src/router';
import { UserRole } from 'src/auth';
import * as ROUTES from './constants';
import CompanyDetailsContent from './components/CompanyDetailsContent';
import CompanyUsers from '../CompanyUsers';

export const routes: RouteProps[] = [
  {
    index: true,
    component: CompanyDetailsContent,
    meta: {
      title: 'Details',
    },
  },
  {
    path: ROUTES.COMPANY_USERS,
    component: CompanyUsers,
    allowedUserRoles: [
      UserRole.PlatformOwner,
      UserRole.PlatformAdmin,
      UserRole.TenantOwner,
      UserRole.PlatformReader,
    ],
    meta: {
      title: 'Users',
    },
  },
];
