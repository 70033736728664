import { useMemo } from 'react';
import { useForm } from 'src/components/Form';
import ModalDialog, { ModalCloseHandler } from 'src/components/ModalDialog';
import { SolutionFileSubscriptionItem } from '../../types';
import { getFileSubscriptionEditFormFields } from '../editFileSubscriptionFormFields';

type Props = {
  item?: SolutionFileSubscriptionItem;
  title: string;
  loading?: boolean;
  open: boolean;
  onClose: ModalCloseHandler;
  onSubmit: (values: any) => void;
  confirmBtnText?: string;
};

function SolutionFileSubscriptionEditModal({
  item,
  title,
  loading,
  open,
  onClose,
  onSubmit,
  confirmBtnText = 'Save',
}: Props) {
  const handlePaste = () => {
    navigator.clipboard.readText().then((clipboardText) => {
      setValue('DestinationConnectionString', clipboardText.trim());
    });
  };

  const fields = useMemo(
    () => getFileSubscriptionEditFormFields(item, handlePaste),
    []
  );
  const [form, handleSubmit, setValue] = useForm({
    visibleValuesOnly: true,
    values: item,
    fields,
    onSubmit,
  });

  return (
    <ModalDialog
      open={open}
      title={title}
      confirmBtnText={confirmBtnText}
      onClose={onClose}
      onConfirm={handleSubmit}
      loading={loading}
      fullWidth
    >
      {form}
    </ModalDialog>
  );
}

export default SolutionFileSubscriptionEditModal;
