import React, {
  useEffect,
  useState,
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  ReactNode,
} from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import SearchIcon from '@mui/icons-material/Search';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  SxProps,
  Chip,
} from '@mui/material';
import SelectOption, { Option, OptionValue } from 'src/components/SelectOption';
import AutoCompleteBoxDisplayValue from '../AutoCompleteBox/components/AutoCompleteBoxDisplayValue';
import AutoCompleteBoxDisplayValueItem from '../AutoCompleteBox/components/AutoCompleteBoxDisplayValueItem';

interface Cluster {
  id: string;
  name: string;
  status: string;
}

interface Item {
  id: string;
  name: string;
  sector: string;
  description: string;
  createdBy: string;
  modifiedBy: string;
  status: string;
  solutionIds?: string[];
  cluster?: Cluster;
  clusters?: Cluster[];
  observability?: string;
}

interface ParentLabelAndChildren {
  parentId: string;
  parentLabel: string;
  children: {
    label: string;
    value: string;
  }[];
}

type Props = FormControlProps & {
  onChange: (value) => void;
  levelOptions?: {
    parentId: string;
    parentLabel: string;
    children: { label: string; value: string }[];
  }[];
  label?: ReactNode;
  helperText?: ReactNode;
  loading?: boolean;
  multiple?: boolean;
};
const labelStyle = {
  backgroundColor: 'white',
  px: 0.5,
};
const inputStyle: SxProps = {
  pb: 1,
  '&:before': {
    borderColor: 'primary.main',
  },
  '&:hover:not(.Mui-disabled):before': {
    borderColor: 'primary.main',
  },
};
const displayValueStyle = {
  maxHeight: '13rem',
  overflow: 'auto',
  overflowX: 'hidden',
};
const getContainerStyle = (error?: boolean): SxProps => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  p: 1.3,
  mr: 2,
  border: 1,
  borderColor: error ? 'error.main' : 'neutral.dark',
  borderRadius: 0.4,
});

const TreeSelect = (
  {
    id = '',
    loading,
    label,
    placeholder = 'Search here...',
    multiple,
    levelOptions = [],
    onChange,
    error,
    helperText,
    ...rest
  }: Props,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const [data, setData] = useState<ParentLabelAndChildren[]>([]);
  const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>(
    {}
  );
  const labels: any[] = [];
  const noOptions = !loading && !data.length;

  useEffect(() => {
    setData(levelOptions);
    const initialChecked: { [key: string]: boolean } = {};
    levelOptions.forEach((parent) => {
      initialChecked[parent.parentId] = false;
      parent.children.forEach((child) => {
        initialChecked[child.value] = false;
      });
    });
    setCheckedItems(initialChecked);
  }, [levelOptions]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    parentId: string,
    value: string
  ) => {
    const updatedChecked = { ...checkedItems };
    if (value === 'parent') {
      updatedChecked[parentId] = event.target.checked;
      data
        .find((parent) => parent.parentId === parentId)!
        .children.forEach((child) => {
          updatedChecked[child.value] = event.target.checked;
        });
    } else {
      updatedChecked[value] = event.target.checked;
      const allChildrenChecked = data
        .find((parent) => parent.parentId === parentId)!
        .children.every((child) => updatedChecked[child.value]);
      updatedChecked[parentId] = allChildrenChecked;
    }
    setCheckedItems(updatedChecked);
    onChange(updatedChecked);
  };

  const getChipsLabels = () => {
    data.map((parent) => {
      if (parent.children.length === 0 && checkedItems[parent.parentId]) {
        labels.push({ label: parent.parentLabel, value: parent.parentId });
      } else {
        if (
          parent.children.every((child) => checkedItems[child.value]) &&
          checkedItems[parent.parentId]
        ) {
          labels.push({ label: parent.parentLabel, value: parent.parentId });
          return null;
        }
        const childLabels = parent.children.filter(
          (child) => checkedItems[child.value]
        );
        childLabels.map((child) =>
          labels.push({ value: child.value, label: child.label })
        );
      }
      return null;
    });
    return labels;
  };

  const handleDelete = (item) => {
    const updatedSelectedItems = { ...checkedItems };
    updatedSelectedItems[item] = false;

    const parent = data.find((parent) => parent.parentId === item);
    if (parent) {
      updatedSelectedItems[parent.parentId] = false;
      parent.children.forEach((child) => {
        updatedSelectedItems[child.value] = false;
      });
    } else {
      data.forEach((parent) => {
        if (parent.children.some((child) => child.value === item)) {
          updatedSelectedItems[parent.parentId] = parent.children.some(
            (child) => updatedSelectedItems[child.value]
          );
        }
      });
    }

    setCheckedItems(updatedSelectedItems);
    onChange(updatedSelectedItems);
  };

  return (
    <FormControl data-testid="treeSelect-box" error={error} {...rest}>
      {label && (
        <InputLabel sx={labelStyle} htmlFor={id} shrink>
          {label}
        </InputLabel>
      )}
      <Box
        data-testid="autocomplete-box-container"
        sx={getContainerStyle(error)}
      >
        <Box
          data-testid="autocomplete-box-display-value"
          sx={displayValueStyle}
        >
          <Input
            data-testid="treeselect-box-input"
            ref={ref}
            sx={inputStyle}
            id={id}
            aria-checked
            placeholder={placeholder}
            startAdornment={
              <InputAdornment position="start">
                {getChipsLabels().map((item) => (
                  <Chip
                    key={item.value}
                    label={item.label}
                    onDelete={() => handleDelete(item.value)}
                  />
                ))}
              </InputAdornment>
            }
            onKeyDown={(event) => {
              if (event.code === 'Enter') {
                event.preventDefault();
              }
            }}
          />
        </Box>
        {noOptions && <MenuItem disabled>No available options</MenuItem>}
        {loading && <MenuItem disabled>Loading...</MenuItem>}
        {data.map((parent) => (
          <div key={parent.parentId}>
            <FormControlLabel
              label={parent.parentLabel}
              control={
                <Checkbox
                  checked={!!checkedItems[parent.parentId]}
                  indeterminate={
                    parent.children.some(
                      (child) => checkedItems[child.value]
                    ) &&
                    !parent.children.every((child) => checkedItems[child.value])
                  }
                  onChange={(event) =>
                    handleChange(event, parent.parentId, 'parent')
                  }
                />
              }
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
              {parent.children.map((child) => (
                <FormControlLabel
                  key={child.value}
                  label={child.label}
                  control={
                    <Checkbox
                      checked={!!checkedItems[child.value]}
                      onChange={(event) =>
                        handleChange(event, parent.parentId, child.value)
                      }
                    />
                  }
                />
              ))}
            </Box>
          </div>
        ))}
      </Box>
    </FormControl>
  );
};

export default React.forwardRef(TreeSelect);
