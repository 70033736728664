import { useParams } from 'react-router-dom';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import ModalDialog, {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { generateApiPath } from 'src/utils';
import { useStoreUnitActions } from 'src/redux/helpers';
import { API__DELETE_FILE_SUBSCRIPTION_ITEM } from '../../constants';
import { SolutionFileSubscriptionItem } from '../../types';
import solutionsStore from '../../redux/solutionsStore';

type Props = {
  item: SolutionFileSubscriptionItem;
  open: boolean;
  onClose: ModalCloseHandler;
};

function SolutionFileSubscriptionDeleteModal({ item, open, onClose }: Props) {
  const { solutionId } = useParams();
  const { showAlert } = useAlert();
  const actions = useStoreUnitActions(solutionsStore);
  const [handleRemove, loading] = useStatefulApi(
    () =>
      pepApiRequest({
        method: RequestMethod.Delete,
        url: generateApiPath(API__DELETE_FILE_SUBSCRIPTION_ITEM, {
          solutionId,
          subscriptionId: item?.id,
        }),
      }),
    () => {
      showAlert({
        type: AlertType.Info,
        title: `'${item?.name}' deleted`,
        text: 'File subscription has been successfully deleted.',
      });
      onClose(null, ModalCloseReason.completeAction);
      actions.fileSubscriptionById(solutionId);
    }
  );

  return (
    <ModalDialog
      open={open}
      title="Delete Storage"
      loading={loading}
      onClose={onClose}
      onConfirm={handleRemove}
      cancelBtnText="No"
      confirmBtnText="Yes"
    >
      Are you sure you want to delete the Storage?&nbsp;
      <br />
      <b>{item?.name}</b>
    </ModalDialog>
  );
}

export default SolutionFileSubscriptionDeleteModal;
