import {
  DataTableActionsButton,
  DataTableActionsWrapper,
  useRowClickHandler,
} from 'src/components/DataTable';

import { generatePath, useNavigate } from 'react-router-dom';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { ReactComponent as CertificateGreen } from 'src/assets/icons/Certificate_Active_Green.svg';
import { ReactComponent as CertificateYellow } from 'src/assets/icons/Certificate_Renewal_Initiated_Yellow.svg';
import { ReactComponent as CertificateRed } from 'src/assets/icons/Certificate_Revoked.svg';
import { ReactComponent as AuthorizationRequest } from 'src/assets/icons/Authorisation_request.svg';
import { ReactComponent as ModifyObservability } from 'src/assets/icons/Modifyobservability.svg';
import { ReactComponent as RevokeCertificate } from 'src/assets/icons/Revokecertificate.svg';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import TerminalOutlinedIcon from '@mui/icons-material/TerminalOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Delete } from '@mui/icons-material';
import { useModal } from 'src/hooks';
import { useUserRoles } from 'src/auth';
import { loadDataFromStorage, navigateToExternalUrl } from 'src/utils';
import { useStoreUnitActions } from 'src/redux/helpers';
import MoreMenu from 'src/components/MoreMenu';
import SolutionDevicesAssignTemplateModal from './SolutionDevicesAssignTemplateModal';
import SolutionDevicesNavToDetailsModal from './SolutionDevicesNavToDetailsModal';
import { useSolutionUserRoles } from '../../hooks';
import solutionsStore, { useSolutionDetails } from '../../redux';
import {
  SolutionDeviceItem,
  CertRenwalState,
  JobType,
  SolutionMenderStatus,
  SolutionType,
} from '../../types';
import { IOT_CENTRAL_DEVICE_URL, MENDER_DEVICES_URL } from '../constants';
import RevokeCertificationModal from './RevokeCertificationModal';
import SolutionDeviceDeleteModel from './SolutionDevicesDeleteModel';
import SolutionDeviceCertificateViewModal from './SolutionDeviceCertificateViewModal';
import SolutionDeviceObservabilityConfigModal from './SolutionDeviceObservabilityConfigModal';
import SolutionDevicesAuthoriseModal from './SolutionDevicesAuthoriseModal';
import { SOLUTION_DEVICE_DETAILS_MODULES } from '../../constants';
import SolutionDevicesAssignCompanyModal from './SolutionDevicesAssignCompanyModal';

type Props = {
  item?: SolutionDeviceItem;
};

function SolutionDevicesActions({ item }: Props) {
  const { devicesPersist } = useStoreUnitActions(solutionsStore);
  const navigate = useNavigate();
  const { isSolutionEditor } = useSolutionUserRoles();
  const [solutionDetails] = useSolutionDetails();

  const { menderEnabled } = solutionDetails?.features?.menderSetup || {};
  const iotCentralUrl = solutionDetails?.ioTCentralInstance?.url;
  const deviceCertInfo = item?.deviceCertInfo;
  const jobType = deviceCertInfo?.jobType;
  const certRenewalState = deviceCertInfo?.certRenewalState;
  const solutionType: string = loadDataFromStorage('solutionType');
  let CertificateIcon = CertificateGreen;
  let certificateTitle = 'Certificate Active';
  let certStatus = 'Active';
  if (deviceCertInfo?.isRevoked || jobType === JobType.Revoked) {
    CertificateIcon = CertificateRed;
    certificateTitle = 'Certificate Revoked';
    certStatus = 'Revoked';
  } else if (jobType === JobType.Provisioned) {
    CertificateIcon = CertificateGreen;
    certificateTitle = 'Certificate Active';
    certStatus = 'Active';
  } else if (jobType === JobType.RenewalProcessing) {
    switch (certRenewalState) {
      case CertRenwalState.RenewalInitiated:
        CertificateIcon = CertificateYellow;
        certificateTitle = 'Certificate Renewal Initiated';
        certStatus = 'Renewal Initiated';
        break;
      case CertRenwalState.RequestAcknowledged:
        CertificateIcon = CertificateYellow;
        certificateTitle = 'Certificate Renewal Acknowledged';
        certStatus = 'Renewal Acknowledged';
        break;
      case CertRenwalState.CertIssued:
        CertificateIcon = CertificateYellow;
        certificateTitle = 'Certificate Issued';
        certStatus = certificateTitle;
        break;
      case CertRenwalState.CertRenewed:
        CertificateIcon = CertificateGreen;
        certificateTitle = 'Certificate Renewed';
        certStatus = 'Active';
        break;
      case CertRenwalState.CertRenewalFailed:
        CertificateIcon = CertificateRed;
        certificateTitle = 'Certificate Renewal failed';
        certStatus = 'Renewal failed';
        break;
      default:
        CertificateIcon = CertificateGreen;
        certificateTitle = 'Certificate Active';
        certStatus = 'Active';
    }
  }

  const { isPlatformReader, isPlatformEngineer } = useUserRoles();

  const openAssignTemplate = () => {
    devicesPersist.add(item);
    showAssignTemplateDialog();
  };
  const [AssignTemplateDialog, showAssignTemplateDialog] = useModal(
    SolutionDevicesAssignTemplateModal,
    { item }
  );

  const [AssignCompanyDialog, showAssignCompanyDialog] = useModal(
    SolutionDevicesAssignCompanyModal,
    { item }
  );

  const openDeviceDetails = () => {
    devicesPersist.add(item);
    if (solutionType === SolutionType.ioTH) {
      return navigate(
        generatePath(SOLUTION_DEVICE_DETAILS_MODULES, {
          solutionName: item?.id,
          iotEdge: item?.iotEdge.toString(),
          modelId: item?.modelId,
        })
      );
    }
    return navigateToExternalUrl(IOT_CENTRAL_DEVICE_URL, {
      params: { iotCentralUrl, deviceId: item?.id },
    });
  };
  const [NavigateToDetailsDialog, showNavigateToDetailsDialog] = useModal(
    SolutionDevicesNavToDetailsModal,
    { openDeviceDetails }
  );
  const openRemoteTerminal = () => {
    devicesPersist.add(item);
    navigateToExternalUrl(MENDER_DEVICES_URL, {
      queryParams: { id: item?.menderId },
    });
  };

  const [RevokeDialog, showRevokeDialog] = useModal(RevokeCertificationModal, {
    selectedItems: [item],
  });

  const [DeleteDevicesDialog, showDeleteDialog] = useModal(
    SolutionDeviceDeleteModel,
    { selectedItems: [item] }
  );

  const [CertStatusDialog, showCertStatusDialog] = useModal(
    SolutionDeviceCertificateViewModal,
    { item, certStatus }
  );

  const openAuthoriseDialog = () => {
    devicesPersist.add(item);
    showAuthoriseDialog();
  };

  const [AuthoriseDevicesDialog, showAuthoriseDialog] = useModal(
    SolutionDevicesAuthoriseModal,
    {
      selectedItems: [item],
      displayStyle: !isSolutionEditor ? 'none' : 'flex',
      openAuthoriseDialog,
    }
  );

  const [ObservabilityConfigDialog, showObservabilityConfigDialog] = useModal(
    SolutionDeviceObservabilityConfigModal,
    { item }
  );

  useRowClickHandler(showNavigateToDetailsDialog);

  const isRemoteTerminalEnable = menderEnabled && item?.menderId;
  const canAuthorise = item?.menderStatus === SolutionMenderStatus.Pending;

  const actions = [
    {
      icon: VisibilityOutlinedIcon,
      title: 'Device details',
      onClick: openDeviceDetails,
      disabled:
        solutionType.toUpperCase() === SolutionType.ioTH.toUpperCase()
          ? false
          : isPlatformReader,
    },
    {
      icon: AssignmentOutlinedIcon,
      title: 'Assign template',
      onClick: openAssignTemplate,
      disabled: !isSolutionEditor,
    },
    isRemoteTerminalEnable && {
      icon: TerminalOutlinedIcon,
      title: 'Remote terminal session',
      onClick: openRemoteTerminal,
      disabled: !isSolutionEditor,
      sx: { fontSize: 'small' },
    },
    deviceCertInfo !== null && {
      icon: CertificateIcon,
      title: certificateTitle,
      onClick: showCertStatusDialog,
      disabled: false,
      sx: { pt: ({ spacing }) => spacing(1) },
    },
    {
      icon: RevokeCertificate,
      title: 'Revoke Certificate',
      onClick: showRevokeDialog,
      disabled: isPlatformReader || isPlatformEngineer,
      sx:
        !item?.enabled ||
        (!isSolutionEditor && !isPlatformReader && !isPlatformEngineer)
          ? 'none'
          : 'flex',
    },
    solutionType !== SolutionType.ioTH && {
      icon: ModifyObservability,
      title: 'Modify Observability Configurations',
      onClick: showObservabilityConfigDialog,
      disabled: isPlatformReader,
      sx: 'flex',
    },
    {
      icon: AuthorizationRequest,
      title: 'Authorisation request',
      onClick: showAuthoriseDialog,
      disabled: !canAuthorise || isPlatformReader || isPlatformEngineer,
    },
    {
      icon: DeleteOutlinedIcon,
      title: 'Delete',
      onClick: showDeleteDialog,
      disabled: isPlatformReader || isPlatformEngineer,
      sx:
        !isSolutionEditor && !isPlatformReader && !isPlatformEngineer
          ? 'none'
          : 'flex',
    },
  ];

  const validActionIcons = actions.filter(
    (action: any) => action !== false && action !== '' && action !== undefined
  );

  const initialActions = validActionIcons.slice(0, 4);
  const moreActions = validActionIcons.slice(4);
  const iotHActionOptions = [
    ...moreActions,
    {
      title: 'Associate to a Company',
      icon: <Delete />,
      onClick: showAssignCompanyDialog,
      disabled: isPlatformReader || isPlatformEngineer,
      sx: 'flex',
    },
  ];
  const actionOptions =
    solutionType.toUpperCase() === SolutionType.ioTH.toUpperCase()
      ? iotHActionOptions
      : moreActions;

  function DataTableIcons({ icons }) {
    return (
      <div>
        {icons.map((action, index) => (
          <DataTableActionsButton
            key={index}
            icon={action.icon}
            title={action.title}
            onClick={action.onClick}
            disabled={action.disabled}
            sx={action.sx}
          />
        ))}
      </div>
    );
  }

  return (
    <DataTableActionsWrapper>
      <DataTableIcons icons={initialActions} />
      {validActionIcons.length > 4 && <MoreMenu actions={actionOptions} />}
      {CertStatusDialog}
      {DeleteDevicesDialog}
      {RevokeDialog}
      {AuthoriseDevicesDialog}
      {ObservabilityConfigDialog}
      {AssignTemplateDialog}
      {solutionType === SolutionType.ioTH && AssignCompanyDialog}
      {!isPlatformReader && NavigateToDetailsDialog}
    </DataTableActionsWrapper>
  );
}
export default SolutionDevicesActions;
