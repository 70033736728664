import Typography from '@mui/material/Typography';

function SolutionDeviceGroupFilterHeading() {
  return (
    <>
      <Typography variant="body2md">Device query</Typography>
      <Typography pt={1} variant="body2">
        Build a query to identify the kinds of devices to include in your group.
      </Typography>
    </>
  );
}

export default SolutionDeviceGroupFilterHeading;
