import { Box, Typography } from '@mui/material';
import { isModalComplete } from 'src/components/ModalDialog';
import DataTable, {
  DataTableWrapper,
  DataTableWrapperActionButton,
} from 'src/components/DataTable';
import { useModal } from 'src/hooks';
import DateFormattedValue from 'src/components/DateFormattedValue';
import SolutionSourcePullImageConfig from './SolutionSourcePullImageModal';
import {
  SolutionSourceImageRepositoryItem,
  SolutionSourcePullDockerImageItemField,
} from '../../types';
import SolutionDockerImagesStatus from './SolutionDockerImagesStatus';
import { useSolutionUserRoles } from '../../hooks';
import SolutionWorkloadMessage from '../../SolutionEdgeWorkload/components/SolutionWorkloadMessage';

type Props = {
  item?: SolutionSourceImageRepositoryItem;
  loading?: boolean;
};

const columns = [
  {
    id: SolutionSourcePullDockerImageItemField.ImageName,
    title: 'Image name',
  },
  {
    id: SolutionSourcePullDockerImageItemField.Tag,
    title: 'Tag',
  },
  {
    id: SolutionSourcePullDockerImageItemField.Status,
    title: 'Status',
    Component: SolutionDockerImagesStatus,
  },
  {
    id: SolutionSourcePullDockerImageItemField.RequestedDate,
    title: 'Created',
    Component: DateFormattedValue,
  },
  {
    id: SolutionSourcePullDockerImageItemField.FailedReason,
    title: 'Message',
    Component: SolutionWorkloadMessage,
  },
];

function SolutionDockerImages({ item, loading }: Props) {
  const { isSolutionOwner } = useSolutionUserRoles();
  const [CreatePullDockerImagesDialog, showPullDockerImagesDialog] = useModal(
    SolutionSourcePullImageConfig,
    {
      item,
      onClose: (reason) => isModalComplete(reason),
    }
  );

  return (
    <DataTableWrapper
      searchBar={false}
      actionsPanel={
        <DataTableWrapperActionButton
          disabled={!isSolutionOwner}
          onClick={showPullDockerImagesDialog}
          sx={{ padding: '7px 43px' }}
          className="pulldockerimage"
        >
          Pull docker Images
        </DataTableWrapperActionButton>
      }
    >
      <Typography
        style={{
          fontWeight: 'bold',
          fontSize: '17px',
          marginBottom: '60px',
          paddingRight: '12px',
        }}
      >
        Docker Images
      </Typography>
      {item?.sourceDockerImages ? (
        <DataTable
          columns={columns}
          sx={{ marginTop: '40px' }}
          data={item?.sourceDockerImages}
          loading={loading}
        />
      ) : (
        <Box>
          <Typography variant="body2" color="#A5A5A5" align="center">
            No data to display
          </Typography>
        </Box>
      )}
      {CreatePullDockerImagesDialog}
    </DataTableWrapper>
  );
}

export default SolutionDockerImages;
