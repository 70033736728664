import React, { useCallback } from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import isArray from 'lodash/isArray';
import Box, { BoxProps } from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import AddIcon from '@mui/icons-material/Add';
import { SxProps } from 'src/theme/types';
import { FormArrayField, FormFieldDefaultValue } from '../types';
import FormArrayRow from './FormArrayRow';
import { getInitialValues } from '../utils';
import { DEFAULT_FORM_ARRAY_MAX_ROWS } from '../constants';

type Props<T> = Omit<BoxProps, 'onSubmit'> &
  FormArrayField<T> & {
    control: Control<any>;
  };

const containerStyle: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
};

const appendBtnStyle: SxProps = {
  alignSelf: 'flex-start',
};

function FormArray<T = FormFieldDefaultValue>({
  description,
  disabled,
  name,
  control,
  childFields,
  appendLabel = 'Add',
  maxRows = DEFAULT_FORM_ARRAY_MAX_ROWS,
  RowComponent = FormArrayRow,
}: Props<T>) {
  const { fields, append, remove, update } = useFieldArray({
    control,
    name,
  });
  const appendNewItem = useCallback(
    () => append(getInitialValues(childFields)),
    [childFields]
  );

  return (
    <>
      {description && <FormLabel>{description}</FormLabel>}
      <Box sx={containerStyle}>
        {isArray(fields) &&
          fields.map((values, index) => (
            <RowComponent
              key={values.id}
              childFields={childFields}
              control={control}
              disabled={disabled}
              index={index}
              name={name}
              update={update}
              remove={remove}
              values={values}
            />
          ))}
        <Button
          sx={appendBtnStyle}
          size="small"
          startIcon={<AddIcon />}
          onClick={appendNewItem}
          disabled={disabled || fields.length >= maxRows}
        >
          {appendLabel}
        </Button>
      </Box>
    </>
  );
}

export default FormArray;
