import React from 'react';
import { FieldValues } from 'react-hook-form';
import FormContainer from './components/FormContainer';
import FormGroup from './components/FormGroup';
import { FormProps } from './types';
import { useFormConfig } from './hooks';

function Form<T extends FieldValues = FieldValues>({
  children,
  fields,
  fieldProps,
  values,
  onChange,
  onSubmit,
  disabled,
  visibleValuesOnly,
  ...rest
}: FormProps<T>) {
  const formProps = useFormConfig<T>({
    fields,
    values,
    onChange,
    visibleValuesOnly,
  });
  const { control, handleSubmit } = formProps;

  return (
    <FormContainer<T>
      data-testid="form"
      contextProps={formProps}
      onSubmit={handleSubmit(onSubmit)}
      {...rest}
    >
      <FormGroup
        control={control}
        disabled={disabled}
        fields={fields}
        {...fieldProps}
      />
      {children}
    </FormContainer>
  );
}

export default Form;
