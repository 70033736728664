import React from 'react';
import HeaderContent from './components/HeaderContent';
import HeaderContext, { headerTypes } from './HeaderContext';

export enum HeaderType {
  Light = 'light',
  Dark = 'dark',
}

type Props = {
  type?: HeaderType;
};

function Header({ type = HeaderType.Light }: Props) {
  return (
    <HeaderContext.Provider value={headerTypes[type]}>
      <HeaderContent />
    </HeaderContext.Provider>
  );
}

export default Header;
