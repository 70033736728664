import { generatePath } from 'react-router-dom';
import { createDatasetSlice } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import {
  API_SOLUTION_SPECIFIC_RULES_MANAGEMENT,
  SOLUTIONS_STORE,
  SOLUTIONS_STORE_SPECIFIC_RULES_MANAGEMENT,
} from '../constants';
import { mapSolutionSpecificRulesManagementData } from './utils';

const api = (Id: string) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: generatePath(API_SOLUTION_SPECIFIC_RULES_MANAGEMENT, { Id }),
  }).then((item) => mapSolutionSpecificRulesManagementData(item));

const solutionSpecifcRulesSlice = createDatasetSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTIONS_STORE_SPECIFIC_RULES_MANAGEMENT,
});

export default solutionSpecifcRulesSlice;
