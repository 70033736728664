import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Add from '@mui/icons-material/Add';
import DataTable, {
  DataTableWrapper,
  DataTableWrapperActionButton,
  PagingType,
  useTableQueryParams,
  getActionsColConfig,
} from 'src/components/DataTable';
import { useStoreUnitActions } from 'src/redux/helpers';
import { useModal } from 'src/hooks';
import { useUserRoles } from 'src/auth';
import { loadDataFromStorage } from 'src/utils';
import { isModalComplete } from 'src/components/ModalDialog';
import solutionsStore, { useSolutionJobs } from '../../redux';
import { SolutionJobItemField, SolutionType } from '../../types';
import { useSolutionUserRoles } from '../../hooks';
import SolutionJobType from './SolutionJobType';
import SolutionJobStatus from './SolutionJobStatus';
import SolutionJobActions from './SolutionJobActions';
import SolutionJobCreateContainer from './SolutionJobCreateContainer';

function SolutionJobsList() {
  const { solutionId } = useParams();
  const { isSolutionEditor } = useSolutionUserRoles();
  const { isPlatformReader } = useUserRoles();
  const actions = useStoreUnitActions(solutionsStore);
  const { data, loading, totalItems } = useSolutionJobs();
  const { paging, sorting } = useTableQueryParams();
  const solutionType = loadDataFromStorage('solutionType');
  const { pageIndex, pageSize } = paging;
  const { sortBy, sortOrder } = sorting;

  const columns = [
    {
      id: SolutionJobItemField.DisplayName,
      title: 'Job name',
    },
    {
      id: SolutionJobItemField.Type,
      title: 'Job type',
      Component: SolutionJobType,
    },
    {
      id: SolutionJobItemField.Status,
      title: 'Status',
      Component: SolutionJobStatus,
    },
    getActionsColConfig(SolutionJobActions),
  ];
  const ioTHColumns = [
    {
      id: SolutionJobItemField.DisplayName,
      title: 'Job name',
    },
    {
      id: SolutionJobItemField.GroupName,
      title: 'Device group',
    },
    {
      id: SolutionJobItemField.Type,
      title: 'Job type',
      Component: SolutionJobType,
    },
    {
      id: SolutionJobItemField.Status,
      title: 'Status',
      Component: SolutionJobStatus,
    },
    getActionsColConfig(SolutionJobActions),
  ];

  const [NewJobDialog, showNewJobDialog] = useModal(
    SolutionJobCreateContainer,
    {
      onClose: (event, reason) =>
        isModalComplete(reason) &&
        actions.jobs({ solutionId, ...paging, ...sorting }),
    }
  );

  useEffect(() => {
    actions.jobs({ solutionId, pageIndex, pageSize, sortBy, sortOrder });
  }, [solutionId, pageIndex, pageSize, sortBy, sortOrder]);

  return (
    <DataTableWrapper
      title="Jobs"
      actionsPanel={
        <DataTableWrapperActionButton
          onClick={showNewJobDialog}
          startIcon={<Add />}
          disabled={!isSolutionEditor || isPlatformReader}
        >
          New job
        </DataTableWrapperActionButton>
      }
      searchBar={true}
    >
      <DataTable
        data={data}
        columns={SolutionType.ioTH === solutionType ? ioTHColumns : columns}
        loading={loading}
        paging={PagingType.Remote}
        totalItems={totalItems}
        {...paging}
        {...sorting}
      />
      {NewJobDialog}
    </DataTableWrapper>
  );
}

export default SolutionJobsList;
