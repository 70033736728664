import React, { useContext, useState } from 'react';
import { Box, Button, Popover } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useParams } from 'react-router-dom';
import solutionsStore from 'src/pages/Solutions/redux/solutionsStore';
import Filter from 'src/pages/Solutions/SolutionDevices/components/SolutionDeviceFilter';
import { useStoreUnitActions } from 'src/redux/helpers';
import {
  SolutionDeviceItemField,
  SolutionType,
} from 'src/pages/Solutions/types';
import { FilterTableContext } from './FilterTableContext';

interface COMPONENTSFILTERINTERFACE {
  index?: string;
  selectedColumn: string;
  searchValue: string;
}

const DataTableFilter = (props) => {
  const filterContext = useContext(FilterTableContext);

  const { devicesPersist } = useStoreUnitActions(solutionsStore);
  const [components, setComponents] = useState([
    {
      index: '0',
      selectedColumn: '',
      searchValue: '',
    },
  ]);

  function addFilterInputFields() {
    if (components.length < 3)
      setComponents((prevs) => [
        ...components,
        {
          index: components.length.toString(),
          selectedColumn: '',
          searchValue: '',
        },
      ]);
  }
  const removeFilterInputFields = (i) => {
    const rows = [...components];
    if (rows.length > 1) rows.splice(i, 1);
    setComponents(rows);
  };
  const handleshowFilter = () => {
    filterContext?.toggleshowfilter();
  };
  const handleFilterChange = (updatedComponents) => {
    setComponents([...updatedComponents]);
  };
  const { solutionId } = useParams();
  const { devices: actions } = useStoreUnitActions(solutionsStore);
  const handleApplyFilter = () => {
    handleshowFilter();
    const filteredData: string[] = [];
    components.forEach((value: COMPONENTSFILTERINTERFACE) => {
      if (
        filterContext?.solutionType.toUpperCase() ===
        SolutionType.ioTH.toUpperCase()
      ) {
        switch (value.selectedColumn.toUpperCase()) {
          case SolutionDeviceItemField.DeviceId.toUpperCase():
            filteredData.push(`${value.searchValue}`);
            break;
          case SolutionDeviceItemField.ConnectionState.toUpperCase():
            filteredData.push(`connectionState eq ${value.searchValue}`);
            break;
          case SolutionDeviceItemField.MenderStatus.toUpperCase():
            filteredData.push(`menderStatus eq ${value.searchValue}`);
            break;
          default:
            break;
        }
      } else {
        switch (value.selectedColumn.toUpperCase()) {
          case SolutionDeviceItemField.DisplayName.toUpperCase():
            filteredData.push(`contains(displayName, '${value.searchValue}')`);
            break;
          case SolutionDeviceItemField.DeviceId.toUpperCase():
            filteredData.push(`contains(id, '${value.searchValue}')`);
            break;
          case SolutionDeviceItemField.Provisioned.toUpperCase():
            filteredData.push(`provisioned eq '${value.searchValue}'`);
            break;
          case SolutionDeviceItemField.MenderStatus.toUpperCase():
            filteredData.push(`menderStatus eq ${value.searchValue}`);
            break;
          default:
            break;
        }
      }
    });
    const filterString = filteredData.join('and');
    if (filterString) {
      actions.init({ solutionId, filterString });
      devicesPersist.init({ solutionId, filterString });
    }
    filterContext?.scrollerUpdateWithFilter(filterString);
  };
  const handleResetFilter = () => {
    setComponents([
      ...components.splice(0, 0),
      {
        index: '0',
        selectedColumn: '',
        searchValue: '',
      },
    ]);
    actions.init({ solutionId });
    devicesPersist.update({ solutionId });
  };
  return (
    <Popover
      id={props.id}
      open={Boolean(filterContext?.showFilters)}
      onClose={handleshowFilter}
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box sx={{ margin: ({ spacing }) => spacing(1) }}>
        {filterContext?.solutionType.toUpperCase() ===
        SolutionType.ioTH.toUpperCase() ? (
          <Filter
            components={components}
            columns={props.columns.filter(
              (x) =>
                x.id === 'id' ||
                x.id === 'connectionState' ||
                x.id === 'menderStatus'
            )}
            onChange={handleFilterChange}
            removeFilterInputFields={removeFilterInputFields}
          ></Filter>
        ) : (
          <Filter
            components={components}
            columns={props.columns.filter(
              (x) =>
                x.id === 'displayName' ||
                x.id === 'id' ||
                x.id === 'provisioned' ||
                x.id === 'menderStatus'
            )}
            onChange={handleFilterChange}
            removeFilterInputFields={removeFilterInputFields}
          ></Filter>
        )}

        <Button
          onClick={addFilterInputFields}
          startIcon={<AddIcon></AddIcon>}
          sx={{
            width: ({ spacing }) => spacing(16),
            ml: ({ spacing }) => spacing(-1),
          }}
        >
          Add Filter
        </Button>
        <Button
          variant="outlined"
          sx={{ ml: ({ spacing }) => spacing(5.1) }}
          onClick={handleResetFilter}
        >
          Reset
        </Button>
        <Button
          variant="contained"
          sx={{ ml: ({ spacing }) => spacing(1) }}
          onClick={handleApplyFilter}
        >
          Apply
        </Button>
      </Box>
    </Popover>
  );
};

export default DataTableFilter;
