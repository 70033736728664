import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ModalDialog from 'src/components/ModalDialog';
import { useStoreUnitActions } from 'src/redux/helpers';
import SolutionDeviceConfigCodeBlock from './SolutionDeviceConfigCodeBlock';
import solutionsStore, { useDeviceConfigFileData } from '../../redux';
import { SolutionDeviceConfigItem } from '../../types';

type Props = {
  item: SolutionDeviceConfigItem;
  open: boolean;
  onClose: () => void;
};

function SolutionDeviceConfigViewModal({ item, open, onClose }: Props) {
  const { displayName, modelId, modelType } = item;
  const { solutionId } = useParams();
  const actions = useStoreUnitActions(solutionsStore);
  const [data, loading] = useDeviceConfigFileData();

  useEffect(() => {
    actions.deviceConfigFile({ solutionId, modelId, modelType });
  }, [modelId]);

  return (
    <ModalDialog
      title={displayName}
      open={open}
      onClose={onClose}
      loading={loading}
      showCancelBtn={false}
      showConfirmBtn={false}
      maxWidth="md"
      fullWidth
    >
      <SolutionDeviceConfigCodeBlock data={data} />
    </ModalDialog>
  );
}

export default SolutionDeviceConfigViewModal;
