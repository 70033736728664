import { useLocation } from 'react-router-dom';

export const Docs = () => {
  const location = useLocation();
  const url = `${location.pathname.replace('/help-center', '')}`;
  return (
    <iframe
      title="Helpcenter"
      src={url}
      width={'100%'}
      height={'100%'}
      frameBorder="0"
    />
  );
};
