import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import { Variant } from '@mui/material/styles/createTypography';
import DataTableContext from 'src/components/DataTable/components/DataTableContext';
import DataTableSearchBar from 'src/components/DataTable/components/DataTableSearchBar';
import { SxProps } from 'src/theme/types';

type Props = BoxProps & {
  title?: string;
  actionsPanel?: ReactNode;
  onSearch?: (value: string) => void;
  searchValue?: string;
  url?: string;
  showUrl?: boolean;
  searchBar?: boolean;
  titleStyle?: any;
  infoTitle?: string;
  titleVariant?: Variant;
};

const style: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  mb: 1.5,
};

function DataTableWrapper({
  title,
  actionsPanel,
  children,
  onSearch,
  searchValue,
  url = '',
  searchBar = true,
  titleStyle,
  showUrl = false,
  infoTitle = '',
  titleVariant = 'h4',
  ...rest
}: Props) {
  const navigate = useNavigate();
  return (
    <DataTableContext searchValue={searchValue} onSearch={onSearch}>
      <Box position="relative" {...rest}>
        <Box sx={style}>
          <Typography
            variant={titleVariant}
            data-testid="data-table-wrapper-header"
            style={titleStyle}
          >
            {title}
          </Typography>
          <Box display="flex" alignItems="end" gap={2}>
            {showUrl && (
              <IconButton
                sx={{ mt: 0.8 }}
                title={infoTitle}
                size="small"
                onClick={() => navigate(url)}
              >
                <HelpOutlineOutlinedIcon fontSize="small" />
              </IconButton>
            )}
            {searchBar && <DataTableSearchBar />}
            {actionsPanel}
          </Box>
        </Box>
        {children}
      </Box>
    </DataTableContext>
  );
}

export default DataTableWrapper;
