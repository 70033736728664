import React from 'react';
import { useCurrentCompanyName } from '../../redux';

type Props = {
  title?: string;
};

function CompanyDetailsTitle({ title }: Props) {
  const companyName = useCurrentCompanyName();

  return <>{companyName || title}</>;
}

export default CompanyDetailsTitle;
