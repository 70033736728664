import React from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { DataTableActions } from 'src/components/DataTable';
import { useUserRoles } from 'src/auth';
import { useCompanyUserRoles } from '../../hooks';
import { CompanyItem } from '../../types';
import { COMPANY_DETAILS } from '../../constants';

type Props = {
  item?: CompanyItem;
};

function CompaniesListAction({ item }: Props) {
  const navigate = useNavigate();
  const { id: companyId } = item as CompanyItem;
  const { isCompanyEditor } = useCompanyUserRoles(companyId);
  const { isPlatformReader, isPlatformEngineer } = useUserRoles();

  const handleView = () =>
    navigate(generatePath(COMPANY_DETAILS, { companyId }));

  return (
    <DataTableActions
      canEdit={false}
      canDelete={false}
      canView={true}
      onView={handleView}
      showView={isCompanyEditor || isPlatformReader || isPlatformEngineer}
      showEdit={isCompanyEditor || isPlatformReader || isPlatformEngineer}
    />
  );
}

export default CompaniesListAction;
