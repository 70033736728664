import { generatePath, useNavigate, useParams } from 'react-router-dom';
import Link from '@mui/material/Link';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { useUserRoles } from 'src/auth';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { generateApiPath } from 'src/utils';
import { useStoreUnitActions } from 'src/redux/helpers';
import { SolutionEdgeWorkloadRepositoryType } from 'src/pages/Solutions/types/solutionEdgeWorkload';
import SolutionEdgeWorkloadEditModal from './SiteEdgeWorkloadEditModal';
import { getSiteWorkloadEditParams } from '../utils';
import sitesStore from '../../redux';
import { API_SITE_WORKLOAD_CONFIG_CREATE } from '../../constants';

type Props = {
  open: boolean;
  onClose: ModalCloseHandler;
  repositoryType: SolutionEdgeWorkloadRepositoryType;
};

function SolutionEdgeWorkloadCreateContainer({
  open,
  onClose,
  repositoryType,
}: Props) {
  const navigate = useNavigate();
  const { siteId } = useParams();

  const { showAlert } = useAlert();
  const { isPlatformOwner, isPlatformEngineer } = useUserRoles();
  const actions = useStoreUnitActions(sitesStore);
  const goto = () => {
    navigate(
      generatePath(`/sites/${siteId}`, {
        siteId,
      }),
      { replace: true }
    );
  };

  const [handleSubmit, loading] = useStatefulApi(
    (params) =>
      pepApiRequest({
        method: RequestMethod.Post,
        url: generateApiPath(API_SITE_WORKLOAD_CONFIG_CREATE, { siteId }),
        params: {
          WorkloadConfiguration: {
            ...getSiteWorkloadEditParams(params, siteId),
            gitFlag: repositoryType,
            deploymentPaused: true,
          },
        },
        skipErrorCodes: [412],
      }),
    (res, params) => {
      showAlert({
        type: AlertType.Success,
        title: `${params.name} is configured`,
        text: 'Git Repo has been configured successfully',
      });
      onClose(null, ModalCloseReason.completeAction);
      actions.edgeWorkloadConfigurations(siteId);
    },
    (err) => {
      if (typeof err === 'object') {
        if (err?.status === 412) {
          showAlert({
            type: AlertType.Error,
            title: 'Git configuration failed',
            text: (
              <>
                The container resouce limit is not set for one or more cluster
                selected.{' '}
                {(isPlatformOwner || isPlatformEngineer) && (
                  <>
                    <Link onClick={goto}>Click here</Link> to set.
                  </>
                )}
              </>
            ),
          });
        }
      }
      onClose(null, ModalCloseReason.completeAction);
    }
  );

  return (
    <SolutionEdgeWorkloadEditModal
      title="Configure Git Repository"
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      loading={loading}
      repositoryType={repositoryType}
    />
  );
}

export default SolutionEdgeWorkloadCreateContainer;
