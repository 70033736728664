import { createStoreUnit } from 'src/redux/helpers';
import details from './siteDetailsSlice';
import edgeWorkloadConfigurations from './siteEdgeWorkloadConfigSlice';
import list from './sitesListSlice';
import solutions from './siteSolutionsSlice';
import clusterDetails from './sitesClusterOptionsSlice';
import { SITES_STORE } from '../constants';

const sitesStore = createStoreUnit({
  name: SITES_STORE,
  slices: {
    list,
    details,
    edgeWorkloadConfigurations,
    solutions,
    clusterDetails,
  },
});

export default sitesStore;
