import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootMiddleware from './middleware';
import rootReducer from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  preloadedState: {},
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => [
    sagaMiddleware,
    ...rootMiddleware,
    ...getDefaultMiddleware({ thunk: false }),
  ],
});

sagaMiddleware.run(rootSaga);

export default store;
