import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { SxProps } from 'src/theme/types';

const getStyle = (color: string, active: boolean, open: boolean): SxProps => {
  const borderRadius = ({ spacing }) => spacing(2.2);
  const borderBottomRadius = open ? 0 : borderRadius;

  return {
    color,
    zIndex: ({ zIndex }) => zIndex.snackbar,
    width: ({ spacing }) => spacing(active ? 50 : 30),
    '& .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-formControl': {
      color,
      padding: 0.7,
      '&.Mui-focused': {
        color: 'black',
      },
      '&.Mui-disabled': {
        color: 'text.disabled',
        backgroundColor: 'transparent',
        '& fieldset.MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      },
    },
    '& .MuiAutocomplete-input': {
      zIndex: 1,
    },
    '& fieldset.MuiOutlinedInput-notchedOutline': {
      borderRadius,
      borderColor: 'white',
      height: ({ spacing }) => spacing(5),
    },
    '&:hover fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: 'brand.main',
    },
    '& .Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: 'brand.main',
      backgroundColor: 'white',
      borderBottomRightRadius: borderBottomRadius,
      borderBottomLeftRadius: borderBottomRadius,
      borderBottomWidth: ({ spacing }) => spacing(open ? 0 : 0.2),
    },
  };
};

type Props = TextFieldProps & {
  active: boolean;
  open: boolean;
  color: string;
};

function SearchBarInput({ InputProps, active, open, color, ...props }: Props) {
  return (
    <TextField
      {...props}
      InputProps={{
        ...InputProps,
        endAdornment: <SearchIcon sx={{ zIndex: 10 }} color="inherit" />,
      }}
      size="small"
      sx={getStyle(color, active, open)}
    />
  );
}

export default SearchBarInput;
