import React from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { SxProps } from 'src/theme/types';

type Props = {
  data: Record<string, any>;
};
const style: SxProps = {
  p: 2,
  minHeight: ({ spacing }) => spacing(40),
  maxHeight: '70vh',
  overflowY: 'auto',
};

function SolutionDeviceConfigCodeBlock({ data }: Props) {
  return (
    <Card sx={style}>
      <Typography variant="small2">
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </Typography>
    </Card>
  );
}

export default SolutionDeviceConfigCodeBlock;
