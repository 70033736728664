import React from 'react';
import { useParams } from 'react-router-dom';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { generateApiPath, loadDataFromStorage } from 'src/utils';
import { API_JOBS, API_JOBS_IOTH } from '../../constants';
import { SolutionJobItem, SolutionType } from '../../types';
import {
  composeSolutionJobsAlertText,
  GetSolutionJobItemRequestBody,
} from '../utils';
import SolutionJobEditStepper from './SolutionJobEditStepper';
import {
  useSolutionCommandOptions,
  useSolutionComponentsDetails,
  useSolutionDeviceGroupOnlyWritablePropOptions,
  useSolutionDeviceGroupProperties,
} from '../../redux';

type Props = {
  item?: SolutionJobItem;
  open: boolean;
  onClose: ModalCloseHandler;
};

function SolutionJobCreateContainer({ item, open, onClose }: Props) {
  const { showAlert } = useAlert();
  const { solutionId } = useParams();

  const [propertyOptions, propertiesLoading] =
    useSolutionDeviceGroupOnlyWritablePropOptions();
  const [propertiesData, propertiesGroupLoading] =
    useSolutionDeviceGroupProperties();
  const [commandData, Commandloading] = useSolutionComponentsDetails();
  const [commandOptions, commandLoading] = useSolutionCommandOptions();
  const [onSubmit, loading] = useStatefulApi(
    (item?: SolutionJobItem) => {
      const solutionType = loadDataFromStorage('solutionType');
      return pepApiRequest({
        method: RequestMethod.Post,
        url: generateApiPath(
          solutionType?.toUpperCase() === SolutionType.ioTH.toUpperCase()
            ? API_JOBS_IOTH
            : API_JOBS,
          { solutionId }
        ),
        params: GetSolutionJobItemRequestBody(
          solutionType,
          propertyOptions,
          propertiesData,
          commandData,
          commandOptions,
          item
        ),
      });
    },
    (res, item) => {
      showAlert({
        type: AlertType.Success,
        title: composeSolutionJobsAlertText(
          'Job created {run}',
          !item?.runLater
        ),
        text: composeSolutionJobsAlertText(
          `The job has been created {run} successfully.`,
          !item?.runLater
        ),
      });
      onClose(null, ModalCloseReason.completeAction);
    }
  );

  return (
    <SolutionJobEditStepper
      item={item}
      editStepTitle="Create new job"
      reviewStepTitle="New job - Review"
      loading={loading}
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
}

export default SolutionJobCreateContainer;
