import React from 'react';
import { useParams } from 'react-router-dom';
import { DataTableActions } from 'src/components/DataTable';
import { isModalComplete } from 'src/components/ModalDialog';
import { useModal } from 'src/hooks';
import { useStoreUnitActions } from 'src/redux/helpers';
import SolutionSubscriptionDeleteModal from './SolutionSubscriptionDeleteModal';
import SolutionSubscriptionEditContainer from './SolutionSubscriptionEditContainer';
import { useSolutionUserRoles } from '../../hooks';
import { SolutionSubscriptionItem } from '../../types';
import { solutionsStore } from '../../index';

type Props = {
  item?: SolutionSubscriptionItem;
};

function SolutionSubscriptionsActions({ item }: Props) {
  const { isSolutionEditor } = useSolutionUserRoles();
  const { solutionId } = useParams();
  const actions = useStoreUnitActions(solutionsStore);
  const onClose = (event, reason) =>
    isModalComplete(reason) && actions.subscriptions(solutionId);

  const [deleteDialog, showDeleteDialog] = useModal(
    SolutionSubscriptionDeleteModal,
    { item, onClose }
  );

  const [editDialog, showEditDialog] = useModal(
    SolutionSubscriptionEditContainer,
    {
      item,
      onClose,
    }
  );

  return (
    <DataTableActions
      canEdit={isSolutionEditor}
      canDelete={isSolutionEditor}
      onEdit={showEditDialog}
      onDelete={showDeleteDialog}
    >
      {deleteDialog}
      {editDialog}
    </DataTableActions>
  );
}

export default SolutionSubscriptionsActions;
