import { useParams } from 'react-router-dom';
import SpeedIcon from '@mui/icons-material/Speed';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import {
  DataTableActionsButton,
  DataTableActionsWrapper,
} from 'src/components/DataTable';
import { isModalComplete } from 'src/components/ModalDialog';
import { useModal } from 'src/hooks';
import { useStoreUnitActions } from 'src/redux/helpers';
import { useUserRoles } from 'src/auth';
import solutionsStore from '../../redux';
import { SolutionSiteItem } from '../../types';
import SolutionSiteDeleteModal from './SolutionSiteDeleteModal';
import SolutionNamespaceRscLimitViewModal from './SolutionNamespaceRscLimitViewModal';

type Props = {
  item?: SolutionSiteItem;
};

function SolutionSiteListAction({ item }: Props) {
  const actions = useStoreUnitActions(solutionsStore);
  const { solutionId } = useParams();
  const { isPlatformReader } = useUserRoles();
  const onClose = (event, reason) => {
    isModalComplete(reason);
    actions.sites(solutionId);
  };

  const [DeleteDialog, handleDelete] = useModal(SolutionSiteDeleteModal, {
    id: item?.id,
    name: item?.name,
    onClose,
  });
  const [NamespaceRscLimitDialog, showNamespaceRscLimitDialog] = useModal(
    SolutionNamespaceRscLimitViewModal,
    {
      siteId: item?.id,
      siteName: item?.name,
      clusterIds: item?.clusters?.map((cluster) => cluster.id),
      onClose,
    }
  );

  return (
    <DataTableActionsWrapper>
      <DataTableActionsButton
        data-testid="solution-site-namespace-resource-limit"
        icon={SpeedIcon}
        title="Quota Limits"
        onClick={showNamespaceRscLimitDialog}
      />
      <DataTableActionsButton
        data-testid="solution-sites-item-delete-action"
        icon={DeleteOutlinedIcon}
        title="Delete"
        onClick={handleDelete}
        disabled={isPlatformReader}
      />
      {NamespaceRscLimitDialog}
      {DeleteDialog}
    </DataTableActionsWrapper>
  );
}

export default SolutionSiteListAction;
