import { ReactElement, ReactNode, useEffect } from 'react';
import { useDatasetUnitAction } from 'src/redux/helpers';
import { useUserAuthProfile } from '../selectors';
import authStore from '../authStore';

type Props = {
  children: ReactNode;
};

function AuthIdentityProvider({ children }: Props) {
  const { email } = useUserAuthProfile();
  const getIdentity = useDatasetUnitAction(authStore);

  useEffect(() => {
    if (email) {
      getIdentity(email);
    }
  }, [email]);

  return children as ReactElement;
}

export default AuthIdentityProvider;
