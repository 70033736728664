import React from 'react';
import Badge from 'src/components/Badge';
import {
  SolutionEdgeWorkloadItem,
  SolutionEdgeWorkloadStatus as Status,
} from '../../types/solutionEdgeWorkload';

type StatusMapItem = {
  color?: 'success' | 'info' | 'error' | 'default' | 'warning';
  label: string;
};

type Props = {
  value?: string;
  item?: SolutionEdgeWorkloadItem;
};

function SolutionGitRepoStatus({ value = '', item }: Props) {
  const statusData = JSON.parse(value);
  const statusValue: Status = statusData.status.toLowerCase() ?? '';
  const errorMsg: string = statusData.errorMsg ?? '';
  const validErrorMsg = errorMsg === null || errorMsg === '';
  const statusColor = () => {
    if (
      !validErrorMsg &&
      statusValue !== Status.notReady &&
      statusValue !== Status.active
    ) {
      return 'error';
    }
    if (validErrorMsg && statusValue === Status.paused) {
      return 'info';
    }
    if (statusValue === Status.active) {
      return 'success';
    }
    if (statusValue === Status.notReady) {
      return 'warning';
    }
    return 'default';
  };

  const statusMap: Record<Status, StatusMapItem> = {
    [Status.paused]: { color: statusColor(), label: 'Paused' },
    [Status.active]: { color: statusColor(), label: 'Active' },
    [Status.errApplied]: { color: statusColor(), label: 'Err Applied' },
    [Status.failed]: { color: statusColor(), label: 'Failed' },
    [Status.gitUpdating]: { color: statusColor(), label: 'Git Updating' },
    [Status.notReady]: { color: statusColor(), label: 'Not Ready' },
    [Status.modified]: { color: statusColor(), label: 'Modified' },
    [Status.waitapplied]: { color: statusColor(), label: 'Wait Applied' },
    [Status.outofsync]: { color: statusColor(), label: 'Out of sync' },
  };

  const defaultStatus = { color: statusColor(), label: statusData.status };
  return (
    <>
      <Badge {...(statusMap[statusValue] ?? defaultStatus)} />
    </>
  );
}

export default SolutionGitRepoStatus;
