import React from 'react';
import Paper from '@mui/material/Paper';
import { SxProps } from 'src/theme/types';

const style: SxProps = {
  top: -1,
  borderColor: 'brand.main',
  borderStyle: 'solid',
  borderWidth: '2px',
  borderTop: 0,
  borderRadius: 0,
  borderBottomLeftRadius: ({ spacing }) => spacing(2.2),
  borderBottomRightRadius: ({ spacing }) => spacing(2.2),
  '& .MuiAutocomplete-listbox .MuiAutocomplete-option': {
    typography: 'body2',
    py: 1,
    '&.Mui-focused': {
      backgroundColor: 'primary.main',
      color: 'white',
    },
  },
};

function SearchBarOptionsList(props: any) {
  return <Paper {...props} sx={style} />;
}

export default SearchBarOptionsList;
