import React, { useEffect } from 'react';
import DataTable from 'src/components/DataTable';
import DateFormattedValue from 'src/components/DateFormattedValue';
import { PageCardSection } from 'src/components/PageCardLayout';
import { useStoreUnitActions } from 'src/redux/helpers';
import SolutionDeviceDeploymentLogStatus from '../../components/SolutionDeviceDeploymentLogStatus';
import solutionsStore, { useSolutionDeviceDeploymentLog } from '../../redux';
import { SolutionDeviceDeploymentLogField } from '../../types';

type Props = {
  deviceId?: string;
};
const tableStyle = {
  mt: 2,
  minHeight: '12vh',
};

const columns = [
  {
    id: SolutionDeviceDeploymentLogField.Started,
    title: 'Started',
    Component: DateFormattedValue,
  },
  {
    id: SolutionDeviceDeploymentLogField.Finished,
    title: 'Finished',
    Component: DateFormattedValue,
  },
  {
    id: SolutionDeviceDeploymentLogField.TargetRelease,
    title: 'Target release',
  },
  {
    id: SolutionDeviceDeploymentLogField.DeploymentStatus,
    title: 'Status',
    Component: SolutionDeviceDeploymentLogStatus,
  },
];

function SolutionDeviceDeploymentLog({ deviceId }: Props) {
  const [data, loading] = useSolutionDeviceDeploymentLog();
  const actions = useStoreUnitActions(solutionsStore);

  useEffect(() => {
    actions.deviceDeploymentLog({ deviceId });
  }, [deviceId]);

  return (
    <PageCardSection title="Deployment log for device">
      <DataTable
        columns={columns}
        data={data}
        loading={loading}
        sx={tableStyle}
      />
    </PageCardSection>
  );
}

export default SolutionDeviceDeploymentLog;
