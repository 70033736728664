import Add from '@mui/icons-material/Add';
import { useUserRoles } from 'src/auth';
import DataTable, {
  DataTableWrapper,
  DataTableWrapperActionButton,
} from 'src/components/DataTable';

const columns = [
  {
    id: 'Email',
    title: 'Email',
  },
  {
    id: 'Role',
    title: 'Role',
  },
];

export default function UserListTab() {
  const data = [];
  const { isPlatformReader, isPlatformEngineer } = useUserRoles();

  return (
    <DataTableWrapper
      title="Users"
      actionsPanel={
        <DataTableWrapperActionButton
          startIcon={<Add />}
          disabled={isPlatformReader || isPlatformEngineer}
        >
          Assign user
        </DataTableWrapperActionButton>
      }
    >
      <DataTable data={data} columns={columns} />
    </DataTableWrapper>
  );
}
