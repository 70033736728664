import { ChangeEvent } from "react";
import * as Yup from 'yup';
import { TextField } from '@mui/material';
import { SiteItemField } from "../types";

export const SiteEdgeServiceFormFields = (
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void
) => [
  {
    name: SiteItemField.EdgeServiceUrl,
    label: 'URL',
    placeholder: 'Enter the URL',
    Component: TextField,
    multiline: true,
    rows: 2,
    validation: Yup.string()
      .required('URL is required')
      .url('URL format is incorrect')
      .test('Is valid url', 'URL format is incorrect', (value) =>
        value ? /^(http|https):\/\/.*\.com$/.test(value) : false
      ),
    required: true,
  },
];