import { createDatasetUnit } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import { API_OTP_KEYS, OTP_STORE, OTP_STORE_LIST } from '../constants';

const api = (queryParams) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: API_OTP_KEYS,
    queryParams,
  }).then(({ otpKeys }) => ({ data: otpKeys }));

const otpStore = createDatasetUnit({
  api,
  storeName: OTP_STORE,
  actionName: OTP_STORE_LIST,
});

export default otpStore;
