import { useEffect } from 'react';
import Add from '@mui/icons-material/Add';
import { useModal } from 'src/hooks';
import DataTable, {
  DataTableWrapper,
  DataTableWrapperActionButton,
  getActionsColConfig,
  useTableQueryParams,
} from 'src/components/DataTable';
import { useStoreUnitActions } from 'src/redux/helpers';
import { isModalComplete } from 'src/components/ModalDialog';
import { useUserRoles } from 'src/auth';
import { ApplicationItemField } from '../types';
import usersStore, { useApplicationList } from '../redux';
import NewApplicationModal from './components/NewApplicationModal';
import ApplicationListAction from './components/ApplicationListAction';

const columns = [
  {
    id: ApplicationItemField.Name,
    title: 'Name',
  },
  {
    id: ApplicationItemField.Id,
    title: 'ClientID',
  },
  {
    id: ApplicationItemField.Roles,
    title: 'Assigned Role',
  },
  getActionsColConfig(ApplicationListAction),
];

export default function ApplicationList() {
  const [data, loading] = useApplicationList();
  const actions = useStoreUnitActions(usersStore);
  const { paging } = useTableQueryParams();
  const { isPlatformReader } = useUserRoles();
  useEffect(() => {
    actions.applicationList();
  }, []);
  const onCloseSuspend = (event, reason) => {
    isModalComplete(reason);
  };
  const [AssignNewUserDialog, showAssignNewUserDialog] = useModal(
    NewApplicationModal,
    {
      onCloseSuspend,
    }
  );

  const applicationData = data?.map((item) => {
    if (item.roles && item.roles.length && item.roles.length > 0) {
      return {
        ...item,
        roles: [item.roles.toString()],
        AsignRoles: item.roles,
      };
    }
    return item;
  });

  return (
    <DataTableWrapper
      title="Applications"
      actionsPanel={
        <DataTableWrapperActionButton
          onClick={showAssignNewUserDialog}
          startIcon={<Add />}
          disabled={isPlatformReader}
        >
          New Application
        </DataTableWrapperActionButton>
      }
    >
      <DataTable
        data={applicationData}
        loading={loading}
        columns={columns}
        {...paging}
      />
      {AssignNewUserDialog}
    </DataTableWrapper>
  );
}
