import { SolutionDeviceGroupPropertyItem } from '../types';

/**
 * Action interface
 */
interface Action {
  Name: string;
  Context: {
    Expression?: string;
    WorkflowName?: string;
    ruleName?: string;
  };
}

/**
 * Rule Payload interface
 */
interface Rule {
  name: string;
  expression: string;
  successEvent: string;
  errorMessage: string;
  solutionId: string;
  templateId: string;
  enabled: boolean;
  conditionQuery: Querys[];
  targetDeviceQuery: Querys[];
  errorType: number;
  actionType: string[];
  targetURL: string;
  conditionQueryOperator: CompositeClause | string;
  timeAggregation: boolean;
  timeWindow?: number;
  timeAggregationValueInMinutes: number;
  conditions: Condition[];
  filters: Filter[];
  telemetry: string;
  SasToken: string;
}
export interface RuleRequest {
  name: string;
  expression: string;
  successEvent: string;
  errorMessage: string;
  solutionId: string;
  templateId: string;
  enabled: boolean;
  conditionQuery: Querys[];
  targetDeviceQuery: Querys[];
  errorType?: number;
  actionType: string[];
  targetUrl: string;
  conditionQueryOperator: CompositeClause | string;
  timeAggregation: boolean;
  timeWindow?: number;
  timeAggregationValueInMinutes: number;
  telemetry: string;
  SasToken: string;
}
export interface Querys {
  property: SolutionDeviceGroupPropertyItem;
  operatorValue: Operators | undefined;
  filterValue: string | undefined;
  aggregationValue?: Aggregations | undefined;
  IsValue?: boolean;
}

/**
 * Workflow interface
 */
interface Workflow {
  Rules: Rule[];
}

interface DeviceTemplate {
  name: string;
}

interface Condition {
  Telemetry: string;
  Operator?: Operators;
  Value: string;
  Aggregation?: Aggregations | any;
  ValueType?: string;
  dropDownValue?: any;
  ValueLabel: string | undefined;
  IsValue?: boolean;
}

interface Filter {
  Property: string;
  Condition?: Operators;
  Value: string;
  ValueLabel: string | undefined;
}

interface Telemetry {
  name: string;
  schema?: SchemaTypes;
  path?: string;
}

export enum Operators {
  'equals' = 'Equals',
  'notEquals' = 'Does not equal',
  'contains' = 'Contains',
  'notContains' = 'Does not contains',
  'greater' = 'Is greater than',
  'equalOrGreater' = 'Is greater than or equal to',
  'less' = 'Is less than',
  'equalOrLess' = 'Is less than or equal to',
  'in' = 'Is in',
  'notIn' = 'Is not in',
  'includes' = 'Includes',
  '!includes' = 'Does not include',
}

export enum CompositeClause {
  OR = 'Any of the condition is valid',
  AND = 'All the conditions are valid',
}

export enum Aggregations {
  Average = 'avg',
  Count = 'count',
  Max = 'max',
  Min = 'min',
  Sum = 'sum',
}

export const WindowSize = [
  {
    label: '5 minutes',
    value: 5,
  },
  {
    label: '10 minutes',
    value: 10,
  },
  {
    label: '15 minutes',
    value: 15,
  },
  {
    label: '20 minutes',
    value: 20,
  },
  {
    label: '30 minutes',
    value: 30,
  },
  {
    label: '1 hour',
    value: 60,
  },
];

export enum SchemaTypes {
  Array = 'Array',
  Boolean = 'boolean',
  Date = 'date',
  DateTime = 'dateTime',
  Double = 'double',
  Duration = 'duration',
  Enum = 'Enum',
  Float = 'float',
  Geopoint = 'Schemas/Geopoint/geopoint',
  Integer = 'integer',
  Long = 'long',
  Map = 'Map',
  Object = 'Object',
  String = 'string',
  Time = 'time',
}
export type {
  Action,
  Rule,
  Workflow,
  DeviceTemplate,
  Filter,
  Condition,
  Telemetry,
};
