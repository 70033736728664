import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import HeaderLangMenu from './HeaderLangMenu';
import HeaderUserMenu from './HeaderUserMenu';
import HeaderContext from '../HeaderContext';

const style = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

function HeaderMenuGroup() {
  const { color } = useContext(HeaderContext);

  return (
    <Box sx={style}>
      <IconButton sx={{ color }} disabled>
        <NotificationsNoneOutlinedIcon />
      </IconButton>
      <HeaderUserMenu />
      <HeaderLangMenu />
    </Box>
  );
}

export default HeaderMenuGroup;
