import React from 'react';
import { Box } from '@mui/system';
import { SolutionUserRole } from '../../types';

type Props = {
  value?: SolutionUserRole;
};

function SolutionUsersRole({ value }: Props) {
  const rolesMap = {
    [SolutionUserRole.Owner]: 'Solution owner',
    [SolutionUserRole.User]: 'Solution user',
  };

  return (
    <Box sx={{ minWidth: '120px' }}>{rolesMap[value as SolutionUserRole]}</Box>
  );
}

export default SolutionUsersRole;
