import { createDatasetSlice } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import { API_SITES_ALL, SITES_STORE, SITES_STORE_LIST } from '../constants';
import { mapSitesListData } from './utils';

const api = () =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: API_SITES_ALL,
  }).then(mapSitesListData);

const sitesListSlice = createDatasetSlice({
  api,
  storeName: SITES_STORE,
  actionName: SITES_STORE_LIST,
});

export default sitesListSlice;
