import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputLabel,
  FormControl,
  Alert,
  Grid,
  Typography,
  TextField,
  IconButton,
  Button,
  MenuItem,
  Stack,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { blue } from '@mui/material/colors';
import { useParams } from 'react-router-dom';
import { useStoreUnitActions } from 'src/redux/helpers';
import { Filter, Operators, Rule } from './interfaces';

import solutionsStore, {
  useSolutionDeviceGroupPropOptions,
  useSolutionDeviceGroupProperties,
  useSolutionDevicePropertyMeta,
  useSolutionDeviceTemplateOptions,
} from '../redux';
import { SolutionDeviceSpecificRulesManagement } from '../types/solutionDevices';
import { camelizeKeys } from './utils';
import SolutionFeatureInformation from '../components/SolutionFeatureInfomation';

interface TargetDevicesCardProps {
  item: SolutionDeviceSpecificRulesManagement;
  rule: Rule;
  isEditRuleModal: boolean;
  error: string | null;
  onChangeTemplate: (newValue: string) => void;
  filters: Filter[];
  updateFilters: (filters: Filter[]) => void;
}
const TargetDevicesCard: React.FC<TargetDevicesCardProps> = ({
  item,
  rule,
  isEditRuleModal,
  error,
  onChangeTemplate,
  filters,
  updateFilters,
}) => {
  const { solutionId } = useParams();
  const actions = useStoreUnitActions(solutionsStore);
  const [property, setProperty] = useState<string>();
  const [expanded, setExpanded] = useState(true);

  const [properties, propertiesDataLoading] =
    useSolutionDeviceGroupProperties();
  const [propertyOptions, propertiesLoading] =
    useSolutionDeviceGroupPropOptions();
  const targetDev = camelizeKeys(item?.TargetDeviceInfo);
  const propertyValue = targetDev?.map((prop) => prop.property.id);
  const retPropertyValue = (arr) => {
    let val = '';
    for (let i = 0; i < arr.length; i += 1) {
      val = arr[i];
    }
    if (val) {
      return val;
    }
    return property;
  };

  useEffect(() => {
    if (isEditRuleModal) {
      setProperty(retPropertyValue(propertyValue));
    }
  }, []);

  const {
    operators: operatorOptions,
    enums: filterValueOptions,
    propertyId,
  } = useSolutionDevicePropertyMeta(property);
  const [templateOptions, templateLoading] = useSolutionDeviceTemplateOptions();

  const [template, setTemplate] = useState<string | undefined>(
    item?.TemplateId ?? ''
  );
  const [addFilters, setAddFilters] = useState<boolean>(false);
  useEffect(() => {
    actions.companies(solutionId);
  }, [solutionId]);

  useEffect(() => {
    if (template) {
      actions.deviceGroupProperties({ solutionId, modelId: template });
      actions.ruleTelemtryProperties({ solutionId, modelId: template });
    }
  }, [template]);
  const handleAddRowFilter = () => {
    setAddFilters(true);
    return updateFilters([
      ...filters,
      { Property: '', Condition: undefined, Value: '', ValueLabel: '' },
    ]);
  };
  const handleDeleteRowFilter = (index: number) => {
    const newFilters = [...filters];
    newFilters.splice(index, 1);
    updateFilters(newFilters);
  };
  const handleUpdateRowFilter =
    (index: number) => (event: SelectChangeEvent<string>) => {
      const selectedProperty = event.target.value;
      setProperty(event.target.value);
      updateFilters(
        filters.map(
          (filter, i): Filter =>
            i === index && selectedProperty
              ? { ...filter, Property: selectedProperty }
              : filter
        )
      );
    };
  const handleUpdateFilterCondition =
    (index: number) => (event: SelectChangeEvent<string>) => {
      updateFilters(
        filters.map(
          (filter, i): Filter =>
            i === index
              ? { ...filter, Condition: event.target.value as Operators }
              : filter
        )
      );
    };
  const handleUpdateFilterValue =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      updateFilters(
        filters.map(
          (filter, i): Filter =>
            i === index ? { ...filter, Value: event.target.value } : filter
        )
      );
    };
  const handleUpdateFilterDropDownValue = (index: number) => (event: any) => {
    updateFilters(
      filters.map(
        (filter, i): Filter =>
          i === index
            ? {
                ...filter,
                Value: event.target.value,
                ValueLabel: filterValueOptions
                  ? filterValueOptions.find(
                      (a) => a.value === event.target.value
                    )?.label
                  : '',
              }
            : filter
      )
    );
  };
  const handleTemplateChange = (event: SelectChangeEvent<string>) => {
    onChangeTemplate(event.target.value);
    setTemplate(event.target.value);
  };

  const handleChangeAccordion = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Accordion expanded={expanded} onChange={handleChangeAccordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h4" fontWeight="500">
            Target devices
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingTop: '0px' }}>
          <Stack spacing={1}>
            <Grid item>
              <FormControl required sx={{ minWidth: 500 }}>
                <InputLabel
                  error={
                    error !== null &&
                    (template === undefined || template === '')
                  }
                >
                  Template
                </InputLabel>
                <Select
                  error={
                    error !== null &&
                    (template === undefined || template === '')
                  }
                  placeholder="Select the Device Template"
                  sx={{ width: ({ spacing }) => spacing(60) }}
                  value={template}
                  label="Template *"
                  onChange={handleTemplateChange}
                  required
                >
                  {templateOptions?.map((template) => (
                    <MenuItem key={template.label} value={template.value}>
                      {template.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <SolutionFeatureInformation featureInfo="Select the device template your rule will use. If you need to narrow the rule's scope, add filters." />
            </Grid>
            <Grid item>
              <Typography
                sx={{ fontWeight: '500', fontSize: '16px' }}
                gutterBottom
                variant="h4"
                component="div"
              >
                Filter
              </Typography>
            </Grid>
            <Grid item container direction="column" gap={1}>
              {filters?.map((filter, index) => (
                <>
                  <Grid item container spacing={2} key={index}>
                    <Grid item>
                      <FormControl required sx={{ minWidth: 321 }}>
                        <InputLabel
                          error={
                            addFilters &&
                            error !== null &&
                            (filter.Property === '' ||
                              filter.Property === undefined ||
                              filter.Property === null)
                          }
                        >
                          Property
                        </InputLabel>
                        <Select
                          error={
                            addFilters &&
                            error !== null &&
                            (filter.Property === '' ||
                              filter.Property === undefined ||
                              filter.Property === null)
                          }
                          value={filter.Property}
                          label="Property *"
                          onChange={handleUpdateRowFilter(index)}
                        >
                          {propertyOptions.map((property) => (
                            <MenuItem
                              key={property.label}
                              value={property.value}
                            >
                              {property.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <FormControl required sx={{ minWidth: 321 }}>
                        <InputLabel
                          error={
                            addFilters &&
                            error !== null &&
                            (filter.Condition?.length === 0 ||
                              filter.Condition === undefined ||
                              filter.Condition === null)
                          }
                          placeholder="Select the device property"
                        >
                          Operator
                        </InputLabel>
                        <Select
                          error={
                            addFilters &&
                            error !== null &&
                            (filter.Condition?.length === 0 ||
                              filter.Condition === undefined ||
                              filter.Condition === null)
                          }
                          placeholder="Select the device property operator"
                          value={filter.Condition}
                          label="Operator *"
                          onChange={handleUpdateFilterCondition(index)}
                        >
                          {operatorOptions.map((value) => (
                            <MenuItem key={value.label} value={value.value}>
                              {value.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <FormControl required sx={{ minWidth: 321 }}>
                        {properties.find((a) => a.id === filter.Property)
                          ?.schema === 'Enum' &&
                        filters.find((a) => a.Property === filter.Property)
                          ?.Value !== null ? (
                          <>
                            <InputLabel
                              error={
                                addFilters &&
                                error !== null &&
                                (filter.Value?.length === 0 ||
                                  filter.Value === '' ||
                                  filter.Value === undefined ||
                                  filter.Value === null)
                              }
                              placeholder="Select the value"
                            >
                              Value
                            </InputLabel>
                            <Select
                              error={
                                addFilters &&
                                error !== null &&
                                (filter.Value === '' ||
                                  filter.Value === undefined ||
                                  filter.Value === null)
                              }
                              id={filter.ValueLabel}
                              placeholder="Select the value"
                              value={filter.Value}
                              label="Value *"
                              onChange={handleUpdateFilterDropDownValue(index)}
                            >
                              {properties
                                .find((a) => a.id === filter?.Property)
                                ?.enums?.map((value) => (
                                  <MenuItem
                                    key={
                                      isEditRuleModal
                                        ? filter.Value
                                        : value.label
                                    }
                                    value={value.value}
                                  >
                                    {value.label}
                                  </MenuItem>
                                ))}
                            </Select>
                          </>
                        ) : (
                          <TextField
                            error={
                              addFilters &&
                              error !== null &&
                              (filter.Value === '' ||
                                filter.Value === undefined ||
                                filter.Value === null)
                            }
                            required
                            placeholder="Select or enter the value"
                            label="Value"
                            id={'textBox'}
                            name={'textBox'}
                            variant="outlined"
                            onChange={handleUpdateFilterValue(index)}
                            value={filter.Value}
                          />
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <IconButton
                        component="button"
                        aria-label="delete"
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                          handleDeleteRowFilter(index)
                        }
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </>
              ))}
            </Grid>
            <Grid item sx={{ paddingTop: '0px' }}>
              <Button
                sx={{
                  marginLeft: 1,
                  paddingBlockStart: 1,
                  paddingTop: '0px',
                  fontSize: 16,
                  fontWeight: 500,
                }}
                disabled={propertyOptions.length === 0}
                onClick={handleAddRowFilter}
                size="medium"
              >
                + Add
              </Button>
            </Grid>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default TargetDevicesCard;
