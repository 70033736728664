import { useEffect, useState } from 'react';
import { AlertTitle, Box, Typography } from '@mui/material';
import Alert from 'src/components/Alert';
import ModalDialog, { ModalCloseHandler } from 'src/components/ModalDialog';
import { useStoreUnitActions } from 'src/redux/helpers';
import { useForm } from 'src/components/Form';
import {
  API_ERROR_EVENT,
  ErrorResponseBody,
  RequestMethod,
  pepApiRequest,
} from 'src/services';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import { PageCardLoader } from 'src/components/PageCardLayout';
import { generateApiPath } from 'src/utils';
import isArray from 'lodash/isArray';
import solutionsStore, {
  useSolutionDetails,
  useSolutionNamespaceRscLimit,
  useSolutionNamespaceRscQuotasDetail,
} from '../../redux';
import { SolutionNamespaceRscLimitItem } from '../../types';
import { API_SOLUTION_SITES_CLUSTERS_RES_SETTING } from '../../constants';
import { getSolutionClusterNamespaceRscLimitFields } from '../formFields';
import {
  getSetClusterNamespaceRscLimitParams,
  numericInputValidation,
} from '../utils';

type Props = {
  siteId: string;
  open: boolean;
  onClose: ModalCloseHandler;
  data: SolutionNamespaceRscLimitItem | undefined;
};
const cellStyle = {
  border: 0,
  height: 'auto',
  padding: '0px 10px 40px 5px',
  wordWrap: 'break-word',
};

const containerStyle = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  minHeight: '40rem',
  justifyContent: 'flex-start',
};

function SolutionNamespaceRscLimitEditModal({
  siteId,
  open,
  onClose,
  data,
}: Props) {
  const { showAlert } = useAlert();
  const actions = useStoreUnitActions(solutionsStore);
  const [clusterQuotadata, loading] = useSolutionNamespaceRscLimit();
  const [solutionData, solutionLoading] = useSolutionDetails();
  const [namespaceQuotaData, namespaceQuotaloading] =
    useSolutionNamespaceRscQuotasDetail();
  const namespaceName = solutionData?.namespace;
  const clusterRscQuotaDetail = clusterQuotadata?.find(
    (cluster) => cluster.clusterId === namespaceQuotaData?.clusterId
  );
  const newNamespaceQuotaData = {
    ...namespaceQuotaData,
    requestsCpu: clusterRscQuotaDetail?.requestsCpu
      ? clusterRscQuotaDetail?.requestsCpu.replace(/\D/g, '')
      : '',
    requestsMemory: clusterRscQuotaDetail?.requestsMemory
      ? clusterRscQuotaDetail?.requestsMemory.replace(/\D/g, '')
      : '',
    limitsCpu: clusterRscQuotaDetail?.limitsCpu
      ? clusterRscQuotaDetail?.limitsCpu.replace(/\D/g, '')
      : '',
    limitsMemory: clusterRscQuotaDetail?.limitsMemory
      ? clusterRscQuotaDetail?.limitsMemory.replace(/\D/g, '')
      : '',
    requestsStorage: clusterRscQuotaDetail?.requestsStorage
      ? clusterRscQuotaDetail?.requestsStorage.replace(/\D/g, '')
      : '',
    usedRequestsCpu: namespaceQuotaData?.usedRequestsCpu
      ? namespaceQuotaData?.usedRequestsCpu.replace('m', 'mc')
      : '',
    usedRequestsMemory: namespaceQuotaData?.usedRequestsMemory
      ? namespaceQuotaData?.usedRequestsMemory
          .replace('mi', 'MiB')
          .replace('Gi', 'GiB')
      : '',
    usedLimitsCpu: namespaceQuotaData?.usedLimitsCpu
      ? namespaceQuotaData?.usedLimitsCpu.replace('m', 'mc')
      : '',
    usedLimitsMemory: namespaceQuotaData?.usedLimitsMemory
      ? namespaceQuotaData?.usedLimitsMemory
          .replace('mi', 'MiB')
          .replace('Gi', 'GiB')
      : '',
    usedRequestsStorage: namespaceQuotaData?.usedRequestsStorage
      ? namespaceQuotaData?.usedRequestsStorage
          .replace('mi', 'MiB')
          .replace('Gi', 'GiB')
      : '',
  };
  const [error, setError] = useState<{ title: string; message: string } | null>(
    null
  );
  const [showError, setShowError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    actions.clusterRscLimit({
      siteId,
      namespaceName,
      clusterIds: { clusterIds: [data?.clusterId] },
    });
  }, [data?.clusterId]);

  useEffect(() => {
    actions.namespaceRscQuotasDetail({
      solutionId: solutionData?.id,
      clusterId: data?.clusterId,
    });
  }, [data?.clusterId, loading, solutionData?.id]);

  useEffect(() => {
    const handleApiError = ({ detail }: CustomEvent<ErrorResponseBody>) => {
      const { errors } = detail;
      if (isArray(errors)) {
        errors.forEach(({ title, detail: message }) =>
          setError({
            title,
            message,
          })
        );
      }
    };
    document.addEventListener(API_ERROR_EVENT, handleApiError as EventListener);
    return () => {
      document.removeEventListener(
        API_ERROR_EVENT,
        handleApiError as EventListener
      );
    };
  }, [showError]);

  const onSubmit = (params) => {
    setSubmitting(true);
    pepApiRequest({
      method: RequestMethod.Put,
      url: generateApiPath(API_SOLUTION_SITES_CLUSTERS_RES_SETTING, {
        siteId,
        namespaceName,
      }),
      skipErrorAlert: true,
      skipCustomAlert: true,
      params: getSetClusterNamespaceRscLimitParams(
        params,
        data?.clusterId,
        data?.clusterName
      ),
    })
      .then((response) => {
        if (response) {
          setSubmitting(false);
          setShowError(false);
          setError(null);
          showAlert({
            type: AlertType.Info,
            title: 'Quota limits saved',
            text: `Quota limits modified for the ${data?.clusterName} successfully.`,
          });
          onClose();
          actions.clusterRscLimit({
            siteId,
            namespaceName,
            clusterIds: data?.payload,
          });
        }
      })
      .catch((error: any) => {
        setSubmitting(false);
        setShowError(true);
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const nameSpaceLimitValue = numericInputValidation(value);
    setValue(name, nameSpaceLimitValue);
  };

  const [form, handleSubmit, setValue] = useForm({
    values: data,
    fields: getSolutionClusterNamespaceRscLimitFields(
      handleChange,
      newNamespaceQuotaData
    ),
    sx: containerStyle,
    onSubmit,
  });

  const handleClose = () => {
    onClose();
    actions.clusterRscLimit({
      siteId,
      namespaceName,
      clusterIds: data?.payload,
    });
    setError(null);
  };

  return (
    <ModalDialog
      open={open}
      title={`Edit Quota Limits : ${data?.clusterName}`}
      showCancelBtn={true}
      cancelBtnText={'Back'}
      confirmBtnText={'Save'}
      loading={solutionLoading || namespaceQuotaloading || loading}
      onConfirm={handleSubmit}
      onClose={handleClose}
      maxWidth="xl"
      fullWidth
      titleStyle={{ fontWeight: '500' }}
    >
      <Box>
        {error && (
          <Alert
            onClose={handleClose}
            color="error"
            sx={{
              minHeight: '0px',
              '.MuiAlert-icon': { paddingTop: '15px' },
              '.MuiAlert-action': { paddingTop: '12px' },
            }}
          >
            {error.title && (
              <AlertTitle sx={{ fontFamily: 'Roboto', fontSize: '18px' }}>
                {error.title}
              </AlertTitle>
            )}
            <Typography sx={{ fontFamily: 'Roboto', fontSize: '16px' }}>
              {error.message}
            </Typography>
          </Alert>
        )}
      </Box>
      {submitting && <PageCardLoader />}
      {form}
    </ModalDialog>
  );
}

export default SolutionNamespaceRscLimitEditModal;
