import React, { useEffect } from 'react';
import Tab from '@mui/material/Tab';
import ModalDialog from 'src/components/ModalDialog';
import DataTable, {
  DataTableWrapper,
  getActionsColConfig,
} from 'src/components/DataTable';
import TimeFormattedValue from 'src/components/TimeFormattedValue';
import Tabs from 'src/components/Tabs';
import { useStoreUnitActions } from 'src/redux/helpers';
import { loadDataFromStorage } from 'src/utils';
import solutionsStore, {
  useSolutionWorkloadResources,
  useSolutionWorkloadConditions,
} from '../../redux';
import {
  SolutionWorkloadStatusResourcesField,
  DeploymentStatus,
  SolutionEdgeWorkloadItem,
  SolutionWorkloadStatusConditionsField,
} from '../../types';
import SolutionWorkloadStatus from './SolutionWorkloadStatus';
import SolutionWorkloadStatusAction from './SolutionWorkloadStatusActions';
import SolutionWorkloadMessage from './SolutionWorkloadMessage';

type Props = {
  item: SolutionEdgeWorkloadItem;
  open: boolean;
  onClose: () => void;
};

const tableStyle = {
  minHeight: 0,
};

const resourcesColumns = [
  {
    id: SolutionWorkloadStatusResourcesField.Name,
    title: 'Name',
  },
  {
    id: SolutionWorkloadStatusResourcesField.Namespace,
    title: 'Namespace',
  },
  {
    id: SolutionWorkloadStatusResourcesField.Kind,
    title: 'Kind',
  },
  {
    id: SolutionWorkloadStatusResourcesField.ApiVersion,
    title: 'ApiVersion',
  },
  {
    id: SolutionWorkloadStatusResourcesField.Id,
    title: 'Cluster',
  },
  {
    id: SolutionWorkloadStatusResourcesField.State,
    title: 'State',
    Component: SolutionWorkloadStatus,
  },
  getActionsColConfig(SolutionWorkloadStatusAction),
];

const conditionsColumns = [
  {
    id: SolutionWorkloadStatusConditionsField.Type,
    title: 'Conditions',
    sx: { width: '1px', padding: 1 },
  },
  {
    id: SolutionWorkloadStatusConditionsField.Status,
    title: 'Status',
    Component: SolutionWorkloadStatus,
    sx: { paddingLeft: 0 },
  },
  {
    id: SolutionWorkloadStatusConditionsField.LastUpdateTime,
    title: 'Updated',
    Component: TimeFormattedValue,
    sx: { width: '1px', padding: 0 },
  },
  {
    id: SolutionWorkloadStatusConditionsField.Message,
    title: 'Message',
    Component: SolutionWorkloadMessage,
  },
];

function SolutionWorkloadStatusViewModal({ item, open, onClose }: Props) {
  const { name } = item;
  const actions = useStoreUnitActions(solutionsStore);
  const [data, loading] = useSolutionWorkloadResources();
  const [conditionsData] = useSolutionWorkloadConditions();
  const [type, setType] = React.useState(
    loadDataFromStorage('DeploymentStatus') || DeploymentStatus.Resources
  );
  const handleChange = (event: React.SyntheticEvent, newValue) => {
    setType(newValue);
    localStorage.removeItem('DeploymentStatus');
  };

  const displayConditionsData = conditionsData?.map((item) => ({
    ...item,
    message: item.message ? item.message : '',
  }));

  useEffect(() => {
    actions.solutionWorkloadStatus(name);
  }, [name]);

  return (
    <ModalDialog
      title={'Solution Workload Status'}
      open={open}
      onClose={onClose}
      onConfirm={onClose}
      loading={loading}
      showCancelBtn={false}
      confirmBtnText={'Close'}
      maxWidth="lg"
      fullWidth
    >
      <Tabs
        value={type}
        onChange={handleChange}
        aria-label="wrapped label tabs example"
        sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 3 }}
      >
        <Tab value={DeploymentStatus.Resources} label="Resources" />
        <Tab value={DeploymentStatus.Conditions} label="Conditions" />
      </Tabs>
      {type === DeploymentStatus.Resources && (
        <DataTableWrapper searchBar={false}>
          <DataTable columns={resourcesColumns} data={data} sx={tableStyle} />
        </DataTableWrapper>
      )}
      {type === DeploymentStatus.Conditions && (
        <DataTableWrapper searchBar={false}>
          <DataTable
            columns={conditionsColumns}
            data={displayConditionsData}
            sx={tableStyle}
          />
        </DataTableWrapper>
      )}
    </ModalDialog>
  );
}

export default SolutionWorkloadStatusViewModal;
