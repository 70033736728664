import { useSelector } from 'react-redux';
import { State } from 'src/redux/types';
import { CompanyItem, CompanyUserItem } from '../types';

export const useCompaniesList = (): [CompanyItem[], boolean | undefined] => {
  const { data, loading } = useSelector((state: State) => state.companies.list);

  return [data || [], loading];
};

export const useCompaniesDetails = (): [
  CompanyItem | undefined,
  boolean | undefined
] => {
  const { data, loading } = useSelector(
    (state: State) => state.companies.details
  );

  return [data, loading];
};

export const useCurrentCompanyName = () => {
  const [data] = useCompaniesDetails();
  return data?.name;
};

export const useCompanyUsers = (): [CompanyUserItem[], boolean | undefined] => {
  const { data, loading } = useSelector(
    (state: State) => state.companies.users
  );

  return [data || [], loading];
};
