import { createDatasetSlice } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import { generateApiPath, loadDataFromStorage } from 'src/utils';
import {
  API_DEVICE_GROUP_PROPS,
  API_DEVICE_GROUP_PROPS_IOTH,
  SOLUTIONS_STORE,
  SOLUTIONS_STORE_DEVICE_GROUP_PROPERTIES,
} from '../constants';
import { mapSolutionDeviceGroupProperties } from './utils';
import { SolutionType } from '../types';

const api = ({ solutionId, companyId, modelId }) => {
  const solutionType = loadDataFromStorage('solutionType');
  return pepApiRequest({
    method: RequestMethod.Get,
    url: generateApiPath(
      solutionType.toUpperCase() === SolutionType.ioTH.toUpperCase()
        ? API_DEVICE_GROUP_PROPS_IOTH
        : API_DEVICE_GROUP_PROPS,
      {
        solutionId,
        companyId,
        modelId,
      }
    ),
  }).then(mapSolutionDeviceGroupProperties);
};

const solutionPropertiesSlice = createDatasetSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTIONS_STORE_DEVICE_GROUP_PROPERTIES,
});

export default solutionPropertiesSlice;
