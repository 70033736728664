import React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import SolutionEdgeWorkload from '../../SolutionEdgeWorkload';
import SolutionSourceImageRepository from '../../SolutionSourceImageRepository/SolutionSourceImageRepo';

function SolutionEdgeWorkloadTab() {
  return (
    <Box>
      <SolutionEdgeWorkload />
      <Divider />
      <SolutionSourceImageRepository />
    </Box>
  );
}

export default SolutionEdgeWorkloadTab;
