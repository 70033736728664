export enum SolutionUserRole {
  Owner = 'owner',
  User = 'user',
}

export enum SolutionUserNotificationPreferences {
  Email = 1,
  SMS = 2,
  Whatsapp = 3,
}

export type SolutionUserItem = {
  userId: string;
  role: SolutionUserRole;
  phoneNumber: string;
  countryCode: string;
  mobileNumber: string;
  notificationPreference: any[];
};

export enum SolutionUserItemField {
  Email = 'userId',
  Role = 'role',
  PhoneNumber = 'phoneNumber',
  CountryCode = 'countryCode',
  MobileNumber = 'mobileNumber',
  NotificationPreference = 'notificationPreference',
  NotificationPreferList = 'notificationPreferList',
}
