import React from 'react';
import { CircularProgress, Box, Typography } from '@mui/material';

interface CircularProgressWithLabelProps {
  actual?: number | null;
  used?: number | null;
  message?: React.ReactNode;
  size?: number;
}

const CircularProgressWithLabel: React.FC<CircularProgressWithLabelProps> = ({
  actual,
  used,
  message = 'No Data',
  size = 50,
}) => {
  if (actual == null || used == null) {
    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress
          variant="determinate"
          value={0}
          size={size}
          thickness={5}
          style={{ color: '#E0E0E0' }}
        />
        <Box
          position="absolute"
          top="50%"
          left="50%"
          style={{
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            width: '80%',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="black"
            style={{ fontSize: size / 6 }}
          >
            {message}
          </Typography>
        </Box>
      </Box>
    );
  }

  if (actual === 0 && used === 0) {
    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress
          variant="determinate"
          value={0}
          size={size}
          thickness={5}
          style={{ color: '#E0E0E0' }}
        />
        <Box
          position="absolute"
          top="50%"
          left="50%"
          style={{
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            width: '80%',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="black"
            style={{ fontSize: size / 6 }}
          >
            {message}
          </Typography>
        </Box>
      </Box>
    );
  }

  let percentage = (used / actual) * 100;

  // Set the color based on the percentage
  let color = '#0047BA'; // Default color (up to 75%)
  if (percentage > 75 && percentage < 95) {
    color = '#FFA500'; // Warning color (between 75% and 95%)
  } else if (percentage >= 95) {
    color = '#FF0000'; // Red color (95% and above)
  }

  // If used exceeds actual, set percentage to 100% and color to red
  if (used > actual) {
    percentage = 100; // Cap the percentage at 100%
    color = '#FF0000'; // Full red for over capacity
  }

  return (
    <Box position="relative" display="inline-flex">
      {/* Bottom layer: Total capacity in grey */}
      <CircularProgress
        variant="determinate"
        value={100}
        size={size}
        thickness={5}
        style={{ color: '#E0E0E0' }}
        sx={{ strokeLinecap: 'round' }}
      />

      <CircularProgress
        variant="determinate"
        value={percentage}
        size={size}
        thickness={5}
        style={{ position: 'absolute', left: 0, color }}
        sx={{ strokeLinecap: 'round' }}
      />

      <Box
        position="absolute"
        top="50%"
        left="50%"
        style={{
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
          width: '80%',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
          style={{ fontSize: size / 6, wordWrap: 'break-word' }}
        >
          {message}
        </Typography>
      </Box>
    </Box>
  );
};

export default CircularProgressWithLabel;
