export enum SolutionJobStatus {
  Complete = 'complete',
  Failed = 'failed',
  Scheduled = 'scheduled',
  Outdated = 'outdated',
  Pending = 'pending',
  Running = 'running',
  Stopped = 'stopped',
  completed = 'completed',
  Cancelled = 'cancelled',
}

export enum SolutionJobType {
  Command = 'command',
  TemplateMigration = 'deviceTemplateMigration',
  HostOsDeployment = 'hostOSDeployment',
  ScheduleUpdateTwin = 'scheduleUpdateTwin',
  ScheduleDeviceMethod = 'scheduleDeviceMethod',
}

export type SolutionJobItem = {
  id: string;
  displayName: string;
  artifactName?: string;
  deploymentId?: string;
  group: string;
  organization: string;
  type: SolutionJobType;
  template: string;
  runLater?: boolean;
  propertyName: [] | any;
  propertyId: string;
  value: string;
  invokeCompany: any;
  status: SolutionJobStatus;
  propertyUpdate: any;
  commandValue: string;
  modelId: string;
  deviceJobStatistics: any;
  properties: any;
  createdTime: string;
  endTime: string;
  propertyValue: [] | any;
  cloudToDeviceMethod: any;
  commandPayload: any;
};

export type SolutionJobs = {
  data: SolutionJobItem[];
  totalItems: number;
};

export enum SolutionJobItemField {
  DisplayName = 'displayName',
  Group = 'group',
  GroupName = 'groupName',
  ModelId = 'modelId',
  ArtifactName = 'artifactName',
  Company = 'organization',
  RunLater = 'runLater',
  Type = 'type',
  Template = 'template',
  Status = 'status',
  StartTime = 'startTime',
  PropertyName = 'propertyName',
  PropertyValue = 'propertyValue',
  CommandValue = 'commandValue',
  Filters = 'filters',
  PropertyUpdate = 'propertyUpdate',
  InvokeCompany = 'invokeCompany',
}
