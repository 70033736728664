import * as Yup from 'yup';
import endsWith from 'lodash/endsWith';
import TextField from '@mui/material/TextField';
import Select from 'src/components/Select';
import { PEP_EMAIL_DOMAIN_PEPSICO } from 'src/constants';
import { CompanyUserItemField } from '../types';
import { COMPANY_USER_ROLE_OPTIONS } from './constants';

const companyUserRoleField = {
  name: CompanyUserItemField.Role,
  type: 'number',
  label: 'Role',
  placeholder: 'Select a role',
  Component: Select,
  options: COMPANY_USER_ROLE_OPTIONS,
  validation: Yup.string().required('User role is required'),
  fullWidth: true,
  required: true,
  description:
    'Select the appropriate role for the user. A company owner has one additional access over company user that is to the Users tab.',
};

export const companyUserAssignFormFields = [
  {
    name: CompanyUserItemField.Email,
    label: 'Email address',
    placeholder: 'Enter email address',
    Component: TextField,
    options: COMPANY_USER_ROLE_OPTIONS,
    validation: Yup.string()
      .required('Email is required')
      .email('Must be a valid email')
      .test(
        'allowed-Domain',
        `Email should belong to the domain ${PEP_EMAIL_DOMAIN_PEPSICO}`,
        (value) => endsWith(value, `@${PEP_EMAIL_DOMAIN_PEPSICO}`)
      ),
    fullWidth: true,
    required: true,
    initialValue: `@${PEP_EMAIL_DOMAIN_PEPSICO}`,
    description:
      'Enter the email address of a user who will be able to manage this company.',
  },
  companyUserRoleField,
];

export const companyUserEditFormFields = [
  {
    name: CompanyUserItemField.Email,
  },
  companyUserRoleField,
];
