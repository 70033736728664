import { generatePath } from 'react-router-dom';
import { createDatasetSlice } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import {
  SOLUTIONS_STORE_SIGNATURE_WHITELIST,
  API_OS_WHITELIST,
  SOLUTIONS_STORE,
} from '../constants';
import { mapSolutionSignatureWhitelistData } from './utils';

const api = (solutionId: string) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: generatePath(API_OS_WHITELIST, { solutionId }),
  }).then(mapSolutionSignatureWhitelistData);

const solutionSignatureWhitelistSlice = createDatasetSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTIONS_STORE_SIGNATURE_WHITELIST,
});

export default solutionSignatureWhitelistSlice;
