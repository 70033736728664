import React from 'react';
import { generatePath, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { SxProps } from 'src/theme/types';
import ModalDialog, { ModalCloseHandler } from 'src/components/ModalDialog';
import { useForm } from 'src/components/Form';
import { useStoreUnitActions } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import { generateApiPath, loadDataFromStorage } from 'src/utils';
import { getAssignTemplateFormFields } from '../formFields';
import { useCompatibleTemplateOptions } from '../hooks';
import { SolutionDeviceItem, SolutionType } from '../../types';
import {
  API_DEVICES_ASSIGN_TEMPLATE,
  API_DEVICES_ASSIGN_TEMPLATE_IOTH,
} from '../../constants';
import solutionsStore from '../../redux';

type Props = {
  item: SolutionDeviceItem;
  open: boolean;
  onClose: ModalCloseHandler;
};

const modalStyle: SxProps = {
  '.MuiDialogContent-root': {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
};

const formStyle: SxProps = {
  gap: 0.5,
  '.MuiFormControlLabel-root': {
    alignSelf: 'flex-end',
    color: 'neutral.dark',
    typography: 'small1',
  },
};

function SolutionDevicesAssignTemplateModal({ item, open, onClose }: Props) {
  const { devices, devicesPersist } = useStoreUnitActions(solutionsStore);
  const { solutionId } = useParams();
  const { id: deviceId, modelId } = item;
  const [options, setOptions, loading] = useCompatibleTemplateOptions(modelId);
  const { showAlert } = useAlert();
  const solutionType = loadDataFromStorage('solutionType');
  const [handleSubmit, saving] = useStatefulApi(
    ({ modelId }) =>
      pepApiRequest({
        method: RequestMethod.Put,
        url:
          solutionType.toUpperCase() === SolutionType.ioTH.toUpperCase()
            ? generateApiPath(API_DEVICES_ASSIGN_TEMPLATE_IOTH, { solutionId })
            : generatePath(API_DEVICES_ASSIGN_TEMPLATE, {
                solutionId,
                deviceId,
              }),
        params:
          solutionType.toUpperCase() === SolutionType.ioTH.toUpperCase()
            ? {
                modelId,
                deviceIds: [deviceId],
              }
            : { modelId },
      }),
    () => {
      showAlert({
        type: AlertType.Success,
        title: 'The template has been assigned.',
        text: 'New template has been assigned to the device successfully.',
      });
      devices.init({ solutionId });
      devicesPersist.update({ solutionId, devices: [item] });
      onClose();
    }
  );
  const [FormComponent, onSubmit] = useForm({
    sx: formStyle,
    fields: getAssignTemplateFormFields(setOptions, options, modelId),
    onSubmit: handleSubmit,
  });

  return (
    <ModalDialog
      open={open}
      title="Assign template"
      onClose={onClose}
      onConfirm={onSubmit}
      confirmBtnText="Save"
      loading={loading || saving}
      sx={modalStyle}
      fullWidth
    >
      {modelId && (
        <Typography variant="body2" color="primary">
          <InfoOutlinedIcon sx={{ mr: 1 }} />
          Current template: {modelId}
        </Typography>
      )}
      <Typography variant="body2">
        Choose the template you'd like assigned to this device.
      </Typography>
      {FormComponent}
    </ModalDialog>
  );
}

export default SolutionDevicesAssignTemplateModal;
