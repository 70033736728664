import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Breadcrumbs, { BreadcrumbsProps } from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { BreadcrumbsItems } from './types';
import { getItemKey, setLastActive } from './utils';

type Props = BreadcrumbsProps & {
  items: BreadcrumbsItems;
  lastActive?: boolean;
};

function BreadCrumbs({ items, lastActive, ...rest }: Props) {
  setLastActive(items, lastActive);

  const updatedItems = items?.map((item) => {
    if (item.name === 'help-center') {
      return { ...item, name: 'Help center' };
    }
    return item;
  });

  return (
    <Breadcrumbs aria-label="breadcrumbs" data-testid="breadcrumbs" {...rest}>
      {updatedItems.map(({ path, icon, name, isActive }) =>
        isActive || !path ? (
          <Typography key={getItemKey(path, name)} variant="inherit">
            {icon}
            {name}
          </Typography>
        ) : (
          <Link
            component={RouterLink}
            key={getItemKey(path, name)}
            underline="none"
            color="primary"
            to={path}
          >
            {icon}
            {name}
          </Link>
        )
      )}
    </Breadcrumbs>
  );
}

export default BreadCrumbs;
