import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'src/redux/types';
import { DatasetUnit } from 'src/redux/helpers';
import { Option } from 'src/components/SelectOption';
import { getSelectOptions } from 'src/components/Select';
import { useCompaniesList } from 'src/pages/Companies/redux/selectors';
import { useSitesList } from 'src/pages/Sites/redux';
import {
  SolutionCompanyItem,
  SolutionDetailsItem,
  SolutionDeviceObservPropsItem,
  SolutionDeviceConfigItem,
  SolutionDeviceGroupDetails,
  SolutionDeviceGroupItem,
  SolutionDeviceItem,
  SolutionDeviceReleaseItem,
  SolutionDeviceDeploymentLogItem,
  SolutionDeviceGroupPropertyItem,
  SolutionDeviceGroupPropertyMeta,
  SolutionEdgeWorkloadItem,
  SolutionItem,
  SolutionSiteItem,
  SolutionJobs,
  SolutionMenderSettings,
  SolutionUserItem,
  SolutionSubscriptionItem,
  SolutionDeviceBulkDeploymentData,
  SolutionDeviceCertDetailsItem,
  SolutionSourceStorageAccountSettings,
  SolutionFileSubscriptionItem,
  SolutionDeviceCertAuditLogsItem,
  SolutionAdxTablesData,
  SolutionAdlsSubscriptionItem,
  SolutionPepsenceImageRepoItem,
  SolutionWorkloadStatusResourcesItem,
  SolutionWorkloadStatusConditionsItem,
  SolutionWorkloadStatusItem,
  SolutionDevicesModulesList,
  SolutionDevicesComponetsList,
  SolutionDevicesRulesManagementList,
  SolutionDevicesSpecificRulesManagementList,
  SolutionCosmosSubscriptionItem,
  AggregationFunctionsPropertyMeta,
  SolutionPropertiesValue,
  SolutionJobsDeviceItem,
  SolutionDigitalTwinModel,
  SolutionAlertRuleItem,
} from '../types';
import { mapDeviceModelsToConfigList } from './utils';
import {
  SolutionNamespaceRscLimitItem,
  SolutionNamespaceRscQuotasDetailItem,
} from '../types/solutionNamespace';
import {
  DashboardSummaryList,
  DashboardsDeviceGroupList,
} from '../types/solutionDashboard';

export const useSolutionsList = (): [SolutionItem[], boolean?] => {
  const { data, loading } = useSelector((state: State) => state.solutions.list);

  return [data || [], loading];
};

export const useSolutionDetails = (): [
  SolutionDetailsItem | undefined,
  boolean | undefined
] => {
  const { data, loading } = useSelector(
    (state: State) => state.solutions.details
  );

  return [data, loading];
};
export const useSolutionModulesDetails = (): [
  SolutionDevicesModulesList[] | [],
  boolean | undefined
] => {
  const { data, loading } = useSelector(
    (state: State) => state.solutions.deviceModules
  );
  return [data || [], loading];
};

export const useSolutionDashboardDetails = (): [
  DashboardSummaryList[] | [],
  boolean | undefined
] => {
  const { data, loading } = useSelector(
    (state: State) => state.solutions.dashboard
  );
  return [data || [], loading];
};

export const useSolutionDashboardDeviceGroupsDetails = (): [
  DashboardsDeviceGroupList[] | [],
  boolean | undefined
] => {
  const { data, loading } = useSelector(
    (state: State) => state.solutions.dashboardDeviceGroups
  );
  return [data || [], loading];
};

export const useDashboardDeviceGroupsOptions = (): [
  Option[],
  boolean | undefined
] => {
  const [data, loading] = useSolutionDashboardDeviceGroupsDetails();
  return [
    useMemo(
      () =>
        data.map(({ id, displayName }: any) => ({
          value: id,
          label: displayName,
        })) || [],
      [data]
    ),
    loading,
  ];
};

export const useSolutionRulesManagement = (): [
  SolutionDevicesRulesManagementList[] | [],
  boolean | undefined
] => {
  const { data, loading } = useSelector(
    (state: State) => state.solutions.rulesManagement
  );
  return [data || [], loading];
};

export const useSolutionSpecificRulesManagement = (): [
  SolutionDevicesSpecificRulesManagementList[] | [],
  boolean | undefined
] => {
  const { data, loading } = useSelector(
    (state: State) => state.solutions.specificRulesManagement
  );
  return [data || [], loading];
};

export const useSolutionComponentsDetails = (): [
  SolutionDevicesComponetsList[] | [],
  boolean | undefined
] => {
  const { data, loading } = useSelector(
    (state: State) => state.solutions.deviceComponents
  );
  return [data || [], loading];
};

export const useSolutionSitesList = (): [
  SolutionSiteItem[],
  boolean | undefined
] => {
  const { data, loading } = useSelector(
    (state: State) => state.solutions.sites
  );

  return [data || [], loading];
};

export const useSolutionAlertRulesList = (): [
  SolutionAlertRuleItem[],
  boolean | undefined
] => {
  const { data, loading } = useSelector(
    (state: State) => state.solutions.alertRules
  );

  return [data || [], loading];
};

export const useSolutionsSitesIds = (): [number[], boolean?] => {
  const [data, loading] = useSolutionSitesList();

  return [useMemo(() => data.map(({ id }) => id), [data]), loading];
};

export const useSolutionAssignSiteOptions = (): [
  Option<string>[],
  boolean | undefined
] => {
  const [list, listLoading] = useSitesList();
  const [selection, selectionLoading] = useSolutionsSitesIds();

  return [
    useMemo(
      () =>
        list
          .filter(
            (item) => !selection.includes(item.id) && item.status === 'active'
          )
          .map(({ id, name }: SolutionSiteItem) => ({
            value: id.toString(),
            label: name,
          })),
      [list, selection]
    ),
    listLoading || selectionLoading,
  ];
};

export const useSolutionMenderSettings = (): [
  SolutionMenderSettings,
  boolean | undefined
] => {
  const [data, loading] = useSolutionDetails();
  const { menderSetup } = data?.features || {};

  return [menderSetup || {}, loading];
};

export const useSolutionSourceStorageAccountSettings = (): [
  SolutionSourceStorageAccountSettings,
  boolean | undefined
] => {
  const [data, loading] = useSolutionDetails();

  const { storageSetup } = data?.features || {};

  return [storageSetup || {}, loading];
};

export const useDeviceConfigFilesList = (): [
  SolutionDeviceConfigItem[],
  boolean?
] => {
  const [data, loading] = useSolutionDetails();
  const { deviceDigitalTwinsModels, contextDigitalTwinsModels } = data || {};

  return [
    useMemo(
      () => [
        ...mapDeviceModelsToConfigList(
          deviceDigitalTwinsModels,
          contextDigitalTwinsModels
        ),
      ],
      [deviceDigitalTwinsModels, contextDigitalTwinsModels]
    ),
    loading,
  ];
};

export const useDeviceConfigFileData = (): [
  SolutionDeviceConfigItem,
  boolean | undefined
] => {
  const { data, loading } = useSelector(
    (state: State) => state.solutions.deviceConfigFile
  );

  return [data, loading];
};

export const useCurrentSolutionDeviceName = () => {
  const { data } = useSolutionDeviceDetails();

  return data?.displayName;
};

export const useCurrentSolutionName = () => {
  const [data] = useSolutionDetails();

  return data?.solutionDisplayName;
};

export const useSolutionsWhitelist = () => {
  const { data, loading } = useSelector(
    (state: State) => state.solutions.filesWhitelist
  );

  return {
    data: data || [],
    loading,
  };
};

export const useSolutionSignatureWhitelist = () => {
  const { data, loading } = useSelector(
    (state: State) => state.solutions.signatureWhitelist
  );

  return {
    data: data || [],
    loading,
  };
};

export const useSolutionCompanies = (): [SolutionCompanyItem[], boolean?] => {
  const { data, loading } = useSelector(
    (state: State) => state.solutions.companies
  );

  return [data || [], loading];
};

export const useSolutionCompaniesIds = (): [string[], boolean?] => {
  const [data, loading] = useSolutionCompanies();

  return [useMemo(() => data.map(({ id }) => id), [data]), loading];
};

export const useSolutionCompaniesOptions = (): [
  Option<string>[],
  boolean | undefined
] => {
  const [data, loading] = useSolutionCompanies();

  return [
    useMemo(
      () =>
        data.map(({ id, name }: SolutionCompanyItem) => ({
          value: id,
          label: name,
        })),
      [data]
    ),
    loading,
  ];
};

export const useSolutionUnassignedCompaniesOptions = (): [
  Option<string>[],
  boolean | undefined
] => {
  const [list, listLoading] = useCompaniesList();
  const [selection, selectionLoading] = useSolutionCompaniesIds();

  return [
    useMemo(
      () =>
        list
          .filter(({ id }) => !selection.includes(id))
          .map(({ id, name }: SolutionCompanyItem) => ({
            value: id,
            label: name,
          })),
      [list, selection]
    ),
    listLoading || selectionLoading,
  ];
};

export const useSolutionsDevicesList = (): [
  SolutionDeviceItem[],
  boolean | undefined,
  string | undefined,
  number | undefined
] => {
  const { data, loading, skipToken, sequence } = useSelector(
    (state: State) => state.solutions.devices
  );
  return [data || [], loading, skipToken, sequence];
};

export const useSolutionDeviceObservProperties = (): [
  SolutionDeviceObservPropsItem,
  boolean | undefined
] => {
  const { data, loading } = useSelector(
    (state: State) => state.solutions.deviceObservProperties
  );
  return [data || {}, loading];
};

export const useSolutionDeviceComponentProperties =
  (): SolutionPropertiesValue => {
    const { data } = useSelector(
      (state: State) => state.solutions.deviceProperties
    );
    return data;
  };

export const useSolutionsDeviceCertDetails = (): [
  SolutionDeviceCertDetailsItem[],
  boolean | undefined
] => {
  const { data, loading } = useSelector(
    (state: State) => state.solutions.deviceCertificateDetails
  );
  return [data || [], loading];
};

export const useSolutionsDeviceCertAuditLogs = (): [
  SolutionDeviceCertAuditLogsItem[],
  boolean | undefined
] => {
  const { data, loading } = useSelector(
    (state: State) => state.solutions.deviceCertificateAuditLogs
  );
  return [data || [], loading];
};

export const useSolutionDevicesPersist = (): [
  SolutionDeviceItem[],
  boolean | undefined
] => {
  const { data, loading } = useSelector(
    (state: State) => state.solutions.devicesPersist
  );
  return [data || [], loading];
};

export const useSolutionDeviceDetails = () =>
  useSelector((state: State) => state.solutions.deviceDetails);

export const useSolutionDeviceDeploymentLog = (): [
  SolutionDeviceDeploymentLogItem[],
  boolean | undefined
] => {
  const { data, loading } = useSelector(
    (state: State) => state.solutions?.deviceDeploymentLog || {}
  );

  return [data || [], loading];
};

export const useSolutionDevDepReleaseOptions = (): [
  Option[],
  boolean | undefined
] => {
  const { data, loading } = useSelector(
    (state: State) => state.solutions.deviceDeploymentReleases
  );

  return [
    useMemo(
      () =>
        data?.map(({ name }: SolutionDeviceReleaseItem) => ({
          value: name,
          label: name,
        })),
      [data]
    ),
    loading,
  ];
};

export const useSolutionDeviceTemplateOptions = (): [
  Option[],
  boolean | undefined
] => {
  const [data, loading] = useDeviceConfigFilesList();

  return [
    useMemo(() => getSelectOptions(data?.map(({ id }) => id) || []), [data]),
    loading,
  ];
};

export const useSolutionUsers = (): [
  SolutionUserItem[],
  boolean | undefined
] => {
  const { data, loading } = useSelector(
    (state: State) => state.solutions?.users
  );
  return [data || [], loading];
};

export const useSolutionDeviceGroups = (): [
  SolutionDeviceGroupItem[],
  boolean | undefined,
  string | undefined
] => {
  const { data, loading, skipToken } = useSelector(
    (state: State) => state.solutions?.deviceGroups
  );
  return [data || [], loading, skipToken];
};
export const useSolutionJobsDevice = (): [
  SolutionJobsDeviceItem,
  boolean | undefined
] => {
  const { data, loading } = useSelector(
    (state: State) => state.solutions?.jobsDevices
  );
  return [data || [], loading];
};

export const useSolutionCommandOptions = (): [
  Option[],
  boolean | undefined
] => {
  const [data, loading] = useSolutionComponentsDetails();
  return [
    useMemo(
      () =>
        data?.map(({ id, name }: any) => ({
          value: id,
          label: name,
        })) || [],
      [data]
    ),
    loading,
  ];
};

export const useSolutionDeviceGroupDetails = (): [
  SolutionDeviceGroupDetails | undefined,
  boolean | undefined
] => {
  const { data, loading } = useSelector(
    (state: State) => state.solutions?.deviceGroupDetails
  );
  return [data, loading];
};

export const useSolutionDeviceGroupsOptions = (): [
  Option<string>[],
  boolean | undefined,
  string | undefined
] => {
  const [list, loading, skipToken] = useSolutionDeviceGroups();

  return [
    useMemo(
      () =>
        list.map(({ id, displayName }: SolutionDeviceGroupItem) => ({
          value: id,
          label: displayName,
        })),
      [list]
    ),
    loading,
    skipToken,
  ];
};

export const useSolutionJobs = (): DatasetUnit<SolutionJobs> => {
  const { data = [], ...rest } = useSelector(
    (state: State) => state.solutions.jobs
  );

  return { data, ...rest };
};

export const useSolutionDeviceBulkDeploymentData =
  (): DatasetUnit<SolutionDeviceBulkDeploymentData> => {
    const { logs, details, statistics, loading } = useSelector(
      (state: State) => state.solutions?.deviceBulkDeploymentDetails || {}
    );
    return {
      logs: logs || [],
      details: details || {},
      statistics: statistics || {},
      loading,
    };
  };

export const useSolutionDeviceGroupProperties = (): [
  SolutionDeviceGroupPropertyItem[],
  boolean | undefined
] => {
  const { data, loading } = useSelector(
    (state: State) => state.solutions?.deviceGroupProperties
  );

  return [data || [], loading];
};

export const useSolutionDeviceGroupWritableProperties = (): [
  SolutionDeviceGroupPropertyItem[],
  boolean | undefined
] => {
  const { data, loading } = useSelector(
    (state: State) => state.solutions?.deviceGroupProperties
  );
  const filterData = data?.filter((d) => d.writable === true);
  return [filterData || [], loading];
};

export const useSolutionRuleTelemtryProperties = (): [
  SolutionDeviceGroupPropertyItem[],
  boolean | undefined
] => {
  const { data, loading } = useSelector(
    (state: State) => state.solutions?.ruleTelemtryProperties
  );

  return [data || [], loading];
};

export const useSolutionDeviceGroupOnlyWritablePropOptions = (): [
  Option[],
  boolean | undefined
] => {
  const [data, loading] = useSolutionDeviceGroupProperties();
  const filterData = data.filter((d) => d.writable === true);
  return [
    useMemo(
      () =>
        filterData?.map(({ id, displayName }) => ({
          value: id,
          label: displayName,
        })) || [],
      [filterData]
    ),
    loading,
  ];
};

export const useSolutionDeviceGroupPropOptions = (): [
  Option[],
  boolean | undefined
] => {
  const [data, loading] = useSolutionDeviceGroupProperties();
  return [
    useMemo(
      () =>
        data?.map(({ id, displayName }) => ({
          value: id,
          label: displayName,
        })) || [],
      [data]
    ),
    loading,
  ];
};

export const useSolutionTelemtryGroupPropOptions = (): [
  Option[],
  boolean | undefined
] => {
  const [data, loading] = useSolutionRuleTelemtryProperties();
  return [
    useMemo(
      () =>
        data?.map(({ id, displayName }) => ({
          value: id,
          label: displayName,
        })) || [],
      [data]
    ),
    loading,
  ];
};

export const useSolutionAggregatePropertyMeta = (
  propertyId?: string
): AggregationFunctionsPropertyMeta => {
  const [data] = useSolutionRuleTelemtryProperties();
  const meta = useMemo(
    () => data?.find(({ id }) => propertyId === id),
    [propertyId, data]
  );

  return {
    aggregationFunctions: meta?.aggregationFunctions || [],
  };
};

export const useSolutionTelemetryPropertyMeta = (
  propertyId?: string
): SolutionDeviceGroupPropertyMeta => {
  const [data] = useSolutionRuleTelemtryProperties();
  const meta = useMemo(
    () => data?.find(({ id }) => propertyId === id),
    [propertyId, data]
  );

  return {
    operators: meta?.operators || [],
    enums: meta?.enums || null,
    propertyId: meta?.enums && meta?.operators?.length >= 0 ? propertyId : null,
  };
};

export const useSolutionDevicePropertyMeta = (
  propertyId?: string
): SolutionDeviceGroupPropertyMeta => {
  const [data] = useSolutionDeviceGroupProperties();
  const meta = data?.find(({ id }) => propertyId === id);

  let updatedMeta;

  if (meta && Array.isArray(meta.enums)) {
    updatedMeta = meta.enums.map((enumItem) => ({
      ...enumItem,
      value: enumItem.value.toString(),
    }));
  } else {
    updatedMeta = meta?.enums;
  }

  return {
    operators: meta?.operators || [],
    enums: updatedMeta,
    propertyId: meta?.enums && meta?.operators?.length >= 0 ? propertyId : null,
  };
};

export const useSolutionSubscriptions = (): [
  SolutionSubscriptionItem[],
  boolean | undefined
] => {
  const { data, loading } = useSelector(
    (state: State) => state.solutions.subscriptions
  );

  return [data || [], loading];
};

export const useSolutionFileSubscription = (): [
  SolutionFileSubscriptionItem[],
  boolean | undefined,
  string | undefined
] => {
  const { data, loading, error } = useSelector(
    (state: State) => state.solutions.fileSubscriptionById
  );

  return [data || [], loading, error];
};

export const useSolutionAdlsSubscription = (): [
  SolutionAdlsSubscriptionItem,
  boolean | undefined,
  string | undefined
] => {
  const { data, loading, error } = useSelector(
    (state: State) => state.solutions.adlsSubscriptionById
  );

  return [data || [], loading, error];
};

export const useSolutionCosmosSubscription = (): [
  SolutionCosmosSubscriptionItem[],
  boolean | undefined
] => {
  const { data, loading } = useSelector(
    (state: State) => state.solutions.cosmosSubscriptionById
  );

  return [data || [], loading];
};

export const useSolutionEdgeWorkloadConfigList = (): [
  SolutionEdgeWorkloadItem[],
  boolean | undefined
] => {
  const { data, loading } = useSelector(
    (state: State) => state.solutions.edgeWorkloadConfigurations
  );

  return [data || [], loading];
};

export const useSolutionDefaultNamespaceRscLimit = (): [
  SolutionNamespaceRscLimitItem | undefined,
  boolean | undefined
] => {
  const { data, loading } = useSelector(
    (state: State) => state.solutions.defaultNamespaceRscLimit
  );

  return [data, loading];
};

export const useSolutionWorkloadStatus = (): [
  SolutionWorkloadStatusItem,
  boolean | undefined
] => {
  const { data, loading } = useSelector(
    (state: State) => state.solutions.solutionWorkloadStatus
  );

  return [data, loading];
};

export const useSolutionWorkloadResources = (): [
  SolutionWorkloadStatusResourcesItem[],
  boolean | undefined
] => {
  const [data, loading] = useSolutionWorkloadStatus();
  const { resources } = data?.status || {};

  return [resources || [], loading];
};

export const useSolutionWorkloadConditions = (): [
  SolutionWorkloadStatusConditionsItem[],
  boolean | undefined
] => {
  const [data, loading] = useSolutionWorkloadStatus();

  const { conditions } = data?.status || {};

  return [conditions || [], loading];
};

export const useSolutionNamespaceRscLimit = (): [
  SolutionNamespaceRscLimitItem[] | undefined,
  boolean | undefined
] => {
  const { data, loading } = useSelector(
    (state: State) => state.solutions.clusterRscLimit
  );

  return [data || [], loading];
};

export const useSolutionNamespaceRscQuotasDetail = (): [
  SolutionNamespaceRscQuotasDetailItem | undefined,
  boolean | undefined
] => {
  const { data, loading } = useSelector(
    (state: State) => state.solutions.namespaceRscQuotasDetail
  );

  return [data, loading];
};

export const useSolutionAdxMappedTables = (): [
  SolutionAdxTablesData,
  boolean | undefined
] => {
  const { data, loading } = useSelector(
    (state: State) => state.solutions.adxMappedTables
  );
  return [data, loading];
};

export const useSolutionPepsenceImageRepoData = (): [
  SolutionPepsenceImageRepoItem | undefined,
  boolean | undefined
] => {
  const { data, loading } = useSelector(
    (state: State) => state.solutions.pepsenceImageRepoDetails
  );

  return [data, loading];
};
