import { Box, IconButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { useUserRoles } from 'src/auth';
import { getActionsColConfig } from 'src/components/DataTable';
import { useStoreUnitActions } from 'src/redux/helpers';
import GuidedTour from 'src/components/GuidedTour/guidedTour';
import { ReactourStep } from 'reactour';
import solutionsStore, {
  useSolutionDetails,
  useSolutionEdgeWorkloadConfigList,
} from '../redux';
import {
  SolutionEdgeWorkloadItem,
  SolutionEdgeWorkloadItemField,
  SolutionEdgeWorkloadRepositoryType,
  SolutionEdgeWorkloadStatus as StatusType,
} from '../types';
import SolutionEdgeWorkloadActions from './components/SolutionEdgeWorkloadActions';
import SolutionEdgeWorkloadRepositoryList from './components/SolutionEdgeWorkloadRepositoryList';
import SolutionEdgeWorkloadStatus from './components/SolutionEdgeWorkloadStatus';

const tourButtonStyle = {
  textDecoration: 'underline',
  color: '#0047BA',
  cursor: 'pointer',
  fontWeight: 500,
  paddingLeft: '3px',
};

const contentStyle = {
  color: '#FFF',
  fontSize: '12px',
  fontWeight: '400',
  fontFamily: 'Roboto',
  opacity: '1',
};

const columns = [
  {
    id: SolutionEdgeWorkloadItemField.Name,
    title: 'Name',
  },
  {
    id: SolutionEdgeWorkloadItemField.Description,
    title: 'Description',
  },
  {
    id: SolutionEdgeWorkloadItemField.RepositoryURL,
    title: 'Repository URL',
  },
  {
    id: SolutionEdgeWorkloadItemField.BranchName,
    title: 'Branch',
  },
  {
    id: SolutionEdgeWorkloadItemField.Path,
    title: 'Path',
  },
  {
    id: SolutionEdgeWorkloadItemField.DeploymentStatusData,
    title: 'Status',
    Component: SolutionEdgeWorkloadStatus,
  },

  getActionsColConfig(SolutionEdgeWorkloadActions),
];

function SolutionEdgeWorkload() {
  const { solutionId } = useParams();
  const navigate = useNavigate();
  const [data, loading] = useSolutionEdgeWorkloadConfigList();
  const [solutionDetails] = useSolutionDetails();
  const {
    isPlatformOwner,
    isPlatformReader,
    isPlatformUser,
    isPlatformEngineer,
  } = useUserRoles();
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [activeStep, setActiveStep] = useState<number | null>(null);
  const pepsenseEdgeData: SolutionEdgeWorkloadItem[] = [];
  const solutionEdgeData: SolutionEdgeWorkloadItem[] = [];
  data?.map((name) => {
    const item: SolutionEdgeWorkloadItem = {
      ...name,
      deploymentStatusData: JSON.stringify({
        status:
          name.deploymentStatus === 'Deployment Paused'
            ? name.deploymentStatus.replace(
                'Deployment Paused',
                StatusType.paused
              )
            : name.deploymentStatus,
        errorMsg: name.deploymentMessage,
      }),
    };
    if (item.gitFlag === SolutionEdgeWorkloadRepositoryType.PepsenseEdge) {
      pepsenseEdgeData.push(item);
      return null;
    }
    solutionEdgeData.push(item);
    return null;
  });
  const actions = useStoreUnitActions(solutionsStore);

  const steps: ReactourStep[] = [
    {
      selector: '.PEPsenseedge',
      content: (
        <>
          <Typography color="#FFF" fontSize="18px">
            Configure New
          </Typography>
          <br />
          <Typography sx={contentStyle}>
            Configure new git repository with manifest files provided in the
            branch/path. Once the repo is created, user can manually deploy the
            resources.
          </Typography>
        </>
      ),
      position: 'right',
      style: {
        backgroundColor: '#013182',
      },
    },
    {
      selector: '.SolutionEdge',
      content: (
        <>
          <Typography color="#FFF" fontSize="18px">
            Configure New
          </Typography>
          <br />
          <Typography sx={contentStyle}>
            Configure new solution specific git repo with manifest files
            provided in the branch/path. Once the repo is created, user can
            manually deploy the resources.
          </Typography>
        </>
      ),
      position: 'right',
      style: {
        backgroundColor: '#013182',
      },
    },
    !solutionDetails?.industrialWorkloadInformation?.serverUrl
      ? {
          selector: '.configureNew',
          content: (
            <>
              <Typography color="#FFF" fontSize="18px">
                Configure New
              </Typography>
              <br />
              <Typography sx={contentStyle}>
                Specify the registry from where the repositories can be pulled
                into pepsense system.
              </Typography>
            </>
          ),
          position: 'right',
          style: {
            backgroundColor: '#013182',
          },
        }
      : {
          selector: '.pulldockerimage',
          content: (
            <>
              <Typography color="#FFF" fontSize="18px">
                Pull docker images
              </Typography>
              <br />
              <Typography sx={contentStyle}>
                List the docker images from the configured source container
                registry
              </Typography>
            </>
          ),
          position: 'right',
          style: {
            backgroundColor: '#013182',
          },
        },
  ];

  const startTour = (event) => {
    event?.preventDefault();
    setIsTourOpen(true);
    setActiveStep(0);
  };

  const handleClose = () => {
    setIsTourOpen(false);
    setActiveStep(null);
  };

  useEffect(() => {
    actions.edgeWorkloadConfigurations(solutionId);
  }, [solutionId]);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          style={{
            fontSize: '18px',
            marginTop: '10px',
            fontFamily: 'Stolzl',
            color: '#242424',
          }}
        >
          Edge workload
        </Typography>
        <Box>
          <IconButton
            size="small"
            title="Help center"
            onClick={() =>
              navigate(
                '/help-center/docs/IndustrialSetup/SolutionWorkloadLifecycleManagement/'
              )
            }
          >
            <HelpOutlineOutlinedIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      <Typography
        style={{
          marginBottom: '40px',
          fontSize: '14px',
          fontFamily: 'Roboto',
          marginTop: '5px',
        }}
      >
        Provisions users to configure edge workloads and handle deployments.
        <Typography
          variant="small2"
          onClick={startTour}
          style={tourButtonStyle}
        >
          Take a tour
        </Typography>
      </Typography>
      {(isPlatformOwner || isPlatformReader || isPlatformEngineer) && (
        <SolutionEdgeWorkloadRepositoryList
          title="PEPsense git repository"
          infoTitle="PEPsense git repository is used deploy core and common details and can be configured by platform owner"
          data={pepsenseEdgeData}
          columns={columns}
          className={activeStep === 0 ? 'PEPsenseedge active' : 'PEPsenseedge'}
          repositoryType={SolutionEdgeWorkloadRepositoryType.PepsenseEdge}
          loading={loading}
          enableSearchBar={false}
        />
      )}
      <br />
      <SolutionEdgeWorkloadRepositoryList
        title="Solution git repository"
        infoTitle="Solution git repository is used to continuously deploy solution-specific workloads on the edge using GitOps."
        data={solutionEdgeData}
        className={
          (activeStep === 0 &&
            !(isPlatformOwner || isPlatformReader || isPlatformEngineer)) ||
          (activeStep === 1 && !isPlatformUser)
            ? 'SolutionEdge active'
            : 'SolutionEdge'
        }
        columns={columns}
        repositoryType={SolutionEdgeWorkloadRepositoryType.SolutionEdge}
        loading={loading}
        enableSearchBar={true}
      />
      <GuidedTour
        steps={
          !(isPlatformOwner || isPlatformReader || isPlatformEngineer)
            ? steps.filter((step) => step.selector !== '.PEPsenseedge')
            : steps
        }
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        isOpen={isTourOpen}
        onClose={handleClose}
        className={
          (activeStep === 1 &&
            !(isPlatformOwner || isPlatformReader || isPlatformEngineer)) ||
          (activeStep === 2 && !isPlatformUser)
            ? 'source-image-repository'
            : ''
        }
      />
    </>
  );
}

export default SolutionEdgeWorkload;
