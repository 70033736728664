import { createDatasetSlice, ResponseMapping } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import { generateApiPath } from 'src/utils';
import {
  API_DEVICE_CERT_LOGS,
  SOLUTIONS_STORE,
  SOLUTIONS_STORE_DEVICE_CERT_AUDIT_LOGS,
} from '../constants';
import { mapSolutionDeviceCertAuditLogs } from './utils';

const api = ({ solutionId, deviceId, type }) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: generateApiPath(API_DEVICE_CERT_LOGS, { solutionId, deviceId }),
  }).then((response) => mapSolutionDeviceCertAuditLogs(response, type));

const solutionDeviceCertAuditLogsSlice = createDatasetSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTIONS_STORE_DEVICE_CERT_AUDIT_LOGS,
  responseMapping: ResponseMapping.Default,
});

export default solutionDeviceCertAuditLogsSlice;
