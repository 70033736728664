import React from 'react';
import Typography from '@mui/material/Typography';
import { labelDisplayedRows, getLabelDisplayedRowsTo } from '../utils';

type Props = {
  pageIndex: number;
  pageSize: number;
  totalItems: number;
};

function PaginationDisplayedItems({ pageIndex, pageSize, totalItems }: Props) {
  return (
    <Typography variant="body2md" paddingLeft={4}>
      {labelDisplayedRows({
        from: pageIndex * pageSize + 1,
        to: getLabelDisplayedRowsTo({ totalItems, pageIndex, pageSize }),
        totalItems,
      })}
    </Typography>
  );
}

export default PaginationDisplayedItems;
