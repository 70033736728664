import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { useModal } from 'src/hooks/modalHooks';
import {
  DataTableActions,
  DataTableActionsButton
} from 'src/components/DataTable';
import Box from '@mui/material/Box';
import { useDatasetUnitAction } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import { PageCardLoader } from 'src/components/PageCardLayout';
import { isModalComplete } from 'src/components/ModalDialog';
import { OtpItem } from '../types';
import DeleteOtpModal from './DeleteOtpModal';
import EditOtpModalContainer from './EditOtpModalContainer';
import otpStore from '../redux';
import { API_OTP_SEND_EMAIL } from '../constants';
import { useOtpUserRoles } from '../hooks';

type Props = {
  item?: OtpItem;
};

function OtpListActions({ item }: Props) {
  const action = useDatasetUnitAction(otpStore);
  const onClose = (event, reason) => isModalComplete(reason) && action();
  const { showAlert } = useAlert();
  const { isKeyEditor } = useOtpUserRoles(item);

  const [DeleteDialog, showDeleteDialog] = useModal(DeleteOtpModal, {
    item,
    itemKey: item?.key,
    onClose,
  });
  const [EditDialog, showEditDialog] = useModal(EditOtpModalContainer, {
    item,
    onClose,
  });

  const [onResendEmail, loading] = useStatefulApi(
    () =>
      pepApiRequest({
        url: API_OTP_SEND_EMAIL,
        method: RequestMethod.Post,
        params: { keys: [item?.key] },
      }),
    () => {
      showAlert({
        type: AlertType.Info,
        title: 'Email has been send.',
        text: 'OTP key email has been send successfully.',
      });
    }
  );

  return (
    <Box className={'data-table-action'}>
      <DataTableActions
        data-testid="data-table-col-data-action"
        canEdit={isKeyEditor}
        canDelete={isKeyEditor}
        onEdit={showEditDialog}
        onDelete={showDeleteDialog}
      >
        <DataTableActionsButton
          data-testid="otp-list-send-email-action"
          disabled={!isKeyEditor}
          icon={ForwardToInboxIcon}
          title="Send email"
          onClick={onResendEmail}
        />
        {EditDialog}
        {DeleteDialog}
        {loading && <PageCardLoader />}
      </DataTableActions>
    </Box>
  );
}

export default OtpListActions;
