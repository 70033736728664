import * as Yup from 'yup';
import { compact } from 'lodash';
import { Option } from 'src/components/SelectOption';
import AutoCompleteBox from 'src/components/AutoCompleteBox';
import AutoCompleteWithFreeText from 'src/components/AutoComplete/AutoCompleteWithFreeText';
import { SolutionSourcePullImageItemField } from '../../types/solutionEdgeWorkload';
// import sx from '@mui/system/sx';

export const SourcePullImageFormFields = (
  options?: Option[],
  canSelectImage?: string | boolean
) =>
  compact([
    canSelectImage === true && {
      name: SolutionSourcePullImageItemField.Images,
      label: 'Images',
      Component: AutoCompleteBox,
      validation: Yup.array()
        .nullable()
        .min(1)
        .required('Image selection is required'),
      options,
      initialValue: [],
      placeholder: 'Search images or tag',
      required: true,
      multiple: true,
    },
    canSelectImage === false && {
      name: SolutionSourcePullImageItemField.Images,
      label: 'Images',
      placeholder: 'Type Image name : tag',
      Component: AutoCompleteWithFreeText,
      options: [],
      validation: Yup.array()
        .min(1)
        .required('Atleast one table name is required')
        .nullable(),
      autocompleteRows: 4,
      multiple: true,
    },
  ]);
