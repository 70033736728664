import * as Yup from 'yup';
import { ChangeEvent, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { DateTimePicker } from '@mui/x-date-pickers';
import Select from 'src/components/Select';
import { SolutionCommandAssignTemplateField } from '../types/solutionDevices';

export const getCommandTemplateFormFields = () => [
  {
    name: 'command',
    label: 'Wait *',
    placeholder: 'Enter the value',
    Component: TextField,
    validation: Yup.string().required('Max file size value is required'),
  },
];

export const getCommandTempFormFields = (
  retData,
  handlePropertyVal?: (event: ChangeEvent<HTMLInputElement>) => void,
  handleObjPropertyVal?: (event: ChangeEvent<HTMLInputElement>) => void
) => {
  if (retData.length !== 0 && typeof retData?.requestSchemaType !== 'string') {
    const fieldValue = retData?.requestSchemaType?.fields;
    const findLabels = (fieldRow) => {
      let enumKey = Object.keys(fieldRow);
      enumKey = enumKey.filter(
        (enumKey) => enumKey !== 'displayName' && enumKey !== 'name'
      );
      const enumKeyValue = enumKey[0];
      const enumArray = fieldRow[enumKeyValue]?.enumValues;
      const enumData = enumArray?.map((element) => ({
        label: element.name,
        value: element.enumValue,
        ...element,
      }));

      enumData.map((item) => {
        delete item.enumValue;
        delete item.name;
        delete item.displayName;
        return item;
      });
      return enumData;
    };
    return fieldValue?.map((fieldRow) => ({
      name: fieldRow?.name,
      label: fieldRow?.name,
      placeholder: 'Enter the value',
      onChange: handleObjPropertyVal,
      Component: fieldRow?.schema ? TextField : Select,
      options: !fieldRow?.schema ? findLabels(fieldRow) : [],
      required: true,
      validation: Yup.string().required(`${fieldRow?.name} is required`),
      sx: { width: ({ spacing }) => spacing(24), padding: '5px' },
    }));
  }
  return [
    {
      name: SolutionCommandAssignTemplateField.CommandInputValue,
      label: 'Wait',
      placeholder: 'Enter the value',
      onChange: handlePropertyVal,
      Component: TextField,
      validation:
        retData?.requestSchemaType === '-'
          ? false
          : Yup.string().required(`${retData?.requestSchemaType} is required`),
      disabled: retData?.requestSchemaType === '-',
    },
  ];
};

export const getPropertiesTemplateFormFields = () => [
  {
    name: 'properties',
    label: 'Wait *',
    placeholder: 'Enter the value',
    Component: TextField,
    validation: Yup.string().required('Max file size value is required'),
  },
];

export const GetPropertiesTempFormFields = (
  propertyData,
  propertiesData,
  desiredPropertyData,
  reportedPropertyData?: any,
  handlePropertyVal?: (event: ChangeEvent<HTMLInputElement>) => void,
  handleObjPropertyVal?: (event: ChangeEvent<HTMLInputElement>) => void
): any => {
  const [textBoxValue, setTextBoxValue] = useState({});

  useEffect(() => {
    const filteredProperty = propertiesData.filter(
      (prop) => prop.name === propertyData.name
    );
    const initialValues = filteredProperty.reduce((acc, propertyVal) => {
      if (desiredPropertyData && desiredPropertyData[propertyVal.name]) {
        acc[propertyVal.name] = desiredPropertyData[propertyVal.name];
      } else if (
        reportedPropertyData &&
        reportedPropertyData[propertyVal.name]
      ) {
        acc[propertyVal.name] = reportedPropertyData[propertyVal.name];
      } else {
        acc[propertyVal.name] = '';
      }
      return acc;
    }, {});
    setTextBoxValue(initialValues);
  }, [propertyData]);

  const handleChangeVal = (name) => (event) => {
    setTextBoxValue((prevTextBoxVal) => ({
      ...prevTextBoxVal,
      [name]: event.target.value,
    }));
  };

  if (propertyData?.length === 0) return [];
  if (propertyData?.schema?.toLowerCase() === 'integer') {
    return [
      {
        id: propertyData?.name,
        name: propertyData?.name,
        label: propertyData?.displayName,
        value: textBoxValue[propertyData?.name],
        placeholder: 'Enter the value',
        onChange: handleChangeVal(propertyData.name),
        Component: TextField,
        type: 'number',
        required: true,
        validation: Yup.string().required(`${propertyData?.name} is required`),
        sx: { width: ({ spacing }) => spacing(24), padding: '5px' },
      },
    ];
  }
  if (propertyData?.schema?.toLowerCase() === 'enum') {
    return [
      {
        name: propertyData?.name,
        label: propertyData?.displayName,
        placeholder: 'Select the value',
        onChange: handleObjPropertyVal,
        Component: Select,
        options: propertyData?.enums,
        required: true,
        validation: Yup.string().required(`${propertyData?.name} is required`),
        sx: { width: ({ spacing }) => spacing(24), padding: '5px' },
      },
    ];
  }
  if (propertyData?.schema === 'datetime') {
    return [
      {
        name: propertyData?.name,
        label: propertyData?.displayName,
        placeholder: 'mm/dd/yyyy hh:mm',
        Component: DateTimePicker,
        validation: Yup.string().required(
          `${propertyData?.schema} is required`
        ),
      },
    ];
  }
  if (propertyData?.schema?.toLowerCase() === 'string') {
    return [
      {
        id: propertyData?.name,
        name: propertyData?.name,
        label: propertyData?.displayName,
        value: textBoxValue[propertyData?.name],
        placeholder: 'Enter the value',
        onChange: handleChangeVal(propertyData.name),
        Component: TextField,
        type: 'text',
        required: true,
        validation: Yup.string().required(`${propertyData?.name} is required`),
        sx: { width: ({ spacing }) => spacing(24), padding: '5px' },
      },
    ];
  }
  return [
    {
      name: propertyData?.displayName,
      label: 'Wait',
      id: propertyData?.displayName,
      value: textBoxValue[propertyData?.name],
      placeholder: 'Enter the value',
      onChange: handleChangeVal(propertyData.name),
      Component: TextField,
      validation:
        propertyData?.schema === '-'
          ? false
          : Yup.string().required(`${propertyData?.schema} is required`),
    },
  ];
};
