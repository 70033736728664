import { baseTheme } from '../baseTheme';

const { alpha, palette, spacing, typography } = baseTheme;

export default {
  MuiTable: {
    styleOverrides: {
      root: {
        borderTopLeftRadius: spacing(0.4),
        borderTopRightRadius: spacing(0.4),
        overflow: 'hidden',
        tableLayout: 'fixed',
      },
    },
  },
  MuiTableContainer: {
    styleOverrides: {
      root: {
        overflow: 'initial',
        position: 'relative',
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: {
        backgroundColor: palette.blue[100],
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        '&:hover:not(.MuiTableRow-head)': {
          backgroundColor: alpha(palette.primary.light, 0.05),
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        textAlign: 'left',
        verticalAlign: 'center',
        borderColor: palette.grayscale[400],
        padding: spacing(0.5, 1.5),
        height: spacing(5),
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ...typography.body2,
      },
      head: {
        ...typography.body2bold,
      },
    },
  },
};
