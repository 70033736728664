import { Option } from '../../../components/SelectOption';

export enum SolutionDeviceGroupItemField {
  DisplayName = 'displayName',
  Description = 'description',
  CompanyName = 'companyName',
  CompanyId = 'companyId',
  Template = 'modelId',
  Filters = 'filters',
}

export type SolutionDeviceGroupItem = {
  id: string;
  displayName: string;
  description: string;
  companyId: string;
  companyName: string;
  modelId: string;
  writable: boolean;
};

export type SolutionJobsDeviceItem = {
  sequence: number;
  values: [];
};

export type SolutionPropertiesValue = {
  properties: {
    desired: any;
    reported: any;
  };
};

export type SolutionDeviceGroupFilter = {
  property: string;
  propertyName: string;
  operatorValue: string;
  filterValue: string;
};

export enum SolutionDeviceGroupFilterField {
  Property = 'property',
  PropertyName = 'propertyName',
  OperatorValue = 'operatorValue',
  FilterValue = 'filterValue',
}

export type SolutionDeviceGroupDetails = SolutionDeviceGroupItem & {
  filters: SolutionDeviceGroupFilter[];
};

export type SolutionDeviceGroups = {
  data?: SolutionDeviceGroupItem[];
  skipToken?: string;
  hasNextPage?: boolean;
};

export type SolutionDeviceGroupPropertyMeta = {
  enums: Option[] | null;
  operators: Option[];
  propertyId?: string | undefined | null;
};

export type AggregationFunctionsPropertyMeta = {
  aggregationFunctions: Option[];
};

export type SolutionDeviceGroupPropertyItem = SolutionDeviceGroupPropertyMeta &
  AggregationFunctionsPropertyMeta & {
    displayName: string;
    id: string;
    moduleName: string;
    name: string;
    path: string;
    schema: string;
    component: string;
    contentType: string;
    writable: boolean;
  };
