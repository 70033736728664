import React, { memo } from 'react';
import isEqual from 'lodash/isEqual';
import DataTableRowContent from './DataTableRowContent';
import DataTableRowContext from './DataTableRowContext';
import { Row, Column, SelectRowHandler, ToggleGroupHandler } from '../types';

type Props<TRow> = {
  row: TRow;
  columns: Column<TRow>[];
  isGroup?: boolean;
  expandable?: boolean;
  expanded?: boolean;
  selected: boolean;
  onSelect: SelectRowHandler<TRow>;
  onToggleGroup?: ToggleGroupHandler<TRow>;
};

function DataTableRow<TRow extends Row>({ row, ...rest }: Props<TRow>) {
  return (
    <DataTableRowContext row={row}>
      <DataTableRowContent<TRow> row={row} {...rest} />
    </DataTableRowContext>
  );
}

export default memo(
  DataTableRow,
  (prevProps, nextProps) =>
    prevProps.expanded === nextProps.expanded &&
    prevProps.expandable === nextProps.expandable &&
    prevProps.selected === nextProps.selected &&
    isEqual(prevProps.row, nextProps.row)
) as typeof DataTableRow;
