import { useNavigate, useParams } from 'react-router-dom';
import PageCardLayout from 'src/components/PageCardLayout';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import { SolutionType } from '../../types';
import EditSolutionForm from './EditSolutionForm';
import {
  API_SOLUTION_INDUSTRIAL,
  API_SOLUTION_CREATE,
  API_SOLUTION_CREATE_IOTH,
} from '../../constants';
import { getSolutionEditParams } from '../utils';

function NewSolution() {
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const { solutionType } = useParams();
  const goBack = () => navigate(-1);
  const urlPath =
    solutionType?.toUpperCase() === SolutionType.ioTH.toUpperCase()
      ? API_SOLUTION_CREATE_IOTH
      : API_SOLUTION_CREATE;
  const [handleSubmit, loading] = useStatefulApi(
    (params) =>
      pepApiRequest({
        method: RequestMethod.Post,
        url:
          solutionType === SolutionType.Industrial
            ? API_SOLUTION_INDUSTRIAL
            : urlPath,
        params: getSolutionEditParams(params),
      }),

    () => {
      showAlert({
        type: AlertType.Info,
        title: 'New solution is in review.',
        text: 'Solution review process has been started successfully.',
      });
      goBack();
    }
  );

  return (
    <PageCardLayout
      loading={loading}
      title={
        solutionType === SolutionType.Industrial
          ? 'Create new solution - Industrial'
          : 'Create new solution'
      }
    >
      <EditSolutionForm onCancel={goBack} onSubmit={handleSubmit} />
    </PageCardLayout>
  );
}

export default NewSolution;
