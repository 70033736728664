import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FormControl from '@mui/material/FormControl';
import DataTableWrapperActionButton from 'src/components/DataTable/components/DataTableWrapperActionButton';
import { useUserRoles } from 'src/auth';
import { loadDataFromStorage } from 'src/utils';
import { SolutionType } from '../../types';

type Props = {
  onRevokeCertificateClick: () => void;
  onDeleteClick: () => void;
  onAuthoriseClick: () => void;
  onAssociateCompanyClick: () => void;
  canRevoke?: boolean;
  canAuthorise?: boolean;
};

function MoreActionsMenu({
  onRevokeCertificateClick,
  onDeleteClick,
  onAuthoriseClick,
  onAssociateCompanyClick,
  canRevoke = true,
  canAuthorise = true,
}: Props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);
  const { isPlatformReader, isPlatformEngineer } = useUserRoles();
  const solutionType = loadDataFromStorage('solutionType');
  const options = [
    {
      id: 'delete',
      label: 'Delete',
      disabled: isPlatformReader || isPlatformEngineer,
      onClick: () => onDeleteClick(),
    },
    {
      id: 'revokeCertificate',
      label: 'Revoke Certificate',
      disabled: !canRevoke || isPlatformReader || isPlatformEngineer,
      onClick: () => onRevokeCertificateClick(),
    },
    {
      id: 'authorise',
      label: 'Authorisation request',
      disabled: !canAuthorise || isPlatformReader || isPlatformEngineer,
      onClick: () => onAuthoriseClick(),
    },
  ];
  const iotHActionOptions = [
    ...options,
    {
      id: 'associateCompany',
      label: 'Associate to a company',
      onClick: () => onAssociateCompanyClick(),
      disabled: isPlatformEngineer,
    },
  ];

  const actionOptions =
    solutionType.toUpperCase() === SolutionType.ioTH.toUpperCase()
      ? iotHActionOptions
      : options;
  const handleOpen = (event?: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (callback: () => void) => {
    handleClose();
    callback();
  };

  return (
    <FormControl>
      <DataTableWrapperActionButton
        data-testid="more-actions-menu-btn"
        id="more-actions-menu-btn"
        aria-controls="more-actions-menu"
        aria-haspopup="true"
        variant="outlined"
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={handleOpen}
        endIcon={isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      >
        {'More Actions'}
      </DataTableWrapperActionButton>
      <Menu
        id="more-actions-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'more-actions-menu-btn',
        }}
      >
        {actionOptions.map(({ id, label, onClick, disabled }) => (
          <MenuItem
            sx={{
              px: ({ spacing }) => spacing(0.5),
              padding: '7px 8.95px',
            }}
            data-testid={`more-actions-menu-link-${id}`}
            key={id}
            disabled={disabled || false}
            onClick={() => handleMenuItemClick(onClick)}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </FormControl>
  );
}

export default MoreActionsMenu;
