import isArray from 'lodash/isArray';
import { Option, OptionValue } from 'src/components/SelectOption';
import { SelectedOptions, SelectedValue } from './types';

export const mapOptionsToValue = <T extends OptionValue>(
  selectedOptions: SelectedOptions<T>
): SelectedValue<T> => {
  const defaultValue = '' as T;
  const result = isArray(selectedOptions)
    ? selectedOptions.map(({ value }) => value)
    : selectedOptions?.value;

  return result ?? defaultValue;
};

export const mapValueToOptions = <T extends OptionValue>(
  selectedValue: SelectedValue<T>,
  options: readonly Option<T>[],
  multiple?: boolean
): SelectedOptions<T> => {
  const defaultValue = multiple ? [] : null;
  const result = isArray(selectedValue)
    ? options.filter(({ value }) => selectedValue.includes(value))
    : options.find(({ value }) => value === selectedValue);

  return result ?? defaultValue;
};

export const mapValueToOptionsFreeSolo = <T extends OptionValue>(
  selectedValue: SelectedValue<T>,
  options: readonly Option<T>[],
  multiple?: boolean,
  freeSolo?: boolean
): SelectedOptions<T> => {
  const defaultValue = multiple ? [] : null;
  const defaultfreeSolo = freeSolo ?? false;

  let matchingOptions: Option<T>[] = [];
  if (isArray(selectedValue)) {
    matchingOptions = options.filter(({ value }) =>
      selectedValue.includes(value)
    );
  } else {
    matchingOptions = options.filter(({ value }) => value === selectedValue);
  }

  let nonMatchingValues: Option<T>[] = [];
  if (defaultfreeSolo) {
    if (isArray(selectedValue)) {
      nonMatchingValues = selectedValue
        .filter((value) => !options.some((option) => option.value === value))
        .map((value) => ({ label: value.toString(), value }));
    } else if (!options.some((option) => option.value === selectedValue)) {
      nonMatchingValues = [
        { label: selectedValue?.toString() || '', value: selectedValue as T },
      ];
    }
  }
  return [...matchingOptions, ...nonMatchingValues] || defaultValue;
};
