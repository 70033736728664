import React from 'react';
import { NestedRouter, RouterBreadcrumbs } from 'src/router';
import { routes } from './routes';

function Sites() {
  return (
    <>
      <RouterBreadcrumbs routes={routes} />
      <NestedRouter routes={routes} />
    </>
  );
}

export default Sites;
