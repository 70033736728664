import { useParams } from 'react-router-dom';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import ModalDialog, {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { generateApiPath } from 'src/utils';
import { useStoreUnitActions } from 'src/redux/helpers';
import solutionsStore from '../../redux';
import { API_WORKLOAD_CONFIG_DELETE } from '../../constants';
import { SolutionEdgeWorkloadItem } from '../../types';

type Props = {
  item: SolutionEdgeWorkloadItem;
  open: boolean;
  onClose: ModalCloseHandler;
};

function SolutionEdgeWorkloadDeleteModal({ item, open, onClose }: Props) {
  const { solutionId } = useParams();
  const repoName = item?.name;
  const { showAlert } = useAlert();
  const actions = useStoreUnitActions(solutionsStore);
  const [handleDelete, loading] = useStatefulApi(
    () =>
      pepApiRequest({
        method: RequestMethod.Delete,
        url: generateApiPath(API_WORKLOAD_CONFIG_DELETE, {
          solutionId,
          repoName,
        }),
      }),
    () => {
      showAlert({
        type: AlertType.Info,
        title: `${repoName} is deleted`,
        text: 'Git Repo has been deleted successfully',
      });
      onClose(null, ModalCloseReason.completeAction);
      actions.edgeWorkloadConfigurations(solutionId);
    }
  );

  return (
    <ModalDialog
      open={open}
      title="Delete Git Repository"
      loading={loading}
      onClose={onClose}
      onConfirm={handleDelete}
      cancelBtnText="No"
      confirmBtnText="Yes"
      maxWidth="sm"
      fullWidth
    >
      Are you sure you want to delete the Git Repository?<br></br>
      <b>{repoName}</b>
    </ModalDialog>
  );
}

export default SolutionEdgeWorkloadDeleteModal;
