import { FormArrayRow, FormArrayRowProps } from 'src/components/Form';
import { getSubscriptionFilterFields } from '../formFields';

function SolutionSubscriptionEditFormRow({
  childFields,
  update,
  values,
  ...rest
}: FormArrayRowProps) {
  return <FormArrayRow childFields={getSubscriptionFilterFields()} {...rest} />;
}

export default SolutionSubscriptionEditFormRow;
