export const colors = {
  blue: {
    900: '#001C4A',
    700: '#003182',
    500: '#0047BA',
    400: '#3381FF',
    300: '#6690D5',
    200: '#99B5E3',
    100: '#E5ECF8',
  },
  cyan: {
    900: '#004058',
    700: '#00709B',
    500: '#00A0DD',
    300: '#66C6EA',
    200: '#99D9F1',
    100: '#E5F5FC',
  },
  green: {
    900: '#3A4E1E',
    700: '#658835',
    500: '#91C24C',
    300: '#BDDA93',
    200: '#D3E7B7',
    100: '#F4F9ED',
  },
  orange: {
    900: '#643E0E',
    700: '#AF6D19',
    500: '#F99C24',
    300: '#FBC37B',
    200: '#FDD7A7',
    100: '#FFF5E8',
  },
  pink: {
    900: '#5C023B',
    700: '#A10568',
    500: '#E50695',
    300: '#EF69BF',
    200: '#F59BD4',
    100: '#FDE5F4',
  },
  red: {
    900: '#580C15',
    700: '#991525',
    500: '#DB1E36',
    300: '#E97886',
    200: '#F1A5AE',
    100: '#FCE8EA',
  },
  yellow: {
    500: '#F6EB39',
  },
  grayscale: {
    900: '#1E1E1E',
    800: '#5A5A5A',
    700: '#626262',
    500: '#A5A5A5',
    400: '#E8E8E8',
    300: '#E9E9E9',
    200: '#F3F3F3',
    100: '#FFFFFF',
  },
};
