import { useEffect, useState } from 'react';
import ModalDialog, {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { useForm } from 'src/components/Form';
import { useAlert, AlertType } from 'src/components/AlertsProvider';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { generatePath, useParams } from 'react-router-dom';
import { useStoreUnitActions } from 'src/redux/helpers';
import {
  API_SOLUTION_SITES_CLUSTERS,
  API_SOLUTION_SITES_ACTIVE,
} from '../../constants';
import { solutionSiteAssignFormFields } from '../formFields';
import solutionsStore, { useSolutionSitesList } from '../../redux';
import { getOutputFormat } from '../utils';
import { getParenLabelAndChildren } from './SolutionSiteOptions';

interface Item {
  id: string;
  name: string;
  sector: string;
  description: string;
  createdBy: string;
  modifiedBy: string;
  status: string;
  solutionIds?: string[];
  cluster?: Cluster;
  clusters?: Cluster[];
  observability?: string;
}

interface Cluster {
  id: string;
  name: string;
  status: string;
}

type Props = {
  open: boolean;
  onClose: ModalCloseHandler;
};

function SolutionSitesAssignModal({ open, onClose }: Props) {
  const { showAlert } = useAlert();
  const [data, solutionSitesloading] = useSolutionSitesList();
  const actions = useStoreUnitActions(solutionsStore);
  const [optionsData, setOptionsData] = useState<
    {
      parentId: string;
      parentLabel: string;
      children: { label: string; value: string }[];
    }[]
  >();
  const [customParams, setCustomParams] = useState({});
  const [optionsLoading, setOptionsLoading] = useState<boolean | undefined>(
    true
  );
  useEffect(() => {
    actions.sites(solutionId);
  }, []);
  useEffect(() => {
    try {
      pepApiRequest({
        method: RequestMethod.Get,
        url: generatePath(API_SOLUTION_SITES_ACTIVE, { solutionId }),
      }).then((item) => {
        const response = getParenLabelAndChildren(item.items, data);
        setOptionsData(response);
        setOptionsLoading(false);
      });
    } catch (error) {
      setOptionsLoading(false);
    }
  }, []);
  const { solutionId } = useParams();

  const handleAssignSiteChange = (value) => {
    setCustomParams(value);
  };

  const [onSubmit, loading] = useStatefulApi(
    (params) =>
      pepApiRequest({
        method: RequestMethod.Put,
        url: generatePath(API_SOLUTION_SITES_CLUSTERS, { solutionId }),
        params: getOutputFormat(optionsData, customParams),
      }),
    () => {
      showAlert({
        type: AlertType.Success,
        title: 'Site assigned',
        text: 'New Site has been assigned successfully.',
      });
      onClose(null, ModalCloseReason.completeAction);
    }
  );

  const [form, handleSubmit] = useForm({
    fields: solutionSiteAssignFormFields(optionsData, handleAssignSiteChange),
    onSubmit,
  });

  return (
    <ModalDialog
      open={open}
      title="Assign New"
      loading={loading || optionsLoading || solutionSitesloading}
      onClose={onClose}
      onConfirm={handleSubmit}
      confirmBtnText="Submit"
      maxWidth="sm"
      fullWidth
    >
      {form}
    </ModalDialog>
  );
}

export default SolutionSitesAssignModal;
