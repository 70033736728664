import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { useStoreUnitActions } from 'src/redux/helpers';
import { RequestMethod, pepApiRequest, useStatefulApi } from 'src/services';
import { generateApiPath, loadDataFromStorage } from 'src/utils';
import SolutionDeviceGroupEditModal from './SolutionDeviceGroupEditModal';
import {
  API_DEVICE_GROUP_ITEM,
  API_DEVICE_GROUP_ITEM_IOTH,
} from '../../constants';
import solutionsStore, {
  useSolutionDeviceGroupDetails,
  useSolutionDeviceGroupProperties,
} from '../../redux';
import { mapDeviceGroupToRequestFormat } from '../utils';
import { SolutionDeviceGroupItem, SolutionType } from '../../types';

type Props = {
  item: SolutionDeviceGroupItem;
  open: boolean;
  onClose: ModalCloseHandler;
};

export default function SolutionDeviceGroupEditContainer({
  item,
  open,
  onClose,
}: Props) {
  const { solutionId } = useParams();
  const actions = useStoreUnitActions(solutionsStore);
  const { showAlert } = useAlert();
  const [properties] = useSolutionDeviceGroupProperties();
  const [details, loading] = useSolutionDeviceGroupDetails();

  const [onSubmit, saving] = useStatefulApi(
    ({ companyId, ...params }) => {
      const solutionType = loadDataFromStorage('solutionType');
      return pepApiRequest({
        method: RequestMethod.Put,
        url: generateApiPath(
          solutionType.toUpperCase() === SolutionType.ioTH.toUpperCase()
            ? API_DEVICE_GROUP_ITEM_IOTH
            : API_DEVICE_GROUP_ITEM,
          {
            groupId: item.id,
            companyId: companyId || 'all',
            solutionId,
          }
        ),
        params: mapDeviceGroupToRequestFormat(params, properties),
      });
    },
    () => {
      showAlert({
        type: AlertType.Info,
        title: `${item.displayName} is updated`,
        text: 'Solution device group has been successfully updated.',
      });
      onClose(null, ModalCloseReason.completeAction);
    }
  );

  useEffect(() => {
    actions.deviceGroupDetails({ solutionId, groupId: item.id });
  }, [solutionId, item]);

  return (
    <SolutionDeviceGroupEditModal
      item={details}
      open={open}
      title={`Edit device group: ${item.displayName}`}
      onClose={onClose}
      onSubmit={onSubmit}
      loading={loading || saving}
    />
  );
}
