import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSyncState } from 'src/hooks';
import { useStoreUnitActions } from 'src/redux/helpers';
import solutionsStore, {
  useSolutionCompaniesOptions,
  useSolutionDeviceTemplateOptions,
} from '../redux';
import {
  getDeviceGroupFilterFields,
  getDeviceGroupMainFields,
} from './formFields';
import {
  SolutionDeviceGroupDetails,
  SolutionDeviceGroupItemField,
} from '../types';

export const useSolutionDeviceGroupEditFormFields = (
  item?: SolutionDeviceGroupDetails
) => {
  const actions = useStoreUnitActions(solutionsStore);
  const { solutionId } = useParams();
  const [modelId, setModelId] = useSyncState<string | undefined>(item?.modelId);
  const [companyOptions, companyLoading] = useSolutionCompaniesOptions();
  const [templateOptions, templateLoading] = useSolutionDeviceTemplateOptions();
  const onTemplateChange = ({ target }, { resetField }) => {
    setModelId(target.value);
    resetField(SolutionDeviceGroupItemField.Filters, { defaultValue: [] });
  };

  useEffect(() => {
    actions.companies(solutionId);
  }, [solutionId]);
  useEffect(() => {
    if (modelId) {
      actions.deviceGroupProperties({ solutionId, modelId });
    }
  }, [solutionId, modelId]);

  return [
    getDeviceGroupMainFields({
      companySelectDisabled: !!item,
      companyOptions,
      companyLoading,
      templateOptions,
      templateLoading,
      onTemplateChange,
    }),
    getDeviceGroupFilterFields({}),
  ];
};
