import { useParams } from 'react-router-dom';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import ModalDialog, {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { generateApiPath } from 'src/utils';
import { API_SOLUTION_COSMOS_SUBSCRIPTION_DELETE } from '../../constants';
import { SolutionCosmosSubscriptionItem } from '../../types';

type Props = {
  item: SolutionCosmosSubscriptionItem;
  open: boolean;
  onClose: ModalCloseHandler;
};

function SolutionCosmosSubscriptionDeleteModal({ item, open, onClose }: Props) {
  const { solutionId } = useParams();
  const { showAlert } = useAlert();
  const [handleRemove, loading] = useStatefulApi(
    () =>
      pepApiRequest({
        method: RequestMethod.Delete,
        url: generateApiPath(API_SOLUTION_COSMOS_SUBSCRIPTION_DELETE, {
          solutionId,
          subscriptionId: item?.id,
        }),
      }),
    () => {
      showAlert({
        type: AlertType.Info,
        title: 'Subscription deleted',
        text: 'Cosmos subscription is deleted successfully.',
      });
      onClose(null, ModalCloseReason.completeAction);
    }
  );

  return (
    <ModalDialog
      open={open}
      title="Delete Subscription"
      loading={loading}
      onClose={onClose}
      onConfirm={handleRemove}
      cancelBtnText="No"
      confirmBtnText="Yes"
    >
      Are you sure you want to delete the subscription?&nbsp;
      <br />
      <b>{item?.name}</b>
    </ModalDialog>
  );
}

export default SolutionCosmosSubscriptionDeleteModal;
