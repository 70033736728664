import { generatePath } from 'react-router-dom';
import { createDatasetSlice } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import {
  API_DEVICE_DEP_JOB_LOG,
  API_DEVICE_DEP_JOB_STATUS,
  SOLUTIONS_STORE,
  SOLUTIONS_STORE_DEVICE_BULK_DEPLOYMENT,
} from '../constants';
import { mapDeviceBulkDeploymentData } from './utils';

const getDeviceBulkDeploymentStatistics = ({ jobId }) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: generatePath(API_DEVICE_DEP_JOB_STATUS, { jobId }),
  });

const getDeviceBulkDeploymentLog = ({ jobId }) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: generatePath(API_DEVICE_DEP_JOB_LOG, { jobId }),
  });

const api = (params) =>
  Promise.all([
    getDeviceBulkDeploymentStatistics(params),
    getDeviceBulkDeploymentLog(params),
  ]).then(mapDeviceBulkDeploymentData);

const SolutionDeviceBulkDeploymentSlice = createDatasetSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTIONS_STORE_DEVICE_BULK_DEPLOYMENT,
});

export default SolutionDeviceBulkDeploymentSlice;
