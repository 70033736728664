import { Box, SxProps, Typography } from '@mui/material';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import { FormControlLabelProps } from '@mui/material/FormControlLabel';
import toString from 'lodash/toString';
import { ForwardedRef, forwardRef, ReactNode } from 'react';

type Props = FormControlProps &
  Pick<FormControlLabelProps, 'labelPlacement'> & {
    label?: ReactNode;
    value?: boolean;
    displayValue?: boolean;
    helperText?: string;
    labelStyle?: SxProps;
  };

function ReadonlyField(
  {
    value = false,
    label,
    labelPlacement,
    displayValue = false,
    helperText = '',
    labelStyle,
    ...rest
  }: Props,
  ref: ForwardedRef<HTMLButtonElement>
) {
  return (
    <FormControl variant="standard" {...rest}>
      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            flexDirection: 'row',
            marginBottom: '16px',
          }}
        >
          <Typography sx={labelStyle ?? { flex: 1 }} variant="body2">
            {toString(label)}
          </Typography>
          {displayValue ? (
            <Typography
              style={{
                fontSize: '16px',
              }}
            >
              {value}
            </Typography>
          ) : (
            <Typography
              style={{ flex: 1 }}
              variant="body2"
              sx={{ fontWeight: 'bold' }}
            >
              {value ? 'Enabled' : 'Disabled'}
            </Typography>
          )}
        </Box>
        {!value && (
          <span dangerouslySetInnerHTML={{ __html: helperText || '' }} />
        )}
        {/* {!value && helperText} */}
        {/* {helperText} */}
      </>
    </FormControl>
  );
}

export default forwardRef(ReadonlyField);
