import { useParams } from 'react-router-dom';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { generateApiPath } from 'src/utils';
import { useStoreUnitActions } from 'src/redux/helpers';
import { API_UPDATE_ADLS_SUBSCRIPTIONS } from '../../constants';
import { SolutionAdlsSubscriptionItem } from '../../types';
import { getAdlsSubscriptionItemReqParams } from '../utils';
import SolutionAdlsSubscriptionEditModal from './SolutionAdlsSubscriptionEditModal';
import solutionsStore from '../../redux';

type Props = {
  item: SolutionAdlsSubscriptionItem;
  open: boolean;
  onClose: ModalCloseHandler;
};

function SolutionAdlsSubscriptionEditContainer({ item, open, onClose }: Props) {
  const { showAlert } = useAlert();
  const { solutionId } = useParams();
  const actions = useStoreUnitActions(solutionsStore);
  const [handleSubmit, loading] = useStatefulApi<SolutionAdlsSubscriptionItem>(
    (params) =>
      pepApiRequest({
        method: RequestMethod.Put,
        url: generateApiPath(API_UPDATE_ADLS_SUBSCRIPTIONS, {
          solutionId: item?.solutionId,
          subscriptionId: item?.id,
        }),
        params: getAdlsSubscriptionItemReqParams(params),
      }),
    () => {
      showAlert({
        type: AlertType.Info,
        title: `${item?.name} is updated`,
        text: 'ADLS subscription has been successfully updated.',
      });
      onClose(null, ModalCloseReason.completeAction);
      actions.adlsSubscriptionById(solutionId);
    }
  );

  return (
    <SolutionAdlsSubscriptionEditModal
      item={item}
      title={`Edit ADLS Subscription`}
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      loading={loading}
    />
  );
}

export default SolutionAdlsSubscriptionEditContainer;
