import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';
import { Row } from '../types';

type RowClickHandler = () => void;

type ContextProps = [
  RowClickHandler,
  Dispatch<SetStateAction<RowClickHandler>>,
  Row?
];

export const defaultRowClickHandler = () => null;

export const RowContext = createContext<ContextProps>([
  defaultRowClickHandler,
  defaultRowClickHandler,
]);

type Props<TRow extends Row> = {
  children: ReactNode;
  row: TRow;
};

function DataTableRowContext<TRow extends Row>({ children, row }: Props<TRow>) {
  const rowState = useState<RowClickHandler>(defaultRowClickHandler);

  return (
    <RowContext.Provider value={[...rowState, row]}>
      {children}
    </RowContext.Provider>
  );
}

export default DataTableRowContext;
