import { createDatasetSlice } from 'src/redux/helpers';
import { RequestMethod, pepApiRequest } from 'src/services';
import { generateApiPath } from 'src/utils';
import {
  API_SOLUTIONS_IOTHUB_DEVICE_MODULES,
  SOLUTIONS_STORE,
  SOLUTIONS_STORE_DEVICE_MODULES,
} from '../constants';
import { mapSolutionDevicesModulesData } from './utils';

const api = ({ solutionId, deviceId }) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: generateApiPath(API_SOLUTIONS_IOTHUB_DEVICE_MODULES, { solutionId }),
    queryParams: { deviceId },
  }).then((items) => mapSolutionDevicesModulesData(items));

const solutionsDeviceModulesSlice = createDatasetSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTIONS_STORE_DEVICE_MODULES,
});

export default solutionsDeviceModulesSlice;
