import { useState } from 'react';

type StatefulApiError = Record<string, any> | string | null;

type StatefulApiReturnParams<P> = [
  (params: P) => void,
  boolean,
  StatefulApiError
];

export const useStatefulApi = <P = any, R = any, M = any>(
  apiRequest: (params: P, meta?: M) => any,
  onSuccess?: (response: R, params: P, meta?: M) => void,
  onError?: (error: StatefulApiError) => void
): StatefulApiReturnParams<P> => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<StatefulApiError>(null);

  const sendRequest = (params: P, meta?: M) => {
    setError(null);
    setLoading(true);
    apiRequest(params, meta)
      .then((response: R) => {
        setLoading(false);
        if (onSuccess) {
          onSuccess(response, params, meta);
        }
      })
      .catch((error: StatefulApiError) => {
        setError(error);
        setLoading(false);
        if (onError) {
          onError(error);
        }
      });
  };

  return [sendRequest, loading, error];
};
