export const ROOT = '/';
export const COMPANIES = '/companies';
export const DASHBOARD = '/dashboard';
export const OTP = '/otp';
export const USERS = '/users';
export const SETTINGS = '/settings';
export const SOLUTIONS = '/solutions';
export const SITES = '/sites';
export const LOGIN_CALLBACK = '/login/callback';
export const HEALTH_CHECK = '/health-check';
export const MONITORING = '/monitoring';
export const Docs = '/help-center';
