import React, { ReactNode } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import CssBaseline from '@mui/material/CssBaseline';
import AlertsProvider from 'src/components/AlertsProvider';
import { AuthProvider } from 'src/auth';
import store from 'src/redux/store';
import theme from 'src/theme';

type Props = {
  children: ReactNode;
};

function AppProviders({ children }: Props) {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <AuthProvider>
            <CssBaseline />
            <AlertsProvider>{children}</AlertsProvider>
          </AuthProvider>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}

export default AppProviders;
