import * as Yup from 'yup';
import { TextField } from '@mui/material';
import { SiteItemField } from '../types';

export const SiteFormFields = (isEditMode?: boolean) => [
  {
    name: SiteItemField.Sector,
    label: 'Sector',
    placeholder: 'Enter Sector Name',
    Component: TextField,
    validation: Yup.string().required('Sector Name is required'),
    required: true,
  },
  {
    name: SiteItemField.SiteName,
    label: 'Site Name',
    placeholder: 'Enter Site Name',
    Component: TextField,
    validation: Yup.string().required('Site Name is required'),
    required: true,
  },
  {
    name: SiteItemField.Description,
    label: 'Description',
    placeholder: 'Enter Description',
    Component: TextField,
    multiline: true,
    rows: 3,
  },
  {
    name: isEditMode ? SiteItemField.ModifiedBy : SiteItemField.CreatedBy,
    sx: { display: 'none' },
    Component: TextField,
    required: false,
  },
];
