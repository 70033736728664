import React from 'react';
import Typography from '@mui/material/Typography';

type Props = {
  value?: any;
  isGroup?: boolean;
};

export default function DataTableGroupColCell({ value, isGroup }: Props) {
  return isGroup ? <Typography variant="body2bold">{value}</Typography> : null;
}
