import {
  SolutionAdlsSubscriptionItem,
  SolutionCosmosSubscriptionItem,
  SolutionFileSubscriptionItem,
  SolutionSubscriptionItem,
} from '../types';

export const getSubscriptionItemReqParams = ({
  target,
  Authorization,
  ...rest
}: Partial<SolutionSubscriptionItem>) => ({
  target: {
    url: target,
    headers: {
      Authorization,
    },
  },
  ...rest,
});

export const getFileSubscriptionItemReqParams = (params) => ({
  name: params?.fileSubscriptionName,
  DestinationType: params?.DestinationType,
  DestinationConnectionString: params?.DestinationConnectionString,
  folderStructure: params?.folderStructure,
});

export const mapApiToView = (item?: SolutionFileSubscriptionItem) => ({
  id: item?.id,
  name: item?.name,
  solutionId: item?.solutionId,
  DestinationType: item?.destination?.destinationType,
  DestinationConnectionString: item?.destination?.destinationURL,
  folderStructure: item?.folderStructure,
  fileSubscriptionName: item?.name,
});

export const getAdlsSubscriptionItemReqParams = (params) => ({
  name: params?.adlsSubscriptionName,
  format: params?.format,
  containerSaasURL: params?.containerSaasURL,
  folderStructure: params?.folderStructure,
  filterCondition: params?.adlsFilterCondition,
  filters: params?.adlsFilters,
});

export const mapAdlsApiToView = (item?: SolutionAdlsSubscriptionItem) => ({
  ...item,
  adlsFilters: item?.filters,
  adlsSubscriptionName: item?.name,
  adlsFilterCondition: item?.filterCondition,
});

export const getCosmosSubscriptionItemReqParams = (params) => ({
  id: params?.id,
  name: params?.cosmosSubscriptionName,
  connectionString: params?.connectionString,
  databaseName: params?.databaseName,
  container: params?.container,
  partitionkey: params?.partitionkey,
  filterCondition: params?.cosmosFilterCondition,
  filters: params?.cosmosFilters,
});

export const mapCosmosApiToView = (item?: SolutionCosmosSubscriptionItem) => ({
  id: item?.id,
  name: item?.name,
  solutionId: item?.solutionId,
  connectionString: item?.connectionString,
  databaseName: item?.databaseName,
  container: item?.container,
  partitionkey: item?.partitionkey,
  filters: item?.filters,
  cosmosFilters: item?.filters,
  cosmosSubscriptionName: item?.name,
  cosmosFilterCondition: item?.filterCondition,
});
