import { DatasetUnit } from 'src/redux/helpers';

export enum OtpItemField {
  Key = 'key',
  RecipientEmail = 'recipientEmail',
  UtcTimeShift = 'utcTimeShift',
  Action = 'action',
}

export type OtpItem = {
  key: string;
  owner?: string;
  recipientEmail: string;
  utcTimeShift: string;
};

export type OtpList = {
  data?: OtpItem[];
};

export type OtpStore = DatasetUnit<OtpList>;
