import { useUserRolesArr } from 'src/auth';
import { RouteProps } from 'src/router/types';
import { isUserRoleAllowed } from 'src/router/utils';
import { routes } from './routes';

export const useUserRoutes = <T extends RouteProps = RouteProps>(
    useNavLinks?: boolean,
    useDashboardLinks?: boolean
): T[] => {
    const userRoles = useUserRolesArr();

    return routes.filter(
        ({ meta, allowedUserRoles }) =>
            isUserRoleAllowed(userRoles, allowedUserRoles) &&
            ((!useNavLinks && !useDashboardLinks) ||
                (useNavLinks && meta?.navLink) ||
                (useDashboardLinks && meta?.dashboardLink))
    ) as T[];
};