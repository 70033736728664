import { generatePath } from 'react-router-dom';
import { createDatasetSlice, ResponseMapping } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import {
  API_COMPANY_ITEM,
  COMPANIES_STORE,
  COMPANIES_STORE_DETAILS,
} from '../constants';

const api = (companyId: string) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: generatePath(API_COMPANY_ITEM, { companyId }),
  });

const companiesDetailsSlice = createDatasetSlice({
  api,
  storeName: COMPANIES_STORE,
  actionName: COMPANIES_STORE_DETAILS,
  responseMapping: ResponseMapping.Default,
});

export default companiesDetailsSlice;
