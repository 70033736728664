import React, {
  ChangeEventHandler,
  ForwardedRef,
  forwardRef,
  ReactNode,
} from 'react';
import remove from 'lodash/remove';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { SxProps } from 'src/theme/types';
import FileUploadInputSelection from './components/FileUploadInputSelection';
import { getInitialValue } from './utils';

type Props = FormControlProps & {
  onChange?: ChangeEventHandler<HTMLInputElement>;
  setValue?: UseFormSetValue<FieldValues>;
  value?: File | File[];
  helperText?: ReactNode;
  label?: ReactNode;
  multiple?: boolean;
};

const getInputStyle = (isDragActive: boolean, error?: boolean): SxProps => ({
  color: 'neutral.main',
  height: ({ spacing }) => spacing(10),
  width: '100%',
  p: 1.5,
  mr: 2,
  borderColor: error ? 'error.main' : 'grayscale.300',
  borderStyle: 'dashed',
  borderWidth: ({ spacing }) => spacing(0.2),
  borderRadius: ({ spacing }) => spacing(0.4),
  ...(isDragActive && {
    borderColor: 'primary.light',
    backgroundColor: ({ palette, alpha }) => alpha(palette.neutral.main, 0.1),
  }),
});

const labelStyle = {
  backgroundColor: 'white',
  px: 0.5,
};

function FileUploadInput(
  {
    id,
    onChange,
    value,
    setValue,
    placeholder,
    error,
    helperText,
    label,
    multiple,
    ...rest
  }: Props,
  ref: ForwardedRef<HTMLInputElement>
) {
  const files = getInitialValue(value);

  const onDrop = (newFiles: File[]) => {
    handleValueChange(multiple ? [...files, ...newFiles] : newFiles);
  };
  const onDeleteFile = (file: File) => {
    remove(files, file);
    handleValueChange(files);
  };
  const handleValueChange = (files: File[]) => {
    if (setValue && id && files) {
      setValue(id, multiple ? files : files[0], { shouldValidate: true });
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple,
    onDrop,
  });

  return (
    <FormControl
      data-testid="file-upload-input-container"
      error={error}
      {...rest}
    >
      {label && (
        <InputLabel sx={labelStyle} htmlFor={id} shrink>
          {label}
        </InputLabel>
      )}
      <Box id={id} sx={getInputStyle(isDragActive, error)} {...getRootProps()}>
        <input
          data-testid="file-upload-input"
          ref={ref}
          onChange={onChange}
          {...getInputProps()}
        />
        {files.length ? (
          <FileUploadInputSelection
            error={error}
            files={files}
            onDeleteFile={onDeleteFile}
          />
        ) : (
          <Typography
            data-testid="file-upload-input-default-message"
            variant="body2"
          >
            <UploadFileOutlinedIcon
              color="neutral"
              fontSize="small"
              sx={{ mr: 0.5 }}
            />
            Drop your file(s) here or <Link>browse</Link> from your computer
          </Typography>
        )}
      </Box>
      <FormHelperText>{helperText || placeholder}</FormHelperText>
    </FormControl>
  );
}

export default forwardRef(FileUploadInput);
