import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Companies from 'src/pages/Companies';
import Dashboard from 'src/pages/Dashboard';
import HealthCheck from 'src/pages/HealthCheck';
import Settings from 'src/pages/Settings';
import Sites from 'src/pages/Sites';
import Solutions from 'src/pages/Solutions';
import Otp from 'src/pages/Otp';
import Users from 'src/pages/Users';
import Monitoring from 'src/pages/Monitoring';
import { AuthCallback } from 'src/auth';
import { ReactComponent as CompaniesIcon } from 'src/assets/icons/companies.svg';
import { ReactComponent as DashboardIcon } from 'src/assets/icons/dashboard.svg';
import { ReactComponent as SolutionsIcon } from 'src/assets/icons/solutions_1.svg';
import { ReactComponent as OtpIcon } from 'src/assets/icons/otp.svg';
import { ReactComponent as SitesIcon } from 'src/assets/icons/site.svg';
import { ReactComponent as MonitorIcon } from 'src/assets/icons/monitoring.svg';
import DefaultRedirect from './components/DefaultRedirect';
import * as ROUTES from './constants';
import { RootRouteProps } from './types';
import FooterRoutes from '../components/SideBar/router/SideBarFooter';

export const routes: RootRouteProps[] = [
  {
    path: ROUTES.DASHBOARD,
    component: Dashboard,
    exact: true,
    meta: {
      title: 'Dashboard',
      navLink: true,
      icon: DashboardIcon,
    },
  },
  {
    path: ROUTES.SOLUTIONS,
    component: Solutions,
    meta: {
      title: 'Solutions',
      description: 'Solutions list view, add new, etc.',
      dashboardLink: true,
      navLink: true,
      icon: SolutionsIcon,
    },
  },
  {
    path: ROUTES.OTP,
    component: Otp,
    meta: {
      title: 'OTP Management',
      description: 'otps list view, add new, etc.',
      dashboardLink: true,
      navLink: true,
      icon: OtpIcon,
    },
  },
  {
    path: ROUTES.USERS,
    component: Users,
    meta: {
      title: 'Users',
      description: 'Users list view',
      dashboardLink: true,
      navLink: true,
      icon: GroupOutlinedIcon,
    },
  },
  {
    path: ROUTES.MONITORING,
    component: Monitoring,
    meta: {
      title: 'Monitoring',
      description: 'Dashboard list  ',
      dashboardLink: true,
      navLink: true,
      icon: MonitorIcon,
    },
  },
  {
    path: ROUTES.SETTINGS,
    component: Settings,
    meta: {
      title: 'Settings',
      description: 'Change user personal settings',
      dashboardLink: true,
      disabled: true,
      navLink: true,
      icon: SettingsOutlinedIcon,
    },
  },
  {
    path: ROUTES.COMPANIES,
    component: Companies,
    meta: {
      title: 'Companies',
      description: 'Companies list view, add new, etc.',
      dashboardLink: true,
      navLink: true,
      icon: CompaniesIcon,
    },
  },
  {
    path: ROUTES.SITES,
    component: Sites,
    meta: {
      title: 'Sites',
      description: 'Sites List, Add New, Edit',
      dashboardLink: true,
      navLink: true,
      icon: SitesIcon,
    },
  },
  {
    path: '',
    component: FooterRoutes,
  },
  {
    path: ROUTES.HEALTH_CHECK,
    component: HealthCheck,
    isPublic: true,
  },
  {
    path: ROUTES.LOGIN_CALLBACK,
    component: AuthCallback,
    isPublic: true,
  },
  {
    path: ROUTES.ROOT,
    component: DefaultRedirect,
    exact: true,
  },
];
