import { ChangeEvent, SyntheticEvent } from 'react';
import { UseFormReturn } from 'react-hook-form';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import { SxProps } from 'src/theme/types';
import Select from 'src/components/Select';
import { Option } from 'src/components/SelectOption';
import {
  DASHBOARD_Aggregate,
  DASHBOARD_INTERVAL_SIZE,
} from '../../types/solutionDashboard';

const inputStyle: SxProps = {
  margin: ({ spacing }) => spacing(0.5),
  marginRight: ({ spacing }) => spacing(2),
};

type MainFieldsProps = {
  onNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  companyOptions: Option[];
  companyLoading?: boolean;
  onCompanyChange: (event: SyntheticEvent, context: UseFormReturn) => void;
  companySelectDisabled?: boolean;
  deviceGroupOptions: Option[];
  deviceGroupLoading?: boolean;
  onDeviceGroupChange: (event: SyntheticEvent, context: UseFormReturn) => void;
  telemetryOptions;
  telemetryLoading;
  onTelemetryChange: (event: SyntheticEvent) => void;
  onAggregationChange: (event: ChangeEvent<HTMLInputElement>) => void;
  propertyOptions;
  propertiesLoading;
  onPropertyChange: (event: SyntheticEvent, context: UseFormReturn) => void;
  onTimeFrameFromChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onTimeFrameToChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onIntervalSizeChange: (event: SyntheticEvent, context: UseFormReturn) => void;
};

export const getDashboardsMainFields = ({
  onNameChange,
  companyOptions,
  companyLoading,
  onCompanyChange,
  deviceGroupOptions,
  deviceGroupLoading,
  onDeviceGroupChange,
  propertyOptions,
  propertiesLoading,
  onPropertyChange,
  telemetryOptions,
  telemetryLoading,
  onTelemetryChange,
  onAggregationChange,
  onTimeFrameFromChange,
  onTimeFrameToChange,
  onIntervalSizeChange,
}: MainFieldsProps) => [
  {
    name: 'name',
    label: 'Name',
    placeholder: 'Enter the name of the dashboard',
    Component: TextField,
    onChange: onNameChange,
    validation: Yup.string().required('Name is required'),
    required: true,
    sx: inputStyle,
  },
  {
    name: 'companyName',
    label: 'Company Name',
    placeholder: 'Enter the company name',
    Component: Select,
    onChange: onCompanyChange,
    options: companyOptions,
    loading: companyLoading,
    required: true,
    validation: Yup.string().required('Company name is required'),
    sx: inputStyle,
  },
  {
    name: 'deviceGroup',
    label: 'Device Group',
    placeholder: 'Select device group',
    Component: Select,
    onChange: onDeviceGroupChange,
    options: deviceGroupOptions,
    loading: deviceGroupLoading,
    required: true,
    validation: Yup.string().required('Device Group is required'),
    sx: inputStyle,
  },
  {
    name: 'telemetry',
    label: 'Telemetry',
    placeholder: 'Select telemetry',
    Component: Select,
    onChange: onTelemetryChange,
    options: telemetryOptions,
    loading: telemetryLoading,
    required: true,
    sx: inputStyle,
    validation: Yup.string().required(`Telemetry is required`),
  },

  {
    name: 'aggregates',
    label: 'Aggregation',
    placeholder: 'Select Aggregation',
    Component: Select,
    onChange: onAggregationChange,
    options: DASHBOARD_Aggregate,
    validation: Yup.string().required('Aggregation is required'),
    required: true,
    sx: inputStyle,
  },
  {
    name: 'groupby',
    label: 'Group by',
    placeholder: 'Select the group',
    Component: Select,
    onChange: onPropertyChange,
    options: propertyOptions,
    loading: propertiesLoading,
    sx: inputStyle,
  },
  {
    name: 'timeFrameFrom',
    label: 'TimeFrame From',
    placeholder: 'Eg: mm/dd/yyyy hh:mm',
    Component: TextField,
    onChange: onTimeFrameFromChange,
    validation: Yup.string().required('TimeFrame From is required'),
    required: true,
    sx: inputStyle,
  },
  {
    name: 'timeFrameTo',
    label: 'TimeFrame To',
    placeholder: 'Eg: mm/dd/yyyy hh:mm',
    Component: TextField,
    onChange: onTimeFrameToChange,
    validation: Yup.string().required('TimeFrame To is required'),
    required: true,
    sx: inputStyle,
  },
  {
    name: 'intervalSize',
    label: 'Interval Size',
    placeholder: 'Select the interval size',
    Component: Select,
    options: DASHBOARD_INTERVAL_SIZE,
    onChange: onIntervalSizeChange,
    validation: Yup.string().required('Interval Size is required'),
    required: true,
    sx: inputStyle,
  },
];
