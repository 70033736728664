import React, { ComponentType } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useUserRoles } from 'src/auth';
import { navigateToExternalUrl } from 'src/utils';
import { PEP_PLATFORM_SERVICES_URL } from 'src/constants';
import { SxProps } from 'src/theme/types';
import { RouteWithMetaProps, matchRoute } from 'src/router';
import { ReactComponent as PlatformServicesIcon } from 'src/assets/icons/platform-services.svg';
import SideBarDivider from './SideBarDivider';
import SideBarNavItem from './SideBarNavItem';
import { useUserRoutes } from '../router/hooks';

const wrapperStyle: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
  bottom: 0,
  bgcolor: 'primary.dark',
  zIndex: 999,
  height: '178px',
};

const buttonStyle: SxProps = {
  mx: 1.5,
  my: 0.8,
  color: 'blue.300',
};

type Props = {
  toggleOpen: (open: boolean) => void;
  open: boolean;
};

function SideBarFooter({ toggleOpen, open }: Props) {
  const navigate = useNavigate();
  const Icon = open ? ChevronLeftIcon : ChevronRightIcon;
  const { isPlatformOwner } = useUserRoles();
  const routes = useUserRoutes<RouteWithMetaProps>(true);
  const { pathname } = useLocation();

  return (
    <Box sx={wrapperStyle}>
      {routes.map(({ path, meta }) => {
        const { title, icon } = meta;
        const selected = !!matchRoute(pathname.replace('/pepsense', ''), path);
        return (
          <SideBarNavItem
            data-testid={`sidebar-nav-link${path}`}
            key={path}
            selected={selected}
            onClick={() => navigate('/help-center/docs/')}
            title={title}
            icon={icon as ComponentType}
            open={open}
          />
        );
      })}
      {isPlatformOwner && (
        <SideBarNavItem
          data-testid="sidebar-link-platform-services"
          icon={PlatformServicesIcon}
          onClick={() => navigateToExternalUrl(PEP_PLATFORM_SERVICES_URL)}
          title="Platform Service"
          open={open}
        />
      )}
      <SideBarDivider />
      <IconButton
        data-testid="sidebar-toggle-btn"
        sx={buttonStyle}
        onClick={() => toggleOpen(!open)}
      >
        <Icon />
      </IconButton>
    </Box>
  );
}

export default SideBarFooter;
