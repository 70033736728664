import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'src/redux/types';
import { OtpItem } from '../types';

export const useOtpList = (): [OtpItem[], boolean | undefined] => {
  const { data, loading } = useSelector((state: State) => state.otp || {});

  return [useMemo(() => data || [], [data]), useMemo(() => loading, [loading])];
};
