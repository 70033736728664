import React, {
  ElementType,
  forwardRef,
  MouseEvent,
  Ref,
  ReactElement,
} from 'react';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import { OptionValue } from './types';

type Props<T> = {
  component?: ElementType;
  id?: string;
  label: string;
  value: T;
  disabled?: boolean;
  multiple?: boolean;
  selected?: boolean;
  onClick?: (event: MouseEvent<HTMLLIElement>, value?: T) => void;
};

const multipleStyle = {
  '&.Mui-selected:not(:hover)': {
    backgroundColor: 'transparent',
    color: 'currentColor',
  },
  p: 0.6,
};

function SelectOption<T extends OptionValue>(
  { disabled, multiple, label, selected, value, onClick, ...rest }: Props<T>,
  ref
) {
  const handleClick = (event) => onClick && onClick(event, value);

  return (
    <MenuItem
      ref={ref}
      sx={multiple ? multipleStyle : null}
      disabled={disabled}
      selected={selected}
      value={value}
      onClick={handleClick}
      {...rest}
    >
      {multiple && <Checkbox checked={selected} disabled={disabled} />}
      {label}
    </MenuItem>
  );
}

export default forwardRef(SelectOption) as <T extends OptionValue>(
  p: Props<T> & { ref?: Ref<HTMLDivElement> }
) => ReactElement;
