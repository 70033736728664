import { UserClaims } from '@okta/okta-auth-js';
import { DatasetUnit } from 'src/redux/helpers';
import { SolutionFeature } from '../pages/Solutions';

export enum UserRole {
  PlatformOwner = 'Platform Owner',
  PlatformAdmin = 'Platform Admin',
  PlatformUser = 'Platform User',
  PlatformReader = 'Platform Reader',
  PlatformEngineer = 'Platform Engineer',
  SolutionOwner = 'Solution Owner',
  SolutionUser = 'Solution User',
  TenantOwner = 'Tenant Owner',
  TenantUser = 'Tenant User',
}

export type UserFeature = SolutionFeature;

export type UserRolesQueryParams = {
  solutionId?: string;
  companyId?: string;
};

export type UserProfile = Partial<UserClaims> & {
  FirstName?: string;
  LastName?: string;
  email?: string;
  pepappiotroles: UserRole[];
};

export type UserIdentity = {
  id?: string;
  solutionsOwner: string[];
  solutionsUser: string[];
  tenantsOwner: string[];
  tenantsUser: string[];
};

export type UserAuthStore = DatasetUnit<UserIdentity>;
