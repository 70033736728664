import React from 'react';
import BreadCrumbs from 'src/components/BreadCrumbs';
import { useBreadcrumbsConfig } from '../hooks';
import { RouteProps } from '../types';

type Props = {
  routes?: RouteProps[];
};

function RouterBreadcrumbs({ routes }: Props) {
  const breadcrumbsConfig = useBreadcrumbsConfig(routes);

  return <BreadCrumbs sx={{ mb: 4.5 }} items={breadcrumbsConfig} lastActive />;
}

export default RouterBreadcrumbs;
