import React, { ReactNode } from 'react';
import Card, { CardProps } from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { SxProps } from 'src/theme/types';
import { Typography } from '@mui/material';
import PageCardLoader from './components/PageCardLoader';

type Props = CardProps & {
  actionsPanel?: ReactNode;
  children: ReactNode;
  loading?: boolean;
  title?: string;
  content?: ReactNode;
  contentProps?: SxProps;
  headerProps?: SxProps;
};

function PageCardLayout({
  actionsPanel,
  contentProps,
  headerProps,
  children,
  loading,
  title,
  content,
  ...rest
}: Props) {
  const showHeader = title || actionsPanel;

  return (
    <Card
      data-testid="page-card-layout"
      {...rest}
      sx={{ boxShadow: '3px 3px 5px #E8E8E8', border: '0.1rem solid #E8E8E8' }}
    >
      {showHeader && (
        <CardHeader
          data-testid="page-card-header"
          title={
            <>
              <Typography sx={{ fontFamily: 'Stolzl' }}>{title}</Typography>
              {content && <br /> && (
                <Typography
                  variant="small2"
                  color="#6C6C6C"
                  sx={{ fontFamily: 'Roboto' }}
                >
                  {content}
                </Typography>
              )}
            </>
          }
          action={actionsPanel}
          sx={headerProps}
        />
      )}
      <CardContent sx={contentProps}>
        {children}
        {loading && <PageCardLoader />}
      </CardContent>
    </Card>
  );
}

export default PageCardLayout;
