import { generatePath } from 'react-router-dom';
import ModalDialog, {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import { API_SOLUTION_RULES_MANAGEMENT } from '../constants';

type Props = {
  item;
  open: boolean;
  onClose: ModalCloseHandler;
};

function SolutionSpecificRuleDeleteModal({ item, open, onClose }: Props) {
  const { showAlert } = useAlert();
  const itemIds = item.map((x) => x.id);
  const itemNames = item.map((x) => x.Name);

  const getRuleNames = (ruleIds: []) => {
    const ruleNames: any = [];
    ruleIds.forEach((element) => {
      ruleNames.push(item.find((a) => a.Id === element).Name);
    });
    return ruleNames;
  };
  const [onSubmit, loading] = useStatefulApi(
    () =>
      pepApiRequest({
        method: RequestMethod.Delete,
        url: generatePath(API_SOLUTION_RULES_MANAGEMENT),
        params: { ids: itemIds },
      }),
    (response) => {
      let alertText = '';
      let alertTitle = '';
      if (response.Deleted.length > 0 && response.NotDeleted.length === 0) {
        const ruleNames = getRuleNames(response.Deleted);
        alertText =
          ruleNames.length > 1
            ? 'Selected Rules are deleted successfully'
            : `${ruleNames
                .toString()
                .replaceAll(',', ', ')} is  deleted successfully.`;
        alertTitle =
          ruleNames.length > 1 ? 'Rules are deleted' : 'Rule is deleted';
      }
      if (response.Deleted.length === 0 && response.NotDeleted.length > 0) {
        const ruleNames = getRuleNames(response.NotDeleted);
        alertTitle =
          ruleNames.length > 1 ? 'Rules are deleted' : 'Rule is deleted';
        alertText =
          ruleNames.length > 1
            ? 'Selected Rules are not deleted successfully'
            : `${ruleNames
                .toString()
                .replaceAll(',', ', ')} is not deleted successfully.`;
      }
      if (response.Deleted.length > 0 && response.NotDeleted.length > 0) {
        const ruleNames = getRuleNames(response.Deleted);
        alertTitle =
          ruleNames.length > 1 ? 'Rules are deleted' : 'Rule is deleted';
        alertText = `${ruleNames
          .toString()
          .replaceAll(',', ', ')} deleted successfully. ${getRuleNames(
          response.NotDeleted
        )
          .toString()
          .replaceAll(',', ', ')}  not deleted successfully.`;
      }
      showAlert({
        type: AlertType.Info,
        title: alertTitle,
        text: alertText,
      });
      onClose(null, ModalCloseReason.completeAction);
    }
  );

  return (
    <ModalDialog
      open={open}
      title={itemNames.length > 1 ? 'Delete Rules' : 'Delete Rule'}
      sx={{ fontWeight: 'normal' }}
      loading={loading}
      onConfirm={onSubmit}
      onClose={onClose}
      confirmBtnText="Yes"
      cancelBtnText="No"
    >
      {itemNames.length > 1
        ? 'Are you sure you want to delete these rule?'
        : 'Are you sure you want to delete this rule?'}
      <br />
      <b>
        {itemNames.length >= 1
          ? itemNames.toString().replaceAll(',', ', ')
          : itemNames}
      </b>
    </ModalDialog>
  );
}

export default SolutionSpecificRuleDeleteModal;
