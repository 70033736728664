import { generatePath, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';
import ModalDialog, {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { useForm } from 'src/components/Form';
import { useStoreUnitActions } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import { getAssignCompanyFormFields } from '../formFields';
import { SolutionDeviceItem } from '../../types';
import { API_DEVICES_ASSIGN_COMPANY } from '../../constants';
import solutionsStore, { useSolutionCompaniesOptions } from '../../redux';

type Props = {
  item: SolutionDeviceItem;
  selectedItems: SolutionDeviceItem[];
  open: boolean;
  onClose: ModalCloseHandler;
};

const modalStyle: SxProps = {
  '.MuiDialogContent-root': {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
};

const formStyle: SxProps = {
  gap: 0.5,
  '.MuiFormControlLabel-root': {
    alignSelf: 'flex-end',
    color: 'neutral.dark',
    typography: 'small1',
  },
};

function SolutionDevicesAssignCompanyModal({
  item,
  selectedItems,
  open,
  onClose,
}: Props) {
  const [options, optionsLoading] = useSolutionCompaniesOptions();
  const { devices, devicesPersist } = useStoreUnitActions(solutionsStore);
  const actions = useStoreUnitActions(solutionsStore);
  const { showAlert } = useAlert();
  const { solutionId } = useParams();
  const itemIds = item ? [item.id] : selectedItems?.map((x) => x.id);
  const modelId = item ? item.modelId : selectedItems?.find((x) => x.modelId);

  useEffect(() => {
    actions.companies(solutionId);
  }, []);

  const [handleSubmit, saving] = useStatefulApi(
    ({ modelId }) =>
      pepApiRequest({
        method: RequestMethod.Put,
        url: generatePath(API_DEVICES_ASSIGN_COMPANY, {
          solutionId,
        }),
        params: {
          deviceIds: itemIds,
          organisationId: modelId,
        },
      }),
    () => {
      showAlert({
        type: AlertType.Success,
        title: 'The company has been assigned.',
        text: 'New company has been assigned to the device successfully.',
      });
      devices.init({ solutionId });
      devicesPersist.update({ solutionId, devices: [item] });
      onClose(null, ModalCloseReason.completeAction);
    }
  );

  const [FormComponent, onSubmit] = useForm({
    sx: formStyle,
    fields: getAssignCompanyFormFields(options, modelId),
    onSubmit: handleSubmit,
  });

  return (
    <ModalDialog
      open={open}
      title="Associate to company"
      onClose={onClose}
      onConfirm={onSubmit}
      confirmBtnText="Associate"
      loading={saving || optionsLoading}
      sx={modalStyle}
      fullWidth
    >
      <Typography variant="body2">
        Choose the company you'd like associate to this device.
      </Typography>

      {FormComponent}
    </ModalDialog>
  );
}

export default SolutionDevicesAssignCompanyModal;
