import { Dispatch } from 'redux';
import isFunction from 'lodash/isFunction';
import { INIT, ON_SUCCESS, UPDATE } from './actionTypes';
import { composeSlices } from './composeSlices';
import { createClearStateSlice } from './createClearStateSlice';
import { createDatasetSlice } from './createDatasetSlice';
import { getHashFunc } from './utils';

type Dispatcher = (dispatch: Dispatch<any>) => (params: any) => void;
type Sagas = { [actionType: string]: any };
type Reducers<T> = {
  [actionType: string]: (state: T, action: any) => T | void;
};

export type CreateDatasetConcatSliceOptions<T> = {
  storeName: string;
  actionName: string;
  initialState?: T;
  api: (data?: any) => Promise<unknown>;
  concatReducers?: Reducers<T> | ((name: string) => Reducers<T>);
  extraReducers?: Reducers<T>;
  extraSagas?: Sagas;
  selectors?: { [key: string]: (state: T) => any };
  dispatchers?: { [key: string]: Dispatcher };
};

function defaultConcatReducer<T>(actionName): Reducers<T> {
  const hashFunc = getHashFunc(actionName);
  return {
    [ON_SUCCESS](state: T, action) {
      const key = hashFunc(action.meta.identifier);
      const { data, ...rest } = action.payload;
      state[key] = {
        loading: false,
        data: [...state[key].data, ...data],
        ...rest,
      };
    },
  };
}

export function createDatasetConcatSlice<T>(
  options: CreateDatasetConcatSliceOptions<T>
) {
  const {
    storeName,
    actionName,
    api,
    initialState,
    concatReducers,
    extraReducers,
  } = options;
  let reducers = defaultConcatReducer<T>(actionName);

  if (concatReducers) {
    reducers = isFunction(concatReducers)
      ? concatReducers(actionName)
      : concatReducers;
  }

  const initSlice = createDatasetSlice<T>({
    actionName: `${actionName}_${INIT}`,
    datasetHashKey: actionName,
    api,
    storeName,
  });

  const updateSlice = createDatasetSlice<T>({
    actionName: `${actionName}_${UPDATE}`,
    datasetHashKey: actionName,
    api,
    storeName,
    reducers,
  });

  const clearStateSlice = createClearStateSlice<T>({
    datasetHashKey: actionName,
    storeName,
    initialState,
  });

  return composeSlices(
    {
      clear: clearStateSlice,
      init: initSlice,
      update: updateSlice,
    },
    {
      initialState,
      extraReducers,
      selectors: options.selectors || {},
      dispatchers: options.dispatchers || {},
    }
  );
}
