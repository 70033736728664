import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card, { CardProps } from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { SxProps } from 'src/theme/types';
import { baseTheme } from 'src/theme/baseTheme';

const { breakpoints } = baseTheme;

const getCardWidth = (
  breakpoint: string,
  direction: string,
  percent: number
) => ({
  [breakpoints[direction](breakpoint)]: {
    width: `calc(${percent}% - 2rem)`,
  },
});

const cardStyle: SxProps = {
  cursor: 'pointer',
  display: 'inline-flex',
  alignItems: 'flex-start',
  textAlign: 'left',
  textDecoration: 'none',
  justifyContent: 'flex-start',
  mx: 1,
  mb: 2,
  p: 2,
  '&.Mui-disabled': {
    bgcolor: 'grayscale.200',
  },
  '&:not(:hover)': {
    boxShadow: 'none',
  },
  ...getCardWidth('lg', 'up', 25),
  ...getCardWidth('lg', 'down', 50),
  ...getCardWidth('sm', 'down', 100),
};

const iconStyle: SxProps = {
  height: ({ spacing }) => spacing(5),
  width: ({ spacing }) => spacing(5),
  backgroundColor: 'grayscale.300',
  borderRadius: '50%',
  p: 1.3,
  mr: 1.5,
  color: 'neutral.main',
};

const textStyle: SxProps = {
  overflow: 'hidden',
  '.MuiTypography': {
    '&-root': {
      whiteSpace: 'nowrap',
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
    },
    '&-body2': {
      color: 'neutral.main',
    },
  },
};

type Props = CardProps<any> & {
  title?: string;
  description?: string;
  icon: React.JSXElementConstructor<SvgIconProps>;
};

function CardButton({
  component = Button,
  title,
  description,
  icon,
  sx,
  ...rest
}: Props) {
  return (
    <Card
      data-testid="card-button"
      component={component}
      sx={{ ...cardStyle, ...sx } as SxProps}
      {...rest}
    >
      <SvgIcon
        component={icon}
        fontSize="medium"
        color="inherit"
        sx={iconStyle}
      />
      <Box sx={textStyle}>
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body2">{description}</Typography>
      </Box>
    </Card>
  );
}

export default CardButton;
