import React, { ComponentType } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useUserRoutes } from 'src/router';
import * as ROUTES from 'src/router/constants';
import CardButton, { CardButtonsContainer } from 'src/components/CardButton';

function Dashboard() {
  const navigate = useNavigate();
  const routes = useUserRoutes(false, true);

  return (
    <Box>
      <Typography variant="body1bold">General</Typography>
      <CardButtonsContainer>
        {routes
          .filter(
            (route) =>
              route.path !== ROUTES.COMPANIES && route.path !== ROUTES.SITES
          )
          .map(({ meta, path }) => (
            <CardButton
              data-testid={`dashboard-nav-link${path}`}
              key={path}
              disabled={meta?.disabled}
              onClick={() => path && navigate(path)}
              title={meta?.title}
              description={meta?.description}
              icon={meta?.icon as ComponentType}
            />
          ))}
      </CardButtonsContainer>
      <Typography variant="body1bold">Consumer</Typography>
      <CardButtonsContainer>
        {routes
          .filter((route) => route.path === ROUTES.COMPANIES)
          .map(({ meta, path }) => (
            <CardButton
              data-testid={`dashboard-nav-link${path}`}
              key={path}
              disabled={meta?.disabled}
              onClick={() => path && navigate(path)}
              title={meta?.title}
              description={meta?.description}
              icon={meta?.icon as ComponentType}
            />
          ))}
      </CardButtonsContainer>
      <Typography variant="body1bold">Industrial</Typography>
      <CardButtonsContainer>
        {routes
          .filter((route) => route.path === ROUTES.SITES)
          .map(({ meta, path }) => (
            <CardButton
              data-testid={`dashboard-nav-link${path}`}
              key={path}
              disabled={meta?.disabled}
              onClick={() => path && navigate(path)}
              title={meta?.title}
              description={meta?.description}
              icon={meta?.icon as ComponentType}
            />
          ))}
      </CardButtonsContainer>
    </Box>
  );
}

export default Dashboard;
