import { ComponentType } from 'react';
import groupBy from 'lodash/groupBy';
import isString from 'lodash/isString';
import keys from 'lodash/keys';
import orderBy from 'lodash/orderBy';
import remove from 'lodash/remove';
import slice from 'lodash/slice';
import toString from 'lodash/toString';
import {
  CellProps,
  Column,
  CommonColumn,
  RowKeyValue,
  Row,
  SelectedItems,
  GroupRowsMeta,
  SortOrder,
  SortDirection,
  Value,
} from './types';

export const getRowKey = <TRow extends Row>(row: TRow): RowKeyValue =>
  (row.id || row.key || Object.values(row).join('')).toString();

export const getCellKey = <TRow extends Row>(row: TRow, column: Column<TRow>) =>
  getRowKey(row) + column.id;

export const getCellTitle = <TRow extends Row>(
  row: TRow,
  { Component, id }: Column<TRow>
): string | undefined => (!Component ? toString(row[id]) : undefined);

export const isAllSelected = (data: Row[], selectedItems: SelectedItems) => {
  const { length } = mapSelectionToArray(selectedItems);
  return !!length && length === data.length;
};

export const isItemSelected = (id: RowKeyValue, selectedItems: SelectedItems) =>
  selectedItems[id] ?? false;

export const mapSelectionToArray = (selectedItems: SelectedItems) =>
  Object.entries(selectedItems).map(([rowKey]) => rowKey);

export const mapRowsToSelection = <TRow extends Row>(data: TRow[]) =>
  Object.fromEntries(data.map((row) => [getRowKey(row), true]));

export const isPageOutOfRange = <TRow extends Row>(
  data: TRow[],
  totalItems?: number,
  pageIndex?: number,
  pageSize?: number
) => {
  const total = totalItems ?? data.length;
  return pageIndex && pageSize && total > 0 && total < pageIndex * pageSize;
};

export const getPageSliceData = <TRow extends Row>(
  data: TRow[],
  pageIndex: number,
  pageSize: number
) => slice(data, pageIndex * pageSize, pageIndex * pageSize + pageSize);

export const getSortedRows = <TRow extends Row>(
  data: TRow[],
  sortBy: string | undefined,
  sortOrder: SortOrder
): TRow[] => {
  const isSorted = sortBy && sortOrder;
  return isSorted
    ? orderBy(
        data,
        (row) => {
          const value = row[sortBy];
          return isString(value) ? value.toLowerCase() : value;
        },
        sortOrder
      )
    : data;
};

export const getCellWidth = (content: string | undefined) => {
  if (content === 'Actions') return '20rem';
  if (content === undefined) return 'unset';
  return Math.max(100, ((content?.length || 0) + 8) * 10);
};

export const findFlexColumnIndex = (columns, noColumns) => {
  if (noColumns > 0)
    return columns[noColumns].title === 'Actions' ? noColumns - 1 : noColumns;
  return noColumns;
};

export const getActionsColConfig = <TRow extends Row = Row>(
  Component: ComponentType<CellProps<any, TRow>>
): Column<TRow> => ({
  id: CommonColumn.Action,
  title: 'Actions',
  sortable: false,
  sx: {
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  Component,
});

export const getUpdatedSortOrder = (sortOrder?: SortOrder) => {
  if (sortOrder === SortDirection.Asc) {
    return SortDirection.Desc;
  }
  if (!sortOrder) {
    return SortDirection.Asc;
  }
  return null;
};

export const getFilteredRows = <TRow extends Row>(
  data: TRow[],
  columns: Column<TRow>[],
  searchStr?: string
): TRow[] => {
  if (searchStr) {
    const columnIds = columns.map((column) => column.id);
    const formatStr = (value: Value) => value.toString().toLowerCase();
    const formattedSearchStr = formatStr(searchStr);
    return data.filter((row) =>
      columnIds.find(
        (id) => row[id] && formatStr(row[id]).includes(formattedSearchStr)
      )
    );
  }
  return data;
};

export const getGroupedRows = <TRow extends Row>(
  data: TRow[],
  groupingData: GroupRowsMeta,
  groupById: string
): TRow[] => {
  const groups = groupBy(data, (row) => row[groupById]);
  return keys(groups)
    .map((key) => {
      const [groupRow, ...rest] = groups[key];
      const rowKey = getRowKey(groupRow);
      const isExpanded = groupingData[rowKey]?.isExpanded;
      return [groupRow, ...(isExpanded ? rest : [])];
    })
    .flat();
};

export const getGroupRowsMeta = <TRow extends Row>(
  data: TRow[],
  groupById: string
): GroupRowsMeta => {
  const groups = groupBy(data, (row) => row[groupById]);
  return keys(groups).reduce((acc, key, index) => {
    const [groupRow, ...subRows] = groups[key];
    return {
      ...acc,
      [getRowKey(groupRow)]: {
        isExpandable: !!subRows.length,
        isExpanded: !index,
      },
    };
  }, {});
};

export const removeColumnById = <TRow extends Row>(
  columns: Column<TRow>[],
  columnId: string
): [Column<TRow>, Column<TRow>[]] => {
  const restColumns = [...columns];
  const [removedColumn] = remove(restColumns, ({ id }) => id === columnId);
  return [removedColumn, restColumns];
};
