const tenantId = process.env.REACT_APP_MSAL_TENANT_ID!;
const appId = process.env.REACT_APP_MSAL_CLIENT_ID!;
const redirectUrl = process.env.REACT_APP_PEPSENSE_URL; // change to 'http://localhost:3000/solutions' for local run

export const msalConfig = {
  auth: {
    clientId: appId,
    authority: `https://login.microsoftonline.com/${tenantId}`,
    redirectUri: redirectUrl,
    scope: 'https://rtd-metadata.azurewebsites.net/user_impersonation',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    allowRedirectInIframe: true,
  },
};

export const loginRequest = {
  scopes: ['openid', 'profile', 'User.Read'],
};
