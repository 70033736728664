import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import { DataTableWrapperActionButton } from 'src/components/DataTable';
import Form from 'src/components/Form';
import { isModalComplete } from 'src/components/ModalDialog';
import { PageCardLoader } from 'src/components/PageCardLayout';
import Switch from 'src/components/Switch/Switch';
import { useModal } from 'src/hooks';
import { useStoreUnitActions } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import CheckboxGroup from 'src/components/CheckboxGroup';
import { generateApiPath, loadDataFromStorage } from 'src/utils';
import { useUserRoles } from 'src/auth';
import {
  API_SOLUTION_MAPPING_ADX_TABLES,
  API_SOLUTION_MENDER,
  API_SOLUTION_NOTIFICATION_PREFER,
  API_SOLUTION_SET_PERIOD,
  API_SOLUTION_SET_PERIOD_IOTH,
  API_SOLUTION_SOURCE_STORAGE,
} from '../constants';
import solutionsStore, {
  useSolutionAdxMappedTables,
  useSolutionDetails,
  useSolutionMenderSettings,
  useSolutionSourceStorageAccountSettings,
} from '../redux';
import {
  SolutionConsumerFeatures,
  SolutionIndustrialFeatures,
  SolutionType,
  SolutionUserNotificationPreferences,
} from '../types';
import SolutionSettingsViewModal from './components/SolutionSettingsViewModal';
import { useSolutionSettingsMenderFormFields } from './hooks';
import {
  getSolutionSettingsADXTableFormFields,
  getSolutionSettingsCacheRetentionFormFields,
} from './formFields';
import { getSolutionEditParams } from '../EditSolution/utils';
import SolutionFeatureInformation from '../components/SolutionFeatureInfomation';
import { IOTH_NOTIFICATION_PREFERENCE_OPTIONS } from '../SolutionUsers/constants';

const style = {
  width: ({ spacing }) => spacing(55),
};

function SolutionSettings() {
  const actions = useStoreUnitActions(solutionsStore);
  const { solutionId, solutionType } = useParams();
  const { isPlatformReader } = useUserRoles();
  const [data, loadingSolution] = useSolutionDetails();
  const [adxTablesData, loadingAdxTables] = useSolutionAdxMappedTables();
  const disableCacheRetention =
    data?.adxInstance?.databaseName === (null || undefined);
  const { showAlert } = useAlert();

  const [values, loading] = useSolutionMenderSettings();
  const [sourceStorageValues, sourceStorageLoading] =
    useSolutionSourceStorageAccountSettings();
  const fields = useSolutionSettingsMenderFormFields(values);

  const [sourceStorageToggle, setSourceStorageToggle] = useState<boolean>(
    sourceStorageValues.storageEnabled || false
  );
  const [notificationPrefer, setNotificationPrefer] = useState(
    data?.notificationPreference ?? []
  );
  const [cachePeriod, setCachePeriod] = useState<number>(30);
  const [retentionPeriod, setRetentionPeriod] = useState<number>(30);
  const [disableCacheLimit, setDisableCacheLimit] = useState<boolean>(false);
  const [disableRetentionLimit, setDisableRetentionLimit] =
    useState<boolean>(false);
  const isMenderEnabled = data?.solutionConsumerFeatures?.includes(
    SolutionConsumerFeatures.IsMenderEnabled
  );
  const isDataIngested =
    data?.solutionConsumerFeatures?.includes(
      SolutionConsumerFeatures.IsDataIngested
    ) ||
    data?.solutionIndustrialFeatures?.includes(
      SolutionIndustrialFeatures.IsDataIngested
    );

  const isSmsAccountCreation = data?.solutionConsumerFeatures?.includes(
    SolutionConsumerFeatures.IsSmsAccountCreation ||
      data?.solutionIndustrialFeatures?.includes(
        SolutionIndustrialFeatures.IsSmsAccountCreation
      )
  );

  const isConsumerSolution = solutionType === SolutionType.Consumer;

  const NOTIFICATION_PREFERENCE_OPTIONS = [
    {
      value: SolutionUserNotificationPreferences.Email,
      label: 'Email',
    },
    {
      value: SolutionUserNotificationPreferences.SMS,
      label: 'SMS',
      disabled: !isSmsAccountCreation || isPlatformReader,
    },
    {
      value: SolutionUserNotificationPreferences.Whatsapp,
      label: 'Whatsapp',
    },
  ];

  const handleCacheLimit = ({ target }) => {
    setCachePeriod(target.value);
  };
  const handleRetentionLimit = ({ target }) => {
    setRetentionPeriod(target.value);
  };
  const handleUnlimitedCache = (e) => {
    if (e.target.value.length) {
      setCachePeriod(0);
      setDisableCacheLimit(true);
    } else {
      setDisableCacheLimit(false);
      setCachePeriod(Number(data?.CachePeriod) ?? 0);
    }
  };
  const handleUnlimitedRetention = (e) => {
    if (e.target.value.length) {
      setRetentionPeriod(0);
      setDisableRetentionLimit(true);
    } else {
      setRetentionPeriod(Number(data?.RetentionPeriod) ?? 0);
      setDisableRetentionLimit(false);
    }
  };

  useEffect(() => {
    if (data?.CachePeriod !== (null || undefined)) {
      setCachePeriod(Number(data?.CachePeriod));
    }
  }, [data?.CachePeriod]);
  useEffect(() => {
    if (data?.RetentionPeriod !== (null || undefined)) {
      setRetentionPeriod(Number(data?.RetentionPeriod));
    }
  }, [data?.RetentionPeriod]);
  useEffect(() => {
    if (
      !(loadingSolution || loadingSolution === undefined) &&
      data?.adxInstance === (null || undefined)
    ) {
      setDisableCacheLimit(true);
      setDisableRetentionLimit(true);
    }
  }, [data?.adxInstance, loadingSolution]);
  useEffect(() => {
    if (
      !(loadingSolution || loadingSolution === undefined) &&
      data?.UnlimitedCache
    ) {
      setCachePeriod(0);
      setDisableCacheLimit(true);
    }
  }, [loadingSolution, data?.UnlimitedCache]);
  useEffect(() => {
    if (
      !(loadingSolution || loadingSolution === undefined) &&
      data?.UnlimitedRetention
    ) {
      setRetentionPeriod(0);
      setDisableRetentionLimit(true);
    }
  }, [loadingSolution, data?.UnlimitedRetention]);
  useEffect(() => {
    if (!sourceStorageLoading) {
      setSourceStorageToggle(sourceStorageValues.storageEnabled || false);
    }
  }, [sourceStorageValues.storageEnabled, sourceStorageLoading]);
  useEffect(() => {
    if (solutionType === SolutionType.Industrial) {
      actions.adxMappedTables(solutionId);
    }
  }, []);
  useEffect(() => {
    setNotificationPrefer(data?.notificationPreference ?? []);
  }, [data]);
  const [ViewStorageAccountDialog, showViewStorageAccountDialog] = useModal(
    SolutionSettingsViewModal,
    {
      onClose: (event, reason) =>
        isModalComplete(reason) && actions.jobs(solutionId),
    }
  );

  const [handleSubmit, saving] = useStatefulApi(
    (params) =>
      pepApiRequest({
        method: RequestMethod.Put,
        url: generateApiPath(API_SOLUTION_MENDER, { solutionId }),
        params,
      }),
    () => {
      showAlert({
        type: AlertType.Info,
        title: 'Solution settings updated.',
        text: 'Solution mender settings have been updated successfully.',
      });
      actions.details(solutionId);
    }
  );

  const [handleStorageAccountSubmit, storageAccountSaving] = useStatefulApi(
    (params) =>
      pepApiRequest({
        method: RequestMethod.Put,
        url: generateApiPath(API_SOLUTION_SOURCE_STORAGE, { solutionId }),
        params,
      }),
    () => {
      showAlert({
        type: AlertType.Info,
        title: 'Solution settings updated.',
        text: 'Solution source storage acount settings have been updated successfully.',
      });
      actions.details(solutionId);
    }
  );
  const [handleNotificationPreferSubmit, notificationPreferSaving] =
    useStatefulApi(
      (params) =>
        pepApiRequest({
          method: RequestMethod.Put,
          url: generateApiPath(API_SOLUTION_NOTIFICATION_PREFER, {
            solutionId,
          }),
          params,
        }),
      () => {
        actions.details(solutionId);
      }
    );
  const [handleCacheRetentionSubmit, cacheRetentionSaving] = useStatefulApi(
    (params) => {
      const solutionType = loadDataFromStorage('solutionType');
      if (solutionType.toUpperCase() === SolutionType.ioTH.toUpperCase()) {
        params.solutionId = solutionId;
        return pepApiRequest({
          method: RequestMethod.Post,
          url: generateApiPath(API_SOLUTION_SET_PERIOD_IOTH, {}),
          params: getSolutionEditParams(params),
        });
      }
      return pepApiRequest({
        method: RequestMethod.Post,
        url: generateApiPath(API_SOLUTION_SET_PERIOD, {
          solutionId,
        }),
        params: getSolutionEditParams(params),
      });
    },
    () => {
      showAlert({
        type: AlertType.Info,
        title: 'Solution Changes saved.',
        text: 'Solution has been modified successfully.',
      });
      actions.details(solutionId);
    }
  );

  const [handleAdxTableMapping, adxTablesSaving] = useStatefulApi(
    (params) =>
      pepApiRequest({
        method: RequestMethod.Put,
        url: generateApiPath(API_SOLUTION_MAPPING_ADX_TABLES, {
          solutionId,
        }),
        params,
      }),
    () => {
      showAlert({
        type: AlertType.Info,
        title: 'ADX Tables saved',
        text: 'ADX table input modified successfully',
      });
      actions.adxMappedTables(solutionId);
    }
  );

  return (
    <Box position="relative" minHeight="70rem">
      {solutionType === SolutionType.Consumer && (
        <Accordion
          sx={{
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px',
            boxShadow: '0 1px 1px 2px rgba(0, 0, 0, 0.1)',
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ padding: '9px' }}
          >
            <Typography variant="h4" fontWeight="500">
              Mender
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{ backgroundColor: 'rgba(165, 165, 165, 0.07)' }}
          >
            {!isMenderEnabled && isConsumerSolution && (
              <SolutionFeatureInformation
                featureInfo="Mender is disabled since this feature was not opted during
                  solution onboarding. To start accessing it, please edit the
                  solution and select the feature."
              />
            )}
            <Typography
              mb={2}
              variant="body2"
              sx={!isMenderEnabled ? { color: '#7B7B7B', opacity: '50%' } : {}}
            >
              Mender-related features such as release artifact upload and remote
              device terminal are{' '}
              {values.menderEnabled ? 'enabled' : 'disabled'}. Click on the
              toggle to {values.menderEnabled ? 'disable' : 'enable'}
              &nbsp; these features.
            </Typography>
            <Form
              fields={fields}
              values={values}
              fieldProps={{ sx: { maxWidth: '40rem' } }}
              onSubmit={handleSubmit}
            >
              <Button
                type="submit"
                variant="contained"
                sx={
                  !isMenderEnabled && isConsumerSolution
                    ? {
                        alignSelf: 'flex-end',
                        mt: 3,
                        color: '#7B7B7B',
                        opacity: '50%',
                      }
                    : { alignSelf: 'flex-end', mt: 3 }
                }
                disabled={isPlatformReader || !isMenderEnabled}
              >
                Save
              </Button>
            </Form>
            {(loading || saving) && <PageCardLoader />}
          </AccordionDetails>
        </Accordion>
      )}
      <div>
        <Accordion
          sx={{
            marginTop: '25px',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
            boxShadow: '0 1px 1px 2px rgba(0, 0, 0, 0.1)',
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            sx={{ padding: '9px', marginTop: '25px' }}
          >
            <Typography variant="h4" fontWeight="500">
              Source Storage Account
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{ backgroundColor: 'rgba(165, 165, 165, 0.07)' }}
          >
            <Box
              style={{ display: 'flex' }}
              flexDirection={'row'}
              justifyContent="space-between"
            >
              <Switch
                onChange={({ target }) => {
                  setSourceStorageToggle(target.checked);
                  handleStorageAccountSubmit({
                    storageEnabled: target.checked,
                  });
                }}
                value={sourceStorageToggle}
                label="Enable Source Storage"
                disabled={!!sourceStorageToggle || isPlatformReader}
              />
              <DataTableWrapperActionButton
                onClick={showViewStorageAccountDialog}
              >
                View details
              </DataTableWrapperActionButton>
            </Box>
            {(sourceStorageLoading || storageAccountSaving) && (
              <PageCardLoader />
            )}
            {ViewStorageAccountDialog}
          </AccordionDetails>
        </Accordion>
      </div>
      <div>
        <Accordion
          sx={{
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px',
            boxShadow: '0 1px 1px 2px rgba(0, 0, 0, 0.1)',
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" />}
            aria-controls="panel1a-content"
            id="panel3a-header"
            sx={{ padding: '9px', marginTop: '25px' }}
          >
            <Typography variant="h4" fontWeight="500">
              Notification
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{ backgroundColor: 'rgba(165, 165, 165, 0.07)' }}
          >
            {!isSmsAccountCreation && (
              <SolutionFeatureInformation
                featureInfo="SMS notification is disabled since this feature was not opted
                  during solution onboarding. To start accessing it, please edit
                  the solution and select the feature."
              />
            )}
            <Box
              style={{ display: 'flex' }}
              flexDirection={'row'}
              justifyContent="space-between"
            >
              <CheckboxGroup
                onChange={({ target }) => {
                  setNotificationPrefer(target.value);
                  handleNotificationPreferSubmit({
                    notificationPreference: target.value,
                  });
                }}
                options={
                  solutionType === SolutionType.ioTH
                    ? IOTH_NOTIFICATION_PREFERENCE_OPTIONS
                    : NOTIFICATION_PREFERENCE_OPTIONS
                }
                value={notificationPrefer}
                disabled={isPlatformReader}
                label="Preference:"
              />
            </Box>
            {notificationPreferSaving && <PageCardLoader />}
          </AccordionDetails>
        </Accordion>
      </div>
      <div>
        {solutionType !== SolutionType.ioTH && (
          <Accordion
            sx={{
              borderBottomLeftRadius: '5px',
              borderBottomRightRadius: '5px',
              boxShadow: '0 1px 1px 2px rgba(0, 0, 0, 0.1)',
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon color="primary" />}
              aria-controls="panel1a-content"
              id="panel4a-header"
              sx={{ padding: '9px', marginTop: '25px' }}
            >
              <Typography variant="h4" fontWeight="500">
                Cache and Retention period
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{ backgroundColor: 'rgba(165, 165, 165, 0.07)' }}
            >
              {!isDataIngested && (
                <SolutionFeatureInformation
                  featureInfo="Data ingestion is disabled since this feature was not opted
                    during solution onboarding. To start accessing it, please
                    edit the solution and select the feature."
                />
              )}
              <Box
                style={{ display: 'flex' }}
                flexDirection={'row'}
                justifyContent="space-between"
              >
                <Form
                  fields={getSolutionSettingsCacheRetentionFormFields(
                    cachePeriod,
                    retentionPeriod,
                    disableCacheLimit,
                    disableRetentionLimit,
                    handleCacheLimit,
                    handleRetentionLimit,
                    handleUnlimitedCache,
                    handleUnlimitedRetention,
                    disableCacheRetention,
                    isPlatformReader,
                    !isDataIngested,
                    isConsumerSolution
                  )}
                  fieldProps={{ sx: style }}
                  sx={{ width: '100%' }}
                  values={data}
                  onSubmit={handleCacheRetentionSubmit}
                  visibleValuesOnly
                >
                  <Box textAlign="right" mt={3}>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={
                        disableCacheRetention ||
                        isPlatformReader ||
                        !isDataIngested
                      }
                    >
                      Save
                    </Button>
                  </Box>
                </Form>
              </Box>
              {cacheRetentionSaving && <PageCardLoader />}
            </AccordionDetails>
          </Accordion>
        )}
      </div>
      <div>
        {solutionType?.toUpperCase() ===
          SolutionType.Industrial.toUpperCase() && (
          <Accordion
            sx={{
              borderBottomLeftRadius: '5px',
              borderBottomRightRadius: '5px',
              boxShadow: '0 1px 1px 2px rgba(0, 0, 0, 0.1)',
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon color="primary" />}
              aria-controls="panel1a-content"
              id="panel4a-header"
              sx={{ padding: '9px', marginTop: '25px' }}
            >
              <Typography variant="h4" fontWeight="500">
                ADX Table Map
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{ backgroundColor: 'rgba(165, 165, 165, 0.07)' }}
            >
              <Box
                style={{ display: 'flex' }}
                flexDirection={'row'}
                justifyContent="space-between"
              >
                <Form
                  fields={getSolutionSettingsADXTableFormFields(
                    adxTablesData?.adxTables,
                    isPlatformReader
                  )}
                  sx={{ width: '100%' }}
                  values={adxTablesData}
                  onSubmit={handleAdxTableMapping}
                  visibleValuesOnly
                >
                  <Box textAlign="right" mt={3}>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isPlatformReader}
                    >
                      Save
                    </Button>
                  </Box>
                </Form>
              </Box>
              {(adxTablesSaving || loadingAdxTables) && <PageCardLoader />}
            </AccordionDetails>
          </Accordion>
        )}
      </div>
      {loadingSolution && <PageCardLoader />}
    </Box>
  );
}

export default SolutionSettings;
