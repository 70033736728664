import TextField from '@mui/material/TextField';
import * as Yup from 'yup';
import { compact } from 'lodash';
import TextFieldWithInfo from 'src/components/TextField';
import MaskedInput from 'src/components/MaskedInput';
import RadioButtonGroup from 'src/components/RadioButtonGroup';
import {
  SolutionSourceImageRepoContainerType,
  SolutionSourceImageRepositoryItemField,
} from '../types';
import { SOURCE_IMG_CONTAINER_REPO_TYPE_OPTIONS } from './constants';

export const getSourceImageRepositoryFormFields = (
  ImagePullSecret: string,
  containerType?: string,
  handlePaste?: () => void,
  handleAcrClientSecretPaste?: () => void,
  handleEcrSecretKeyPaste?: () => void
) =>
  compact([
    {
      name: SolutionSourceImageRepositoryItemField.ContainerRepositoryType,
      label: 'Type',
      Component: RadioButtonGroup,
      value: containerType,
      initialValue: containerType,
      required: true,
      options: SOURCE_IMG_CONTAINER_REPO_TYPE_OPTIONS,
    },
    containerType === SolutionSourceImageRepoContainerType.Acr && {
      name: SolutionSourceImageRepositoryItemField.acrServerUrl,
      label: 'Server URL',
      Component: TextFieldWithInfo,
      required: true,
      showinfoicon: true,
      placeholder: 'Enter Container Registry URL',
      infotitle: 'Container registry URL',
      sx: { width: ({ spacing }) => spacing(55), paddingRight: '2rem' },
      validation: Yup.string().required('Server URL is required'),
    },
    containerType === SolutionSourceImageRepoContainerType.Acr && {
      name: SolutionSourceImageRepositoryItemField.UserName,
      label: 'Pull secret name',
      Component: TextFieldWithInfo,
      placeholder: 'Enter the username',
      showinfoicon: true,
      infotitle: 'Token name created in ACR',
      sx: { width: ({ spacing }) => spacing(55), paddingRight: '2rem' },
      required: true,
      validation: Yup.string().required('Username is required'),
    },
    containerType === SolutionSourceImageRepoContainerType.Acr && {
      name: SolutionSourceImageRepositoryItemField.ImagePullSecret,
      label: 'Pull Secret',
      Component: MaskedInput,
      value: ImagePullSecret,
      placeholder: 'Enter the secret',
      onClickPasteIcon: handlePaste,
      showInfoIcon: true,
      infoTitle: 'Password created for token name',
      sx: { width: ({ spacing }) => spacing(55), paddingRight: '2rem' },
      required: true,
      icon: true,
      validation: Yup.string().required('PullSecret is required'),
    },
    containerType === SolutionSourceImageRepoContainerType.Acr && {
      name: SolutionSourceImageRepositoryItemField.AcrClientId,
      label: 'Client ID',
      Component: TextFieldWithInfo,
      placeholder: 'Enter the ID',
      showinfoicon: true,
      infotitle: 'Service principle name',
      sx: { width: ({ spacing }) => spacing(55), paddingRight: '2rem' },
      validation: Yup.string(),
    },
    containerType === SolutionSourceImageRepoContainerType.Acr && {
      name: SolutionSourceImageRepositoryItemField.AcrClientSecret,
      label: 'Client Secret',
      Component: MaskedInput,
      placeholder: 'Enter the secret',
      onClickPasteIcon: handleAcrClientSecretPaste,
      showInfoIcon: true,
      infoTitle: 'Service principle password',
      sx: { width: ({ spacing }) => spacing(55), paddingRight: '2rem' },
      icon: true,
      validation: Yup.string(),
    },
    containerType === SolutionSourceImageRepoContainerType.Ecr && {
      name: SolutionSourceImageRepositoryItemField.ecrServerUrl,
      label: 'Server URL',
      Component: TextFieldWithInfo,
      required: true,
      showinfoicon: true,
      placeholder: 'Enter Container Registry URL',
      infotitle: 'Container registry URL',
      sx: { width: ({ spacing }) => spacing(55), paddingRight: '2rem' },
      validation: Yup.string().required('Server URL is required'),
    },
    containerType === SolutionSourceImageRepoContainerType.Ecr && {
      name: SolutionSourceImageRepositoryItemField.EcrAccesKey,
      label: 'Access key',
      Component: TextField,
      placeholder: 'Enter the Key',
      required: true,
      validation: Yup.string().required('Access Key is required'),
    },
    containerType === SolutionSourceImageRepoContainerType.Ecr && {
      name: SolutionSourceImageRepositoryItemField.EcrSecretKey,
      label: 'Secret key',
      Component: MaskedInput,
      placeholder: 'Enter the secret key',
      onClickPasteIcon: handleEcrSecretKeyPaste,
      required: true,
      icon: true,
      validation: Yup.string().required('Secret Key is required'),
    },
    containerType === SolutionSourceImageRepoContainerType.Ecr && {
      name: SolutionSourceImageRepositoryItemField.EcrRegionEndpoint,
      label: 'Region endpoint',
      Component: TextField,
      placeholder: 'Select the value',
      required: true,
      validation: Yup.string().required('Region endpoint is required'),
    },
  ]);
