import { Add } from '@mui/icons-material';
import DataTable, {
  DataTableWrapper,
  DataTableWrapperActionButton,
  useTableQueryParams,
} from 'src/components/DataTable';
import { SiteDeviceNodesItem, SiteDeviceNodesItemField } from '../../types';
import NodeItemStatus from './NodeItemStatus';

const columns = [
  {
    id: SiteDeviceNodesItemField.Name,
    title: 'Name',
  },
  {
    id: SiteDeviceNodesItemField.Status,
    title: 'Status',
    Component: NodeItemStatus,
  },
  {
    id: SiteDeviceNodesItemField.Pods,
    title: 'Pods',
  },
];

type Props = {
  data: SiteDeviceNodesItem[];
};

function NodesList({ data }: Props) {
  const { paging: nodePaging, sorting: nodeSorting } = useTableQueryParams();
  return (
    <DataTableWrapper
      sx={{ marginTop: '1%' }}
      title="Nodes"
      titleStyle={{ fontWeight: '700', fontSize: '16px', fontFamily: 'Roboto' }}
      searchBar={false}
      // actionsPanel={
      //     <DataTableWrapperActionButton
      //         startIcon={<Add />}
      //     >
      //         Add New Node
      //     </DataTableWrapperActionButton>
      // }
    >
      <DataTable
        columns={columns}
        data={data}
        {...nodePaging}
        {...nodeSorting}
      />
    </DataTableWrapper>
  );
}

export default NodesList;
