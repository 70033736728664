import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useStoreUnitActions } from 'src/redux/helpers';
import PageCardLayout from 'src/components/PageCardLayout';
import SolutionDeviceDeploymentReleaseSelect from './components/SolutionDeviceDeploymentReleaseSelect';
import solutionsStore, { useSolutionDeviceDetails } from '../redux';
import SolutionDeviceDeploymentLog from './components/SolutionDeviceDeploymentLog';
import SoluitonDeviceDeploymentActions from './components/SoluitonDeviceDeploymentActions';
import SolutionDeviceDeploymentDetails from './components/SolutionDeviceDeploymentDetails';

function SolutionDeviceDeployment() {
  const { solutionId, deviceId } = useParams();
  const { data } = useSolutionDeviceDetails();
  const actions = useStoreUnitActions(solutionsStore);
  const [releaseName, setReleaseName] = useState<string>('');

  useEffect(() => {
    if (solutionId && deviceId) {
      actions.deviceDetails({ solutionId, deviceId });
    }
  }, [solutionId, deviceId]);

  return (
    <PageCardLayout title="Device deployment">
      <SolutionDeviceDeploymentDetails item={data} />
      <SolutionDeviceDeploymentReleaseSelect
        onReleaseChange={(name) => setReleaseName(name)}
        releaseName={releaseName}
      />
      <SolutionDeviceDeploymentLog deviceId={deviceId} />
      <SoluitonDeviceDeploymentActions releaseName={releaseName} />
    </PageCardLayout>
  );
}

export default SolutionDeviceDeployment;
