export enum SiteUserItemField {
  Email = 'userId',
  Role = 'role',
}

export type SiteUserItem = {
  userId: string;
  role: SiteUserRole;
};

export enum SiteUserRole {
  Owner = 'owner',
  User = 'user',
}
