// api call slice actions
export const ON_FAILURE = 'FAILURE';
export const ON_SUCCESS = 'SUCCESS';
export const ON_REQUEST = 'REQUEST';

// restful slice actions
export const ADD = 'ADD';
export const CLEAR = 'CLEAR';
export const INIT = 'INIT';
export const UPDATE = 'UPDATE';
