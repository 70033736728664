import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import { Dispatch } from 'redux';
import {
  createAction,
  createReducer,
  PayloadActionCreator,
} from '@reduxjs/toolkit';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import forEach from 'lodash/forEach';
import { SagaAction } from './types';
import { ON_FAILURE, ON_SUCCESS, ON_REQUEST } from './actionTypes';

export type Reducer<P, S> =
  | undefined
  | ((
      state: S,
      action: { type: string; payload: P; error?: any; meta?: any }
    ) => any);

export type Reducers<P, S> = {
  ON_SUCCESS?: Reducer<P, S>;
  ON_FAILURE?: Reducer<any, S>;
  ON_REQUEST?: Reducer<P, S>;
  [key: string]: Reducer<any, S>;
};

export type CreateApiCallSliceOptions<Payload, State, Result> = {
  api: (payload: Payload) => Promise<Result>;
  name: string;
  initialState?: State;
  reducers: Reducers<Result, State>;
  sagas?: {
    ON_SUCCESS?: SagaAction;
    ON_FAILURE?: SagaAction;
    ON_REQUEST?: SagaAction;
    [key: string]: undefined | SagaAction;
  };
  selectors?: { [key: string]: (state: any, props?: any) => any };
};

export function createApiCallActions<P>(name: string) {
  const successAction = createAction(
    `${name}_${ON_SUCCESS}`,
    (payload, identifier, meta) => ({ payload, meta: { ...meta, identifier } })
  );
  const failureAction = createAction(
    `${name}_${ON_FAILURE}`,
    (payload, error, meta) => ({
      payload,
      error,
      meta: { ...meta, identifier: payload },
    })
  );
  type PrepareAction = (...args: any[]) => {
    payload: P;
    meta: any;
  };
  type ActionType = {
    success?: any;
    failure?: any;
  } & PayloadActionCreator<
    ReturnType<PrepareAction>['payload'],
    string,
    PrepareAction
  >;

  const requestAction: ActionType = createAction<PrepareAction>(
    `${name}_${ON_REQUEST}`,
    (payload, meta) => ({
      payload,
      meta: {
        ...meta,
        $async: {
          success: {
            type: successAction.type,
            resolution: 'resolve',
            meta: { identifier: payload },
          },
          failure: {
            type: failureAction.type,
            resolution: 'reject',
            meta: { identifier: payload },
          },
        },
      },
    })
  );
  requestAction.success = successAction;
  requestAction.failure = failureAction;

  return {
    requestAction,
    successAction,
    failureAction,
  };
}

export const createApiCallSaga = (api, { successAction, failureAction }) =>
  function* onRequested(action) {
    let data;
    const state = yield select((state) => state);
    const { ...meta } = action.meta;
    try {
      data = yield call(api, action.payload, state);
    } catch (e: any) {
      return yield put(
        failureAction(
          action.payload,
          { message: e.message, code: e.code },
          meta
        )
      );
    }

    return yield put(successAction(data, action.payload, meta));
  };

export function createApiCallSlice<P, S, R = P>(
  options: CreateApiCallSliceOptions<P, S, R>
) {
  const { name, api, initialState, sagas, reducers, ...rest } = options;
  const { requestAction, successAction, failureAction } =
    createApiCallActions(name);
  const onRequested = createApiCallSaga(api, { successAction, failureAction });

  const reducer = createReducer(initialState as S, (builder) => {
    forEach(reducers || {}, (reducer: any, event) => {
      builder.addCase(`${name}_${event}`, reducer);
    });
  });

  return {
    *saga() {
      const effects = mapValues(
        mapKeys(sagas || {}, (value, key) => `${name}_${key}`),
        (saga: SagaAction, event: string) => takeEvery(event, saga)
      );
      yield all({
        [requestAction.type]: takeEvery(requestAction, onRequested),
        ...effects,
      });
    },
    name,
    reducer,
    actions: requestAction,
    initialState,
    dispatchers: (dispatch: Dispatch<any>) => (params, meta) =>
      dispatch(requestAction(params, meta)),
    ...rest,
  };
}
