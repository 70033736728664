import { generatePath, useParams } from 'react-router-dom';
import ModalDialog, {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { useAlert, AlertType } from 'src/components/AlertsProvider';
import { useForm } from 'src/components/Form';
import { companyUserEditFormFields } from '../formFields';
import { CompanyUserItem } from '../../types';
import { API_COMPANY_USERS } from '../../constants';

type Props = {
  item: CompanyUserItem;
  open: boolean;
  onClose: ModalCloseHandler;
};

function CompanyUsersEditModal({ item, open, onClose }: Props) {
  const { showAlert } = useAlert();
  const { companyId } = useParams();
  const [onSubmit, loading] = useStatefulApi(
    (params) =>
      pepApiRequest({
        method: RequestMethod.Put,
        url: generatePath(API_COMPANY_USERS, { companyId }),
        params,
      }),
    () => {
      showAlert({
        type: AlertType.Success,
        title: 'Company user updated',
        text: 'Company user has been successfully updated.',
      });
      onClose(null, ModalCloseReason.completeAction);
    }
  );

  const [form, handleSubmit] = useForm({
    fields: companyUserEditFormFields,
    values: item,
    onSubmit,
  });

  return (
    <ModalDialog
      open={open}
      title={`Edit user: ${item?.userId}`}
      loading={loading}
      onClose={onClose}
      onConfirm={handleSubmit}
      confirmBtnText="Save"
      maxWidth="sm"
    >
      {form}
    </ModalDialog>
  );
}

export default CompanyUsersEditModal;
