import { useContext, useEffect } from 'react';
import isArray from 'lodash/isArray';
import { API_ERROR_EVENT, ErrorResponseBody } from 'src/services';
import AlertContext, { AlertHandlerProps, AlertType } from './AlertContext';

export const useAlert = () => useContext(AlertContext);

export const useApiErrorAlert = (
  showAlert: (props: AlertHandlerProps) => void
) => {
  const handleApiError = ({ detail }: CustomEvent<ErrorResponseBody>) => {
    const { errors,skipAlert } = detail;
    if (isArray(errors) && !skipAlert) {
      errors.forEach(({ title, detail: text }) =>
        showAlert({
          type: AlertType.Error,
          title,
          text,
        })
      );
    }
  };

  useEffect(() => {
    document.addEventListener(API_ERROR_EVENT, handleApiError as EventListener);
    return () => {
      document.removeEventListener(
        API_ERROR_EVENT,
        handleApiError as EventListener
      );
    };
  });
};
