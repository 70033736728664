import { useNavigate } from 'react-router-dom';
import { Box, Typography, IconButton } from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import DataTable, {
  DataTableWrapper,
  getActionsColConfig,
} from 'src/components/DataTable';
import { PageCardLoader } from 'src/components/PageCardLayout';
import {
  SolutionNamespaceRscLimitItemField,
  SolutionNamespaceRscQuotasDetailItem,
} from '../../types';
import SolutionSiteClusterListAction from './SolutionSiteClusterListAction';
import SolutionNamespaceQuotasUsage from './SolutionNamespaceQuotasUsage';

const cellStyle = {
  border: 0,
  height: 'auto',
  padding: '0px 5px 25px 5px',
  lineHeight: '2rem',
  fontSize: '16px',
};
const LabelStyle = {
  ...cellStyle,
  fontWeight: 'bold',
  width: '15%',
};

interface StorageAccountDetailsProps {
  data: SolutionNamespaceRscQuotasDetailItem[] | undefined;
  rscLimitData: SolutionNamespaceRscQuotasDetailItem[] | undefined;
  siteId: string;
  namespaceName: string | undefined;
  loading: boolean | undefined;
  payload: any;
}

const columns = [
  { id: SolutionNamespaceRscLimitItemField.ClusterName, title: 'Cluster Name' },
  {
    id: SolutionNamespaceRscLimitItemField.CPULimitStatus,
    title: 'CPU Limit(milicores)',
    Component: SolutionNamespaceQuotasUsage,
  },
  {
    id: SolutionNamespaceRscLimitItemField.CPUReservationStatus,
    title: 'CPU Reservation(milicores)',
    Component: SolutionNamespaceQuotasUsage,
  },
  {
    id: SolutionNamespaceRscLimitItemField.MemoryLimitStatus,
    title: 'Memory Limit(MiB)',
    Component: SolutionNamespaceQuotasUsage,
  },
  {
    id: SolutionNamespaceRscLimitItemField.MemoryReservationStatus,
    title: 'Memory Reservation(MiB)',
    Component: SolutionNamespaceQuotasUsage,
  },
  {
    id: SolutionNamespaceRscLimitItemField.StorageLimitStatus,
    title: 'Storage Limit(MiB)',
    Component: SolutionNamespaceQuotasUsage,
  },
  getActionsColConfig(SolutionSiteClusterListAction),
];

export const SolutionNamespaceRscLimitDetails = ({
  data = [],
  rscLimitData = [],
  siteId,
  namespaceName,
  loading,
  payload,
}: StorageAccountDetailsProps) => {
  const navigate = useNavigate();
  const alteredResponse = rscLimitData
    .map((item) => {
      const result = data?.find((obj) => obj.clusterId === item.clusterId);
      if (result) {
        return {
          ...item,
          ...result,
        };
      }
      return item;
    })
    .filter((obj) => Object.keys(obj).length > 0);
  const quotaLimitsData: SolutionNamespaceRscQuotasDetailItem[] =
    alteredResponse.map((item) => ({
      ...item,
      requestsCpuStatus: [
        item?.requestsCpu ? item?.requestsCpu?.replace('m', 'mCPUs') : '–',
        item?.usedRequestsCpu
          ? item?.usedRequestsCpu?.replace('m', 'mCPUs')
          : '0',
      ],
      requestsMemoryStatus: [
        item?.requestsMemory ? item?.requestsMemory?.replace('mi', 'MiB') : '–',
        item?.usedRequestsMemory
          ? item?.usedRequestsMemory?.replace('mi', 'MiB').replace('Gi', 'GiB')
          : '0',
      ],
      limitsCpuStatus: [
        item?.limitsCpu ? item?.limitsCpu?.replace('m', 'mCPUs') : '–',
        item?.usedLimitsCpu ? item?.usedLimitsCpu?.replace('m', 'mCPUs') : '0',
      ],
      limitsMemoryStatus: [
        item?.limitsMemory ? item?.limitsMemory?.replace('m', 'mCPUs') : '–',
        item?.usedLimitsMemory
          ? item?.usedLimitsMemory?.replace('mi', 'MiB').replace('Gi', 'GiB')
          : '0',
      ],
      requestsStorageStatus: [
        item?.requestsStorage
          ? item?.requestsStorage?.replace('m', 'mCPUs')
          : '–',
        item?.usedRequestsStorage
          ? item?.usedRequestsStorage?.replace('mi', 'MiB').replace('Gi', 'GiB')
          : '0',
      ],
      limitsCpu: item?.limitsCpu ? item?.limitsCpu?.replace('m', 'mCPUs') : '–',
      limitsMemory: item?.limitsMemory
        ? item?.limitsMemory?.replace('mi', 'MiB')
        : '–',
      requestsCpu: item?.requestsCpu
        ? item?.requestsCpu?.replace('m', 'mCPUs')
        : '–',
      requestsMemory: item?.requestsMemory
        ? item?.requestsMemory?.replace('mi', 'MiB')
        : '–',
      requestsStorage: item?.requestsStorage
        ? item?.requestsStorage?.replace('mi', 'MiB')
        : '–',
    }));

  const newData: SolutionNamespaceRscQuotasDetailItem[] = quotaLimitsData.map(
    (item) => ({
      ...item,
      payload,
      siteId,
    })
  );

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          backgroundColor: 'white',
          zIndex: 1000,
          paddingBottom: '10px',
        }}
      >
        <Box
          display="flex"
          justifyContent="flex-end"
          sx={{ paddingRight: '20px' }}
        >
          <IconButton
            size="small"
            title="Help center"
            onClick={() =>
              navigate(
                '/help-center/docs/IndustrialSetup/AdditionalPoints/QuotaLimits/'
              )
            }
          >
            <HelpOutlineOutlinedIcon fontSize="small" />
          </IconButton>
        </Box>
        <Box display="flex">
          <Typography sx={[cellStyle, LabelStyle]}>Namespace </Typography>
          <Typography sx={cellStyle}>{namespaceName ?? '-'}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          overflowY: 'auto',
          flexGrow: 1,
        }}
      >
        <DataTableWrapper searchBar={false}>
          <DataTable columns={columns} data={newData} paging={false} />
        </DataTableWrapper>
        {loading && <PageCardLoader />}
      </Box>
    </Box>
  );
};
