import { RouteProps } from 'src/router';
import SolutionJobsList from './components/SolutionJobsList';
import SolutionDeviceBulkDeployment from '../SolutionDeviceBulkDeployment';
import { SOLUTION_JOB_DETAILS } from '../constants';
import { SolutionFeature } from '../types';

export const routes: RouteProps[] = [
  {
    index: true,
    component: SolutionJobsList,
  },
  {
    path: SOLUTION_JOB_DETAILS,
    component: SolutionDeviceBulkDeployment,
    allowedFeatures: [SolutionFeature.Mender],
    meta: {
      title: 'Job details',
    },
  },
];
