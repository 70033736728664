import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import {
  SolutionConsumerFeatures,
  SolutionIndustrialFeatures,
  SolutionItem,
  SolutionType,
} from '../../types';

type Props = {
  value?: SolutionConsumerFeatures[] | SolutionIndustrialFeatures[];
  item?: SolutionItem;
};
function SolutionFeaturesTitle({ value, item }: Props) {
  const { solutionType } = useParams();
  const solutionFeatures: any = {
    isMenderEnabled: 'Mender',
    isDataIngested: 'Data Ingestion in PEPSense',
    isSmsAccountCreation: 'SMS notification',
    isFileSubscriptionService:
      solutionType === SolutionType.ioTH
        ? 'Device file upload service'
        : 'File subscription service',
    isSubscriptionService: 'Subscription Service',
    isTelemetryStreamProcessing: 'Telemetry Stream Processing',
    isAlarmEventProcessing: ' Alarm/Event Processing',
  };

  return (
    <Box>{value?.map((feature) => solutionFeatures[feature]).join(', ')}</Box>
  );
}

export default SolutionFeaturesTitle;
