import { ComponentType, MouseEventHandler } from 'react';
import { SxProps } from 'src/theme/types';

export enum CommonColumn {
  Action = 'action',
  Checkbox = 'checkbox',
  GroupToggle = 'groupToggle',
}

export enum PagingType {
  Local = 'local',
  Remote = 'remote',
}

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export type SimpleValue = string | number | boolean;
export type Value = SimpleValue | SimpleValue[];
export type Row = Record<string, Value>;
export type RowActionHandler = MouseEventHandler | null | false;
export type RowKeyValue = string | number;
export type SelectedItems = Record<RowKeyValue, boolean>;
export type SelectRowHandler<TRow> = (selected: boolean, row: TRow) => void;
export type SelectAllHandler = (selected: boolean) => void;
export type ToggleGroupHandler<TRow> = (expanded: boolean, row: TRow) => void;
export type GroupRowMeta = { isExpanded: boolean; isExpandable?: boolean };
export type GroupRowsMeta = Record<string, GroupRowMeta>;
export type SortOrder = SortDirection | null;
export type SortHandler = (columnName: string, sortOrder?: SortOrder) => void;

export type CellProps<T = Value, R = Row> = {
  value?: T;
  item?: R;
  id?: string;
  selected?: boolean;
  onSelect?: (selected: boolean, row?: R) => void;
  isGroup?: boolean;
  expandable?: boolean;
  expanded?: boolean;
  onToggleGroup?: ToggleGroupHandler<T>;
  onSort?: SortHandler;
  sortOrder?: SortOrder;
  sortable?: boolean;
};

export type Column<TRow = Row> = {
  id: string;
  title?: string;
  Component?: ComponentType<CellProps<any, TRow>>;
  HeaderComponent?: ComponentType<CellProps<SimpleValue, TRow>>;
  sortable?: boolean;
  sx?: SxProps;
};
