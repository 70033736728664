import { baseTheme } from '../baseTheme';

const { palette, typography, spacing } = baseTheme;

export default {
  styleOverrides: {
    root: {
      color: palette.neutral.main,
      display: 'flex',
      '& .MuiBreadcrumbs-ol': {
        alignItems: 'center',
      },
      '& .MuiBreadcrumbs-li': {
        display: 'inline-flex',
        alignItems: 'center',
      },
      '& .MuiSvgIcon-root': {
        fontSize: spacing(2.2),
        height: spacing(2.2),
        marginRight: spacing(0.5),
      },
      '& .MuiTypography-root': {
        display: 'inline-flex',
        alignItems: 'center',
        ...typography.body3md,
        '&:hover': {
          textDecoration: 'none',
        },
      },
    },
  },
};
