import { useCallback, useMemo } from 'react';
import { useQueryStateValues } from 'src/hooks/queryHooks';
import { DEFAULT_PAGE_SIZE } from '../constants';
import { SortHandler, SortOrder } from '../types';

type TablePagingQueryProps = {
  pageIndex?: number;
  pageSize?: number;
  onPageIndexChange: (index: number) => void;
  onPageSizeChange: (size: number) => void;
};

type TableSearchQueryProps = {
  searchValue?: string;
  onSearch: (value: string) => void;
};

type TableSortingQueryProps = {
  sortBy?: string;
  sortOrder?: SortOrder;
  onSort: SortHandler;
};

export type TableQueryProps = {
  paging: TablePagingQueryProps;
  search: TableSearchQueryProps;
  sorting: TableSortingQueryProps;
};

type TableQueryParams = {
  pageIndex?: number;
  pageSize?: number;
  search?: string;
  sortBy?: string;
  sortOrder?: SortOrder;
};

const defaultTableQueryParams: TableQueryParams = {
  pageIndex: 0,
  pageSize: DEFAULT_PAGE_SIZE,
  search: '',
  sortBy: '',
  sortOrder: null,
};

export const useTableQueryParams = (): TableQueryProps => {
  const [params, setParams] = useQueryStateValues<TableQueryParams>(
    defaultTableQueryParams
  );
  const { pageIndex, pageSize, search, sortBy, sortOrder } = params;

  const onPageIndexChange = useCallback(
    (pageIndex: number) => {
      setParams({ pageIndex });
    },
    [setParams]
  );

  const onPageSizeChange = useCallback(
    (pageSize: number) => {
      setParams({ pageSize });
    },
    [setParams]
  );

  const onSearch = useCallback(
    (search: string) => {
      setParams({ search });
    },
    [setParams]
  );

  const onSort = useCallback(
    (sortBy: string, sortOrder?: SortOrder) => {
      setParams({ sortBy, sortOrder });
    },
    [setParams]
  );

  return {
    paging: useMemo(
      () => ({
        pageIndex: Number(pageIndex),
        pageSize: Number(pageSize),
        onPageIndexChange,
        onPageSizeChange,
      }),
      [pageIndex, pageSize]
    ),
    search: useMemo(
      () => ({
        searchValue: search,
        onSearch,
      }),
      [search]
    ),
    sorting: useMemo(
      () => ({
        sortBy,
        sortOrder,
        onSort,
      }),
      [sortBy, sortOrder]
    ),
  };
};
