import {
  KibanaSpaceType,
  SolutionConsumerFeatures,
  SolutionIndustrialFeatures,
  SolutionItem,
  SubscriptionType,
} from '../types';

export const getMendorPerfixParms = (params) => {
  const ismendorEnabled = params.solutionConsumerFeatures?.includes(
    SolutionConsumerFeatures.IsMenderEnabled
  );

  return { ismendorEnabled, releasePrefix: params?.ReleasePrefix };
};

export const getSolutionEditParams = (
  params: SolutionItem,
  disableCacheRetention?: boolean
) => {
  const dataIngestion =
    params.solutionConsumerFeatures?.includes(
      SolutionConsumerFeatures.IsDataIngested
    ) ||
    params.solutionIndustrialFeatures?.includes(
      SolutionIndustrialFeatures.IsDataIngested
    );
  const fileSubscriptionService =
    params.solutionConsumerFeatures?.includes(
      SolutionConsumerFeatures.IsFileSubscriptionService
    ) || params.subscriptionType?.includes(SubscriptionType.Files);
  const mender = params.solutionConsumerFeatures?.includes(
    SolutionConsumerFeatures.IsMenderEnabled
  );
  const smsCreation =
    params.solutionConsumerFeatures?.includes(
      SolutionConsumerFeatures.IsSmsAccountCreation
    ) ||
    params.solutionIndustrialFeatures?.includes(
      SolutionIndustrialFeatures.IsSmsAccountCreation
    );

  const isMessageSubscriptionService = params.subscriptionType?.includes(
    SubscriptionType.Messages
  );
  const isAlarmEventProcessing = params.solutionIndustrialFeatures?.includes(
    SolutionIndustrialFeatures.IsAlarmEventProcessing
  );
  const isTelemetryStreamProcessing =
    params.solutionIndustrialFeatures?.includes(
      SolutionIndustrialFeatures.IsTelemetryStreamProcessing
    ) ?? false;

  const getKibanaValue = () => {
    if (params.kibanaSpaceType) {
      return params.kibanaSpaceType?.toLowerCase() === 'global'
        ? KibanaSpaceType.Global
        : KibanaSpaceType.Confidential;
    }
    return params.kibanaSpaceType;
  };
  delete params.solutionConsumerFeatures;
  delete params.solutionIndustrialFeatures;
  delete params.subscriptionType;
  const {
    UnlimitedCache,
    UnlimitedCacheArr,
    UnlimitedRetention,
    UnlimitedRetentionArr,
    CachePeriod,
    RetentionPeriod,
    isDataIngested,
    isFileSubscriptionService,
    isMenderEnabled,
    isSmsAccountCreation,
    solutionConsumerFeatures,
    fileSubscription,
    telemetryStreamProcessing,
    ...rest
  } = params;
  const isUnlimitedCache = UnlimitedCacheArr?.length !== 0;
  const isUnlimitedRetention = UnlimitedRetentionArr?.length !== 0;

  return {
    ...rest,
    kibanaSpaceType: getKibanaValue(),
    CachePeriod: isUnlimitedCache ? '' : CachePeriod,
    RetentionPeriod: isUnlimitedRetention ? '' : RetentionPeriod,
    UnlimitedCache: isUnlimitedCache ?? false,
    UnlimitedRetention: isUnlimitedRetention ?? false,
    IsDataIngestionEnabled: dataIngestion ?? false,
    IsFileSubscriptionServiceEnabled: fileSubscriptionService ?? false,
    isMenderEnabled: mender ?? false,
    releasePrefix: params.releasePrefix ?? '',
    isSmsAccountCreation: smsCreation ?? false,
    isMessageSubscriptionServiceEnabled: isMessageSubscriptionService ?? false,
    isTelemetryStreamProcessingEnabled: isTelemetryStreamProcessing,
    isAlarmEventsStreamProcessingEnabled: isAlarmEventProcessing ?? false,
    fileSubscriptionType: fileSubscription ?? '',
    telemetryStreamProcessingType: telemetryStreamProcessing ?? '',
  };
};
