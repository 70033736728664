import { FormArrayRow, FormArrayRowProps } from 'src/components/Form';
import { getPropertyChildFields } from './formFields';
import {
  useSolutionDeviceGroupOnlyWritablePropOptions,
  useSolutionDeviceGroupProperties,
  useSolutionDevicePropertyMeta,
} from '../redux';

function GetChildPropertyUpdateFields({
  childFields,
  update,
  values,
  ...rest
}: FormArrayRowProps) {
  const [propertyOptions, propertiesLoading] =
    useSolutionDeviceGroupOnlyWritablePropOptions();
  const [propertiesData, propertiesDataLoading] =
    useSolutionDeviceGroupProperties();
  const { operators: operatorOptions, enums: filterValueOptions } =
    useSolutionDevicePropertyMeta(values?.propertyName);
  const propertyData = propertiesData?.filter(
    (a) => a.id === values?.propertyName
  )[0];

  const onPropertyChange = ({ target }) =>
    update && update(rest.index, { propertyName: target.value });
  return (
    <FormArrayRow
      childFields={getPropertyChildFields(
        onPropertyChange,
        propertyData,
        propertyOptions,
        filterValueOptions,
        propertiesLoading
      )}
      {...rest}
    />
  );
}

export default GetChildPropertyUpdateFields;
