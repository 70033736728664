import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Add from '@mui/icons-material/Add';
import DataTable, {
  DataTableWrapper,
  DataTableWrapperActionButton,
  getActionsColConfig,
  useTableQueryParams,
} from 'src/components/DataTable';
import { useStoreUnitActions } from 'src/redux/helpers';
import { useModal } from 'src/hooks';
import DataTableContext from 'src/components/DataTable/components/DataTableContext';
import SignatureWhitelistAction from './components/SignatureWhitelistAction';
import SignatureWhitelistCreateModal from './components/SignatureWhitelistCreateModal';
import { useSolutionUserRoles } from '../hooks';
import solutionsStore, { useSolutionSignatureWhitelist } from '../redux';
import { SolutionSignatureWhitelistField } from '../types';

const columns = [
  { id: SolutionSignatureWhitelistField.Signature, title: 'OS signature' },
  { id: SolutionSignatureWhitelistField.Description, title: 'Description' },
  getActionsColConfig(SignatureWhitelistAction),
];

const SolutionSignatureWhitelist = () => {
  const { solutionId } = useParams();
  const actions = useStoreUnitActions(solutionsStore);
  const { data, loading } = useSolutionSignatureWhitelist();
  const { isSolutionEditor } = useSolutionUserRoles();
  const { paging, search, sorting } = useTableQueryParams();

  const [AddNewSignatureDialog, showAddNewSignatureDialog] = useModal(
    SignatureWhitelistCreateModal,
    { title: 'Add OS signature' }
  );

  useEffect(() => {
    actions.signatureWhitelist(solutionId);
  }, [solutionId]);

  const filteredData = data.filter(
    (item, index, self) => index === self.findIndex((t) => t.id === item.id)
  );

  return (
    <DataTableContext {...search}>
      <DataTableWrapper
        data-testid="solution-signature-whitelist-wrapper"
        mt={3}
        mb={4}
        title="OS signature whitelist"
        actionsPanel={
          <DataTableWrapperActionButton
            startIcon={<Add />}
            onClick={showAddNewSignatureDialog}
            disabled={!isSolutionEditor}
          >
            New
          </DataTableWrapperActionButton>
        }
      >
        <DataTable
          columns={columns}
          data={filteredData}
          loading={loading}
          {...paging}
          {...sorting}
        />
        {AddNewSignatureDialog}
      </DataTableWrapper>
    </DataTableContext>
  );
};

export default SolutionSignatureWhitelist;
