import React from 'react';
import { useParams } from 'react-router-dom';
import { DataTableActions } from 'src/components/DataTable';
import { isModalComplete } from 'src/components/ModalDialog';
import { useModal } from 'src/hooks';
import { useStoreUnitActions } from 'src/redux/helpers';
import { useSolutionUserRoles } from '../../hooks';
import { SolutionCosmosSubscriptionItem } from '../../types';
import { solutionsStore } from '../../index';
import SolutionCosmosSubscriptionEditContainer from './SolutionCosmosSubscriptionEditContainer';
import SolutionCosmosSubscriptionDeleteModal from './SolutionCosmosSubscriptionDeleteModal';

type Props = {
  item?: SolutionCosmosSubscriptionItem;
};

function SolutionCosmosSubscriptionActions({ item }: Props) {
  const { isSolutionEditor } = useSolutionUserRoles();
  const { solutionId } = useParams();
  const actions = useStoreUnitActions(solutionsStore);
  const onClose = (event, reason) =>
    isModalComplete(reason) && actions.cosmosSubscriptionById(solutionId);

  const [deleteDialog, showDeleteDialog] = useModal(
    SolutionCosmosSubscriptionDeleteModal,
    { item, onClose }
  );

  const [editDialog, showEditDialog] = useModal(
    SolutionCosmosSubscriptionEditContainer,
    {
      item,
      onClose,
    }
  );

  return (
    <DataTableActions
      canEdit={isSolutionEditor}
      canDelete={isSolutionEditor}
      onEdit={showEditDialog}
      onDelete={showDeleteDialog}
    >
      {deleteDialog}
      {editDialog}
    </DataTableActions>
  );
}

export default SolutionCosmosSubscriptionActions;
