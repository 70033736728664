import React from 'react';
import { RouterBreadcrumbs } from 'src/router';
import MonitorDashboards from './components/MonitorDashboards';

function Monitoring() {
  return (
    <>
      <RouterBreadcrumbs />
      <MonitorDashboards />
    </>
  );
}

export default Monitoring;
