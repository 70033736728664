import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Add from '@mui/icons-material/Add';
import DataTable, {
  DataTableWrapper,
  DataTableWrapperActionButton,
  getActionsColConfig,
} from 'src/components/DataTable';
import { useStoreUnitActions } from 'src/redux/helpers';
import { useModal } from 'src/hooks';
import { useSolutionUserRoles } from '../hooks';
import solutionsStore, { useSolutionsWhitelist } from '../redux';
import SolutionFilesWhitelistAction from './components/SolutionFilesWhitelistAction';
import { SolutionFilesWhitelistField } from '../types';
import SolutionFilesWhitelistCreateModal from './components/SolutionFilesWhitelistCreateModal';

const columns = [
  { id: SolutionFilesWhitelistField.Extension, title: 'File extension' },
  {
    id: SolutionFilesWhitelistField.MaxFileSize,
    title: 'Maximum file size (kb)',
  },
  getActionsColConfig(SolutionFilesWhitelistAction),
];

function SolutionsFilesWhitelist() {
  const { solutionId } = useParams();
  const actions = useStoreUnitActions(solutionsStore);
  const { data, loading } = useSolutionsWhitelist();
  const { isSolutionEditor } = useSolutionUserRoles();
  const [AddNewFileDialog, showAddNewFileDialog] = useModal(
    SolutionFilesWhitelistCreateModal,
    { title: 'Add file extension' }
  );

  useEffect(() => {
    actions.filesWhitelist(solutionId);
  }, [solutionId]);

  return (
    <DataTableWrapper
      data-testid="solution-files-whitelist-wrapper"
      my={3}
      mb={4}
      title="File extension whitelist"
      actionsPanel={
        <DataTableWrapperActionButton
          onClick={showAddNewFileDialog}
          startIcon={<Add />}
          disabled={!isSolutionEditor}
        >
          New
        </DataTableWrapperActionButton>
      }
      searchBar={false}
    >
      <DataTable columns={columns} data={data} loading={loading} />
      {AddNewFileDialog}
    </DataTableWrapper>
  );
}

export default SolutionsFilesWhitelist;
