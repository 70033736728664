import React, { useContext, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import HeaderContext from '../HeaderContext';

const langOptions = ['English', 'German', 'Polish'];

function HeaderLangMenu() {
  const [isOpen, setOpen] = useState(false);
  const [lang, setLang] = useState('English');
  const buttonRef = useRef(null);
  const anchorEl = buttonRef.current;
  const { color } = useContext(HeaderContext);

  const handleChange = (event: any) => {
    setLang(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <FormControl variant="filled">
      <Button
        data-testid="lang-menu-btn"
        sx={{
          color,
          px: ({ spacing }) => spacing(1.5),
          minWidth: ({ spacing }) => spacing(10),
        }}
        ref={buttonRef}
        aria-controls="lang-select-menu"
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={handleOpen}
        endIcon={isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      >
        {lang}
      </Button>
      <Select
        sx={{ display: 'none' }}
        value={lang}
        onChange={handleChange}
        open={isOpen}
        onClose={handleClose}
        MenuProps={{ anchorEl }}
      >
        {langOptions.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default HeaderLangMenu;
