import React from 'react';
import Box from '@mui/material/Box';
import CircularLoader from 'src/components/CircularLoader';
import { SxProps } from 'src/theme/types';

const backdropStyle: SxProps = {
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  backgroundColor: ({ palette, alpha }) => alpha(palette.white, 0.7),
};

const stickyBoxStyle: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  maxHeight: '65vh',
  height: '100%',
  width: '100%',
  position: 'sticky',
  top: ({ spacing }) => spacing(15),
};

function DataTableLoader() {
  return (
    <Box data-testid="data-table-loader" sx={backdropStyle}>
      <Box sx={stickyBoxStyle}>
        <CircularLoader />
      </Box>
    </Box>
  );
}

export default DataTableLoader;
