import { combineReducers } from 'redux';
import { authStore } from 'src/auth';
import { solutionsStore } from 'src/pages/Solutions';
import { companiesStore } from 'src/pages/Companies';
import { otpStore } from 'src/pages/Otp';
import { usersStore } from 'src/pages/Users';
import { sitesStore } from 'src/pages/Sites';

const rootReducer = combineReducers({
  auth: authStore.reducer,
  solutions: solutionsStore.reducer,
  companies: companiesStore.reducer,
  otp: otpStore.reducer,
  users: usersStore.reducer,
  sites: sitesStore.reducer,
});

export default rootReducer;
