import React from 'react';
import DataTable, { DataTableWrapper } from 'src/components/DataTable';
import DateFormattedValue from 'src/components/DateFormattedValue';
import SolutionDeviceDeploymentLogStatus from '../../components/SolutionDeviceDeploymentLogStatus';
import {
  SolutionDeviceBulkDeploymentLogField,
  SolutionDeviceBulkDeploymentLogItem,
} from '../../types';

const columns = [
  {
    id: SolutionDeviceBulkDeploymentLogField.DeviceName,
    title: 'Device name',
  },
  {
    id: SolutionDeviceBulkDeploymentLogField.Started,
    title: 'Started',
    Component: DateFormattedValue,
  },
  {
    id: SolutionDeviceBulkDeploymentLogField.Finished,
    title: 'Finished',
    Component: DateFormattedValue,
  },
  {
    id: SolutionDeviceBulkDeploymentLogField.DeploymentStatus,
    title: 'Deployment status',
    Component: SolutionDeviceDeploymentLogStatus,
  },
];

type Props = {
  logs: SolutionDeviceBulkDeploymentLogItem[];
};

function SolutionDeviceBulkDeploymentLog({ logs }: Props) {
  return (
    <DataTableWrapper title="Deployment log">
      <DataTable
        groupBy={SolutionDeviceBulkDeploymentLogField.DeviceName}
        data={logs}
        columns={columns}
      />
    </DataTableWrapper>
  );
}

export default SolutionDeviceBulkDeploymentLog;
