import { keyframes } from '@emotion/react';
import Box, { BoxProps } from '@mui/material/Box';
import { SxProps } from 'src/theme/types';

const spinnerAnimation = keyframes({
  from: {
    transform: 'rotate(0deg)',
  },
  to: {
    transform: 'rotate(360deg)',
  },
});

const style: SxProps = {
  width: ({ spacing }) => spacing(11),
  height: ({ spacing }) => spacing(11),
  borderWidth: ({ spacing }) => spacing(0.8),
  borderRadius: '50%',
  borderStyle: 'solid',
  borderColor: 'warning.main',
  borderLeftColor: 'primary.light',
  borderTopColor: 'primary.main',
  animation: `${spinnerAnimation} 2s linear infinite`,
};

function CircularLoader({ sx, ...rest }: BoxProps) {
  return (
    <Box
      data-testid="circular-loader"
      sx={{ ...style, ...sx } as SxProps}
      {...rest}
    />
  );
}

export default CircularLoader;
