export type ApplicationItem = {
  id: string;
  roles: string[];
  AsignRoles: string[];
  name: string;
};

export type ApplicationRoleInformation = {
  name: string;
  description: string;
};

export type ApplicationRoles = {
  id: string;
  applicationRoleInformation: ApplicationRoleInformation[];
};

export enum ApplicationItemField {
  Id = 'id',
  Roles = 'roles',
  AsignRoles = 'AsignRoles',
  Name = 'name',
}
