import React, { useState } from 'react';
import { ModalCloseHandler } from 'src/components/ModalDialog';
import { SolutionJobItem } from '../../types';
import SolutionJobEditStep from './SolutionJobEditStep';
import SolutionJobReviewStep from './SolutionJobReviewStep';

type State = {
  item?: SolutionJobItem;
  isReviewStep?: boolean;
};

type Props = {
  editStepTitle: string;
  reviewStepTitle: string;
  item?: SolutionJobItem;
  loading?: boolean;
  open: boolean;
  onClose: ModalCloseHandler;
  onSubmit: (item?: SolutionJobItem) => void;
};

function SolutionJobEditStepper({
  editStepTitle,
  reviewStepTitle,
  item,
  loading,
  open,
  onClose,
  onSubmit,
  ...rest
}: Props) {
  const [state, setState] = useState<State>({ item });

  const goReview = (item: SolutionJobItem) => {
    setState({ item, isReviewStep: true });
  };

  const goBack = () => {
    setState((state) => ({ ...state, isReviewStep: false }));
  };

  return (
    <>
      <SolutionJobEditStep
        title={editStepTitle}
        item={state?.item}
        open={open && !state?.isReviewStep}
        onClose={onClose}
        onSubmit={goReview}
        {...rest}
      />
      <SolutionJobReviewStep
        title={reviewStepTitle}
        item={state?.item}
        loading={loading}
        open={open && !!state?.isReviewStep}
        onClose={onClose}
        onSubmit={onSubmit}
        goBack={goBack}
        {...rest}
      />
    </>
  );
}

export default SolutionJobEditStepper;
