import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import * as ROUTES from 'src/router/constants';
import HeaderContext from '../HeaderContext';
import HeaderUserMenuProfileInfo from './HeaderUserMenuProfileInfo';

function HeaderUserMenu() {
  const navigate = useNavigate();
  const { oktaAuth } = useOktaAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);
  const { color } = useContext(HeaderContext);

  const menuItems = [
    {
      Icon: SettingsOutlinedIcon,
      id: 'settings',
      label: 'Settings',
      onClick: () => navigate(ROUTES.SETTINGS),
      disabled: true,
    },
    {
      Icon: LogoutIcon,
      id: 'logout',
      label: 'Logout',
      onClick: () => oktaAuth.signOut(),
    },
  ];

  const handleOpenMenu = (event?: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (callback: () => void) => {
    handleCloseMenu();
    callback();
  };

  return (
    <Box>
      <Button
        sx={{
          color,
          px: ({ spacing }) => spacing(1.5),
        }}
        data-testid="user-menu-btn"
        id="user-menu-btn"
        aria-controls="user-menu"
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={handleOpenMenu}
        endIcon={isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      >
        <PersonOutlineOutlinedIcon />
      </Button>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'user-menu-btn',
        }}
      >
        <HeaderUserMenuProfileInfo />
        {menuItems.map(({ Icon, id, label, onClick, disabled }) => (
          <MenuItem
            data-testid={`user-menu-link-${id}`}
            disabled={disabled}
            key={id}
            onClick={() => handleMenuItemClick(onClick)}
          >
            <ListItemIcon>
              <Icon fontSize="small" />
            </ListItemIcon>
            {label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}

export default HeaderUserMenu;
