import { useState } from 'react';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { SolutionAdlsSubscriptionItem } from '../../types';

const cellStyle = {
  border: 0,
  height: 'auto',
  padding: '10px 20px 10px 0px',
};
const LabelStyle = {
  fontWeight: 'bold',
  width: '23%',
};

interface AdlsSubscriptionProps {
  data: SolutionAdlsSubscriptionItem;
}

export const AdlsSubscription = ({ data }: AdlsSubscriptionProps) => {
  const { name, format, containerSaasURL, folderStructure } = data;
  const [showContainerSaasURL, setShowContainerSaasURL] = useState(false);
  const VisibilityIcon = showContainerSaasURL
    ? VisibilityOffOutlinedIcon
    : VisibilityOutlinedIcon;
  const handleShowConnectionString = () => {
    setShowContainerSaasURL((prevShowPassword: any) => !prevShowPassword);
  };

  return (
    <Box>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={[cellStyle, LabelStyle]}>
              Subscription Name
            </TableCell>
            <TableCell sx={cellStyle}>{name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={[cellStyle, LabelStyle]}>Format</TableCell>
            <TableCell sx={cellStyle}>{format}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={[cellStyle, LabelStyle]}>
              Container SAS URL
            </TableCell>
            <TableCell sx={cellStyle}>
              {showContainerSaasURL ? containerSaasURL : '*'.repeat(50)}
              &nbsp;
              {containerSaasURL && (
                <IconButton
                  onClick={handleShowConnectionString}
                  size="small"
                  title={showContainerSaasURL ? 'Hide' : 'View'}
                >
                  <VisibilityIcon sx={{ color: '#0047BA', fontSize: '20px' }} />
                </IconButton>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={[cellStyle, LabelStyle]}>
              Destination Folder Format
            </TableCell>
            <TableCell sx={cellStyle}>
              {folderStructure === '' || folderStructure === undefined
                ? '-'
                : folderStructure}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};
