import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'src/components/Form';
import ModalDialog, { ModalCloseHandler } from 'src/components/ModalDialog';
import { SolutionCosmosSubscriptionItem } from '../../types';
import { getCosmosSubscriptionEditFormFields } from '../editCosmosSubscriptionFormFields';

type Props = {
  item?: SolutionCosmosSubscriptionItem;
  title: string;
  loading?: boolean;
  open: boolean;
  onClose: ModalCloseHandler;
  onSubmit: (values: any) => void;
  confirmBtnText?: string;
};

function SolutionCosmosSubscriptionEditModal({
  item,
  title,
  loading,
  open,
  onClose,
  onSubmit,
  confirmBtnText = 'Save',
}: Props) {
  const [filters, setFilters] = useState<any[]>(item?.cosmosFilters ?? []);

  const handlePaste = () => {
    navigator.clipboard.readText().then((clipboardText) => {
      setValue('connectionString', clipboardText.trim());
    });
  };

  const onChange = useCallback(
    ({ cosmosFilters }: SolutionCosmosSubscriptionItem) => {
      setFilters(cosmosFilters);
    },
    []
  );
  const fields = useMemo(
    () => getCosmosSubscriptionEditFormFields(filters, handlePaste),
    [filters, handlePaste]
  );

  const [form, handleSubmit, setValue] = useForm({
    visibleValuesOnly: true,
    values: item,
    fields,
    onChange,
    onSubmit,
  });

  return (
    <ModalDialog
      open={open}
      title={title}
      confirmBtnText={confirmBtnText}
      onClose={onClose}
      onConfirm={handleSubmit}
      loading={loading}
      fullWidth
    >
      {form}
    </ModalDialog>
  );
}

export default SolutionCosmosSubscriptionEditModal;
