import React from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { AnalyticsOutlined } from '@mui/icons-material';
import { ReactComponent as EdgeServices } from 'src/assets/icons/EdgeServices.svg';
import {
  DataTableActions,
  DataTableActionsButton,
} from 'src/components/DataTable';
import { useUserRoles } from 'src/auth';
import { useModal } from 'src/hooks';
import { SiteItem, SiteItemStatus } from '../../types';
import { SITE_DETAILS, SITE_EDIT } from '../../constants';
import SiteEdgeServiceUrlCreateModal from './SiteEdgeServiceUrlCreateModal';
import SiteEdgeServiceUrlViewModal from './SiteEdgeServiceUrlViewModal';

type Props = {
  item?: SiteItem;
};

function SitesListAction({ item }: Props) {
  const navigate = useNavigate();
  const siteId = item?.id.toString();
  const { observability, edgeServiceUrl , status} = item as SiteItem;
  const { isPlatformReader, isPlatformOwner, isPlatformEngineer } = useUserRoles();
  const isValidUser = isPlatformOwner || isPlatformEngineer || isPlatformReader;
  const hasEdgeServiceUrl: boolean = Array.isArray(edgeServiceUrl)
    ? edgeServiceUrl.some(
      (detail) => detail !== undefined && detail !== null && detail !== ''
    )
    : edgeServiceUrl !== undefined &&
    edgeServiceUrl !== null &&
    edgeServiceUrl !== '';
  const isEdgeServiceDisabled = (isPlatformReader && !hasEdgeServiceUrl) || !isValidUser || status!==SiteItemStatus.Active

  const [edgeServicesUrlDialog, showEdgeServicesUrlDialog] = useModal(
    SiteEdgeServiceUrlCreateModal,
    {
      siteId: item?.id,
      siteName: item?.name
    }
  );

  const [edgeServicesUrlViewDialog, showEdgeServicesUrlViewDialog] = useModal(
    SiteEdgeServiceUrlViewModal,
    {
      siteId: item?.id,
      edgeServiceUrl,
    }
  );

  const handleView = () =>
    navigate(generatePath(`${SITE_DETAILS}`, { siteId }));
  const handleEdit = () =>
    navigate(generatePath(`${SITE_DETAILS}/${SITE_EDIT}`, { siteId }));
  const handleViewObservability = () => window.open(observability);
  const handleShowEdgeService = () => {
    if (hasEdgeServiceUrl) {
      showEdgeServicesUrlViewDialog();
    } else {
      showEdgeServicesUrlDialog();
    }
  };

  return (
    <DataTableActions
      canEdit={!isPlatformReader}
      canView={true}
      onView={handleView}
      onEdit={handleEdit}
      showView={true}
      showEdit={true}
      showDelete={false}
    >
      <DataTableActionsButton
        data-testid="solution-observability-visit-icon"
        icon={AnalyticsOutlined}
        title="Observability Dashboard"
        onClick={handleViewObservability}
        disabled={observability === '' || observability === undefined}
      />
      <DataTableActionsButton
        data-testid="solution-site-edge-services"
        icon={EdgeServices}
        title="Edge Service URL"
        onClick={handleShowEdgeService}
        sx={{ paddingTop: '15px', paddingLeft: '10px' }}
        disabled={isEdgeServiceDisabled}
      />
      {edgeServicesUrlViewDialog}
      {edgeServicesUrlDialog}
    </DataTableActions>
  );
}

export default SitesListAction;
