import React from 'react';
import { generatePath, useParams } from 'react-router-dom';
import ModalDialog, {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import { API_SOLUTION_DELETE_DASHBOARD } from '../../constants';

type Props = {
  item;
  open: boolean;
  onClose: ModalCloseHandler;
};

function SolutionDashboardDeleteModal({ item, open, onClose }: Props) {
  const { showAlert } = useAlert();
  const { solutionId } = useParams();
  const [onSubmit, loading] = useStatefulApi(
    () =>
      pepApiRequest({
        method: RequestMethod.Delete,
        url: generatePath(API_SOLUTION_DELETE_DASHBOARD, { solutionId }),
        params: {
          solutionId,
          Dashboards: [item],
        },
      }),
    () => {
      showAlert({
        type: AlertType.Info,
        title: 'Dashboard deleted',
        text: 'Dashboard has been deleted successfully',
      });
      onClose(null, ModalCloseReason.completeAction);
    }
  );

  return (
    <ModalDialog
      open={open}
      title="Delete dashboard"
      loading={loading}
      onConfirm={onSubmit}
      onClose={onClose}
      confirmBtnText="Yes"
      cancelBtnText="No"
    >
      Are you sure you want to delete this dashboard?
      <br />
      <b>{item.name}</b>
    </ModalDialog>
  );
}

export default SolutionDashboardDeleteModal;
