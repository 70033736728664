import { useParams } from 'react-router-dom';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import ModalDialog, {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { generateApiPath } from 'src/utils';
import { API__DELETE_FILE_SUBSCRIPTION_ITEM } from '../../constants';
import { SolutionAdlsSubscriptionItem } from '../../types';

type Props = {
  item: SolutionAdlsSubscriptionItem;
  open: boolean;
  onClose: ModalCloseHandler;
};

function SolutionAdlsSubscriptionDeleteModal({ item, open, onClose }: Props) {
  const { solutionId } = useParams();
  const { showAlert } = useAlert();
  const [handleRemove, loading] = useStatefulApi(
    () =>
      pepApiRequest({
        method: RequestMethod.Delete,
        url: generateApiPath(API__DELETE_FILE_SUBSCRIPTION_ITEM, {
          solutionId,
          subscriptionId: item?.id,
        }),
      }),
    () => {
      showAlert({
        type: AlertType.Info,
        title: `'${item?.name}' deleted`,
        text: 'ADLS subscription has been successfully deleted.',
      });
      onClose(null, ModalCloseReason.completeAction);
    }
  );

  return (
    <ModalDialog
      open={open}
      title="Delete Subscription"
      loading={loading}
      onClose={onClose}
      onConfirm={handleRemove}
      cancelBtnText="No"
      confirmBtnText="Yes"
    >
      Are you sure you want to delete the Subscription?&nbsp;
      <br />
      <b>{item?.name}</b>
    </ModalDialog>
  );
}

export default SolutionAdlsSubscriptionDeleteModal;
