import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Add from '@mui/icons-material/Add';
import DataTable, {
  DataTableWrapper,
  DataTableWrapperActionButton,
  getActionsColConfig,
} from 'src/components/DataTable';
import { useStoreUnitActions } from 'src/redux/helpers';
import { useUserRoles } from 'src/auth';
import solutionsStore, { useSolutionAlertRulesList } from '../redux';
import SolutionAlertRulesListAction from './components/SolutionAlertRulesListAction';
import { SolutionAlertRuleItemField } from '../types';
import SolutionAlertRuleStatus from './components/SolutionAlertRuleStatus';

const columns = [
  {
    id: SolutionAlertRuleItemField.RuleName,
    title: 'Rule Name',
  },
  {
    id: SolutionAlertRuleItemField.RuleType,
    title: 'Rule Type',
  },
  {
    id: SolutionAlertRuleItemField.Severity,
    title: 'Severity Type',
  },
  {
    id: SolutionAlertRuleItemField.Interval,
    title: 'Checks Every',
  },
  {
    id: SolutionAlertRuleItemField.Enabled,
    title: 'State',
    Component: SolutionAlertRuleStatus,
  },
  getActionsColConfig(SolutionAlertRulesListAction),
];

export default function SolutionAlertRules() {
  const [data, loading] = useSolutionAlertRulesList();
  const { solutionId } = useParams();
  const { isPlatformReader } = useUserRoles();
  const actions = useStoreUnitActions(solutionsStore);

  useEffect(() => {
    actions.alertRules(solutionId);
  }, []);

  return (
    <DataTableWrapper
      actionsPanel={
        <DataTableWrapperActionButton startIcon={<Add />} disabled={true}>
          Assign New
        </DataTableWrapperActionButton>
      }
    >
      <DataTable data={data} loading={loading} columns={columns} />
    </DataTableWrapper>
  );
}
