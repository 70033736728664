import { ChangeEvent } from 'react';
import { useSyncState } from 'src/hooks';
import { useUserRoles } from 'src/auth';
import { useParams } from 'react-router-dom';
import { SolutionMenderSettings, SolutionType } from '../types';
import { getSolutionSettingsMenderFormFields } from './formFields';

export const useSolutionSettingsMenderFormFields = (
  item?: SolutionMenderSettings
) => {
  const [menderEnabled, setMenderEnabled] = useSyncState(item?.menderEnabled, [
    item?.menderEnabled,
  ]);
  const onMenderEnabledChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setMenderEnabled(target.checked);
  };

  const { isPlatformReader } = useUserRoles();
  const { solutionType } = useParams();
  const isConsumerSolution = solutionType === SolutionType.Consumer;

  return getSolutionSettingsMenderFormFields(
    menderEnabled,
    isPlatformReader,
    isConsumerSolution,
    onMenderEnabledChange
  );
};
