import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import compact from 'lodash/compact';
import MaskedInput from 'src/components/MaskedInput';
import Select from 'src/components/Select';
import { SolutionFileSubscriptionItemField } from '../types';
import { STORAGE_ACCOUNT_OPTIONS } from './constants';

export const getFileSubscriptionEditFormFields = (
  item?: any,
  handlePaste?: any
) =>
  compact([
    {
      name: SolutionFileSubscriptionItemField.FileSubscriptionName,
      label: 'Subscription Name',
      Component: TextField,
      placeholder: 'Enter subscription name',
      required: true,
      validation: Yup.string().required('Subscription name is required'),
    },
    {
      name: SolutionFileSubscriptionItemField.DestinationType,
      label: 'Destination Storage Type',
      Component: Select,
      required: true,
      validation: Yup.string().required('Destination Storage Type is required'),
      options: STORAGE_ACCOUNT_OPTIONS,
      icon: true,
    },
    {
      name: SolutionFileSubscriptionItemField.FolderStructure,
      label: 'Destination Folder Format',
      Component: TextField,
      placeholder: 'Enter Destination Folder Format',
    },
    {
      name: SolutionFileSubscriptionItemField.DestinationConnectionString,
      label: 'Connection String',
      Component: MaskedInput,
      placeholder: 'Enter Connection String',
      onClickPasteIcon: handlePaste,
      required: true,
      icon: true,
      validation: Yup.string().required('Connection String is required'),
    },
  ]);
