import React from 'react';
import Badge from 'src/components/Badge';
import { SolutionDeviceDeploymentLogItemStatus as Status } from '../types';

type StatusMapItem = {
  color: 'success' | 'info' | 'error';
  label: string;
};

type Props = {
  value?: Status;
};

function SolutionDeviceDeploymentLogStatus({ value = Status.Pending }: Props) {
  const statusMap: Record<Status, StatusMapItem> = {
    [Status.Aborted]: { color: 'error', label: 'Aborted' },
    [Status.AlreadyInstalled]: { color: 'info', label: 'Already installed' },
    [Status.Decommissioned]: { color: 'info', label: 'Decommissioned' },
    [Status.Downloading]: { color: 'info', label: 'Downloading' },
    [Status.Failure]: { color: 'error', label: 'Failure' },
    [Status.Installing]: { color: 'info', label: 'Installing' },
    [Status.NoArtifact]: { color: 'info', label: 'No Artifact' },
    [Status.Pause]: { color: 'info', label: 'Pause' },
    [Status.PauseBeforeInstalling]: {
      color: 'info',
      label: 'Pause before installing',
    },
    [Status.PauseBeforeCommitting]: {
      color: 'info',
      label: 'Pause before committing',
    },
    [Status.PauseBeforeRebooting]: {
      color: 'info',
      label: 'Pause before rebooting',
    },
    [Status.Pending]: { color: 'info', label: 'Pending' },
    [Status.Rebooting]: { color: 'info', label: 'Rebooting' },
    [Status.Skipped]: { color: 'info', label: 'Skipped' },
    [Status.Success]: { color: 'success', label: 'Success' },
  };

  return <Badge {...statusMap[value]} />;
}

export default SolutionDeviceDeploymentLogStatus;
