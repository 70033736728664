import { createDatasetSlice, ResponseMapping } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import { generateApiPath } from 'src/utils';
import {
  API_WORKLOAD_CONFIGURATIONS,
  SOLUTIONS_STORE,
  SOLUTIONS_STORE_EDGE_WORKLOAD_CONFIGURATIONS,
} from '../constants';

const api = (solutionId) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: generateApiPath(API_WORKLOAD_CONFIGURATIONS, { solutionId }),
  });

const edgeWorkloadConfigurations = createDatasetSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTIONS_STORE_EDGE_WORKLOAD_CONFIGURATIONS,
  responseMapping: ResponseMapping.Default,
});

export default edgeWorkloadConfigurations;
