import forEach from 'lodash/forEach';
import mapValues from 'lodash/mapValues';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';
import { Action, Dispatch } from 'redux';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { composeSlices } from './composeSlices';
import { createExtraActions } from './createExtraActions';
import { getSliceNamePrefix, recMapDispatchers } from './utils';

type Dispatcher = (dispatch: Dispatch<any>) => (params: any) => void;
type Displatchers = { [key: string]: Dispatcher };

export type CreateStoreOptions = {
  name: string;
  initialState?: any;
  selectors?: { [key: string]: (state: any) => any };
  slices?: { [key: string]: any };
  actions?: { [key: string]: any };
  dispatchers?: Displatchers;
  reducers?: { [actionType: string]: (state: any, action: Action) => any };
};

export function createStoreUnit({ name, ...options }: CreateStoreOptions) {
  const actionNameFn = (action: string) => getSliceNamePrefix(name, action);
  const initialState =
    options.initialState ||
    mapValues(options.slices, ({ initialState }) => initialState);
  const { actions, dispatchers } = createExtraActions(
    options.actions || {},
    actionNameFn
  );

  const extraReducers = (builder: ActionReducerMapBuilder<any>) => {
    forEach(options.reducers || {}, (reducer, event) => {
      builder.addCase(actionNameFn(event), reducer);
    });
  };

  return composeSlices(options.slices || {}, {
    selectors: options.selectors || {},
    dispatchers: { ...options.dispatchers, ...dispatchers },
    actions,
    extraReducers,
    initialState,
    name,
    ...options,
  });
}

export const getStoreUnitActions = (store) => (dispatch: Dispatch) =>
  recMapDispatchers(dispatch, store.dispatchers);

export const useStoreUnitActions = (store) => {
  const dispatcher = useDispatch();
  return useMemo(
    () => getStoreUnitActions(store)(dispatcher),
    [store, dispatcher]
  );
};
