import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Form from 'src/components/Form';
import { SiteFormFields } from '../formFields';
import { SiteItem } from '../../types';

const style = {
  width: ({ spacing }) => spacing(55),
};

type Props = {
  item?: SiteItem;
  onCancel: () => void;
  onSubmit: (values: Record<string, any>) => void;
  confirmBtnText?: string;
};

function EditSolutionForm({
  onCancel,
  onSubmit,
  item,
  confirmBtnText = 'Create',
}: Props) {
  return (
    <Form
      fields={SiteFormFields(!!item)}
      fieldProps={{ sx: style }}
      values={item}
      onSubmit={onSubmit}
      visibleValuesOnly
    >
      <Box textAlign="right" mt={3}>
        <Button onClick={onCancel} variant="outlined" sx={{ mr: 2 }}>
          Cancel
        </Button>
        <Button type="submit" variant="contained">
          {confirmBtnText}
        </Button>
      </Box>
    </Form>
  );
}

export default EditSolutionForm;
