import { AlertType, useAlert } from 'src/components/AlertsProvider';
import {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { generateApiPath } from 'src/utils';
import { useStoreUnitActions } from 'src/redux/helpers';
import { useParams } from 'react-router-dom';
import { SolutionEdgeWorkloadItem } from 'src/pages/Solutions/types/solutionEdgeWorkload';
import SolutionEdgeWorkloadEditModal from './SiteEdgeWorkloadEditModal';
import { getSiteWorkloadEditParams } from '../utils';
import { API_SITE_WORKLOAD_CONFIG_UPDATE } from '../../constants';
import sitesStore from '../../redux';

type Props = {
  item: SolutionEdgeWorkloadItem;
  open: boolean;
  onClose: ModalCloseHandler;
};

function SolutionEdgeWorkloadEditContainer({ item, open, onClose }: Props) {
  const { siteId } = useParams();
  const { name: repoName, gitFlag, deploymentPaused, deploymentStatus } = item;
  const { showAlert } = useAlert();
  const actions = useStoreUnitActions(sitesStore);
  const clusterData = item?.siteClusters?.reduce((result, cluster) => {
    cluster.clusterIds.forEach((id) => {
      result.push(id);
    });
    return result;
  }, []);
  const data: SolutionEdgeWorkloadItem = {
    ...item,
    siteClusters: clusterData,
  };

  const [handleSubmit, loading] = useStatefulApi<SolutionEdgeWorkloadItem>(
    (params) =>
      pepApiRequest({
        method: RequestMethod.Put,
        url: generateApiPath(API_SITE_WORKLOAD_CONFIG_UPDATE, {
          siteId,
          repoName,
        }),
        params: {
          WorkloadConfiguration: {
            ...getSiteWorkloadEditParams(params, siteId),
            gitFlag,
            deploymentPaused,
            deploymentStatus,
          },
        },
      }),
    () => {
      showAlert({
        type: AlertType.Info,
        title: `${repoName} is saved`,
        text: 'Git Repo has been modified successfully',
      });
      onClose(null, ModalCloseReason.completeAction);
      actions.edgeWorkloadConfigurations(siteId);
    }
  );

  return (
    <SolutionEdgeWorkloadEditModal
      item={data}
      title={'Edit Git Repository'}
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      confirmBtnText={'Save'}
      loading={loading}
    />
  );
}

export default SolutionEdgeWorkloadEditContainer;
