import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import StatsSummary from 'src/components/StatsSummary';
import StatusBar from 'src/components/StatusBar';
import { SxProps } from 'src/theme/types';
import {
  SolutionDeviceBulkDeploymentDetails,
  SolutionDeviceDeploymentLogItemStatus,
  SolutionDeviceDeploymentStatus,
} from '../../types';

type Props = {
  details: SolutionDeviceBulkDeploymentDetails;
  statistics: Record<SolutionDeviceDeploymentLogItemStatus, number>;
};

const wrapperStyle: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  py: 2,
  px: 3,
  mb: 2,
};

const statusOptions = [
  {
    value: SolutionDeviceDeploymentStatus.Finished,
    label: 'Finished',
  },
  {
    value: SolutionDeviceDeploymentStatus.InProgress,
    label: 'In progress',
  },
  {
    value: SolutionDeviceDeploymentStatus.Scheduled,
    label: 'Scheduled',
  },
  {
    value: SolutionDeviceDeploymentStatus.Pending,
    label: 'Pending',
  },
];

const summaryOptions = [
  {
    id: SolutionDeviceDeploymentLogItemStatus.Skipped,
    label: 'Skipped',
  },
  {
    id: SolutionDeviceDeploymentLogItemStatus.Pause,
    label: 'Paused',
  },
  {
    id: SolutionDeviceDeploymentLogItemStatus.Pending,
    label: 'Pending',
  },
  {
    id: SolutionDeviceDeploymentLogItemStatus.Installing,
    label: 'In progress',
  },
  {
    id: SolutionDeviceDeploymentLogItemStatus.Success,
    label: 'Success',
  },
  {
    id: SolutionDeviceDeploymentLogItemStatus.Failure,
    label: 'Fail',
  },
];

function SolutionDeviceBulkDeploymentStatus({ details, statistics }: Props) {
  return (
    <>
      <Typography variant="h4" mb={2}>
        Status
      </Typography>
      <Paper variant="outlined" sx={wrapperStyle}>
        <StatusBar value={details?.status} options={statusOptions} />
        <StatsSummary
          totalTitle="Devices"
          data={statistics}
          fields={summaryOptions}
        />
        <Box display="flex" alignSelf="end">
          <Typography variant="body2md" pr={4}>
            Retries per device
          </Typography>
          {details?.retries}
        </Box>
      </Paper>
    </>
  );
}

export default SolutionDeviceBulkDeploymentStatus;
