import { generatePath } from 'react-router-dom';
import { createDatasetSlice } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import {
  API_COMPANY_USERS,
  COMPANIES_STORE,
  COMPANIES_STORE_USERS,
} from '../constants';
import { mapCompanyUsersData } from './utils';

const api = (companyId: string) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: generatePath(API_COMPANY_USERS, { companyId }),
  }).then(mapCompanyUsersData);

const companyUsersSlice = createDatasetSlice({
  api,
  storeName: COMPANIES_STORE,
  actionName: COMPANIES_STORE_USERS,
});

export default companyUsersSlice;
