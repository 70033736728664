import { useParams } from 'react-router-dom';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import ModalDialog, {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { useStoreUnitActions } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { generateApiPath, loadDataFromStorage } from 'src/utils';
import { API_DEVICES, API_DEVICES_IOTH } from '../../constants';
import solutionsStore from '../../redux';
import { SolutionDeviceItem, SolutionType } from '../../types';

type Props = {
  selectedItems: SolutionDeviceItem[];
  open: boolean;
  onClose: ModalCloseHandler;
};
export default function RevokeCertificationModal({
  open,
  selectedItems,
  onClose,
}: Props) {
  const { solutionId } = useParams();
  const solutionType = loadDataFromStorage('solutionType');
  const { showAlert } = useAlert();
  const { devices } = useStoreUnitActions(solutionsStore);
  const itemIds = selectedItems.map((x) => x.id);

  const deviceNames = selectedItems.map((x) => x.displayName);
  const deviceIds = selectedItems.map((x) => x.id);
  const deviceNameStr =
    deviceNames.length > 1
      ? `${deviceNames.slice(0, -1).join(' ,')} and ${deviceNames.slice(-1)}`
      : deviceNames[0];
  const deviceIdStr =
    deviceIds.length > 1
      ? `${deviceIds.slice(0, -1).join(' ,')} and ${deviceIds.slice(-1)}`
      : deviceIds[0];

  const [onSubmit, loading] = useStatefulApi(
    () =>
      pepApiRequest({
        method: RequestMethod.Put,
        url: generateApiPath(
          solutionType.toUpperCase() === SolutionType.ioTH.toUpperCase()
            ? API_DEVICES_IOTH
            : API_DEVICES,
          { solutionId }
        ),
        params: {
          ReasonCode: 1,
          DeviceIDs: itemIds,
        },
      }),
    () => {
      showAlert({
        type: AlertType.Info,
        title: 'Certificate Revoked Successfully',
        text: `Devices ${
          solutionType.toUpperCase() === SolutionType.ioTH.toUpperCase()
            ? deviceIdStr
            : deviceNameStr
        } will be unreachable.`,
      });
      devices.init({ solutionId });
      onClose(null, ModalCloseReason.completeAction);
    }
  );

  return (
    <ModalDialog
      open={open}
      title="Certificate Revocation"
      onClose={onClose}
      confirmBtnText="Yes"
      cancelBtnText="No"
      onConfirm={onSubmit}
      loading={loading}
    >
      {`Are you sure you want to revoke certificates for Device ${
        solutionType.toUpperCase() === SolutionType.ioTH.toUpperCase()
          ? deviceIdStr
          : deviceNameStr
      } permanently?`}
      <br />
      Once Initiated, devices will not be reachable
    </ModalDialog>
  );
}
