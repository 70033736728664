import { createDatasetSlice, ResponseMapping } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import { generateApiPath, loadDataFromStorage } from 'src/utils';
import {
  API_DEVICES_OBSERVABILITY_CONFIG,
  API_DEVICES_OBSERVABILITY_CONFIG_IOTH_GET,
  SOLUTIONS_STORE,
  SOLUTIONS_STORE_DEVICE_OBSERV_PROPERTIES,
} from '../constants';
import { mapSolutionDeviceObservProperties } from './utils';
import { SolutionType } from '../types';

const api = ({
  solutionId,
  companyId,
  deviceId,
  componentName = 'DeviceMetrics',
  moduleName = '',
}) => {
  const solutionType = loadDataFromStorage('solutionType');
  return pepApiRequest({
    method: RequestMethod.Get,
    url: generateApiPath(
      solutionType.toUpperCase() === SolutionType.ioTH.toUpperCase()
        ? API_DEVICES_OBSERVABILITY_CONFIG_IOTH_GET
        : API_DEVICES_OBSERVABILITY_CONFIG,
      {
        solutionId,
        companyId,
        deviceId,
      }
    ),
    queryParams: { componentName, moduleName },
  }).then((allProperties) => mapSolutionDeviceObservProperties(allProperties));
};

const solutionDeviceObservPropertiesSlice = createDatasetSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTIONS_STORE_DEVICE_OBSERV_PROPERTIES,
  responseMapping: ResponseMapping.Default,
});

export default solutionDeviceObservPropertiesSlice;
