import { encodeQuery, stringifyQuery } from 'src/utils';
import { ErrorResponseBody, RequestParams } from './types';
import { getRequestBody, isJsonResponse, setContentType } from './utils';
import { API_ERROR_EVENT } from './constants';

export function apiRequest({
  method,
  url,
  params,
  queryParams,
  contentType,
  headers,
  skipErrorCodes = [],
  skipErrorAlert,
  skipEncodeUri,
  skipCustomAlert,
}: RequestParams): Promise<any> {
  const getQueryFn = skipEncodeUri ? stringifyQuery : encodeQuery;
  const queryString = (queryParams && `?${getQueryFn(queryParams)}`) || '';

  const body = getRequestBody({ contentType, params, method });
  setContentType(headers, contentType);

  return fetch(`${url}${queryString}`, {
    method,
    headers,
    body,
  })
    .then((response) => {
      if (response.ok) {
        return isJsonResponse(response)
          ? response.json()
          : Promise.resolve(response);
      }
      return Promise.reject(response);
    })
    .catch((error) => {
      if (
        !skipErrorCodes.some((item) => item === error.status) &&
        !skipErrorAlert &&
        isJsonResponse(error)
      ) {
        error
          .json()
          .then((detail: ErrorResponseBody) =>{
            document.dispatchEvent(
              new CustomEvent<ErrorResponseBody>(API_ERROR_EVENT, { detail })
            )
          }
          );
      }else if( !skipErrorCodes.some((item) => item === error.status) &&
        skipCustomAlert &&
        isJsonResponse(error) ) {
        error
          .json()
          .then((details: ErrorResponseBody) =>{
             const detail = {...details,skipAlert: skipCustomAlert}
            document.dispatchEvent(
              new CustomEvent<ErrorResponseBody>(API_ERROR_EVENT,{detail})
            )
          }
          );
      }
      throw error;
    });
}
