import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import { PageCardLoader } from 'src/components/PageCardLayout';
import FieldsSet from 'src/components/FieldsSet';
import CompanyDetailsSoltions from './CompanyDetailsSolutions';
import { CompanyItemField } from '../../types';
import { useCompaniesDetails } from '../../redux';

const fields = [
  {
    id: CompanyItemField.Name,
    label: 'Company name',
  },
  {
    id: CompanyItemField.Id,
    label: 'Company ID',
  },
  {
    id: CompanyItemField.Solutions,
    label: 'Solutions',
    Component: CompanyDetailsSoltions,
  },
];

function CompanyDetailTab() {
  const [data, loading] = useCompaniesDetails();

  return (
    <Box pb={3}>
      <Box textAlign="right" mb={2}>
        <Button
          size="small"
          variant="outlined"
          startIcon={<EditIcon />}
          disabled
        >
          Edit
        </Button>
      </Box>
      <FieldsSet fields={fields} data={data} />
      {loading && <PageCardLoader />}
    </Box>
  );
}

export default CompanyDetailTab;
