import React from 'react';
import { Control } from 'react-hook-form';
import { BoxProps } from '@mui/material/Box';
import { Typography } from '@mui/material';
import FormArray from './FormArray';
import FormControl from './FormControl';
import { FormField } from '../types';

type Props = Omit<BoxProps, 'onSubmit'> & {
  control: Control<any>;
  fields: FormField[];
  disabled?: boolean;
};

function FormGroup({ control, fields, ...rest }: Props) {
  return (
    <>
      {fields.map((field) => {
        if (field.type === 'heading') {
          return (
            <Typography
              variant="body2"
              key={field.name}
              style={{
                fontWeight: 'bold',
                font: '18px',
                margin: '10px auto',
              }}
              width="-webkit-fill-available"
            >
              {field.label}
            </Typography>
          );
        }
        const Component = 'childFields' in field ? FormArray : FormControl;
        return (
          <Component control={control} key={field.name} {...rest} {...field} />
        );
      })}
    </>
  );
}

export default FormGroup;
