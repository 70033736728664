import React, { ReactNode, useEffect } from 'react';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import {
  DefaultValues,
  FieldValues,
  useForm as useHookForm,
  UseFormHandleSubmit,
  UseFormSetValue,
  UseFormGetValues,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  getFilteredValues,
  getInitialValues,
  getValidationSchema,
} from './utils';
import { FormConfigProps, FormProps } from './types';
import FormContainer from './components/FormContainer';
import FormGroup from './components/FormGroup';

export function useFormConfig<T extends FieldValues>({
  fields,
  values,
  onChange,
  visibleValuesOnly,
}: FormConfigProps<T>) {
  const defaultValues = {
    ...getInitialValues(fields),
    ...omitBy(values, isNil),
  } as DefaultValues<T>;
  const resolver = yupResolver(getValidationSchema(fields));
  const formProps = useHookForm<T>({ defaultValues, resolver });
  const handleSubmit: UseFormHandleSubmit<T> = visibleValuesOnly
    ? (onValid, ...rest) =>
        formProps.handleSubmit(
          (values: T) => onValid(getFilteredValues<T>(fields, values)),
          ...rest
        )
    : formProps.handleSubmit;

  useEffect(() => {
    const subscription = formProps.watch(
      (values) => onChange && onChange(values as T)
    );
    return () => subscription.unsubscribe();
  }, [formProps.watch, onChange]);
  useEffect(() => {
    formProps.reset(defaultValues);
  }, [values]);

  return { ...formProps, handleSubmit };
}

export function useForm<T extends FieldValues = FieldValues>({
  fields,
  fieldProps,
  onChange,
  onSubmit,
  values,
  disabled,
  visibleValuesOnly,
  ...rest
}: FormProps<T>): [
  ReactNode,
  (event) => void,
  UseFormSetValue<T>,
  UseFormGetValues<T>
] {
  const formProps = useFormConfig<T>({
    fields,
    values,
    onChange,
    visibleValuesOnly,
  });
  const { control, handleSubmit, setValue, getValues } = formProps;

  const FormElement = (
    <FormContainer<T> contextProps={formProps} {...rest}>
      <FormGroup
        control={control}
        fields={fields}
        disabled={disabled}
        {...fieldProps}
      />
    </FormContainer>
  );

  return [FormElement, handleSubmit(onSubmit), setValue, getValues];
}
