export enum SolutionSubscriptionType {
  Messages = 'messages',
  Files = 'files',
  ADLS = 'adls',
  Cosmos = 'cosmos',
}

export enum SolutionStorageAccount {
  AzureBlob = 'Azure Blob',
  AWS_S3 = 'AWS S3',
  Ftp = 'FTP',
}

export enum SolutionADLSFormat {
  JSON = 'Json',
  AVRO = 'Avro',
  PARQUET = 'Parquet',
}

export enum SolutionSubscribeTo {
  Alarm = 'alarm',
  Event = 'event',
  PlatformEvent = 'platformEvent',
  Telemetry = 'telemetry',
}

export type SolutionFileSubscriptionItem = {
  id: string;
  name: string;
  fileSubscriptionName: string;
  solutionId: string;
  sourceConnectionString: string | null;
  destination: {
    destinationURL: string;
    destinationType: string;
  };
  DestinationConnectionString: string;
  folderStructure: string;
};

export enum SolutionFileSubscriptionItemField {
  Name = 'name',
  FileSubscriptionName = 'fileSubscriptionName',
  SourceStorageAccount = 'sourceStorageAccount',
  DestinationConnectionString = 'DestinationConnectionString',
  FolderStructure = 'folderStructure',
  DestinationType = 'DestinationType',
}

export type SolutionCosmosSubscriptionItem = {
  id: string;
  solutionId: string;
  name: string;
  connectionString: string;
  databaseName: string;
  container: string;
  partitionkey: string;
  filterCondition?: string;
  filters: any[];
  cosmosFilters: any[];
  cosmosSubscriptionName: string;
  cosmosFilterCondition?: string;
};

export enum SolutionCosmosSubscriptionItemField {
  Name = 'name',
  ConnectionString = 'connectionString',
  DatabaseName = 'databaseName',
  Container = 'container',
  Partitionkey = 'partitionkey',
  CosmosFilters = 'cosmosFilters',
  Filters = 'filters',
  FilterCondition = 'filterCondition',
  CosmosSubscriptionName = 'cosmosSubscriptionName',
  CosmosFilterCondition = 'cosmosFilterCondition',
}

export type SolutionAdlsSubscriptionItem = {
  id: string;
  solutionId: string;
  name: string;
  format: string;
  adlsSubscriptionName: string;
  containerSaasURL: string;
  folderStructure: string;
  filterCondition?: string;
  filters: any[];
  adlsFilters: any[];
  adlsFilterCondition?: string;
};

export type SolutionAdlsSubscriptionData = {
  data?: SolutionAdlsSubscriptionItem;
  loading?: boolean;
};

export enum SolutionAdlsSubscriptionItemField {
  Name = 'name',
  Format = 'format',
  AdlsSubscriptionName = 'adlsSubscriptionName',
  ContainerSaasURL = 'containerSaasURL',
  FolderStructure = 'folderStructure',
  Filters = 'filters',
  AdlsFilters = 'adlsFilters',
  FilterCondition = 'filterCondition',
  AdlsFilterCondition = 'adlsFilterCondition',
}

export type SolutionSubscriptionItem = {
  id: string;
  enabled?: boolean;
  subscriptionType?: SolutionSubscriptionType;
  name: string;
  cosmosSubscriptionName: string;
  adlsSubscriptionName: string;
  fileSubscriptionName: string;
  sourceStorageAccount?: string;
  destinationStorageType?: SolutionStorageAccount;
  DestinationConnectionString?: string;
  folderStructure?: string;
  solutionId?: string;
  type?: string[];
  typeList?: string[];
  target?: string;
  useAuth?: boolean;
  Authorization?: string;
  filterCondition?: string;
  adlsFilterCondition?: string;
  cosmosFilterCondition?: string;
  filters: any[];
  adlsFilters: any[];
  cosmosFilters: any[];
  containerSaasURL: string;
  connectionString?: string;
};

export enum SolutionSubscriptionFilterField {
  Property = 'property',
  OperatorValue = 'operatorValue',
  FilterValue = 'filterValue',
}

export type SolutionSubscriptionFilter = {
  property: string;
  operatorValue: string;
  filterValue: string;
};

export enum SolutionSubscriptionItemField {
  SubscriptionType = 'subscriptionType',
  Name = 'name',
  SourceStorageAccount = 'sourceStorageAccount',
  DestinationConnectionString = 'destinationConnectionString',
  DestinationStorageType = 'destinationStorageType',
  FolderStructure = 'folderStructure',
  SolutionId = 'solutionId',
  Type = 'type',
  TypeList = 'typeList',
  Target = 'target',
  UseAuth = 'useAuth',
  Authorization = 'Authorization',
  Enabled = 'enabled',
  Filters = 'filters',
  FilterCondition = 'filterCondition',
  AdlsFilterCondition = 'adlsFilterCondition',
  CosmosFilterCondition = 'cosmosFilterCondition',
  AdlsFilters = 'adlsFilters',
  CosmosFilters = 'cosmosFilters',
  ContainerSaasURL = 'containerSaasURL',
  ConnectionString = 'connectionString',
  DatabaseName = 'databaseName',
  Container = 'container',
  Partitionkey = 'partitionkey',
  AdlsSubscriptionName = 'adlsSubscriptionName',
  CosmosSubscriptionName = 'cosmosSubscriptionName',
  FileSubscriptionName = 'fileSubscriptionName',
}
