import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { useState } from 'react';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { SolutionDetailsItem } from '../../types';

const cellStyle = {
  border: 0,
  height: 'auto',
  padding: '10px 20px',
  wordWrap: 'break-word',
};
const LabelStyle = {
  ...cellStyle,
  fontWeight: 'bold',
  width: '35%',
};

interface StorageAccountDetailsProps {
  data: SolutionDetailsItem | undefined;
}

export const StorageAccountDetails = ({ data }: StorageAccountDetailsProps) => {
  const item = {
    name: data?.features?.storageSetup?.solutionBlobStorageName,
    type: data?.features?.storageSetup?.sourceConnectionString,
    connectionString: data?.features?.storageSetup?.sourceConnectionString,
  };
  const [showConnectionString, setShowConnectionString] = useState(false);
  const VisibilityIcon = showConnectionString
    ? VisibilityOffOutlinedIcon
    : VisibilityOutlinedIcon;

  const handleShowConnectionString = () => {
    setShowConnectionString((prevShowPassword: any) => !prevShowPassword);
  };

  const getConnectionString = (ConnectionString, showConnectionString) => {
    if (ConnectionString === '' || ConnectionString === undefined) {
      return <span>–</span>;
    }
    return showConnectionString ? ConnectionString : '*'.repeat(40);
  };

  return (
    <Box>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={[cellStyle, LabelStyle]}>Type</TableCell>
            <TableCell sx={cellStyle}>
              {item.type === undefined || item.type === '' ? (
                <span>–</span>
              ) : (
                'Azure Blob'
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={[cellStyle, LabelStyle]}>Display Name</TableCell>
            <TableCell sx={cellStyle}>
              {item.name === '' || item.name === undefined ? (
                <span>–</span>
              ) : (
                item.name
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={LabelStyle}>Connection String</TableCell>
            <TableCell sx={cellStyle}>
              {getConnectionString(item.connectionString, showConnectionString)}
              &nbsp;
              {item.connectionString && (
                <IconButton
                  onClick={handleShowConnectionString}
                  size="small"
                  title={showConnectionString ? 'Hide' : 'View'}
                >
                  <VisibilityIcon sx={{ color: '#0047BA', fontSize: '20px' }} />
                </IconButton>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};
