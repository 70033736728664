import { useState, useEffect } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { pepApiRequest, RequestMethod } from 'src/services';
import { Add } from '@mui/icons-material';
import { useUserRoles } from 'src/auth';
import { SiteItemStatus as SiteStatus } from 'src/pages/Sites/types';
import { useModal } from 'src/hooks';
import {
  DataTableWrapper,
  DataTableWrapperActionButton,
} from 'src/components/DataTable';
import { PageCardLoader } from 'src/components/PageCardLayout';
import {
  API_SITE_CLUSTERS,
  API_SITE_UPDATE_SITE_STATUS,
} from '../../constants';
import { SiteItemStatus as Status } from '../../types';
import { useSiteDetails } from '../../redux';
import SiteClusterCreateModal from './SiteClusterCreateModal';
import SiteClusterComponent from './SiteClusterComponent';

function SiteDevices() {
  const { siteId } = useParams();
  const [data] = useSiteDetails();
  const { isPlatformOwner, isPlatformEngineer } = useUserRoles();
  const isValidUser = isPlatformOwner || isPlatformEngineer;
  const [clusterDetail, setCluseterDetail] = useState<any>([]);
  const [AddNewClusterDialog, showAddNewClusterDialog] = useModal(
    SiteClusterCreateModal,
    { title: 'Add Cluster', setCluseterDetail }
  );

  useEffect(() => {
    pepApiRequest({
      method: RequestMethod.Get,
      url: generatePath(API_SITE_CLUSTERS, { siteId }),
      skipErrorAlert: true,
      skipErrorCodes: [500],
    }).then((response) => {
      setCluseterDetail(response);
    });
  }, []);

  useEffect(() => {
    let intervalId;
    if (data && data.status === Status.InProgress) {
      intervalId = setInterval(() => {
        pepApiRequest({
          method: RequestMethod.Get,
          url: generatePath(API_SITE_CLUSTERS, { siteId }),
        }).then((response) => {
          if (response.state === 'active') {
            setCluseterDetail(response);
            pepApiRequest({
              method: RequestMethod.Put,
              url: generatePath(API_SITE_UPDATE_SITE_STATUS, {
                siteId,
              }),
              params: {
                status: 'Active',
              },
            });
          }
        });
      }, 30000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [data]);

  return (
    <>
      <DataTableWrapper
        data-testid="solution-files-whitelist-wrapper"
        my={3}
        mb={2}
        title="Clusters"
        actionsPanel={
          <DataTableWrapperActionButton
            onClick={showAddNewClusterDialog}
            startIcon={<Add />}
            disabled={data?.status !== SiteStatus.Active || !isValidUser}
          >
            Add new cluster
          </DataTableWrapperActionButton>
        }
        searchBar={false}
      >
        {AddNewClusterDialog}
      </DataTableWrapper>
      {clusterDetail?.clustersDetail?.map((cluster: any) => (
        <SiteClusterComponent
          data={data}
          cluster={cluster}
          clusterDetail={clusterDetail}
        />
      ))}
      {!clusterDetail?.clustersDetail && <PageCardLoader />}
    </>
  );
}

export default SiteDevices;
