import { AlertType, useAlert } from 'src/components/AlertsProvider';
import {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { generateApiPath } from 'src/utils';
import { API_UPDATE_FILE_SUBSCRIPTIONS } from '../../constants';
import { SolutionFileSubscriptionItem } from '../../types';
import { getFileSubscriptionItemReqParams } from '../utils';
import SolutionFileSubscriptionEditModal from './SolutionFileSubscriptionEditModal';

type Props = {
  item: SolutionFileSubscriptionItem;
  open: boolean;
  onClose: ModalCloseHandler;
};

function SolutionFileSubscriptionEditContainer({ item, open, onClose }: Props) {
  const { showAlert } = useAlert();
  const [handleSubmit, loading] = useStatefulApi<SolutionFileSubscriptionItem>(
    (params) =>
      pepApiRequest({
        method: RequestMethod.Put,
        url: generateApiPath(API_UPDATE_FILE_SUBSCRIPTIONS, {
          solutionId: item?.solutionId,
          subscriptionId: item?.id,
        }),
        params: getFileSubscriptionItemReqParams(params),
      }),
    () => {
      showAlert({
        type: AlertType.Info,
        title: `${item?.name} is updated`,
        text: 'File subscription has been successfully updated.',
      });
      onClose(null, ModalCloseReason.completeAction);
    }
  );

  return (
    <SolutionFileSubscriptionEditModal
      item={item}
      title={`Edit File Subscription`}
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      confirmBtnText={'Subscribe'}
      loading={loading}
    />
  );
}

export default SolutionFileSubscriptionEditContainer;
