import { generatePath } from 'react-router-dom';
import replace from 'lodash/replace';
import words from 'lodash/words';
import { encodeQuery } from './queryUtils';

type Params = Record<string, string | null | undefined>;

export const generateApiPath = (
  path: string,
  params: Params,
  defaultValue?: string
): string => {
  const defaultParamsValue = defaultValue || 'all';
  const pathParamsList = words(path, /(?<=:)\w+/g);
  const cutEndSlash = (value: string) => replace(value, /[/]$/, '');
  const transformedParams = pathParamsList.reduce(
    (acc, paramKey) => ({
      ...acc,
      [paramKey]: cutEndSlash(params[paramKey] ?? defaultParamsValue),
    }),
    {}
  );

  return generatePath(path, transformedParams);
};

type ExternalUrlParams = {
  params?: Params;
  queryParams?: Params;
};

export const navigateToExternalUrl = (
  url: string,
  requestParams?: ExternalUrlParams
) => {
  const { params, queryParams } = requestParams || {};
  const queryString = (queryParams && `?${encodeQuery(queryParams)}`) || '';
  const path = generateApiPath(url, params || {});
  window.open(path + queryString);
};
