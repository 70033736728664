import { useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import GuidedTooltip from 'src/components/GuidedTour/guidedTooltip';
import { useStoreUnitActions } from 'src/redux/helpers';
import { PageCardLoader } from 'src/components/PageCardLayout';
import { useSolutionPepsenceImageRepoData } from '../../redux/selectors';
import solutionsStore from '../../redux';

function SolutionPepsenceImageRepoDetails() {
  const cellStyle = {
    border: 0,
    height: 'auto',
    padding: '10px 20px 10px 0px',
  };
  const LabelStyle = {
    fontWeight: 'bold',
    width: '23%',
  };
  const iconStyle = {
    color: '#0047BA',
  };

  const [data, loading] = useSolutionPepsenceImageRepoData();
  const { solutionId } = useParams();
  const actions = useStoreUnitActions(solutionsStore);
  const [isCopied, setIsCopied] = useState('');

  const handleCopy = (text?: string) => {
    navigator.clipboard.writeText(text ?? '').then(() => {
      setIsCopied(text ?? '');
    });
  };

  useEffect(() => {
    actions.pepsenceImageRepoDetails(solutionId);
  }, [solutionId]);

  return (
    <Box sx={{ margin: '40px 0px' }}>
      <Box sx={{ marginBottom: '18px' }}>
        <Typography
          variant="body2md"
          style={{ fontWeight: 'bold', fontSize: '17px', paddingRight: '12px' }}
        >
          PEPsense Image Repository
        </Typography>
        <GuidedTooltip title="PEPsense Image Repository is used to configure edge workload deployment manifest or helm chart for pulling the image." />
      </Box>
      <>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell sx={[cellStyle, LabelStyle]}>
                Image repository URI &nbsp; &nbsp;
                <GuidedTooltip title="Image repository URI is used to form the image name in deployment manifest or helm chart. Eg. pepeiotpnonprodpriacr.azurecr.io/{Pulled docker images with tag }" />
              </TableCell>
              <TableCell sx={cellStyle}>
                {data?.imageUrl ?? '-'}
                {data?.imageUrl && (
                  <IconButton
                    title={isCopied ? 'Copied to clipboard' : 'Copy'}
                    onClick={() => handleCopy(data?.imageUrl)}
                  >
                    <ContentCopyIcon sx={iconStyle}></ContentCopyIcon>
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={[cellStyle, LabelStyle]}>
                Pull secret name &nbsp; &nbsp;
                <GuidedTooltip title="Pull secret is used to define image pull secret in the deployment manifest or helm charts" />
              </TableCell>
              <TableCell sx={cellStyle}>
                {data?.imagePullSecrets ?? '-'}
                {data?.imagePullSecrets && (
                  <IconButton
                    title={isCopied ? 'Copied to clipboard' : 'Copy'}
                    onClick={() => handleCopy(data?.imagePullSecrets)}
                  >
                    <ContentCopyIcon sx={iconStyle}></ContentCopyIcon>
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </>
      {loading && <PageCardLoader />}
    </Box>
  );
}

export default SolutionPepsenceImageRepoDetails;
