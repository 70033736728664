import { useUserAuthProfile, useUserRoles } from 'src/auth';
import { OtpItem } from './types';

export const useOtpUserRoles = (key?: OtpItem) => {
  const { email } = useUserAuthProfile();
  const { isPlatformOwner, isPlatformReader, isPlatformEngineer } =
    useUserRoles();
  const isKeyOwner = email === key?.owner;

  return {
    isKeyEditor:
      isPlatformOwner || isKeyOwner || !isPlatformReader || isPlatformEngineer,
  };
};
