import TextField from '@mui/material/TextField';
import compact from 'lodash/compact';
import { Option } from 'src/components/SelectOption';
import ReadonlyField from 'src/components/ReadonlyField';
import { SxProps } from 'src/theme/types';
import Select from 'src/components/Select/Select';
import Switch from 'src/components/Switch';
import * as Yup from 'yup';
import TreeSelectwithTextfield from 'src/components/TreeSelect/TreeSelectwithTextfield';
import {
  SolutionEdgeWorkloadItemField,
  SolutionEdgeWorkloadAuthType,
  SolutionEdgeWorkloadCertVerifytype,
} from '../types';
import {
  EDGEWORKLOAD_AUTH_OPTIONS,
  EDGEWORKLOAD_CERTVERIFY_OPTIONS,
} from './constants';

const inputStyle: SxProps = { width: ({ spacing }) => spacing(40) };

export const getWorkloadConfigEditFormFields = (
  isEditMode: boolean,
  options?: Option[],
  selectedSiteIds?: any,
  loading?: boolean,
  gitAuthentication?: string,
  helmAuthentication?: string,
  tlsCertVerification?: string,
  targetNamespace?: string,
  gitRepoType?: boolean,
  optionsData?: {
    parentId: string;
    parentLabel: string;
    children: { label: string; value: string }[];
  }[],
  handleAssignSiteChange?: (value) => void
) =>
  compact([
    {
      name: SolutionEdgeWorkloadItemField.Name,
      label: 'Name',
      Component: TextField,
      placeholder: 'Enter name',
      required: true,
      validation: Yup.string().required('Repository name is required'),
      sx: { width: ({ spacing }) => spacing(24) },
      disabled: isEditMode,
    },

    {
      name: SolutionEdgeWorkloadItemField.Description,
      label: 'Description',
      Component: TextField,
      placeholder: 'Enter repository description',
      required: false,
      sx: { width: ({ spacing }) => spacing(56) },
    },
    {
      name: SolutionEdgeWorkloadItemField.RepositoryURL,
      label: 'Repository URL',
      Component: TextField,
      required: true,
      validation: Yup.string().required('Repository URL is required'),
      sx: inputStyle,
    },
    {
      name: SolutionEdgeWorkloadItemField.BranchName,
      label: 'Branch Name',
      Component: TextField,
      required: true,
      validation: Yup.string().required('Branch Name is required'),
      sx: inputStyle,
    },
    {
      name: SolutionEdgeWorkloadItemField.GitAuthentication,
      label: 'Git Authentication',
      Component: Select,
      placeholder: 'Select the option',
      initialValue: SolutionEdgeWorkloadAuthType.None,
      required: false,
      options: EDGEWORKLOAD_AUTH_OPTIONS,
      sx: inputStyle,
    },
    gitAuthentication === SolutionEdgeWorkloadAuthType.HTTPBased && {
      name: SolutionEdgeWorkloadItemField.GitUserName,
      label: 'UserName',
      Component: TextField,
      placeholder: 'Enter the username',
      required: false,
      sx: inputStyle,
    },
    gitAuthentication === SolutionEdgeWorkloadAuthType.HTTPBased && {
      name: SolutionEdgeWorkloadItemField.GitPassword,
      label: 'Password',
      Component: TextField,
      placeholder: 'Enter the password',
      required: false,
      sx: inputStyle,
    },
    gitAuthentication === SolutionEdgeWorkloadAuthType.SSHBased && {
      name: SolutionEdgeWorkloadItemField.GitPublicKey,
      label: 'Public Key',
      Component: TextField,
      placeholder: 'Enter the Public Key',
      required: false,
      sx: inputStyle,
    },
    gitAuthentication === SolutionEdgeWorkloadAuthType.SSHBased && {
      name: SolutionEdgeWorkloadItemField.GitPrivateKey,
      label: 'Private Key',
      Component: TextField,
      placeholder: 'Enter the Private Key',
      required: false,
      sx: inputStyle,
    },
    {
      name: SolutionEdgeWorkloadItemField.HelmAuthentication,
      label: 'Helm Authentication',
      Component: Select,
      placeholder: 'Select the option',
      initialValue: SolutionEdgeWorkloadAuthType.None,
      required: false,
      options: EDGEWORKLOAD_AUTH_OPTIONS,
      sx: inputStyle,
    },
    helmAuthentication === SolutionEdgeWorkloadAuthType.HTTPBased && {
      name: SolutionEdgeWorkloadItemField.HelmUserName,
      label: 'User Name',
      Component: TextField,
      placeholder: 'Enter the username',
      required: false,
      sx: inputStyle,
    },
    helmAuthentication === SolutionEdgeWorkloadAuthType.HTTPBased && {
      name: SolutionEdgeWorkloadItemField.HelmPassword,
      label: 'Password',
      Component: TextField,
      placeholder: 'Enter the password',
      required: false,
      sx: inputStyle,
    },
    helmAuthentication === SolutionEdgeWorkloadAuthType.SSHBased && {
      name: SolutionEdgeWorkloadItemField.HelmPublickey,
      label: 'Public Key',
      Component: TextField,
      placeholder: 'Enter the Public Key',
      required: false,
      sx: inputStyle,
    },
    helmAuthentication === SolutionEdgeWorkloadAuthType.SSHBased && {
      name: SolutionEdgeWorkloadItemField.HelmPrivatekey,
      label: 'Private Key',
      Component: TextField,
      placeholder: 'Enter the Private Key',
      required: false,
      sx: inputStyle,
    },
    {
      name: SolutionEdgeWorkloadItemField.TlsCertVerification,
      label: 'TLS Certificate Verification',
      Component: Select,
      placeholder: 'Select the option',
      initialValue: SolutionEdgeWorkloadCertVerifytype.ValidCert,
      required: false,
      options: EDGEWORKLOAD_CERTVERIFY_OPTIONS,
      sx: inputStyle,
    },
    tlsCertVerification ===
      SolutionEdgeWorkloadCertVerifytype.AdditionalCert && {
      name: SolutionEdgeWorkloadItemField.Certificates,
      label: 'Certificate',
      Component: TextField,
      placeholder: 'Enter the Certificate',
      validation: Yup.string().required('Certificate field is required'),
      required: false,
      sx: inputStyle,
    },
    {
      name: SolutionEdgeWorkloadItemField.Path,
      label: 'Path',
      Component: TextField,
      placeholder: 'Enter the Path',
      required: false,
      sx: inputStyle,
    },
    {
      name: SolutionEdgeWorkloadItemField.SiteClusters,
      label: 'Sites',
      selectedIds: selectedSiteIds,
      Component: TreeSelectwithTextfield,
      levelOptions: optionsData,
      required: true,
      onChange: handleAssignSiteChange,
      placeholder: 'Select the sites',
      loading,
      sx: inputStyle,
    },
    {
      name: SolutionEdgeWorkloadItemField.TargetNamespace,
      label: 'Target Namespace',
      value: targetNamespace,
      Component: ReadonlyField,
      labelStyle: { fontWeight: 'bold', flex: 1 },
      displayValue: true,
      required: false,
      sx: { width: ({ spacing }) => spacing(45) },
    },
    gitRepoType && {
      name: SolutionEdgeWorkloadItemField.KeepResources,
      label: 'Keep resources after deletion',
      Component: Switch,
      type: 'checkbox',
      required: false,
      labelStyle: { color: '#7B7B7B', opacity: '80%' },
      sx: inputStyle,
      showInfoIcon: true,
      infoTitle:
        'When enabled above, resources will be kept when deleting a GitRepo or Bundle - only Helm release secrets will be deleted.',
    },
  ]);
