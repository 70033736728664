import React, { useRef } from 'react';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  Row,
  Column,
  SelectAllHandler,
  SortHandler,
  SortOrder,
} from '../types';
import DataTableFilter from './DataTableFilter';
import { getCellWidth, findFlexColumnIndex } from '../utils';

type Props<TRow> = {
  columns: Column<TRow>[];
  allSelected?: boolean;
  onSelectAll: SelectAllHandler;
  onSort: SortHandler;
  sortBy?: string;
  sortOrder?: SortOrder;
};

function DataTableHead<TRow extends Row>({
  columns,
  allSelected,
  onSelectAll,
  onSort,
  sortBy,
  sortOrder,
}: Props<TRow>) {
  const anchorEl = useRef<any>(null);
  const noColumns = columns.length - 1;
  const flexColumnIndex = findFlexColumnIndex(columns, noColumns);

  return (
    <TableHead>
      <TableRow>
        {columns.map(({ id, title, HeaderComponent, sx }, index) => (
          <TableCell
            ref={(r) => {
              if (index === 0) anchorEl.current = r;
            }}
            data-testid={`data-table-col-header-${id}`}
            key={id}
            className="header-cell"
            sx={
              index === flexColumnIndex
                ? {
                    ...sx,
                    minWidth: getCellWidth(title),
                    width: '100%',
                    flexGrow: 1,
                  }
                : { ...sx, minWidth: getCellWidth(title) }
            }
          >
            {index === 0 && (
              <DataTableFilter
                id={id}
                anchorEl={anchorEl.current}
                columns={columns}
              ></DataTableFilter>
            )}

            {HeaderComponent ? (
              <HeaderComponent
                id={id}
                value={title}
                onSelect={onSelectAll}
                selected={allSelected}
                onSort={onSort}
                sortOrder={(sortBy === id && sortOrder) || null}
              />
            ) : (
              title
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default DataTableHead;
