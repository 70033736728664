import { useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import {
  DataTableActions,
  DataTableActionsButton,
} from 'src/components/DataTable';
import { isModalComplete } from 'src/components/ModalDialog';
import { useModal } from 'src/hooks';
import { RequestMethod, pepApiRequest, useStatefulApi } from 'src/services';
import { ReactComponent as deployfailed } from 'src/assets/icons/deployfailed.svg';
import { ReactComponent as deploy } from 'src/assets/icons/deploy.svg';
import { ReactComponent as deploymentStatus } from 'src/assets/icons/deploymentstatus.svg';
import { PageCardLoader } from 'src/components/PageCardLayout';
import { useStoreUnitActions } from 'src/redux/helpers';
import { useUserRoles } from 'src/auth';
import { saveDataToStorage } from 'src/utils';
import { Delete } from '@mui/icons-material';
import MoreMenu from 'src/components/MoreMenu';
import { useSolutionUserRoles } from '../../hooks';
import {
  DeploymentStatus,
  SolutionEdgeWorkloadItem,
  SolutionEdgeWorkloadRepositoryType,
  SolutionEdgeWorkloadStatus,
} from '../../types';
import { solutionsStore } from '../../index';
import SolutionEdgeWorkloadDeleteModal from './SolutionEdgeWorkloadDeleteModal';
import SolutionEdgeWorkloadEditContainer from './SolutionEdgeWorkloadEditContainer';
import { API_EDGEWORKLOAD_DEPLOY } from '../../constants';
import { getEdgeWorkloadEditParams } from '../utils';
import SolutionWorkloadStatusViewModal from './SolutionWorkloadStatusViewModal';
import SolutionEdgeWorkloadViewContainer from './SolutionEdgeWorkloadViewContainer';

type CreateApiHandlersParams = {
  url: string;
  method: RequestMethod;
  params?: Record<string, any>;
};

type Props = {
  item?: SolutionEdgeWorkloadItem;
};

function SolutionEdgeWorkloadActions({ item }: Props) {
  const { isSolutionEditor } = useSolutionUserRoles();
  const isDeploying =
    item?.deploymentStatus === SolutionEdgeWorkloadStatus.paused ||
    item?.deploymentStatus === 'Deployment Paused';
  const { solutionId } = useParams();
  const actions = useStoreUnitActions(solutionsStore);
  const onClose = (reason) =>
    isModalComplete(reason) &&
    actions.subscriptions(solutionId) &&
    localStorage.removeItem('DeploymentStatus');
  const [loader, setLoader] = useState(false);
  const setStatusType = DeploymentStatus.Resources;
  const { isPlatformReader, isPlatformEngineer } = useUserRoles();
  const isValidUser =
    item?.gitFlag === SolutionEdgeWorkloadRepositoryType.PepsenseEdge &&
    isPlatformEngineer;

  const createDeployActionsApiHandlers = ({
    url,
    method,
    params,
  }: CreateApiHandlersParams): [
    apiRequest: () => Promise<any>,
    onSuccess: () => void
  ] => [
    () =>
      pepApiRequest({
        method,
        url: generatePath(url, { solutionId }),
        params: getEdgeWorkloadEditParams(params),
      }),
    () => {
      setLoader(true);
      const timeOutId = setTimeout(() => {
        actions.edgeWorkloadConfigurations(solutionId);
      }, 3000);
      return () => {
        if (timeOutId) {
          setLoader(false);
          clearTimeout(timeOutId);
        }
      };
    },
  ];

  const [onReDeploy] = useStatefulApi(
    ...createDeployActionsApiHandlers({
      url: API_EDGEWORKLOAD_DEPLOY,
      method: RequestMethod.Put,
      params: { ...item, deploymentPaused: true },
    })
  );

  const [onDeploy] = useStatefulApi(
    ...createDeployActionsApiHandlers({
      url: API_EDGEWORKLOAD_DEPLOY,
      method: RequestMethod.Put,
      params: { ...item, deploymentPaused: false },
    })
  );

  const [deleteDialog, showDeleteDialog] = useModal(
    SolutionEdgeWorkloadDeleteModal,
    { item, onClose }
  );

  const [editDialog, showEditDialog] = useModal(
    SolutionEdgeWorkloadEditContainer,
    {
      item,
      onClose,
    }
  );

  const [workloadStatusDialog, showWorkloadStatusDialog] = useModal(
    SolutionWorkloadStatusViewModal,
    { item, setStatusType, onClose }
  );

  const [viewDialog, showViewDialog] = useModal(
    SolutionEdgeWorkloadViewContainer,
    {
      item,
      onClose,
    }
  );

  const handleWorkloadStatusDialog = () => {
    saveDataToStorage('DeploymentStatus', DeploymentStatus.Resources);
    showWorkloadStatusDialog();
  };

  const actionOptions = [
    {
      title: 'Delete',
      icon: <Delete />,
      onClick: showDeleteDialog,
      disabled: isPlatformReader || isPlatformEngineer,
      sx: 'flex',
    },
  ];

  return (
    <DataTableActions
      showView={true}
      canView={true}
      onView={showViewDialog}
      canEdit={isSolutionEditor || isValidUser}
      canDelete={isSolutionEditor || isValidUser}
      onEdit={showEditDialog}
      onDelete={showDeleteDialog}
      showDelete={
        item?.gitFlag === SolutionEdgeWorkloadRepositoryType.PepsenseEdge
      }
    >
      {!isDeploying && (
        <DataTableActionsButton
          data-testid="solution-redeploy-action"
          icon={deployfailed}
          title="stop deployment"
          onClick={onReDeploy}
          disabled={
            isPlatformReader ||
            (isPlatformEngineer &&
              item?.gitFlag === SolutionEdgeWorkloadRepositoryType.SolutionEdge)
          }
        />
      )}

      {isDeploying && (
        <DataTableActionsButton
          data-testid="solution-deploy-action"
          icon={deploy}
          title="deploy"
          onClick={onDeploy}
          disabled={
            isPlatformReader ||
            (isPlatformEngineer &&
              item?.gitFlag === SolutionEdgeWorkloadRepositoryType.SolutionEdge)
          }
        />
      )}
      {item?.gitFlag !== SolutionEdgeWorkloadRepositoryType.PepsenseEdge && (
        <DataTableActionsButton
          data-testid="solution-deployment-status-action"
          icon={deploymentStatus}
          title="Deployment status"
          onClick={handleWorkloadStatusDialog}
        />
      )}
      {item?.gitFlag !== SolutionEdgeWorkloadRepositoryType.PepsenseEdge && (
        <MoreMenu actions={actionOptions} />
      )}
      {loader && <PageCardLoader />}
      {deleteDialog}
      {editDialog}
      {workloadStatusDialog}
      {viewDialog}
    </DataTableActions>
  );
}

export default SolutionEdgeWorkloadActions;
