import ModalDialog, { ModalCloseHandler } from 'src/components/ModalDialog';
import { useEffect } from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useStoreUnitActions } from 'src/redux/helpers';
import { StorageAccountDetails } from './StorageAccountDetails';
import solutionsStore, { useSolutionDetails } from '../../redux';

type Props = {
  open: boolean;
  onClose: ModalCloseHandler;
};

function SolutionSettingsViewModal({ open, onClose }: Props) {
  const { solutionId } = useParams();
  const actions = useStoreUnitActions(solutionsStore);

  useEffect(() => {
    actions.details(solutionId);
  }, [solutionId]);

  const [data, loading] = useSolutionDetails();
  return (
    <ModalDialog
      open={open}
      title="Source Storage Details"
      showCancelBtn={false}
      onConfirm={onClose}
      loading={loading}
      confirmBtnText={'Close'}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      titleStyle={{ fontWeight: '500' }}
    >
      <Box style={{ display: 'flex' }} justifyContent="space-between">
        <StorageAccountDetails data={data} />
      </Box>
    </ModalDialog>
  );
}

export default SolutionSettingsViewModal;
