import { NestedRouter, RouterBreadcrumbs } from 'src/router';
import { routes } from './routes';

function Solutions() {
  return (
    <>
      <RouterBreadcrumbs routes={routes} />
      <NestedRouter routes={routes} />
    </>
  );
}

export default Solutions;
