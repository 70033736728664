import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { SxProps } from 'src/theme/types';
import ModalDialog, { ModalCloseHandler } from 'src/components/ModalDialog';
import { useForm } from 'src/components/Form';
import Select from 'src/components/Select';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import { RequestMethod, pepApiRequest, useStatefulApi } from 'src/services';
import { MenuItem, TextField } from '@mui/material';
import { generateApiPath } from 'src/utils';
import solutionsStore from '../redux';
import { GetPropertiesTempFormFields } from './formFields';
import { API_DEVICES_POST_PROPERTIES_VALUES } from '../constants';

type Props = {
  componentName: string;
  moduleName: string;
  newPropertyDataList: any;
  propertyData: any;
  desiredPropertyData: any;
  reportedPropertyData: any;
  propertiesData: any;
  open: boolean;
  onClose: ModalCloseHandler;
};

const modalStyle: SxProps = {
  '.MuiDialogContent-root': {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
};

const formStyle: SxProps = {
  gap: 0.5,
  '.MuiFormControlLabel-root': {
    alignSelf: 'flex-end',
    color: 'neutral.dark',
    typography: 'small1',
    marginTop: '20px',
  },
};

function SolutionPropertiesAssignTemplateModal({
  componentName,
  moduleName,
  newPropertyDataList,
  propertyData,
  desiredPropertyData,
  reportedPropertyData,
  propertiesData,
  open,
  onClose,
}: Props) {
  const { solutionId, solutionName } = useParams();
  const { showAlert } = useAlert();
  const [expanded, setExpanded] = React.useState(null);
  const [rowDetails, setRowDetails] = useState<any>([]);
  const [objDetails] = useState<any>([]);
  const [propertyValue, setPropertyValue] = useState();
  const [textBoxValue, setTextBoxValue] = useState({});
  const [selectBoxValue, setSelectBoxValue] = useState({});
  let objProp = {};

  const handleChangeVal = (name) => (event) => {
    setTextBoxValue((prevTextBoxVal) => ({
      ...prevTextBoxVal,
      [name]: event.target.value,
    }));
  };
  const handleChangeSelectVal = (name) => (event) => {
    setSelectBoxValue((prevBoxVal) => ({
      ...prevBoxVal,
      [name]: event.target.value,
    }));
  };
  const handlePropertyVal = (event) => {
    setPropertyValue(event.target.value);
  };
  const handleObjPropertyVal = (event) => {
    objProp = {
      propertyName: event.target.name,
      propertyValue: event.target.value,
    };
    if (objDetails.length !== 0) {
      for (let i = 0; i < objDetails.length; i += 1) {
        if (objDetails[i]?.propertyName === event.target.name) {
          objDetails[i].propertyValue = event.target.value;
        } else {
          objDetails.push(objProp);
        }
      }
    } else {
      objDetails.push(objProp);
    }
  };
  const getKeys = (params) => {
    const keys = params ? Object.keys(params) : [];
    const values: string[] = params ? Object.values(params) : [];
    const returnObj = {};

    for (let index = 0; index < keys.length; index += 1) {
      let obj = {};
      const ParamData = propertyData.filter(
        (items) =>
          items.name === keys[index] || items.displayName === keys[index]
      );
      const retKey = ParamData.length !== 0 ? ParamData[0].name : keys[index];
      let retValue: string | number = '';
      if (ParamData[0]?.schema.toLowerCase() === 'enum') {
        retValue = ParamData[0].enums?.find(
          (a) => a.value === Number(values[index])
        )?.label;
      } else {
        retValue =
          ParamData[0]?.schema === 'integer'
            ? Number(values[index])
            : values[index];
      }
      obj = { [retKey.replaceAll(' ', '')]: retValue };
      Object.assign(returnObj, obj);
    }
    return returnObj;
  };
  const getTextBoxKeys = () => {
    const returnObj = {};
    if (textBoxValue && Object.keys(textBoxValue)?.length !== 0) {
      const keys = textBoxValue ? Object.keys(textBoxValue) : [];
      const values: string[] = textBoxValue ? Object.values(textBoxValue) : [];
      const newObject = {};
      let component = '';
      let key: any = '';

      for (let index = 0; index < keys?.length; index += 1) {
        const element = keys[index];
        if (element?.includes('/')) {
          const newKeys = element?.split('/');

          [component, key] = newKeys;
          const ParamData = propertyData?.filter(
            (items: any) =>
              items?.name === newKeys[1] || items?.displayName === newKeys[1]
          );
          Object.assign(newObject, {
            [key.replaceAll(' ', '')]:
              ParamData[0]?.schema === 'integer'
                ? Number(values[index])
                : values[index],
          });
          Object.assign(returnObj, {
            [component.replaceAll(' ', '')]: newObject,
          });
        } else {
          let obj = {};
          const ParamData = propertyData.filter(
            (items) =>
              items.name === keys[index] || items.displayName === keys[index]
          );
          const retKey =
            ParamData.length !== 0 ? ParamData[0].name : keys[index];
          const retValue =
            ParamData[0]?.schema === 'integer'
              ? Number(values[index])
              : values[index];
          obj = { [retKey.replaceAll(' ', '')]: retValue };
          Object.assign(returnObj, obj);
        }
      }
    } else if (selectBoxValue && Object.keys(selectBoxValue)?.length !== 0) {
      const keys = selectBoxValue ? Object.keys(selectBoxValue) : [];
      const values: string[] = selectBoxValue
        ? Object.values(selectBoxValue)
        : [];

      const newObject = {};

      for (let index = 0; index < keys?.length; index += 1) {
        const element = keys[index];

        const ParamData = propertyData?.filter(
          (items: any) =>
            items?.name === element || items?.displayName === element
        );
        const retKey = ParamData.length !== 0 ? ParamData[0].name : keys[index];

        Object.assign(newObject, {
          [retKey.replaceAll(' ', '')]:
            ParamData[0]?.schema?.toLowerCase() === 'enum'
              ? ParamData[0].enums?.find(
                  (a) => a.value === Number(values[index])
                ).label
              : values[index],
        });
        Object.assign(returnObj, newObject);
      }
    }
    return returnObj;
  };

  const [onSubmit, loading] = useStatefulApi(
    (params) =>
      pepApiRequest({
        method: RequestMethod.Put,
        url: generateApiPath(API_DEVICES_POST_PROPERTIES_VALUES, {
          solutionId,
          deviceId: solutionName,
        }),
        params: {
          componentName:
            componentName === 'DefaultComponent' ? '' : componentName,
          moduleName: moduleName ?? '',
          properties: getKeys(params),
        },
      }),
    () => {
      showAlert({
        type: AlertType.Success,
        title: 'Properties Executed Successfully.',
        text: 'The property has been executed successfully.',
      });
    }
  );

  const [onHandleSubmit, onLoading] = useStatefulApi(
    (params) =>
      pepApiRequest({
        method: RequestMethod.Put,
        url: generateApiPath(API_DEVICES_POST_PROPERTIES_VALUES, {
          solutionId,
          deviceId: solutionName,
        }),
        params: {
          componentName:
            componentName === 'DefaultComponent' ? '' : componentName,
          moduleName: moduleName ?? '',
          properties: getTextBoxKeys(),
        },
      }),
    () => {
      showAlert({
        type: AlertType.Success,
        title: 'Properties Executed Successfully.',
        text: 'The property has been executed successfully.',
      });
    }
  );

  const handleChange = (row, panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : null);
    setRowDetails(row);
  };

  const [FormComponent, handleSubmit] = useForm({
    sx: formStyle,
    fields: GetPropertiesTempFormFields(
      rowDetails,
      propertiesData,
      desiredPropertyData,
      reportedPropertyData,
      handlePropertyVal,
      handleObjPropertyVal
    ),
    onSubmit,
  });
  return (
    <ModalDialog
      open={open}
      title="Properties"
      onClose={onClose}
      showConfirmBtn={false}
      sx={modalStyle}
      loading={loading || onLoading}
      maxWidth="lg"
      fullWidth
    >
      <>
        {newPropertyDataList?.length ? (
          <>
            {newPropertyDataList?.map((row, index) => (
              <div>
                <Accordion
                  sx={{
                    borderBottomLeftRadius: '5px',
                    borderBottomRightRadius: '5px',
                    boxShadow: '0 1px 1px 2px rgba(0, 0, 0, 0.1)',
                  }}
                  onChange={handleChange(row, index)}
                  expanded={expanded === index}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon color="primary" />}
                    sx={{ padding: '9px', marginTop: '25px' }}
                  >
                    <Typography variant="h4" fontWeight="500">
                      {row.headerName}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{ backgroundColor: 'rgba(165, 165, 165, 0.07)' }}
                  >
                    <Box
                      style={{ display: 'flex' }}
                      flexDirection={'row'}
                      justifyContent="space-between"
                    >
                      <Typography fontSize="16px" fontWeight="600">
                        Schema
                      </Typography>
                    </Box>
                    {row?.data[0].map((dataRow, index) => (
                      <>
                        <Box>
                          <Box
                            style={{ display: 'flex', marginTop: '20px' }}
                            flexDirection={'row'}
                            justifyContent="space-between"
                          >
                            {dataRow.schema?.toLowerCase() !== 'enum' && (
                              <TextField
                                id={dataRow.displayName}
                                name={dataRow.displayName}
                                type={
                                  dataRow.schema?.toLowerCase() === 'integer'
                                    ? 'number'
                                    : 'text'
                                }
                                value={textBoxValue[dataRow.displayName]}
                                onChange={handleChangeVal(dataRow.displayName)}
                                label={dataRow.displayName}
                                placeholder="Enter the value"
                                required={true}
                                sx={{
                                  width: ({ spacing }) => spacing(100),
                                }}
                              />
                            )}
                            {dataRow.schema === 'Enum' && (
                              <Select
                                label={dataRow.displayName}
                                placeholder="Select the value"
                                required
                                id={dataRow.displayName}
                                options={dataRow.enums}
                                value={selectBoxValue[dataRow.displayName]}
                                sx={{
                                  width: ({ spacing }) => spacing(100),
                                }}
                                onChange={handleChangeSelectVal(
                                  dataRow.displayName
                                )}
                              />
                            )}
                          </Box>
                        </Box>
                      </>
                    ))}
                  </AccordionDetails>
                  <Box textAlign="right" mt={3}>
                    <Button
                      type="submit"
                      onClick={onHandleSubmit}
                      variant="contained"
                      sx={{
                        marginBottom: '20px',
                        marginRight: '20px',
                        marginTop: '10px',
                      }}
                    >
                      Update
                    </Button>
                  </Box>
                </Accordion>
              </div>
            ))}
          </>
        ) : (
          <>
            {propertyData?.map((row, index) => (
              <div>
                <Accordion
                  sx={{
                    borderBottomLeftRadius: '5px',
                    borderBottomRightRadius: '5px',
                    boxShadow: '0 1px 1px 2px rgba(0, 0, 0, 0.1)',
                  }}
                  onChange={handleChange(row, index)}
                  expanded={expanded === index}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon color="primary" />}
                    sx={{ padding: '9px', marginTop: '25px' }}
                  >
                    <Typography variant="h4" fontWeight="500">
                      {row.displayName}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{ backgroundColor: 'rgba(165, 165, 165, 0.07)' }}
                  >
                    <Box
                      style={{ display: 'flex' }}
                      flexDirection={'row'}
                      justifyContent="space-between"
                    >
                      <Typography fontSize="16px" fontWeight="600">
                        Schema
                      </Typography>
                    </Box>
                    <Box>
                      <Box
                        style={{ display: 'flex', marginTop: '20px' }}
                        flexDirection={'row'}
                        justifyContent="space-between"
                      >
                        {FormComponent}
                      </Box>
                      <Box textAlign="right" mt={3}>
                        <Button
                          type="submit"
                          onClick={handleSubmit}
                          variant="contained"
                          sx={{ marginTop: '50px' }}
                        >
                          Update
                        </Button>
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </>
        )}
      </>
    </ModalDialog>
  );
}

export default SolutionPropertiesAssignTemplateModal;
