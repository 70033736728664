import React, { useContext } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { getCellKey, getCellTitle } from '../utils';
import { Row, Column, SelectRowHandler, ToggleGroupHandler } from '../types';
import { RowContext } from './DataTableRowContext';

type Props<TRow> = {
  row: TRow;
  columns: Column<TRow>[];
  isGroup?: boolean;
  expandable?: boolean;
  expanded?: boolean;
  selected: boolean;
  onSelect: SelectRowHandler<TRow>;
  onToggleGroup?: ToggleGroupHandler<TRow>;
};

function DataTableRowContent<TRow extends Row>({
  row,
  columns,
  onSelect,
  ...rest
}: Props<TRow>) {
  const [onClick] = useContext(RowContext);
  return (
    <TableRow onClick={onClick}>
      {columns.map((column) => {
        const { Component, id, sx, title } = column;
        const maxWidth = title?.length || 10 * 10;

        return (
          <TableCell
            data-testid={`data-table-col-data-${id}`}
            key={getCellKey<TRow>(row, column)}
            title={getCellTitle<TRow>(row, column)}
            sx={
              Component
                ? sx
                : { maxWidth, overflow: 'hidden', whiteSpace: 'nowrap' }
            }
          >
            {Component ? (
              <Component
                value={row[id]}
                item={row}
                onSelect={onSelect as SelectRowHandler<Row | undefined>}
                {...rest}
              />
            ) : (
              row[id]
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

export default DataTableRowContent;
