import take from 'lodash/take';
import uniqBy from 'lodash/uniqBy';

export const addOrRemoveValFromArr = <T = any>(array: T[], value: T) => {
  const valueIndex = array.indexOf(value);
  array = [...array];

  if (valueIndex > -1) {
    array.splice(valueIndex, 1);
  } else {
    array.push(value);
  }
  return array;
};

export const prepandItemToQuery = <T = any>(
  item: T,
  query: T[] = [],
  limit,
  id?: string
) =>
  take(
    uniqBy([item, ...query], (item) => (id ? item[id] : item)),
    limit
  );
