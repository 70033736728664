import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Grid,
  FormControl,
  TextField,
  Alert,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Switch,
  FormControlLabel,
  Button,
  IconButton,
  Typography,
  RadioGroup,
  Radio,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import { blue } from '@mui/material/colors';
import { Condition, CompositeClause, WindowSize, Rule } from './interfaces';
import { getEnumKeys } from './helper';
import {
  useSolutionAggregatePropertyMeta,
  useSolutionDetails,
  useSolutionTelemetryPropertyMeta,
  useSolutionTelemtryGroupPropOptions,
} from '../redux';
import { SolutionDeviceSpecificRulesManagement } from '../types';
import { camelizeKeys } from './utils';
import SolutionFeatureInformation from '../components/SolutionFeatureInfomation';

interface RulesConditionCard {
  item: SolutionDeviceSpecificRulesManagement;
  rule: Rule;
  isEditRuleModal: boolean;
  error: string | null;
  conditionQueryOperator: string;
  onChangeTriggerTheRuleIf: (newValue: string) => void;
  timeAggregation: boolean;
  onChangeTimeAggregation: (newValue: boolean) => void;
  timeWindow?: number;
  onChangeTimeWindow: (newValue: number) => void;
  conditions: Condition[];
  updateConditions: (conditions: Condition[]) => void;
}
const RulesConditionCard: React.FC<RulesConditionCard> = ({
  item,
  rule,
  isEditRuleModal,
  error,
  conditionQueryOperator,
  onChangeTriggerTheRuleIf,
  timeAggregation,
  onChangeTimeAggregation,
  timeWindow,
  onChangeTimeWindow,
  conditions,
  updateConditions,
}) => {
  const [data] = useSolutionDetails();
  const isDataIngestion = data?.features?.dataIngestion;
  const [addFilters, setAddFilters] = useState<boolean>(false);
  const [telemetry, setTelemetry] = useState('');
  const [timeWindowData, setTimeWindowData] = useState(
    item?.TimeAggregationValueInMinutes ?? 0
  );
  const [expanded, setExpanded] = useState(true);

  const [telemetryOptions, telemetryLoading] =
    useSolutionTelemtryGroupPropOptions();
  const conditionTel = camelizeKeys(item?.ConditionQueryInfo);

  const propertyValue = conditionTel?.map((prop) => prop.property.id);
  const retPropertyValue = (arr) => {
    let val = '';
    for (let i = 0; i < arr.length; i += 1) {
      val = arr[i];
    }
    if (val) {
      return val;
    }
    return telemetry;
  };

  useEffect(() => {
    if (isEditRuleModal) {
      setTelemetry(retPropertyValue(propertyValue));
    }
  }, []);

  const { operators: operatorOptions, enums: filterValueOptions } =
    useSolutionTelemetryPropertyMeta(telemetry);
  const { aggregationFunctions: aggregationOptions } =
    useSolutionAggregatePropertyMeta(telemetry);

  const handleTriggerTheRuleIf = (event: SelectChangeEvent<string>) => {
    onChangeTriggerTheRuleIf(event.target.value);
  };
  const handleTimeWindowChange = (event) => {
    setTimeWindowData(event.target.value);
    onChangeTimeWindow(event.target.value);
  };
  const handleTimeAggregation = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    onChangeTimeAggregation(isChecked);
    if (!isChecked) {
      onChangeTimeWindow(0);
      setTimeWindowData(0);
    }
  };
  const handleAddRowCondition = () => {
    setAddFilters(true);
    return updateConditions([
      ...conditions,
      {
        Telemetry: '',
        Operator: undefined,
        Value: '',
        ValueLabel: undefined,
        dropDownValue: '',
      },
    ]);
  };
  const handleDeleteRowCondition = (index: number) => {
    const newFilters = [...conditions];
    newFilters.splice(index, 1);
    updateConditions(newFilters);
  };

  const handleUpdateRowCondition =
    (index: number) => (event: SelectChangeEvent<string>) => {
      setTelemetry(event.target.value);

      handleUpdateTelemetryGenericRowCondition(
        index,
        'Telemetry'
      )(event.target.value);
    };
  const handleUpdateTelemetryGenericRowCondition =
    (index: number, field: keyof Condition) => (value: string) => {
      updateConditions(
        conditions.map(
          (condition, i): Condition =>
            i === index ? { ...condition, [field]: value } : condition
        )
      );
    };
  const fetchValue = (condition: Condition): any => {
    let valueType: any = condition?.ValueType;
    if (isEditRuleModal)
      if (condition.IsValue) valueType = 'text';
      else valueType = 'dropdown';

    return valueType;
  };

  const handleUpdateGenericRowCondition =
    (index: number, field: keyof Condition) =>
    (
      event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>
    ) => {
      updateConditions(
        conditions.map(
          (condition, i): Condition =>
            i === index
              ? { ...condition, [field]: event.target.value }
              : condition
        )
      );
    };

  const handleChangeAccordion = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Accordion expanded={expanded} onChange={handleChangeAccordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
        >
          <Typography variant="h4" fontWeight="500">
            Conditions
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack>
            <Grid item sx={{ marginBottom: '40px' }}>
              <SolutionFeatureInformation featureInfo="Conditions define when your rule is triggered." />
            </Grid>
            <Grid item container spacing={2}>
              {!isDataIngestion && (
                <SolutionFeatureInformation featureInfo="Time aggregations conditions are not applicable since data ingestion in PEPsense disabled during solution creation." />
              )}
            </Grid>
            <Grid item>
              <Grid
                item
                container
                spacing={1}
                sx={{ marginBottom: 1, marginTop: '-40px' }}
              >
                <Grid item>
                  <FormControl required sx={{ m: 1, ml: '0px', minWidth: 415 }}>
                    <InputLabel
                      error={error !== null && conditionQueryOperator === ''}
                    >
                      Trigger the rule if
                    </InputLabel>
                    <Select
                      error={error !== null && conditionQueryOperator === ''}
                      value={
                        isEditRuleModal
                          ? rule.conditionQueryOperator.trim()
                          : conditionQueryOperator
                      }
                      label="Trigger the rule if *"
                      onChange={handleTriggerTheRuleIf}
                    >
                      {getEnumKeys(CompositeClause).map((key) => (
                        <MenuItem key={key} value={key}>
                          {CompositeClause[key]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={
                          isEditRuleModal
                            ? rule.timeAggregation
                            : timeAggregation
                        }
                        onChange={handleTimeAggregation}
                        disabled={!isDataIngestion}
                      />
                    }
                    label="Time aggregation"
                  />
                </Grid>
                {isDataIngestion && (
                  <Grid item>
                    <FormControl
                      disabled={
                        isEditRuleModal
                          ? !rule.timeAggregation
                          : !timeAggregation
                      }
                      required={timeAggregation}
                      sx={{ m: 1, minWidth: 415 }}
                    >
                      <InputLabel
                        error={
                          timeAggregation &&
                          error !== null &&
                          timeWindow === undefined
                        }
                      >
                        Time Window
                      </InputLabel>
                      <Select
                        error={
                          timeAggregation &&
                          error !== null &&
                          (timeWindow === 0 || timeWindow === undefined)
                        }
                        value={timeWindowData}
                        label="Time window"
                        onChange={handleTimeWindowChange}
                      >
                        {WindowSize.map((option) => (
                          <MenuItem value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
              <Stack>
                <Grid item container direction="column" spacing={1}>
                  {conditions?.map((condition, index) => (
                    <>
                      <Grid item container spacing={1} key={index} gap={2}>
                        <Grid item sx={{ paddingLeft: '0px' }}>
                          <FormControl
                            required
                            sx={{ minWidth: 321, paddingLeft: '0px' }}
                          >
                            <InputLabel
                              error={
                                error !== null && condition.Telemetry === ''
                              }
                            >
                              Telemetry
                            </InputLabel>
                            <Select
                              error={
                                error !== null && condition.Telemetry === ''
                              }
                              label="Telemetry *"
                              value={condition.Telemetry}
                              onChange={handleUpdateRowCondition(index)}
                            >
                              {telemetryOptions.map((option) => (
                                <MenuItem
                                  key={option.label}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item>
                          <FormControl required sx={{ minWidth: 321 }}>
                            <InputLabel
                              error={
                                addFilters &&
                                error !== null &&
                                (condition.Operator?.length === 0 ||
                                  condition.Operator === undefined ||
                                  condition.Operator === null)
                              }
                            >
                              Operator
                            </InputLabel>
                            <Select
                              error={
                                addFilters &&
                                error !== null &&
                                (condition.Operator?.length === 0 ||
                                  condition.Operator === undefined ||
                                  condition.Operator === null)
                              }
                              value={condition.Operator}
                              label="Operator *"
                              onChange={handleUpdateGenericRowCondition(
                                index,
                                'Operator'
                              )}
                            >
                              {operatorOptions.map((option) => (
                                <MenuItem
                                  key={option.label}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        {timeAggregation ? (
                          <Grid item>
                            <FormControl
                              required={timeAggregation}
                              sx={{ minWidth: 321 }}
                            >
                              <InputLabel
                                error={
                                  timeAggregation &&
                                  error !== null &&
                                  (condition.Aggregation?.length === 0 ||
                                    condition.Aggregation === undefined ||
                                    condition.Aggregation === null)
                                }
                              >
                                Aggregation
                              </InputLabel>
                              {isDataIngestion && (
                                <Select
                                  error={
                                    timeAggregation &&
                                    error !== null &&
                                    (condition.Aggregation?.length === 0 ||
                                      condition.Aggregation === undefined ||
                                      condition.Aggregation === null)
                                  }
                                  required
                                  value={condition.Aggregation}
                                  label="Aggregation *"
                                  onChange={handleUpdateGenericRowCondition(
                                    index,
                                    'Aggregation'
                                  )}
                                >
                                  {aggregationOptions.map((key) => (
                                    <MenuItem key={key.label} value={key.value}>
                                      {key.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              )}
                            </FormControl>
                          </Grid>
                        ) : null}
                        <Grid item>
                          <IconButton
                            component="button"
                            aria-label="delete"
                            onClick={(
                              event: React.MouseEvent<HTMLButtonElement>
                            ) => handleDeleteRowCondition(index)}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Grid item container direction="column" spacing={2}>
                        <Grid item>
                          {
                            <FormControl required>
                              <RadioGroup
                                row
                                value={fetchValue(condition)}
                                onChange={handleUpdateGenericRowCondition(
                                  index,
                                  'ValueType'
                                )}
                              >
                                <FormControlLabel
                                  value={'text' || undefined}
                                  control={<Radio />}
                                  label="Enter a value"
                                  checked={
                                    condition.ValueType === 'text' ||
                                    condition.ValueType === undefined
                                  }
                                />
                                <FormControlLabel
                                  value="dropdown"
                                  control={<Radio />}
                                  label="Select a value"
                                  checked={condition.ValueType === 'dropdown'}
                                />
                              </RadioGroup>
                              {condition.ValueType === 'dropdown' ? (
                                <FormControl required sx={{ minWidth: 300 }}>
                                  <InputLabel>Value</InputLabel>
                                  <Select
                                    error={
                                      addFilters &&
                                      error !== null &&
                                      (condition.Value?.length === 0 ||
                                        condition.Value === undefined ||
                                        condition.Value === null)
                                    }
                                    value={condition.dropDownValue}
                                    label="Value"
                                    onChange={handleUpdateGenericRowCondition(
                                      index,
                                      'dropDownValue'
                                    )}
                                  >
                                    {filterValueOptions?.map((value) => (
                                      <MenuItem
                                        key={
                                          isEditRuleModal
                                            ? condition.Operator
                                            : value.label
                                        }
                                        value={value.value}
                                      >
                                        {value.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              ) : (
                                <TextField
                                  error={
                                    addFilters &&
                                    error !== null &&
                                    (condition.Value?.length === 0 ||
                                      condition.Value === undefined ||
                                      condition.Value === null)
                                  }
                                  label="Value"
                                  variant="outlined"
                                  required
                                  placeholder="Enter the value"
                                  value={condition.Value}
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) =>
                                    handleUpdateGenericRowCondition(
                                      index,
                                      'Value'
                                    )(event)
                                  }
                                />
                              )}
                            </FormControl>
                          }
                        </Grid>
                      </Grid>
                    </>
                  ))}
                </Grid>
                <Grid item>
                  <Button
                    sx={{
                      marginLeft: 1,
                      paddingBlockStart: 1,
                      '& button': { m: 2 },
                      fontSize: 16,
                      fontWeight: 560,
                    }}
                    size="medium"
                    onClick={handleAddRowCondition}
                  >
                    + Add
                  </Button>
                </Grid>
              </Stack>
            </Grid>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default RulesConditionCard;
