import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'src/components/Form';
import ModalDialog, { ModalCloseHandler } from 'src/components/ModalDialog';
import {
  SolutionSourceImageRepoContainerType,
  SolutionSourceImageRepositoryItem,
} from '../../types';
import { getSourceImageRepositoryFormFields } from '../formFields';

type Props = {
  item?: SolutionSourceImageRepositoryItem;
  title: string;
  loading?: boolean;
  open: boolean;
  onClose: ModalCloseHandler;
  onSubmit: (values: any) => void;
  confirmBtnText?: string;
};

function SolutionSourceImageRepoEditModal({
  item,
  title,
  loading,
  open,
  onClose,
  onSubmit,
  confirmBtnText = 'Save',
}: Props) {
  const [ImagePullSecret, setImagePullSecret] = useState(
    item?.ImagePullSecret || ''
  );
  const [containerType, setContainerType] = useState(
    item?.containerRepositoryType ?? SolutionSourceImageRepoContainerType.Acr
  );

  const handlePaste = () => {
    navigator.clipboard.readText().then((clipboardText) => {
      setImagePullSecret(clipboardText);
      setValue('ImagePullSecret', clipboardText.trim());
    });
  };
  const handleAcrClientSecretPaste = () => {
    navigator.clipboard.readText().then((clipboardText) => {
      setValue('AcrClientSecret', clipboardText.trim());
    });
  };
  const handleEcrSecretKeyPaste = () => {
    navigator.clipboard.readText().then((clipboardText) => {
      setValue('ecrSecretKey', clipboardText.trim());
    });
  };

  const onChange = useCallback(
    ({
      ImagePullSecret,
      containerRepositoryType,
    }: SolutionSourceImageRepositoryItem) => {
      setImagePullSecret(ImagePullSecret ?? '');
      setContainerType(
        containerRepositoryType || SolutionSourceImageRepoContainerType.Acr
      );
    },
    []
  );

  const fields = useMemo(
    () =>
      getSourceImageRepositoryFormFields(
        ImagePullSecret,
        containerType,
        handlePaste,
        handleAcrClientSecretPaste,
        handleEcrSecretKeyPaste
      ),
    [handlePaste]
  );
  const [form, handleSubmit, setValue] = useForm({
    visibleValuesOnly: true,
    values: item,
    fields,
    onChange,
    onSubmit,
  });

  return (
    <ModalDialog
      open={open}
      title={title}
      confirmBtnText={confirmBtnText}
      onClose={onClose}
      onConfirm={handleSubmit}
      loading={loading}
      fullWidth
    >
      {form}
    </ModalDialog>
  );
}

export default SolutionSourceImageRepoEditModal;
