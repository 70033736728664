import { useMemo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { parseQueryString, encodeQuery } from 'src/utils';
import pickBy from 'lodash/pickBy';
import pick from 'lodash/pick';
import keys from 'lodash/keys';

export function useQueryString() {
  const location = useLocation();
  return useMemo(
    () => parseQueryString(location.search) || {},
    [location.search]
  );
}

export function useQueryStateValues<T extends Record<string, any>>(
  defaultValue: T,
  replace = true,
  copyState = true
): [T, (value: T) => void] {
  const query = useQueryString();
  const location = useLocation();
  const navigate = useNavigate();
  const currentKeys = keys(defaultValue);
  const currentValue = { ...defaultValue, ...pick(query, currentKeys) };
  const setValue = useCallback(
    async (values: Partial<T>) => {
      const currQuery = parseQueryString(window.location.search);
      const newQuery = pickBy({ ...currQuery, ...values });
      const search = encodeQuery(newQuery, [], '');
      const { state, ...restLocation } = location;
      await navigate(
        { ...restLocation, search },
        { replace, state: copyState ? state : null }
      );
    },
    [replace, location, copyState]
  );
  return [currentValue, setValue];
}
