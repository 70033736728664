import React from 'react';

import { NoContentWrapper } from 'src/components/NoContent';

function HealthCheck() {
  return (
    <NoContentWrapper
      height="100vh"
      title="Health-check page"
      subtitle={`Mgmt Portal v.${process.env.REACT_APP_VERSION}`}
    />
  );
}

export default HealthCheck;
