import { RouteProps } from 'src/router';
import * as ROUTES from './constants';
import SolutionsList from './SolutionsList';
import { NewSolution } from './EditSolution';
import SolutionDetails, {
  SolutionDetailsTitle,
  solutionDetailsRoutes,
} from './SolutionDetails';

export const routes: RouteProps[] = [
  {
    index: true,
    component: SolutionsList,
  },
  {
    path: ROUTES.NEW_SOLUTION,
    component: NewSolution,
    meta: {
      title: 'New Solution',
    },
  },
  {
    path: ROUTES.SOLUTION_DETAILS,
    component: SolutionDetails,
    children: solutionDetailsRoutes,
    meta: {
      title: 'Solution details',
      TitleComponent: SolutionDetailsTitle,
    },
  },
];
