export const typography = {
  fontSize: 16,
  htmlFontSize: 10,
  small1: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '1.4rem',
  },
  small1bold: {
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '1.4rem',
  },
  small2: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '1.8rem',
  },
  body1: {
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '2.2rem',
  },
  body1md: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '2.2rem',
  },
  body1bold: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '2.2rem',
  },
  body2: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '2rem',
  },
  body2md: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '2rem',
  },
  body2bold: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '2rem',
  },
  body3: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '1.8rem',
  },
  body3md: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '1.8rem',
  },
  body3bold: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '1.8rem',
  },
  body4: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '1.5rem',
  },
  h1: {
    fontFamily: 'Stolzl',
    fontSize: 48,
    lineHeight: '6rem',
  },
  h2: {
    fontFamily: 'Stolzl',
    fontSize: 38,
    lineHeight: '4.7rem',
  },
  h3: {
    fontFamily: 'Stolzl',
    fontSize: 28,
    lineHeight: '3.5rem',
  },
  h4: {
    fontFamily: 'Stolzl',
    fontSize: 18,
    lineHeight: '2.4rem',
  },
  hero: {
    fontFamily: 'Stolzl',
    fontSize: 60,
    fontWeight: 500,
    lineHeight: '7.5rem',
  },
};
