import React from 'react';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { ROOT as ROUTE_ROOT } from 'src/router';
import { SxProps } from 'src/theme/types';
import Logo from 'src/assets/icons/pep-logo.svg';
import IconLogo from 'src/assets/icons/pep-icon-logo.svg';
import { SIDEBAR_WIDTH_CLOSED } from '../constants';

const getLogoStyle = (open: boolean): SxProps => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
  height: ({ spacing }) => spacing(SIDEBAR_WIDTH_CLOSED * 1.6),
  backgroundImage: `url(${open ? Logo : IconLogo})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
});

type Props = {
  open: boolean;
};

function SideBarLogo({ open }: Props) {
  return (
    <Link component={RouterLink} sx={getLogoStyle(open)} to={ROUTE_ROOT} />
  );
}

export default SideBarLogo;
