import React from 'react';
import { useCurrentSolutionName } from '../../redux';

type Props = {
  title?: string;
};

function SolutionDetailsTitle({ title }: Props) {
  const solutionName = useCurrentSolutionName();

  return <>{solutionName || title}</>;
}

export default SolutionDetailsTitle;
