import { SiteItemStatus } from './site';

export type SiteDeviceItem = {
  deviceName: string;
  status: SiteDeviceStatus;
  deviceLabel: string;
};

export enum SiteDeviceStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING',
}

export enum SiteDeviceItemField {
  DeviceName = 'deviceName',
  Status = 'status',
  Label = 'deviceLable',
}

export type SiteDeviceNodesItem = {
  name: string;
  serverUrl?: string;
  serverToken?: string;
  status: SiteDeviceNodeStatus;
  pods?: number;
};

export enum SiteDeviceNodeStatus {
  Ready = 'Ready',
  Unavailable = 'UNAVAILABLE',
}

export enum SiteDeviceNodesItemField {
  Name = 'name',
  Status = 'status',
  Pods = 'pods',
  ServerUrl = 'serverUrl',
  ServerToken = 'serverToken',
}

export type SiteDeviceProjectItem = {
  nameSpace: string;
};

export enum SiteDeviceProjectItemField {
  Namespace = 'nameSpace',
}

export type ClusterDetail = {
  name: string;
  state: SiteItemStatus;
};

export enum SiteClusterItemField {
  Name = 'name',
}
