import React, { useEffect, useState } from 'react';
import Add from '@mui/icons-material/Add';
import { useParams, generatePath } from 'react-router-dom';
import { Box } from '@mui/system';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import DataTable, {
  DataTableWrapper,
  DataTableWrapperActionButton,
  useTableQueryParams,
  getActionsColConfig,
  DataTableBoolCell,
} from 'src/components/DataTable';
import { useModal } from 'src/hooks';
import { isModalComplete, ModalCloseReason } from 'src/components/ModalDialog';
import { useStoreUnitActions } from 'src/redux/helpers';
import { useUserRoles } from 'src/auth';
import SolutionRuleConfigurationActions from './SolutionRuleConfigurationActions';
import solutionsStore, { useSolutionRulesManagement } from '../redux';
import {
  SolutionDeviceRuleManagementField,
  SolutionDevicesRulesManagementList,
} from '../types';
import RuleForm from './RuleForm';
import SolutionSpecificRuleDeleteModal from './SolutionSpecificRuleDeleteModal';
import MoreActionsMenu from './MoreActionsMenu';
import { API_SOLUTION_RULES_MANAGEMENT_ENABLE } from '../constants';

function RuleConfigurationList() {
  const { showAlert } = useAlert();
  const { paging } = useTableQueryParams();
  const actions = useStoreUnitActions(solutionsStore);
  const { isPlatformReader } = useUserRoles();
  const { solutionId } = useParams();
  const [item, setSelectedItems] = useState<any[]>([]);
  const [data, loading] = useSolutionRulesManagement();
  const editRuleValue = false;

  const formattedData = data.map((element) => ({
    id: element.Id ? element.Id : '',
    ...element,
  }));

  const columns = [
    {
      id: SolutionDeviceRuleManagementField.Name,
      title: 'Rule Name',
    },
    {
      id: SolutionDeviceRuleManagementField.Enabled,
      title: 'Enabled',
      Component: DataTableBoolCell,
    },
    {
      id: SolutionDeviceRuleManagementField.DeviceTemplate,
      title: 'Device Template',
    },

    getActionsColConfig(SolutionRuleConfigurationActions),
  ];
  const onClose = (event, reason) =>
    isModalComplete(reason) && actions.rulesManagement(solutionId);
  const [NewRuleCreationDialog, showNewRuleCreationDialog] = useModal(
    RuleForm,
    {
      editRuleValue,
      onClose: (event, reason) => isModalComplete(reason),
    }
  );

  const [DeleteDialog, handleDelete] = useModal(
    SolutionSpecificRuleDeleteModal,
    {
      item,
      onClose,
    }
  );

  const [canEnable, setCanEnable] = useState(false);
  const [canDisable, setCanDisable] = useState(false);
  const itemEnable = item.map((x) => x.Enabled);
  const itemIds = item.map((x) => x.id);

  useEffect(() => {
    setCanEnable(!item.some((items) => items.Enabled === false));
    setCanDisable(!item.some((items) => items.Enabled === true));
  }, [itemEnable]);

  const [handleDisable] = useStatefulApi(
    () =>
      pepApiRequest({
        method: RequestMethod.Put,
        url: generatePath(API_SOLUTION_RULES_MANAGEMENT_ENABLE, {
          enabled: 'false',
        }),
        params: { ids: itemIds },
      }),
    () => {
      showAlert({
        type: AlertType.Info,
        title: itemIds.length > 1 ? 'Rules are Disabled' : 'Rule is Disabled',
        text:
          itemIds.length > 1
            ? 'Selected Rules are disabled successfully.'
            : 'Selected Rule is disabled successfully.',
      });
      onClose(null, ModalCloseReason.completeAction);
      actions.rulesManagement(solutionId);
      setSelectedItems((prevItems) =>
        prevItems.map((item) => ({ ...item, Enabled: false }))
      );
    }
  );

  const [handleEnable] = useStatefulApi(
    () =>
      pepApiRequest({
        method: RequestMethod.Put,
        url: generatePath(API_SOLUTION_RULES_MANAGEMENT_ENABLE, {
          enabled: 'true',
        }),
        params: { ids: itemIds },
      }),
    () => {
      showAlert({
        type: AlertType.Info,
        title: itemIds.length > 1 ? 'Rules are Enabled' : 'Rule is Enabled',
        text:
          itemIds.length > 1
            ? 'Selected Rules are Enabled successfully.'
            : 'Selected Rule is Enabled successfully.',
      });
      onClose(null, ModalCloseReason.completeAction);
      actions.rulesManagement(solutionId);
      setSelectedItems((prevItems) =>
        prevItems.map((item) => ({ ...item, Enabled: true }))
      );
    }
  );

  function onSelectItems(selectedItems) {
    const items = Object.keys(selectedItems).filter((k) => selectedItems[k]);

    const selectedRuleItems: SolutionDevicesRulesManagementList[] = [];

    items.forEach((item) => {
      const selectedItem = formattedData.find(
        (dataItem) => dataItem.id === item
      );
      if (selectedItem) {
        selectedRuleItems.push(selectedItem);
      }
    });
    setSelectedItems(selectedRuleItems);
  }
  useEffect(() => {
    actions.rulesManagement(solutionId);
  }, []);

  return (
    <>
      <DataTableWrapper
        title="Rule Configuration"
        style={{ fontWeight: 'bold' }}
        actionsPanel={
          <Box>
            <DataTableWrapperActionButton
              onClick={showNewRuleCreationDialog}
              startIcon={<Add />}
              disabled={isPlatformReader}
            >
              New Rule
            </DataTableWrapperActionButton>
            {item.length >= 1 && (
              <MoreActionsMenu
                onDeleteClick={handleDelete}
                onDisableClick={handleDisable}
                onEnableClick={handleEnable}
                canEnable={canEnable}
                canDisable={canDisable}
              />
            )}
          </Box>
        }
        searchBar={true}
      >
        <DataTable
          columns={columns}
          data={formattedData}
          loading={loading}
          {...paging}
          checkboxes={true}
          sortable={true}
          onSelectItems={onSelectItems}
        />
        {NewRuleCreationDialog}
        {DeleteDialog}
      </DataTableWrapper>
    </>
  );
}

export default RuleConfigurationList;
