import React from 'react';

type Props = {
  value?: string[];
};

function DataTableBoolCell({ value }: Props) {
  return <>{value?.join(', ')}</>;
}

export default DataTableBoolCell;
