import { RouteProps } from 'src/router';
import * as ROUTES from './constants';
import SitesList from './SitesList';
import SiteDetails, {
  siteDetailsRoutes,
  SiteDetailsTitle,
} from './SitesDetails';
import { SiteNew } from './SiteEdit';

export const routes: RouteProps[] = [
  {
    index: true,
    component: SitesList,
  },
  {
    path: ROUTES.NEW_SITE,
    component: SiteNew,
    meta: {
      title: 'New Site',
    },
  },
  {
    path: ROUTES.SITE_DETAILS,
    component: SiteDetails,
    children: siteDetailsRoutes,
    meta: {
      title: 'Site details',
      TitleComponent: SiteDetailsTitle,
    },
  },
];
