import { MouseEvent } from 'react';

export enum ModalCloseReason {
  escapeKeyDown = 'escapeKeyDown',
  backdropClick = 'backdropClick',
  completeAction = 'completeAction',
}

export type ModalCloseHandler = (
  event?: MouseEvent | null,
  reason?: ModalCloseReason
) => void;
