import { useMemo } from 'react';
import filter from 'lodash/filter';
import { CompanyUserItemField, CompanyUserRole } from '../types';
import { useCompanyUsers } from '../redux';

export const useIsLastOwner = (role?: CompanyUserRole): boolean => {
  const [users] = useCompanyUsers();

  return useMemo(
    () =>
      role === CompanyUserRole.Owner &&
      filter(users, [CompanyUserItemField.Role, CompanyUserRole.Owner]).length <
        2,
    [role, users]
  );
};
