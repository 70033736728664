import React, { ComponentType } from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import { RowActionHandler } from '../types';

type Props = Omit<IconButtonProps, 'onClick'> & {
  icon: ComponentType;
  onClick?: RowActionHandler;
};

const style = {
  '&:first-of-type': {
    ml: -1,
  },
};

function DataTableActionsButton({ icon, onClick, sx, ...rest }: Props) {
  const handleClick = (event) => {
    event.stopPropagation();
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <IconButton
      size="small"
      onClick={handleClick}
      sx={{ ...style, ...sx }}
      {...rest}
    >
      <SvgIcon component={icon} fontSize="small" />
    </IconButton>
  );
}

export default DataTableActionsButton;
