import React, { SyntheticEvent, useState } from 'react';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import SearchBarInput from './components/SearchBarInput';
import SearchBarOptionsList from './components/SearchBarOptionsList';
import SearchBarLoader from './components/SearchBarLoader';
import SearchBarCloseBtn from './components/SearchBarCloseBtn';

type Props = Omit<
  AutocompleteProps<string, false, false, false>,
  'renderInput'
> & {
  onFocusChange?: (focused: boolean) => void;
  color?: string;
};

function SearchBar({ onFocusChange, color = 'white', ...rest }: Props) {
  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(false);

  const onFocusBlur = () => {
    setActive(!active);
    if (onFocusChange) {
      onFocusChange(!active);
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  const onInputChange = (event: SyntheticEvent<Element>) => {
    setOpen(!!(event as SyntheticEvent<HTMLInputElement>).currentTarget.value);
  };

  return (
    <>
      <Autocomplete
        data-testid="search-bar"
        disablePortal={true}
        open={open}
        onClose={onClose}
        onFocus={onFocusBlur}
        onBlur={onFocusBlur}
        onInputChange={onInputChange}
        clearIcon={null}
        popupIcon={null}
        loadingText={<SearchBarLoader />}
        PaperComponent={SearchBarOptionsList}
        renderInput={(params: any) => (
          <SearchBarInput
            active={active}
            open={open}
            color={color}
            {...params}
          />
        )}
        {...rest}
      />
      {active && <SearchBarCloseBtn sx={{ color }} onClick={onClose} />}
    </>
  );
}

export default SearchBar;
