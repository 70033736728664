import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import AutoCompleteBox from 'src/components/AutoCompleteBox';
import { Option } from 'src/components/SelectOption';
import { SolutionCompanyField } from '../types';

export const getAssignTemplateFormFields = (
  options: Option[],
  loading?: boolean
) => [
  {
    name: SolutionCompanyField.Company,
    label: 'Company',
    Component: AutoCompleteBox,
    validation: Yup.string().required('Company selection is required'),
    required: true,
    options,
    loading,
  },
  {
    name: SolutionCompanyField.ClientId,
    label: 'Company App/Client ID',
    placeholder: 'Enter Company App/Client ID',
    Component: TextField,
    validation: Yup.string().required('Company App/Client ID is required'),
    required: true,
  },
];
