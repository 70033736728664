import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SystemProps } from '@mui/system/Box';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useParentLocation } from 'src/router';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { generateApiPath } from 'src/utils';
import { PageCardLoader } from 'src/components/PageCardLayout';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import { API_DEVICE_DEP_CREATE } from '../../constants';
import { getCreateDeviceDepParams } from '../utils';

type Props = {
  releaseName?: string;
};

const style: SystemProps = {
  display: 'flex',
  justifyContent: 'flex-end',
  gap: 2,
  mt: 3,
};

function SoluitonDeviceDeploymentActions({ releaseName }: Props) {
  const navigate = useNavigate();
  const parentLocation = useParentLocation();
  const { deviceId, solutionId } = useParams();
  const { showAlert } = useAlert();

  const onCancel = () => navigate(parentLocation);

  const [onSubmit, loading] = useStatefulApi(
    () =>
      pepApiRequest({
        method: RequestMethod.Post,
        url: generateApiPath(API_DEVICE_DEP_CREATE, {
          solutionId,
        }),
        params: getCreateDeviceDepParams(deviceId, releaseName),
      }),
    () => {
      showAlert({
        type: AlertType.Success,
        title: 'Deployment created',
        text: 'The device deployment has been created successfully.',
      });
      onCancel();
    }
  );

  return (
    <Box {...style}>
      <Button onClick={onCancel} variant="outlined">
        Cancel
      </Button>
      <Button variant="contained" disabled={!releaseName} onClick={onSubmit}>
        Create deployment
      </Button>
      {loading && <PageCardLoader />}
    </Box>
  );
}

export default SoluitonDeviceDeploymentActions;
