import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Add from '@mui/icons-material/Add';
import { useModal } from 'src/hooks';
import DataTable, {
  DataTableWrapper,
  DataTableWrapperActionButton,
  getActionsColConfig,
} from 'src/components/DataTable';
import { isModalComplete } from 'src/components/ModalDialog';
import { useStoreUnitActions } from 'src/redux/helpers';
import { useUserRoles } from 'src/auth';
import companiesStore, { useCompanyUsers } from '../redux';
import { CompanyUserItemField } from '../types';
import CompanyUsersAction from './components/CompanyUsersAction';
import CompanyUsersRole from './components/CompanyUsersRole';
import CompanyUsersAssignModal from './components/CompanyUsersAssignModal';

const columns = [
  {
    id: CompanyUserItemField.Email,
    title: 'Email address',
  },
  {
    id: CompanyUserItemField.Role,
    title: 'Role',
    Component: CompanyUsersRole,
  },
  getActionsColConfig(CompanyUsersAction),
];

export default function CompanyUsers() {
  const { companyId } = useParams();
  const actions = useStoreUnitActions(companiesStore);
  const { isPlatformReader } = useUserRoles();
  const [data, loading] = useCompanyUsers();
  const [AssignNewUserDialog, showAssignNewUserDialog] = useModal(
    CompanyUsersAssignModal,
    {
      onClose: (event, reason) =>
        isModalComplete(reason) && actions.users(companyId),
    }
  );

  useEffect(() => {
    actions.users(companyId);
  }, [companyId]);

  return (
    <DataTableWrapper
      title="Users"
      actionsPanel={
        <DataTableWrapperActionButton
          onClick={showAssignNewUserDialog}
          startIcon={<Add />}
          disabled={isPlatformReader}
        >
          Assign user
        </DataTableWrapperActionButton>
      }
    >
      <DataTable data={data} columns={columns} loading={loading} />
      {AssignNewUserDialog}
    </DataTableWrapper>
  );
}
