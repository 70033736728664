import React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

function SideBarDivider() {
  return (
    <Box width="100%" px="20px">
      <Divider sx={{ borderBottomColor: '#26529B' }} />
    </Box>
  );
}

export default SideBarDivider;
