import { ChangeEvent } from 'react';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import Switch from 'src/components/Switch';
import { nanToUndefined } from 'src/utils';
import AutoCompleteBox from 'src/components/AutoCompleteBox';
import { Option } from 'src/components/SelectOption';
import {
  SolutionDeviceAssignTemplateField,
  SolutionDeviceObservPropsField,
} from '../types';

export const getAssignTemplateFormFields = (
  toggleCompatibleOnly: (event: ChangeEvent<HTMLInputElement>) => void,
  templateOptions: Option[],
  modelId?: string
) => [
  {
    name: SolutionDeviceAssignTemplateField.CompatibleOnly,
    label: 'Show only compatible templates',
    labelPlacement: 'start',
    Component: Switch,
    initialValue: true,
    onChange: toggleCompatibleOnly,
    type: 'checkbox',
  },
  {
    name: SolutionDeviceAssignTemplateField.ModelId,
    label: 'Template',
    Component: AutoCompleteBox,
    validation: Yup.string().required('Template selection is required'),
    required: true,
    initialValue: modelId,
    sx: { height: ({ spacing }) => spacing(32) },
    options: templateOptions,
  },
];
export const getAssignCompanyFormFields = (
  companyOptions: any,
  modelId?: any
) => [
  {
    name: SolutionDeviceAssignTemplateField.ModelId,
    label: 'Company',
    Component: AutoCompleteBox,
    options: companyOptions,
    required: true,
    validation: Yup.string().required('Company selection is required'),
    sx: { height: ({ spacing }) => spacing(32) },
  },
];
export const getDeviceObservPropsFormFields = () => [
  {
    name: SolutionDeviceObservPropsField.FilePath,
    label: 'File Path',
    placeholder: 'Enter the pathname',
    Component: TextField,
    validation: Yup.string().required('File Path field is required'),
    required: true,
  },
  {
    name: SolutionDeviceObservPropsField.FileName,
    label: 'File Name',
    placeholder: 'Enter the name',
    Component: TextField,
    validation: Yup.string().required('Max file size value is required'),
    required: true,
  },
  {
    name: SolutionDeviceObservPropsField.RotateFile,
    label: 'File Rotation',
    placeholder: 'Enter the value ranges from 256 to 5120',
    Component: TextField,
    validation: Yup.number()
      .transform(nanToUndefined)
      .min(256, 'File Rotation must be greater than or equal to 256')
      .max(5120)
      .required('File Rotation must be less than or equal to 5120'),
    type: 'number',
    required: true,
  },
  {
    name: SolutionDeviceObservPropsField.NumOfFile,
    label: 'Number of File',
    placeholder: 'Enter the value ranges from 3 to 7',
    Component: TextField,
    validation: Yup.number()
      .transform(nanToUndefined)
      .min(3, 'Number of File must be greater than or equal to 3')
      .max(7, 'Number of File must be less than or equal to 7')
      .required('Number of File value is required'),
    type: 'number',
    required: true,
  },
  {
    name: SolutionDeviceObservPropsField.Frequency,
    label: 'Frequency',
    placeholder: 'Enter the value ranges from 60 to 300',
    Component: TextField,
    validation: Yup.number()
      .transform(nanToUndefined)
      .min(60, 'Frequency must be greater than or equal to 60')
      .max(300, 'Frequency must be less than or equal to 300')
      .required('Frequency value is required'),
    type: 'number',
    required: true,
  },
  {
    name: SolutionDeviceObservPropsField.Enabled,
    label: 'Enable',
    Component: Switch,
    type: 'checkbox',
  },
];
