import React from 'react';
import Badge from 'src/components/Badge';
import { SolutionMenderStatus as Status } from '../../types';

type StatusMapItem = {
  color?: 'success' | 'info' | 'error' | 'default';
  label: string;
};

type Props = {
  value?: Status;
};

function SolutionMenderStatus({ value = Status.Pending }: Props) {
  const statusMap: Record<Status, StatusMapItem> = {
    [Status.Pending]: { color: 'info', label: 'Pending' },
    [Status.Accepted]: { color: 'success', label: 'Accepted' },
    [Status.Rejected]: { color: 'error', label: 'Rejected' },
  };
  return statusMap[value] ? <Badge {...statusMap[value]} /> : <span>–</span>;
}

export default SolutionMenderStatus;
