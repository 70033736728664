import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { Box } from '@mui/material';

type Props = {
  title: string;
  url: string;
};

export const SolutionsListTitle = ({ title, url }: Props) => {
  const navigate = useNavigate();
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box>{title}</Box>
      <Box>
        <IconButton
          size="small"
          title="Help center"
          onClick={() => navigate(url)}
        >
          <HelpOutlineOutlinedIcon fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
};
