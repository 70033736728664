import { useUserRoles } from 'src/auth';

export const useCompanyUserRoles = (companyId?: string) => {
  const params = companyId ? { companyId } : undefined;
  const { isPlatformOwner, isTenantOwner, isTenantUser } = useUserRoles(params);

  return {
    isCompanyEditor: isPlatformOwner || isTenantOwner || isTenantUser,
    isCompanyUsersEditor: isPlatformOwner || isTenantOwner,
  };
};
