import { DataTableActions } from 'src/components/DataTable';
import { useParams } from 'react-router-dom';
import { useStoreUnitActions } from 'src/redux/helpers';
import { isModalComplete } from 'src/components/ModalDialog';
import { useModal } from 'src/hooks';
import { useUserRoles } from 'src/auth';
import solutionsStore from '../redux';
import SolutionSpecificRuleDeleteModal from './SolutionSpecificRuleDeleteModal';
import SolutionSpecificRuleViewModal from './SolutionSpecificRuleViewModal';
import RuleForm from './RuleForm';

type Props = {
  item?: any;
};

function SolutionRuleConfigurationActions({ item }: Props) {
  const actions = useStoreUnitActions(solutionsStore);

  const { solutionId } = useParams();
  const editRuleValue = true;
  const onClose = (event, reason) =>
    isModalComplete(reason) && actions.rulesManagement(solutionId);
  const { isPlatformReader } = useUserRoles();
  const [DeleteDialog, handleDelete] = useModal(
    SolutionSpecificRuleDeleteModal,
    {
      item: [item],
      onClose,
    }
  );

  const [ViewDialog, handleView] = useModal(SolutionSpecificRuleViewModal, {
    item,
  });

  const [EditDialog, handleEdit] = useModal(RuleForm, {
    item,
    editRuleValue,
  });

  return (
    <DataTableActions
      showView={true}
      onView={handleView}
      onDelete={handleDelete}
      canDelete={!isPlatformReader}
      canEdit={!isPlatformReader}
      onEdit={handleEdit}
    >
      {ViewDialog}
      {EditDialog}
      {DeleteDialog}
    </DataTableActions>
  );
}

export default SolutionRuleConfigurationActions;
