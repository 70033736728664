import { CompanyUserRole } from '../types';

export const COMPANY_USER_ROLE_OPTIONS = [
  {
    value: CompanyUserRole.Owner,
    label: 'Company owner',
  },
  {
    value: CompanyUserRole.User,
    label: 'Company user',
  },
];
