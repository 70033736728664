import React, { ReactNode } from 'react';
import Box from '@mui/material/Box';
import CircularLoader from 'src/components/CircularLoader';
import { SxProps } from 'src/theme/types';

const style: SxProps = {
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: ({ palette, alpha }) => alpha(palette.neutral.main, 0.07),
};

type Props = {
  children?: ReactNode;
};

function FullScreenLoader({ children }: Props) {
  return (
    <Box data-testid="fullscreen-loader" sx={style}>
      <CircularLoader />
      {children}
    </Box>
  );
}

export default FullScreenLoader;
