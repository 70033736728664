export const maskPhoneNumber = (phoneNumber: string, validate?: boolean) => {
  const isValidPhoneNumber =
    /^\+?[1-9]\d{1,14}$/.test(phoneNumber) || /^\d{7,15}$/.test(phoneNumber);

  if (!isValidPhoneNumber && validate) {
    return 'Invalid phone number';
  }
  if (!isValidPhoneNumber && !validate) {
    return phoneNumber;
  }

  const maskedNumber =
    phoneNumber.length > 4 ? `${phoneNumber.slice(0, -4)}****` : '**';

  return maskedNumber;
};
