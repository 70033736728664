import { generatePath, useNavigate } from 'react-router-dom';
import { AnalyticsOutlined } from '@mui/icons-material';
import {
  DataTableActions,
  DataTableActionsButton,
} from 'src/components/DataTable';
import { useUserRoles } from 'src/auth';
import { useSolutionUserRoles } from '../../hooks';
import { SolutionItem, SolutionItemStatus, SolutionType } from '../../types';
import { SOLUTION_DETAILS, SOLUTION_EDIT } from '../../constants';

type Props = {
  item?: SolutionItem;
};

function SolutionsListAction({ item }: Props) {
  const navigate = useNavigate();
  const { id: solutionId, status, kibanaSpaceUrl } = item as SolutionItem;
  const { isSolutionEditor } = useSolutionUserRoles(solutionId);
  const { isPlatformReader, isPlatformEngineer } = useUserRoles();
  const isObservability = item?.solutionType === SolutionType.Industrial;
  const isSuccceeded =
    status === SolutionItemStatus.Succeeded ||
    status === SolutionItemStatus.Suspended;
  const isValidUser = isSolutionEditor || isPlatformEngineer;

  const handleView = () => {
    switch (item?.solutionType) {
      case SolutionType.Industrial:
        return navigate(
          generatePath(SOLUTION_DETAILS, {
            solutionId,
            solutionType: SolutionType.Industrial,
          })
        );
      case SolutionType.Consumer:
        return navigate(
          generatePath(SOLUTION_DETAILS, {
            solutionId,
            solutionType: SolutionType.Consumer,
          })
        );
      case SolutionType.ioTH:
        return navigate(
          generatePath(SOLUTION_DETAILS, {
            solutionId,
            solutionType: SolutionType.ioTH,
          })
        );

      default:
        break;
    }
    return null;
  };
  const handleEdit = () => {
    switch (item?.solutionType) {
      case SolutionType.Industrial:
        return navigate(
          generatePath(`${SOLUTION_DETAILS}/${SOLUTION_EDIT}`, {
            solutionId,
            solutionType: SolutionType.Industrial,
          })
        );
      case SolutionType.Consumer:
        return navigate(
          generatePath(`${SOLUTION_DETAILS}/${SOLUTION_EDIT}`, {
            solutionId,
            solutionType: SolutionType.Consumer,
          })
        );
      case SolutionType.ioTH:
        return navigate(
          generatePath(`${SOLUTION_DETAILS}/${SOLUTION_EDIT}`, {
            solutionId,
            solutionType: SolutionType.ioTH,
          })
        );

      default:
        break;
    }
    return null;
  };
  const handleViewObservability = () => window.open(kibanaSpaceUrl);

  return (
    <DataTableActions
      canView={isSuccceeded}
      canEdit={isSuccceeded && isValidUser}
      canDelete={false}
      onView={handleView}
      onEdit={handleEdit}
      showView={true}
      showEdit={isValidUser || isPlatformReader}
    >
      {isObservability && (
        <DataTableActionsButton
          data-testid="solution-observability-visit-icon"
          icon={AnalyticsOutlined}
          title="Observability Dashboard"
          onClick={handleViewObservability}
          disabled={kibanaSpaceUrl === '' || kibanaSpaceUrl === undefined}
        />
      )}
    </DataTableActions>
  );
}

export default SolutionsListAction;
