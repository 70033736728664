import compact from 'lodash/compact';
import { TextField } from '@mui/material';
import CheckboxGroup from 'src/components/CheckboxGroup';
import MaskedInput from 'src/components/MaskedInput';
import RadioButtonGroup from 'src/components/RadioButtonGroup';
import ReadonlyField from 'src/components/ReadonlyField';
import Select from 'src/components/Select/Select';
import Switch from 'src/components/Switch';
import * as Yup from 'yup';
import { SOLUTION_SETTINGS } from '../constants';
import {
  SolutionAdlsSubscriptionItemField,
  SolutionCosmosSubscriptionItemField,
  SolutionFileSubscriptionItemField,
  SolutionSubscribeTo,
  SolutionSubscriptionFilterField,
  SolutionSubscriptionItemField,
  SolutionSubscriptionType,
  SolutionType,
} from '../types';
import {
  STORAGE_ACCOUNT_OPTIONS,
  SUBSCRIBE_FILTER_OPERATOR_OPTIONS,
  SUBSCRIBE_FILTER_CONDITION_OPTIONS,
  SUBSCRIPTION_SAS_PATTERN,
  ADLS_FORMAT_OPTIONS,
} from './constants';
import SolutionSubscriptionEditFormRow from './components/SolutionSubscriptionEditFormRow';

export const getSubscriptionEditFormFields = (
  filters: any[],
  adlsFilters: any[],
  cosmosFilters: any[],
  useAuth?: boolean,
  subscriptionType?: string,
  isEditMsgSubscription?: boolean,
  storageEnabled?: boolean,
  solutionType?: string,
  subscribeToType?: string[],
  handlePaste?: any,
  handlePasteConnectionString?: any,
  handlePasteAuthorizationToken?: any,
  SUBSCIPTION_TYPE_OPTIONS?: any,
  INDUSTRIAL_SUBSCRIPTION_TYPE_OPTIONS?: any,
  isFileSubscriptionService?: boolean,
  isMessageSubscriptionService?: boolean,
  SUBSCRIBE_TO_OPTIONS?: any,
  SUBSCRIBE_TO_OPT_INDUSTRIAL?: any,
  isAlarmEventProcessing?: any,
  isTelemetryStreamProcessing?: any,
  handleConnectionStringPaste?: () => void
) =>
  compact([
    !isEditMsgSubscription && {
      name: SolutionSubscriptionItemField.SubscriptionType,
      label: 'Subscription type',
      Component: RadioButtonGroup,
      value: subscriptionType,
      defaultValue: subscriptionType,
      required: true,
      options:
        solutionType === SolutionType.Industrial
          ? INDUSTRIAL_SUBSCRIPTION_TYPE_OPTIONS
          : SUBSCIPTION_TYPE_OPTIONS,
    },
    subscriptionType === SolutionSubscriptionType.Cosmos && {
      name: SolutionCosmosSubscriptionItemField.CosmosSubscriptionName,
      label: 'Subscription Name',
      Component: TextField,
      placeholder: 'Enter subscription name',
      required: true,
      validation: Yup.string().required('Subscription name is required'),
    },
    subscriptionType === SolutionSubscriptionType.Cosmos && {
      name: SolutionCosmosSubscriptionItemField.ConnectionString,
      label: 'Connection String',
      Component: MaskedInput,
      placeholder: 'Enter connection string',
      onClickPasteIcon: handleConnectionStringPaste,
      icon: true,
      required: true,
      validation: Yup.string().required('Connection String is required'),
    },
    subscriptionType === SolutionSubscriptionType.Cosmos && {
      name: SolutionCosmosSubscriptionItemField.DatabaseName,
      label: 'Database Name',
      Component: TextField,
      placeholder: 'Enter name',
      required: true,
      validation: Yup.string().required('Database Name is required'),
    },
    subscriptionType === SolutionSubscriptionType.Cosmos && {
      name: SolutionCosmosSubscriptionItemField.Container,
      label: 'Container',
      Component: TextField,
      placeholder: 'Enter container',
      required: true,
      validation: Yup.string().required('Container is required'),
    },
    subscriptionType === SolutionSubscriptionType.Cosmos && {
      name: SolutionCosmosSubscriptionItemField.Partitionkey,
      label: 'Partition Key',
      Component: TextField,
      placeholder: 'Enter the partition key',
      required: true,
      validation: Yup.string().required('Partition key is required'),
    },
    subscriptionType === SolutionSubscriptionType.ADLS && {
      name: SolutionAdlsSubscriptionItemField.AdlsSubscriptionName,
      label: 'Subscription Name',
      Component: TextField,
      placeholder: 'Enter subscription name',
      required: true,
      validation: Yup.string().required('Subscription name is required'),
    },
    subscriptionType === SolutionSubscriptionType.ADLS && {
      name: SolutionAdlsSubscriptionItemField.Format,
      label: 'Format',
      Component: Select,
      required: true,
      validation: Yup.string().required(
        'Solution Subscription Format is required'
      ),
      options: ADLS_FORMAT_OPTIONS,
    },
    subscriptionType === SolutionSubscriptionType.ADLS && {
      name: SolutionAdlsSubscriptionItemField.ContainerSaasURL,
      label: 'Container SAS URL',
      Component: MaskedInput,
      onClickPasteIcon: handlePaste,
      icon: true,
      placeholder: 'Enter Container SAS URL',
      required: true,
      validation: Yup.string().required('Container SAS URL is required'),
    },
    subscriptionType === SolutionSubscriptionType.ADLS && {
      name: SolutionAdlsSubscriptionItemField.FolderStructure,
      label: 'Destination Folder Format',
      Component: TextField,
      placeholder: 'Enter the format in 2023/06/12/24/34',
    },
    subscriptionType === SolutionSubscriptionType.Files && {
      name: SolutionFileSubscriptionItemField.FileSubscriptionName,
      label: 'Subscription Name',
      Component: TextField,
      placeholder: 'Enter subscription name',
      required: true,
      validation: Yup.string().required('Subscription name is required'),
    },
    subscriptionType === SolutionSubscriptionType.Files && {
      name: SolutionFileSubscriptionItemField.SourceStorageAccount,
      value: storageEnabled,
      label: 'Source Storage Account',
      Component: ReadonlyField,
      required: false,
      helperText: `Note: You need to enable source storage account to facilitate the file transfer to destination storage.
      <a href=${SOLUTION_SETTINGS}>Click here</a> to enable it.`,
    },
    subscriptionType === SolutionSubscriptionType.Files && {
      name: SolutionFileSubscriptionItemField.DestinationType,
      label: 'Destination Storage Type',
      Component: Select,
      required: true,
      validation: Yup.string().required('Destination Storage Type is required'),
      options: STORAGE_ACCOUNT_OPTIONS,
      disabled: !storageEnabled,
    },
    subscriptionType === SolutionSubscriptionType.Files && {
      name: SolutionFileSubscriptionItemField.FolderStructure,
      label: 'Destination Folder Format',
      Component: TextField,
      placeholder: 'Enter Destination Folder Format',
    },
    subscriptionType === SolutionSubscriptionType.Files && {
      name: SolutionFileSubscriptionItemField.DestinationConnectionString,
      label: 'Connection String',
      Component: MaskedInput,
      placeholder: 'Enter Connection String',
      required: true,
      onClickPasteIcon: handlePasteConnectionString,
      icon: true,
      validation: Yup.string().required('Connection String is required'),
      disabled: !storageEnabled,
    },
    subscriptionType === SolutionSubscriptionType.Messages && {
      name: SolutionSubscriptionItemField.Name,
      label: 'Subscription Name',
      Component: TextField,
      placeholder: 'Enter subscription name',
      required: true,
      validation: Yup.string().required('Subscription name is required'),
    },
    subscriptionType === SolutionSubscriptionType.Messages && {
      name: SolutionSubscriptionItemField.Type,
      label: 'Subscribe to:',
      Component: CheckboxGroup,
      type: 'checkbox-group',
      validation: Yup.array().min(1, 'At least one selection is required'),
      required: true,
      labelStyle: { mr: 3 },
      options:
        solutionType === SolutionType.Industrial
          ? SUBSCRIBE_TO_OPT_INDUSTRIAL
          : SUBSCRIBE_TO_OPTIONS,
    },
    subscriptionType !== SolutionSubscriptionType.Files &&
      filters?.length > 1 &&
      (((solutionType === SolutionType.Consumer ||
        solutionType === SolutionType.ioTH) &&
        subscribeToType?.includes(SolutionSubscribeTo.Event)) ||
        (solutionType === SolutionType.Industrial &&
          subscriptionType === SolutionSubscriptionType.Messages &&
          subscribeToType?.includes(SolutionSubscribeTo.Telemetry))) && {
        name: SolutionSubscriptionItemField.FilterCondition,
        label: 'Apply if',
        placeholder: 'Select the value',
        Component: Select,
        options: SUBSCRIBE_FILTER_CONDITION_OPTIONS,
        initialValue: 'And',
        validation: Yup.string().required('Operator is required'),
        required: true,
        sx: { width: ({ spacing }) => spacing(30) },
      },
    adlsFilters?.length > 1 &&
      subscriptionType === SolutionSubscriptionType.ADLS && {
        name: SolutionSubscriptionItemField.AdlsFilterCondition,
        label: 'Apply if',
        placeholder: 'Select the value',
        Component: Select,
        options: SUBSCRIBE_FILTER_CONDITION_OPTIONS,
        initialValue: 'And',
        validation: Yup.string().required('Operator is required'),
        required: true,
        sx: { width: ({ spacing }) => spacing(30) },
      },
    cosmosFilters?.length > 1 &&
      subscriptionType === SolutionSubscriptionType.Cosmos && {
        name: SolutionSubscriptionItemField.CosmosFilterCondition,
        label: 'Apply if',
        placeholder: 'Select the value',
        Component: Select,
        options: SUBSCRIBE_FILTER_CONDITION_OPTIONS,
        initialValue: 'And',
        validation: Yup.string().required('Operator is required'),
        required: true,
        sx: { width: ({ spacing }) => spacing(30) },
      },
    subscriptionType === SolutionSubscriptionType.Messages &&
      (solutionType === SolutionType.Consumer ||
        solutionType === SolutionType.ioTH) &&
      subscribeToType?.includes(SolutionSubscribeTo.Event) && {
        name: SolutionSubscriptionItemField.Filters,
        appendLabel: 'Filter',
        childFields: getSubscriptionFilterFields(),
        RowComponent: SolutionSubscriptionEditFormRow,
      },
    solutionType === SolutionType.Industrial &&
      subscriptionType === SolutionSubscriptionType.Messages &&
      subscribeToType?.includes(SolutionSubscribeTo.Telemetry) && {
        name: SolutionSubscriptionItemField.Filters,
        appendLabel: 'Telemetry Filter',
        childFields: getSubscriptionFilterFields(),
        RowComponent: SolutionSubscriptionEditFormRow,
        maxRows: 30,
      },
    solutionType === SolutionType.Industrial &&
      subscriptionType === SolutionSubscriptionType.ADLS && {
        name: SolutionSubscriptionItemField.AdlsFilters,
        appendLabel: 'Filter',
        childFields: getSubscriptionFilterFields(),
        RowComponent: SolutionSubscriptionEditFormRow,
        maxRows: 30,
      },
    solutionType === SolutionType.Industrial &&
      subscriptionType === SolutionSubscriptionType.Cosmos && {
        name: SolutionSubscriptionItemField.CosmosFilters,
        appendLabel: 'Filter',
        childFields: getSubscriptionFilterFields(),
        RowComponent: SolutionSubscriptionEditFormRow,
        maxRows: 30,
      },
    subscriptionType === SolutionSubscriptionType.Messages && {
      name: SolutionSubscriptionItemField.Target,
      label: 'Target URL',
      Component: TextField,
      placeholder: 'https://example.com',
      required: true,
      validation: Yup.string()
        .required('Target URL is required')
        .url('Target URL should have a correct format'),
    },
    subscriptionType === SolutionSubscriptionType.Messages && {
      name: SolutionSubscriptionItemField.Enabled,
      label: 'Enable',
      Component: Switch,
      type: 'checkbox',
    },
    subscriptionType === SolutionSubscriptionType.Messages && {
      name: SolutionSubscriptionItemField.UseAuth,
      label: 'Service Bus / Event Hub Target',
      Component: Switch,
      type: 'checkbox',
    },
    subscriptionType === SolutionSubscriptionType.Messages &&
      useAuth && {
        name: SolutionSubscriptionItemField.Authorization,
        label: 'SAS Token',
        Component: MaskedInput,
        placeholder: 'Enter SAS Token',
        required: true,
        onClickPasteIcon: handlePasteAuthorizationToken,
        validation: Yup.string()
          .required('SAS Token is required')
          .matches(
            SUBSCRIPTION_SAS_PATTERN,
            'SAS Token should have "SharedAccessSignature sr={sr}&sig={sig}&se={ttl}&skn={saName}" format'
          ),
      },
  ]);

export const getSubscriptionFilterFields = () => [
  {
    name: SolutionSubscriptionFilterField.Property,
    label: 'Property Name',
    placeholder: 'Enter the name',
    Component: TextField,
    validation: Yup.string().required('Property name is required'),
    required: true,
    sx: { width: ({ spacing }) => spacing(20) },
  },
  {
    name: SolutionSubscriptionFilterField.OperatorValue,
    label: 'Operator',
    placeholder: 'Select the value',
    Component: Select,
    options: SUBSCRIBE_FILTER_OPERATOR_OPTIONS,
    validation: Yup.string().required('Operator is required'),
    required: true,
    sx: { width: ({ spacing }) => spacing(21) },
  },
  {
    name: SolutionSubscriptionFilterField.FilterValue,
    label: 'Value',
    placeholder: 'Enter the value',
    Component: TextField,
    validation: Yup.string().required('Value is required'),
    required: true,
    sx: { width: ({ spacing }) => spacing(35) },
  },
];
