import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ReactComponent as CommandIcon } from 'src/assets/icons/command.svg';
import { ReactComponent as PropertyIcon } from 'src/assets/icons/property.svg';
import { useStoreUnitActions } from 'src/redux/helpers';
import { RequestMethod } from 'src/services';
import {
  DataTableActionsButton,
  DataTableActionsWrapper,
  useTableQueryParams,
} from 'src/components/DataTable';
import { isModalComplete } from 'src/components/ModalDialog';
import { useModal } from 'src/hooks';
import { useUserRoles } from 'src/auth';
import SolutionCommandAssignTemplateModal from './SolutionCommandAssignTemplateModal';
import solutionsStore, {
  useSolutionComponentsDetails,
  useSolutionDeviceComponentProperties,
  useSolutionDeviceGroupWritableProperties,
} from '../redux';
import SolutionPropertiesAssignTemplateModal from './SolutionPropertiesAssignTemplateModal';

type CreateApiHandlersParams = {
  url: string;
  method: RequestMethod;
  params?: Record<string, any>;
  title: string;
  text: string;
};

type Props = {
  item?: any;
};
type PropertyDataList = PropertyData[];

type PropertyData = {
  headerName: string;
  data: data[];
};
type data = {
  name: string;
  schema: string;
};

function SolutionDevicesComponentsAction({ item }: Props) {
  const { devicesPersist } = useStoreUnitActions(solutionsStore);
  const [data, loading] = useSolutionComponentsDetails();
  const { isPlatformReader, isPlatformEngineer } = useUserRoles();
  const [propertiesData, propertiesLoading] =
    useSolutionDeviceGroupWritableProperties();
  const propertiesValueData = useSolutionDeviceComponentProperties();
  const { paging } = useTableQueryParams();
  const { pageIndex, pageSize } = paging;
  const { solutionId } = useParams();
  const actions = useStoreUnitActions(solutionsStore);
  const componentName = item.component;
  const itemModuleName = item.moduleName;
  const onClose = (event, reason) =>
    isModalComplete(reason) &&
    actions.jobs({ solutionId, pageIndex, pageSize });

  const [desiredPropertyData, setDesiredPropertyData] = useState({});
  const [reportedPropertyData, setReportedPropertyData] = useState({});

  const retData = data.filter((comp) => comp.component === item.component);

  const propertyData = propertiesData.filter(
    (comp) => comp.component === item.component
  );

  useEffect(() => {
    if (propertiesValueData) {
      const data1 = propertiesValueData?.properties?.desired[item.component];
      setDesiredPropertyData(data1);
      const data2 = propertiesValueData?.properties?.reported[item.component];
      setReportedPropertyData(data2);
    } else {
      setDesiredPropertyData({});
      setReportedPropertyData({});
    }
  }, [propertiesValueData]);

  const list: any = [];
  for (let index = 0; index < propertyData?.length; index += 1) {
    const data = propertyData[index];
    if (list.includes(data.displayName)) break;
    list.push(data.displayName.split('/')[0]);
    if (!data?.displayName?.includes('/')) list.push(data.displayName);
  }
  const newList = list.filter((item, index) => list.indexOf(item) === index);

  const newPropertyDataList: PropertyDataList = [];
  newList.forEach((e) => {
    const newPropertyData: PropertyData = { data: [], headerName: '' };
    newPropertyData.data = [];
    newPropertyData.headerName = e;
    const dataValue: any = propertiesData.filter((comp) =>
      comp.displayName.match(e)
    );
    newPropertyData.data.push(dataValue);
    newPropertyDataList.push(newPropertyData);
  });
  const openCommandTemplate = () => {
    devicesPersist.add(retData);
    showCommandTemplateDialog();
  };
  const openPropertiesTemplate = () => {
    showPropertiesTemplateDialog();
  };
  const [CommandTemplateDialog, showCommandTemplateDialog] = useModal(
    SolutionCommandAssignTemplateModal,
    { retData }
  );

  const [PropertiesTemplateDialog, showPropertiesTemplateDialog] = useModal(
    SolutionPropertiesAssignTemplateModal,
    {
      componentName,
      itemModuleName,
      newPropertyDataList,
      propertyData,
      desiredPropertyData,
      reportedPropertyData,
      propertiesData,
    }
  );

  return (
    <DataTableActionsWrapper>
      <DataTableActionsButton
        data-testid="solution-job-run-action"
        icon={CommandIcon}
        onClick={openCommandTemplate}
        title="Command"
        disabled={isPlatformReader || isPlatformEngineer}
      />
      <DataTableActionsButton
        data-testid="solution-job-run-action"
        icon={PropertyIcon}
        onClick={openPropertiesTemplate}
        title="Property"
        sx={{ marginBottom: '-1rem' }}
        disabled={isPlatformReader || isPlatformEngineer}
      />
      {CommandTemplateDialog}
      {PropertiesTemplateDialog}
    </DataTableActionsWrapper>
  );
}

export default SolutionDevicesComponentsAction;
