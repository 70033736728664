import React, { createContext, ReactNode } from 'react';

type ContextProps = {
  onChange: (value: number) => void;
  index: number;
};

export const Context = createContext<ContextProps>({
  onChange: () => null,
  index: 0,
});

type Props = {
  onChange: (value: number) => void;
  index: number;
  children: ReactNode;
};

function TabsContext({ children, index, onChange }: Props) {
  return (
    <Context.Provider value={{ index, onChange }}>{children}</Context.Provider>
  );
}

export default TabsContext;
