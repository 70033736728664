import { Dispatch } from 'redux';
import { createAction } from '@reduxjs/toolkit';
import forEach from 'lodash/forEach';

export function createExtraActions(
  actions: Record<string, string>,
  nameFn: (action: string) => string
) {
  const result = { actions: {}, dispatchers: {} };

  forEach(actions, (value: string, key: string) => {
    const action = createAction(nameFn(value), (payload, meta) => ({
      payload,
      meta,
    }));

    result.actions[key] = action;
    result.dispatchers[key] = (dispatch: Dispatch) => (params, meta) =>
      dispatch(action(params, meta));
  });

  return result;
}
