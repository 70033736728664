import ModalDialog from 'src/components/ModalDialog';
import { useForm } from 'src/components/Form';
import { RequestMethod, pepApiRequest, useStatefulApi } from 'src/services';
import { generatePath, useParams } from 'react-router-dom';
import { useStoreUnitActions } from 'src/redux/helpers';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import { SignatureWhitelistFormFields } from '../formFields';
import { SolutionSignatureWhitelistItem } from '../../types';

import { API_OS_WHITELIST } from '../../constants';
import solutionsStore from '../../redux';

type Props = {
  title: string;
  item: SolutionSignatureWhitelistItem;
  open: boolean;
  onClose: () => void;
};

function SignatureWhitelistEditModal({ item, title, open, onClose }: Props) {
  const { solutionId } = useParams();
  const { showAlert } = useAlert();
  const actions = useStoreUnitActions(solutionsStore);
  const [onSubmit, loading] = useStatefulApi(
    (params) =>
      pepApiRequest({
        method: RequestMethod.Put,
        url: generatePath(API_OS_WHITELIST, { solutionId }),
        params: { operatingSystems: [params] },
      }),
    () => {
      showAlert({
        type: AlertType.Success,
        title: 'OS signature Whitelist updated',
        text: 'OS signature whitelist has been successfully updated.',
      });
      actions.signatureWhitelist(solutionId);
      onClose();
    }
  );

  const [form, handleSubmit] = useForm({
    values: item,
    fields: SignatureWhitelistFormFields,
    onSubmit,
  });

  return (
    <ModalDialog
      open={open}
      title={title}
      loading={loading}
      onClose={onClose}
      onConfirm={handleSubmit}
      confirmBtnText="Save"
      fullWidth
    >
      {form}
    </ModalDialog>
  );
}

export default SignatureWhitelistEditModal;
