import { Add } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useStoreUnitActions } from 'src/redux/helpers';
import DataTable, {
  Column,
  DataTableWrapper,
  DataTableWrapperActionButton,
  useTableQueryParams,
} from 'src/components/DataTable';
import { useModal } from 'src/hooks';
import { isModalComplete } from 'src/components/ModalDialog';
import { useUserRoles } from 'src/auth';
import {
  SolutionEdgeWorkloadItem,
  SolutionEdgeWorkloadRepositoryType,
} from 'src/pages/Solutions/types/solutionEdgeWorkload';
import { SiteItemStatus as SiteStatus } from 'src/pages/Sites/types';
import sitesStore, {
  useSiteClusterOptionsist,
  useSiteDetails,
} from '../../redux';
import SolutionEdgeWorkloadCreateContainer from './SiteEdgeWorkloadCreateContainer';

type Props = {
  data: SolutionEdgeWorkloadItem[];
  columns: Column<SolutionEdgeWorkloadItem>[];
  repositoryType: SolutionEdgeWorkloadRepositoryType;
  loading?: boolean;
  title?: string;
  infoTitle?: any;
};

const SolutionEdgeWorkloadRepositoryList = ({
  data,
  columns,
  repositoryType,
  loading = false,
  title,
}: Props) => {
  const [CreateEdgeWorkloadDialog, showCreateEdgeWorkloadDialog] = useModal(
    SolutionEdgeWorkloadCreateContainer,
    {
      onClose: (reason) => isModalComplete(reason),
      repositoryType,
    }
  );
  const { isPlatformReader, isPlatformOwner, isPlatformEngineer } =
    useUserRoles();
  const isValidUser = isPlatformOwner || isPlatformEngineer;
  const { paging, sorting } = useTableQueryParams();
  const [siteData] = useSiteDetails();
  const actions = useStoreUnitActions(sitesStore);
  const { siteId } = useParams();

  useEffect(() => {
    actions.clusterDetails(siteId);
  }, []);

  return (
    <DataTableWrapper
      title={title}
      titleStyle={{
        fontWeight: '500',
        fontSize: '16px',
        paddingRight: '12px',
      }}
      searchBar={true}
      actionsPanel={
        <DataTableWrapperActionButton
          startIcon={<Add />}
          onClick={showCreateEdgeWorkloadDialog}
          disabled={
            isPlatformReader ||
            !isValidUser ||
            siteData?.status !== SiteStatus.Active
          }
        >
          Configure New
        </DataTableWrapperActionButton>
      }
    >
      <DataTable
        data={data}
        columns={columns}
        loading={loading}
        {...paging}
        {...sorting}
      />
      {CreateEdgeWorkloadDialog}
    </DataTableWrapper>
  );
};

export default SolutionEdgeWorkloadRepositoryList;
