export const getPageCount = (pageSize: number, totalItems: number) =>
  Math.ceil(totalItems / pageSize);

export const labelDisplayedRows = ({
  from,
  to,
  totalItems,
}: {
  from: number;
  to: number;
  totalItems: number;
}) => `${from}-${to} of ${totalItems !== -1 ? totalItems : `more than ${to}`}`;

export const getLabelDisplayedRowsTo = ({
  totalItems,
  pageIndex,
  pageSize,
}: {
  totalItems: number;
  pageIndex: number;
  pageSize: number;
}) => {
  if (totalItems === -1) {
    return (pageIndex + 1) * pageSize;
  }
  return pageSize === -1
    ? totalItems
    : Math.min(totalItems, (pageIndex + 1) * pageSize);
};
