import { createStoreUnit } from 'src/redux/helpers';
import applicationList from './applicationListSlice';
import applicationRoles from './applicationRolesSlice';
import { USERS_STORE } from '../constants';

const usersStore = createStoreUnit({
  name: USERS_STORE,
  slices: {
    applicationList,
    applicationRoles,
  },
});

export default usersStore;
