import { generatePath } from 'react-router-dom';
import { createDatasetUnit } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import { API_GET_USER_IDENTITY, AUTH_STORE, GET_AUTH_STATE } from './constants';
import { UserIdentity } from './types';

const api = (userId: string) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: generatePath(API_GET_USER_IDENTITY, { userId }),
    skipErrorAlert: true,
  });

const initialState: UserIdentity = {
  solutionsOwner: [],
  solutionsUser: [],
  tenantsOwner: [],
  tenantsUser: [],
};

const authStore = createDatasetUnit({
  api,
  initialState,
  storeName: AUTH_STORE,
  actionName: GET_AUTH_STATE,
});

export default authStore;
