import { useParams } from 'react-router-dom';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { useStoreUnitActions } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { generateApiPath } from 'src/utils';
import solutionsStore from '../../redux';
import { API_SOURCE_IMAGE_REPOSITORY } from '../../constants';
import { SolutionSourceImageRepositoryItem } from '../../types';
import SolutionSourceImageRepoEditModal from './SolutionSourceImageRepoEditModal';
import { getSolutionSourceImageRepositoryParams } from '../utils';

type Props = {
  item: SolutionSourceImageRepositoryItem;
  open: boolean;
  onClose: ModalCloseHandler;
};

function SolutionSourceImageRepoEditContainer({ item, open, onClose }: Props) {
  const { showAlert } = useAlert();
  const { solutionId } = useParams();
  const actions = useStoreUnitActions(solutionsStore);
  const [handleSubmit, loading] =
    useStatefulApi<SolutionSourceImageRepositoryItem>(
      (params) =>
        pepApiRequest({
          method: RequestMethod.Put,
          url: generateApiPath(API_SOURCE_IMAGE_REPOSITORY, { solutionId }),
          params: {
            ...getSolutionSourceImageRepositoryParams(params),
          },
        }),
      () => {
        showAlert({
          type: AlertType.Info,
          title: 'Source image repository is modified',
          text: 'Source image repository has been successfully modified.',
        });
        onClose(null, ModalCloseReason.completeAction);
        actions.details(solutionId);
      }
    );

  return (
    <SolutionSourceImageRepoEditModal
      item={item}
      title={`Edit Source Image Repository`}
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      confirmBtnText={'Save'}
      loading={loading}
    />
  );
}

export default SolutionSourceImageRepoEditContainer;
