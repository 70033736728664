import { generatePath, useParams, useNavigate } from 'react-router-dom';
import { CancelOutlined } from '@mui/icons-material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutline';
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { generateApiPath } from 'src/utils/apiUtils';
import { useStoreUnitActions } from 'src/redux/helpers';
import { useModal } from 'src/hooks';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import { loadDataFromStorage } from 'src/utils';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {
  DataTableActionsButton,
  DataTableActionsWrapper,
  useTableQueryParams,
} from 'src/components/DataTable';
import { isModalComplete, ModalCloseReason } from 'src/components/ModalDialog';
import { PageCardLoader } from 'src/components/PageCardLayout';
import { useUserRoles } from 'src/auth';
import SolutionJobEditContainer from './SolutionJobEditContainer';
import {
  SolutionJobItem,
  SolutionJobStatus,
  SolutionJobType,
  SolutionType,
} from '../../types';
import { useSolutionUserRoles } from '../../hooks';
import solutionsStore, { useSolutionMenderSettings } from '../../redux';
import {
  API_JOB_ITEM_CANCEL,
  API_JOB_ITEM_IOTH_RERUN,
  API_JOB_ITEM_RERUN,
  API_JOB_ITEM_RESUME,
  API_JOB_ITEM_RUN,
  API_JOB_ITEM_STOP,
  SOLUTION_JOB_DETAILS,
} from '../../constants';
import SolutionJobViewContainer from './SolutionJobViewContainer';

type CreateApiHandlersParams = {
  url: string;
  method: RequestMethod;
  params?: Record<string, any>;
  title: string;
  text: string;
};

type Props = {
  item?: SolutionJobItem;
};

function SolutionJobActions({ item }: Props) {
  const navigate = useNavigate();
  const isFailed = item?.status === SolutionJobStatus.Failed;
  const isRunning = item?.status === SolutionJobStatus.Running;
  const isStopped = item?.status === SolutionJobStatus.Stopped;
  const isPending = item?.status === SolutionJobStatus.Pending;
  const isScheduled = item?.status === SolutionJobStatus.Scheduled;
  const isOutdated = item?.status === SolutionJobStatus.Outdated;
  const isHostOsUpdateType = item?.type === SolutionJobType.HostOsDeployment;
  const isCompleted = item?.status === SolutionJobStatus.completed;
  const isComplete = item?.status === SolutionJobStatus.Complete;
  const isCancelled = item?.status === SolutionJobStatus.Cancelled;
  const [{ menderEnabled }] = useSolutionMenderSettings();
  const { isSolutionEditor } = useSolutionUserRoles();
  const { isPlatformReader, isPlatformEngineer } = useUserRoles();
  const solutionType = loadDataFromStorage('solutionType');
  const canManage = isSolutionEditor && !isHostOsUpdateType;
  const { showAlert } = useAlert();
  const { paging } = useTableQueryParams();
  const { pageIndex, pageSize } = paging;
  const { solutionId } = useParams();
  const actions = useStoreUnitActions(solutionsStore);
  const onClose = (event, reason) =>
    isModalComplete(reason) &&
    actions.jobs({ solutionId, pageIndex, pageSize });

  const createJobActionsApiHandlers = ({
    url,
    method,
    params,
    title,
    text,
  }: CreateApiHandlersParams): [
    apiRequest: () => Promise<any>,
    onSuccess: () => void
  ] => [
    () =>
      pepApiRequest({
        method,
        url: generatePath(url, { solutionId, jobId: item?.id }),
        params,
      }),
    () => {
      showAlert({ type: AlertType.Info, title, text });
      onClose(null, ModalCloseReason.completeAction);
    },
  ];

  const [onRerun, loadingRerun] = useStatefulApi(
    ...createJobActionsApiHandlers({
      url:
        SolutionType.ioTH.toUpperCase() === solutionType.toUpperCase()
          ? API_JOB_ITEM_IOTH_RERUN
          : API_JOB_ITEM_RERUN,
      method: RequestMethod.Post,
      params: { startJobAfterSaving: true },
      title: 'Job is running',
      text: 'The failed job has been re-run successfully.',
    })
  );

  const [onCancel, loadingCancel] = useStatefulApi(
    ...createJobActionsApiHandlers({
      url: API_JOB_ITEM_CANCEL,
      method: RequestMethod.Post,
      params: { startJobAfterSaving: true },
      title: 'Job is cancelled',
      text: 'The job has been cancelled successfully.',
    })
  );

  const navigateToJobDetails = () =>
    navigate(
      generateApiPath(SOLUTION_JOB_DETAILS, {
        jobId: item?.id,
      })
    );

  const [onRun, loadingRun] = useStatefulApi(
    ...createJobActionsApiHandlers({
      url:
        SolutionType.ioTH.toUpperCase() === solutionType.toUpperCase()
          ? API_JOB_ITEM_RUN
          : API_JOB_ITEM_RESUME,
      method:
        SolutionType.ioTH.toUpperCase() === solutionType.toUpperCase()
          ? RequestMethod.Post
          : RequestMethod.Put,
      title: 'Job is running',
      text: 'The job has been run successfully.',
    })
  );

  const [onStop, loadingStop] = useStatefulApi(
    ...createJobActionsApiHandlers({
      url: API_JOB_ITEM_STOP,
      method: RequestMethod.Put,
      title: 'Job stopped',
      text: 'The job has been stopped.',
    })
  );

  const onOpenView = () => {
    actions.jobsDevices({
      jobId: item?.id,
      solutionId,
      sequence: '0',
      pageIndex,
      pageSize: 50,
    });
    return handleView();
  };

  const [EditDialog, showEditDialog] = useModal(SolutionJobEditContainer, {
    item,
    onClose,
  });

  const [ViewDialog, handleView] = useModal(SolutionJobViewContainer, {
    item,
    onClose,
  });

  return (
    <DataTableActionsWrapper>
      {SolutionType.ioTH.toUpperCase() === solutionType.toUpperCase() &&
        (item?.type === SolutionJobType.TemplateMigration ||
          item?.type === SolutionJobType.ScheduleDeviceMethod ||
          item?.type === SolutionJobType.ScheduleUpdateTwin) && (
          <DataTableActionsButton
            data-testid="solution-job-view-action"
            onClick={onOpenView}
            icon={VisibilityOutlinedIcon}
            disabled={isCancelled || isScheduled || isRunning || isOutdated}
            title="View"
          />
        )}
      {SolutionType.ioTH.toUpperCase() === solutionType.toUpperCase() &&
        isHostOsUpdateType &&
        menderEnabled && (
          <DataTableActionsButton
            data-testid="solution-job-dep-info-action"
            icon={VisibilityOutlinedIcon}
            title="View"
            onClick={navigateToJobDetails}
            disabled={!isSolutionEditor}
          />
        )}
      {
        <DataTableActionsButton
          data-testid="data-table-edit-action"
          disabled={
            SolutionType.ioTH.toUpperCase() === solutionType.toUpperCase()
              ? isCancelled ||
                isCompleted ||
                isComplete ||
                isFailed ||
                isPlatformReader ||
                isPlatformEngineer ||
                isOutdated ||
                isPending ||
                isRunning
              : (canManage && !isScheduled) ||
                isPlatformEngineer ||
                isPlatformReader
          }
          onClick={showEditDialog}
          icon={EditOutlinedIcon}
          title="Edit"
        />
      }
      {SolutionType.Consumer.toUpperCase() === solutionType.toUpperCase() && (
        <DataTableActionsButton
          data-testid="solution-job-delete-action"
          disabled={true}
          icon={DeleteOutlinedIcon}
          title="Delete"
        />
      )}
      {SolutionType.ioTH.toUpperCase() === solutionType.toUpperCase() &&
        (item?.type === SolutionJobType.ScheduleDeviceMethod ||
          item?.type === SolutionJobType.ScheduleUpdateTwin) && (
          <DataTableActionsButton
            data-testid="solution-job-delete-action"
            disabled={
              isFailed ||
              isCancelled ||
              isCompleted ||
              isPlatformReader ||
              isPlatformEngineer ||
              isOutdated
            }
            onClick={onCancel}
            icon={CancelOutlined}
            title="Cancel"
          />
        )}

      {isFailed && (
        <DataTableActionsButton
          data-testid="solution-job-rerun-action"
          icon={ReplayOutlinedIcon}
          title="Re-run"
          onClick={onRerun}
          disabled={
            SolutionType.ioTH.toUpperCase() === solutionType.toUpperCase()
              ? isCancelled || !canManage || isOutdated || isPlatformEngineer
              : !canManage || isPlatformEngineer
          }
        />
      )}
      {SolutionType.Consumer.toUpperCase() === solutionType.toUpperCase() &&
        !isRunning &&
        !isFailed && (
          <DataTableActionsButton
            data-testid="solution-job-run-action"
            icon={PlayCircleOutlinedIcon}
            title="Run"
            onClick={onRun}
            disabled={
              !canManage || (!isStopped && !isScheduled) || isPlatformEngineer
            }
          />
        )}
      {SolutionType.ioTH.toUpperCase() === solutionType.toUpperCase() &&
        (isRunning || !isFailed) && (
          <DataTableActionsButton
            data-testid="solution-job-run-action"
            icon={PlayCircleOutlinedIcon}
            title="Run"
            onClick={onRun}
            disabled={
              !canManage ||
              isOutdated ||
              (!isStopped && !isScheduled) ||
              isCancelled ||
              isPlatformEngineer
            }
          />
        )}
      {SolutionType.ioTH.toUpperCase() !== solutionType.toUpperCase() &&
        isRunning && (
          <DataTableActionsButton
            data-testid="solution-job-stop-action"
            icon={StopCircleOutlinedIcon}
            title="Stop"
            onClick={onStop}
            disabled={
              SolutionType.ioTH.toUpperCase() === solutionType.toUpperCase()
                ? !canManage || isCancelled || isPlatformEngineer
                : !canManage || isPlatformEngineer
            }
          />
        )}

      {isHostOsUpdateType && menderEnabled && (
        <DataTableActionsButton
          data-testid="solution-job-dep-info-action"
          icon={DescriptionOutlinedIcon}
          title="Deployment information"
          onClick={navigateToJobDetails}
          disabled={!isSolutionEditor}
        />
      )}
      {(loadingRun || loadingRerun || loadingCancel || loadingStop) && (
        <PageCardLoader />
      )}
      {EditDialog}
      {ViewDialog}
    </DataTableActionsWrapper>
  );
}

export default SolutionJobActions;
