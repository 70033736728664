import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { SxProps } from 'src/theme/types';
import CircularLoader from 'src/components/CircularLoader';

const style: SxProps = {
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  overflow: 'hidden',
  backgroundColor: ({ palette, alpha }) => alpha(palette.white, 0.7),
  zIndex: ({ zIndex }) => zIndex.appBar,
};

function PageCardLoader(props: BoxProps) {
  return (
    <Box data-testid="page-card-loader" sx={style} {...props}>
      <CircularLoader />
    </Box>
  );
}

export default PageCardLoader;
