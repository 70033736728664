import { ReactNode } from 'react';
import isString from 'lodash/isString';
import now from 'lodash/now';
import { BreadcrumbsItems } from './types';

export const getItemKey = (path?: string, name?: ReactNode) => {
  if (isString(name)) {
    return name;
  }
  if (path) {
    return path;
  }
  return now();
};

export const setLastActive = (
  items: BreadcrumbsItems,
  lastActive?: boolean
) => {
  const { length } = items;

  if (length && lastActive) {
    items[length - 1].isActive = true;
  }
};
