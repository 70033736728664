import { createContext } from 'react';

interface FilterTableInterface {
  showFilters?: boolean | false;
  toggleshowfilter: any;
  scrollerUpdateWithFilter: any;
  solutionType: string;
}

export const FilterTableContext = createContext<FilterTableInterface | null>(
  null
);
