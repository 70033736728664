import { useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Link from '@mui/material/Link';
import SiteItemStatus from 'src/pages/Sites/components/SiteItemStatus';
import { SiteItemStatus as Status } from '../../types';

const cellStyle = {
  border: 0,
  height: 'auto',
  padding: '10px 20px 10px 0px',
};
const LabelStyle = {
  fontWeight: 'bold',
  fontSize: '16px',
  width: '10%',
};

const iconStyle = {
  color: '#0047BA',
};

type Props = {
  status: Status;
  label: any;
  observability: string;
};

function ClusterDetails({ status, label, observability }: Props) {
  const [isCopied, setIsCopied] = useState('');
  const handleNavigate = () => window.open(observability);

  const handleCopy = (text?: any) => {
    navigator.clipboard.writeText(text ?? '').then(() => {
      setIsCopied(text ?? '');
    });
  };

  return (
    <>
      <Box flexDirection="column">
        <Box display="flex" alignItems="center" gap={2}>
          <Typography sx={[cellStyle, LabelStyle]}>Status</Typography>
          <SiteItemStatus value={status} />
        </Box>
        <Box display="flex" alignItems="center" gap={2}>
          <Typography sx={[cellStyle, LabelStyle]}>Label</Typography>
          <Typography sx={{ fontSize: '16px' }}>
            {label}
            {label !== '-' && (
              <IconButton
                title={isCopied ? 'Copied to clipboard' : 'Copy'}
                onClick={() => handleCopy(label)}
              >
                <ContentCopyIcon sx={iconStyle}></ContentCopyIcon>
              </IconButton>
            )}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={2}>
          <Typography sx={[cellStyle, LabelStyle]}>Observability</Typography>
          {observability ? (
            <Link onClick={handleNavigate}>Monitoring dashboard</Link>
          ) : (
            <Typography>-</Typography>
          )}
        </Box>
      </Box>
    </>
  );
}

export default ClusterDetails;
