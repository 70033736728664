import React from 'react';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { API_OTP_KEYS } from '../constants';
import EditOtpModal from './EditOtpModal';

type Props = {
  open: boolean;
  onClose: ModalCloseHandler;
};

function AddOtpModalContainer({ open, onClose }: Props) {
  const { showAlert } = useAlert();
  const [handleSubmit, loading] = useStatefulApi(
    (params) =>
      pepApiRequest({
        method: RequestMethod.Post,
        url: API_OTP_KEYS,
        params,
      }),
    () => {
      showAlert({
        type: AlertType.Success,
        title: 'OTP key added',
        text: 'OTP key has been successfully Added.',
      });
      onClose(null, ModalCloseReason.completeAction);
    }
  );

  return (
    <EditOtpModal
      title="Add key"
      open={open}
      onSubmit={handleSubmit}
      onClose={onClose}
      loading={loading}
    />
  );
}

export default AddOtpModalContainer;
