import React, { ReactNode } from 'react';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import { SxProps } from 'src/theme/types';
import { SIDEBAR_WIDTH_OPEN, SIDEBAR_WIDTH_CLOSED } from '../constants';

const getStyle = (open: boolean): SxProps => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  width: ({ spacing }) =>
    spacing(open ? SIDEBAR_WIDTH_OPEN : SIDEBAR_WIDTH_CLOSED),
  transition: ({ transitions: { create, duration, easing } }) =>
    create('width', {
      easing: easing.sharp,
      duration: duration.short,
    }),
  '& .MuiDrawer-paper': {
    bgcolor: 'primary.dark',
    overflowX: 'hidden',
    position: 'relative',
    width: '100%',
  },
});

type Props = DrawerProps & {
  children: ReactNode;
  open: boolean;
};

function SideBarContainer({ children, open, ...rest }: Props) {
  return (
    <Drawer
      data-testid="container"
      variant="permanent"
      open={open}
      sx={getStyle(open)}
      {...rest}
    >
      {children}
    </Drawer>
  );
}

export default SideBarContainer;
