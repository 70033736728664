import { createDatasetSlice, ResponseMapping } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import { generateApiPath } from 'src/utils';
import {
  API_SITES_SOLUTIONS,
  SITES_STORE,
  SITES_STORE_SOLUTIONS,
} from '../constants';

const api = (siteId: string) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: generateApiPath(API_SITES_SOLUTIONS, { siteId }),
  });

const siteSolutionsSlice = createDatasetSlice({
  api,
  storeName: SITES_STORE,
  actionName: SITES_STORE_SOLUTIONS,
  responseMapping: ResponseMapping.Default,
});

export default siteSolutionsSlice;
