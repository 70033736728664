export type CompanyItem = {
  id: string;
  name: string;
};

export type CompanySolutionItem = {
  id: string;
  solutionDisplayName: string;
};

export type CompanyDetailsItem = CompanyItem & {
  solutions: CompanySolutionItem[];
};

export enum CompanyItemField {
  Id = 'id',
  Name = 'name',
  Solutions = 'solutions',
}

export enum CompanyUserItemField {
  Email = 'userId',
  Role = 'role',
}

export type CompanyUserItem = {
  userId: string;
  role: CompanyUserRole;
};

export enum CompanyUserRole {
  Owner = 'owner',
  User = 'user',
}
