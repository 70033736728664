export enum SolutionAlertRuleItemSeverity {
  Minor = 'minor',
  Critical = 'critical',
}

export enum SolutionAlertRuleStatus {
  Enabled = 'true',
  Disabled = 'false',
}

export type SolutionAlertRuleItem = {
  id: number;
  name: string;
  type: string;
  severity: SolutionAlertRuleItemSeverity;
  interval: string;
  enabled: boolean;
};

export enum SolutionAlertRuleItemField {
  RuleName = 'name',
  RuleType = 'type',
  Severity = 'severity',
  Interval = 'interval',
  Enabled = 'enabled',
}
