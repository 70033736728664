import { createDatasetSlice, ResponseMapping } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import {
  API_SOLUTION_COMPANIES,
  SOLUTIONS_STORE,
  SOLUTIONS_STORE_COMPANIES,
} from '../constants';

const api = (solutionId: string) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: API_SOLUTION_COMPANIES,
    queryParams: { solutionId },
  });

const solutionWhitelistSlice = createDatasetSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTIONS_STORE_COMPANIES,
  responseMapping: ResponseMapping.Default,
});

export default solutionWhitelistSlice;
