import { useMemo, useContext } from 'react';
import { TableContext } from '../components/DataTableContext';
import { Column, Row } from '../types';
import { getFilteredRows } from '../utils';

type useFilteringProps<TRow extends Row> = {
  inputData: TRow[];
  inputColumns: Column<TRow>[];
};

type useFilteringReturnProps<TRow extends Row> = TRow[];

export const useFiltering = <TRow extends Row>({
  inputData,
  inputColumns,
}: useFilteringProps<TRow>): useFilteringReturnProps<TRow> => {
  const [searchStr] = useContext(TableContext);

  const filteredData = useMemo(
    () => getFilteredRows(inputData, inputColumns, searchStr),
    [inputData, searchStr]
  );

  return filteredData;
};
