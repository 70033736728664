import { useUserRoles } from 'src/auth';
import {
  useSolutionMenderSettings,
  useSolutionSourceStorageAccountSettings,
} from './redux';
import { SolutionFeature } from './types';

export const useSolutionUserRoles = (solutionId?: string) => {
  const params = solutionId ? { solutionId } : undefined;
  const { isPlatformOwner, isSolutionOwner, isSolutionUser, isPlatformUser } =
    useUserRoles(params);

  return {
    isSolutionEditor:
      isPlatformOwner || isSolutionOwner || isSolutionUser || isPlatformUser,
    isSolutionOwner: isPlatformOwner || isPlatformUser || isSolutionOwner,
  };
};

export const useSolutionFeatures = (): [SolutionFeature[], boolean?] => {
  const [settings, loading] = useSolutionMenderSettings();
  const [sourceStorageSettings, sourceStorageLoading] =
    useSolutionSourceStorageAccountSettings();
  const features: SolutionFeature[] = [];

  if (settings.menderEnabled) {
    features.push(SolutionFeature.Mender);
  }

  if (sourceStorageSettings.storageEnabled) {
    features.push(SolutionFeature.SourceStorage);
  }

  return [features, loading && sourceStorageLoading];
};
