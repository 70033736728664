import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { Box, Icon, Typography } from '@mui/material';

function SolutionFeatureInformation({ featureInfo }) {
  return (
    <Box
      sx={{
        flexDirection: 'row',
        color: '#0047BA',
        display: 'flex',
        alignItems: 'center',
        mb: 2,
      }}
    >
      <Icon>
        <InfoOutlined />
      </Icon>
      <Typography ml={1} variant="body2">
        {featureInfo}
      </Typography>
    </Box>
  );
}

export default SolutionFeatureInformation;
