import React from 'react';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import { Row, ToggleGroupHandler } from '../types';

type Props<TRow> = {
  item: TRow;
  expandable: boolean;
  expanded: boolean;
  onToggleGroup: ToggleGroupHandler<TRow>;
  isGroup: boolean;
};

export default function DataTableGroupToggle<TRow extends Row>({
  expandable,
  expanded,
  onToggleGroup,
  item,
  isGroup,
}: Props<TRow>) {
  const ArrowIcon = expanded ? ExpandMore : ChevronRightIcon;

  const onClick = (event) => {
    event.stopPropagation();
    onToggleGroup(!expanded, item);
  };

  return isGroup && expandable ? (
    <IconButton color="primary" onClick={onClick}>
      <ArrowIcon />
    </IconButton>
  ) : null;
}
