import { createDatasetSlice, ResponseMapping } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import { generateApiPath } from 'src/utils';
import {
  API_SITE_WORKLOAD_CONFIGURATIONS,
  SITES_STORE,
  SITES_STORE_EDGE_WORKLOAD_CONFIGURATIONS,
} from '../constants';

const api = (siteId) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: generateApiPath(API_SITE_WORKLOAD_CONFIGURATIONS, { siteId }),
  });

const edgeWorkloadConfigurations = createDatasetSlice({
  api,
  storeName: SITES_STORE,
  actionName: SITES_STORE_EDGE_WORKLOAD_CONFIGURATIONS,
  responseMapping: ResponseMapping.Default,
});

export default edgeWorkloadConfigurations;
