import { ReactNode } from 'react';
import isArray from 'lodash/isArray';
import { Option, OptionValue } from 'src/components/SelectOption';

export const getSelectOptions = (values: OptionValue[]): Option[] =>
  values.map((value) => ({ value, label: value.toString() }));

export const getDisplayValue = (
  value: OptionValue | OptionValue[],
  options: Option[],
  loading?: boolean
): ReactNode => {
  const inputValue = !loading ? value : undefined;
  const displayValue = isArray(value)
    ? options
        .filter((option) => value.includes(option.value))
        .map(({ label }) => label)
        .join(', ')
    : options.find((option) => option.value === value)?.label;

  return displayValue || inputValue;
};
