import React, { useContext } from 'react';
import debounce from 'lodash/debounce';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { TableContext } from './DataTableContext';
import { DEBOUNCE_DURATION } from '../constants';

type Props = {
  placeholder?: string;
  className?: string;
  style?: React.CSSProperties;
};

function DataTableSearchBar({
  placeholder = 'Search',
  className = 'search-bar',
  style,
}: Props) {
  const [searchStr, setSearchStr] = useContext(TableContext);
  const handleChange = debounce(
    (event) => setSearchStr(event.target.value),
    DEBOUNCE_DURATION
  );

  return (
    <TextField
      variant="standard"
      defaultValue={searchStr}
      placeholder={placeholder}
      onChange={handleChange}
      className={className}
      inputProps={{ size: 16, 'data-testid': 'data-table-search-input' }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color="primary" fontSize="small" />
          </InputAdornment>
        ),
      }}
      style={style}
    />
  );
}

export default DataTableSearchBar;
