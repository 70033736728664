import React from 'react';

type HeaderType = {
  backgroundColor: string;
  color: string;
  logoColor: string;
  navColor: 'primary' | 'secondary';
};

export const headerTypes: Record<string, HeaderType> = {
  light: {
    backgroundColor: 'white',
    color: 'primary.main',
    logoColor: 'black',
    navColor: 'primary',
  },
  dark: {
    backgroundColor: 'primary.dark',
    color: 'white',
    logoColor: 'white',
    navColor: 'secondary',
  },
};

export default React.createContext(headerTypes.light);
