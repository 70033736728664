import React, { ReactNode } from 'react';
import uniqueId from 'lodash/uniqueId';
import pullAt from 'lodash/pullAt';
import Snackbar from '@mui/material/Snackbar';
import AlertTitle from '@mui/material/AlertTitle';
import Alert from '../Alert';
import AlertContext, { AlertType, AlertHandlerProps } from './AlertContext';
import AlertsContainer from './components/AlertsContainer';
import { useApiErrorAlert } from './hooks';

type Props = {
  children: React.ReactNode;
};

type AlertProps = {
  id?: string;
  open: boolean;
  type?: AlertType;
  text?: ReactNode;
  title?: string;
};

type AlertState = AlertProps[];

function AlertProvider({ children }: Props) {
  const [state, setState] = React.useState<AlertState>([]);

  const handleClick = ({ id, ...rest }: AlertHandlerProps) => {
    const newItem = { id: id || uniqueId(), open: true, ...rest };
    setState((state) => [...state, newItem]);
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason: string | null,
    index: number
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    pullAt(state, index);
    setState([...state]);
  };

  useApiErrorAlert(handleClick);

  return (
    <AlertContext.Provider value={{ showAlert: handleClick }}>
      {!!state.length && (
        <AlertsContainer>
          {state.map(({ id, open, type, title, text }, index) => (
            <Snackbar
              key={id}
              open={open}
              onClose={(...args) => handleClose(...args, index)}
            >
              <Alert
                data-testid={`alert-${id || type}`}
                onClose={(event) => handleClose(event, null, index)}
                color={type}
              >
                {title && <AlertTitle>{title}</AlertTitle>}
                {text}
              </Alert>
            </Snackbar>
          ))}
        </AlertsContainer>
      )}
      {children}
    </AlertContext.Provider>
  );
}

export default AlertProvider;
