import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { CellProps, Row } from '../types';

function DataTableCheckbox<TRow extends Row>({
  item,
  selected,
  onSelect,
}: CellProps<boolean, TRow>) {
  return (
    <Checkbox
      checked={selected}
      onChange={() => onSelect && onSelect(!selected, item)}
      onClick={(event) => event.stopPropagation()}
      sx={{
        marginLeft: '-10px',
      }}
    />
  );
}

export default DataTableCheckbox;
