import { baseTheme } from '../baseTheme';
import { ALERT_TIMEOUT } from '../constants';

const { spacing, breakpoints } = baseTheme;

export default {
  defaultProps: {
    autoHideDuration: ALERT_TIMEOUT,
  },
  styleOverrides: {
    root: {
      position: 'static',
      [breakpoints.up('sm')]: {
        minWidth: spacing(53),
      },
    },
  },
};
