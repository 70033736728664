import React, { ReactNode } from 'react';
import Box from '@mui/material/Box';

type Props = {
  children?: ReactNode;
  onClick?: () => void;
  className?: string;
};

const style = {
  display: 'flex',
  alignItems: 'center',
  float: 'center',
  '>.MuiIconButton-root:first-of-type': {
    ml: -1,
  },
};

function DataTableActionsWrapper({ children, onClick, className }: Props) {
  const handleClick = (event) => {
    event.stopPropagation();
    if (onClick) {
      onClick();
    }
  };

  return (
    <Box sx={style} onClick={handleClick} className={className}>
      {children}
    </Box>
  );
}

export default DataTableActionsWrapper;
