import { NestedRouter, RouterBreadcrumbs } from 'src/router';
import { routes } from './routes';

function SideBarFooter() {
    return (
        <>
            <RouterBreadcrumbs routes={routes} />
            <NestedRouter routes={routes} />
        </>
    );
}

export default SideBarFooter;
