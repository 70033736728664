import { useEffect, useRef, useState } from 'react';
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ModalDialog, { ModalCloseHandler } from 'src/components/ModalDialog';
import { loadDataFromStorage, saveDataToStorage } from 'src/utils';
import { msalInstance } from './SolutionDashboardCreateContainer';
import { loginRequest } from './msalConfig';

type Props = {
  item;
  open: boolean;
  onClose: ModalCloseHandler;
};

const SolutionsDashboardViewModal = ({ item, open, onClose }: Props) => {
  const { dashboardId, name } = item;
  const { inProgress } = useMsal();
  const iframeRef = useRef(null);
  const [iframeLoad, setIframeLoad] = useState(false);
  const [user, setUser] = useState<any>();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const accountDetails = loadDataFromStorage('msalAccountDetail');
  const msalTokenData = loadDataFromStorage('msalToken');

  const iframeConfig = {
    cluster: process.env.REACT_APP_CLUSTER_DETAILS,
    zone: process.env.REACT_APP_CLUSTER_ZONE,
    features:
      'f-IFrameAuth=true&f-UseMeControl=true&f-ShowNavigation=false&f-ShowPageHeader=false&f-HideConnectionPane=true&f-Homepage=false&f-DisableDashboardTopBar=true&f-ShowConnectionButtons=false',
  };

  const handleLogin = async () => {
    try {
      await msalInstance.initialize();
      await msalInstance
        .loginPopup(loginRequest)
        .then((response) => {
          if (response?.account) {
            setIframeLoad(true);
            setUser(response?.account);
            saveDataToStorage('msalExpireTime', response?.expiresOn?.getTime());
            saveDataToStorage('msalToken', response.idToken);
            saveDataToStorage('msalAccountDetail', response?.account);
          }
        })
        .catch((error) => {
          console.error('Login error', error);
        });
      setIsAuthenticated(true);
    } catch (error: any) {
      if (error.errorCode === 'interaction_in_progress') {
        console.warn('Another interaction is in progress. Please wait.');
      } else {
        console.error('Login error:', error);
      }
    }
  };

  const isTokenExpired = () => {
    const msalExpireTime = loadDataFromStorage('msalExpireTime');
    return msalExpireTime && new Date().getTime() > msalExpireTime;
  };
  const refreshTokenSilently = async () => {
    try {
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        const account = accounts[0];
        msalInstance.setActiveAccount(account);
        const silentRequest = {
          scopes: ['user.read'],
          account,
        };
        const response = await msalInstance.acquireTokenSilent(silentRequest);
        saveDataToStorage('msalToken', response.accessToken);
        setUser(account);
        setIsAuthenticated(true);
      }
    } catch (error) {
      console.error('Silent token refresh error:', error);
      handleLogin(); // If silent refresh fails, prompt re-authentication
    }
  };

  const checkTokenValidity = async () => {
    if (isTokenExpired()) {
      await refreshTokenSilently();
    }
  };

  function mapScope(scope) {
    switch (scope) {
      case 'query':
        return [
          `https://${iframeConfig.cluster}.${iframeConfig.zone}.kusto.windows.net/.default`,
        ];
      case 'People.Read':
        return ['People.Read', 'User.ReadBasic.All', 'Group.Read.All'];
      case 'https://rtd-metadata.azurewebsites.net/user_impersonation':
        return ['https://rtd-metadata.azurewebsites.net/user_impersonation'];
      default:
        return [scope];
    }
  }

  const postToken = (iframe, accessToken, scope) => {
    iframe.contentWindow.postMessage(
      {
        type: 'postToken',
        message: accessToken,
        scope,
      },
      '*'
    );
  };

  useEffect(() => {
    if (!msalTokenData) {
      handleLogin();
    } else {
      setIframeLoad(true);
    }
  }, []);

  useEffect(() => {
    if (user) {
      setIframeLoad(true);
    }
  }, [user, msalTokenData]);

  useEffect(() => {
    checkTokenValidity();
  }, []);

  useEffect(() => {
    const handleIframeMessage = async (event) => {
      if (
        event.data.type !== 'getToken' ||
        event.origin !== 'https://dataexplorer.azure.com'
      ) {
        return;
      }
      const aadScopes = mapScope(event.data.scope);
      const iframe = iframeRef.current;
      const accountsDetails = [accountDetails];
      if (accountsDetails.length > 0 && inProgress === InteractionStatus.None) {
        await msalInstance.initialize();
        await msalInstance.handleRedirectPromise();
        await msalInstance
          .acquireTokenSilent({
            scopes: aadScopes,
            account: accountsDetails[0],
          })
          .then((response) =>
            postToken(iframe, response.accessToken, event.data.scope)
          )
          .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
              msalInstance
                .acquireTokenPopup({ scopes: aadScopes })
                .then((response) =>
                  postToken(iframe, response.accessToken, event.data.scope)
                )
                .catch((error) => {
                  console.error(`Error acquiring token: ${error}`);
                });
            } else {
              console.error(`Error acquiring token: ${error}`);
            }
          });
      }
    };
    // Attach event listener when the component mounts
    window.addEventListener('message', handleIframeMessage);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, [accountDetails]);

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      onConfirm={onClose}
      showCancelBtn={false}
      title="View Dashboard"
      confirmBtnText="Close"
      maxWidth="xl"
      fullWidth
    >
      <Typography color="primary" variant="body2" mb={3}>
        <InfoIcon sx={{ mr: 1 }} />
        Please enter the ADX credentials in the external pop-up if you haven't
        done so earlier.
      </Typography>
      <iframe
        ref={iframeRef}
        title="adx example"
        src={
          !iframeLoad
            ? `https://dataexplorer.azure.com/dashboards/?${iframeConfig.features}`
            : `https://dataexplorer.azure.com/dashboards/${dashboardId}?${iframeConfig.features}`
        }
        width="100%"
        height="600"
      />
    </ModalDialog>
  );
};

export default SolutionsDashboardViewModal;
