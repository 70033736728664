import React, { useState, useCallback } from 'react';

type UseModalOptions = {
  renderOnOpen?: boolean;
  onClose?: (...args: any[]) => void;
} & Record<string, any>;

type ModalShowCallback = (params?: Record<string, any>) => void;

type UseModalReturn = [
  React.ReactNode | null | false,
  ModalShowCallback,
  boolean
];

const defaultOptions: UseModalOptions = {
  renderOnOpen: true,
};

export const useModal = (
  Component,
  options?: UseModalOptions
): UseModalReturn => {
  const [{ open, params }, setState] = useState({ open: false, params: {} });
  const { onClose, renderOnOpen, ...props } = { ...defaultOptions, ...options };

  const showDialog = useCallback(
    (params) => setState({ open: true, params }),
    []
  );

  const closeDialog = useCallback(
    (...args) => {
      setState({ open: false, params: {} });
      if (onClose) {
        onClose(...args);
      }
    },
    [onClose]
  );

  const DialogComponent =
    !renderOnOpen || open ? (
      <Component open={open} onClose={closeDialog} {...props} {...params} />
    ) : null;

  return [DialogComponent, showDialog, open];
};
