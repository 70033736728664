import React from 'react';
import ModalDialog, { ModalCloseHandler } from 'src/components/ModalDialog';
import { useForm } from 'src/components/Form';
import { getOtpEditFormFields } from '../formFields';
import { OtpItem } from '../types';

type Props = {
  title: string;
  item?: OtpItem;
  open: boolean;
  onSubmit: (values: OtpItem) => void;
  onClose: ModalCloseHandler;
  loading?: boolean;
};

function EditOtpModal({
  item,
  title,
  open,
  onSubmit,
  onClose,
  loading,
}: Props) {
  const fields = getOtpEditFormFields(item?.key);
  const [form, handleSubmit] = useForm({
    values: item,
    fields,
    onSubmit,
  });

  return (
    <ModalDialog
      open={open}
      title={title}
      onClose={onClose}
      onConfirm={handleSubmit}
      confirmBtnText="Save"
      loading={loading}
      maxWidth="xs"
      fullWidth
    >
      {form}
    </ModalDialog>
  );
}

export default EditOtpModal;
