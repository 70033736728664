import { useMemo } from 'react';
import { useSyncState } from 'src/hooks';
import DataTableSortHeader from '../components/DataTableSortHeader';
import { Column, SortOrder, Row, SortHandler } from '../types';
import { getSortedRows } from '../utils';

type useSortingProps<TRow extends Row> = {
  inputData: TRow[];
  inputColumns: Column<TRow>[];
  onSort?: SortHandler;
  sortable?: boolean;
  sortBy?: string;
  sortOrder?: SortOrder;
};

type useSortingReturnProps<TRow extends Row> = {
  columns: Column<TRow>[];
  sortedData: TRow[];
  onSort: SortHandler;
  sortBy?: string;
  sortOrder?: SortOrder;
};

type SortingState = {
  sortBy?: string;
  sortOrder: SortOrder;
};

export const useSorting = <TRow extends Row>({
  inputData,
  inputColumns,
  sortable,
  sortBy: initialSortBy,
  sortOrder: initialSortOrder = null,
  onSort: handleSort,
}: useSortingProps<TRow>): useSortingReturnProps<TRow> => {
  const [{ sortBy, sortOrder }, setState] = useSyncState<SortingState>(
    {
      sortBy: initialSortBy,
      sortOrder: initialSortOrder,
    },
    [initialSortBy, initialSortOrder]
  );

  const columns = useMemo(
    () =>
      inputColumns.map((column) =>
        (sortable && (column.sortable ?? true)) || column.sortable
          ? ({
              HeaderComponent: DataTableSortHeader,
              ...column,
            } as Column<TRow>)
          : column
      ),
    [inputColumns]
  );

  const sortedData = useMemo(
    () => getSortedRows(inputData, sortBy, sortOrder),
    [inputData, sortOrder, sortBy]
  );

  const onSort = (sortBy, sortOrder) => {
    setState({ sortBy, sortOrder });
    if (handleSort) {
      handleSort(sortBy, sortOrder);
    }
  };

  return {
    columns,
    onSort,
    sortBy,
    sortedData,
    sortOrder,
  };
};
