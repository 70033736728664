import { matchPath } from 'react-router-dom';
import isBoolean from 'lodash/isBoolean';
import slice from 'lodash/slice';
import { UserFeature, UserRole } from 'src/auth';
import { routes } from './routes';
import { RouteProps } from './types';

export const isUserRoleAllowed = (
  currentUserRoles: UserRole[],
  routeUserRoles?: UserRole[]
) =>
  !routeUserRoles ||
  !!routeUserRoles?.find((role) => currentUserRoles.includes(role));

export const isFeatureAllowed = (
  currentUserFeatures: UserFeature[],
  routeFeatures?: UserFeature[]
) =>
  !routeFeatures ||
  routeFeatures.find((feature) => currentUserFeatures.includes(feature));

export const isRoutePublic = (pathname: string) =>
  routes
    .filter(({ isPublic }) => isPublic)
    .find(({ path }) => matchPath({ path }, pathname));

export const matchRoute = (
  pathname: string,
  path?: string,
  rootPath?: string | null,
  end?: boolean
) => {
  end = isBoolean(end) ? end : false;
  path = rootPath ? concatPath(rootPath, path) : path;
  return path && matchPath({ path, end }, pathname);
};

export const getRoute = (
  pathname: string,
  routes: RouteProps[],
  rootPath?: string | null
): RouteProps | undefined =>
  routes.find(({ path, index }) => matchRoute(pathname, path, rootPath, index));

export const getRouteIndex = (
  pathname: string,
  routes: RouteProps[],
  rootPath?: string
): number =>
  routes.findIndex(({ path, index }) =>
    matchRoute(pathname, path, rootPath, index)
  );

export const getRouteMeta = (...args: [string, RouteProps[], string?]) =>
  getRoute(...args)?.meta || { title: '' };

export const getExactPath = (path: string | undefined = '', exact?: boolean) =>
  exact ? path : `${path}/*`;

export const concatPath = (...args: (string | undefined)[]) =>
  args.filter((item) => !!item).join('/');

export const slicePath = (
  path: string,
  startSegment: number,
  endSegment?: number
) => {
  const divider = '/';
  return slice(
    path.split(divider).filter((path) => path),
    startSegment,
    endSegment
  )
    .map((segment) => divider + segment)
    .join('');
};

export const getRootPath = (pathname: string, routes: RouteProps[]) => {
  let pathEnd = pathname;
  let isRouteFound = false;

  while (!isRouteFound && pathEnd.length) {
    isRouteFound = !!getRoute(pathEnd, routes);
    if (!isRouteFound) {
      pathEnd = slicePath(pathEnd, 1);
    }
  }

  return pathname.replace(pathEnd, '');
};

export const isChildRoute = (
  { path }: RouteProps,
  pathname: string,
  rootPath?: string
) => {
  const routeSelfPath = rootPath ? concatPath(rootPath, path) : path;
  return pathname !== routeSelfPath;
};

export const isTabsPanelVisible = (
  currRoute: RouteProps,
  pathname: string,
  rootPath?: string
) => {
  const { hideTabs, hideTabsInChildren } = currRoute.meta || {};
  const isChild = isChildRoute(currRoute, pathname, rootPath);
  return (!isChild || !hideTabsInChildren) && !hideTabs;
};

export const getRouteTabId = (rootId: string, path?: string) =>
  `${rootId}-${path || 'default'}`;
