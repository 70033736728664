import React from 'react';
import Chip, { ChipProps } from '@mui/material/Chip';
import { SxProps } from 'src/theme/types';

const style: SxProps = {
  typography: 'small1',
  textTransform: 'uppercase',
  minWidth: ({ spacing }) => spacing(8),
  height: ({ spacing }) => spacing(2),
  borderRadius: ({ spacing }) => spacing(0.4),
  '&.MuiChip-colorDefault': {
    color: 'white',
    backgroundColor: 'neutral.main',
  },
  '&.MuiChip-colorSuccess, &.MuiChip-colorWarning': {
    color: 'white',
  },
};

function Badge(props: ChipProps) {
  return <Chip data-testid="badge" size="small" sx={style} {...props} />;
}

export default Badge;
