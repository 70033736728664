import { omit } from 'lodash';
import {
  SolutionDeviceGroupDetails,
  SolutionDeviceGroupPropertyItem,
} from '../types';

export const mapDeviceGroupToRequestFormat = (
  values: SolutionDeviceGroupDetails,
  properties: SolutionDeviceGroupPropertyItem[]
) => {
  const mapIdToPropertyItem = (propertyId) =>
    properties.find(
      ({ id }) => propertyId === id
    ) as SolutionDeviceGroupPropertyItem;

  const buildFilterValue = (propertyId, filterValue) => {
    const selectedProperty = properties?.find((a) => a?.id === propertyId);
    return selectedProperty?.schema?.toLowerCase() === 'integer'
      ? Number(filterValue)
      : filterValue;
  };
  return {
    ...values,
    filters: values.filters.map((item) => ({
      ...item,
      filterValue: buildFilterValue(item.property, item.filterValue),
      property: omit(mapIdToPropertyItem(item.property), 'operators'),
    })),
  };
};
