import React, { ReactNode } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type Props = BoxProps & {
  children?: ReactNode;
  title?: string;
};

function PageCardSection({ children, title, ...rest }: Props) {
  return (
    <Box mb={3} {...rest}>
      {title && (
        <Typography mb={2} paragraph={true} variant="body2bold">
          {title}
        </Typography>
      )}
      {children}
    </Box>
  );
}

export default PageCardSection;
