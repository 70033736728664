import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SxProps } from 'src/theme/types';

type Props = {
  title?: string;
  count: number;
};
const style: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,
  typography: 'body2bold',
  width: ({ spacing }) => spacing(15),
  height: ({ spacing }) => spacing(15),
  border: 1,
  borderRadius: '50%',
  borderColor: 'primary.main',
  gap: 1,
};

function StatsSummaryTotal({ title, count }: Props) {
  return (
    <Box data-testid="stats-summary-total" sx={style}>
      {title}
      <Typography variant="h3" gutterBottom>
        {count}
      </Typography>
    </Box>
  );
}

export default StatsSummaryTotal;
