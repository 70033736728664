import { RouteProps } from 'src/router';
import { Docs } from 'src/pages/Docs';
import { HelpOutline } from '@mui/icons-material';
import { DocsRoute } from '../constants';

export const routes: RouteProps[] = [
  {
    path: DocsRoute,
    component: Docs,
    meta: {
      title: 'Help center',
      description: 'Help center',
      dashboardLink: true,
      navLink: true,
      icon: HelpOutline,
    },
  },
];
