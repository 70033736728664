import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AnalyticsOutlined } from '@mui/icons-material';
import { useStoreUnitActions } from 'src/redux/helpers';
import { useUserRoles } from 'src/auth';
import {
  DataTableActions,
  DataTableActionsButton,
} from 'src/components/DataTable';
import { useSolutionUserRoles } from '../../hooks';
import solutionsStore, { useSolutionDetails } from '../../redux';

function SolutionWorkloadStatusAction() {
  const [item] = useSolutionDetails();
  const { solutionId } = useParams();
  const actions = useStoreUnitActions(solutionsStore);

  useEffect(() => {
    actions.details(solutionId);
  }, [solutionId]);
  const { isSolutionOwner } = useSolutionUserRoles(solutionId);
  const { isPlatformReader, isPlatformEngineer } = useUserRoles();
  const isSolutionEnabled =
    isPlatformReader || isSolutionOwner || isPlatformEngineer;
  const handleViewObservability = () => window.open(item?.kibanaSpaceUrl);

  return (
    <DataTableActions showView={false} showEdit={false} showDelete={false}>
      <DataTableActionsButton
        data-testid="solution-observability-visit-icon"
        icon={AnalyticsOutlined}
        title="Observability Dashboard"
        onClick={handleViewObservability}
        disabled={
          !isSolutionEnabled ||
          item?.kibanaSpaceUrl === '' ||
          item?.kibanaSpaceUrl === undefined
        }
      />
    </DataTableActions>
  );
}

export default SolutionWorkloadStatusAction;
