import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PageCardLayout from 'src/components/PageCardLayout';
import { useStoreUnitActions } from 'src/redux/helpers';
import SolutionDeviceBulkDeploymentStatus from './components/SolutionDeviceBulkDeploymentStatus';
import SolutionDeviceBulkDeploymentLog from './components/SolutionDeviceBulkDeploymentLog';
import solutionsStore, { useSolutionDeviceBulkDeploymentData } from '../redux';

function SolutionDeviceBulkDeployment() {
  const { jobId } = useParams();
  const actions = useStoreUnitActions(solutionsStore);
  const { details, statistics, logs, loading } =
    useSolutionDeviceBulkDeploymentData();

  useEffect(() => {
    actions.deviceBulkDeploymentDetails({ jobId });
  }, [jobId]);

  return (
    <PageCardLayout title="Deployment information" loading={loading}>
      <SolutionDeviceBulkDeploymentStatus
        details={details}
        statistics={statistics}
      />
      <SolutionDeviceBulkDeploymentLog logs={logs} />
    </PageCardLayout>
  );
}

export default SolutionDeviceBulkDeployment;
