import React, { createContext, ReactNode } from 'react';
import { useSyncState } from 'src/hooks';

type ContextProps = [string, (string) => void];

export const TableContext = createContext<ContextProps>(['', () => null]);

type Props = {
  children: ReactNode;
  onSearch?: (value: string) => void;
  searchValue?: string;
};

function DataTableContext({ children, onSearch, searchValue = '' }: Props) {
  const [searchStr, setSearchStr] = useSyncState<string>(searchValue);
  const handleSearch = (value: string) => {
    setSearchStr(value);
    if (onSearch) {
      onSearch(value);
    }
  };

  return (
    <TableContext.Provider value={[searchStr, handleSearch]}>
      {children}
    </TableContext.Provider>
  );
}

export default DataTableContext;
