import React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import SolutionsFilesWhitelist from '../../SolutionFilesWhitelist/SolutionsFilesWhitelist';
import SolutionsDeviceConfig from '../../SolutionDeviceConfig/SolutionDeviceConfig';
import SolutionSignatureWhitelist from '../../SolutionSignatureWhitelist/SolutionSignatureWhitelist';
import SolutionDeviceExtResources from '../../SolutionDeviceExtResources';
import { useSolutionMenderSettings } from '../../redux';

function SolutionDeviceConfigTab() {
  const [{ menderEnabled }] = useSolutionMenderSettings();

  return (
    <Box>
      <SolutionsDeviceConfig />
      <Divider />
      <SolutionsFilesWhitelist />
      <Divider />
      <SolutionSignatureWhitelist />
      {menderEnabled && (
        <>
          <Divider />
          <SolutionDeviceExtResources />
        </>
      )}
    </Box>
  );
}

export default SolutionDeviceConfigTab;
