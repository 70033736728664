import React from 'react';
import { useSolutionDeviceDetails } from '../../redux';

type Props = {
  title?: string;
};

function SolutionDeviceDeploymentTitle({ title }: Props) {
  const { data } = useSolutionDeviceDetails();

  return <>{data?.displayName || title}</>;
}

export default SolutionDeviceDeploymentTitle;
