import React from 'react';
import { maskPhoneNumber } from 'src/utils/phoneNumberutils';
import { SolutionWorkloadStatus as Status } from '../../types/solutionEdgeWorkload';

type Props = {
  value?: string;
};

function SolutionUsersPhoneNumber({ value = Status.Ready }: Props) {
  const maskedNumber = maskPhoneNumber(value);
  return <span>{maskedNumber}</span>;
}

export default SolutionUsersPhoneNumber;
