import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { SystemProps } from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import { SxProps } from 'src/theme/types';
import { PageCardLoader } from '../PageCardLayout';
import { FieldDataItem, FieldsSetData } from './types';

type Props<Data extends FieldsSetData> = BoxProps & {
  data?: Data;
  fields: FieldDataItem[];
  defaultValue?: string;
  loading?: boolean;
};

const containerStyle: SystemProps = {
  display: 'flex',
  flexDirection: 'column',
  gap: 3,
  position: 'relative',
  maxWidth: '75rem',
};

const labelStyle: SxProps = {
  minWidth: '40%',
  mr: 2,
  fontWeight: 500,
};

function FieldsSet<Data extends FieldsSetData = FieldsSetData>({
  data,
  fields,
  loading,
  defaultValue = '–',
  ...rest
}: Props<Data>) {
  return (
    <Box {...containerStyle} {...rest}>
      {data &&
        fields.map(({ id, label, Component }) => (
          <Box data-testid={`fieldset-item-${id}`} display="flex" key={label}>
            <Typography sx={labelStyle} variant="body2bold">
              {label}
            </Typography>
            {Component ? (
              <Component defaultValue={defaultValue} value={data[id]} />
            ) : (
              data[id] || defaultValue
            )}
          </Box>
        ))}
      {loading && <PageCardLoader />}
    </Box>
  );
}

export default FieldsSet;
