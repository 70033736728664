import React from 'react';
import { useSolutionDevicePropertyMeta } from '../../redux';
import { SolutionDeviceGroupFilter } from '../../types';

type Props = {
  item?: SolutionDeviceGroupFilter;
  value?: string;
};

export default function SolutionDeviceGroupFiltersValueCell({
  item,
  value,
}: Props) {
  const { enums } = useSolutionDevicePropertyMeta(item?.property);

  return (
    <>{enums?.find((enumItem) => enumItem.value === value)?.label || value}</>
  );
}
