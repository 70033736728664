import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import DataTable, {
  getActionsColConfig,
  useTableQueryParams,
} from 'src/components/DataTable';
import DataTableContext from 'src/components/DataTable/components/DataTableContext';
import DataTableSearchBar from 'src/components/DataTable/components/DataTableSearchBar';
import PageCardLayout from 'src/components/PageCardLayout';
import { useStoreUnitActions } from 'src/redux/helpers/createStoreUnit';
import { useUserRoles } from 'src/auth';
import companiesStore from '../redux/companiesStore';
import { useCompaniesList } from '../redux';
import CompaniesListAction from './components/CompaniesListAction';
import { CompanyItemField } from '../types';
import * as ROUTES from '../constants';

const columns = [
  { id: CompanyItemField.Name, title: 'Company name' },
  { id: CompanyItemField.Id, title: 'Company ID' },
  getActionsColConfig(CompaniesListAction),
];

function CompaniesList() {
  const [data, loading] = useCompaniesList();
  const { paging, search, sorting } = useTableQueryParams();
  const actions = useStoreUnitActions(companiesStore);
  const { isPlatformReader } = useUserRoles();

  useEffect(() => {
    actions.list();
  }, []);

  return (
    <DataTableContext {...search}>
      <PageCardLayout
        title="Companies"
        actionsPanel={
          <>
            <DataTableSearchBar />
            <Button
              component={Link}
              to={ROUTES.NEW_COMPANY}
              variant="outlined"
              size="small"
              startIcon={<AddIcon />}
              disabled={isPlatformReader}
            >
              New company
            </Button>
          </>
        }
      >
        {data && (
          <DataTable
            data={data}
            columns={columns}
            loading={loading}
            {...paging}
            {...sorting}
          />
        )}
      </PageCardLayout>
    </DataTableContext>
  );
}

export default CompaniesList;
