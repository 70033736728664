import {
  RoutableTabsLayout, RouterBreadcrumbs
} from 'src/router';
import { routes } from './routes';

function Users() {
  return (
    <>
      <RouterBreadcrumbs routes={routes} />
      <RoutableTabsLayout routes={routes} />
    </>
  );
}

export default Users;
