export type SolutionNamespaceRscLimitItem = {
  id?: string;
  siteId?: string;
  name?: string;
  clusterId?: string;
  clusterName?: string;
  requestsCpu?: string;
  requestsMemory?: string;
  limitsCpu?: string;
  limitsMemory?: string;
  limitsGpu?: number;
  requestsStorage?: string;
  payload?: any;
};

export type SolutionNamespaceRscLimitData = {
  data?: SolutionNamespaceRscLimitItem;
  loading?: boolean;
};

export type SolutionNamespaceRscQuotasDetailItem = {
  id?: string;
  siteId?: string;
  name?: string;
  clusterId?: string;
  clusterName?: string;
  requestsCpu?: string;
  requestsMemory?: string;
  limitsCpu?: string;
  limitsMemory?: string;
  limitsGpu?: number;
  requestsStorage?: string;
  usedRequestsCpu?: string;
  usedRequestsMemory?: string;
  usedLimitsCpu?: string;
  usedLimitsMemory?: string;
  usedRequestsStorage?: string;
  requestsCpuStatus?: string[];
  requestsMemoryStatus?: string[];
  limitsCpuStatus?: string[];
  limitsMemoryStatus?: string[];
  requestsStorageStatus?: string[];
  payload?: any;
};

export type SolutionNamespaceRscQuotasDetailData = {
  data?: SolutionNamespaceRscQuotasDetailItem;
  loading?: boolean;
};

export type SolutionClusterNamespaceRscLimitData = {
  data?: SolutionNamespaceRscLimitItem[];
  loading?: boolean;
};

export enum SolutionNamespaceRscLimitItemField {
  NameSpace = 'name',
  ClusterName = 'clusterName',
  CPUReservation = 'requestsCpu',
  MemoryReservation = 'requestsMemory',
  CPULimit = 'limitsCpu',
  MemoryLimit = 'limitsMemory',
  GPULimit = 'limitsGpu',
  StorageLimit = 'requestsStorage',
  CPUReservationStatus = 'requestsCpuStatus',
  MemoryReservationStatus = 'requestsMemoryStatus',
  MemoryLimitStatus = 'limitsMemoryStatus',
  CPULimitStatus = 'limitsCpuStatus',
  StorageLimitStatus = 'requestsStorageStatus',
}
