import { generateApiPath } from 'src/utils';
import { createDatasetSlice, ResponseMapping } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import {
  API_JOBS_DEVICES_LIST,
  SOLUTIONS_STORE,
  SOLUTIONS_STORE_JOBS_DEVICES,
} from '../constants';

const api = ({ jobId, solutionId, sequence = '0', pageIndex, pageSize }) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: generateApiPath(API_JOBS_DEVICES_LIST, {
      jobId,
      solutionId,
      pageSize,
      offset: pageIndex,
      sequence,
    }),
  });

const solutionJobsDevicesSlice = createDatasetSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTIONS_STORE_JOBS_DEVICES,
  responseMapping: ResponseMapping.Default,
});

export default solutionJobsDevicesSlice;
