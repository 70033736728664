import React, { ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { SxProps } from 'src/theme/types';

type Props = SxProps & {
  children?: ReactNode;
};

const baseStyle: SxProps = {
  display: 'flex',
  gap: 2,
  alignItems: 'center',
  mb: 2,
};

function SolutionDeviceDeploymentNotification({ children, ...rest }: Props) {
  const style = { ...baseStyle, ...rest } as SxProps;
  return (
    <Typography variant="body2" color="primary" sx={style}>
      <InfoOutlinedIcon fontSize="small" color="primary" />
      {children}
    </Typography>
  );
}

export default SolutionDeviceDeploymentNotification;
