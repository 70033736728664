import { DefaultDatasetUnit } from 'src/redux/helpers';
import { CompanyUserRole, CompanyUserItem } from '../types';

export const mapCompanyUsersData = ({
  companyOwners,
  companyUsers,
}): DefaultDatasetUnit<CompanyUserItem[]> => {
  const mapUsers = (
    users: string[],
    role: CompanyUserRole
  ): CompanyUserItem[] => users.map((userId) => ({ userId, role }));

  return {
    data: [
      ...mapUsers(companyOwners, CompanyUserRole.Owner),
      ...mapUsers(companyUsers, CompanyUserRole.User),
    ],
  };
};
