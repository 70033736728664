import React, { forwardRef, ReactElement, Ref, useEffect } from 'react';
import TableContainer, {
  TableContainerProps,
} from '@mui/material/TableContainer';
import Pagination from 'src/components/Pagination';
import {
  PagingType,
  Row,
  Column,
  Value,
  SortHandler,
  SortOrder,
  SelectedItems,
} from './types';
import { PAGE_SIZE_OPTIONS } from './constants';
import DataTableBackdropRows from './components/DataTableBackdropRows';
import DataTableBlankRow from './components/DataTableBlankRow';
import DataTableContent from './components/DataTableContent';
import DataTableLoader from './components/DataTableLoader';
import {
  useFiltering,
  useGrouping,
  useRowsSelection,
  usePaging,
  useSorting,
} from './hooks';

type Props<TRow> = TableContainerProps & {
  data: TRow[];
  columns: Column<TRow>[];
  pageIndex?: number;
  pageSize?: number;
  onPageIndexChange?: (index: number) => void;
  onPageSizeChange?: (size: number) => void;
  onSelectionChange?: (items: Value[]) => void;
  onSelectItems?: (selectedItems: SelectedItems) => void;
  checkboxes?: boolean;
  loading?: boolean;
  paging?: PagingType | false;
  totalItems?: number;
  groupBy?: string;
  onSort?: SortHandler;
  sortable?: boolean;
  sortBy?: string;
  sortOrder?: SortOrder;
};

function DataTable<TRow extends Row = Row>(
  {
    columns: inputColumns,
    data: inputData,
    pageIndex,
    pageSize,
    paging = PagingType.Local,
    onPageIndexChange,
    onPageSizeChange,
    onSelectionChange,
    onSelectItems,
    checkboxes,
    loading,
    totalItems,
    groupBy,
    onSort,
    sortable = true,
    sortBy,
    sortOrder,
    ...rest
  }: Props<TRow>,
  ref: Ref<HTMLDivElement>
) {
  const noData = !loading && inputData.length === 0;

  const filteredData = useFiltering<TRow>({
    inputData,
    inputColumns,
  });
  const { sortedData, columns, ...rowsSorting } = useSorting<TRow>({
    inputData: filteredData,
    inputColumns,
    onSort,
    sortable,
    sortBy,
    sortOrder,
  });
  const { groupedData, groupedColumns, ...rowsGrouping } = useGrouping<TRow>({
    inputData: sortedData,
    inputColumns: columns,
    groupBy,
  });
  const { showPaging, data, ...pagingProps } = usePaging<TRow>({
    inputData: groupedData,
    pageIndex,
    pageSize,
    paging,
    onPageIndexChange,
    onPageSizeChange,
    totalItems,
  });
  const rowsSelection = useRowsSelection<TRow>({
    inputColumns: groupedColumns,
    checkboxes,
    data,
    onSelectionChange,
  });

  useEffect(() => {
    onSelectItems?.(rowsSelection.selectedItems);
  }, [rowsSelection.selectedItems]);

  return (
    <TableContainer data-testid="data-table" ref={ref} {...rest}>
      <DataTableContent<TRow>
        data={data}
        {...rowsSelection}
        {...rowsSorting}
        {...rowsGrouping}
      />
      {noData && (
        <DataTableBlankRow data-testid="no-data-row">
          No data to display
        </DataTableBlankRow>
      )}
      {loading && <DataTableBackdropRows rowsCount={data.length} />}
      {showPaging && (
        <Pagination
          mt={2}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          {...pagingProps}
        />
      )}
      {loading && <DataTableLoader />}
    </TableContainer>
  );
}

export default forwardRef(DataTable) as <TRow extends Row>(
  p: Props<TRow> & { ref?: Ref<HTMLDivElement> }
) => ReactElement;
