import Add from '@mui/icons-material/Add';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import { Box, IconButton, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import DataTable, {
  DataTableBoolCell,
  DataTableWrapper,
  DataTableWrapperActionButton,
  getActionsColConfig,
  useTableQueryParams,
} from 'src/components/DataTable';
import { isModalComplete } from 'src/components/ModalDialog';
import { useModal } from 'src/hooks';
import { useStoreUnitActions } from 'src/redux/helpers';
import { capitalizeFirstletter } from 'src/utils';
import { useUserRoles } from 'src/auth';
import { useSolutionUserRoles } from '../hooks';
import solutionsStore, {
  useSolutionAdlsSubscription,
  useSolutionCosmosSubscription,
  useSolutionDetails,
  useSolutionFileSubscription,
  useSolutionSubscriptions,
} from '../redux';
import {
  SolutionSubscriptionItemField,
  SolutionSubscribeTo,
  SolutionType,
  SolutionConsumerFeatures,
  SubscriptionType,
  SolutionCosmosSubscriptionItemField,
  SolutionSubscriptionItem,
  SolutionCosmosSubscriptionItem,
  SolutionAdlsSubscriptionItem,
} from '../types';

import { FileSubscription } from './components/FileSubscription';
import SolutionFileSubscriptionDeleteModal from './components/SolutionFileSubscriptionDeleteModal';
import SolutionFileSubscriptionEditContainer from './components/SolutionFileSubscriptionEditContainer';
import SolutionSubscriptionCreateContainer from './components/SolutionSubscriptionCreateContainer';
import SolutionSubscriptionsActions from './components/SolutionSubscriptionsActions';
import { mapAdlsApiToView, mapApiToView } from './utils';
import { AdlsSubscription } from './components/AdlsSubscription';
import SolutionAdlsSubscriptionEditContainer from './components/SolutionAdlsSubscriptionEditContainer';
import SolutionAdlsSubscriptionDeleteModal from './components/SolutionAdlsSubscriptionDeleteModal';
import SolutionFeatureInformation from '../components/SolutionFeatureInfomation';
import SolutionCosmosSubscriptionActions from './components/SolutionCosmosSubscriptionActions';

const columns = [
  {
    id: SolutionSubscriptionItemField.Name,
    title: 'Name',
  },
  {
    id: SolutionSubscriptionItemField.TypeList,
    title: 'Subscribe to',
  },
  {
    id: SolutionSubscriptionItemField.Enabled,
    title: 'Enabled',
    Component: DataTableBoolCell,
  },
  {
    id: SolutionSubscriptionItemField.Target,
    title: 'Target',
  },
  getActionsColConfig(SolutionSubscriptionsActions),
];

const cosmosColumns = [
  {
    id: SolutionCosmosSubscriptionItemField.Name,
    title: 'Name',
  },
  {
    id: SolutionCosmosSubscriptionItemField.DatabaseName,
    title: 'Database name',
  },
  {
    id: SolutionCosmosSubscriptionItemField.Container,
    title: 'Container',
  },
  {
    id: SolutionCosmosSubscriptionItemField.Partitionkey,
    title: 'Partition key',
  },
  getActionsColConfig(SolutionCosmosSubscriptionActions),
];

function SolutionSubscriptions() {
  const { solutionId, solutionType } = useParams();
  const { isSolutionEditor } = useSolutionUserRoles();
  const navigate = useNavigate();
  const { isPlatformReader, isPlatformEngineer } = useUserRoles();
  const { paging } = useTableQueryParams();
  const [data, loading] = useSolutionSubscriptions();
  const [fileData, fileLoading] = useSolutionFileSubscription();
  const [adlsData, adlsLoading] = useSolutionAdlsSubscription();
  const [cosmosData, cosmosLoading] = useSolutionCosmosSubscription();
  const newCosmosData = cosmosData?.map((item) => {
    let newData: SolutionCosmosSubscriptionItem = item;
    if (item.filterCondition !== null || undefined) {
      const filterCondition = capitalizeFirstletter(
        item.filterCondition?.toLowerCase()
      );
      newData = {
        ...newData,
        filterCondition,
      };
    }
    return newData;
  });
  let newAdlsData: SolutionAdlsSubscriptionItem = adlsData;
  if (adlsData) {
    if (adlsData.filterCondition !== null || undefined) {
      const filterCondition =
        adlsData.filterCondition && adlsData.filterCondition.length > 0
          ? capitalizeFirstletter(adlsData?.filterCondition?.toLowerCase())
          : adlsData.filterCondition;
      newAdlsData = {
        ...newAdlsData,
        filterCondition,
      };
    }
  }

  const [solutionDetails] = useSolutionDetails();
  const { storageEnabled } = solutionDetails?.features?.storageSetup || {};
  const isFileSubscriptionService =
    solutionDetails?.solutionConsumerFeatures?.includes(
      SolutionConsumerFeatures.IsFileSubscriptionService
    ) || solutionDetails?.subscriptionType?.includes(SubscriptionType.Files);
  const isMessageSubscriptionService =
    solutionDetails?.subscriptionType?.includes(SubscriptionType.Messages);

  const actions = useStoreUnitActions(solutionsStore);

  const callAllSubscriptions = () => {
    actions.subscriptions(solutionId);
    actions.cosmosSubscriptionById(solutionId);
    actions.fileSubscriptionById(solutionId);
    actions.adlsSubscriptionById(solutionId);
  };

  const [CreateSubscriptionDialog, showCreateSubscriptionDialog] = useModal(
    SolutionSubscriptionCreateContainer,
    {
      storageEnabled,
      onClose: (event, reason) =>
        isModalComplete(reason) && callAllSubscriptions(),
    }
  );

  const [EditFileSubscriptionDialog, showEditFileSubscriptionDialog] = useModal(
    SolutionFileSubscriptionEditContainer,
    {
      item: mapApiToView(fileData?.length > 0 ? fileData[0] : undefined),
      onClose: (event, reason) =>
        isModalComplete(reason) && actions.fileSubscriptionById(solutionId),
    }
  );

  const [DeleteFileSubscriptionDialog, showDeleteFileSubscriptionDialog] =
    useModal(SolutionFileSubscriptionDeleteModal, {
      item: fileData[0] || undefined,
      onClose: (reason) =>
        isModalComplete(reason) && actions.fileSubscriptionById(solutionId),
    });

  const [EditAdlsSubscriptionDialog, showEditAdlsSubscriptionDialog] = useModal(
    SolutionAdlsSubscriptionEditContainer,
    {
      item: mapAdlsApiToView(newAdlsData),
      onClose: (reason) =>
        isModalComplete(reason) && actions.adlsSubscriptionById(solutionId),
    }
  );

  const [DeleteAdlsSubscriptionDialog, showDeleteAdlsSubscriptionDialog] =
    useModal(SolutionAdlsSubscriptionDeleteModal, {
      item: newAdlsData || undefined,
      onClose: (event, reason) =>
        isModalComplete(reason) && actions.adlsSubscriptionById(solutionId),
    });

  const subscribeTo = data?.map((item) => {
    let newData: SolutionSubscriptionItem = item;
    if (item.type) {
      const types = item.type
        .map((type) => {
          if (type === SolutionSubscribeTo.PlatformEvent)
            return 'Platform event';
          return capitalizeFirstletter(type);
        })
        .toString();
      newData = {
        ...newData,
        typeList: [types],
      };
    }
    if (item.filterCondition !== null || undefined) {
      const filterCondition = capitalizeFirstletter(
        item.filterCondition?.toLowerCase()
      );
      newData = {
        ...newData,
        filterCondition,
      };
    }
    return newData;
  });

  useEffect(() => {
    actions.subscriptions(solutionId);
    actions.cosmosSubscriptionById(solutionId);
    actions.fileSubscriptionById(solutionId);
    actions.adlsSubscriptionById(solutionId);
  }, [solutionId]);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: '40px' }}
      >
        <Typography
          variant="body2"
          style={{
            fontSize: '18px',
            fontFamily: 'Stolzl',
            color: '#242424',
          }}
        >
          Subscriptions
        </Typography>
        {solutionType === SolutionType.Industrial && (
          <Box>
            <IconButton
              size="small"
              title="Help center"
              onClick={() =>
                navigate('/help-center/docs/IndustrialSetup/Subcriptionsetup/')
              }
            >
              <HelpOutlineOutlinedIcon fontSize="small" />
            </IconButton>
          </Box>
        )}
      </Box>
      <DataTableWrapper
        title="Message Subscriptions"
        titleStyle={{ fontSize: '16px', fontWeight: 'bold' }}
        titleVariant="body1"
        actionsPanel={
          <DataTableWrapperActionButton
            startIcon={<Add />}
            disabled={!isSolutionEditor}
            onClick={showCreateSubscriptionDialog}
          >
            New subscription
          </DataTableWrapperActionButton>
        }
      >
        {!isMessageSubscriptionService &&
        solutionType === SolutionType.Industrial ? (
          <SolutionFeatureInformation
            featureInfo="Message subscription is disabled since this feature was not opted
            during solution onboarding. To start accessing it, please edit the
            solution and select the feature."
          />
        ) : (
          <DataTable
            data={subscribeTo}
            columns={columns}
            loading={loading}
            {...paging}
          />
        )}
        {CreateSubscriptionDialog}
      </DataTableWrapper>
      <Box>
        <Typography
          variant="body2"
          style={{
            fontWeight: 'bold',
            marginTop: '20px',
            marginBottom: '10px',
          }}
        >
          File Subscription
        </Typography>
        {!fileLoading && fileData.length > 0 ? (
          <>
            {isFileSubscriptionService && (
              <Box
                style={{ display: 'flex', justifyContent: 'flex-end' }}
                justifyContent="row"
                alignItems="start"
                marginBottom="20px"
              >
                <DataTableWrapperActionButton
                  onClick={showEditFileSubscriptionDialog}
                  startIcon={<Edit />}
                  style={{ marginRight: '15px' }}
                  disabled={isPlatformReader || isPlatformEngineer}
                >
                  Edit
                </DataTableWrapperActionButton>
                <DataTableWrapperActionButton
                  onClick={showDeleteFileSubscriptionDialog}
                  startIcon={<DeleteOutline />}
                  color="error"
                  sx={{ borderColor: '#DB1E37' }}
                  disabled={isPlatformReader || isPlatformEngineer}
                >
                  Delete
                </DataTableWrapperActionButton>
              </Box>
            )}
            <Box style={{ display: 'flex' }} justifyContent="space-between">
              <FileSubscription
                storageEnabled={storageEnabled}
                data={fileData[0]}
              />
            </Box>
          </>
        ) : (
          <Box>
            <Typography variant="body2" color="#A5A5A5" align="center">
              No data to display
            </Typography>
          </Box>
        )}

        {EditFileSubscriptionDialog}
        {DeleteFileSubscriptionDialog}
      </Box>
      {solutionType === SolutionType.Industrial && (
        <Box>
          <Typography
            variant="body2"
            style={{
              fontWeight: 'bold',
              marginTop: '25px',
            }}
          >
            ADLS Subscription
          </Typography>
          {!adlsLoading && newAdlsData.id ? (
            <>
              <Box
                style={{ display: 'flex', justifyContent: 'flex-end' }}
                justifyContent="row"
                alignItems="start"
                marginBottom="20px"
              >
                <DataTableWrapperActionButton
                  onClick={showEditAdlsSubscriptionDialog}
                  startIcon={<Edit />}
                  style={{ marginRight: '15px' }}
                  disabled={isPlatformReader || isPlatformEngineer}
                >
                  Edit
                </DataTableWrapperActionButton>
                <DataTableWrapperActionButton
                  onClick={showDeleteAdlsSubscriptionDialog}
                  startIcon={<DeleteOutline />}
                  color="error"
                  sx={{ borderColor: '#DB1E37' }}
                  disabled={isPlatformReader || isPlatformEngineer}
                >
                  Delete
                </DataTableWrapperActionButton>
              </Box>
              <Box style={{ display: 'flex' }} justifyContent="space-between">
                <AdlsSubscription data={newAdlsData} />
              </Box>
            </>
          ) : (
            <Box>
              <Typography variant="body2" color="#A5A5A5" align="center">
                No data to display
              </Typography>
            </Box>
          )}

          {EditAdlsSubscriptionDialog}
          {DeleteAdlsSubscriptionDialog}
        </Box>
      )}
      {solutionType === SolutionType.Industrial && (
        <DataTableWrapper
          title="Cosmos Subscription"
          titleStyle={{
            fontSize: '16px',
            fontWeight: 'bold',
            marginTop: '20px',
          }}
          titleVariant="body1"
          searchBar={true}
        >
          <DataTable
            data={newCosmosData}
            columns={cosmosColumns}
            loading={cosmosLoading}
            {...paging}
          />
        </DataTableWrapper>
      )}
    </>
  );
}

export default SolutionSubscriptions;
