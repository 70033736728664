import React from 'react';
import { SolutionDeviceConfigModelType } from '../../types';

type Props = {
  value?: SolutionDeviceConfigModelType;
};

function SolutionDeviceTemplateType({ value }: Props) {
  const templateMap: Record<SolutionDeviceConfigModelType, string> = {
    [SolutionDeviceConfigModelType.ContextModel]: 'Context model',
    [SolutionDeviceConfigModelType.DeviceTemplate]: 'Device template',
    [SolutionDeviceConfigModelType.EdgeDeviceTemplate]: 'Edge device template',
  };
  return <>{templateMap[value as SolutionDeviceConfigModelType]}</>;
}

export default SolutionDeviceTemplateType;
