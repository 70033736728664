import { useState } from 'react';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { generateApiPath, loadDataFromStorage } from 'src/utils';
import {
  API_SUBSCRIPTION_ITEM,
  API_SUBSCRIPTION_ITEM_IOTH,
} from '../../constants';
import {
  SolutionSubscriptionItem,
  SolutionSubscriptionType,
  SolutionType,
} from '../../types';
import { getSubscriptionItemReqParams } from '../utils';
import SolutionSubscriptionEditModal from './SolutionSubscriptionEditModal';

type Props = {
  item: SolutionSubscriptionItem;
  open: boolean;
  onClose: ModalCloseHandler;
};

function SolutionSubscriptionEditContainer({ item, open, onClose }: Props) {
  const { showAlert } = useAlert();
  const solutionType = loadDataFromStorage('solutionType');
  const [subscriptionType, setSubscriptionType] = useState(
    SolutionSubscriptionType.Messages
  );
  const [handleSubmit, loading] = useStatefulApi<SolutionSubscriptionItem>(
    (params) =>
      pepApiRequest({
        method: RequestMethod.Put,
        url: generateApiPath(
          solutionType === SolutionType.ioTH
            ? API_SUBSCRIPTION_ITEM_IOTH
            : API_SUBSCRIPTION_ITEM,
          {
            solutionId: item.solutionId,
            subscriptionId: item.id,
          }
        ),
        params: getSubscriptionItemReqParams(params),
      }),
    () => {
      showAlert({
        type: AlertType.Success,
        title: 'Subscription updated',
        text: 'Subscription has been updated successfully.',
      });
      onClose(null, ModalCloseReason.completeAction);
    }
  );

  return (
    <SolutionSubscriptionEditModal
      item={item}
      title={`Edit subscription: ${item?.name}`}
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      loading={loading}
      subscriptionType={subscriptionType}
      setSubscriptionType={setSubscriptionType}
      isEditMsgSubscription={true}
    />
  );
}

export default SolutionSubscriptionEditContainer;
