import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Add from '@mui/icons-material/Add';
import { useModal } from 'src/hooks';
import DataTable, {
  DataTableWrapper,
  DataTableWrapperActionButton,
  getActionsColConfig,
} from 'src/components/DataTable';
import { useStoreUnitActions } from 'src/redux/helpers';
import { isModalComplete } from 'src/components/ModalDialog';
import SiteItemStatus from 'src/pages/Sites/components/SiteItemStatus';
import { useUserRoles } from 'src/auth';
import solutionsStore, { useSolutionSitesList } from '../redux';
import SolutionSiteListAction from './components/SolutionSiteListAction';
import { SolutionSiteItemField } from '../types';
import SolutionSitesAssignModal from './components/SolutionSitesAssignModal';

const columns = [
  {
    id: SolutionSiteItemField.SiteName,
    title: 'Site Name',
  },
  {
    id: SolutionSiteItemField.ClusterNames,
    title: 'Cluster',
  },
  {
    id: SolutionSiteItemField.Sector,
    title: 'Sector',
  },
  {
    id: SolutionSiteItemField.Description,
    title: 'Description',
  },
  {
    id: SolutionSiteItemField.Status,
    title: 'Status',
    Component: SiteItemStatus,
  },
  getActionsColConfig(SolutionSiteListAction),
];

export default function SoluitionSites() {
  const [data, loading] = useSolutionSitesList();
  const { solutionId } = useParams();
  const { isPlatformReader } = useUserRoles();
  const actions = useStoreUnitActions(solutionsStore);
  useEffect(() => {
    actions.sites(solutionId);
  }, []);
  const onClose = (event, reason) => {
    isModalComplete(reason);
    actions.sites(solutionId);
  };
  const [AssignNewSiteDialog, showAssignNewSiteDialog] = useModal(
    SolutionSitesAssignModal,
    {
      onClose,
    }
  );
  const sitesData = data.map((site) => ({
    ...site,
    clusterNames:
      site.clusters?.map((cluster) => cluster.name).toString() || '',
  }));

  return (
    <DataTableWrapper
      url="/help-center/docs/IndustrialSetup/AdditionalPoints/SolutionSiteMapping/"
      showUrl={true}
      infoTitle="Help center"
      actionsPanel={
        <DataTableWrapperActionButton
          onClick={showAssignNewSiteDialog}
          startIcon={<Add />}
          disabled={isPlatformReader}
        >
          Assign New
        </DataTableWrapperActionButton>
      }
    >
      <DataTable data={sitesData} loading={loading} columns={columns} />
      {AssignNewSiteDialog}
    </DataTableWrapper>
  );
}
