import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';

function SearchBarCloseBtn(props: ButtonProps) {
  return (
    <Button
      sx={{
        px: ({ spacing }) => spacing(2),
      }}
      {...props}
    >
      Close
    </Button>
  );
}

export default SearchBarCloseBtn;
