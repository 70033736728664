import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import DataTable, {
  useTableQueryParams,
  getActionsColConfig,
} from 'src/components/DataTable';
import { useModal } from 'src/hooks/modalHooks';
import { useDatasetUnitAction } from 'src/redux/helpers';
import PageCardLayout from 'src/components/PageCardLayout';
import { isModalComplete } from 'src/components/ModalDialog';
import DataTableContext from 'src/components/DataTable/components/DataTableContext';
import DataTableSearchBar from 'src/components/DataTable/components/DataTableSearchBar';
import GuidedTour from 'src/components/GuidedTour/guidedTour';
import { useUserRoles } from 'src/auth';
import { ReactourStep } from 'reactour';
import otpStore from '../redux/otpStore';
import { useOtpList } from '../redux';
import { OtpItemField } from '../types';
import OtpListTimezone from './OtpListTimezone';
import OtpListActions from './OtpListActions';
import AddOtpModalContainer from './AddOtpModalContainer';

const tourButtonStyle = {
  textDecoration: 'underline',
  color: '#0047BA',
  cursor: 'pointer',
  fontWeight: 500,
  paddingLeft: '3px',
};

const contentStyle = {
  color: '#FFF',
  fontSize: '12px',
  fontFamily: 'Roboto',
  fontWeight: '400',
  opacity: '1',
};

const iconStyle = {
  width: '16px',
  height: '16px',
};

const columns = [
  { id: OtpItemField.Key, title: 'Key' },
  {
    id: OtpItemField.UtcTimeShift,
    title: 'Timezone',
    Component: OtpListTimezone,
  },
  {
    id: OtpItemField.RecipientEmail,
    title: 'Deliver to email',
  },
  getActionsColConfig(OtpListActions),
];

const performActions = [
  {
    icon: <EditOutlinedIcon sx={iconStyle} />,
    iconContent: 'Edit the details of an existing key',
  },
  {
    icon: <DeleteOutlinedIcon sx={iconStyle} />,
    iconContent: 'Delete the existing key',
  },
  {
    icon: <ForwardToInboxIcon sx={iconStyle} />,
    iconContent:
      'If the automated email is not received or accidentally deleted by the user, they can manually trigger it through this action icon.',
  },
];

const steps: ReactourStep[] = [
  {
    selector: '.add-keys',
    content: (
      <>
        <Typography color="#FFF" fontSize="18px">
          Add New Keys
        </Typography>
        <br />
        <Typography sx={contentStyle}>
          To add a new key that is used to generate the OTP and QR code. Once
          the key details are saved, an automated email will be sent to the user
          with the key, OTP, and QR code, which will be valid for 24 hours.
        </Typography>
      </>
    ),
    position: 'bottom',
    style: {
      backgroundColor: '#013182',
    },
  },
  {
    selector: '.search-bar',
    content: (
      <>
        <Typography color="#FFF" fontSize="18px">
          Search Keys
        </Typography>
        <br />
        <Typography sx={contentStyle}>
          Search for an existing key by name and email ID.
        </Typography>
      </>
    ),
    position: 'bottom',
    style: {
      backgroundColor: '#013182',
    },
  },
  {
    selector: '.data-table-action',
    content: (
      <>
        <Typography color="#FFF" fontSize="18px">
          Perform actions
        </Typography>
        <br />
        {performActions?.map((action: any) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              color: '#FFF',
              paddingBottom: '5px',
            }}
          >
            <Typography sx={{ paddingRight: '10px' }}>{action.icon}</Typography>
            <Typography sx={contentStyle}>{action.iconContent}</Typography>
          </Box>
        ))}
      </>
    ),
    position: 'bottom',
    style: {
      backgroundColor: '#013182',
    },
  },
];

function OtpList() {
  const [data, loading] = useOtpList();
  const { paging, search, sorting } = useTableQueryParams();
  const action = useDatasetUnitAction(otpStore);
  const { isPlatformReader } = useUserRoles();
  const [AddOtpDialog, showAddOtpDialog] = useModal(AddOtpModalContainer, {
    onClose: (event, reason) => isModalComplete(reason) && action(),
  });
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [activeStep, setActiveStep] = useState<number | null>(null);

  useEffect(() => {
    action();
  }, []);

  const startTour = (event) => {
    event?.preventDefault();
    setIsTourOpen(true);
    setActiveStep(0);
  };

  const handleClose = () => {
    setIsTourOpen(false);
    setActiveStep(null);
  };

  return (
    <DataTableContext {...search}>
      <PageCardLayout
        title="OTP Management"
        content={
          <>
            During device provisioning, the device requires either an OTP or a
            QR code. This page helps to generate and associate to a key.
            <Typography
              variant="small2"
              onClick={startTour}
              style={tourButtonStyle}
            >
              Take a tour
            </Typography>
          </>
        }
        actionsPanel={
          <>
            <DataTableSearchBar
              className={activeStep === 1 ? 'search-bar active' : 'search-bar'}
            />
            <Button
              data-testid="add-keys-button"
              className={activeStep === 0 ? 'add-keys active' : 'add-keys'}
              onClick={showAddOtpDialog}
              variant="outlined"
              size="small"
              startIcon={<AddIcon />}
              disabled={isPlatformReader}
            >
              Add keys
            </Button>
          </>
        }
      >
        {data && (
          <DataTable
            data={data}
            columns={columns}
            loading={loading}
            groupBy={OtpItemField.RecipientEmail}
            {...paging}
            {...sorting}
          />
        )}
        {AddOtpDialog}
      </PageCardLayout>
      <GuidedTour
        steps={steps}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        isOpen={isTourOpen}
        onClose={handleClose}
        className={activeStep === 2 ? 'tour-card-with-arrow' : ''}
      />
    </DataTableContext>
  );
}

export default OtpList;
