import React, {
  useEffect,
  useState,
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  ReactNode,
} from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import SearchIcon from '@mui/icons-material/Search';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  Chip,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SxProps,
  TextField,
} from '@mui/material';
import { Close, ExpandLess, ExpandMore } from '@mui/icons-material';
import AutoCompleteBoxDisplayValueItem from '../AutoCompleteBox/components/AutoCompleteBoxDisplayValueItem';

interface Cluster {
  id: string;
  name: string;
  status: string;
}

interface Item {
  id: string;
  name: string;
  sector: string;
  description: string;
  createdBy: string;
  modifiedBy: string;
  status: string;
  solutionIds?: string[];
  cluster?: Cluster;
  clusters?: Cluster[];
  observability?: string;
}

interface ParentLabelAndChildren {
  parentId: string;
  parentLabel: string;
  children: {
    label: string;
    value: string;
  }[];
}

type Value = { [key: string]: boolean };

type Props = FormControlProps & {
  onChange: (value) => void;
  selectedIds?: any;
  levelOptions?: {
    parentId: string;
    parentLabel: string;
    children: { label: string; value: string }[];
  }[];
  label?: string;
  helperText?: ReactNode;
  loading?: boolean;
  multiple?: boolean;
};
const labelStyle = {
  backgroundColor: 'white',
  px: 0.5,
};
const inputStyle: SxProps = {
  '&:before': {
    borderColor: 'primary.main',
  },
  '&:hover:not(.Mui-disabled):before': {
    borderColor: 'primary.main',
  },
};
const displayValueStyle = {
  maxHeight: '13rem',
  overflow: 'auto',
  overflowX: 'hidden',
};
const getContainerStyle = (error?: boolean): SxProps => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  p: 1.3,
  mr: 2,
  border: 1,
  borderColor: error ? 'error.main' : 'neutral.dark',
  borderRadius: 0.4,
});

const TreeSelectwithTextfield = (
  {
    id = '',
    selectedIds,
    loading,
    label,
    placeholder = 'Search here...',
    multiple,
    levelOptions = [],
    onChange,
    error,
    helperText,
    ...rest
  }: Props,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const [data, setData] = useState<ParentLabelAndChildren[]>([]);
  const [expandedParents, setExpandedParents] = useState<{
    [key: string]: boolean;
  }>({});
  const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [labelList, setLabelList] = useState<any[]>([]);
  const labels: any[] = [];
  const noOptions = !loading && !data.length;

  useEffect(() => {
    setData(levelOptions);
    const initialChecked: { [key: string]: boolean } = {};
    levelOptions.forEach((parent) => {
      if (selectedIds?.find((item) => item === parent.parentId)) {
        initialChecked[parent.parentId] = true;
      } else {
        initialChecked[parent.parentId] = false;
      }

      parent.children.forEach((child) => {
        if (selectedIds?.find((item) => item === child.value)) {
          initialChecked[child.value] = true;
        } else {
          initialChecked[child.value] = false;
        }
      });
    });
    setCheckedItems(initialChecked);
    const initialExpand = levelOptions.reduce((acc, parent) => {
      acc[parent.parentId] = true;
      return acc;
    }, {});
    setExpandedParents(initialExpand);
  }, [levelOptions]);

  const getChipsLabels = () => {
    data.map((parent) => {
      if (parent.children.length === 0 && checkedItems[parent.parentId]) {
        labels.push({ label: parent.parentLabel, value: parent.parentId });
      } else {
        if (
          parent.children.every((child) => checkedItems[child.value]) &&
          checkedItems[parent.parentId]
        ) {
          labels.push({ label: parent.parentLabel, value: parent.parentId });
          return null;
        }
        const childLabels = parent.children.filter(
          (child) => checkedItems[child.value]
        );
        childLabels.map((child) =>
          labels.push({ value: child.value, label: child.label })
        );
      }
      return null;
    });
    return labels;
  };

  useEffect(() => {
    const list: any[] = getChipsLabels();
    setLabelList(list);
  }, [checkedItems]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    parentId: string,
    value: string
  ) => {
    const updatedChecked = { ...checkedItems };
    if (value === 'parent') {
      updatedChecked[parentId] = event.target.checked;
      data
        .find((parent) => parent.parentId === parentId)!
        .children.forEach((child) => {
          updatedChecked[child.value] = event.target.checked;
        });
    } else {
      updatedChecked[value] = event.target.checked;
      const allChildrenChecked = data
        .find((parent) => parent.parentId === parentId)!
        .children.every((child) => updatedChecked[child.value]);
      updatedChecked[parentId] = allChildrenChecked;
    }
    setCheckedItems(updatedChecked);
    onChange(updatedChecked);
  };

  const handleDelete = (item) => {
    const updatedSelectedItems = { ...checkedItems };
    updatedSelectedItems[item] = false;

    const parent = data.find((parent) => parent.parentId === item);
    if (parent) {
      updatedSelectedItems[parent.parentId] = false;
      parent.children.forEach((child) => {
        updatedSelectedItems[child.value] = false;
      });
    } else {
      data.forEach((parent) => {
        if (parent.children.some((child) => child.value === item)) {
          updatedSelectedItems[parent.parentId] = parent.children.some(
            (child) => updatedSelectedItems[child.value]
          );
        }
      });
    }

    setCheckedItems(updatedSelectedItems);
    onChange(updatedSelectedItems);
    const updatedLabel = getChipsLabels();
    setLabelList(updatedLabel);
  };

  const toggleExpand = (parentId: string) => {
    setExpandedParents((prevExpanded) => ({
      ...prevExpanded,
      [parentId]: !prevExpanded[parentId],
    }));
  };

  return (
    <FormControl data-testid="treeSelect-box" error={error} {...rest}>
      <InputLabel id="demo-simple-select-label" sx={{ paddingBottom: '0px' }}>
        {label}
      </InputLabel>
      <Select
        data-testid="treeselect-box-input"
        ref={ref}
        label={label}
        value={labelList}
        sx={inputStyle}
        required
        id={id}
        aria-checked
        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {labelList.map((item) => (
              <Chip
                key={item.value}
                label={item.label}
                onMouseDown={(event) => event.stopPropagation()}
                onDelete={(event) => {
                  event.stopPropagation();
                  handleDelete(item.value);
                }}
              />
            ))}
          </Box>
        )}
        placeholder={placeholder}
        onKeyDown={(event) => {
          if (event.code === 'Enter') {
            event.preventDefault();
          }
        }}
      >
        {noOptions && <MenuItem disabled>No available options</MenuItem>}
        {loading && <MenuItem disabled>Loading...</MenuItem>}
        {data.map((parent) => (
          <div key={parent.parentId}>
            <IconButton onClick={() => toggleExpand(parent.parentId)}>
              {expandedParents[parent.parentId] ? (
                <ExpandMore />
              ) : (
                <ExpandLess />
              )}
            </IconButton>
            <FormControlLabel
              label={parent.parentLabel}
              control={
                <Checkbox
                  checked={!!checkedItems[parent.parentId]}
                  indeterminate={
                    parent.children.some(
                      (child) => checkedItems[child.value]
                    ) &&
                    !parent.children.every((child) => checkedItems[child.value])
                  }
                  onChange={(event) =>
                    handleChange(event, parent.parentId, 'parent')
                  }
                />
              }
            />
            {expandedParents[parent.parentId] && (
              <Box sx={{ display: 'flex', flexDirection: 'column', ml: 8 }}>
                {parent.children.map((child) => (
                  <FormControlLabel
                    key={child.value}
                    label={child.label}
                    control={
                      <Checkbox
                        checked={!!checkedItems[child.value]}
                        onChange={(event) =>
                          handleChange(event, parent.parentId, child.value)
                        }
                      />
                    }
                  />
                ))}
              </Box>
            )}
          </div>
        ))}
      </Select>
    </FormControl>
  );
};

export default React.forwardRef(TreeSelectwithTextfield);
