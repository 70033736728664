import React from 'react';
import { RouterBreadcrumbs } from 'src/router';
import OtpList from './components/OtpList';

function Otp() {
  return (
    <>
      <RouterBreadcrumbs />
      <OtpList />
    </>
  );
}

export default Otp;
