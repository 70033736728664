import { generatePath } from 'react-router-dom';
import { createDatasetSlice } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import { loadDataFromStorage } from 'src/utils';
import {
  API_JOBS_LIST,
  API_JOBS_LIST_IOTH,
  SOLUTIONS_STORE,
  SOLUTIONS_STORE_JOBS,
} from '../constants';
import { mapSolutionJobsData } from './utils';
import { SolutionType } from '../types';

const api = ({ solutionId, pageSize, pageIndex, sortBy, sortOrder }) => {
  const solutionType = loadDataFromStorage('solutionType');
  return pepApiRequest({
    method: RequestMethod.Get,
    url: generatePath(
      solutionType?.toUpperCase() === SolutionType.ioTH.toUpperCase()
        ? API_JOBS_LIST_IOTH
        : API_JOBS_LIST,
      { solutionId }
    ),
    queryParams: { pageSize, pageNumber: pageIndex, sortBy, sortOrder },
  }).then(mapSolutionJobsData);
};

const solutionJobsSlice = createDatasetSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTIONS_STORE_JOBS,
});

export default solutionJobsSlice;
