import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { SxProps } from 'src/theme/types';
import { FormArrayRowProps, FormFieldDefaultValue } from '../types';
import FormControl from './FormControl';

const style: SxProps = {
  display: 'flex',
  gap: 2,
};

function FormArrayRow<T = FormFieldDefaultValue>({
  childFields,
  control,
  index,
  name,
  remove,
  canRemove = true,
  ...rest
}: FormArrayRowProps<T>) {
  return (
    <Box sx={style} {...rest}>
      {childFields &&
        childFields.map(({ name: itemName, ...field }) => (
          <FormControl
            key={itemName}
            control={control}
            name={`${name}.${index}.${itemName}`}
            fullWidth
            {...field}
            {...rest}
          />
        ))}
      <IconButton
        sx={{ mt: 0.8, mb: 'auto' }}
        size="small"
        onClick={() => remove(index)}
        disabled={!canRemove}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Box>
  );
}

export default FormArrayRow;
