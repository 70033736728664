import { createDatasetSlice } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import { generateApiPath } from 'src/utils';
import {
  API_DEVICE_DEP_RELEASES_LIST,
  SOLUTIONS_STORE,
  SOLUTIONS_STORE_DEVICE_DEP_RELEASES,
} from '../constants';
import { mapDeviceDepReleasesData } from './utils';

const api = ({ solutionId, deviceType }) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: generateApiPath(API_DEVICE_DEP_RELEASES_LIST, { solutionId }),
    queryParams: deviceType && { deviceType },
  }).then(mapDeviceDepReleasesData);

const solutionDeviceDepReleasesSlice = createDatasetSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTIONS_STORE_DEVICE_DEP_RELEASES,
});

export default solutionDeviceDepReleasesSlice;
