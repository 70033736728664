import TextField from '@mui/material/TextField';
import compact from 'lodash/compact';
import * as Yup from 'yup';
import Select from 'src/components/Select/Select';
import MaskedInput from 'src/components/MaskedInput';
import { SolutionCosmosSubscriptionItemField } from '../types';
import { getSubscriptionFilterFields } from './formFields';
import SolutionSubscriptionEditFormRow from './components/SolutionSubscriptionEditFormRow';
import { SUBSCRIBE_FILTER_CONDITION_OPTIONS } from './constants';

export const getCosmosSubscriptionEditFormFields = (
  filters: any[],
  handlePaste?: () => void
) =>
  compact([
    {
      name: SolutionCosmosSubscriptionItemField.CosmosSubscriptionName,
      label: 'Subscription Name',
      Component: TextField,
      placeholder: 'Enter subscription name',
      required: true,
      validation: Yup.string().required('Subscription name is required'),
    },
    {
      name: SolutionCosmosSubscriptionItemField.ConnectionString,
      label: 'Connection String',
      Component: MaskedInput,
      placeholder: 'Enter connection string',
      required: true,
      icon: true,
      validation: Yup.string().required('Connection String is required'),
      onClickPasteIcon: handlePaste,
    },
    {
      name: SolutionCosmosSubscriptionItemField.DatabaseName,
      label: 'Database Name',
      Component: TextField,
      placeholder: 'Enter name',
      required: true,
      validation: Yup.string().required('Database Name is required'),
    },
    {
      name: SolutionCosmosSubscriptionItemField.Container,
      label: 'Container',
      Component: TextField,
      placeholder: 'Enter container',
      required: true,
      validation: Yup.string().required('Container is required'),
    },
    {
      name: SolutionCosmosSubscriptionItemField.Partitionkey,
      label: 'Partition Key',
      Component: TextField,
      placeholder: 'Enter the partition key',
      required: true,
      validation: Yup.string().required('Partition key is required'),
    },
    filters?.length > 1 && {
      name: SolutionCosmosSubscriptionItemField.CosmosFilterCondition,
      label: 'Apply if',
      placeholder: 'Select the value',
      Component: Select,
      options: SUBSCRIBE_FILTER_CONDITION_OPTIONS,
      initialValue: 'And',
      validation: Yup.string().required('Operator is required'),
      required: true,
      sx: { width: ({ spacing }) => spacing(30) },
    },
    {
      name: SolutionCosmosSubscriptionItemField.CosmosFilters,
      appendLabel: 'Filter',
      childFields: getSubscriptionFilterFields(),
      RowComponent: SolutionSubscriptionEditFormRow,
      maxRows: 30,
    },
  ]);
