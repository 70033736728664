import * as Yup from 'yup';
import { FormField } from './types';

export const getArrayValidationSchema = (fields: FormField[]) =>
  Yup.array().of(getValidationSchema(fields));

export const getValidationSchema = (fields: FormField[]) =>
  Yup.object().shape(
    fields.reduce(
      (acc, { name, validation, ...field }) => ({
        ...acc,
        [name]:
          'childFields' in field
            ? getArrayValidationSchema(field.childFields || [])
            : validation,
      }),
      {}
    )
  );

export const getInitialFieldValue = ({
  initialValue,
  type,
  ...field
}: Partial<FormField>) => {
  if (initialValue !== undefined) {
    return initialValue;
  }
  if ('childFields' in field) {
    return [];
  }
  switch (type) {
    case 'checkbox-group':
      return [];
    case 'checkbox':
      return false;
    case 'file':
      return null;
    default:
      return '';
  }
};

export const getInitialValues = (fields?: FormField[]) =>
  fields &&
  fields.reduce(
    (acc, { name, ...field }) => ({
      ...acc,
      [name]: getInitialFieldValue(field),
    }),
    {}
  );

export const getCombinedHandler =
  (mainHandler, extraHandler, formContext) => (event) => {
    if (mainHandler) {
      mainHandler(event);
    }
    if (extraHandler) {
      extraHandler(event, formContext);
    }
  };

export const getFilteredValues = <T>(fields: FormField[], values: T): T =>
  fields.reduce((acc, { name }) => ({ ...acc, [name]: values[name] }), {}) as T;
