import { baseTheme } from '../baseTheme';

const { breakpoints, spacing } = baseTheme;

export default {
  defaultProps: {
    maxWidth: 'xxl',
  },
  styleOverrides: {
    root: {
      [breakpoints.up('sm')]: {
        padding: spacing(0, 2),
      },
    },
  },
};
