import { useNavigate, useParams } from 'react-router-dom';
import { generateApiPath } from 'src/utils';
import PageCardLayout from 'src/components/PageCardLayout';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { useStoreUnitActions } from 'src/redux/helpers';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import SiteEditForm from './components/SiteEditForm';
import { API_SITE as API_SITE_UPDATE } from '../constants';
import sitesStore, { useSiteDetails } from '../redux';

function SiteEdit() {
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const { siteId } = useParams();
  const goBack = () => navigate(-1);
  const [item, loading] = useSiteDetails();
  const displayName = item?.name;
  const actions = useStoreUnitActions(sitesStore);

  const [handleSubmit, saving] = useStatefulApi(
    (params) =>
      pepApiRequest({
        method: RequestMethod.Put,
        url: generateApiPath(API_SITE_UPDATE, {
          siteId,
        }),
        params,
      }),
    () => {
      showAlert({
        type: AlertType.Info,
        title: 'Site saved.',
        text: 'Site has been modified successfully.',
      });
      goBack();
      actions.details(siteId);
    }
  );

  return (
    <PageCardLayout
      loading={loading || saving}
      title={displayName && `Edit site: ${displayName}`}
    >
      <SiteEditForm
        item={item}
        onCancel={goBack}
        onSubmit={handleSubmit}
        confirmBtnText="Save"
      />
    </PageCardLayout>
  );
}

export default SiteEdit;
