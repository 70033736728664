import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSyncState } from 'src/hooks';
import InfoIcon from '@mui/icons-material/Info';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import ModalDialog, {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import {
  generateApiPath,
  loadDataFromStorage,
  saveDataToStorage,
} from 'src/utils';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { useStoreUnitActions } from 'src/redux/helpers';
import { useMsal } from '@azure/msal-react';
import {
  InteractionRequiredAuthError,
  InteractionStatus,
  PublicClientApplication,
} from '@azure/msal-browser';

import { SxProps } from 'src/theme/types';
import { useForm } from 'src/components/Form';
import Button from '@mui/material/Button';
import { Grid, Typography } from '@mui/material';
import { getDashboardsMainFields } from './formFields';
import solutionsStore, {
  useCurrentSolutionName,
  useDashboardDeviceGroupsOptions,
  useSolutionCompaniesOptions,
  useSolutionDashboardDeviceGroupsDetails,
  useSolutionDeviceGroupPropOptions,
  useSolutionDeviceGroupProperties,
  useSolutionDeviceTemplateOptions,
  useSolutionRuleTelemtryProperties,
  useSolutionTelemtryGroupPropOptions,
} from '../../redux';
import {
  API_SOLUTION_ANALYSE_DASHBOARD,
  API_SOLUTION_SAVE_DASHBOARD,
} from '../../constants';
import { msalConfig, loginRequest } from './msalConfig';

const msalInstance = new PublicClientApplication(msalConfig);
export { msalInstance };
type Props = {
  open: boolean;
  onClose: ModalCloseHandler;
};

const formStyle: SxProps = {
  gap: 0.5,
  '.MuiFormControlLabel-root': {
    alignSelf: 'flex-end',
    color: 'neutral.dark',
    typography: 'small1',
    marginTop: '20px',
  },
};

function SolutionDashboardCreateContainer({ open, onClose }: Props) {
  const iframeRef = useRef(null);
  const { inProgress, instance } = useMsal();
  const [dashboardToken, setDashboardToken] = useState<string>();
  const actions = useStoreUnitActions(solutionsStore);
  const { showAlert } = useAlert();
  const { solutionId } = useParams();
  const solutionName = useCurrentSolutionName();
  const [name, setName] = useState();
  const [functionOrMaterializedView, setFunctionOrMaterializedView] =
    useState('-');
  const [companyOptions, companyLoading] = useSolutionCompaniesOptions();
  const [templateOptions, templateLoading] = useSolutionDeviceTemplateOptions();
  const [deviceGroupOptions, deviceGroupLoading] =
    useDashboardDeviceGroupsOptions();
  const [deviceGroupDetails, deviceGroupDetailsLoading] =
    useSolutionDashboardDeviceGroupsDetails();
  const [propertyOptions, propertiesLoading] =
    useSolutionDeviceGroupPropOptions();
  const [propertyData, propertyDataLoading] =
    useSolutionDeviceGroupProperties();
  const [telemetryOptions, telemetryLoading] =
    useSolutionTelemtryGroupPropOptions();
  const [telemetryData, telemetryDataLoading] =
    useSolutionRuleTelemtryProperties();
  const [companyId, setCompanyId] = useSyncState<string | undefined>('');
  const [deviceGroupId, setDeviceGroupId] = useSyncState<string | undefined>(
    ''
  );
  const [templateId, setTemplateId] = useSyncState<string | undefined>('');
  const [propertyValue, setPropertyValue] = useSyncState<string | undefined>(
    ''
  );
  const [telemetryValue, setTelemetryValue] = useSyncState<string | undefined>(
    ''
  );
  const [aggregationValue, setAggregationValue] = useState();
  const [fromTimeframe, setFromTimeframe] = useState();
  const [toTimeframe, setToTimeframe] = useState();
  const [interval, setInterval] = useSyncState<string | undefined>('');
  const msalTokenData = loadDataFromStorage('msalToken');
  const accountDetails = loadDataFromStorage('msalAccountDetail');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<any>();
  const [iframeLoad, setIframeLoad] = useState(false);
  const onNameChange = (event) => {
    setName(event.target.value);
  };
  const onCompanyChange = ({ target }, { resetField }) => {
    setCompanyId(target.value);
  };
  const onDeviceGroupChange = ({ target }, { resetField }) => {
    setDeviceGroupId(target.value);
    const retData = deviceGroupDetails.filter(
      (comp) => comp.id === target.value
    );
    setTemplateId(retData[0].modelId);
  };
  const onPropertyChange = ({ target }, { resetField }) => {
    const selectedProperty = propertyData.filter(
      (eve) => eve.id === target.value
    );
    setPropertyValue(selectedProperty[0].name);
  };
  const onTelemetryChange = ({ target }) => {
    const selectedTelemetry = telemetryData.filter(
      (eve) => eve.id === target.value
    );
    setTelemetryValue(selectedTelemetry[0].path.replace('$.', ''));
  };
  const onAggregationChange = (event) => {
    setAggregationValue(event.target.value);
  };
  const onTimeFrameFromChange = (event) => {
    setFromTimeframe(event.target.value);
  };
  const onTimeFrameToChange = (event) => {
    setToTimeframe(event.target.value);
  };
  const onIntervalSizeChange = ({ target }, { resetField }) => {
    setInterval(target.value);
  };

  const iframeConfig = {
    cluster: process.env.REACT_APP_CLUSTER_DETAILS,
    zone: process.env.REACT_APP_CLUSTER_ZONE,
    features:
      'f-IFrameAuth=true&f-UseMeControl=true&f-ShowNavigation=true&f-ShowPersona=true&f-Homepage=false',
  };
  function mapScope(scope) {
    switch (scope) {
      case 'query':
        return [
          `https://${iframeConfig.cluster}.${iframeConfig.zone}.kusto.windows.net/.default`,
        ];
      case 'People.Read':
        return ['People.Read', 'User.ReadBasic.All', 'Group.Read.All'];
      case 'https://rtd-metadata.azurewebsites.net/user_impersonation':
        return ['https://rtd-metadata.azurewebsites.net/user_impersonation'];
      default:
        return [scope];
    }
  }
  const postToken = (iframe, accessToken, scope) => {
    iframe.contentWindow.postMessage(
      {
        type: 'postToken',
        message: accessToken,
        scope,
      },
      '*'
    );
  };

  useEffect(() => {
    if (user) {
      setIframeLoad(true);
    }
  }, [user]);

  const handleLogin = async () => {
    try {
      await msalInstance.initialize();
      await msalInstance
        .loginPopup(loginRequest)
        .then((response) => {
          if (response?.account) {
            setIframeLoad(true);
            setUser(response?.account);
            saveDataToStorage('msalExpireTime', response?.expiresOn?.getTime());
            saveDataToStorage('msalToken', response.idToken);
            saveDataToStorage('msalAccountDetail', response?.account);
          }
        })
        .catch((error) => {
          console.error('Login error', error);
        });
      setIsAuthenticated(true);
    } catch (error: any) {
      if (error.errorCode === 'interaction_in_progress') {
        console.warn('Another interaction is in progress. Please wait.');
      } else {
        console.error('Login error:', error);
      }
    }
  };
  const isTokenExpired = () => {
    const msalExpireTime = loadDataFromStorage('msalExpireTime');
    return msalExpireTime && new Date().getTime() > msalExpireTime;
  };
  const refreshTokenSilently = async () => {
    try {
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length > 0) {
        const account = accounts[0];
        msalInstance.setActiveAccount(account);
        const silentRequest = {
          scopes: ['user.read'],
          account,
        };
        const response = await msalInstance.acquireTokenSilent(silentRequest);
        saveDataToStorage('msalToken', response.accessToken);
        setUser(account);
        setIsAuthenticated(true);
      }
    } catch (error) {
      console.error('Silent token refresh error:', error);
      handleLogin(); // If silent refresh fails, prompt re-authentication
    }
  };

  const checkTokenValidity = async () => {
    if (isTokenExpired()) {
      await refreshTokenSilently();
    }
  };
  useEffect(() => {
    const handleIframeMessage = async (event) => {
      if (
        event.data.type !== 'getToken' ||
        event.origin !== 'https://dataexplorer.azure.com'
      ) {
        return;
      }
      const aadScopes = mapScope(event.data.scope);
      const iframe = iframeRef.current;
      const accountsDetails = [accountDetails];
      if (accountsDetails.length > 0 && inProgress === InteractionStatus.None) {
        await msalInstance.initialize();
        await msalInstance.handleRedirectPromise();
        msalInstance
          .acquireTokenSilent({
            scopes: aadScopes,
            account: accountsDetails[0],
          })
          .then((response) => {
            setDashboardToken(response.accessToken);
            postToken(iframe, response.accessToken, event.data.scope);
          })
          .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
              msalInstance
                .acquireTokenPopup({ scopes: aadScopes })
                .then((response) =>
                  postToken(iframe, response.accessToken, event.data.scope)
                )
                .catch((error) => {
                  console.error(`Error acquiring token: ${error}`);
                });
            } else {
              console.error(`Error acquiring token: ${error}`);
            }
          });
      }
    };
    // Attach event listener when the component mounts
    window.addEventListener('message', handleIframeMessage);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, [accountDetails]);

  useEffect(() => {
    actions.companies(solutionId);
  }, [solutionId]);

  useEffect(() => {
    if (companyId) {
      actions.dashboardDeviceGroups(companyId);
    }
  }, [companyId]);

  useEffect(() => {
    if (templateId) {
      actions.deviceGroupProperties({ solutionId, modelId: templateId });
      actions.ruleTelemtryProperties({ solutionId, modelId: templateId });
    }
  }, [templateId]);

  useEffect(() => {
    checkTokenValidity();
  }, []);

  const getPayloadParams = {
    SolutionId: solutionId,
    DeviceGroupId: deviceGroupId,
    GroupBy: propertyValue,
    Telemetry: {
      TelemetryName: telemetryValue,
      Aggregate: aggregationValue,
    },
    TimeWindow: {
      FromTime: fromTimeframe,
      ToTime: toTimeframe,
      Interval: interval,
    },
  };

  const [handleAnalyse, saving] = useStatefulApi(
    () => {
      if (!loadDataFromStorage('msalToken')) {
        handleLogin();
      }
      return pepApiRequest({
        method: RequestMethod.Post,
        url: generateApiPath(API_SOLUTION_ANALYSE_DASHBOARD, { solutionId }),
        params: getPayloadParams,
      });
    },
    (respone) => {
      setFunctionOrMaterializedView(respone.results[0].name);
      showAlert({
        type: AlertType.Success,
        title: 'Analyse created and saved',
        text: `Analyse for the  ${solutionName} is created successfully`,
      });
    }
  );

  const [handleSubmit, loading] = useStatefulApi(
    () =>
      pepApiRequest({
        method: RequestMethod.Post,
        url: generateApiPath(API_SOLUTION_SAVE_DASHBOARD, { solutionId }),
        params: {
          msalAdxToken: dashboardToken,
        },
      }),
    () => {
      showAlert({
        type: AlertType.Success,
        title: 'Dashboard created and saved',
        text: `Dasboard for the ${solutionName} is created successfully`,
      });
      onClose(null, ModalCloseReason.completeAction);
      actions.dashboard(solutionId);
    },
    (err) => {
      showAlert({
        type: AlertType.Error,
        title: 'Dashboard creation Error',
        text: 'Error while creating and saving Dasboard.',
      });
    }
  );

  const [FormComponent, onSubmit, setValue] = useForm({
    sx: formStyle,
    fields: getDashboardsMainFields({
      onNameChange,
      companyOptions,
      companyLoading,
      onCompanyChange,
      deviceGroupOptions,
      deviceGroupLoading,
      onDeviceGroupChange,
      telemetryOptions,
      telemetryLoading,
      onTelemetryChange,
      onAggregationChange,
      propertyOptions,
      propertiesLoading,
      onPropertyChange,
      onTimeFrameFromChange,
      onTimeFrameToChange,
      onIntervalSizeChange,
    }),
    onSubmit: handleAnalyse,
  });

  const handleClear = () => {
    setValue('name', '');
    setValue('companyName', '');
    setValue('deviceGroup', '');
    setValue('telemetry', '');
    setValue('aggregates', '');
    setValue('groupby', '');
    setValue('timeFrameFrom', '');
    setValue('timeFrameTo', '');
    setValue('intervalSize', '');
  };

  return (
    <ModalDialog
      title="Create new Dashboard"
      open={open}
      onClose={onClose}
      loading={loading || saving}
      onConfirm={handleSubmit}
      confirmBtnText="Save"
      maxWidth="xl"
      fullWidth
    >
      <Grid container spacing={2.5}>
        <Grid item xs={3}>
          <Grid
            sx={{ maxHeight: '650px', overflow: 'auto', overflowX: 'hidden' }}
          >
            <Typography
              display="inline-flex"
              color="primary"
              sx={{
                fontSize: '16px',
                marginRight: '20px',
                marginBottom: '20px',
              }}
              variant="body2"
              mb={3}
            >
              <InfoIcon sx={{ mr: 1 }} />
              Provide the necessary inputs and click analyse. If you haven't
              already, enter your ADX credentials in the external pop-up.
              Function name will be shown at the end. Enter the function name on
              the ADX iFrame ( Right hand side ) to start generating the
              dashboard.
            </Typography>
            {FormComponent}
            <Typography
              sx={{ margin: '10px', fontWeight: '500', fontSize: '16px' }}
            >
              Function Name/Materialised View
            </Typography>
            <Typography
              sx={{ margin: '10px', fontWeight: '400', fontSize: '16px' }}
            >
              {functionOrMaterializedView}
            </Typography>
          </Grid>
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'right',
              marginRight: '40px',
              marginTop: '20px',
            }}
          >
            <Button size="small" variant="outlined" onClick={handleClear}>
              Clear
            </Button>
            <Button
              size="small"
              variant="contained"
              sx={{ marginLeft: '8px' }}
              onClick={onSubmit}
            >
              Analyse
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={9}>
          <iframe
            ref={iframeRef}
            title="adx example"
            src={
              !iframeLoad
                ? `https://dataexplorer.azure.com/?${iframeConfig.features}`
                : `https://dataexplorer.azure.com/clusters/${iframeConfig.cluster}.${iframeConfig.zone}/databases/${solutionId}?${iframeConfig.features}`
            }
            width="100%"
            height="700"
          />
        </Grid>
      </Grid>
    </ModalDialog>
  );
}

export default SolutionDashboardCreateContainer;
