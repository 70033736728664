import { useParams } from 'react-router-dom';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { generateApiPath, loadDataFromStorage } from 'src/utils';
import { API_JOB_ITEM, API_JOB_ITEM_IOTH } from '../../constants';
import { SolutionJobItem, SolutionType } from '../../types';
import {
  composeSolutionJobsAlertText,
  GetSolutionJobItemRequestBody,
} from '../utils';
import SolutionJobEditStepper from './SolutionJobEditStepper';
import {
  useSolutionCommandOptions,
  useSolutionComponentsDetails,
  useSolutionDeviceGroupOnlyWritablePropOptions,
  useSolutionDeviceGroupPropOptions,
  useSolutionDeviceGroupProperties,
} from '../../redux';

type Props = {
  item?: SolutionJobItem;
  open: boolean;
  onClose: ModalCloseHandler;
};

function SolutionJobEditContainer({ item, open, onClose }: Props) {
  const { showAlert } = useAlert();
  const { solutionId } = useParams();
  const [propertiesData, propertiesLoading] =
    useSolutionDeviceGroupProperties();
  const [propertyOptions, propertyOptionsLoading] =
    useSolutionDeviceGroupOnlyWritablePropOptions();
  const [commandData, Commandloading] = useSolutionComponentsDetails();
  const [commandOptions, commandLoading] = useSolutionCommandOptions();

  const [onSubmit, loading] = useStatefulApi(
    (values?: SolutionJobItem) => {
      const solutionType = loadDataFromStorage('solutionType');
      return pepApiRequest({
        method: RequestMethod.Put,
        url: generateApiPath(
          solutionType === SolutionType.ioTH ? API_JOB_ITEM_IOTH : API_JOB_ITEM,
          {
            solutionId,
            jobId: item?.id,
          }
        ),
        params: GetSolutionJobItemRequestBody(
          solutionType,
          propertyOptions,
          propertiesData,
          commandData,
          commandOptions,
          values
        ),
      });
    },
    (res, item) => {
      showAlert({
        type: AlertType.Success,
        title: composeSolutionJobsAlertText(
          'Job updated {run}',
          !item?.runLater
        ),
        text: composeSolutionJobsAlertText(
          'The job has been updated {run} successfully.',
          !item?.runLater
        ),
      });
      onClose(null, ModalCloseReason.completeAction);
    }
  );

  return (
    <SolutionJobEditStepper
      item={item}
      editStepTitle={`Edit job: ${item?.displayName}`}
      reviewStepTitle="Edit job - Review"
      loading={loading}
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
}

export default SolutionJobEditContainer;
