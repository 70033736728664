import { generatePath } from 'react-router-dom';
import { createDatasetSlice, ResponseMapping } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import {
  API_GET_SOL_NAMESPACE_RSC_LIMIT,
  SOLUTION_STORE_DEFAULT_NAMESPACE_RSC_LIMIT,
  SOLUTIONS_STORE,
} from '../constants';

const api = (solutionId) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: generatePath(API_GET_SOL_NAMESPACE_RSC_LIMIT, { solutionId }),
  });

const defaultNamespaceRscLimit = createDatasetSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTION_STORE_DEFAULT_NAMESPACE_RSC_LIMIT,
  responseMapping: ResponseMapping.Default,
});

export default defaultNamespaceRscLimit;
