import { Dispatch } from 'redux';
import {
  createAction,
  createReducer,
  createListenerMiddleware,
  isAnyOf,
  CaseReducer,
  ActionCreatorWithPreparedPayload,
} from '@reduxjs/toolkit';
import {
  loadDataFromStorage,
  prepandItemToQuery,
  saveDataToStorage,
} from 'src/utils';
import {
  DEFAULT_PERSIST_SLICE_ID,
  DEFAULT_PERSIST_SLICE_LIMIT,
} from './constants';
import { State } from '../types';
import { getHashFunc, getSliceNamePrefix } from './utils';

export type CreatePersistDataSliceOptions<T> = {
  actionName: string;
  storeName: string;
  datasetHashKey?: string;
  initialState?: T;
  identifier?: string;
  limit?: number;
  extraActions?: ActionCreatorWithPreparedPayload<any, any>[];
  payloadMapFn?: (payload) => any;
  reducer?: (...params) => CaseReducer<T>;
};

function updateDataReducer({
  actionName,
  datasetHashKey,
  identifier,
  limit,
  payloadMapFn,
}) {
  const hashFunc = getHashFunc(actionName, datasetHashKey);
  return (state, action) => {
    const key = hashFunc(action.payload);
    const payload = payloadMapFn(action.payload);
    state[key].data = prepandItemToQuery(
      payload,
      state[key].data,
      limit,
      identifier
    );
  };
}

export function createPersistDataSlice<T>({
  storeName,
  actionName,
  datasetHashKey,
  initialState = { data: [] } as T,
  identifier = DEFAULT_PERSIST_SLICE_ID,
  limit = DEFAULT_PERSIST_SLICE_LIMIT,
  reducer = updateDataReducer,
  extraActions = [],
  payloadMapFn = (item) => item,
}: CreatePersistDataSliceOptions<T>) {
  const listener = createListenerMiddleware();
  const name = getSliceNamePrefix(storeName, actionName);
  const actions = createAction(name, (payload, meta) => ({
    payload,
    meta,
  }));
  datasetHashKey = datasetHashKey || actionName;
  initialState = loadDataFromStorage(name) || initialState;

  listener.startListening({
    matcher: isAnyOf(actions, ...extraActions),
    effect: (action, listenerApi) => {
      const state = listenerApi.getState() as State;
      saveDataToStorage(name, state[storeName][datasetHashKey]);
    },
  });

  return {
    actions,
    name,
    initialState,
    middleware: listener.middleware,
    reducer: createReducer(initialState, (builder) => {
      builder.addCase(
        name,
        reducer({ actionName, datasetHashKey, identifier, limit, payloadMapFn })
      );
    }),
    dispatchers: (dispatch: Dispatch) => (params, meta) =>
      dispatch(actions(params, meta)),
  };
}
