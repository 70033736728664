import { generatePath } from 'react-router-dom';
import ModalDialog, { ModalCloseHandler } from 'src/components/ModalDialog';
import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { pepApiRequest, RequestMethod } from 'src/services';
import { useStoreUnitActions } from 'src/redux/helpers';
import { SiteNamespace } from 'src/pages/Sites/types';
import { solutionsStore } from 'src/pages/Solutions';
import { useSolutionNamespaceRscLimit } from 'src/pages/Solutions/redux';
import { API_SITE_CLUSTER_NAMESPACE_QUOTAS_DETAIL } from 'src/pages/Sites/constants';
import { mapSiteNamespaceRscQuotasDetailData } from 'src/pages/Sites/redux/utils';
import { SiteNamespaceRscQuotasDetailItem } from 'src/pages/Sites/types/siteNamespace';
import { SiteNamespaceRscLimitDetails } from './SiteNamespaceRscLimitDetails';

type Props = {
  siteId: string;
  clusterId: string;
  clusterName: string;
  open: boolean;
  onClose: ModalCloseHandler;
};

function SiteClusterNamespaceRscLimitViewModal({
  siteId,
  clusterId,
  clusterName = '',
  open,
  onClose,
}: Props) {
  const actions = useStoreUnitActions(solutionsStore);
  const namespaceName = SiteNamespace.CoreAndCommon;
  const [data, loading] = useSolutionNamespaceRscLimit();
  const [namespaceRscQuotasData, setNamespaceRscQuotasData] =
    useState<SiteNamespaceRscQuotasDetailItem>();
  const [namespaceQuotaLoader, setNamespaceQuotaLoader] =
    useState<boolean>(false);

  useEffect(() => {
    actions.clusterRscLimit({
      siteId,
      namespaceName,
      clusterIds: { clusterIds: [clusterId] },
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (clusterId) {
          setNamespaceQuotaLoader(true);
          const apiUrl = generatePath(
            API_SITE_CLUSTER_NAMESPACE_QUOTAS_DETAIL,
            {
              siteId,
              clusterId,
            }
          );
          try {
            const response = await pepApiRequest({
              method: RequestMethod.Get,
              url: apiUrl,
            });
            setNamespaceRscQuotasData(
              mapSiteNamespaceRscQuotasDetailData(response, clusterId)
            );
            setNamespaceQuotaLoader(false);
            return;
          } catch (error) {
            setNamespaceQuotaLoader(false);
          }
        }
      } catch (error) {
        console.error('Error in getting namespace quotas details:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <ModalDialog
      open={open}
      title={`View Quota for Core and Common : ${clusterName}`}
      showCancelBtn={true}
      showConfirmBtn={false}
      loading={loading || namespaceQuotaLoader}
      onClose={onClose}
      maxWidth="xl"
      fullWidth
      titleStyle={{ fontWeight: '500' }}
    >
      <Box
        style={{ maxHeight: '80vh', overflowY: 'auto' }}
        justifyContent="space-between"
      >
        <SiteNamespaceRscLimitDetails
          data={namespaceRscQuotasData}
          rscLimitData={data}
          siteId={siteId}
          namespaceName={namespaceName}
          loading={loading}
          payload={{ clusterIds: { clusterIds: [clusterId] } }}
        />
      </Box>
    </ModalDialog>
  );
}

export default SiteClusterNamespaceRscLimitViewModal;
