import React from 'react';
import { FormArrayRow, FormArrayRowProps } from 'src/components/Form';
import {
  useSolutionDeviceGroupPropOptions,
  useSolutionDeviceGroupProperties,
  useSolutionDevicePropertyMeta,
} from '../../redux';
import { getDeviceGroupFilterFields } from '../formFields';

function SolutionDeviceGroupEditFormRow({
  childFields,
  update,
  values,
  ...rest
}: FormArrayRowProps) {
  const [propertyOptions, propertiesLoading] =
    useSolutionDeviceGroupPropOptions();
  const { operators: operatorOptions, enums: filterValueOptions } =
    useSolutionDevicePropertyMeta(values?.property);
  const onPropertyChange = ({ target }) =>
    update && update(rest.index, { property: target.value });
  const [properties] = useSolutionDeviceGroupProperties();
  const selectedProperty = properties.find((a) => a.id === values?.property);
  return (
    <FormArrayRow
      childFields={getDeviceGroupFilterFields({
        onPropertyChange,
        propertyOptions,
        selectedProperty,
        propertiesLoading,
        operatorOptions,
        filterValueOptions,
      })}
      {...rest}
    />
  );
}

export default SolutionDeviceGroupEditFormRow;
