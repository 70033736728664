import {
  SolutionUserNotificationPreferences,
  SolutionUserRole,
} from '../types';

export const SOLUTION_USER_ROLE_OPTIONS = [
  {
    value: SolutionUserRole.Owner,
    label: 'Solution owner',
  },
  {
    value: SolutionUserRole.User,
    label: 'Solution user',
  },
];

export const SOLUTION_USER_COUNTRY_CODE = [
  {
    value: '+91',
    label: 'IND (+91)',
  },
  {
    value: '+1',
    label: 'US (+1)',
  },
];

export const NOTIFICATION_PREFERENCE_OPTIONS = [
  {
    value: SolutionUserNotificationPreferences.Email,
    label: 'Email',
  },
  {
    value: SolutionUserNotificationPreferences.SMS,
    label: 'SMS',
  },
  {
    value: SolutionUserNotificationPreferences.Whatsapp,
    label: 'Whatsapp',
  },
];

export const IOTH_NOTIFICATION_PREFERENCE_OPTIONS = [
  {
    value: SolutionUserNotificationPreferences.Email,
    label: 'Email',
  },
  {
    value: SolutionUserNotificationPreferences.Whatsapp,
    label: 'Whatsapp',
  },
];
