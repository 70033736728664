export enum RequestMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Delete = 'DELETE',
  Patch = 'PATCH',
}

export enum ContentType {
  JSON = 'application/json',
  FormData = 'multipart/form-data',
}

export type RequestBodyParams = Record<string, any> | null;

export type RequestParams = {
  method: RequestMethod;
  url: string;
  params?: RequestBodyParams;
  queryParams?: RequestBodyParams;
  contentType?: ContentType;
  headers?: HeadersInit;
  skipErrorCodes?: number[];
  skipErrorAlert?: boolean;
  skipEncodeUri?: boolean;
  skipCustomAlert?: boolean;
};

export type ResponseErrorItem = {
  code: string;
  detail: string;
  title: string;
};

export type ErrorResponseBody = {
  status: number;
  errors: ResponseErrorItem[];
  skipAlert: boolean;
};
