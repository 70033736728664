// routes
export const NEW_COMPANY = `new`;
export const COMPANY_DETAILS = `:companyId`;

// api
export const API_COMPANIES = '/companies';
export const API_COMPANY_ITEM = `${API_COMPANIES}/:companyId`;
export const API_COMPANY_USERS = '/platformroles/tenants/:companyId';
export const API_COMPANY_USER_ITEM = `${API_COMPANY_USERS}/:userId`;

// redux
export const COMPANIES_STORE = 'companies';
export const COMPANIES_STORE_LIST = 'list';
export const COMPANIES_STORE_DETAILS = 'details';
export const COMPANIES_STORE_USERS = 'users';
