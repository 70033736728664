import { ForwardedRef, forwardRef } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import CircularProgressWithLabel from '../CircularProgressWithLabel';

interface CircularProgressProps {
  actual?: number;
  used?: number;
  message?: string;
  size?: number;
}
interface Props {
  showinfoicon?: boolean;
  showcirclularprogress?: boolean;
  infotitle?: string;
  circularprogressprops?: CircularProgressProps;
}

function TextFieldWithInfo(
  props: Props & TextFieldProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const {
    showinfoicon,
    showcirclularprogress,
    infotitle,
    circularprogressprops,
    ...rest
  } = props;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <TextField
        ref={ref}
        variant="outlined"
        sx={rest?.sx ? { ...rest.sx } : { minWidth: '-webkit-fill-available' }}
        {...props}
        data-testid="custom-text-field"
      />
      {showcirclularprogress && (
        <CircularProgressWithLabel {...circularprogressprops} />
      )}
      {showinfoicon && infotitle && (
        <Tooltip
          title={<Typography fontSize="12px">{infotitle}</Typography>}
          placement="bottom-end"
          componentsProps={{
            tooltip: {
              sx: {
                fontSize: '12px',
                bgcolor: '#24242499',
                color: '#fff',
                border: 'none',
              },
            },
            arrow: {
              sx: {
                '::before': {
                  border: 'none',
                  backgroundColor: '#24242499',
                },
              },
            },
          }}
        >
          <InfoOutlined
            color="info"
            sx={{ fontSize: '22px' }}
            data-testid="info-icon"
          />
        </Tooltip>
      )}
    </Box>
  );
}

export default forwardRef(TextFieldWithInfo);
