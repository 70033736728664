import { baseTheme } from '../baseTheme';

const { palette } = baseTheme;

export default {
  defaultProps: {
    color: 'primary',
  },
  styleOverrides: {
    root: {
      '&.Mui-disabled': {
        color: palette.neutral.main,
      },
    },
  },
};
