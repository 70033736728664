import React from 'react';
import { generatePath } from 'react-router-dom';
import ModalDialog, {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import { API_OTP_KEY_ITEM } from '../constants';
import { OtpItem } from '../types';

type Props = {
  item: OtpItem;
  itemKey: string;
  open: boolean;
  onClose: ModalCloseHandler;
};

function DeleteOtpModal({ item, itemKey: key, open, onClose }: Props) {
  const { showAlert } = useAlert();
  const otpKey = item?.key;
  const [handleSubmit, loading] = useStatefulApi(
    () =>
      pepApiRequest({
        method: RequestMethod.Delete,
        url: generatePath(API_OTP_KEY_ITEM, { key, otpKey }),
      }),
    () => {
      showAlert({
        type: AlertType.Info,
        title: `${otpKey} is deleted`,
        text: 'OTP key has been successfully deleted.',
      });
      onClose(null, ModalCloseReason.completeAction);
    }
  );

  return (
    <ModalDialog
      open={open}
      title="Remove key"
      text="Are you sure to remove this OTP key?"
      loading={loading}
      onClose={onClose}
      onConfirm={handleSubmit}
      cancelBtnText="No"
      confirmBtnText="Yes"
    />
  );
}

export default DeleteOtpModal;
