import { generatePath } from 'react-router-dom';
import { createDatasetSlice } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import {
  API_GET_SOLUTION_ALERT_RULES,
  SOLUTIONS_STORE,
  SOLUTIONS_STORE_ALERT_RULES,
} from '../constants';
import { mapSolutionAlertRules } from './utils';

const api = (solutionId: string) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: generatePath(API_GET_SOLUTION_ALERT_RULES),
    queryParams: { solutionId },
  }).then((response) => mapSolutionAlertRules(response));

const alertRules = createDatasetSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTIONS_STORE_ALERT_RULES,
});

export default alertRules;
