import React from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { ReactComponent as CommandIcon } from 'src/assets/icons/command.svg';
import { ReactComponent as LogIcon } from 'src/assets/icons/log.svg';
import { ReactComponent as RefreshIcon } from 'src/assets/icons/refresh.svg';
import { ReactComponent as PropertyIcon } from 'src/assets/icons/property.svg';
import { useStoreUnitActions } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { PageCardLoader } from 'src/components/PageCardLayout';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import { useModal } from 'src/hooks';
import {
  DataTableActionsButton,
  useTableQueryParams,
} from 'src/components/DataTable';
import { isModalComplete, ModalCloseReason } from 'src/components/ModalDialog';
import { useUserRoles } from 'src/auth';
import solutionsStore, {
  useSolutionComponentsDetails,
  useSolutionDeviceComponentProperties,
  useSolutionDeviceGroupWritableProperties,
} from '../redux';
import { API_DEVICES_MODULES_IOTH } from '../constants';
import SolutionCommandAssignTemplateModal from '../SolutionDevicesComponents/SolutionCommandAssignTemplateModal';
import SolutionModuleLogsModal from './SolutionModuleLogs';
import SolutionPropertiesAssignTemplateModal from '../SolutionDevicesComponents/SolutionPropertiesAssignTemplateModal';

type Props = {
  item?: any;
};
type PropertyDataList = PropertyData[];
type PropertyData = {
  headerName: string;
  data: data[];
};
type data = {
  name: string;
  schema: string;
};

function SolutionDevicesModulesAction({ item }: Props) {
  const { showAlert } = useAlert();
  const { devicesPersist } = useStoreUnitActions(solutionsStore);
  const { paging } = useTableQueryParams();
  const { isPlatformReader, isPlatformEngineer } = useUserRoles();
  const { pageIndex, pageSize } = paging;
  const { solutionId, solutionName } = useParams();
  const actions = useStoreUnitActions(solutionsStore);
  const [data, loading] = useSolutionComponentsDetails();
  const [propertiesData, propertiesLoading] =
    useSolutionDeviceGroupWritableProperties();
  const propertiesValueData = useSolutionDeviceComponentProperties();
  const componentName = item.component;
  const moduleName = item.moduleId;
  const onClose = (event, reason) =>
    isModalComplete(reason) &&
    actions.jobs({ solutionId, pageIndex, pageSize });

  const retData = data.filter((comp) => comp?.moduleName === item.moduleId);
  const isCommandEnable = data.some(
    (command) => command?.moduleName === item.moduleId
  );

  const propertyData = propertiesData.filter(
    (prop) => prop.moduleName === item.moduleId
  );
  const desiredPropertyData =
    propertiesValueData?.properties?.desired[item.component];
  const reportedPropertyData =
    propertiesValueData?.properties?.reported[item.component];
  const similarData = propertyData.filter((comp) =>
    comp.displayName.includes('/')
  );
  const list: any = [];
  for (let index = 0; index < similarData.length; index += 1) {
    const data = similarData[index];
    if (list.includes(data.displayName)) break;
    list.push(data.displayName.split('/')[0]);
  }
  const newList = list.filter((item, index) => list.indexOf(item) === index);
  const newPropertyDataList: PropertyDataList = [];
  newList.forEach((e) => {
    const newPropertyData: PropertyData = { data: [], headerName: '' };
    newPropertyData.data = [];
    newPropertyData.headerName = e;
    const dataValue: any = propertiesData.filter((comp) =>
      comp.displayName.match(e)
    );
    newPropertyData.data.push(dataValue);
    newPropertyDataList.push(newPropertyData);
  });

  const openCommandTemplate = () => {
    devicesPersist.add(retData);
    showCommandTemplateDialog();
  };

  const [CommandTemplateDialog, showCommandTemplateDialog] = useModal(
    SolutionCommandAssignTemplateModal,
    { retData }
  );
  const openPropertiesTemplate = () => {
    showPropertiesTemplateDialog();
  };

  const [PropertiesTemplateDialog, showPropertiesTemplateDialog] = useModal(
    SolutionPropertiesAssignTemplateModal,
    {
      componentName,
      moduleName,
      newPropertyDataList,
      propertyData,
      desiredPropertyData,
      reportedPropertyData,
      propertiesData,
    }
  );

  const moduleId = item?.moduleId;
  const [moduleLogsDialog, showModuleLogsDialog] = useModal(
    SolutionModuleLogsModal,
    { moduleId }
  );

  const [onRestart, loadingRestart] = useStatefulApi(
    (params) =>
      pepApiRequest({
        method: RequestMethod.Post,
        url: generatePath(API_DEVICES_MODULES_IOTH, {
          solutionId,
          tenantId: 'all',
          deviceId: solutionName,
        }),
        params: {
          componentName: '',
          moduleName: '$edgeAgent',
          commandName: 'RestartModule',
          commandProperties: [
            {
              propertyName: 'schemaVersion',
              propertyValue: '1.0',
            },
            {
              propertyName: 'id',
              propertyValue: item?.moduleId.startsWith('$')
                ? item?.moduleId.replace('$', '')
                : item?.moduleId,
            },
          ],
        },
      }),
    (success) => {
      const status = success?.value?.status;
      const message = success?.value?.payload?.message;
      if (status === 200) {
        showAlert({
          type: AlertType.Success,
          title: 'Restarted status',
          text: 'Restarted successfully.',
        });
      } else {
        showAlert({
          type: AlertType.Error,
          title: 'Restarted status',
          text: message || 'Restart could not started successfully',
        });
      }
      actions.details(solutionId);
      onClose(null, ModalCloseReason.completeAction);
    }
  );

  return (
    <>
      <DataTableActionsButton
        data-testid="solution-modules-restart-action"
        icon={RefreshIcon}
        title="Restart"
        onClick={onRestart}
        disabled={
          item.connectionState.toLowerCase() === 'disconnected' ||
          isPlatformReader ||
          isPlatformEngineer
        }
      />
      <DataTableActionsButton
        data-testid="solution-modules-command-action"
        icon={CommandIcon}
        title="Command"
        onClick={openCommandTemplate}
        disabled={!isCommandEnable || isPlatformReader || isPlatformEngineer}
      />
      <DataTableActionsButton
        data-testid="solution-modules-log-action"
        icon={LogIcon}
        title="View Module Logs"
        onClick={showModuleLogsDialog}
        disabled={isPlatformReader}
      />
      <DataTableActionsButton
        data-testid="solution-job-run-action"
        icon={PropertyIcon}
        title="Property"
        onClick={openPropertiesTemplate}
        disabled={!isCommandEnable || isPlatformReader || isPlatformEngineer}
        sx={{ marginBottom: '-1rem' }}
      />
      {CommandTemplateDialog}
      {moduleLogsDialog}
      {PropertiesTemplateDialog}
      {loadingRestart && <PageCardLoader />}
    </>
  );
}

export default SolutionDevicesModulesAction;
