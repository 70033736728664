import { forwardRef, ForwardedRef } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { InfoOutlined } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import SelectOption, { Option, OptionValue } from 'src/components/SelectOption';
import { getDisplayValue } from './utils';
import InputLoader from '../InputLoader';

type Props<T = OptionValue> = Omit<TextFieldProps, 'onChange'> & {
  onChange?: (event: SelectChangeEvent<T | T[]>) => void;
  options?: Option<T>[];
  loading?: boolean;
  showInfoIcon?: boolean;
  multiple?: boolean;
  infoTitle?: any;
};

const loaderStyle = {
  position: 'absolute',
  right: ({ spacing }) => spacing(3),
};

const containerStyle = {
  display: 'flex',
  alignItems: 'center',
};

const textfieldStyle = {
  flexGrow: 1,
};

function Select(
  {
    options = [],
    loading,
    showInfoIcon = false,
    multiple,
    infoTitle,
    ...rest
  }: Props,
  ref: ForwardedRef<HTMLInputElement>
) {
  const renderValue = (value) => getDisplayValue(value, options, loading);
  const noOptions = !loading && !options.length;

  return (
    <Box sx={rest?.sx ? { ...rest.sx, ...containerStyle } : containerStyle}>
      <TextField
        data-testid="select"
        select
        SelectProps={{
          multiple,
          renderValue,
        }}
        inputProps={{ ref }}
        InputProps={{
          endAdornment: <InputLoader loading={loading} sx={loaderStyle} />,
        }}
        {...(rest as TextFieldProps)}
        sx={textfieldStyle}
      >
        {noOptions && <MenuItem disabled>No available options</MenuItem>}
        {loading && <MenuItem disabled>Loading...</MenuItem>}
        {options.map(({ label, value, disabled }) => (
          <SelectOption
            disabled={disabled}
            key={value}
            multiple={multiple}
            label={label}
            value={value}
          />
        ))}
      </TextField>
      {showInfoIcon && (
        <Tooltip
          title={<Typography fontSize="12px">{infoTitle}</Typography>}
          placement="bottom-end"
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: '#24242499',
                color: '#fff',
                border: 'none',
              },
            },
            arrow: {
              sx: {
                '::before': {
                  border: 'none',
                  backgroundColor: '#24242499',
                },
              },
            },
          }}
        >
          <InfoOutlined
            fontSize="small"
            color="info"
            sx={{ marginLeft: '10px' }}
          />
        </Tooltip>
      )}
    </Box>
  );
}

export default forwardRef(Select);
