import { RouteProps } from 'src/router';
import * as ROUTES from './constants';
import CompaniesList from './CompaniesList';
import NewCompany from './NewCompany';
import CompanyDetails, { CompanyDetailsTitle } from './CompanyDetails';

export const routes: RouteProps[] = [
  {
    index: true,
    component: CompaniesList,
  },
  {
    path: ROUTES.NEW_COMPANY,
    component: NewCompany,
    meta: {
      title: 'New company',
    },
  },
  {
    path: ROUTES.COMPANY_DETAILS,
    component: CompanyDetails,
    meta: {
      title: 'Company details',
      TitleComponent: CompanyDetailsTitle,
    },
  },
];
