import { generatePath } from 'react-router-dom';
import { createDatasetSlice, ResponseMapping } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import {
  API_SOLUTION_SITES_CLUSTERS_RES_SETTING,
  SOLUTION_STORE_CLUSTER_NAMESPACE_RSC_LIMIT,
  SOLUTIONS_STORE,
} from '../constants';
// import { mapSolutionNamespaceRscLimitData } from './utils';

const api = ({ siteId, namespaceName, clusterIds }) =>
  pepApiRequest({
    method: RequestMethod.Post,
    url: generatePath(API_SOLUTION_SITES_CLUSTERS_RES_SETTING, {
      siteId,
      namespaceName,
    }),
    params: clusterIds,
  });

const clusterRscLimit = createDatasetSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTION_STORE_CLUSTER_NAMESPACE_RSC_LIMIT,
  responseMapping: ResponseMapping.Default,
});

export default clusterRscLimit;
