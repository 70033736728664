import { RouteProps } from 'src/router';
import * as ROUTES from '../constants';
import SolutionDevicesList from './components/SolutionDevicesList';
import SolutionDeviceDeployment, {
  SolutionDeviceDeploymentTitle,
} from '../SolutionDeviceDeployment';
import { SolutionFeature } from '../types';
import SolutionDevicesModules from '../SolutionDevicesModules/SolutionDevicesModules';
import SolutionDevicesModulesTitle from '../SolutionDevicesModules/SolutionDevicesModulesTitle';

export const routes: RouteProps[] = [
  {
    index: true,
    component: SolutionDevicesList,
  },
  {
    path: ROUTES.SOLUTION_DEVICE_DETAILS_MODULES,
    component: SolutionDevicesModules,
    meta: {
      title: 'Modules',
      TitleComponent: SolutionDevicesModulesTitle,
    },
  },
  {
    path: ROUTES.SOLUTION_DEVICE_DETAILS,
    component: SolutionDeviceDeployment,
    allowedFeatures: [SolutionFeature.Mender],
    meta: {
      title: 'Device details',
      TitleComponent: SolutionDeviceDeploymentTitle,
    },
  },
];
