import { ForwardedRef, forwardRef, ReactNode } from 'react';
import TextField from '@mui/material/TextField';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import {
  DateTimePicker as MuiDateTimePicker,
  DateTimePickerProps,
} from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DATE_TIME_DISPLAY_FORMAT } from 'src/constants';
import { SxProps } from 'src/theme/types';
import { dateToString } from './utils';

type Props = Omit<DateTimePickerProps<string, Date>, 'renderInput'> & {
  id?: string;
  name?: string;
  label?: ReactNode;
  error?: boolean;
  helperText?: ReactNode;
  onChange?: (value: string) => void;
  required?: boolean;
  placeholder?: string;
  sx?: SxProps;
};

function DateTimePicker(
  {
    id,
    name,
    value,
    onChange,
    error,
    helperText,
    placeholder,
    required,
    inputFormat = DATE_TIME_DISPLAY_FORMAT,
    ampm = false,
    disablePast = true,
    sx,
    ...props
  }: Props,
  ref: ForwardedRef<HTMLInputElement>
) {
  const handleChange = (value: Date | null) =>
    onChange && onChange(dateToString(value));

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiDateTimePicker<string, Date>
        ampm={ampm}
        disablePast={disablePast}
        inputFormat={inputFormat}
        value={value}
        onChange={handleChange}
        ref={ref}
        components={{
          OpenPickerIcon: () => <EventOutlinedIcon fontSize="small" />,
        }}
        OpenPickerButtonProps={{ size: 'small' }}
        renderInput={(params) => (
          <TextField
            data-testid="date-time-picker-textfield"
            {...params}
            id={id}
            name={name}
            error={error}
            helperText={helperText}
            placeholder={placeholder}
            required={required}
            sx={sx}
          />
        )}
        {...props}
      />
    </LocalizationProvider>
  );
}

export default forwardRef(DateTimePicker);
