import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';

function CardButtonsContainer({ children, ...rest }: BoxProps) {
  return (
    <Box display="flex" flexWrap="wrap" mx={-1} mt={2} {...rest}>
      {children}
    </Box>
  );
}

export default CardButtonsContainer;
