import { SolutionEdgeWorkloadAuthType, SolutionEdgeWorkloadCertVerifytype } from "src/pages/Solutions/types";
import { SiteNamespace } from "../types";


export const EDGEWORKLOAD_AUTH_OPTIONS = [
  {
    value: SolutionEdgeWorkloadAuthType.None,
    label: 'None',
  },
  {
    value: SolutionEdgeWorkloadAuthType.HTTPBased,
    label: 'Create a HTTP Based Auth secret',
  },
  {
    value: SolutionEdgeWorkloadAuthType.SSHBased,
    label: 'Create a SSH Key Set',
  },
];

export const EDGEWORKLOAD_CERTVERIFY_OPTIONS = [
  {
    value: SolutionEdgeWorkloadCertVerifytype.ValidCert,
    label: 'Require a valid certificate',
  },
  {
    value: SolutionEdgeWorkloadCertVerifytype.AdditionalCert,
    label: 'Specify additional certificate to be accepted',
  },
  {
    value: SolutionEdgeWorkloadCertVerifytype.AnyCerticate,
    label: 'Accept any certificate ( Insecure )',
  },
];

export const EDGEWORKLOAD_NAMESPACE_OPTIONS = [
  {
    value: SiteNamespace.CoreAndCommon,
    label: 'PEPsense Core and Common',
  },
  {
    value: SiteNamespace.KubeSystem,
    label: 'Kube System',
  }
];