import { useForm } from 'src/components/Form';
import ModalDialog, { ModalCloseHandler } from 'src/components/ModalDialog';
import {
  SolutionDeviceGroupDetails,
  SolutionDeviceGroupItemField,
  SolutionDeviceGroupPropertyItem,
} from '../../types';
import { useSolutionDeviceGroupEditFormFields } from '../hooks';
import SolutionDeviceGroupFilterHeading from './SolutionDeviceGroupFilterHeading';
import SolutionDeviceGroupEditFormRow from './SolutionDeviceGroupEditFormRow';

type Props = {
  item?: SolutionDeviceGroupDetails;
  properties?: SolutionDeviceGroupPropertyItem[];
  confirmBtnText?: string;
  disabled?: boolean;
  loading?: boolean;
  open: boolean;
  onSubmit?: (item?: SolutionDeviceGroupDetails) => void;
  onClose: ModalCloseHandler;
  title: string;
};

export default function SolutionDeviceGroupEditModal({
  item,
  disabled,
  loading,
  open,
  onClose,
  onSubmit,
  title,
  confirmBtnText = 'Save',
}: Props) {
  const [mainFields, filterFields] = useSolutionDeviceGroupEditFormFields(item);

  const [form, handleSubmit] = useForm({
    fields: [
      ...mainFields,
      {
        name: SolutionDeviceGroupItemField.Filters,
        appendLabel: 'Filter',
        childFields: filterFields,
        description: <SolutionDeviceGroupFilterHeading />,
        RowComponent: SolutionDeviceGroupEditFormRow,
      },
    ],
    onSubmit: (values) => onSubmit && onSubmit(values),
    values: item,
    disabled,
  });

  return (
    <ModalDialog
      open={open}
      title={title}
      onClose={onClose}
      confirmBtnText={confirmBtnText}
      onConfirm={disabled ? onClose : handleSubmit}
      loading={loading}
      showCancelBtn={!disabled}
      maxWidth="md"
      fullWidth
    >
      {form}
    </ModalDialog>
  );
}
