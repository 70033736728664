import React from 'react';
import Badge from 'src/components/Badge';
import { SolutionJobStatus as Status } from '../../types';

type StatusMapItem = {
  color: 'success' | 'info' | 'error' | 'default';
  label: string;
};

type Props = {
  value?: Status;
};

function SolutionJobStatus({ value = Status.Pending }: Props) {
  const statusMap: Record<Status, StatusMapItem> = {
    [Status.Complete]: { color: 'success', label: 'Completed' },
    [Status.completed]: { color: 'success', label: 'Completed' },
    [Status.Failed]: { color: 'error', label: 'Failed' },
    [Status.Scheduled]: { color: 'info', label: 'Scheduled' },
    [Status.Outdated]: { color: 'default', label: 'Outdated' },
    [Status.Pending]: { color: 'info', label: 'Pending' },
    [Status.Running]: { color: 'success', label: 'Running' },
    [Status.Stopped]: { color: 'error', label: 'Stopped' },
    [Status.Cancelled]: { color: 'error', label: 'Cancelled' },
  };

  return <Badge {...statusMap[value]} />;
}

export default SolutionJobStatus;
