import React from 'react';
import Select from 'src/components/Select';
import { PageCardSection } from 'src/components/PageCardLayout';
import { useSolutionDevDepReleaseOptions } from '../../redux';
import SolutionDeviceDeploymentNotification from './SoluitonDeviceDeploymentNotification';

const selectStyle = {
  width: ({ spacing }) => spacing(55),
  maxWidth: '100%',
};

type Props = {
  onReleaseChange: (id: string) => void;
  releaseName?: string;
};

function SolutionDeviceDeploymentReleaseSelect({
  releaseName,
  onReleaseChange,
}: Props) {
  const [options, loading] = useSolutionDevDepReleaseOptions();

  const handleChange = ({ target }) => {
    onReleaseChange(target.value);
  };

  return (
    <PageCardSection title="Select a deployment">
      <SolutionDeviceDeploymentNotification>
        The Mender services will automatically skip deployments to devices that
        are already on the target Release version, or that have an incompatible
        device type.
      </SolutionDeviceDeploymentNotification>
      <Select
        label="Select a compatible release"
        loading={loading}
        onChange={handleChange}
        options={options}
        value={releaseName}
        sx={selectStyle}
        required
      />
    </PageCardSection>
  );
}

export default SolutionDeviceDeploymentReleaseSelect;
