import React, {
  forwardRef,
  ReactNode,
  ForwardedRef,
  useMemo,
  ChangeEvent,
  useState,
  useEffect,
} from 'react';
import clsx from 'clsx';
import MuiAutocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { InputProps } from '@mui/material/Input';
import { PaperProps } from '@mui/material/Paper';
import { Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import { isArray } from 'lodash';
import InputLoader from '../InputLoader';
import SelectOption, { Option, OptionValue } from '../SelectOption';
import { mapOptionsToValue, mapValueToOptionsFreeSolo } from './utils';
import { SelectedValue, SelectedOptions } from './types';
import { updateEventTargetValue } from '../../utils';

type Props<T> = Partial<
  Omit<AutocompleteProps<Option<T>, boolean, undefined, boolean>, 'value'>
> & {
  value?: SelectedValue<T>;
  freeSolo?: boolean;
  selectedOptions?: SelectedOptions<T>;
  label?: ReactNode;
  placeholder?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  autocompleteRows?: number;
  error?: boolean;
  helperText?: ReactNode;
  loading?: boolean;
  multiple?: boolean;
  required?: boolean;
};

function AutoCompleteWithFreeText<T extends OptionValue = OptionValue>(
  {
    id,
    value,
    freeSolo = true,
    selectedOptions,
    options = [],
    label,
    placeholder,
    disabled,
    loading,
    autocompleteRows = 1,
    error,
    helperText,
    multiple,
    required,
    onChange,
    ...rest
  }: Props<T>,
  ref: ForwardedRef<HTMLSelectElement>
) {
  const [open, setOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState(value || []);
  const mappedValue = useMemo(
    () =>
      mapValueToOptionsFreeSolo<T>(selectedItems, options, multiple, freeSolo),
    [selectedItems, options, multiple]
  );
  const minHeight = autocompleteRows * 40;

  useEffect(() => {
    setSelectedItems(value || []);
  }, [value, multiple, options, selectedOptions]);

  const handleChange = (event, optionValues) => {
    if (onChange) {
      optionValues = mapOptionsToValue<T>(optionValues);
      const duplicate = optionValues.filter((val, id, array) => {
        if (array.indexOf(val) !== id) {
          return val;
        }
        return null;
      });
      const newValue = optionValues.filter((val) => val !== duplicate[0]);
      onChange(updateEventTargetValue(event, id, newValue));
      setSelectedItems(newValue);
    }
  };

  const openDropdown = () => {
    setOpen(true);
  };

  const closeDropdown = () => {
    setOpen(false);
  };

  return (
    <MuiAutocomplete<Option<T>, boolean, undefined, boolean>
      autoComplete
      freeSolo={freeSolo}
      data-testid="autocomplete"
      className={clsx({ 'Mui-disabled': disabled })}
      disabled={disabled}
      value={mappedValue}
      disableCloseOnSelect={true}
      loading={loading}
      multiple={multiple}
      open={open}
      onOpen={openDropdown}
      onClose={closeDropdown}
      onChange={(event, newValue) => {
        const optionValues =
          newValue && isArray(newValue)
            ? newValue.map((option) => {
                if (typeof option === 'string') {
                  return { label: option, value: option };
                }
                return option;
              })
            : newValue;
        handleChange(event, optionValues);
      }}
      options={options}
      ref={ref}
      renderOption={(props, option) => {
        props['aria-selected'] = false;
        const isSelected =
          mappedValue &&
          isArray(mappedValue) &&
          mappedValue.find((m) => m.value === option.value.toString());
        return (
          <SelectOption<T>
            component="div"
            key={option.value}
            id={id}
            selected={!!isSelected}
            multiple={true}
            {...props}
            {...option}
          />
        );
      }}
      componentsProps={{
        paper: { 'data-testid': 'autocomplete-options' } as PaperProps,
      }}
      renderInput={({ InputProps, ...rest }) => (
        <TextField
          data-testid="autocomplete-textfield"
          error={error}
          helperText={helperText}
          label={label}
          placeholder={placeholder}
          required={required}
          onKeyDown={(event) => {
            if (event.code === 'Enter') {
              event.preventDefault();
            }
          }}
          InputProps={
            {
              ...InputProps,
              style:
                autocompleteRows > 1
                  ? {
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start',
                      minHeight,
                    }
                  : {},
              startAdornment: (
                <>
                  <Box style={autocompleteRows > 1 ? { marginTop: 20 } : {}}>
                    <InputLoader
                      data-testid="autocomplete-loader"
                      loading={loading}
                    />
                    <InputAdornment position="end">
                      <SearchIcon onClick={openDropdown} />
                    </InputAdornment>
                  </Box>
                  {InputProps.startAdornment}
                </>
              ),
              'data-testid': 'autocomplete-input',
            } as InputProps
          }
          {...rest}
        />
      )}
      {...rest}
    />
  );
}

export default forwardRef(
  AutoCompleteWithFreeText
) as typeof AutoCompleteWithFreeText;
