import ModalDialog from 'src/components/ModalDialog';
import { useForm } from 'src/components/Form';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import { useStoreUnitActions } from 'src/redux/helpers';
import { RequestMethod, pepApiRequest, useStatefulApi } from 'src/services';
import { generatePath, useParams } from 'react-router-dom';
import { whitelistFileFormFields } from '../formFields';
import { SolutionFilesWhitelistItem } from '../../types';
import { API_WHITELIST } from '../../constants';
import solutionsStore from '../../redux';

type Props = {
  title: string;
  item: SolutionFilesWhitelistItem;
  open: boolean;
  onClose: () => void;
};

function SolutionFilesWhitelistEditModal({
  item,
  title,
  open,
  onClose,
}: Props) {
  const { solutionId } = useParams();
  const { showAlert } = useAlert();
  const actions = useStoreUnitActions(solutionsStore);
  const [onSubmit, loading] = useStatefulApi(
    (params) =>
      pepApiRequest({
        method: RequestMethod.Put,
        url: generatePath(API_WHITELIST, { solutionId }),
        params: { whitelistedExtensions: [params] },
      }),
    () => {
      showAlert({
        type: AlertType.Info,
        title: 'Whitelist is Saved',
        text: 'File extention whitelist has been saved successfully',
      });
      actions.filesWhitelist(solutionId);
      onClose();
    }
  );

  const [form, handleSubmit] = useForm({
    fields: whitelistFileFormFields,
    values: item,
    onSubmit,
  });

  return (
    <ModalDialog
      open={open}
      title={title}
      loading={loading}
      onClose={onClose}
      onConfirm={handleSubmit}
      confirmBtnText="Save"
    >
      {form}
    </ModalDialog>
  );
}

export default SolutionFilesWhitelistEditModal;
