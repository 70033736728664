import { useEffect, useState } from 'react';
import ModalDialog from 'src/components/ModalDialog';
import { useForm } from 'src/components/Form';
import { RequestMethod, pepApiRequest, useStatefulApi } from 'src/services';
import { generatePath, useParams } from 'react-router-dom';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import { useStoreUnitActions } from 'src/redux/helpers';
import { API_SITES_CREATE_EDGE_SERVICE } from '../../constants';
import { SiteEdgeServiceFormFields } from '../formFields';
import sitesStore, { useSiteDetails } from '../../redux';
import { SiteEdgeServiceItem } from '../../types';

type Props = {
  siteId: string;
  siteName: string;
  title: string;
  item: SiteEdgeServiceItem;
  open: boolean;
  onClose: () => void;
};

function SiteEdgeServiceUrlCreateModal({
  siteId,
  siteName,
  item,
  title,
  open,
  onClose,
}: Props) {
  const { showAlert } = useAlert();
  const actions = useStoreUnitActions(sitesStore);
  const [onSubmit, loading] = useStatefulApi(
    (params) =>
      pepApiRequest({
        method: RequestMethod.Post,
        url: generatePath(API_SITES_CREATE_EDGE_SERVICE, {
          siteId
        }),
        params: { endpoint: params.edgeServiceUrl },
      }),
    () => {
      showAlert({
        type: AlertType.Success,
        title: 'Edge Service URL added',
        text: `Edge Service URL for ${siteName} site has been added successfully.`,
      });
      onClose();
      actions.list();
    }
  );

  const [form, handleSubmit] = useForm({
    values: item,
    fields: SiteEdgeServiceFormFields(),
    onSubmit,
  });

  return (
    <ModalDialog
      data-testid="modal-dialog"
      open={open}
      title="Add Edge Service URL"
      loading={loading}
      onClose={onClose}
      onConfirm={handleSubmit}
      confirmBtnText="Submit"
      fullWidth
    >
      {form}
    </ModalDialog>
  );
}

export default SiteEdgeServiceUrlCreateModal;
