import React, { ChangeEvent, ForwardedRef, forwardRef, ReactNode } from 'react';
import toString from 'lodash/toString';
import FormControlLabel, {
  FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { Box, Tooltip } from '@mui/material';

type Props = FormControlProps &
  Pick<FormControlLabelProps, 'labelPlacement'> & {
    label?: ReactNode;
    type?: string;
    value?: boolean;
    onChange?: (event: ChangeEvent<HTMLButtonElement>) => void;
    helperText?: ReactNode;
    showInfoIcon?: boolean;
    infoTitle?: any;
  };

function SwitchButton(
  {
    value = false,
    type = 'checkbox',
    label,
    labelPlacement,
    onChange,
    helperText,
    showInfoIcon,
    infoTitle,
    ...rest
  }: Props,
  ref: ForwardedRef<HTMLButtonElement>
) {
  const handleChange = (event) => {
    if (onChange) {
      event.target.value = event.target.checked;
      onChange(event);
    }
  };

  return (
    <FormControl variant="standard" {...rest}>
      <Box alignItems="center" display="flex">
        <FormControlLabel
          control={
            <Switch
              data-testid="switch"
              type={type}
              checked={value}
              onChange={handleChange}
              ref={ref}
            />
          }
          label={toString(label)}
          labelPlacement={labelPlacement}
        />
        {showInfoIcon && (
          <Tooltip
            title={infoTitle}
            placement="right"
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: '#24242499',
                  color: '#fff',
                  border: 'none',
                },
              },
              arrow: {
                sx: {
                  '::before': {
                    border: 'none',
                    backgroundColor: '#24242499',
                  },
                },
              },
            }}
          >
            <InfoOutlined fontSize="small" color="info" />
          </Tooltip>
        )}
      </Box>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}

export default forwardRef(SwitchButton);
