import { createDatasetSlice } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import {
  API_SOLUTIONS,
  SOLUTIONS_STORE,
  SOLUTIONS_STORE_LIST,
} from '../constants';
import { mapSolutionsListData } from './utils';

const api = ({ queryParams, switchValue }) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: API_SOLUTIONS,
    queryParams,
  }).then((items) => mapSolutionsListData(items, switchValue));

const solutionsListSlice = createDatasetSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTIONS_STORE_LIST,
});

export default solutionsListSlice;
