export type DatasetUnit<S> = S & {
  error?: string;
  loading?: boolean;
};

export type DefaultDatasetUnit<S> = DatasetUnit<{ data: S }>;

export enum ResponseMapping {
  Empty = 'empty',
  Default = 'default',
}

export type SagaAction = (action: any) => any;

export type SliceParams<T> = {
  storeName: string;
  api: (value: any) => Promise<any>;
  actionName?: string;
  selectors?: { [key: string]: (state: any) => any };
  sagas?: { [actionType: string]: SagaAction };
  reducers?: any;
  datasetHashKey?: ((T: T) => string) | string;
  initialState?: T;
  responseMapping?: ResponseMapping;
};
