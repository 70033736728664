import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Add from '@mui/icons-material/Add';
import DataTable, {
  DataTableWrapper,
  DataTableWrapperActionButton,
  getActionsColConfig,
} from 'src/components/DataTable';
import { isModalComplete } from 'src/components/ModalDialog';
import { useStoreUnitActions } from 'src/redux/helpers';
import { useModal, useScrollObserver } from 'src/hooks';
import { loadDataFromStorage } from 'src/utils';
import SolutionDeviceGroupsActions from './components/SolutionDeviceGroupsActions';
import SolutionDeviceGroupCreateContainer from './components/SolutionDeviceGroupCreateContainer';
import { useSolutionUserRoles } from '../hooks';
import { SolutionDeviceGroupItemField, SolutionType } from '../types';
import solutionsStore, { useSolutionDeviceGroups } from '../redux';

export default function SolutionDeviceGroups() {
  const { solutionId } = useParams();
  const { isSolutionEditor } = useSolutionUserRoles();
  const { deviceGroups: actions } = useStoreUnitActions(solutionsStore);
  const [data, loading, skipToken] = useSolutionDeviceGroups();
  const solutionType = loadDataFromStorage('solutionType');

  const ioTHColumns = [
    {
      id: SolutionDeviceGroupItemField.DisplayName,
      title: 'Group name',
    },
    {
      id: SolutionDeviceGroupItemField.Description,
      title: 'Description',
    },
    {
      id: SolutionDeviceGroupItemField.CompanyName,
      title: 'Company',
    },
    getActionsColConfig(SolutionDeviceGroupsActions),
  ];

  const columns = [
    {
      id: SolutionDeviceGroupItemField.DisplayName,
      title: 'Group name',
    },
    {
      id: SolutionDeviceGroupItemField.Description,
      title: 'Description',
    },
    {
      id: SolutionDeviceGroupItemField.CompanyId,
      title: 'Company',
    },
    getActionsColConfig(SolutionDeviceGroupsActions),
  ];

  useEffect(() => {
    actions.init({ solutionId });
    return () => actions.clear();
  }, [solutionId]);

  const scrollElementRef = useScrollObserver(
    () => !loading && skipToken && actions.update({ solutionId, skipToken }),
    [solutionId, loading, skipToken]
  );

  const [CreateDeviceGroupDialog, showCreateDeviceGroupDialog] = useModal(
    SolutionDeviceGroupCreateContainer,
    {
      onClose: (event, reason) =>
        isModalComplete(reason) && actions.init({ solutionId }),
    }
  );
  const updatedData = data.map((item) => ({
    ...item,
    description: item.description ? item.description : '–',
    companyName: item.companyName ? item.companyName : '–',
  }));

  return (
    <DataTableWrapper
      title="Device groups"
      actionsPanel={
        <DataTableWrapperActionButton
          onClick={showCreateDeviceGroupDialog}
          startIcon={<Add />}
          disabled={!isSolutionEditor}
        >
          New group
        </DataTableWrapperActionButton>
      }
    >
      <DataTable
        ref={scrollElementRef}
        data={updatedData}
        columns={SolutionType.ioTH === solutionType ? ioTHColumns : columns}
        loading={loading}
        paging={false}
      />
      {CreateDeviceGroupDialog}
    </DataTableWrapper>
  );
}
