import React from 'react';
import { useCurrentSiteName } from '../../redux';

type Props = {
  title?: string;
};

function SiteDetailsTitle({ title }: Props) {
  const siteName = useCurrentSiteName();

  return <>{siteName || title}</>;
}

export default SiteDetailsTitle;
