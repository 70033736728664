import { generatePath } from 'react-router-dom';
import { createDatasetSlice, ResponseMapping } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import { loadDataFromStorage } from 'src/utils';
import { mapSolutionDetailsData } from './utils';
import { SolutionType } from '../types';
import {
  API_SOLUTION_DETAILS,
  API_SOLUTION_DETAILS_IOTH,
  SOLUTIONS_STORE,
  SOLUTIONS_STORE_DETAILS,
} from '../constants';

const api = (solutionId: string) => {
  const solutionType = loadDataFromStorage('solutionType');
  return pepApiRequest({
    method: RequestMethod.Get,
    url: generatePath(
      solutionType === SolutionType.ioTH
        ? API_SOLUTION_DETAILS_IOTH
        : API_SOLUTION_DETAILS,
      { solutionId }
    ),
  }).then((response) => mapSolutionDetailsData(response));
};

const solutionsDetailsSlice = createDatasetSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTIONS_STORE_DETAILS,
  responseMapping: ResponseMapping.Default,
});

export default solutionsDetailsSlice;
