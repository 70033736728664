import React from 'react';
import LinearProgressWithLabel from 'src/components/LinearProgressWithLabel';

type Props = {
  value?: string[];
};

function SiteNamespaceQuotasUsage({ value = [] }: Props) {
  const actual = value[0] ? Number(value[0].replace(/[^0-9]/g, '')) : 0;
  let used = value[1] ? Number(value[1].replace(/[^0-9]/g, '')) : 0;
  if (value[1]) {
    if (value[1].includes('Gi')) {
      used *= 1024;
    }
  }
  const message = `${value[1]} used out of ${actual}`;

  return (
    <LinearProgressWithLabel
      actual={actual}
      used={used}
      message={message}
      width={'13em'}
    />
  );
}

export default SiteNamespaceQuotasUsage;
