import { ChangeEvent, useState } from 'react';
import * as Yup from 'yup';
import compact from 'lodash/compact';
import TextField from '@mui/material/TextField';
import Select from 'src/components/Select';
import CheckboxGroup from 'src/components/CheckboxGroup';
import { endsWith } from 'lodash';
import RadioButtonGroup from 'src/components/RadioButtonGroup';
import {
  PEP_EMAIL_DOMAIN_PEPSICO,
  PEP_EMAIL_DOMAIN_PEPSICORPTST,
} from 'src/constants';
import { Option } from 'src/components/SelectOption';
import AutoCompleteWithFreeText from 'src/components/AutoComplete/AutoCompleteWithFreeText';
import { nanToUndefined } from 'src/utils';
import {
  LOCATION_OPTIONS,
  DEVICE_TYPE_OPTIONS,
  KIBANA_SPACE_TYPE_OPTIONS,
  TELEMETRY_STREAM_PROCESSING_OPTIONS,
  FILE_SUBSCRIPTION_OPTIONS,
  SUBSCRIPTION_TYPE_OPTIONS,
} from './constants';
import {
  KibanaSpaceType,
  SolutionConsumerFeatures,
  SolutionItemField,
  SolutionType,
} from '../types';

export const GetNewConsumerEditSolutionFormFields = (
  solutionType: SolutionType,
  isEditMode?: boolean,
  solClientIds?: string[],
  options?: Option[],
  isSolutionOwner?: boolean,
  isMenderEnabled?: boolean,
  createSmsAccount?: boolean,
  handleMenderEnable?: (event: ChangeEvent<HTMLInputElement>) => void,
  handleSmsNotifyEnable?: (event: ChangeEvent<HTMLInputElement>) => void,
  cachePeriod?: number,
  retentionPeriod?: number,
  disableCacheLimit?: boolean,
  disableRetentionLimit?: boolean,
  handleCacheLimit?: (event: ChangeEvent<HTMLInputElement>) => void,
  handleRetentionLimit?: (event: ChangeEvent<HTMLInputElement>) => void,
  handleUnlimitedCache?: (event: ChangeEvent<HTMLInputElement>) => void,
  handleUnlimitedRetention?: (event: ChangeEvent<HTMLInputElement>) => void,
  handleReleasePrefix?: (event: ChangeEvent<HTMLInputElement>) => void,
  disableCacheRetention?: boolean,
  kibanaValue?: string,
  isDataIngested?: boolean,
  isReleasePrefix?: boolean,
  releasePrefix?: string,
  isDataIngestionEnabled?: boolean,
  handleFeaturesChange?: (event: ChangeEvent<HTMLInputElement>) => void,
  SOLUTION_FEATURES_CONSUMER?: any
) => {
  const [kibanaRadioValue, setkibanaValue] = useState<any>(kibanaValue);
  const handleKibanaSpaceType = (event) => {
    setkibanaValue(event.target.value);
  };
  const setValue = (): string => {
    if (
      (!kibanaRadioValue &&
        kibanaValue === KibanaSpaceType.Global.toLowerCase()) ||
      kibanaRadioValue === KibanaSpaceType.Global.toLowerCase()
    )
      return KibanaSpaceType.Global;
    if (
      (!kibanaRadioValue &&
        kibanaValue === KibanaSpaceType.Confidential.toLowerCase()) ||
      kibanaRadioValue === KibanaSpaceType.Confidential.toLowerCase()
    )
      return KibanaSpaceType.Confidential;
    return kibanaRadioValue;
  };

  return compact([
    {
      name: SolutionItemField.displayName,
      label: 'Solution name',
      placeholder: 'Enter solution name',
      Component: TextField,
      validation: Yup.string().required(
        'Solution display name field is required'
      ),
      required: true,
    },
    {
      name: SolutionItemField.IoTHubName,
      label: 'IoT Hub name',
      placeholder: 'Enter IoT Hub name',
      Component: TextField,
      validation: Yup.string()
        .required('IoT Hub name field is required')
        .max(20, 'IOT Hub name cannot be more than 20 characters')
        .matches(
          /^[a-z0-9-]+$/,
          `IoT Hub Name should contain only [a-z], [0-9], and ${"'-'"} characters`
        ),
      disabled: isEditMode,
      required: true,
    },
    {
      name: SolutionItemField.Location,
      label: 'Location',
      placeholder: 'Enter location',
      Component: Select,
      options: LOCATION_OPTIONS,
      validation: Yup.string().required('Location field is required'),
      disabled: isEditMode,
      required: true,
    },
    {
      name: SolutionItemField.CostCenter,
      label: 'Cost center',
      placeholder: 'Enter cost center',
      Component: TextField,
      validation: Yup.string().required('Cost center field is required'),
      required: true,
    },
    {
      name: SolutionItemField.ClientId,
      label: 'Solution App/Client ID',
      placeholder: isEditMode ? '' : 'Enter Solution App/Client ID',
      Component: AutoCompleteWithFreeText,
      validation: Yup.array()
        .nullable()
        .min(1)
        .required('Solution App/Client ID field is required'),
      options,
      multiple: true,
      value: solClientIds,
      selectedOptions: solClientIds,
      required: true,
      disabled: !isSolutionOwner,
    },
    {
      name: SolutionItemField.BusinessOwner,
      label: 'Business Owner',
      placeholder: 'Enter Business Owner',
      Component: TextField,
      required: true,
      validation: !isEditMode
        ? Yup.string()
            .required('Business Owner field is required')
            .email('Must be a valid email')
            .test(
              'allowed-Domain',
              `Email should belong to the domain ${PEP_EMAIL_DOMAIN_PEPSICO} or ${PEP_EMAIL_DOMAIN_PEPSICORPTST}`,
              (value) =>
                endsWith(value, `@${PEP_EMAIL_DOMAIN_PEPSICO}`) ||
                endsWith(value, `@${PEP_EMAIL_DOMAIN_PEPSICORPTST}`)
            )
        : Yup.string().required('Business Owner field is required'),
      initialValue: !isEditMode ? `@${PEP_EMAIL_DOMAIN_PEPSICO}` : '----',
      disabled: !isSolutionOwner,
    },
    {
      name: SolutionItemField.deviceType,
      label: 'Device type',
      Component: RadioButtonGroup,
      validation: Yup.string().required('Device Type Required'),
      required: true,
      options: DEVICE_TYPE_OPTIONS,
      disabled: isEditMode,
    },
    {
      name: SolutionItemField.KibanaSpaceType,
      label: 'Kibana Monitoring Space',
      Component: RadioButtonGroup,
      validation: Yup.string().required('Kibana Monitoring Space is Required'),
      required: true,
      initialValue: setValue(),
      options: KIBANA_SPACE_TYPE_OPTIONS,
      onChange: handleKibanaSpaceType,
    },
    {
      name: SolutionItemField.SolutionConsumerFeatures,
      label: 'Features',
      Component: CheckboxGroup,
      onChange: handleFeaturesChange,
      type: 'checkbox-group',
      required: true,
      validation: Yup.array()
        .test(
          'features Allowed',
          'At least one selection is required',
          (value) => {
            if (!value) return false;
            const filtervalue = value.filter(
              (item) => item !== 'isSmsAccountCreation'
            );
            return filtervalue.length > 0;
          }
        )
        .required('At least one selection is required')
        .min(1, 'At least one selection is required'),
      labelStyle: { mr: 3 },
      options: SOLUTION_FEATURES_CONSUMER,
      showTooltip: true,
    },
    (isDataIngested || isDataIngestionEnabled) && {
      name: SolutionItemField.CachePeriod,
      label: 'Cache Period',
      placeholder: 'Enter the values in days',
      value: disableCacheLimit ? '' : cachePeriod,
      initialValue: '30',
      Component: TextField,
      onChange: handleCacheLimit,
      InputProps: { inputProps: { min: 1 } },
      validation: disableCacheLimit
        ? Yup.string().notRequired()
        : Yup.number()
            .transform(nanToUndefined)
            .min(1, 'Cache period must be in range 1-365')
            .max(365, 'Cache period must be in range 1-365')
            .required('Cache Period field is required'),
      type: disableCacheLimit ? 'text' : 'number',
      required: !disableCacheLimit,
      disabled: disableCacheLimit,
    },
    (isDataIngested || isDataIngestionEnabled) && {
      name: SolutionItemField.UnlimitedCache,
      Component: CheckboxGroup,
      type: 'checkbox-group',
      required: false,
      labelStyle: { mr: 3 },
      onChange: handleUnlimitedCache,
      disabled: disableCacheRetention,
      options: [
        { label: 'Unlimited days for Cache period', value: 'UnlimitedCache' },
      ],
    },
    (isDataIngested || isDataIngestionEnabled) && {
      name: SolutionItemField.RetentionPeriod,
      label: 'Retention Period',
      placeholder: 'Enter the values in days',
      value: disableRetentionLimit ? '' : retentionPeriod,
      initialValue: '30',
      onChange: handleRetentionLimit,
      Component: TextField,
      InputProps: { inputProps: { min: 1 } },
      validation: disableRetentionLimit
        ? Yup.string().notRequired()
        : Yup.number()
            .transform(nanToUndefined)
            .min(1, 'Retention Period must be in range 1-365')
            .max(365, 'Retention Period must be in range 1-365')
            .required('Retention Period field is required'),
      type: disableRetentionLimit ? 'text' : 'number',
      required: !disableRetentionLimit,
      disabled: disableRetentionLimit,
    },
    (isDataIngested || isDataIngestionEnabled) && {
      name: SolutionItemField.UnlimitedRetention,
      Component: CheckboxGroup,
      type: 'checkbox-group',
      required: false,
      labelStyle: { mr: 3 },
      onChange: handleUnlimitedRetention,
      disabled: disableCacheRetention,
      options: [
        {
          label: 'Unlimited days for Retention period',
          value: 'UnlimitedRetention',
        },
      ],
    },
    {
      name: SolutionItemField.SolutionType,
      sx: { display: 'none' },
      Component: TextField,
      initialValue: '2',
      required: false,
    },

    (isMenderEnabled || isReleasePrefix) && {
      name: SolutionConsumerFeatures.ReleasePrefix,
      label: 'Mender Release prefix',
      Component: TextField,
      placeholder: 'Enter release prefix',
      required: isMenderEnabled,
      onChange: handleReleasePrefix,
      value: releasePrefix,
      InputProps: { inputProps: { min: 1 } },
      validation:
        !isMenderEnabled || !releasePrefix
          ? Yup.string().required('Release prefix name is required')
          : undefined,
    },
  ]);
};

export const getEditSolutionFormFields = (
  solutionType: SolutionType,
  isEditMode?: boolean,
  solClientIds?: string[],
  options?: Option[],
  isSolutionOwner?: boolean,
  isMenderEnabled?: boolean,
  createSmsAccount?: boolean,
  handleMenderEnable?: (event: ChangeEvent<HTMLInputElement>) => void,
  handleSmsNotifyEnable?: (event: ChangeEvent<HTMLInputElement>) => void,
  cachePeriod?: number,
  retentionPeriod?: number,
  disableCacheLimit?: boolean,
  disableRetentionLimit?: boolean,
  handleCacheLimit?: (event: ChangeEvent<HTMLInputElement>) => void,
  handleRetentionLimit?: (event: ChangeEvent<HTMLInputElement>) => void,
  handleUnlimitedCache?: (event: ChangeEvent<HTMLInputElement>) => void,
  handleUnlimitedRetention?: (event: ChangeEvent<HTMLInputElement>) => void,
  disableCacheRetention?: boolean,
  kibanaSpaceType?: string,
  isDataIngested?: boolean,
  isDataIngestionEnabled?: boolean,
  handleFeaturesChange?: (event: ChangeEvent<HTMLInputElement>) => void,
  SOLUTION_FEATURES_CONSUMER?: any
) =>
  compact([
    {
      name: SolutionItemField.displayName,
      label: 'Solution name',
      placeholder: 'Enter solution name',
      Component: TextField,
      validation: Yup.string().required(
        'Solution display name field is required'
      ),
      required: true,
    },
    {
      name: SolutionItemField.IoTCentralName,
      label: 'IoT Central name',
      placeholder: 'Enter IoT Central name',
      Component: TextField,
      validation: Yup.string()
        .required('IoT Central name field is required')
        .max(20, 'IOT Central name cannot be more than 20 characters')
        .matches(
          /^[a-z0-9-]+$/,
          `IoT Central Name should contain only [a-z], [0-9], and ${"'-'"} characters`
        ),
      disabled: isEditMode,
      required: true,
    },
    {
      name: SolutionItemField.Location,
      label: 'Location',
      placeholder: 'Enter location',
      Component: Select,
      options: LOCATION_OPTIONS,
      validation: Yup.string().required('Location field is required'),
      disabled: isEditMode,
      required: true,
    },
    {
      name: SolutionItemField.CostCenter,
      label: 'Cost center',
      placeholder: 'Enter cost center',
      Component: TextField,
      validation: Yup.string().required('Cost center field is required'),
      required: true,
    },
    {
      name: SolutionItemField.ClientId,
      label: 'Solution App/Client ID',
      placeholder: isEditMode ? '' : 'Enter Solution App/Client ID',
      Component: AutoCompleteWithFreeText,
      validation: Yup.array()
        .nullable()
        .min(1)
        .required('Solution App/Client ID field is required'),
      options,
      multiple: true,
      value: solClientIds,
      selectedOptions: solClientIds,
      required: true,
      disabled: !isSolutionOwner,
    },
    {
      name: SolutionItemField.BusinessOwner,
      label: 'Business Owner',
      placeholder: 'Enter Business Owner',
      Component: TextField,
      required: true,
      validation: !isEditMode
        ? Yup.string()
            .required('Business Owner field is required')
            .email('Must be a valid email')
            .test(
              'allowed-Domain',
              `Email should belong to the domain ${PEP_EMAIL_DOMAIN_PEPSICO} or ${PEP_EMAIL_DOMAIN_PEPSICORPTST}`,
              (value) =>
                endsWith(value, `@${PEP_EMAIL_DOMAIN_PEPSICO}`) ||
                endsWith(value, `@${PEP_EMAIL_DOMAIN_PEPSICORPTST}`)
            )
        : Yup.string().required('Business Owner field is required'),
      initialValue: !isEditMode ? `@${PEP_EMAIL_DOMAIN_PEPSICO}` : '----',
      disabled: !isSolutionOwner,
    },
    {
      name: SolutionItemField.deviceType,
      label: 'Device type',
      Component: RadioButtonGroup,
      validation: Yup.string().required('Device Type Required'),
      required: true,
      options: DEVICE_TYPE_OPTIONS,
      disabled: isEditMode,
    },
    {
      name: SolutionItemField.KibanaSpaceType,
      label: 'Kibana Monitoring Space',
      Component: RadioButtonGroup,
      validation: Yup.string().required('Kibana Monitoring Space is Required'),
      required: true,
      initialValue: kibanaSpaceType,
      options: KIBANA_SPACE_TYPE_OPTIONS,
    },
    {
      name: SolutionItemField.SolutionConsumerFeatures,
      label: 'Features',
      Component: CheckboxGroup,
      onChange: handleFeaturesChange,
      type: 'checkbox-group',
      labelStyle: { mr: 3 },
      options: SOLUTION_FEATURES_CONSUMER,
      showTooltip: true,
    },
    {
      name: SolutionItemField.SolutionType,
      sx: { display: 'none' },
      Component: TextField,
      initialValue: '0',
      required: false,
    },
    (isDataIngested || isDataIngestionEnabled) && {
      name: SolutionItemField.CachePeriod,
      label: 'Cache Period',
      placeholder: 'Enter the values in days',
      value: disableCacheLimit ? '' : cachePeriod,
      initialValue: '30',
      Component: TextField,
      onChange: handleCacheLimit,
      InputProps: { inputProps: { min: 1 } },
      validation: disableCacheLimit
        ? Yup.string().notRequired()
        : Yup.number()
            .transform(nanToUndefined)
            .min(1, 'Cache period must be in range 1-365')
            .max(365, 'Cache period must be in range 1-365')
            .required('Cache Period field is required'),
      type: disableCacheLimit ? 'text' : 'number',
      required: !disableCacheLimit,
      disabled: disableCacheLimit,
    },
    (isDataIngested || isDataIngestionEnabled) && {
      name: SolutionItemField.UnlimitedCache,
      Component: CheckboxGroup,
      type: 'checkbox-group',
      required: false,
      labelStyle: { mr: 3 },
      onChange: handleUnlimitedCache,
      disabled: disableCacheRetention,
      options: [
        { label: 'Unlimited days for Cache period', value: 'UnlimitedCache' },
      ],
    },
    (isDataIngested || isDataIngestionEnabled) && {
      name: SolutionItemField.RetentionPeriod,
      label: 'Retention Period',
      placeholder: 'Enter the values in days',
      value: disableRetentionLimit ? '' : retentionPeriod,
      initialValue: '30',
      onChange: handleRetentionLimit,
      Component: TextField,
      InputProps: { inputProps: { min: 1 } },
      validation: disableRetentionLimit
        ? Yup.string().notRequired()
        : Yup.number()
            .transform(nanToUndefined)
            .min(1, 'Retention Period must be in range 1-365')
            .max(365, 'Retention Period must be in range 1-365')
            .required('Retention Period field is required'),
      type: disableRetentionLimit ? 'text' : 'number',
      required: !disableRetentionLimit,
      disabled: disableRetentionLimit,
    },
    (isDataIngested || isDataIngestionEnabled) && {
      name: SolutionItemField.UnlimitedRetention,
      Component: CheckboxGroup,
      type: 'checkbox-group',
      required: false,
      labelStyle: { mr: 3 },
      onChange: handleUnlimitedRetention,
      disabled: disableCacheRetention,
      options: [
        {
          label: 'Unlimited days for Retention period',
          value: 'UnlimitedRetention',
        },
      ],
    },
  ]);

export const getIndustrialSolutionFormFields = (
  solClientIds?: string[],
  options?: Option[],
  isSolutionOwner?: boolean,
  createSmsAccount?: boolean,
  handleSmsNotifyEnable?: (event: ChangeEvent<HTMLInputElement>) => void,
  cachePeriod?: number,
  retentionPeriod?: number,
  disableCacheLimit?: boolean,
  disableRetentionLimit?: boolean,
  handleCacheLimit?: (event: ChangeEvent<HTMLInputElement>) => void,
  handleRetentionLimit?: (event: ChangeEvent<HTMLInputElement>) => void,
  handleUnlimitedCache?: (event: ChangeEvent<HTMLInputElement>) => void,
  handleUnlimitedRetention?: (event: ChangeEvent<HTMLInputElement>) => void,
  disableCacheRetention?: boolean,
  kibanaSpaceType?: string,
  isDataIngested?: boolean,
  isDataIngestionEnabled?: boolean,
  isSubscriptionService?: boolean,
  isSubscriptionServicetype?: boolean,
  isFileSubscriptionType?: boolean,
  isFileSubscriptionServicetype?: boolean,
  isTelemetryStreamProcessing?: boolean,
  isTelemetryStreamProcessingtype?: boolean,
  handleIndustrialFeatures?: (event: ChangeEvent<HTMLInputElement>) => void,
  handleSubscriptionService?: (event: ChangeEvent<HTMLInputElement>) => void,
  SOLUTION_FEATURES_INDUSTRIAL?: any,
  SUBSCIPTION_TYPE_OPTIONS?: any
) =>
  compact([
    {
      name: SolutionItemField.displayName,
      label: 'Solution name',
      placeholder: 'Enter solution name',
      Component: TextField,
      validation: Yup.string().required(
        'Solution display name field is required'
      ),
      required: true,
    },
    {
      name: SolutionItemField.CostCenter,
      label: 'Cost center',
      placeholder: 'Enter cost center',
      Component: TextField,
      validation: Yup.string().required('Cost center field is required'),
      required: true,
    },
    {
      name: SolutionItemField.ClientId,
      label: 'Solution App/Client ID',
      placeholder: 'Enter Solution App/Client ID',
      Component: AutoCompleteWithFreeText,
      validation: Yup.array()
        .nullable()
        .min(1)
        .required('Solution App/Client ID field is required'),
      options,

      multiple: true,
      value: solClientIds,
      selectedOptions: solClientIds,
      required: true,
      disabled: !isSolutionOwner,
    },
    {
      name: SolutionItemField.BusinessOwner,
      label: 'Business Owner',
      placeholder: 'Enter Business Owner',
      Component: TextField,
      required: true,
      validation: Yup.string()
        .required('Business Owner field is required')
        .email('Must be a valid email')
        .test(
          'allowed-Domain',
          `Email should belong to the domain ${PEP_EMAIL_DOMAIN_PEPSICO} or ${PEP_EMAIL_DOMAIN_PEPSICORPTST}`,
          (value) =>
            endsWith(value, `@${PEP_EMAIL_DOMAIN_PEPSICO}`) ||
            endsWith(value, `@${PEP_EMAIL_DOMAIN_PEPSICORPTST}`)
        ),
      initialValue: `@${PEP_EMAIL_DOMAIN_PEPSICO}`,
      disabled: !isSolutionOwner,
    },
    {
      name: SolutionItemField.ADGroup,
      label: 'AD Group',
      placeholder: 'Enter the group name',
      Component: TextField,
      validation: Yup.string().required('AD Group field is required'),
      required: true,
    },
    {
      name: SolutionItemField.SolutionType,
      sx: { display: 'none' },
      Component: TextField,
      initialValue: '1',
      required: false,
    },
    {
      name: SolutionItemField.KibanaSpaceType,
      label: 'Kibana Monitoring Space',
      Component: RadioButtonGroup,
      validation: Yup.string().required('Kibana Monitoring Space is Required'),
      required: true,
      initialValue: kibanaSpaceType,
      options: KIBANA_SPACE_TYPE_OPTIONS,
    },
    {
      name: SolutionItemField.SolutionIndustrialFeatures,
      label: 'Features',
      Component: CheckboxGroup,
      onChange: handleIndustrialFeatures,
      type: 'checkbox-group',
      labelStyle: { mr: 3 },
      options: SOLUTION_FEATURES_INDUSTRIAL,
      showTooltip: true,
    },
    (isDataIngested || isDataIngestionEnabled) && {
      name: SolutionItemField.CachePeriod,
      label: 'Cache Period',
      placeholder: 'Enter the values in days',
      value: disableCacheLimit ? '' : cachePeriod,
      initialValue: '30',
      onChange: handleCacheLimit,
      Component: TextField,
      InputProps: { inputProps: { min: 1 } },
      validation: disableCacheLimit
        ? Yup.string().notRequired()
        : Yup.number()
            .transform(nanToUndefined)
            .min(1, 'Cache period must be in range 1-365')
            .max(365, 'Cache period must be in range 1-365')
            .required('Cache Period field is required'),
      type: disableCacheLimit ? 'text' : 'number',
      required: !disableCacheLimit,
      disabled: disableCacheLimit,
    },
    (isDataIngested || isDataIngestionEnabled) && {
      name: SolutionItemField.UnlimitedCache,
      Component: CheckboxGroup,
      type: 'checkbox-group',
      required: false,
      labelStyle: { mr: 3 },
      disabled: disableCacheRetention,
      onChange: handleUnlimitedCache,
      options: [
        { label: 'Unlimited days for Cache period', value: 'UnlimitedCache' },
      ],
    },
    (isDataIngested || isDataIngestionEnabled) && {
      name: SolutionItemField.RetentionPeriod,
      label: 'Retention Period',
      placeholder: 'Enter the values in days',
      value: disableRetentionLimit ? '' : retentionPeriod,
      initialValue: '30',
      onChange: handleRetentionLimit,
      Component: TextField,
      InputProps: { inputProps: { min: 1 } },
      validation: disableRetentionLimit
        ? Yup.string().notRequired()
        : Yup.number()
            .transform(nanToUndefined)
            .min(1, 'Retention Period must be in range 1-365')
            .max(365, 'Retention Period must be in range 1-365')
            .required('Retention Period field is required'),
      type: disableRetentionLimit ? 'text' : 'number',
      required: !disableRetentionLimit,
      disabled: disableRetentionLimit,
    },
    (isDataIngested || isDataIngestionEnabled) && {
      name: SolutionItemField.UnlimitedRetention,
      Component: CheckboxGroup,
      type: 'checkbox-group',
      required: false,
      labelStyle: { mr: 3 },
      disabled: disableCacheRetention,
      onChange: handleUnlimitedRetention,
      options: [
        {
          label: 'Unlimited days for Retention period',
          value: 'UnlimitedRetention',
        },
      ],
    },
    (isSubscriptionService || isSubscriptionServicetype) && {
      name: SolutionItemField.SubscriptionType,
      label: 'Subscription type',
      required: true,
      validation: Yup.array()
        .nullable()
        .min(1, 'Subscription type is Required')
        .required('Subscription type is Required'),
      options: SUBSCRIPTION_TYPE_OPTIONS,
      Component: CheckboxGroup,
      onChange: handleSubscriptionService,
    },
    ((isFileSubscriptionType && isSubscriptionService) ||
      isFileSubscriptionServicetype) && {
      name: SolutionItemField.FileSubscriptionType,
      label: 'File subscription type',
      validation: Yup.string().required('File subscription type is Required'),
      Component: RadioButtonGroup,
      required: true,
      options: FILE_SUBSCRIPTION_OPTIONS,
    },
    (isTelemetryStreamProcessing || isTelemetryStreamProcessingtype) && {
      name: SolutionItemField.TelemetryStreamProcessingType,
      label: 'Telemetry stream processing type',
      required: true,
      Component: RadioButtonGroup,
      options: TELEMETRY_STREAM_PROCESSING_OPTIONS,
      validation: Yup.string().required(
        'Telemetry stream processing type is Required'
      ),
    },
  ]);
