import { createDatasetSlice } from 'src/redux/helpers';
import { RequestMethod, pepApiRequest } from 'src/services';
import { generateApiPath } from 'src/utils';
import {
  API_SOLUTIONS_IOTHUB_DEVICE_COMPONENTS,
  SOLUTIONS_STORE,
  SOLUTIONS_STORE_DEVICE_COMPONENTS,
} from '../constants';
import { mapSolutionDevicesComponentsData } from './utils';

const api = ({ solutionId, deviceId, modelId }) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: generateApiPath(API_SOLUTIONS_IOTHUB_DEVICE_COMPONENTS, {
      solutionId,
      deviceId,
      modelId,
    }),
  }).then((items) => mapSolutionDevicesComponentsData(items));

const solutionsDeviceComponentsSlice = createDatasetSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTIONS_STORE_DEVICE_COMPONENTS,
});

export default solutionsDeviceComponentsSlice;
