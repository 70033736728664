import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';

type Props = CircularProgressProps & {
  loading?: boolean;
};

export default function InputLoader({ loading, ...rest }: Props) {
  return loading ? <CircularProgress size={28} {...rest} /> : null;
}
