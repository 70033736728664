import { useParams } from 'react-router-dom';
import { EditOutlined } from '@mui/icons-material';
import {
  DataTableActionsButton,
  DataTableActionsWrapper,
} from 'src/components/DataTable';
import { isModalComplete } from 'src/components/ModalDialog';
import { useModal } from 'src/hooks';
import { useStoreUnitActions } from 'src/redux/helpers';
import { useUserRoles } from 'src/auth';
import solutionsStore from '../../redux';
import { SolutionNamespaceRscLimitItem } from '../../types';
import SolutionNamespaceRscLimitEditModal from './SolutionNamespaceRscLimitEditModal';

type Props = {
  item?: SolutionNamespaceRscLimitItem;
};

function SolutionSiteClusterListAction({ item }: Props) {
  const actions = useStoreUnitActions(solutionsStore);
  const { solutionId } = useParams();
  const { isPlatformOwner, isPlatformEngineer } = useUserRoles();
  const isValidUser = isPlatformOwner || isPlatformEngineer;
  const onClose = (event, reason) => {
    isModalComplete(reason);
    actions.sites(solutionId);
  };
  const newData: SolutionNamespaceRscLimitItem = {
    ...item,
    requestsCpu: item?.requestsCpu ? item?.requestsCpu.replace(/\D/g, '') : '',
    requestsMemory: item?.requestsMemory
      ? item?.requestsMemory.replace(/\D/g, '')
      : '',
    limitsCpu: item?.limitsCpu ? item?.limitsCpu.replace(/\D/g, '') : '',
    limitsMemory: item?.limitsMemory
      ? item?.limitsMemory.replace(/\D/g, '')
      : '',
    requestsStorage: item?.requestsStorage?.replace(/\D/g, ''),
  };
  const [EditNamespaceRscLimitDetails, showEditNamespaceRscLimitDetails] =
    useModal(SolutionNamespaceRscLimitEditModal, {
      siteId: item?.siteId ?? '',
      data: newData,
      onClose,
    });

  return (
    <DataTableActionsWrapper>
      <DataTableActionsButton
        data-testid="solution-sites-item-edit-action"
        icon={EditOutlined}
        title="Edit"
        onClick={showEditNamespaceRscLimitDetails}
        disabled={!isValidUser}
      />
      {EditNamespaceRscLimitDetails}
    </DataTableActionsWrapper>
  );
}

export default SolutionSiteClusterListAction;
