import { generatePath } from 'react-router-dom';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import ModalDialog, {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { API_APPLICATION_ITEM } from '../../constants';

type Props = {
  id: string;
  open: boolean;
  onClose: ModalCloseHandler;
};

function ApplicationListDeleteModal({ id, open, onClose }: Props) {
  const { showAlert } = useAlert();
  const [handleRemove, loading] = useStatefulApi(
    () =>
      pepApiRequest({
        method: RequestMethod.Delete,
        url: generatePath(API_APPLICATION_ITEM, {
          id,
        }),
      }),
    () => {
      showAlert({
        type: AlertType.Info,
        title: `${id} is deleted`,
        text: 'Application has been deleted successfully.',
      });
      onClose(null, ModalCloseReason.completeAction);
    }
  );

  return (
    <ModalDialog
      open={open}
      title="Delete Application"
      loading={loading}
      onClose={onClose}
      onConfirm={handleRemove}
      cancelBtnText="No"
      confirmBtnText="Yes"
    >
      Are you sure you want to delete this Application with Client ID{' '}
      <b>{id} ?</b>
    </ModalDialog>
  );
}

export default ApplicationListDeleteModal;
