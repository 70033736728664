import React, { useEffect } from 'react';
import Add from '@mui/icons-material/Add';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/system';
import DataTable, {
  DataTableWrapper,
  DataTableWrapperActionButton,
  useTableQueryParams,
  getActionsColConfig,
} from 'src/components/DataTable';
import { useModal } from 'src/hooks';
import { useUserRoles } from 'src/auth';
import { isModalComplete } from 'src/components/ModalDialog';
import { useStoreUnitActions } from 'src/redux/helpers';
import solutionsStore, { useSolutionDashboardDetails } from '../redux';
import SolutionDashboardCreateContainer from './components/SolutionDashboardCreateContainer';
import SolutionDashboardActions from './components/SolutionDashboardActions';
import { DashboardSummary } from '../types/solutionDashboard';

function SolutionDashboards() {
  const { paging } = useTableQueryParams();
  const actions = useStoreUnitActions(solutionsStore);
  const { solutionId } = useParams();
  const { isSolutionOwner, isPlatformOwner, isPlatformEngineer } =
    useUserRoles();
  const [data, loading] = useSolutionDashboardDetails();

  const columns = [
    {
      id: DashboardSummary.name,
      title: 'Name',
    },
    {
      id: DashboardSummary.dashboardId,
      title: 'ID',
    },
    getActionsColConfig(SolutionDashboardActions),
  ];

  const onClose = (event, reason) =>
    isModalComplete(reason) && actions.dashboard(solutionId);

  const [NewRuleCreationDialog, showNewDashboardDialog] = useModal(
    SolutionDashboardCreateContainer,
    {
      onClose: (event, reason) => isModalComplete(reason),
    }
  );

  useEffect(() => {
    actions.dashboard(solutionId);
  }, []);

  return (
    <>
      <DataTableWrapper
        title="Dashboards"
        style={{ fontWeight: 'bold' }}
        actionsPanel={
          <Box>
            <DataTableWrapperActionButton
              disabled={
                !(isSolutionOwner || isPlatformOwner || isPlatformEngineer)
              }
              sx={{ margin: '10px' }}
              onClick={showNewDashboardDialog}
            >
              <Add /> Add New
            </DataTableWrapperActionButton>
          </Box>
        }
        searchBar={true}
      >
        <DataTable
          columns={columns}
          data={data}
          loading={loading}
          {...paging}
          sortable={true}
        />
        {NewRuleCreationDialog}
      </DataTableWrapper>
    </>
  );
}

export default SolutionDashboards;
