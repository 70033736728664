import { useParams } from 'react-router-dom';
import { DataTableActions } from 'src/components/DataTable';
import { isModalComplete } from 'src/components/ModalDialog';
import { useUserRoles } from 'src/auth';
import { useModal } from 'src/hooks';
import { useStoreUnitActions } from 'src/redux/helpers';
import { useSolutionUserRoles } from '../../hooks';
import solutionsStore from '../../redux';
import { SolutionDeviceGroupItem } from '../../types';
import SolutionDeviceGroupDetailsContainer from './SolutionDeviceGroupDetailsContainer';
import SolutionDeviceGroupDeleteModal from './SolutionDeviceGroupDeleteModal';
import SolutionDeviceGroupEditContainer from './SolutionDeviceGroupEditContainer';

type Props = {
  item?: SolutionDeviceGroupItem;
};

function SolutionDeviceGroupsActions({ item }: Props) {
  const { solutionId } = useParams();
  const { isSolutionEditor } = useSolutionUserRoles();
  const { isPlatformReader, isPlatformEngineer } = useUserRoles();
  const { deviceGroups: actions } = useStoreUnitActions(solutionsStore);
  const onClose = (event, reason) =>
    isModalComplete(reason) && actions.init({ solutionId });

  const [DeleteDialog, showDeleteDialog] = useModal(
    SolutionDeviceGroupDeleteModal,
    { item, onClose }
  );

  const [EditDialog, showEditDialog] = useModal(
    SolutionDeviceGroupEditContainer,
    { item, onClose }
  );

  const [ViewDialog, showViewDialog] = useModal(
    SolutionDeviceGroupDetailsContainer,
    { item }
  );

  return (
    <DataTableActions
      canDelete={isSolutionEditor}
      canEdit={isSolutionEditor}
      onDelete={showDeleteDialog}
      onEdit={showEditDialog}
      onView={showViewDialog}
      showEdit={isSolutionEditor || isPlatformReader || isPlatformEngineer}
      showView
    >
      {DeleteDialog}
      {EditDialog}
      {ViewDialog}
    </DataTableActions>
  );
}

export default SolutionDeviceGroupsActions;
