import React from 'react';
import DataTable, {
  DataTableWrapper,
  getActionsColConfig,
} from 'src/components/DataTable';
import { PageCardLoader } from 'src/components/PageCardLayout';
import { useDeviceConfigFilesList } from '../../redux';
import { SolutionDeviceConfigItemField } from '../../types';
import SolutionDeviceConfigFilesAction from './SolutionDeviceConfigFilesAction';
import SolutionDeviceTemplateType from './SolutionDeviceTemplateType';

const columns = [
  {
    id: SolutionDeviceConfigItemField.DisplayName,
    title: 'Display name',
  },
  {
    id: SolutionDeviceConfigItemField.ModelId,
    title: 'Model ID',
  },
  {
    id: SolutionDeviceConfigItemField.ModelType,
    title: 'Model type',
    Component: SolutionDeviceTemplateType,
  },
  getActionsColConfig(SolutionDeviceConfigFilesAction),
];

function SolutionDeviceConfigFiles() {
  const [data, loading] = useDeviceConfigFilesList();

  return (
    <DataTableWrapper data-testid="solution-device-config-files-wrapper">
      <DataTable sx={{ minHeight: 0 }} columns={columns} data={data} />
      {loading && <PageCardLoader />}
    </DataTableWrapper>
  );
}

export default SolutionDeviceConfigFiles;
