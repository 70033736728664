import React from 'react';
import AppRouter from './router';
import AppProviders from './components/AppProviders';
import AppWrapper from './components/AppWrapper';

function App() {
  return (
    <AppProviders>
      <AppWrapper>
        <AppRouter />
      </AppWrapper>
    </AppProviders>
  );
}

export default App;
