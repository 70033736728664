import React from 'react';
import { Routes, Route } from 'react-router-dom';
import SecureRoute from './components/SecureRoute';
import { routes } from './routes';
import { getExactPath } from './utils';
import { ROOT } from './constants';

function Router() {
  return (
    <Routes>
      {routes.map(({ path, exact, ...rest }) => (
        <Route
          key={path}
          path={getExactPath(path, exact)}
          element={<SecureRoute fallbackUrl={ROOT} {...rest} />}
        />
      ))}
    </Routes>
  );
}

export default Router;
