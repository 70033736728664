import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useStoreUnitActions } from 'src/redux/helpers';
import { RoutableTabsLayout } from 'src/router';
import solutionsStore from '../redux';
import { routes } from './routes';
import * as ROUTES from '../constants';
import { SolutionType } from '../types';

function SolutionDetails() {
  const { solutionId, solutionType } = useParams();
  const actions = useStoreUnitActions(solutionsStore);

  useEffect(() => {
    actions.details(solutionId);
  }, [solutionId]);

  const getRoutes = () => {
    if (solutionType?.toUpperCase() === SolutionType.Consumer.toUpperCase())
      return routes.filter(
        (route) =>
          route.path !== ROUTES.SOLUTION_RULES_CONFIGURATION &&
          route.path !== ROUTES.SOLUTION_DASHBOARDS &&
          route.path !== ROUTES.EDGE_WORKLOAD &&
          route.path !== ROUTES.Sites &&
          route.path !== ROUTES.SOLUTION_ALERT_RULES
      );
    return routes.filter(
      (route) =>
        route.path !== ROUTES.EDGE_WORKLOAD &&
        route.path !== ROUTES.Sites &&
        route.path !== ROUTES.SOLUTION_ALERT_RULES
    );
  };
  const enableRoutes =
    solutionType === SolutionType.Industrial
      ? routes.filter(
          (route) =>
            route.index === true ||
            route.path === ROUTES.SOLUTION_USERS ||
            route.path === ROUTES.Sites ||
            route.path === ROUTES.EDGE_WORKLOAD ||
            route.path === ROUTES.SOLUTION_SETTINGS ||
            route.path === ROUTES.SOLUTION_SUBSCRIPTIONS ||
            route.path === ROUTES.SOLUTION_EDIT ||
            route.path === ROUTES.SOLUTION_ALERT_RULES
        )
      : getRoutes();
  return <RoutableTabsLayout routes={enableRoutes} />;
}

export default SolutionDetails;
