import Badge from 'src/components/Badge';
import { SiteDeviceNodeStatus } from '../../types';

type StatusMapItem = {
    color?: 'success' | 'info' | 'error' | 'warning';
    label: string;
};

type Props = {
    value?: boolean;
};

function NodeItemStatus({ value = true }: Props) {
    const statusMap: Record<SiteDeviceNodeStatus, StatusMapItem> = {
        [SiteDeviceNodeStatus.Ready]: { color: 'success', label: 'Ready' },
        [SiteDeviceNodeStatus.Unavailable]: { color: 'warning', label: 'UNAVAILABLE' },
    };

    return (
        <>
            <Badge {...statusMap[value.toString()]} />
        </>
    );
}

export default NodeItemStatus;