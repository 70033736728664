import React from 'react';
import { generatePath } from 'react-router-dom';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { API_OTP_KEY_ITEM } from '../constants';
import { OtpItem } from '../types';
import EditOtpModal from './EditOtpModal';

type Props = {
  item: OtpItem;
  open: boolean;
  onClose: ModalCloseHandler;
};

function EditOtpModalContainer({ item, open, onClose }: Props) {
  const { showAlert } = useAlert();
  const otpKey = item?.key;
  const [handleSubmit, loading] = useStatefulApi(
    ({ key, ...params }) =>
      pepApiRequest({
        method: RequestMethod.Put,
        url: generatePath(API_OTP_KEY_ITEM, { key, otpKey }),
        params,
      }),
    () => {
      showAlert({
        type: AlertType.Success,
        title: `${otpKey} is Updated`,
        text: 'OTP key has been successfully updated.',
      });
      onClose(null, ModalCloseReason.completeAction);
    }
  );

  return (
    <EditOtpModal
      title="Edit key"
      open={open}
      onSubmit={handleSubmit}
      onClose={onClose}
      loading={loading}
      item={item}
    />
  );
}

export default EditOtpModalContainer;
