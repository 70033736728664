import { createDatasetSlice } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import { generateApiPath, loadDataFromStorage } from 'src/utils';
import {
  API_SUBSCRIPTIONS,
  API_SUBSCRIPTIONS_IOTH,
  SOLUTIONS_STORE,
  SOLUTIONS_STORE_SUBSCRIPTIONS,
} from '../constants';
import { mapSubscriptionsData } from './utils';
import { SolutionType } from '../types';

const api = (solutionId: string) => {
  const solutionType = loadDataFromStorage('solutionType');
  return pepApiRequest({
    method: RequestMethod.Get,
    url: generateApiPath(
      solutionType?.toUpperCase() === SolutionType.ioTH.toUpperCase()
        ? API_SUBSCRIPTIONS_IOTH
        : API_SUBSCRIPTIONS,
      { solutionId }
    ),
  }).then(mapSubscriptionsData);
};

const solutionSubscriptionsSlice = createDatasetSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTIONS_STORE_SUBSCRIPTIONS,
});

export default solutionSubscriptionsSlice;
