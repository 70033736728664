import { baseTheme } from '../baseTheme';

const { palette, typography } = baseTheme;

export default {
  styleOverrides: {
    root: {
      backgroundColor: palette.primary.main,
      ...typography.body1md,
    },
  },
};
