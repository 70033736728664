import { generatePath } from 'react-router-dom';
import { createDatasetSlice } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import {
  API_SOLUTION_GET_ADX_TABLES,
  SOLUTIONS_STORE_ADX_MAPPED_TABLES,
  SOLUTIONS_STORE,
} from '../constants';
import { mapSolutionAdxMappedTables } from './utils';

const api = (solutionId) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: generatePath(API_SOLUTION_GET_ADX_TABLES, { solutionId }),
  }).then((response) => mapSolutionAdxMappedTables(response));

const adxMappedTables = createDatasetSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTIONS_STORE_ADX_MAPPED_TABLES,
});

export default adxMappedTables;
