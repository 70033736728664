import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import compact from 'lodash/compact';
import { loadDataFromStorage } from 'src/utils';
import { useStoreUnitActions } from 'src/redux/helpers';
import { FormField } from 'src/components/Form';
import {
  getJobEditFormFields,
  getJobTempMigrationFields,
  getJobHostOsUpdateFields,
  getJobPropertyUpdateFields,
  getJobInvokeCommandUpdateFields,
} from './formFields';
import solutionsStore, {
  useSolutionCommandOptions,
  useSolutionCompaniesOptions,
  useSolutionComponentsDetails,
  useSolutionDevDepReleaseOptions,
  useSolutionDeviceGroupDetails,
  useSolutionDeviceGroupOnlyWritablePropOptions,
  useSolutionDeviceGroupProperties,
  useSolutionDeviceGroupsOptions,
  useSolutionDevicePropertyMeta,
  useSolutionDeviceTemplateOptions,
  useSolutionMenderSettings,
} from '../redux';
import { SolutionJobItem, SolutionJobType, SolutionType } from '../types';

export const useSolutionJobEditFormConfig = (
  item?: SolutionJobItem
): [FormField[], (values: SolutionJobItem) => void] => {
  const [{ menderEnabled }] = useSolutionMenderSettings();
  const canEditType = useMemo(() => !item, []);
  const [state, setState] = useState<SolutionJobItem | undefined>(item);
  const { organization: companyId, group, type, runLater } = state || {};
  const { solutionId } = useParams();
  const [deviceGroupOptions, deviceGroupsLoading, skipToken] =
    useSolutionDeviceGroupsOptions();
  const [companyOptions, companiesLoading] = useSolutionCompaniesOptions();
  const [propertyOptions, propertiesLoading] =
    useSolutionDeviceGroupOnlyWritablePropOptions();

  const [details, detailsLoading] = useSolutionDeviceGroupDetails();
  const [releaseOptions, releasesLoading] = useSolutionDevDepReleaseOptions();
  const templates = useSolutionDeviceTemplateOptions();
  const actions = useStoreUnitActions(solutionsStore);
  const solutionType = loadDataFromStorage('solutionType');
  const { operators: operatorOptions, enums: filterValueOptions } =
    useSolutionDevicePropertyMeta(state?.propertyId);
  const [commandData, Commandloading] = useSolutionComponentsDetails();
  const [propertiesData, propertiesDataLoading] =
    useSolutionDeviceGroupProperties();
  const propertyData = propertiesData?.filter(
    (a) => a.id === state?.propertyId
  )[0];
  const onPropertyChange: any = () => {
    console.log('daaa');
  };
  const [commandOptions, loading] = useSolutionCommandOptions();
  const jobTypesFieldsMap = {
    [SolutionJobType.TemplateMigration]: getJobTempMigrationFields(templates),
    [SolutionJobType.ScheduleUpdateTwin]: getJobPropertyUpdateFields(
      onPropertyChange,
      propertyData,
      propertyOptions,
      filterValueOptions,
      propertiesLoading
    ),

    [SolutionJobType.ScheduleDeviceMethod]: getJobInvokeCommandUpdateFields(
      commandData,
      state,
      commandOptions,
      loading
    ),
    [SolutionJobType.HostOsDeployment]: getJobHostOsUpdateFields({
      releaseOptions,
      releasesLoading,
      runLater,
    }),
  };
  const { filters = [], modelId } = details || {};

  useEffect(() => {
    if (solutionId && modelId && solutionType === SolutionType.ioTH)
      actions.deviceComponents({
        solutionId,
        modelId,
      });
  }, [solutionId, modelId]);

  useEffect(() => {
    actions.companies(solutionId);
    if (menderEnabled) {
      actions.deviceDeploymentReleases({ solutionId });
    }
  }, [solutionId]);
  useEffect(() => {
    if (companyId) {
      actions.deviceGroups.init({ solutionId, companyId });
    }
    return () => actions.deviceGroups.clear();
  }, [solutionId, companyId]);

  useEffect(() => {
    if (group) {
      actions.deviceGroupDetails({ solutionId, groupId: group });
    }
  }, [group]);

  useEffect(() => {
    if (details && modelId) {
      actions.deviceGroupProperties({ solutionId, modelId });
    }
  }, [details, modelId]);

  useEffect(() => {
    if (skipToken) {
      actions.deviceGroups.update({ solutionId, companyId, skipToken });
    }
  }, [skipToken, solutionId]);

  const fields = compact([
    ...getJobEditFormFields({
      canEditType,
      companyOptions,
      companiesLoading,
      deviceGroupOptions,
      deviceGroupsLoading,
      menderEnabled,
      solutionType,
    }),
    ...(type ? jobTypesFieldsMap[type] : []),
  ]);

  return [fields, setState];
};
