import React, { ComponentType } from 'react';
import { Link, useLocation } from 'react-router-dom';
import List, { ListProps } from '@mui/material/List';
import { matchRoute, RouteWithMetaProps, useUserRoutes } from 'src/router';
import Box from '@mui/system/Box';
import SideBarNavItem from './SideBarNavItem';

type Props = ListProps & {
  open: boolean;
};

function SideBarNav({ open }: Props) {
  const routes = useUserRoutes<RouteWithMetaProps>(true);
  const { pathname } = useLocation();

  return (
    <Box
      sx={{
        overflowY: 'auto',
        overflowX: 'hidden',
        height: 'calc(100vh - 300px)',
        '&::-webkit-scrollbar': {
          width: 4,
          height: 4,
        },
        '&::-webkit-scrollbar-track': {
          borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#557ABA',
          borderRadius: '10px',
        },
      }}
    >
      <List data-testid="sidebar">
        {routes.map(({ path, meta }) => {
          const { title, icon } = meta;
          const selected = !!matchRoute(pathname, path);
          return (
            <SideBarNavItem
              data-testid={`sidebar-nav-link${path}`}
              key={path}
              selected={selected}
              component={Link}
              to={path}
              title={title}
              icon={icon as ComponentType}
              open={open}
            />
          );
        })}
      </List>
    </Box>
  );
}

export default SideBarNav;
