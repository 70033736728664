import React, { useState } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import ListItem, { ListItemProps } from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@mui/material/Tooltip';
import { Theme } from '@mui/system/createTheme';
import { SxProps } from 'src/theme/types';
import { SIDEBAR_WIDTH_CLOSED } from '../constants';

const style: SxProps<Theme> = {
  px: SIDEBAR_WIDTH_CLOSED / 3.5,
  minHeight: ({ spacing }) => spacing(6),
  overflowY: 'auto',
  color: 'white',
  overflowX: 'hidden',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: ({ palette, alpha }) => alpha(palette.blue[900], 0.6),
  },
  '& .MuiListItemIcon-root': {
    color: ({ palette, alpha }) => alpha(palette.white, 0.5),
  },
  '&.Mui-selected': {
    backgroundColor: 'blue.900',
    '& .MuiListItemIcon-root': {
      color: 'inherit',
    },
  },
};

type Props = Record<string, any> &
  ListItemProps & {
    icon: React.JSXElementConstructor<SvgIconProps>;
    title: string;
    open: boolean;
  };

function SideBarNavItem({ title, icon, open, ...rest }: Props) {
  const [showToolTip, setShowToolTip] = useState(false);

  const handleTooltipOpen = () => {
    setShowToolTip(!open);
  };

  const handleTooltipClose = () => {
    setShowToolTip(false);
  };

  return (
    <Tooltip
      open={showToolTip}
      onOpen={handleTooltipOpen}
      onClose={handleTooltipClose}
      title={title}
      placement="right"
    >
      <ListItem sx={style} {...rest}>
        <ListItemIcon>
          <SvgIcon component={icon} />
        </ListItemIcon>
        {title}
      </ListItem>
    </Tooltip>
  );
}

export default SideBarNavItem;
