import React from 'react';
import Badge from 'src/components/Badge';

type Props = {
  value?: boolean;
};

function SolutionAlertRuleStatus({ value = false }: Props) {
  return value ? (
    <Badge color="info" label="ENABLED" />
  ) : (
    <Badge label="DISABLED" />
  );
}

export default SolutionAlertRuleStatus;
