import React from 'react';
import sum from 'lodash/sum';
import Box, { BoxProps } from '@mui/material/Box';
import { SystemProps } from '@mui/system/Box';
import { SxProps } from 'src/theme/types';
import StatsSummaryTotal from './components/StatsSummaryTotal';
import StatSummaryItem from './components/StatSummaryItem';
import { StatsSummaryField, StatsSummaryData } from './types';

type Props = BoxProps & {
  totalTitle?: string;
  fields: StatsSummaryField[];
  data: StatsSummaryData;
};

const containerStyle: SystemProps = {
  display: 'flex',
  alignItems: 'flex-start',
};

const itemsListStyle: SxProps = {
  borderBottom: 1,
  borderColor: 'primary.main',
  display: 'flex',
  maxWidth: '80%',
  maxHeight: ({ spacing }) => spacing(7.5),
  pl: 2,
};

function StatsSummary({ totalTitle, fields, data, ...rest }: Props) {
  return (
    <Box data-testid="stats-summary" {...containerStyle} {...rest}>
      <StatsSummaryTotal title={totalTitle} count={sum(Object.values(data))} />
      <Box data-testid="stats-summary-items" sx={itemsListStyle}>
        {fields.map(({ id, label }) => (
          <StatSummaryItem key={id} label={label} value={data[id]} />
        ))}
      </Box>
    </Box>
  );
}

export default StatsSummary;
