import { useMemo } from 'react';
import filter from 'lodash/filter';
import { SolutionUserItemField, SolutionUserRole } from '../types';
import { useSolutionUsers } from '../redux';

export const useIsLastOwner = (role?: SolutionUserRole): boolean => {
  const [users] = useSolutionUsers();

  return useMemo(
    () =>
      role === SolutionUserRole.Owner &&
      filter(users, [SolutionUserItemField.Role, SolutionUserRole.Owner])
        .length < 2,
    [users]
  );
};
