import React, { ForwardedRef } from 'react';
import MuiAlert, {
  AlertProps,
  AlertColor as MuiAlertColor,
} from '@mui/material/Alert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import NotificationsIcon from '@mui/icons-material/Notifications';
import WarningIcon from '@mui/icons-material/Warning';

import { SvgIconProps } from '@mui/material/SvgIcon';

export type AlertColor = MuiAlertColor | 'neutral';

const Alert = React.forwardRef(
  (
    { color = 'neutral', ...props }: AlertProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const iconsMap: Record<
      AlertColor,
      React.JSXElementConstructor<SvgIconProps>
    > = {
      neutral: ErrorIcon,
      success: CheckCircleIcon,
      info: InfoIcon,
      warning: NotificationsIcon,
      error: WarningIcon,
    };
    const Icon = iconsMap[color];

    return (
      <MuiAlert
        data-testid="alert"
        ref={ref}
        color={color}
        icon={<Icon />}
        {...props}
      />
    );
  }
);

export default Alert;
