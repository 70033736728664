import { SolutionDeviceObservPropsItem } from '../types';

export const mapDevicePropertiesToRequestFormat = (
  properties: SolutionDeviceObservPropsItem[]
) => {
  const formattedBody = {
    componentName: 'DeviceMetrics',
    moduleName: '',
    properties,
  };
  return formattedBody;
};
