import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SxProps } from 'src/theme/types';

type Props = {
  value: number;
  label: string;
};

const containerStyle: SxProps = {
  textAlign: 'center',
  transform: 'translate(0, 3rem)',
  width: ({ spacing }) => spacing(11),
};

const labelStyle: SxProps = {
  mr: 1,
  '&:before': {
    content: '" "',
    height: ({ spacing }) => spacing(2.5),
    width: ({ spacing }) => spacing(0.1),
    m: 'auto',
    mb: 1,
    transform: 'skew(-45deg) translate(1rem, 0)',
    backgroundColor: 'primary.main',
    display: 'flex',
  },
};

function StatSummaryItem({ value, label }: Props) {
  return (
    <Box sx={containerStyle} color={value ? 'black' : 'neutral.main'}>
      <Typography pl={5} variant="body2bold">
        {value}
      </Typography>
      <Box sx={labelStyle}>{label}</Box>
    </Box>
  );
}

export default StatSummaryItem;
