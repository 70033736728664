import React, {
  forwardRef,
  ReactNode,
  ForwardedRef,
  useMemo,
  ChangeEvent,
} from 'react';
import clsx from 'clsx';
import MuiAutocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { InputProps } from '@mui/material/Input';
import { PaperProps } from '@mui/material/Paper';
import InputLoader from '../InputLoader';
import SelectOption, { Option, OptionValue } from '../SelectOption';
import { mapOptionsToValue, mapValueToOptions } from './utils';
import { SelectedValue } from './types';
import { updateEventTargetValue } from '../../utils';

type Props<T> = Partial<
  Omit<AutocompleteProps<Option<T>, boolean, undefined, undefined>, 'value'>
> & {
  value?: SelectedValue<T>;
  label?: ReactNode;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  helperText?: ReactNode;
  loading?: boolean;
  multiple?: boolean;
  required?: boolean;
};

function AutoComplete<T extends OptionValue = OptionValue>(
  {
    id,
    value,
    options = [],
    label,
    disabled,
    loading,
    error,
    helperText,
    multiple,
    required,
    onChange,
    ...rest
  }: Props<T>,
  ref: ForwardedRef<HTMLSelectElement>
) {
  const handleChange = (event, newValue) => {
    if (onChange) {
      newValue = mapOptionsToValue<T>(newValue);
      onChange(updateEventTargetValue(event, id, newValue));
    }
  };
  const mappedValue = useMemo(
    () => mapValueToOptions<T>(value, options, multiple),
    [value, options, multiple]
  );

  return (
    <MuiAutocomplete<Option<T>, boolean>
      autoComplete
      data-testid="autocomplete"
      className={clsx({ 'Mui-disabled': disabled })}
      getOptionLabel={(option) => option?.label}
      disabled={disabled}
      loading={loading}
      multiple={multiple}
      onChange={handleChange}
      options={options}
      ref={ref}
      componentsProps={{
        paper: { 'data-testid': 'autocomplete-options' } as PaperProps,
      }}
      renderInput={({ InputProps, ...rest }) => (
        <TextField
          data-testid="autocomplete-textfield"
          error={error}
          helperText={helperText}
          label={label}
          required={required}
          InputProps={
            {
              ...InputProps,
              'data-testid': 'autocomplete-input',
              endAdornment: (
                <>
                  <InputLoader
                    data-testid="autocomplete-loader"
                    loading={loading}
                  />
                  {InputProps.endAdornment}
                </>
              ),
            } as InputProps
          }
          {...rest}
        />
      )}
      renderOption={(props, option) => (
        <SelectOption<T> key={option.value} {...props} {...option} />
      )}
      value={mappedValue}
      {...rest}
    />
  );
}

export default forwardRef(AutoComplete) as typeof AutoComplete;
