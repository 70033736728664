import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputLabel,
  FormControl,
  Alert,
  Grid,
  Typography,
  MenuItem,
  Stack,
  Checkbox,
  OutlinedInput,
  ListItemText,
  SelectChangeEvent,
  Box,
  Chip,
  Select,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MaskedInput from 'src/components/MaskedInput';
import TextFieldWithInfo from 'src/components/TextField';
import SolutionFeatureInformation from '../components/SolutionFeatureInfomation';

const ActionCard = ({
  rule,
  isEditRuleModal,
  actionType,
  actionTargetURL,
  actionSASToken,
  error,
}) => {
  const [type, setType] = useState<string[]>([]);
  const [targetURL, setTargetURL] = useState<string>('');
  const [sasToken, setSASToken] = useState<string>('');
  const [expanded, setExpanded] = useState(true);
  const names = ['sms', 'email', 'targeturl'];
  const handleChange = (event) => {
    handleAction(event);
    setType(event.target.value);
  };

  const handleOnTargetURLChange = (event) => {
    handleTargetURLAction(event);
    setTargetURL(event.target.value);
  };
  const handleOnSasTokenChange = (event) => {
    handleSASTokenAction(event);
    setSASToken(event.target.value);
  };
  const handleSASTokenKeyPaste = () => {
    navigator.clipboard.readText().then((clipboardText) => {
      setSASToken(clipboardText.trim());
    });
  };
  const handleDelete = (event) => {
    setType(event.target.value);
  };

  const ITEM_HEIGHT = 48;
  // const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const handleAction = (event: SelectChangeEvent<string>) => {
    actionType(event.target.value);
  };
  const handleTargetURLAction = (event: SelectChangeEvent<string>) => {
    actionTargetURL(event.target.value);
  };

  const handleSASTokenAction = (event: SelectChangeEvent<string>) => {
    actionSASToken(event.target.value);
  };

  const handleChangeAccordion = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Accordion expanded={expanded} onChange={handleChangeAccordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h4" fontWeight="500">
            Actions
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack>
            <Grid item>
              <SolutionFeatureInformation featureInfo="Choose what action your rule should take." />
            </Grid>
            <Grid item>
              <SolutionFeatureInformation featureInfo="SMS and email notifiactions will be sent to the users of this solutions,depending upon their selection of notification preferences in the users tab." />
            </Grid>
            <Grid item container direction="column">
              <Grid item container spacing={2}>
                <Grid item>
                  <FormControl required sx={{ m: 1, minWidth: 500 }}>
                    <InputLabel
                      error={
                        error !== null &&
                        (type.length === 0 ||
                          type === undefined ||
                          type === null)
                      }
                    >
                      Type
                    </InputLabel>
                    <Select
                      error={
                        error !== null &&
                        (type.length === 0 ||
                          type === undefined ||
                          type === null)
                      }
                      labelId="action-multiple-checkbox-label"
                      id="action-multiple-checkbox"
                      multiple
                      value={isEditRuleModal ? rule.actionType : type}
                      onChange={handleChange}
                      input={
                        <OutlinedInput label="Tag" sx={{ height: '46px' }} />
                      }
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                          {selected.map((value) => (
                            <Chip
                              onDelete={handleDelete}
                              sx={{ fontSize: '15px' }}
                              key={value}
                              label={value}
                            />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {names.map((name) => (
                        <MenuItem
                          key={name}
                          value={name}
                          sx={{ height: '46px', overflow: 'hidden' }}
                        >
                          <Checkbox
                            checked={
                              isEditRuleModal
                                ? rule.actionType.includes(
                                    name.toLocaleLowerCase()
                                  )
                                : type.indexOf(name) > -1
                            }
                          />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl required sx={{ m: 1, minWidth: 500 }}>
                    {rule.actionType.includes('targeturl') ||
                    type.includes('targeturl') ? (
                      <>
                        <TextFieldWithInfo
                          error={
                            type?.includes('targeturl') &&
                            error !== null &&
                            (targetURL === '' ||
                              targetURL === undefined ||
                              targetURL === null)
                          }
                          required
                          label="Target URL"
                          onChange={handleOnTargetURLChange}
                          placeholder="Eg. https://example.com"
                          value={isEditRuleModal ? rule.targetURL : targetURL}
                          showinfoicon={true}
                          sx={{
                            width: ({ spacing }) => spacing(55),
                            paddingRight: '2rem',
                          }}
                          infotitle={
                            'When the conditions of the rule are met,a POST request will be sent to the target URL provided in this field.'
                          }
                        />
                      </>
                    ) : (
                      ''
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container spacing={1}>
              <FormControl
                required
                sx={{ m: 1, paddingLeft: '0.9rem', minWidth: 510 }}
              >
                {rule.actionType.includes('targeturl') ||
                type.includes('targeturl') ? (
                  <MaskedInput
                    error={
                      type?.includes('targeturl') &&
                      error !== null &&
                      (rule.SasToken === '' ||
                        rule.SasToken === undefined ||
                        rule.SasToken === null)
                    }
                    value={isEditRuleModal ? rule.SasToken : sasToken}
                    required
                    label="SAS Token"
                    placeholder="Enter SAS Token"
                    defaultValue="Default input"
                    onChange={handleOnSasTokenChange}
                    onClickPasteIcon={handleSASTokenKeyPaste}
                  />
                ) : (
                  ''
                )}
              </FormControl>
            </Grid>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ActionCard;
