import { ChangeEvent } from 'react';
import toString from 'lodash/toString';
import { useSyncState } from 'src/hooks';
import { Option } from 'src/components/SelectOption';
import { useSolutionDeviceTemplateOptions } from '../redux';

export const useCompatibleTemplateOptions = (
  value?: string
): [
  Option[],
  (event: ChangeEvent<HTMLInputElement>) => void,
  boolean | undefined
] => {
  const getCompatibleOptions = (
    options: Option[],
    compatibleOnly?: boolean
  ) => {
    const [trimmedValue] = toString(value).split(';');
    return compatibleOnly
      ? options.filter(({ value }) => toString(value).includes(trimmedValue))
      : options;
  };
  const [inputOptions, loading] = useSolutionDeviceTemplateOptions();
  const [options, setOptions] = useSyncState(
    getCompatibleOptions(inputOptions, true),
    [inputOptions]
  );
  const handleSetOptions = ({ target }: ChangeEvent<HTMLInputElement>) =>
    setOptions(getCompatibleOptions(inputOptions, target.checked));

  return [options, handleSetOptions, loading];
};
