import { generatePath } from 'react-router-dom';
import { createDatasetSlice } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import {
  API_GET_ADLS_SUBSCRIPTION,
  SOLUTIONS_STORE,
  SOLUTIONS_STORE_ADLS_SUBSCRIPTION,
} from '../constants';
import { mapSolutionAdlsSubscription } from './utils';

const api = (solutionId: string) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: generatePath(API_GET_ADLS_SUBSCRIPTION, { solutionId }),
  }).then((response) => mapSolutionAdlsSubscription(response));

const adlsSubscriptionById = createDatasetSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTIONS_STORE_ADLS_SUBSCRIPTION,
});

export default adlsSubscriptionById;
