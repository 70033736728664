import React from 'react';
import { useParams } from 'react-router-dom';
import { loadDataFromStorage } from 'src/utils';
import { DataTableActions } from 'src/components/DataTable';
import { useStoreUnitActions } from 'src/redux/helpers';
import { isModalComplete } from 'src/components/ModalDialog';
import { useModal } from 'src/hooks';
import { useUserRoles } from 'src/auth';
import SolutionCompaniesDeleteModal from './SolutionCompaniesDeleteModal';
import solutionsStore from '../../redux';
import { SolutionType } from '../../types';

type Props = {
  item?: any;
};

function SolutionsCompaniesAction({ item }: Props) {
  // TODO implement unassign functionality
  const { solutionId } = useParams();
  const { isPlatformReader, isPlatformEngineer } = useUserRoles();
  const actions = useStoreUnitActions(solutionsStore);
  const solutionType = loadDataFromStorage('solutionType');
  const isValidUser = isPlatformReader || isPlatformEngineer;
  const [DeleteDialog, onDeleteClick] = useModal(SolutionCompaniesDeleteModal, {
    item,
    onClose: (event, reason) =>
      isModalComplete(reason) && actions.companies(solutionId),
  });

  return (
    <>
      <DataTableActions
        canDelete={
          solutionType.toUpperCase() === SolutionType.ioTH.toUpperCase() &&
          !isValidUser
        }
        onDelete={onDeleteClick}
        showEdit={false}
      />
      {DeleteDialog}
    </>
  );
}

export default SolutionsCompaniesAction;
