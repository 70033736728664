import { DataTableActions } from 'src/components/DataTable';
import { useParams } from 'react-router-dom';
import { useStoreUnitActions } from 'src/redux/helpers';
import { isModalComplete } from 'src/components/ModalDialog';
import { useUserRoles } from 'src/auth';
import { useModal } from 'src/hooks';
import solutionsStore from '../../redux';
import SolutionsDashbordViewModal from './SolutionsDashbordViewModal';
import SolutionDashboardDeleteModal from './SolutionDashboardDeleteModal';

type Props = {
  item?: any;
};

function SolutionDashboardActions({ item }: Props) {
  const actions = useStoreUnitActions(solutionsStore);
  const { isPlatformReader } = useUserRoles();
  const { solutionId } = useParams();
  const onClose = (event, reason) =>
    isModalComplete(reason) && actions.dashboard(solutionId);

  const [ViewDialog, handleView] = useModal(SolutionsDashbordViewModal, {
    item,
  });

  const [DeleteDialog, handleDelete] = useModal(SolutionDashboardDeleteModal, {
    item,
    onClose,
  });
  return (
    <DataTableActions
      showView={true}
      showEdit={false}
      onView={handleView}
      onDelete={handleDelete}
      canDelete={!isPlatformReader}
    >
      {ViewDialog}
      {DeleteDialog}
    </DataTableActions>
  );
}

export default SolutionDashboardActions;
