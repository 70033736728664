import { DataTableActions } from "src/components/DataTable";

function NameSpacesListAction() {
    return (
        <DataTableActions
            canView={true}
            showEdit={false}
            showDelete={false}
            showView={true}
        />
    )
}

export default NameSpacesListAction;