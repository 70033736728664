export enum SiteItemStatus {
  Active = 'active',
  InProgress = 'inprogress',
  Deleted = 'deleted',
}

export type ClusterDetail = {
  id: string;
  name: string;
  status: SiteItemStatus;
};

export type SolutionSiteItem = {
  id: number;
  name: string;
  sector: string;
  description: string;
  status: SiteItemStatus;
  clusters?: any;
  clusterNames?: any;
};

export enum SolutionSiteItemField {
  SiteName = 'name',
  Sector = 'sector',
  Description = 'description',
  Status = 'status',
  ClusterNames = 'clusterNames',
}
