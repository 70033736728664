import ModalDialog, { ModalCloseHandler } from 'src/components/ModalDialog';
import { useStoreUnitActions } from 'src/redux/helpers';
import { useForm } from 'src/components/Form';
import { RequestMethod, pepApiRequest, useStatefulApi } from 'src/services';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import { PageCardLoader } from 'src/components/PageCardLayout';
import { generateApiPath } from 'src/utils';
import { numericInputValidation } from '../../SolutionSites/utils';
import solutionsStore, { useSolutionDetails } from '../../redux';
import { SolutionNamespaceRscLimitItem } from '../../types';
import { API_POST_SOL_NAMESPACE_RSC_LIMIT } from '../../constants';
import { getSolutionNamespaceRscLimitFields } from '../../SolutionSites/formFields';
import { getSetDefaultNamespaceRscLimitParams } from '../utils';

type Props = {
  solutionId: string;
  open: boolean;
  onClose: ModalCloseHandler;
  data: SolutionNamespaceRscLimitItem | undefined;
  solNamespaceQuotaAction: string;
};

const containerStyle = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  minHeight: '27rem',
  justifyContent: 'flex-start',
};

function SolutionNamespaceQuotaDefaultEditModal({
  solutionId,
  open,
  onClose,
  data,
  solNamespaceQuotaAction,
}: Props) {
  const { showAlert } = useAlert();
  const actions = useStoreUnitActions(solutionsStore);
  const [solutionData, solutionLoading] = useSolutionDetails();
  const solutionDisplayName = solutionData?.solutionDisplayName;

  const [onSubmit, submitting] = useStatefulApi<SolutionNamespaceRscLimitItem>(
    (params) =>
      pepApiRequest({
        method: RequestMethod.Post,
        url: generateApiPath(API_POST_SOL_NAMESPACE_RSC_LIMIT, { solutionId }),
        params: getSetDefaultNamespaceRscLimitParams(params),
      }),
    () => {
      showAlert({
        type:
          solNamespaceQuotaAction === 'Edit'
            ? AlertType.Info
            : AlertType.Success,
        title:
          solNamespaceQuotaAction === 'Edit'
            ? 'Default quota limit details saved'
            : 'Default quota limit created and set',
        text:
          solNamespaceQuotaAction === 'Edit'
            ? `Quota limit for the solution ${solutionDisplayName} has been modified successfully`
            : `Quota limit for the solution ${solutionDisplayName} has been set as default successfully`,
      });
      onClose();
      actions.defaultNamespaceRscLimit(solutionId);
    }
  );

  const handleChange = (event) => {
    const { name, value } = event.target;
    const nameSpaceLimitValue = numericInputValidation(value);
    setValue(name, nameSpaceLimitValue);
  };

  const [form, handleSubmit, setValue] = useForm({
    values: data,
    fields: getSolutionNamespaceRscLimitFields(handleChange),
    sx: containerStyle,
    onSubmit,
  });

  return (
    <ModalDialog
      open={open}
      title={`${solNamespaceQuotaAction} default quota limits`}
      showCancelBtn={true}
      confirmBtnText={'Save'}
      onConfirm={handleSubmit}
      loading={solutionLoading}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      {submitting && <PageCardLoader />}
      {form}
    </ModalDialog>
  );
}

export default SolutionNamespaceQuotaDefaultEditModal;
