import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import { PageCardLoader } from 'src/components/PageCardLayout';
import { useModal } from 'src/hooks';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { generateApiPath, loadDataFromStorage } from 'src/utils';
import { useStoreUnitActions } from 'src/redux/helpers';
import { useUserRoles } from 'src/auth';
import { SolutionItemStatus, SolutionType } from '../../types';
import {
  API_SOLUTION_UPDATE_STATUS,
  API_SOLUTION_UPDATE_STATUS_IOTH,
} from '../../constants';
import SolutionSuspendModal from './SolutionSuspendModal';
import solutionsStore, { useSolutionDetails } from '../../redux';

function SolutionSuspension() {
  const { showAlert } = useAlert();
  const actions = useStoreUnitActions(solutionsStore);
  const [data] = useSolutionDetails();
  const { isPlatformReader } = useUserRoles();
  const { solutionId } = useParams();
  let isActivateDisable: boolean | undefined;
  if (data?.status === SolutionItemStatus.Suspended) {
    const one_day = 1000 * 60 * 60 * 24;
    const lastStatusChange = data?.lastStatusChange;
    const retentionDays = Number(data?.retentionDays);
    const lastStatusCahangedate = new Date(lastStatusChange || '');
    const UTCDate = new Date().toUTCString();
    const currentDate = Number(new Date(UTCDate));
    const RetentionEndDate = lastStatusCahangedate.setUTCDate(
      lastStatusCahangedate.getUTCDate() + retentionDays
    );
    const RetentionUTCDate = Math.round(
      (RetentionEndDate - currentDate) / one_day
    );
    if (RetentionUTCDate > 0.5) {
      isActivateDisable = false;
    } else {
      isActivateDisable = true;
    }
  }

  const [suspendDialog, showSuspendDialog] = useModal(SolutionSuspendModal);

  const [activateSolution, loading] = useStatefulApi(
    () => {
      const solutionType = loadDataFromStorage('solutionType');
      return pepApiRequest({
        method: RequestMethod.Put,
        url: generateApiPath(
          solutionType.toUpperCase() === SolutionType.ioTH.toUpperCase()
            ? API_SOLUTION_UPDATE_STATUS_IOTH
            : API_SOLUTION_UPDATE_STATUS,
          {
            solutionId,
          }
        ),
        params: { status: 'Succeeded' },
      });
    },
    () => {
      showAlert({
        type: AlertType.Success,
        title: 'Solution is Activated',
        text: 'Solution is in activated state.',
      });
      actions.details(solutionId);
    }
  );

  return (
    <Box textAlign="right">
      {loading && <PageCardLoader />}
      {data?.status === SolutionItemStatus.Suspended ? (
        <Button
          size="small"
          variant="outlined"
          onClick={activateSolution}
          disabled={isActivateDisable || isPlatformReader}
        >
          Activate the solution
        </Button>
      ) : (
        <Button
          size="small"
          variant="outlined"
          onClick={showSuspendDialog}
          disabled={isPlatformReader}
        >
          {suspendDialog}
          Suspend this solution
        </Button>
      )}
    </Box>
  );
}
export default SolutionSuspension;
