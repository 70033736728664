import { ChangeEvent } from 'react';
import * as Yup from 'yup';
import { InputAdornment, TextField, Typography } from '@mui/material';
import TreeSelect from 'src/components/TreeSelect';
import TextFieldWithInfo from 'src/components/TextField';
import {
  SolutionNamespaceRscLimitItemField,
} from '../types';
import { getProcessedUsedQuotaLimits } from './utils';

export const solutionSiteAssignFormFields = (
  optionsData?: {
    parentId: string;
    parentLabel: string;
    children: { label: string; value: string }[];
  }[],
  handleAssignSiteChange?: (value) => void,
  loading?: boolean
) => [
  {
    name: 'SiteClusters',
    label: 'Site Name',
    placeholder: 'Choose the sites',
    Component: TreeSelect,
    levelOptions: optionsData,
    onChange: handleAssignSiteChange,
  },
];

export const getSolutionClusterNamespaceRscLimitFields = (
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void,
  namespaceQuotaDetails?: any
) => [
  {
    type: 'heading',
    name: 'NamespaceLevel',
    label: 'Namespace level',
  },
  {
    name: SolutionNamespaceRscLimitItemField.CPULimit,
    label: 'CPU Limit Actual',
    Component: TextFieldWithInfo,
    required: true,
    onChange: handleChange,
    validation: Yup.string().required('CPU limit is required'),
    showcirclularprogress: true,
    circularprogressprops: {
      actual: Number(namespaceQuotaDetails.limitsCpu),
      used: getProcessedUsedQuotaLimits(namespaceQuotaDetails.usedLimitsCpu),
      message: (
        <>
          <b>Used:</b>
          <br />
          {namespaceQuotaDetails.usedLimitsCpu}
        </>
      ),
    },
    InputProps: {
      endAdornment: (
        <InputAdornment position="start">
          <Typography fontSize="16px" variant="body2bold" color={'#000000'}>
            Milicores
          </Typography>
        </InputAdornment>
      ),
    },
    placeholder: 'Eg. 1000',
    sx: { width: ({ spacing }) => spacing(43.3), paddingRight: '10px' },
  },
  {
    name: SolutionNamespaceRscLimitItemField.CPUReservation,
    label: 'CPU Reservation Actual',
    Component: TextFieldWithInfo,
    required: true,
    onChange: handleChange,
    validation: Yup.string().required('CPU reservation is required'),
    showcirclularprogress: true,
    circularprogressprops: {
      actual: Number(namespaceQuotaDetails.requestsCpu),
      used: getProcessedUsedQuotaLimits(namespaceQuotaDetails.usedRequestsCpu),
      message: (
        <>
          <b>Used:</b>
          <br />
          {namespaceQuotaDetails.usedRequestsCpu}
        </>
      ),
    },
    InputProps: {
      endAdornment: (
        <InputAdornment position="start">
          <Typography fontSize="16px" variant="body2bold" color={'#000000'}>
            Milicores
          </Typography>
        </InputAdornment>
      ),
    },
    placeholder: 'Eg. 1000',
    sx: { width: ({ spacing }) => spacing(43.3), paddingRight: '10px' },
  },
  {
    name: SolutionNamespaceRscLimitItemField.MemoryLimit,
    label: 'Memory Limit Actual',
    Component: TextFieldWithInfo,
    required: true,
    onChange: handleChange,
    validation: Yup.string().required('Memory limit is required'),
    showcirclularprogress: true,
    circularprogressprops: {
      actual: Number(namespaceQuotaDetails.limitsMemory),
      used: getProcessedUsedQuotaLimits(namespaceQuotaDetails.usedLimitsMemory),
      message: (
        <>
          <b>Used:</b>
          <br />
          {namespaceQuotaDetails.usedLimitsMemory}
        </>
      ),
    },
    InputProps: {
      endAdornment: (
        <InputAdornment position="start">
          <Typography fontSize="16px" variant="body2bold" color={'#000000'}>
            {' '}
            MiB
          </Typography>
        </InputAdornment>
      ),
    },
    placeholder: 'Eg. 128',
    sx: { width: ({ spacing }) => spacing(43.3), paddingRight: '10px' },
  },
  {
    name: SolutionNamespaceRscLimitItemField.MemoryReservation,
    label: 'Memory Reservation Actual',
    Component: TextFieldWithInfo,
    required: true,
    onChange: handleChange,
    validation: Yup.string().required('Memory reservation is required'),
    showcirclularprogress: true,
    circularprogressprops: {
      actual: Number(namespaceQuotaDetails.requestsMemory),
      used: getProcessedUsedQuotaLimits(
        namespaceQuotaDetails.usedRequestsMemory
      ),
      message: (
        <>
          <b>Used:</b>
          <br />
          {namespaceQuotaDetails.usedRequestsMemory}
        </>
      ),
    },
    InputProps: {
      endAdornment: (
        <InputAdornment position="start">
          <Typography fontSize="16px" variant="body2bold" color={'#000000'}>
            MiB
          </Typography>
        </InputAdornment>
      ),
    },
    placeholder: 'Eg. 128',
    sx: { width: ({ spacing }) => spacing(43.3), paddingRight: '10px' },
  },
  {
    type: 'heading',
    name: 'StorageLevel',
    label: 'Storage level',
  },
  {
    name: SolutionNamespaceRscLimitItemField.StorageLimit,
    label: 'Storage Limit Actual',
    Component: TextFieldWithInfo,
    required: true,
    onChange: handleChange,
    InputProps: {
      inputProps: { min: 0 },
      endAdornment: (
        <InputAdornment position="start">
          <Typography fontSize="16px" variant="body2bold" color={'#000000'}>
            MiB
          </Typography>
        </InputAdornment>
      ),
    },
    validation: Yup.string().required('Limit is required'),
    showcirclularprogress: true,
    circularprogressprops: {
      actual: Number(namespaceQuotaDetails.requestsStorage),
      used: getProcessedUsedQuotaLimits(
        namespaceQuotaDetails.usedRequestsStorage
      ),
      message: (
        <>
          <b>Used:</b>
          <br />
          {namespaceQuotaDetails.usedRequestsStorage}
        </>
      ),
    },
    placeholder: 'Eg. 128',
    sx: { width: ({ spacing }) => spacing(43.2), paddingRight: '10px' },
  },
];

export const getSolutionNamespaceRscLimitFields = (
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void
) => [
  {
    type: 'heading',
    name: 'NamespaceLevel',
    label: 'Namespace level',
  },
  {
    name: SolutionNamespaceRscLimitItemField.CPUReservation,
    label: 'CPU Reservation',
    Component: TextField,
    required: true,
    onChange: handleChange,
    validation: Yup.string().required('CPU reservation is required'),
    InputProps: {
      endAdornment: (
        <InputAdornment position="start">
          <Typography fontSize="16px">mCPUs</Typography>
        </InputAdornment>
      ),
    },
    placeholder: 'Eg. 1000',
    sx: { width: ({ spacing }) => spacing(42) },
  },
  {
    name: SolutionNamespaceRscLimitItemField.MemoryReservation,
    label: 'Memory Reservation',
    Component: TextField,
    required: true,
    onChange: handleChange,
    validation: Yup.string().required('Memory reservation is required'),
    InputProps: {
      endAdornment: (
        <InputAdornment position="start">
          <Typography fontSize="16px">MiB</Typography>
        </InputAdornment>
      ),
    },
    placeholder: 'Eg. 128',
    sx: { width: ({ spacing }) => spacing(42) },
  },
  {
    name: SolutionNamespaceRscLimitItemField.CPULimit,
    label: 'CPU Limit',
    Component: TextField,
    required: true,
    onChange: handleChange,
    validation: Yup.string().required('CPU limit is required'),
    InputProps: {
      endAdornment: (
        <InputAdornment position="start">
          <Typography fontSize="16px">mCPUs</Typography>
        </InputAdornment>
      ),
    },
    placeholder: 'Eg. 1000',
    sx: { width: ({ spacing }) => spacing(42) },
  },
  {
    name: SolutionNamespaceRscLimitItemField.MemoryLimit,
    label: 'Memory Limit',
    Component: TextField,
    required: true,
    onChange: handleChange,
    validation: Yup.string().required('Memory limit is required'),
    InputProps: {
      endAdornment: (
        <InputAdornment position="start">
          <Typography fontSize="16px"> MiB</Typography>
        </InputAdornment>
      ),
    },
    placeholder: 'Eg. 128',
    sx: { width: ({ spacing }) => spacing(42) },
  },
  {
    type: 'heading',
    name: 'StorageLevel',
    label: 'Storage level',
  },
  {
    name: SolutionNamespaceRscLimitItemField.StorageLimit,
    label: 'Limit',
    Component: TextField,
    required: true,
    onChange: handleChange,
    InputProps: {
      inputProps: { min: 0 },
      endAdornment: (
        <InputAdornment position="start">
          <Typography fontSize="16px">MiB</Typography>
        </InputAdornment>
      ),
    },
    validation: Yup.string().required('Limit is required'),
    placeholder: 'Eg. 128',
    sx: { width: ({ spacing }) => spacing(42) },
  },
];
