import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import { Option } from 'src/components/SelectOption';
import AutoCompleteWithFreeText from 'src/components/AutoComplete/AutoCompleteWithFreeText';

export const NewApplicationFormFields = (options: Option[]) => [
  {
    name: 'applicationName',
    label: 'Application Name',
    placeholder: 'Application Name',
    Component: TextField,
    validation: Yup.string().required('Application Name is required'),
    required: true,
  },
  {
    name: 'clientId',
    label: 'Client ID',
    placeholder: 'Enter ClientID',
    Component: TextField,
    validation: Yup.string().required('ClientID is required'),
    required: true,
  },
  {
    name: 'AsignRoles',
    label: 'Assign Roles',
    placeholder: 'Search here...',
    Component: AutoCompleteWithFreeText,
    validation: Yup.array()
      .nullable()
      .min(1, 'Assign Roles field must have at least 1 item')
      .required('Assign Roles field is required'),
    required: true,
    multiple: true,
    options,
  },
];

export const EditApplicationFormFields = (
  options: Option[],
  assignedRoles: string[]
) => [
  {
    name: 'name',
    label: 'Application Name',
    placeholder: 'Application Name',
    Component: TextField,
    validation: Yup.string().required('Application Name is required'),
    required: true,
    disabled: true,
  },
  {
    name: 'id',
    label: 'Client ID',
    placeholder: 'Enter ClientID',
    Component: TextField,
    validation: Yup.string().required('ClientID is required'),
    required: true,
    disabled: true,
  },
  {
    name: 'AsignRoles',
    label: 'Assign Roles',
    placeholder: 'Search here...',
    Component: AutoCompleteWithFreeText,
    validation: Yup.array()
      .nullable()
      .min(1)
      .required('AssignedRole field is required'),
    required: true,
    value: assignedRoles,
    selectedOptions: assignedRoles,
    multiple: true,
    options,
  },
];
