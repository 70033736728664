import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Add from '@mui/icons-material/Add';
import DataTable, {
  DataTableWrapper,
  DataTableWrapperActionButton,
  getActionsColConfig,
  useTableQueryParams,
} from 'src/components/DataTable';
import { useStoreUnitActions } from 'src/redux/helpers';
import { useModal } from 'src/hooks/modalHooks';
import { isModalComplete } from 'src/components/ModalDialog';
import solutionsStore, { useSolutionCompanies } from '../redux';
import { useSolutionUserRoles } from '../hooks';
import { SolutionCompanyField } from '../types';
import SolutionCompaniesAssignModal from './components/SolutionCompaniesAssignModal';
import SolutionsCompaniesAction from './components/SolutionsCompaniesAction';

const columns = [
  { id: SolutionCompanyField.Name, title: 'Company name' },
  { id: SolutionCompanyField.ClientId, title: 'Company App/Client ID' },
  getActionsColConfig(SolutionsCompaniesAction),
];

function SolutionCompanies() {
  const { solutionId } = useParams();
  const actions = useStoreUnitActions(solutionsStore);
  const { paging } = useTableQueryParams();
  const { isSolutionEditor } = useSolutionUserRoles();
  const [data, loading] = useSolutionCompanies();
  const [AssignCompanyDialog, showAssignCompanyDialog] = useModal(
    SolutionCompaniesAssignModal,
    {
      onClose: (event, reason) =>
        isModalComplete(reason) && actions.companies(solutionId),
    }
  );

  useEffect(() => {
    actions.companies(solutionId);
  }, [solutionId]);

  return (
    <DataTableWrapper
      title="Companies"
      actionsPanel={
        <DataTableWrapperActionButton
          onClick={showAssignCompanyDialog}
          startIcon={<Add />}
          disabled={!isSolutionEditor}
        >
          Assign company
        </DataTableWrapperActionButton>
      }
    >
      <DataTable columns={columns} data={data} {...paging} loading={loading} />
      {AssignCompanyDialog}
    </DataTableWrapper>
  );
}

export default SolutionCompanies;
