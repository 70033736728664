import { useState } from 'react';
import DataTableCheckbox from '../components/DataTableCheckbox';
import {
  CommonColumn,
  Row,
  Column,
  SelectedItems,
  RowKeyValue,
  SelectRowHandler,
  SelectAllHandler,
} from '../types';
import { getRowKey, mapRowsToSelection, mapSelectionToArray } from '../utils';

type inputProps<TRow> = {
  checkboxes?: boolean;
  data: TRow[];
  inputColumns: Column<TRow>[];
  onSelectionChange?: (items: RowKeyValue[]) => void;
};

type outputProps<TRow> = {
  columns: Column<TRow>[];
  selectedItems: SelectedItems;
  onSelectRow: SelectRowHandler<TRow>;
  onSelectAll: SelectAllHandler;
};

const checkboxColumn = {
  id: CommonColumn.Checkbox,
  Component: DataTableCheckbox,
  HeaderComponent: DataTableCheckbox,
  sx: {
    width: '7.5rem',
  },
};

export const useRowsSelection = <TRow extends Row>({
  checkboxes,
  data,
  inputColumns,
  onSelectionChange,
}: inputProps<TRow>): outputProps<TRow> => {
  const [selectedItems, setSelectedItems] = useState<SelectedItems>({});
  const columns = checkboxes
    ? [checkboxColumn as Column<TRow>, ...inputColumns]
    : inputColumns;

  const handleChangeSelection = (newSelection: SelectedItems) => {
    if (onSelectionChange) {
      onSelectionChange(mapSelectionToArray(newSelection));
    }
    return newSelection;
  };

  const onSelectRow = (selected: boolean, row: TRow) => {
    const rowKey = getRowKey(row);
    setSelectedItems((currSelection) =>
      handleChangeSelection({ ...currSelection, [rowKey]: selected })
    );
  };

  const onSelectAll = (selectAll: boolean) => {
    setSelectedItems(() =>
      handleChangeSelection(selectAll ? mapRowsToSelection(data) : {})
    );
  };

  return {
    columns,
    selectedItems,
    onSelectRow,
    onSelectAll,
  };
};
