import { ContentType, RequestBodyParams, RequestMethod } from './types';

const getFormData = (params: Record<string, any>) => {
  const formData = new FormData();

  Object.keys(params).forEach((key: string) => {
    formData.append(key, params[key]);
  });
  return formData;
};

type GetRequestBodyParams = {
  contentType?: ContentType;
  method: RequestMethod;
  params?: RequestBodyParams;
};

export const getRequestBody = ({
  contentType,
  method,
  params,
}: GetRequestBodyParams) => {
  if (method !== RequestMethod.Get && params) {
    if (contentType === ContentType.FormData) {
      return getFormData(params);
    }
    return JSON.stringify(params);
  }
  return null;
};

export const setContentType = (
  headers?: Record<string, any>,
  contentType?: ContentType
) => {
  if (contentType && contentType !== ContentType.FormData) {
    headers = headers || {};
    headers['Content-Type'] = contentType;
  }
};

export const isJsonResponse = ({ headers }: Response) => {
  const contentType = headers.get('Content-type');
  return contentType && contentType.includes(ContentType.JSON);
};
