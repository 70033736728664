import { Box } from '@mui/material';

type Props = {
  value?: any;
};
function SolutionRuleOperator({ value }: Props) {
  const operators: any = {
    '==': 'Equals',
    '!=': 'Does not equal',
    '>=': 'Is greater or equal to',
    '<=': 'Is lower or equal to',
    '>': 'Is greater than',
    '<': 'Is lower than',
  };

  return <Box>{operators[value]}</Box>;
}

export default SolutionRuleOperator;
