import * as Yup from 'yup';
import FileUploadInput from 'src/components/FileUploadInput';
import { ALLOWED_EXTENSION } from './constants';

const fileInputField = {
  Component: FileUploadInput,
  type: 'file',
  required: true,
  validation: Yup.mixed()
    .required('A file is required')
    .test(
      'fileFormat',
      ({ value }) =>
        `This file (${value?.name}) type is not supported. Only ${ALLOWED_EXTENSION} are allowed.`,
      (value) => value?.type === ALLOWED_EXTENSION
    ),
};

export const contextModelUploadFormFields = [
  {
    name: 'SerializedJsonContent',
    label: 'Model definitions',
    ...fileInputField,
  },
];

export const getDeviceModelUploadFormFields = (isEdgeDevice: boolean) => [
  {
    name: 'IsEdgeDevice',
    initialValue: isEdgeDevice,
  },
  {
    name: 'SerializedJsonModel',
    label: 'Model definitions',
    ...fileInputField,
  },
  {
    name: 'SerializedJsonIoTCTemplate',
    label: 'IoT Central template',
    ...fileInputField,
  },
];

export const getDeviceModelUploadFormFieldsIotH = (isEdgeDevice: boolean) => [
  {
    name: 'IsEdgeDevice',
    initialValue: isEdgeDevice,
  },
  {
    name: 'SerializedJsonDeploymentManifest',
  },
  {
    name: 'SerializedJsonModel',
    label: 'Model definitions',
    ...fileInputField,
  },
];

export const getEdgeDeviceModelUploadFormFieldsIotH = (
  isEdgeDevice: boolean
) => [
  {
    name: 'IsEdgeDevice',
    initialValue: isEdgeDevice,
  },
  {
    name: 'SerializedJsonModel',
    label: 'Model definitions',
    ...fileInputField,
  },
  {
    name: 'SerializedJsonDeploymentManifest',
    label: 'Deployment Manifest',
    ...fileInputField,
  },
];
