import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import Select, { getSelectOptions } from 'src/components/Select';
import { nanToUndefined } from 'src/utils';
import { SiteClusterItemField } from '../types';

export const addClusterFormFields = [
  {
    name: SiteClusterItemField.Name,
    label: 'Name',
    placeholder: 'Enter cluster name',
    Component: TextField,
    validation: Yup.string().required('Cluster name is required'),
    required: true,
  },
];
