import now from 'lodash/now';
import { DefaultDatasetUnit } from 'src/redux/helpers';
import {
  SolutionType,
  SolutionDevices,
  SolutionDeviceConfigItem,
  SolutionDeviceConfigModelType,
  SolutionDeviceBulkDeploymentData,
  SolutionDeviceReleaseItem,
  SolutionDeviceItem,
  SolutionDeviceCertDetailsItem,
  SolutionDeviceCertAuditLogsItem,
  CertRequestType,
  CertificateType,
  SolutionDeviceGroups,
  SolutionDeviceGroupPropertyItem,
  SolutionDigitalTwinModel,
  SolutionUserItem,
  SolutionItem,
  SolutionFilesWhitelistItem,
  SolutionSignatureWhitelistItem,
  SolutionJobs,
  SolutionSubscriptionItem,
  SolutionJobStatus,
  SolutionDeviceObservPropsItem,
  SolutionDetailsItem,
  SolutionNamespaceRscLimitData,
  SolutionAdxTablesItem,
  SolutionDevicesModulesList,
  SolutionConsumerFeatures,
  SolutionIndustrialFeatures,
  SubscriptionType,
  SolutionDevicesComponetsList,
  SolutionDevicesRulesManagementList,
  SolutionDevicesSpecificRulesManagementList,
  SolutionNamespaceRscQuotasDetailItem,
  SolutionAlertRuleItem,
} from '../types';
import {
  DashboardSummaryList,
  DashboardsDeviceGroupList,
} from '../types/solutionDashboard';

const getFilteredSoltuions = (data, type) => {
  const item = data.map((ele) => ({
    ...ele,
    kibanaSpaceUrl: ele?.kibanaSpace?.url,
    kibanaSpaceType: ele?.kibanaSpace?.type,
  }));
  switch (type.toUpperCase()) {
    case SolutionType.Consumer.toUpperCase():
      data = item.filter(
        (solution) =>
          solution.solutionType === SolutionType.Consumer ||
          solution.solutionType === null
      );
      break;
    case SolutionType.ioTH.toUpperCase():
      data = item.filter(
        (solution) => solution.solutionType === SolutionType.ioTH
      );
      break;
    default:
      data = item.filter(
        (solution) => solution.solutionType === SolutionType.Industrial
      );
      break;
  }
  return data;
};

export const mapSolutionsListData = (
  items,
  switchValue
): DefaultDatasetUnit<SolutionItem[]> => ({
  data: getFilteredSoltuions(items.items, switchValue),
});

export const mapSolutionDetailsData = (
  response
): DefaultDatasetUnit<SolutionDetailsItem> => {
  const {
    industrialWorkloadInformation,
    notificationPreference,
    projects,
    unlimitedCache,
    unlimitedRetention,
    UnlimitedCacheArr,
    UnlimitedRetentionArr,
    cachePeriod,
    retentionPeriod,
    releasePrefix,
    kibanaSpace,
    features,
    isSmsAccountCreation,
    ...rest
  } = response || {};
  let notificationPreferUpdate: number[] = [1, 2, 3];
  if (notificationPreference) {
    notificationPreferUpdate = notificationPreference?.map(
      (notificationPrefer) => {
        if (notificationPrefer === 'email') {
          return 1;
        }
        if (notificationPrefer === 'sms') {
          return 2;
        }
        return 3;
      }
    );
  }
  const consumerFeatures: string[] = [];
  if (features) {
    if (features?.menderSetup?.menderEnabled === true) {
      consumerFeatures.push(SolutionConsumerFeatures.IsMenderEnabled);
    }
    if (features?.dataIngestion === true) {
      consumerFeatures.push(SolutionConsumerFeatures.IsDataIngested);
    }
    if (
      features?.fileSubscriptionServiceSetup?.fileSubscriptionService === true
    ) {
      consumerFeatures.push(SolutionConsumerFeatures.IsFileSubscriptionService);
    }
    if (isSmsAccountCreation === true) {
      consumerFeatures.push(SolutionConsumerFeatures.IsSmsAccountCreation);
    }
  }

  const industrialFeatures: string[] = [];
  const subscriptionFeatureType: string[] = [];
  if (features) {
    if (features?.dataIngestion === true) {
      industrialFeatures.push(SolutionIndustrialFeatures.IsDataIngested);
    }
    if (features?.isAlarmEventsStreamProcessing === true) {
      industrialFeatures.push(
        SolutionIndustrialFeatures.IsAlarmEventProcessing
      );
    }
    if (
      features?.telemetryStreamProcessingSetup?.isTelemetryStreamProcessing ===
      true
    ) {
      industrialFeatures.push(
        SolutionIndustrialFeatures.IsTelemetryStreamProcessing
      );
    }
    if (
      features?.fileSubscriptionServiceSetup?.fileSubscriptionService ===
        true ||
      features?.isMessageSubscriptionService === true
    ) {
      industrialFeatures.push(SolutionIndustrialFeatures.IsSubscriptionService);
    }
    if (isSmsAccountCreation === true) {
      industrialFeatures.push(SolutionIndustrialFeatures.IsSmsAccountCreation);
    }
    if (
      features?.fileSubscriptionServiceSetup?.fileSubscriptionService === true
    ) {
      subscriptionFeatureType.push(SubscriptionType.Files);
    }
    if (features?.isMessageSubscriptionService === true) {
      subscriptionFeatureType.push(SubscriptionType.Messages);
    }
  }

  return {
    notificationPreference: notificationPreferUpdate,
    industrialWorkloadInformation: {
      AcrClientId: industrialWorkloadInformation?.acrClientId,
      AcrClientSecret: industrialWorkloadInformation?.acrClientSecretName,
      containerRepositoryType:
        industrialWorkloadInformation?.containerRepositoryType === 'acr'
          ? '0'
          : '1',
      ecrAccesKey: industrialWorkloadInformation?.ecrAccesKey,
      ecrSecretKey: industrialWorkloadInformation?.ecrSecretKeyName,
      ecrRegionEndpoint: industrialWorkloadInformation?.ecrRegionEndpoint,
      serverUrl: industrialWorkloadInformation?.sourceServerUrl,
      userName: industrialWorkloadInformation?.sourcePullSecretUserName,
      ImagePullSecretKey:
        industrialWorkloadInformation?.sourcePullSecretPasswordKey,
      ImagePullSecret: industrialWorkloadInformation?.sourcePullSecretPassword,
      sourceDockerImages: industrialWorkloadInformation?.sourceDockerImages,
      ecrServerUrl:
        industrialWorkloadInformation?.containerRepositoryType === 'ecr'
          ? industrialWorkloadInformation?.sourceServerUrl
          : '',
      acrServerUrl:
        industrialWorkloadInformation?.containerRepositoryType === 'acr'
          ? industrialWorkloadInformation?.sourceServerUrl
          : '',
    },
    kibanaSpaceUrl: kibanaSpace?.url,
    kibanaSpaceType: kibanaSpace?.type,
    project: projects ? projects[0]?.name : null,
    namespace: projects ? projects[0]?.namespaces[0]?.name : null,
    CachePeriod: cachePeriod,
    RetentionPeriod: retentionPeriod,
    releasePrefix: features?.menderSetup?.releasePrefix
      ? features?.menderSetup?.releasePrefix
      : releasePrefix,
    UnlimitedCache: unlimitedCache,
    UnlimitedRetention: unlimitedRetention,
    UnlimitedCacheArr: unlimitedCache ? ['UnlimitedCache'] : [],
    UnlimitedRetentionArr: unlimitedRetention ? ['UnlimitedRetention'] : [],
    solutionConsumerFeatures: consumerFeatures,
    solutionIndustrialFeatures: industrialFeatures,
    subscriptionType: subscriptionFeatureType,
    telemetryStreamProcessing:
      features?.telemetryStreamProcessingSetup?.streamProcessingType,
    fileSubscription: features?.fileSubscriptionServiceSetup?.subscriptionType,
    features,
    ...rest,
  };
};

export const mapSolutionAdlsSubscription = (
  response
): SolutionAdxTablesItem => ({
  data: response[0],
});

const getAlertRuleSeverity = (name) => {
  if (name) {
    if (name.toLowerCase().includes('critical')) {
      return 'Critical';
    }
    return 'Minor';
  }
  return '';
};

const getAlertRuleInterval = (schedule) => {
  const interval = schedule?.interval ?? '';
  if (interval) {
    // 15s, 15m, 15h, 15d values available
    if (interval.includes('d')) {
      return interval.replace('d', 'days');
    }
    if (interval.includes('h')) {
      return interval.replace('h', 'hours');
    }
    if (interval.includes('m')) {
      return interval.replace('m', 'mins');
    }
    return interval.replace('s', 'sec');
  }
  return '';
};

export const mapSolutionAlertRules = (
  response
): DefaultDatasetUnit<SolutionAlertRuleItem[]> => ({
  data: response.map((item) => ({
    severity: getAlertRuleSeverity(item?.name),
    interval: getAlertRuleInterval(item?.schedule),
    ...item,
  })),
});

const getListItemId = (field, index) => index + field + now();

export const mapSolutionFilesWhitelistData = (
  items
): DefaultDatasetUnit<SolutionFilesWhitelistItem[]> => ({
  data: items.map((item, index) => ({
    id: getListItemId(item.extension, index),
    ...item,
  })),
});

export const mapSolutionSignatureWhitelistData = (
  items
): DefaultDatasetUnit<SolutionSignatureWhitelistItem[]> => ({
  data: items.map((item, index) => ({
    id: getListItemId(item.signature, index),
    ...item,
  })),
});

export const mapDeviceModelsToConfigList = (
  items: SolutionDigitalTwinModel[] = [],
  contextDigitalTwinsModels: SolutionDigitalTwinModel[] = []
): SolutionDeviceConfigItem[] => {
  const digitalTwinModels = items.map(
    ({ modelDefinitionInfo, ioTCentralTemplateInfo, isEdgeDevice }) => {
      let modelType = SolutionDeviceConfigModelType.ContextModel;
      if (ioTCentralTemplateInfo || modelDefinitionInfo) {
        modelType = SolutionDeviceConfigModelType.DeviceTemplate;
      }
      if (isEdgeDevice) {
        modelType = SolutionDeviceConfigModelType.EdgeDeviceTemplate;
      }

      return {
        id: modelDefinitionInfo.modelId,
        modelType,
        ...modelDefinitionInfo,
      };
    }
  );

  const contextTwinsModels = contextDigitalTwinsModels.map(
    ({ modelDefinitionInfo }) => {
      const modelType = SolutionDeviceConfigModelType.ContextModel;

      return {
        id: modelDefinitionInfo.modelId,
        modelType,
        ...modelDefinitionInfo,
      };
    }
  );

  return [...digitalTwinModels, ...contextTwinsModels];
};

export const mapSolutionDeviceItem = (device): SolutionDeviceItem => {
  const { company, model, ...rest } = device || {};
  return {
    companyId: company?.id,
    companyName: company?.name,
    modelId: model?.modelId,
    modelDisplayName: model?.displayName,
    ...rest,
  };
};

export const mapSolutionDeviceObservProperties = (
  allProperties
): SolutionDeviceObservPropsItem => {
  const { $metadata: data, ...rest } = allProperties || {};
  return {
    MBFILEPATH: data?.MBFILEPATH?.desiredValue,
    MBFILENAME: data?.MBFILENAME?.desiredValue,
    MBROTATEFILE: data?.MBROTATEFILE?.desiredValue,
    MBNUMOFFILE: data?.MBNUMOFFILE?.desiredValue,
    MBPERIOD: data?.MBPERIOD?.desiredValue,
    MBENABLED: data?.MBENABLED?.desiredValue,
    ...rest,
  };
};

export const mapSolutionDevicesData = ({
  devices,
  ...rest
}): SolutionDevices => ({
  data: devices?.map(mapSolutionDeviceItem),
  ...rest,
});

export const mapSolutionDevicesComponentsData = (
  response
): SolutionDevicesComponetsList => {
  let retData = response.map((element) => ({
    requestSchemaType: !element.requestSchema ? '-' : element.requestSchema,
    ...element,
  }));
  retData = retData.filter((comp) => comp.component !== '');
  return { data: retData };
};

export const mapSolutionDevicesModulesData = (
  response
): SolutionDevicesModulesList => {
  const retData = response.map((element) => ({
    moduleType: element.moduleId.includes('$') ? 'system' : 'custom',
    ...element,
  }));
  return { data: retData };
};

export const mapSolutionDashboardsData = (response): DashboardSummaryList => {
  const retData = response.dashboardSummary;
  return { data: retData };
};

export const mapSolutionDashboardDeviceGroupData = (
  response
): DashboardsDeviceGroupList => {
  const retData = response;
  return { data: retData };
};

export const mapSolutionRulesManagementData = (
  response
): SolutionDevicesRulesManagementList => {
  const retData = response.map((element) => ({
    ...element,
  }));
  return { data: retData };
};

export const mapSolutionSpecificRulesManagementData = (
  response: any[]
): SolutionDevicesSpecificRulesManagementList => {
  const retData = response.map((element: any) => ({
    ...element,
  }));
  return { data: retData };
};

export const mapSolutionDevicesPersistData = (
  response,
  currDevices
): SolutionDevices =>
  mapSolutionDevicesData({
    devices: currDevices?.map((item) => ({
      ...item,
      ...response
        .map(({ devices }) => mapSolutionDeviceItem(devices[0]))
        .find((device) => item.id === device?.id),
    })),
  });

export const mapSolutionDeviceGroups = ({
  deviceGroups,
  ...rest
}): SolutionDeviceGroups => ({
  data: deviceGroups,
  ...rest,
});

export const mapSolutionJobsData = ({ items, totalCount }): SolutionJobs => ({
  data: items.map(({ organizations, ...rest }) => ({
    runLater: rest?.status === SolutionJobStatus.Scheduled,
    organization: organizations[0],
    ...rest,
  })),
  totalItems: totalCount,
});

const getFilteredData = (data, type) => {
  if (type === CertificateType.Mender) {
    data = data.filter(
      (cert) =>
        cert.requestType === CertRequestType.Mender ||
        cert.commonName.includes(CertificateType.Mender)
    );
  } else {
    data = data.filter(
      (cert) =>
        cert.requestType === CertRequestType.IOTC ||
        !cert.commonName.includes(CertificateType.Mender)
    );
  }
  return data;
};

export const mapSolutionDeviceCertDetails = (
  response,
  type
): SolutionDeviceCertDetailsItem[] => {
  const data = response.map((cert) => cert);
  return getFilteredData(data, type);
};

export const mapSolutionDeviceCertAuditLogs = (
  response,
  type
): SolutionDeviceCertAuditLogsItem[] => {
  const data = response.map((cert) => cert);
  return getFilteredData(data, type);
};

export const mapSolutionUsersData = ({
  solutionOwners,
  solutionUsers,
}): DefaultDatasetUnit<SolutionUserItem[]> => {
  const mapUsers = (users: SolutionUserItem[]): SolutionUserItem[] =>
    users.map((x) => x);

  const items = [...mapUsers(solutionOwners), ...mapUsers(solutionUsers)];

  return {
    data: items.map((item) => {
      if (item?.phoneNumber) {
        const countryCodeLength = item.phoneNumber.length - 10;
        const countryCode = item.phoneNumber.substring(0, countryCodeLength);
        const mobileNumber = item.phoneNumber.substring(
          countryCodeLength,
          item.phoneNumber.length
        );
        return { ...item, countryCode, mobileNumber };
      }
      return item;
    }),
  };
};

export const mapSolutionDeviceGroupDetails = ({ filters, ...data }) => ({
  data: {
    filters: filters.map(({ property, ...rest }) => ({
      property: property.id,
      propertyName: property.displayName,
      ...rest,
    })),
    ...data,
  },
});

export const mapSolutionDeviceGroupProperties = (
  properties: SolutionDeviceGroupPropertyItem[]
) => {
  const mapToOption = ({ description, value, displayName }) => ({
    label: description || displayName,
    value,
  });
  const mapToOptions = (values) => (values ? values.map(mapToOption) : null);

  return {
    data: [
      ...properties.map(
        ({ aggregationFunctions, operators, enums, ...property }) => ({
          ...property,
          operators: mapToOptions(operators),
          aggregationFunctions: mapToOptions(aggregationFunctions),
          enums: mapToOptions(enums),
        })
      ),
    ],
  };
};

export const mapDeviceDepReleasesData = (
  releases
): DefaultDatasetUnit<SolutionDeviceReleaseItem[]> => ({
  data: releases?.map(({ Artifacts }) => Artifacts[0]),
});

export const mapDeviceBulkDeploymentData = ([
  { deploymentDetails, deploymentStatistics },
  logs,
]): SolutionDeviceBulkDeploymentData => {
  const {
    aborted,
    noArtifact,
    pause_before_installing,
    pause_before_rebooting,
    pause_before_committing,
    downloading,
    installing,
    rebooting,
    ...rest
  } = deploymentStatistics || {};
  return {
    details: deploymentDetails,
    statistics: {
      ...rest,
      pause:
        pause_before_installing +
        pause_before_rebooting +
        pause_before_committing,
      skipped: aborted + noArtifact + rest['already-installed'],
      installing: downloading + installing + rebooting,
    },
    logs,
  };
};

export const mapSubscriptionsData = (
  response
): DefaultDatasetUnit<SolutionSubscriptionItem[]> => ({
  data: response.map(({ target, ...rest }) => ({
    target: target.url,
    useAuth: !!target.headers.Authorization,
    Authorization: target.headers.Authorization,
    ...rest,
  })),
});

export const mapSolutionNamespaceRscLimitData = (
  response
): SolutionNamespaceRscLimitData => {
  const { id, metadata } = response;
  const parsedValues = JSON.parse(
    metadata.annotations['field.cattle.io/containerDefaultResourceLimit']
  );

  return {
    data: {
      id,
      name: metadata.name,
      requestsCpu: parsedValues.requestsCpu,
      requestsMemory: parsedValues.requestsMemory,
      limitsCpu: parsedValues.limitsCpu,
      limitsMemory: parsedValues.limitsMemory,
      limitsGpu: parsedValues.limitsGpu,
    },
  };
};

export const mapSolutionNamespaceRscQuotasDetailData = (
  response,
  clusterId
): SolutionNamespaceRscQuotasDetailItem => {
  const { data } = response;
  const result = data[0];

  return {
    id: result.id,
    clusterId,
    usedRequestsCpu: result.status.used['requests.cpu'],
    usedRequestsMemory: result.status.used['requests.memory'],
    usedLimitsCpu: result.status.used['limits.cpu'],
    usedLimitsMemory: result.status.used['limits.memory'],
    usedRequestsStorage: result.status.used['requests.storage'],
  };
};

export const mapSolutionAdxMappedTables = (
  response
): SolutionAdxTablesItem => ({
  data: {
    adxTables: response,
  },
});
