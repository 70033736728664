import { generatePath } from 'react-router-dom';
import ModalDialog, { ModalCloseHandler } from 'src/components/ModalDialog';
import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { pepApiRequest, RequestMethod } from 'src/services';
import { useStoreUnitActions } from 'src/redux/helpers';
import solutionsStore, {
  useSolutionDetails,
  useSolutionNamespaceRscLimit,
} from '../../redux';
import { SolutionNamespaceRscLimitDetails } from './SolutionNamespaceRscLimitDetails';
import { mapSolutionNamespaceRscQuotasDetailData } from '../../redux/utils';
import { API_SOLUTION_NAMESPACE_RSC_QUOTAS_DETAIL } from '../../constants';

type Props = {
  siteId: string;
  siteName: string;
  clusterIds: string[];
  open: boolean;
  onClose: ModalCloseHandler;
};

function SolutionNamespaceRscLimitViewModal({
  siteId,
  siteName,
  clusterIds,
  open,
  onClose,
}: Props) {
  const actions = useStoreUnitActions(solutionsStore);
  const [solutionData, solutionLoading] = useSolutionDetails();
  const namespaceName = solutionData?.namespace;
  const [data, loading] = useSolutionNamespaceRscLimit();
  const [namespaceBulkRscQuotasData, setNamespaceBulkQuotasData] = useState<
    any[]
  >([]);
  const [namespaceQuotaLoader, setNamespaceQuotaLoader] =
    useState<boolean>(false);
  const clusteridsarray = clusterIds;
  const payload = { clusterIds: clusteridsarray };

  useEffect(() => {
    actions.clusterRscLimit({ siteId, namespaceName, clusterIds: payload });
  }, [clusterIds]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (clusteridsarray && clusteridsarray.length !== 0) {
          const requests = clusterIds.map(async (clusterId) => {
            setNamespaceQuotaLoader(true);
            const apiUrl = generatePath(
              API_SOLUTION_NAMESPACE_RSC_QUOTAS_DETAIL,
              {
                solutionId: solutionData?.id,
                clusterId,
              }
            );

            try {
              const response = await pepApiRequest({
                method: RequestMethod.Get,
                url: apiUrl,
              });
              return mapSolutionNamespaceRscQuotasDetailData(
                response,
                clusterId
              );
            } catch (error) {
              return {};
            }
          });

          const responses = await Promise.all(requests);
          setNamespaceBulkQuotasData(responses);
          setNamespaceQuotaLoader(false);
        }
      } catch (error) {
        console.error('Error in getting namespace quotas details:', error);
      }
    };

    fetchData();
  }, [clusterIds, loading]);

  return (
    <ModalDialog
      open={open}
      title={`Quota Limits : ${siteName}`}
      showCancelBtn={true}
      showConfirmBtn={false}
      loading={solutionLoading || loading || namespaceQuotaLoader}
      onClose={onClose}
      maxWidth="xl"
      fullWidth
      titleStyle={{ fontWeight: '500' }}
    >
      <Box
        style={{ maxHeight: '80vh', overflowY: 'auto' }}
        justifyContent="space-between"
      >
        <SolutionNamespaceRscLimitDetails
          data={namespaceBulkRscQuotasData}
          rscLimitData={data}
          siteId={siteId}
          namespaceName={namespaceName}
          loading={loading}
          payload={payload}
        />
      </Box>
    </ModalDialog>
  );
}

export default SolutionNamespaceRscLimitViewModal;
