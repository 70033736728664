import { useParams } from 'react-router-dom';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { generateApiPath } from 'src/utils';
import { useStoreUnitActions } from 'src/redux/helpers';
import { API_SOURCE_IMAGE_REPOSITORY } from '../../constants';
import solutionsStore from '../../redux/solutionsStore';
import SolutionSourceImageRepoEditModal from './SolutionSourceImageRepoEditModal';
import { getSolutionSourceImageRepositoryParams } from '../utils';

type Props = {
  open: boolean;
  onClose: ModalCloseHandler;
};

function SolutionSourceImageRepoConfig({ open, onClose }: Props) {
  const { showAlert } = useAlert();
  const { solutionId } = useParams();

  const actions = useStoreUnitActions(solutionsStore);
  const [onSubmit, loading] = useStatefulApi(
    (params) =>
      pepApiRequest({
        method: RequestMethod.Put,
        url: generateApiPath(API_SOURCE_IMAGE_REPOSITORY, { solutionId }),
        params: {
          ...getSolutionSourceImageRepositoryParams(params),
        },
      }),
    () => {
      showAlert({
        type: AlertType.Success,
        title: 'Source image repository configured.',
        text: 'New source image repository has been successfully configured.',
      });
      onClose(null, ModalCloseReason.completeAction);
      actions.details(solutionId);
    }
  );

  return (
    <SolutionSourceImageRepoEditModal
      title={'Configure Source Image Repository'}
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      confirmBtnText={'Submit'}
      loading={loading}
    />
  );
}

export default SolutionSourceImageRepoConfig;
