import React from 'react';
import { CompanyUserRole } from '../../types';

type Props = {
  value?: CompanyUserRole;
};

function CompanyUsersRole({ value }: Props) {
  const rolesMap = {
    [CompanyUserRole.Owner]: 'Company owner',
    [CompanyUserRole.User]: 'Company user',
  };

  return <>{rolesMap[value as CompanyUserRole]}</>;
}

export default CompanyUsersRole;
