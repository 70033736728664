import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { SxProps } from 'src/theme/types';

type Props = {
  options: number[];
  value: number;
  onChange: (value: number) => void;
};

const style: SxProps = {
  mx: 2,
  width: 100,
  '.MuiSelect-select.MuiSelect-outlined': {
    px: 2,
    py: 1,
    color: 'black',
    minHeight: 'auto',
    textAlign: 'left',
    typography: 'body2bold',
  },
  '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
    borderColor: 'primary.light',
  },
  'fieldset.MuiOutlinedInput-notchedOutline': {
    borderColor: 'neutral.main',
  },
};

function PaginationPageSizeSelector({ onChange, options, value }: Props) {
  return (
    <Select
      data-testid="pagination-page-size-select"
      sx={style}
      size="small"
      value={value}
      onChange={({ target }: any) => onChange(target.value)}
    >
      {options.map((item) => (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      ))}
    </Select>
  );
}

export default PaginationPageSizeSelector;
