import React, { ReactNode, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import auth from '../auth';
import AuthIdentityProvider from './AuthIdentityProvider';
import { useUnmountEffect } from '../../hooks';

type Props = {
  children: ReactNode;
};

function AuthProvider({ children }: Props) {
  const navigate = useNavigate();
  const oktaAuth = useMemo(() => auth, []);

  const restoreOriginalUri = useCallback(
    async (_oktaAuth: OktaAuth, originalUri: string) => {
      navigate(toRelativeUrl(originalUri || '/', window.location.origin));
    },
    []
  );

  // fix for "Two custom restoreOriginalUri callbacks are detected" warning
  useUnmountEffect(() => {
    oktaAuth.options.restoreOriginalUri = undefined;
  });

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <AuthIdentityProvider>{children}</AuthIdentityProvider>
    </Security>
  );
}

export default AuthProvider;
