import { generatePath, useParams } from 'react-router-dom';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import ModalDialog from 'src/components/ModalDialog';
import { useStoreUnitActions } from 'src/redux/helpers';
import { RequestMethod, pepApiRequest, useStatefulApi } from 'src/services';
import solutionsStore from '../../redux';
import { API_OS_WHITELIST } from '../../constants';
import { SolutionSignatureWhitelistItem } from '../../types';

type Props = {
  item: SolutionSignatureWhitelistItem;
  open: boolean;
  onClose: () => void;
};

function SignatureWhitelistDeleteModal({ item, open, onClose }: Props) {
  const { solutionId } = useParams();
  const actions = useStoreUnitActions(solutionsStore);
  const { showAlert } = useAlert();

  const [handleRemove, loading] = useStatefulApi(
    () =>
      pepApiRequest({
        method: RequestMethod.Delete,
        url: generatePath(API_OS_WHITELIST, { solutionId }),
        params: { operatingSystems: [item] },
      }),
    () => {
      showAlert({
        type: AlertType.Success,
        title: 'OS signature Whitelist deleted',
        text: 'OS signature whitelist has been successfully deleted.',
      });
      actions.signatureWhitelist(solutionId);
      onClose();
    }
  );

  return (
    <ModalDialog
      open={open}
      title="Remove OS signature"
      text="Are you sure to remove the OS signature from the whitelist?"
      loading={loading}
      onClose={onClose}
      onConfirm={handleRemove}
      cancelBtnText="No"
      confirmBtnText="Yes"
    />
  );
}

export default SignatureWhitelistDeleteModal;
