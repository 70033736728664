import React from 'react';
import { generatePath, useParams } from 'react-router-dom';
import ModalDialog from 'src/components/ModalDialog';
import { RequestMethod, pepApiRequest, useStatefulApi } from 'src/services';
import { useStoreUnitActions } from 'src/redux/helpers';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import { SolutionFilesWhitelistItem } from '../../types';
import solutionsStore from '../../redux';
import { API_WHITELIST } from '../../constants';

type Props = {
  item: SolutionFilesWhitelistItem;
  open: boolean;
  onClose: () => void;
};

function SolutionFilesWhitelistDeleteModal({ item, open, onClose }: Props) {
  const { solutionId } = useParams();
  const actions = useStoreUnitActions(solutionsStore);
  const { showAlert } = useAlert();

  const [handleRemove, loading] = useStatefulApi(
    () =>
      pepApiRequest({
        method: RequestMethod.Delete,
        url: generatePath(API_WHITELIST, { solutionId }),
        params: { whitelistedExtensions: [item] },
      }),
    () => {
      showAlert({
        type: AlertType.Info,
        title: 'Whitelist is Deleted',
        text: 'File extention whitelist has been deleted successfully',
      });
      actions.filesWhitelist(solutionId);
      onClose();
    }
  );

  return (
    <ModalDialog
      open={open}
      title="Remove file extension"
      text="Are you sure to remove the extension from files whitelist?"
      loading={loading}
      onClose={onClose}
      onConfirm={handleRemove}
      cancelBtnText="No"
      confirmBtnText="Yes"
    />
  );
}

export default SolutionFilesWhitelistDeleteModal;
