import { useMemo } from 'react';
import { useSyncState } from 'src/hooks';
import DataTableGroupColCell from '../components/DataTableGroupColCell';
import DataTableGroupToggle from '../components/DataTableGroupToggle';
import {
  Column,
  CommonColumn,
  GroupRowsMeta,
  Row,
  ToggleGroupHandler,
} from '../types';
import {
  getGroupRowsMeta,
  getGroupedRows,
  getRowKey,
  removeColumnById,
} from '../utils';

type useGroupingProps<TRow extends Row> = {
  inputData: TRow[];
  inputColumns: Column<TRow>[];
  groupBy?: string;
};

type useGroupingReturnProps<TRow extends Row> = {
  groupedData: TRow[];
  groupedColumns: Column<TRow>[];
  groupRows: GroupRowsMeta;
  onToggleGroup: ToggleGroupHandler<TRow>;
};

const getGroupCustomColumns = (groupingColumn) => [
  {
    id: CommonColumn.GroupToggle,
    Component: DataTableGroupToggle,
    sx: {
      width: '7.5rem',
    },
  },
  {
    ...groupingColumn,
    Component: DataTableGroupColCell,
  },
];

export const useGrouping = <TRow extends Row>({
  inputData,
  inputColumns,
  groupBy,
}: useGroupingProps<TRow>): useGroupingReturnProps<TRow> => {
  const initialGroupRows = useMemo(
    () => (groupBy ? getGroupRowsMeta(inputData, groupBy) : {}),
    [inputData, groupBy]
  );
  const [groupRows, setGroupRows] =
    useSyncState<GroupRowsMeta>(initialGroupRows);

  const groupedData = useMemo(
    () => (groupBy ? getGroupedRows(inputData, groupRows, groupBy) : inputData),
    [inputData, groupRows, groupBy]
  );
  const groupedColumns = useMemo(() => {
    if (groupBy) {
      const [groupingColumn, restColumns] = removeColumnById(
        inputColumns,
        groupBy
      );
      return [...getGroupCustomColumns(groupingColumn), ...restColumns];
    }
    return inputColumns;
  }, [inputColumns, groupBy]);

  const onToggleGroup = (isExpanded: boolean, row: TRow) => {
    const rowKey = getRowKey(row);
    setGroupRows((prev) => ({
      ...prev,
      [rowKey]: { ...prev[rowKey], isExpanded },
    }));
  };

  return {
    groupedData,
    groupedColumns,
    groupRows,
    onToggleGroup,
  };
};
