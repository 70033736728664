import { generatePath, useParams } from 'react-router-dom';
import ModalDialog from 'src/components/ModalDialog';
import { useForm } from 'src/components/Form';
import { RequestMethod, pepApiRequest, useStatefulApi } from 'src/services';
import { useStoreUnitActions } from 'src/redux/helpers';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import solutionsStore, { useCurrentSiteName } from '../../redux';
import { addClusterFormFields } from '../formFields';
import { API_CREATE_CLUSTER, API_SITE_CLUSTERS } from '../../constants';
import { SiteDeviceProjectItem } from '../../types';

type Props = {
  title: string;
  setCluseterDetail: (res) => void;
  setNameSpaces: (res) => void;
  open: boolean;
  onClose: () => void;
};

function SiteClusterCreateModal({
  title,
  setCluseterDetail,
  setNameSpaces,
  open,
  onClose,
}: Props) {
  const siteName = useCurrentSiteName();
  const { siteId } = useParams();
  const { showAlert } = useAlert();
  const actions = useStoreUnitActions(solutionsStore);
  const [onSubmit, loading] = useStatefulApi(
    (params) =>
      pepApiRequest({
        method: RequestMethod.Post,
        url: generatePath(API_CREATE_CLUSTER, { siteId }),
        params,
      }),
    (res, params) => {
      showAlert({
        type: AlertType.Success,
        title: 'New Cluster Created',
        text: `${params.name} is created successfully for site ${siteName}`,
      });
      onClose();
      const nameSpaces: SiteDeviceProjectItem[] = [];
      pepApiRequest({
        method: RequestMethod.Get,
        url: generatePath(API_SITE_CLUSTERS, { siteId }),
        skipErrorAlert: true,
        skipErrorCodes: [500],
      }).then((response) => {
        setCluseterDetail(response);
        const nameSpaces: SiteDeviceProjectItem[] = [];
        response?.clustersDetail?.map((clusters: any) =>
          clusters.nodeNamespaces?.Namespaces.map((namespace) =>
            nameSpaces.push({ nameSpace: namespace })
          )
        );
        setNameSpaces(nameSpaces);
      });
    }
  );

  const [form, handleSubmit] = useForm({
    fields: addClusterFormFields,
    onSubmit,
  });

  return (
    <ModalDialog
      open={open}
      title={title}
      loading={loading}
      onClose={onClose}
      onConfirm={handleSubmit}
      confirmBtnText="Add"
      maxWidth="xs"
      fullWidth
    >
      {form}
    </ModalDialog>
  );
}

export default SiteClusterCreateModal;
