import * as Yup from 'yup';
import endsWith from 'lodash/endsWith';
import TextField from '@mui/material/TextField';
import Select from 'src/components/Select';
import {
  PEP_EMAIL_DOMAIN_PEPSICO,
  PEP_EMAIL_DOMAIN_PEPSICORPTST,
  PEP_EMAIL_DOMAIN_CORPPEPTST,
} from 'src/constants';
import { compact } from 'lodash';
import RadioButtonGroup from 'src/components/RadioButtonGroup';
import CheckboxGroup from 'src/components/CheckboxGroup';
import { SolutionType, SolutionUserItemField } from '../types';
import {
  SOLUTION_USER_ROLE_OPTIONS,
  SOLUTION_USER_COUNTRY_CODE,
  NOTIFICATION_PREFERENCE_OPTIONS,
  IOTH_NOTIFICATION_PREFERENCE_OPTIONS,
} from './constants';

const solutionUserRoleField = {
  name: SolutionUserItemField.Role,
  label: 'Role',
  placeholder: 'Select a role',
  Component: Select,
  options: SOLUTION_USER_ROLE_OPTIONS,
  validation: Yup.string().required('User role is required'),
  fullWidth: true,
  required: true,
  sx: { width: ({ spacing }) => spacing(56) },
  description:
    'Select the appropriate role for the user. A solution owner has one additional access over solution user that is to the Users tab.',
};

const notificationPreferenceField = (solutionType) => ({
  name: SolutionUserItemField.NotificationPreference,
  label: 'Notification preference:',
  Component: CheckboxGroup,
  type: 'checkbox-group',
  required: false,
  labelStyle: { mr: 3 },
  options:
    solutionType === SolutionType.ioTH
      ? IOTH_NOTIFICATION_PREFERENCE_OPTIONS
      : NOTIFICATION_PREFERENCE_OPTIONS,
});

const solutionUserPhoneNumberField = [
  {
    name: SolutionUserItemField.CountryCode,
    label: 'Country Code',
    placeholder: 'Select Country Code',
    initialValue: '+1',
    options: SOLUTION_USER_COUNTRY_CODE,
    Component: Select,
    sx: { width: ({ spacing }) => spacing(17) },
  },
  {
    name: SolutionUserItemField.MobileNumber,
    label: 'Number',
    inputProps: {
      maxLength: 10,
    },
    onChange: (event, context) => {
      context.setValue(
        SolutionUserItemField.MobileNumber,
        event.target.value.replace(/[^0-9]+/g, '')
      );
    },
    validation: Yup.string().test(
      'empty-or-10-number-check',
      'Phone Number must be 10 characters',
      (value) => !value || value.length === 10
    ),
    placeholder: 'Enter phone number',
    Component: TextField,
    sx: { flexGrow: 1 },
  },
];

export const solutionUserAssignFormFields = (solutionType) =>
  compact([
    {
      name: SolutionUserItemField.Email,
      label: 'Email address',
      placeholder: 'Enter email address',
      Component: TextField,
      options: SOLUTION_USER_ROLE_OPTIONS,
      validation: Yup.string()
        .required('Email is required')
        .email('Must be a valid email')
        .test(
          'allowed-Domain',
          `Email should belong to the domain ${PEP_EMAIL_DOMAIN_PEPSICO}, ${PEP_EMAIL_DOMAIN_PEPSICORPTST} or ${PEP_EMAIL_DOMAIN_CORPPEPTST}`,
          (value) =>
            endsWith(value, `@${PEP_EMAIL_DOMAIN_PEPSICO}`) ||
            endsWith(value, `@${PEP_EMAIL_DOMAIN_PEPSICORPTST}`) ||
            endsWith(value, `@${PEP_EMAIL_DOMAIN_CORPPEPTST}`)
        ),
      fullWidth: true,
      required: true,
      initialValue: `@${PEP_EMAIL_DOMAIN_PEPSICO}`,
      description:
        'Enter the email address of a user who will be able to manage this solution.',
    },
    solutionUserRoleField,
    {
      name: 'label',
      label: 'Phone Number',
      Component: RadioButtonGroup,
      option: [],
      sx: { width: ({ spacing }) => spacing(56) },
    },
    ...solutionUserPhoneNumberField,
    notificationPreferenceField(solutionType),
  ]);

export const solutionUserEditFormFields = (solutionType) =>
  compact([
    {
      name: SolutionUserItemField.Email,
    },
    solutionUserRoleField,
    {
      name: 'label',
      label: 'Phone Number',
      Component: RadioButtonGroup,
      option: [],
      sx: { width: ({ spacing }) => spacing(56) },
    },
    ...solutionUserPhoneNumberField,
    notificationPreferenceField(solutionType),
  ]);
