import React from 'react';
import { Control, useController, useFormContext } from 'react-hook-form';
import FormLabel from '@mui/material/FormLabel';
import { FormField, FormFieldDefaultValue } from '../types';
import { getCombinedHandler, getInitialFieldValue } from '../utils';

type Props<T> = FormField<T> & {
  control: Control<any>;
};

function FormControl<T = FormFieldDefaultValue>({
  Component,
  name,
  control,
  description,
  initialValue,
  onChange,
  type,
  ...rest
}: Props<T>) {
  const formContext = useFormContext();
  const defaultValue = getInitialFieldValue({ initialValue, type });
  const extraProps = type === 'file' && { setValue: formContext.setValue };
  const {
    field: { onChange: onFieldChange, ...field },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    defaultValue,
  });

  return Component ? (
    <>
      {description && <FormLabel>{description}</FormLabel>}
      <Component
        data-testid={`form-control-${field.name}`}
        onChange={getCombinedHandler(onFieldChange, onChange, formContext)}
        error={invalid}
        helperText={error && error.message}
        id={name}
        type={type}
        {...extraProps}
        {...field}
        {...rest}
      />
    </>
  ) : null;
}

export default FormControl;
