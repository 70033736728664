import { Dispatch } from 'redux';
import { createAction, createReducer } from '@reduxjs/toolkit';
import { CLEAR } from './actionTypes';
import { getHashFunc, getSliceNamePrefix } from './utils';

export type CreateClearStateSliceOptions<T> = {
  storeName: string;
  datasetHashKey: string;
  initialState?: T;
};

export function clearStateReducer(initialState, actionName) {
  const hashFunc = getHashFunc(actionName);
  return (state, action) => {
    const key = hashFunc(action.payload);
    state[key] = initialState;
  };
}

export function createClearStateSlice<T>({
  storeName,
  datasetHashKey,
  initialState = {} as T,
}: CreateClearStateSliceOptions<T>) {
  const name = getSliceNamePrefix(storeName, datasetHashKey);
  const actionName = `${name}_${CLEAR}`;
  const actions = createAction(actionName, (payload, meta) => ({
    payload,
    meta,
  }));
  const reducer = createReducer(initialState, (builder) => {
    builder.addCase(
      actionName,
      clearStateReducer(initialState, datasetHashKey)
    );
  });

  return {
    actions,
    name,
    reducer,
    dispatchers: (dispatch: Dispatch) => (params, meta) =>
      dispatch(actions(params, meta)),
  };
}
