import { baseTheme } from '../baseTheme';

const { spacing, palette } = baseTheme;

export default {
  defaultProps: {
    arrow: true,
  },
  styleOverrides: {
    tooltip: {
      backgroundColor: palette.white,
      border: `${spacing(0.1)} solid`,
      borderColor: palette.neutral.main,
      color: palette.black,
    },
    arrow: {
      '::before': {
        border: `${spacing(0.1)} solid`,
        backgroundColor: palette.white,
        borderColor: palette.neutral.main,
      },
    },
  },
};
