import { getSiteClusterOutput } from '../SolutionSites/utils';
import { SolutionEdgeWorkloadAuthType } from '../types';

export const getEdgeWorkloadEditParams = (
  params: any,
  gitFlag?: string | undefined
) => {
  if (params.gitAuthentication !== SolutionEdgeWorkloadAuthType.HTTPBased) {
    const updatedParams = params;
    delete updatedParams.gitUsername;
    delete updatedParams.gitPassword;
    return {
      WorkloadConfiguration: { ...updatedParams },
    };
  }

  return {
    WorkloadConfiguration: { ...params },
  };
};

export const getSolutionWorkloadEditParams = (
  params: any,
  gitTargetNamespace?: string,
  optionsData?: any
) => {
  const siteIdList = params.siteClusters;
  delete params.siteClusters;
  return {
    ...params,
    targetNamespace: gitTargetNamespace,
    ...getSiteClusterOutput(optionsData, siteIdList),
  };
};
