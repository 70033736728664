import React, { DependencyList, ReactNode } from 'react';
import Box from '@mui/material/Box';
import { useScrollObserver } from 'src/hooks';

type Props = {
  children: ReactNode;
  deps: DependencyList;
  onScrollToBottom: () => void;
};

function SolutionDevicesListObserver({
  children,
  deps,
  onScrollToBottom,
}: Props) {
  const scrollElementRef = useScrollObserver(onScrollToBottom, deps);

  return <Box ref={scrollElementRef}>{children}</Box>;
}

export default SolutionDevicesListObserver;
