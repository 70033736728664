import React, { ChangeEvent, ForwardedRef, forwardRef, ReactNode } from 'react';
import isArray from 'lodash/isArray';
import Box from '@mui/material/Box';
import { SystemProps } from '@mui/system/Box';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import { SxProps } from 'src/theme/types';
import { updateEventTargetValue } from 'src/utils';
import SelectOption, { Option, OptionValue } from 'src/components/SelectOption';
import { PageCardLoader } from 'src/components/PageCardLayout';
import AutoCompleteBoxDisplayValue from './components/AutoCompleteBoxDisplayValue';
import { isSelected, getUpdatedSelection } from './utils';
import { useFiltering } from './hooks';

type Props<T> = FormControlProps & {
  value?: T | T[];
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: ReactNode;
  helperText?: ReactNode;
  options?: Option<T>[];
  loading?: boolean;
  multiple?: boolean;
};

const getContainerStyle = (error?: boolean): SxProps => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  p: 1.3,
  mr: 2,
  border: 1,
  borderColor: error ? 'error.main' : 'neutral.dark',
  borderRadius: ({ spacing }) => spacing(0.4),
});

const inputStyle: SxProps = {
  pb: 1,
  '&:before': {
    borderColor: 'primary.main',
  },
  '&:hover:not(.Mui-disabled):before': {
    borderColor: 'primary.main',
  },
};

const labelStyle = {
  backgroundColor: 'white',
  px: 0.5,
};

const optionsWrapperStyle: SystemProps = {
  minHeight: '50%',
  mt: 1.5,
  overflow: 'auto',
  position: 'relative',
};

function AutoCompleteBox<T extends OptionValue = OptionValue>(
  {
    id,
    options = [],
    loading,
    label,
    placeholder = 'Search here...',
    multiple,
    value,
    onChange,
    error,
    helperText,
    ...rest
  }: Props<T>,
  ref: ForwardedRef<HTMLInputElement>
) {
  const { searchValue, filteredOptions, onFilter, onResetFilter } =
    useFiltering<T>(options, value, multiple);
  const isMultiple = multiple && isArray(value);
  const noOptions = !loading && !filteredOptions.length;

  const handleSelect = (event, newValue) => {
    if (!multiple) {
      onResetFilter(newValue);
    }
    if (onChange) {
      newValue = getUpdatedSelection(value, newValue);
      onChange(updateEventTargetValue(event, id, newValue));
    }
  };

  const handleInputChange = ({ target }) => {
    onFilter(target.value);
  };

  return (
    <FormControl data-testid="autocomplete-box" error={error} {...rest}>
      {label && (
        <InputLabel sx={labelStyle} htmlFor={id} shrink>
          {label}
        </InputLabel>
      )}
      <Box
        data-testid="autocomplete-box-container"
        sx={getContainerStyle(error)}
      >
        {isMultiple && (
          <AutoCompleteBoxDisplayValue
            options={options}
            value={value}
            onDelete={handleSelect}
          />
        )}
        <Input
          data-testid="autocomplete-box-input"
          ref={ref}
          sx={inputStyle}
          id={id}
          placeholder={placeholder}
          value={searchValue}
          onChange={handleInputChange}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon color="primary" fontSize="small" />
            </InputAdornment>
          }
          onKeyDown={(event) => {
            if (event.code === 'Enter') {
              event.preventDefault();
            }
          }}
        />
        <Box data-testid="autocomplete-box-options" {...optionsWrapperStyle}>
          {loading && <PageCardLoader />}
          {noOptions && (
            <MenuItem data-testid="autocomplete-box-no-options" disabled>
              No available options
            </MenuItem>
          )}
          {filteredOptions.map((option) => (
            <SelectOption<T>
              component="div"
              key={option.value}
              id={id}
              multiple={multiple}
              selected={isSelected<T>(option.value, value)}
              onClick={handleSelect}
              {...option}
            />
          ))}
        </Box>
      </Box>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}

export default forwardRef(AutoCompleteBox);
