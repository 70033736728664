import { generatePath } from 'react-router-dom';
import { createDatasetSlice, ResponseMapping } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import {
  API_GET_FILE_SUBSCRIPTION,
  SOLUTIONS_STORE,
  SOLUTIONS_STORE_FILE_SUBSCRIPTION,
} from '../constants';

const api = (solutionId: string) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: generatePath(API_GET_FILE_SUBSCRIPTION, { solutionId }),
  });

const solutionFileSubscriptionSlice = createDatasetSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTIONS_STORE_FILE_SUBSCRIPTION,
  responseMapping: ResponseMapping.Default,
});

export default solutionFileSubscriptionSlice;
