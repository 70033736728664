import React from 'react';
import { useModal } from 'src/hooks/modalHooks';
import { DataTableActions } from 'src/components/DataTable';
import { SolutionFilesWhitelistItem } from '../../types';
import SolutionFilesWhitelistEditModal from './SolutionFilesWhitelistEditModal';
import SolutionFilesWhitelistDeleteModal from './SolutionFilesWhitelistDeleteModal';
import { useSolutionUserRoles } from '../../hooks';

type Props = {
  item?: SolutionFilesWhitelistItem;
};

function SolutionFilesWhitelistAction({ item }: Props) {
  const { isSolutionEditor } = useSolutionUserRoles();
  const [DeleteDialog, handleDelete] = useModal(
    SolutionFilesWhitelistDeleteModal,
    { item }
  );
  const [EditDialog, handleEdit] = useModal(SolutionFilesWhitelistEditModal, {
    item,
    title: 'Edit file extension',
  });

  return (
    <DataTableActions
      canEdit={isSolutionEditor}
      canDelete={isSolutionEditor}
      onEdit={handleEdit}
      onDelete={handleDelete}
    >
      {EditDialog}
      {DeleteDialog}
    </DataTableActions>
  );
}

export default SolutionFilesWhitelistAction;
