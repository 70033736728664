import React, { useState } from 'react';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FormControl from '@mui/material/FormControl';
import { useUserRoles } from 'src/auth';

type Props = {
  onDeleteClick: () => void;
  onDisableClick: any;
  onEnableClick: any;
  canEnable?: boolean;
  canDisable?: boolean;
};

function MoreActionsMenu({
  onDeleteClick,
  onDisableClick,
  onEnableClick,
  canEnable = true,
  canDisable = true,
}: Props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);
  const { isPlatformReader } = useUserRoles();
  const options = [
    {
      id: 'delete',
      label: 'Delete',
      disabled: isPlatformReader,
      onClick: () => onDeleteClick(),
    },
    {
      id: 'disable',
      label: 'Disable',
      disabled: canDisable || isPlatformReader,
      onClick: onDisableClick,
    },
    {
      id: 'enable',
      label: 'Enable',
      disabled: canEnable || isPlatformReader,
      onClick: onEnableClick,
    },
  ];

  const handleOpen = (event?: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (callback: () => void) => {
    handleClose();
    callback();
  };

  return (
    <FormControl>
      <Button
        sx={{
          padding: '3px 7px',
          fontSize: '14px',
          margin: '9px',
          minHeight: '3.7rem',
          top: '-9px',
        }}
        data-testid="more-actions-menu-btn"
        id="more-actions-menu-btn"
        aria-controls="more-actions-menu"
        aria-haspopup="true"
        variant="outlined"
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={handleOpen}
        endIcon={isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      >
        {'More Actions'}
      </Button>
      <Menu
        id="more-actions-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'more-actions-menu-btn',
        }}
      >
        {options.map(({ id, label, onClick, disabled }) => (
          <MenuItem
            sx={{
              px: ({ spacing }) => spacing(0.5),
              padding: '7px 65px 7px 10px',
            }}
            data-testid={`more-actions-menu-link-${id}`}
            key={id}
            disabled={disabled || false}
            onClick={() => handleMenuItemClick(onClick)}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </FormControl>
  );
}

export default MoreActionsMenu;
