import { MENDER_DOMAIN_URL } from 'src/constants';
import { SolutionLogLevel, SolutionMenderStatus } from '../types';

export const MENDER_DEVICES_URL = `${MENDER_DOMAIN_URL}`;
export const IOT_CENTRAL_DEVICE_URL = `:iotCentralUrl/devices/details/:deviceId`;

export const SOLUTION_DEVICE_AUTHORISE_OPTIONS = [
  {
    value: SolutionMenderStatus.Accepted,
    label: 'Accept',
  },
  {
    value: SolutionMenderStatus.Rejected,
    label: 'Reject',
  },
];

export const SOLUTION_LOG_LEVEL_OPTIONS = [
  {
    value: SolutionLogLevel.Emergency,
    label: '0 - Emergency',
  },
  {
    value: SolutionLogLevel.Alert,
    label: '1 - Alert',
  },
  {
    value: SolutionLogLevel.Critical,
    label: '2 - Critical',
  },
  {
    value: SolutionLogLevel.Error,
    label: '3 - Error',
  },
  {
    value: SolutionLogLevel.Warning,
    label: '4 - Warning',
  },
  {
    value: SolutionLogLevel.Notice,
    label: '5 - Notice',
  },
  {
    value: SolutionLogLevel.Informational,
    label: '6 - Informational',
  },
  {
    value: SolutionLogLevel.Debug,
    label: '7 - Debug',
  },
];
