import React from 'react';
import { useUserRoles } from 'src/auth';
import { DataTableActionsButton } from 'src/components/DataTable';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { SolutionDeviceCertAuditLogsItem } from '../../types';

type Props = {
  item?: SolutionDeviceCertAuditLogsItem;
};

function SolutionDeviceCertDetailsActions({ item }: Props) {
  const { isPlatformReader } = useUserRoles();
  return (
    <>
      <DataTableActionsButton
        data-testid="solution-devices-item-details-action"
        icon={ForwardToInboxIcon}
        title="Mail"
        disabled={isPlatformReader}
      />
      {}
    </>
  );
}

export default SolutionDeviceCertDetailsActions;
