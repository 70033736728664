import React from 'react';
import { useParams } from 'react-router-dom';
import Badge from 'src/components/Badge';
import { Typography } from '@mui/material';
import { saveDataToStorage } from 'src/utils';
import { useStoreUnitActions } from 'src/redux/helpers';
import { useModal } from 'src/hooks';
import { isModalComplete } from 'src/components/ModalDialog';
import {
  DeploymentStatus,
  SolutionEdgeWorkloadItem,
  SolutionEdgeWorkloadRepositoryType,
  SolutionEdgeWorkloadStatus as Status,
} from 'src/pages/Solutions/types/solutionEdgeWorkload';
import SolutionWorkloadStatusViewModal from './SiteWorkloadStatusViewModal';
import solutionsStore from '../../redux';

type StatusMapItem = {
  color?: 'success' | 'info' | 'error' | 'default' | 'warning';
  label: string;
};

type Props = {
  value?: string;
  item?: SolutionEdgeWorkloadItem;
};

const buttonStyle = {
  textDecoration: 'underline',
  color: '#0047BA',
  cursor: 'pointer',
  fontWeight: 500,
  paddingLeft: '3px',
  fontSize: '13px',
};

function SolutionEdgeWorkloadStatus({ value = '', item }: Props) {
  const statusData = JSON.parse(value);
  const statusValue: Status = statusData?.status
    ? statusData.status.toLowerCase()
    : '';
  const errorMsg: string = statusData.errorMsg ?? '';
  const validErrorMsg = errorMsg === null || errorMsg === '';
  const statusColor = () => {
    if (
      !validErrorMsg &&
      statusValue !== Status.notReady &&
      statusValue !== Status.active
    ) {
      return 'error';
    }
    if (validErrorMsg && statusValue === Status.paused) {
      return 'info';
    }
    if (statusValue === Status.active) {
      return 'success';
    }
    if (statusValue === Status.notReady) {
      return 'warning';
    }
    return 'default';
  };

  const statusMap: Record<Status, StatusMapItem> = {
    [Status.paused]: { color: statusColor(), label: 'Paused' },
    [Status.active]: { color: statusColor(), label: 'Active' },
    [Status.errApplied]: { color: statusColor(), label: 'Err Applied' },
    [Status.failed]: { color: statusColor(), label: 'Failed' },
    [Status.gitUpdating]: { color: statusColor(), label: 'Git Updating' },
    [Status.notReady]: { color: statusColor(), label: 'Not Ready' },
    [Status.modified]: { color: statusColor(), label: 'Modified' },
    [Status.waitapplied]: { color: statusColor(), label: 'Wait Applied' },
    [Status.outofsync]: { color: statusColor(), label: 'Out of sync' },
  };
  const defaultStatus = { color: statusColor(), label: statusData.status };

  const { solutionId } = useParams();
  const actions = useStoreUnitActions(solutionsStore);

  const onClose = (reason) =>
    isModalComplete(reason) && actions.subscriptions(solutionId);

  const handleClick = (event: any) => {
    event.stopPropagation();
    saveDataToStorage('DeploymentStatus', DeploymentStatus.Conditions);
    showWorkloadStatusDialog();
  };

  const [workloadStatusDialog, showWorkloadStatusDialog] = useModal(
    SolutionWorkloadStatusViewModal,
    { item, onClose }
  );

  return (
    <>
      <Badge {...(statusMap[statusValue] ?? defaultStatus)} />
      <Typography
        sx={{
          display: '-webkit-box',
          color: '#DB1E36',
          fontSize: '13px',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          textOverflow: 'ellipsis',
          wordBreak: 'break-all',
          overflow: 'hidden',
          whiteSpace: 'normal',
        }}
      >
        {errorMsg}
      </Typography>
      {item?.gitFlag === SolutionEdgeWorkloadRepositoryType.SolutionEdge &&
        (statusValue === Status.notReady || errorMsg) && (
          <Typography
            variant="small2"
            onClick={handleClick}
            style={buttonStyle}
          >
            Click here for more
          </Typography>
        )}
      {workloadStatusDialog}
    </>
  );
}

export default SolutionEdgeWorkloadStatus;
