import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAsyncOktaAuthCheck } from 'src/auth';
import { RouteProps } from '../types';
import { useAllowedFeaturesCheck, useAllowedUserRolesCheck } from '../hooks';

type Props = Omit<RouteProps, 'path'> & {
  fallbackUrl: string;
};

function SecureRoute({
  component: Component,
  allowedUserRoles,
  allowedFeatures,
  fallbackUrl,
  isPublic,
  ...rest
}: Props) {
  const isAuthenticated = useAsyncOktaAuthCheck();
  const isUserRoleAllowed = useAllowedUserRolesCheck(allowedUserRoles);
  const isFeatureAllowed = useAllowedFeaturesCheck(allowedFeatures);

  if (!isPublic && !isAuthenticated) {
    return null;
  }
  if (!isUserRoleAllowed || !isFeatureAllowed) {
    return <Navigate to={fallbackUrl} />;
  }
  return <Component {...rest} />;
}

export default SecureRoute;
