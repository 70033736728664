import { createDatasetSlice, ResponseMapping } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import { generateApiPath } from 'src/utils';
import {
  API_DEVICES_GET_PROPERTIES_VALUES,
  SOLUTIONS_STORE,
  SOLUTIONS_STORE_DEVICE_GET_PROPERTIES,
} from '../constants';

const api = ({ solutionId, deviceId }) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: generateApiPath(API_DEVICES_GET_PROPERTIES_VALUES, {
      solutionId,
      deviceId,
    }),
  });

const solutionDeviceCommandPropertiesSlice = createDatasetSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTIONS_STORE_DEVICE_GET_PROPERTIES,
  responseMapping: ResponseMapping.Default,
});

export default solutionDeviceCommandPropertiesSlice;
