import { useNavigate } from 'react-router-dom';
import PageCardLayout from 'src/components/PageCardLayout';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import SiteEditForm from './SiteEditForm';
import { API_SITES_ALL as API_SITE_CREATE } from '../../constants';

function SiteNew() {
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const goBack = () => navigate(-1);

  const [handleSubmit, loading] = useStatefulApi(
    (params) =>
      pepApiRequest({
        method: RequestMethod.Post,
        url: API_SITE_CREATE,
        params,
      }),

    () => {
      showAlert({
        type: AlertType.Success,
        title: 'Site created.',
        text: 'New site has been created Successfully.',
      });
      goBack();
    }
  );

  return (
    <PageCardLayout loading={loading} title="Create new site">
      <SiteEditForm onCancel={goBack} onSubmit={handleSubmit} />
    </PageCardLayout>
  );
}

export default SiteNew;
