export type SolutionDigitalTwinModelInfo = {
  displayName: string;
  modelId: string;
};

export type SolutionIoTCentralInstance = {
  url: string;
};

export type SolutionDigitalTwinModel = {
  modelDefinitionInfo: SolutionDigitalTwinModelInfo;
  ioTCentralTemplateInfo?: SolutionDigitalTwinModelInfo;
  isEdgeDevice?: boolean;
};

export enum SolutionDeviceConfigItemField {
  DisplayName = 'displayName',
  ModelId = 'modelId',
  ModelType = 'modelType',
}

export enum SolutionDeviceConfigModelType {
  DeviceTemplate = 'deviceTemplate',
  ContextModel = 'contextModel',
  EdgeDeviceTemplate = 'edgeDeviceTemplate',
}

export type SolutionDeviceConfigItem = SolutionDigitalTwinModelInfo & {
  id: string;
  modelType: SolutionDeviceConfigModelType;
};

export type SolutionFilesWhitelistItem = {
  id?: number;
  extension: string;
  maxFileSize: number;
};

export enum SolutionFilesWhitelistField {
  Extension = 'extension',
  MaxFileSize = 'maxFileSize',
}

export enum AllowedFilesWhitelistExtension {
  Txt = 'txt',
  Jpg = 'jpg',
  Jpeg = 'jpeg',
  Png = 'png',
}

export type SolutionSignatureWhitelistItem = {
  id?: number;
  signature: string;
  description?: string;
};

export enum SolutionSignatureWhitelistField {
  Signature = 'signature',
  Description = 'description',
}
