import DataTable, {
  DataTableWrapper,
  getActionsColConfig,
  useTableQueryParams,
} from 'src/components/DataTable';
import NameSpacesListAction from './NameSpacesListAction';
import { SiteDeviceProjectItem, SiteDeviceProjectItemField } from '../../types';

const nameSpacesColumn = [
  {
    id: SiteDeviceProjectItemField.Namespace,
    title: 'Name',
  },
  getActionsColConfig(NameSpacesListAction),
];

type Props = {
  data: SiteDeviceProjectItem[];
};

function NameSpacesList({ data }: Props) {
  return (
    <DataTableWrapper
      sx={{ marginTop: '2%' }}
      title="Namespaces"
      titleStyle={{ fontWeight: '700', fontSize: '16px', fontFamily: 'Roboto' }}
      searchBar={false}
    >
      <DataTable columns={nameSpacesColumn} data={data} />
    </DataTableWrapper>
  );
}

export default NameSpacesList;
