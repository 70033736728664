import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { EditOutlined } from '@mui/icons-material';
import {
  DataTableActionsButton,
  DataTableActionsWrapper,
} from 'src/components/DataTable';
import { SolutionAlertRuleItem } from '../../types';

type Props = {
  item?: SolutionAlertRuleItem;
};

function SolutionAlertRulesListAction({ item }: Props) {
  // implement edit and delete aler rule here

  return (
    <DataTableActionsWrapper>
      <DataTableActionsButton
        data-testid="solution-alert-rule-item-delete-action"
        icon={DeleteOutlinedIcon}
        title="Delete"
        disabled={true}
      />
      <DataTableActionsButton
        data-testid="solution-alert-rule-item-edit-action"
        icon={EditOutlined}
        title="Edit"
        disabled={true}
      />
    </DataTableActionsWrapper>
  );
}

export default SolutionAlertRulesListAction;
