import { createDatasetSlice } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import { generateApiPath } from 'src/utils';
import {
  API_SOLUTION_TELEMETRY,
  SOLUTIONS_RULE_TELEMETRY_PROPERTIES,
  SOLUTIONS_STORE,
} from '../constants';
import { mapSolutionDeviceGroupProperties } from './utils';

const api = ({ solutionId, modelId }) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: generateApiPath(API_SOLUTION_TELEMETRY, { solutionId, modelId }),
  }).then(mapSolutionDeviceGroupProperties);

const solutionTelemetrySlice = createDatasetSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTIONS_RULE_TELEMETRY_PROPERTIES,
});

export default solutionTelemetrySlice;
