import { useParams } from 'react-router-dom';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import ModalDialog, {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { useForm } from 'src/components/Form';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { useStoreUnitActions } from 'src/redux/helpers';
import { generateApiPath } from 'src/utils';
import {
  API_DEVICES_AUTHORISE,
  API_DEVICES_AUTHORISE_IOTH,
} from '../../constants';
import solutionsStore from '../../redux';
import { SolutionDeviceItem, SolutionType } from '../../types';
import { solutionDevicesAuthoriseField } from './formFields';

type Props = {
  item: SolutionDeviceItem;
  selectedItems: SolutionDeviceItem[];
  open: boolean;
  onClose: ModalCloseHandler;
};
export default function SolutionDevicesAuthoriseModal({
  item,
  open,
  selectedItems,
  onClose,
}: Props) {
  const { showAlert } = useAlert();
  const { solutionId, solutionType } = useParams();
  const { devices } = useStoreUnitActions(solutionsStore);
  const itemIds = selectedItems.map((x) => x.id);
  const [onSubmit, loading] = useStatefulApi(
    (params) =>
      pepApiRequest({
        method: RequestMethod.Put,
        url: generateApiPath(
          solutionType?.toUpperCase() === SolutionType.ioTH.toUpperCase()
            ? API_DEVICES_AUTHORISE_IOTH
            : API_DEVICES_AUTHORISE,
          {
            solutionId,
          }
        ),
        params: { deviceIds: itemIds, ...params },
      }),
    () => {
      showAlert({
        type: AlertType.Info,
        title: 'Device/Devices authorised',
        text: `Device/Devices has been authorised successfully.`,
      });
      devices.init({ solutionId });
      onClose(null, ModalCloseReason.completeAction);
    }
  );

  const [form, handleSubmit] = useForm({
    fields: solutionDevicesAuthoriseField,
    values: item,
    onSubmit,
  });

  return (
    <ModalDialog
      open={open}
      title="Authorisation request"
      onClose={onClose}
      confirmBtnText="Submit"
      cancelBtnText="Cancel"
      onConfirm={handleSubmit}
      loading={loading}
      fullWidth
    >
      {form}
    </ModalDialog>
  );
}
