import * as Yup from 'yup';
import TextFieldWithInfo from 'src/components/TextField';
import Select from 'src/components/Select';
import { SolutionModuleLogs } from '../types';
import { SOLUTION_LOG_LEVEL_OPTIONS } from '../SolutionDevices/constants';

export const getSolutionModuleLogFields = [
  {
    name: SolutionModuleLogs.Tail,
    label: 'Tail',
    Component: TextFieldWithInfo,
    placeholder: 'Enter the number',
    showinfoicon: true,
    infotitle:
      'Number of log lines in the past to retrieve starting from the latest.',
    required: true,
    validation: Yup.string().required('Tail is required'),
    sx: { width: ({ spacing }) => spacing(35), paddingRight: '1rem' },
  },
  {
    name: SolutionModuleLogs.Since,
    label: 'Since',
    Component: TextFieldWithInfo,
    showinfoicon: true,
    infotitle:
      'Only return logs since this time, as an rfc3339 timestamp, UNIX timestamp, or a duration (days (d) hours (h) minutes (m)). For example, a duration for one day, 12 hours, and 30 minutes can be specified as 1 day 12 hours 30 minutes or 1d 12h 30m.',
    placeholder: '1 day 12 hours 30 minutes or 1d 12h 30m',
    sx: { width: ({ spacing }) => spacing(35), paddingRight: '1rem' },
  },
  {
    name: SolutionModuleLogs.Until,
    label: 'Until',
    Component: TextFieldWithInfo,
    showinfoicon: true,
    infotitle:
      'Only return logs before the specified time, as an rfc3339 timestamp, UNIX timestamp, or duration (days (d) hours (h) minutes (m)). For example, a duration 90 minutes can be specified as 90 minutes or 90m.',
    placeholder: '1 day 12 hours 30 minutes or 1d 12h 30m',
    sx: { width: ({ spacing }) => spacing(35), paddingRight: '1rem' },
  },
  {
    name: SolutionModuleLogs.LogLevel,
    label: 'Log Level',
    Component: Select,
    options: SOLUTION_LOG_LEVEL_OPTIONS,
    showInfoIcon: true,
    infoTitle: 'Filter log lines equal to specified log level.',
    placeholder: 'Enter the value',
    sx: { width: ({ spacing }) => spacing(37) },
  },
  {
    name: SolutionModuleLogs.RegexCondition,
    label: 'Regex condition',
    Component: TextFieldWithInfo,
    showinfoicon: true,
    infotitle:
      'Filter log lines that have content that match the specified regular expression using .NET Regular Expressions format.',
    placeholder: 'Enter the Regex expression',
    sx: { width: ({ spacing }) => spacing(35), paddingRight: '1rem' },
  },
];
