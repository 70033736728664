import { useParams } from 'react-router-dom';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import ModalDialog, {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { generateApiPath } from 'src/utils';
import { useStoreUnitActions } from 'src/redux/helpers';
import { API_Delete_SOURCE_IMAGE_REPO } from '../../constants';
import { SolutionSourceImageRepositoryItem } from '../../types';
import solutionsStore from '../../redux';

type Props = {
  id: string;
  name: string;
  item: SolutionSourceImageRepositoryItem;
  open: boolean;
  onClose: ModalCloseHandler;
};

function SolutionImageRepoDeleteModal({ id, name, open, onClose }: Props) {
  const { solutionId } = useParams();
  const { showAlert } = useAlert();
  const actions = useStoreUnitActions(solutionsStore);
  const [handleRemove, loading] = useStatefulApi(
    () =>
      pepApiRequest({
        method: RequestMethod.Delete,
        url: generateApiPath(API_Delete_SOURCE_IMAGE_REPO, {
          solutionId,
        }),
        params: { solutionId: [id] },
      }),
    () => {
      showAlert({
        type: AlertType.Info,
        title: `Source image repository is deleted`,
        text: 'Source image details are deleted successfully',
      });
      onClose(null, ModalCloseReason.completeAction);
      actions.details(solutionId);
    }
  );

  return (
    <ModalDialog
      open={open}
      title="Delete Source Image Repository"
      loading={loading}
      onClose={onClose}
      onConfirm={handleRemove}
      cancelBtnText="No"
      confirmBtnText="Yes"
    >
      Are you sure you want to delete this image repository?
      <br />
      <b> {name} </b>
    </ModalDialog>
  );
}

export default SolutionImageRepoDeleteModal;
