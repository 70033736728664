import { Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import ModalDialog, { ModalCloseHandler } from 'src/components/ModalDialog';
import DataTable, { DataTableWrapper } from 'src/components/DataTable';
import { useStoreUnitActions } from 'src/redux/helpers';
import solutionsStore, {
  useSolutionDeviceGroupProperties,
  useSolutionRuleTelemtryProperties,
  useSolutionSpecificRulesManagement,
} from '../redux';
import {
  SolutionDeviceSpecificRulesManagement,
  SolutionSpecificRuleManagementField,
} from '../types';
import { Condition, Filter } from './interfaces';
import SolutionRuleOperator from './SolutionRuleOperator';
import { camelizeKeys } from './utils';

type Props = {
  item: SolutionDeviceSpecificRulesManagement;
  open: boolean;
  onClose: ModalCloseHandler;
};

export default function SolutionSpecificRuleViewModal({
  item,
  open,
  onClose,
}: Props) {
  let conditonTableData = [];
  let filterTableData = [];

  const actions = useStoreUnitActions(solutionsStore);
  const [properties, propertiesDataLoading] =
    useSolutionDeviceGroupProperties();
  const fetchConditonFilter = (
    item: SolutionDeviceSpecificRulesManagement
  ): any => {
    const conditionArray: Condition[] = [];
    const ConditionInfo = camelizeKeys(item.ConditionQueryInfo);
    ConditionInfo.forEach((cond) => {
      const data: Condition = {
        Telemetry: cond.property.displayName,
        Operator: cond.operatorValue,
        Value: cond.filterValue,
        Aggregation: cond.property?.aggregationFunctions?.find(
          (a) => a.value === cond?.aggregationValue
        )?.description
          ? cond.property.aggregationFunctions.find(
              (a) => a.value === cond.aggregationValue
            ).description
          : cond.aggregationValue,
        ValueLabel: cond.filterValue,
      };
      data.Telemetry = cond.property.displayName;
      data.Value = cond.filterValue;
      data.Operator = cond.operatorValue;
      data.Aggregation = cond.property?.aggregationFunctions?.find(
        (a) => a.value === cond?.aggregationValue
      )?.description
        ? cond.property.aggregationFunctions.find(
            (a) => a.value === cond.aggregationValue
          ).description
        : cond.aggregationValue;
      data.Aggregation = data.Aggregation ? data.Aggregation : '-';
      conditionArray.push(data);
    });
    return conditionArray;
  };

  const fetchTargetFilter = (
    item: SolutionDeviceSpecificRulesManagement
  ): any => {
    const filterArray: Filter[] = [];
    const targetInfo = camelizeKeys(item.TargetDeviceInfo);
    targetInfo.forEach((filter) => {
      const data: Filter = {
        Property: filter.property.displayName,
        Condition: filter.operatorValue,
        Value:
          properties?.find((a) => a.displayName === filter.property.displayName)
            ?.schema === 'Enum'
            ? properties
                ?.find((a) => a.displayName === filter.property.displayName)
                ?.enums?.find((a) => a.value === Number(filter.filterValue))
                ?.label
            : filter.filterValue,
        ValueLabel: filter.filterValue,
      };
      data.Property = filter.property.displayName;
      data.Value =
        properties?.find((a) => a.displayName === filter.property.displayName)
          ?.schema === 'Enum'
          ? properties
              ?.find((a) => a.displayName === filter.property.displayName)
              ?.enums?.find((a) => a.value === Number(filter.filterValue))
              ?.label
          : filter.filterValue;
      data.Condition = filter.operatorValue;
      filterArray.push(data);
    });
    return filterArray;
  };

  useEffect(() => {
    if (item?.SolutionId && item?.TemplateId) {
      actions.deviceGroupProperties({
        solutionId: item.SolutionId,
        modelId: item.TemplateId,
      });
    }
  }, [item]);

  conditonTableData = fetchConditonFilter(item);
  filterTableData = fetchTargetFilter(item);

  const [, loading] = useSolutionSpecificRulesManagement();

  const tableStyle = {
    minHeight: 10,
  };

  const conditionColumns = [
    {
      id: SolutionSpecificRuleManagementField.TelemetryName,
      title: 'Telemetry',
      sx: { minWidth: 100 },
    },
    {
      id: SolutionSpecificRuleManagementField.OperatorName,
      title: 'Operator',
      Component: SolutionRuleOperator,
    },
    {
      id: SolutionSpecificRuleManagementField.AggregationName,
      title: 'Aggregation',
    },
    {
      id: SolutionSpecificRuleManagementField.ConditionValue,
      title: 'Value',
    },
  ];

  const actionsColumns = [
    {
      id: SolutionSpecificRuleManagementField.PropertyName,
      title: 'Property',
      sx: { minWidth: 100 },
    },
    {
      id: SolutionSpecificRuleManagementField.FilterOperatorName,
      title: 'Operator',
      Component: SolutionRuleOperator,
    },
    {
      id: SolutionSpecificRuleManagementField.ValueName,
      title: 'Value',
    },
  ];

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      onConfirm={onClose}
      loading={loading}
      showCancelBtn={false}
      title={`View Rule - ${item.Name}`}
      titleStyle={{ fontStyle: 'Stolzl', fontWeight: '500' }}
      confirmBtnText="Close"
      maxWidth="md"
      fullWidth
    >
      <Grid container mb={2}>
        <Grid item xs={3} sx={{ fontWeight: 500 }}>
          Name
        </Grid>
        <Grid item sx={{ fontWeight: 400 }}>
          {item.Name}
        </Grid>
      </Grid>
      <Grid container mb={2}>
        <Grid item xs={3} sx={{ fontWeight: 500 }}>
          Enabled
        </Grid>
        <Grid item sx={{ fontWeight: 400 }}>
          {item.Enabled.toString()}
        </Grid>
      </Grid>
      <Typography mt={3} mb={2} sx={{ fontWeight: 500, fontFamily: 'Stolzl' }}>
        Target devices
      </Typography>
      <Grid container mb={2}>
        <Grid item xs={3} sx={{ fontWeight: 500 }}>
          Template
        </Grid>
        <Grid item sx={{ fontWeight: 400 }}>
          {item.TemplateId || '--'}
        </Grid>
      </Grid>
      <Grid item xs={3} mb={2} sx={{ fontWeight: 500 }}>
        Filters
      </Grid>
      <DataTableWrapper searchBar={false}>
        <DataTable
          data={filterTableData}
          columns={actionsColumns}
          sx={tableStyle}
        />
      </DataTableWrapper>
      <Typography
        mt={3}
        mb={2}
        sx={{ fontWeight: 500, marginTop: '3rem', fontFamily: 'Stolzl' }}
      >
        Conditions
      </Typography>
      <Grid container mb={2}>
        <Grid item xs={3} sx={{ fontWeight: 500 }}>
          Time aggregation
        </Grid>
        <Grid item>
          {item.TimeAggregation.toString()
            ? item.TimeAggregation.toString()
            : '-'}
        </Grid>
      </Grid>
      <Grid container mb={2}>
        <Grid item xs={3} sx={{ fontWeight: 500 }}>
          Time window
        </Grid>
        <Grid item sx={{ fontWeight: 400 }}>
          {item.TimeAggregationValueInMinutes === 0
            ? '-'
            : `${item.TimeAggregationValueInMinutes}mins`}
        </Grid>
      </Grid>
      <DataTableWrapper searchBar={false}>
        <DataTable
          data={conditonTableData}
          columns={conditionColumns}
          sx={tableStyle}
        />
      </DataTableWrapper>
      <Typography
        mt={3}
        mb={2}
        sx={{ fontWeight: 500, marginTop: '3rem', fontFamily: 'Stolzl' }}
      >
        Actions
      </Typography>
      <Grid container mt={2}>
        <Grid item xs={3} sx={{ fontWeight: 500 }}>
          Types
        </Grid>
        <Grid item sx={{ fontWeight: 400 }}>
          {item.ActionType.toString() ? item.ActionType.toString() : '-'}
        </Grid>
      </Grid>
      <Grid container mt={2}>
        <Grid item xs={3} sx={{ fontWeight: 500 }}>
          Target URL
        </Grid>
        <Grid item sx={{ fontWeight: 400 }}>
          {item.TargetUrl ? item.TargetUrl : '-'}
        </Grid>
      </Grid>
    </ModalDialog>
  );
}
