import React, { useEffect, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { SxProps } from 'src/theme/types';
import ModalDialog, { ModalCloseHandler } from 'src/components/ModalDialog';
import { useForm } from 'src/components/Form';
import { useStoreUnitActions } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import solutionsStore from '../redux';
import { API_SOLUTIONS_IOTHUB_COMPONENT_COMMAND } from '../constants';
import { getCommandTempFormFields } from './formFields';

type Props = {
  retData: any;
  open: boolean;
  onClose: ModalCloseHandler;
};

const modalStyle: SxProps = {
  '.MuiDialogContent-root': {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
};

const formStyle: SxProps = {
  gap: 0.5,
  '.MuiFormControlLabel-root': {
    alignSelf: 'flex-end',
    color: 'neutral.dark',
    typography: 'small1',
    marginTop: '20px',
  },
};

function SolutionCommandAssignTemplateModal({ retData, open, onClose }: Props) {
  const { solutionName } = useParams();
  const { devices, devicesPersist } = useStoreUnitActions(solutionsStore);
  const { solutionId } = useParams();
  const { showAlert } = useAlert();
  const [rowDetails, setRowDetails] = useState<any>([]);
  const [objDetails] = useState<any>([]);
  const [propertyValue, setPropertyValue] = useState();
  let objProp = {};
  const handlePropertyVal = (event) => {
    setPropertyValue(event.target.value);
  };
  const handleObjPropertyVal = (event) => {
    objProp = {
      propertyName: event.target.name,
      propertyValue: event.target.value,
    };
    if (objDetails.length !== 0) {
      for (let i = 0; i < objDetails.length; i += 1) {
        if (objDetails[i]?.propertyName === event.target.name) {
          objDetails[i].propertyValue = event.target.value;
        } else {
          objDetails.push(objProp);
        }
      }
    } else {
      objDetails.push(objProp);
    }
  };
  const getPayloadParams = (params) => ({
    componentName:
      params?.component === 'DefaultComponent' ? '' : params.component,
    moduleName: params?.moduleName,
    commandName: params?.name,
    commandProperties: getCommandProperties(params),
  });
  const getCommandProperties = (params) => {
    if (
      typeof params?.requestSchemaType === 'string' &&
      params?.requestSchemaType !== '-'
    ) {
      return [
        {
          propertyName: params?.request?.name
            ? params?.request?.name
            : params.requestSchema,
          propertyValue,
        },
      ];
    }
    if (typeof params?.requestSchemaType === 'object') {
      const payload = objDetails.filter(
        (obj, index) =>
          index ===
          objDetails.findIndex(
            (newObj) =>
              obj.propertyName === newObj.propertyName &&
              obj.propertyValue === newObj.propertyValue
          )
      );
      return payload;
    }
    return [{}];
  };
  const [handleSubmit, saving] = useStatefulApi(
    ({ modelId }) =>
      pepApiRequest({
        method: RequestMethod.Post,
        url: generatePath(API_SOLUTIONS_IOTHUB_COMPONENT_COMMAND, {
          solutionId,
          tenantId: 'all',
          deviceId: solutionName,
        }),
        params: getPayloadParams(rowDetails),
      }),
    () => {
      showAlert({
        type: AlertType.Success,
        title: 'Command Executed Successfully.',
        text: 'The command has been executed successfully.',
      });
      devices.init({ solutionId });
      devicesPersist.update({ solutionId, devices: [retData] });
      onClose();
    }
  );

  useEffect(() => {
    setRowDetails(rowDetails);
  }, [rowDetails]);

  const [expanded, setExpanded] = React.useState(null);
  const handleChange = (row, panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : null);
    setRowDetails(row);
  };

  const [FormComponent, onSubmit] = useForm({
    sx: formStyle,
    fields: getCommandTempFormFields(
      rowDetails,
      handlePropertyVal,
      handleObjPropertyVal
    ),
    onSubmit: handleSubmit,
  });

  return (
    <ModalDialog
      open={open}
      title="Commands"
      onClose={onClose}
      cancelBtnText="Close"
      showConfirmBtn={false}
      loading={saving}
      sx={modalStyle}
      fullWidth
      maxWidth="xl"
    >
      {retData.map((row, index) => (
        <div>
          <Accordion
            sx={{
              borderBottomLeftRadius: '5px',
              borderBottomRightRadius: '5px',
              boxShadow: '0 1px 1px 2px rgba(0, 0, 0, 0.1)',
            }}
            onChange={handleChange(row, index)}
            expanded={expanded === index}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon color="primary" />}
              sx={{ padding: '9px', marginTop: '25px' }}
            >
              <Typography variant="h4" fontWeight="500">
                {row.displayName}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{ backgroundColor: 'rgba(165, 165, 165, 0.07)' }}
            >
              <Box
                style={{ display: 'flex' }}
                flexDirection={'row'}
                justifyContent="space-between"
              >
                <Typography fontSize="16px" fontWeight="600">
                  Request schema
                </Typography>
                <Typography variant="body2" sx={{ marginLeft: '20px' }}>
                  {typeof row.requestSchemaType === 'string'
                    ? row.requestSchemaType
                    : 'Object'}
                </Typography>
                <Typography
                  fontSize="16px"
                  fontWeight="600"
                  sx={{ marginLeft: '10px' }}
                >
                  Response schema
                </Typography>
                <Typography variant="body2" sx={{ marginLeft: '20px' }}>
                  {row?.responseSchema
                    ? JSON.stringify(row.responseSchema)
                    : '-'}
                </Typography>
                <Typography
                  variant="body2"
                  fontWeight="600"
                  sx={{ marginLeft: '20px' }}
                >
                  Type
                </Typography>
                <Typography variant="body2" sx={{ marginLeft: '20px' }}>
                  {row?.commandType ? row.commandType : '-'}
                </Typography>
              </Box>
              <Box>
                <Box
                  style={{ display: 'flex', marginTop: '20px' }}
                  flexDirection={'row'}
                  justifyContent="space-between"
                >
                  {FormComponent}
                </Box>
                <Box textAlign="right" mt={3}>
                  <Button
                    type="submit"
                    onClick={onSubmit}
                    variant="contained"
                    sx={{ marginTop: '50px' }}
                  >
                    Send Command
                  </Button>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </div>
      ))}
    </ModalDialog>
  );
}

export default SolutionCommandAssignTemplateModal;
