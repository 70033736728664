import { Add } from '@mui/icons-material';
import { Typography } from '@mui/material';
import DataTable, {
  Column,
  DataTableWrapper,
  DataTableWrapperActionButton,
  useTableQueryParams,
} from 'src/components/DataTable';
import { useModal } from 'src/hooks';
import GuidedTooltip from 'src/components/GuidedTour/guidedTooltip';
import { isModalComplete } from 'src/components/ModalDialog';
import { useUserRoles } from 'src/auth';
import SolutionEdgeWorkloadCreateContainer from './SolutionEdgeWorkloadCreateContainer';
import {
  SolutionEdgeWorkloadItem,
  SolutionEdgeWorkloadRepositoryType,
} from '../../types';

type Props = {
  data: SolutionEdgeWorkloadItem[];
  columns: Column<SolutionEdgeWorkloadItem>[];
  repositoryType: SolutionEdgeWorkloadRepositoryType;
  loading?: boolean;
  title?: string;
  infoTitle?: any;
  className?: any;
  enableSearchBar?: boolean;
};

const SolutionEdgeWorkloadRepositoryList = ({
  data,
  columns,
  repositoryType,
  loading = false,
  title,
  infoTitle,
  className,
  enableSearchBar,
}: Props) => {
  const [CreateEdgeWorkloadDialog, showCreateEdgeWorkloadDialog] = useModal(
    SolutionEdgeWorkloadCreateContainer,
    {
      onClose: (reason) => isModalComplete(reason),
      repositoryType,
    }
  );
  const { isPlatformReader, isPlatformEngineer } = useUserRoles();
  const isValidUser =
    repositoryType === SolutionEdgeWorkloadRepositoryType.SolutionEdge &&
    isPlatformEngineer;
  const { paging, sorting } = useTableQueryParams();

  return (
    <>
      <Typography
        variant="body2md"
        style={{
          fontWeight: 'bold',
          fontSize: '17px',
          marginBottom: '60px',
          paddingRight: '12px',
        }}
      >
        {title}
      </Typography>
      <GuidedTooltip title={infoTitle} />
      <DataTableWrapper
        searchBar={enableSearchBar}
        actionsPanel={
          <DataTableWrapperActionButton
            startIcon={<Add />}
            onClick={showCreateEdgeWorkloadDialog}
            disabled={isPlatformReader || isValidUser}
            className={className}
          >
            Configure New
          </DataTableWrapperActionButton>
        }
      >
        <DataTable
          data={data}
          columns={columns}
          loading={loading}
          {...paging}
          {...sorting}
        />
        {CreateEdgeWorkloadDialog}
      </DataTableWrapper>
    </>
  );
};

export default SolutionEdgeWorkloadRepositoryList;
