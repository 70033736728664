import TextField from '@mui/material/TextField';
import compact from 'lodash/compact';
import MaskedInput from 'src/components/MaskedInput';
import Select from 'src/components/Select';
import * as Yup from 'yup';
import { SolutionAdlsSubscriptionItemField } from '../types';
import { getSubscriptionFilterFields } from './formFields';
import SolutionSubscriptionEditFormRow from './components/SolutionSubscriptionEditFormRow';
import {
  ADLS_FORMAT_OPTIONS,
  SUBSCRIBE_FILTER_CONDITION_OPTIONS,
} from './constants';

export const getAdlsSubscriptionEditFormFields = (
  filters: any[],
  item?: any,
  handlePaste?: any
) =>
  compact([
    {
      name: SolutionAdlsSubscriptionItemField.AdlsSubscriptionName,
      label: 'Subscription Name',
      Component: TextField,
      placeholder: 'Enter subscription name',
      required: true,
      validation: Yup.string().required('Subscription name is required'),
    },
    {
      name: SolutionAdlsSubscriptionItemField.Format,
      label: 'Format',
      Component: Select,
      required: true,
      validation: Yup.string().required(
        'Solution Subscription Format is required'
      ),
      options: ADLS_FORMAT_OPTIONS,
    },
    {
      name: SolutionAdlsSubscriptionItemField.ContainerSaasURL,
      label: 'Container SAS URL',
      Component: MaskedInput,
      placeholder: 'Enter Container SAS URL',
      onClickPasteIcon: handlePaste,
      required: true,
      icon: true,
      validation: Yup.string().required('Container SAS URL is required'),
    },
    {
      name: SolutionAdlsSubscriptionItemField.FolderStructure,
      label: 'Destination Folder Format',
      Component: TextField,
      placeholder: 'Enter the format in 2023/06/12/24/34',
    },
    filters?.length > 1 && {
      name: SolutionAdlsSubscriptionItemField.AdlsFilterCondition,
      label: 'Apply if',
      placeholder: 'Select the value',
      Component: Select,
      options: SUBSCRIBE_FILTER_CONDITION_OPTIONS,
      initialValue: 'And',
      validation: Yup.string().required('Operator is required'),
      required: true,
      sx: { width: ({ spacing }) => spacing(30) },
    },
    {
      name: SolutionAdlsSubscriptionItemField.AdlsFilters,
      appendLabel: 'Filter',
      childFields: getSubscriptionFilterFields(),
      RowComponent: SolutionSubscriptionEditFormRow,
      maxRows: 30,
    },
  ]);
