import React from 'react';
import { useParams } from 'react-router-dom';
import { DataTableActions } from 'src/components/DataTable';
import { isModalComplete } from 'src/components/ModalDialog';
import { useUserRoles } from 'src/auth';
import { useModal } from 'src/hooks';
import { useStoreUnitActions } from 'src/redux/helpers';
import companiesStore from '../../redux';
import { CompanyUserItem } from '../../types';
import { useIsLastOwner } from '../hooks';
import CompanyUsersDeleteModal from './CompanyUsersDeleteModal';
import CompanyUsersEditModal from './CompanyUsersEditModal';

type Props = {
  item?: CompanyUserItem;
};

function CompanyUsersAction({ item }: Props) {
  const { companyId } = useParams();
  const actions = useStoreUnitActions(companiesStore);
  const isLastOwner = useIsLastOwner(item?.role);
  const { isPlatformReader } = useUserRoles();

  const onClose = (event, reason) =>
    isModalComplete(reason) && actions.users(companyId);

  const [EditDialog, handleEdit] = useModal(CompanyUsersEditModal, {
    item,
    onClose,
  });

  const [DeleteDialog, handleDelete] = useModal(CompanyUsersDeleteModal, {
    userId: item?.userId,
    onClose,
  });

  return (
    <DataTableActions
      onEdit={handleEdit}
      onDelete={handleDelete}
      canDelete={!isLastOwner && !isPlatformReader}
      canEdit={!isLastOwner && !isPlatformReader}
    >
      {EditDialog}
      {DeleteDialog}
    </DataTableActions>
  );
}

export default CompanyUsersAction;
