import { generatePath } from 'react-router-dom';
import { createDatasetSlice } from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import {
  API_NAMESPACE_RSC_LIMIT,
  SOLUTION_STORE_NAMESPACE_RSC_LIMIT,
  SOLUTIONS_STORE,
} from '../constants';
import { mapSolutionNamespaceRscLimitData } from './utils';

const api = ({ siteId, namespaceName }) =>
  pepApiRequest({
    method: RequestMethod.Get,
    url: generatePath(API_NAMESPACE_RSC_LIMIT, { siteId, namespaceName }),
  }).then((response) => mapSolutionNamespaceRscLimitData(response));

const namespaceRscLimit = createDatasetSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTION_STORE_NAMESPACE_RSC_LIMIT,
});

export default namespaceRscLimit;
