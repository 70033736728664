import React from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { SxProps } from 'src/theme/types';
import { SortDirection, SortHandler, SortOrder } from '../types';
import { getUpdatedSortOrder } from '../utils';

type Props = {
  id: string;
  value: string;
  onSort: SortHandler;
  sortOrder?: SortOrder;
};

const getStyle = (sortOrder): SxProps => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  gap: 0.5,
  ':not(:hover) .MuiIconButton-root': {
    visibility: sortOrder ? 'visible' : 'hidden',
  },
});

function DataTableSortHeader({ id, value, sortOrder, onSort }: Props) {
  const ArrowIcon =
    sortOrder === SortDirection.Desc ? ArrowDownwardIcon : ArrowUpwardIcon;

  return (
    <Box
      data-testid={`data-table-sort-header-${id}`}
      sx={getStyle(sortOrder)}
      onClick={() => onSort(id, getUpdatedSortOrder(sortOrder))}
    >
      {value}
      <IconButton size="small">
        <ArrowIcon color={sortOrder ? 'primary' : 'neutral'} fontSize="small" />
      </IconButton>
    </Box>
  );
}

export default DataTableSortHeader;
