import isString from 'lodash/isString';
import isFunction from 'lodash/isFunction';
import mapValues from 'lodash/mapValues';
import { ResponseMapping } from './types';

export const getHashFunc = (
  actionName: string,
  hashKey?: ((id: any) => string) | string
) => {
  const defaultHashFunc = () => (isString(hashKey) ? hashKey : actionName);

  return isFunction(hashKey) ? hashKey : defaultHashFunc;
};

export const getSliceNamePrefix = (
  storeName: string,
  sliceName?: string,
  actionName?: string
) => {
  if (sliceName && actionName) {
    sliceName = `${sliceName}_${actionName}`;
  }
  return `[${storeName}] ${sliceName}`.toUpperCase();
};

export const applyMappingToApi = (api: any, mapping?: ResponseMapping) => {
  switch (mapping) {
    case ResponseMapping.Default:
      return (params) => api(params).then((data) => ({ data }));
    default:
      return api;
  }
};

export const recMapDispatchers = (dispatch, dispatchers) =>
  mapValues(dispatchers, (dispatcher) => {
    if (isFunction(dispatcher)) {
      return dispatcher(dispatch);
    }

    return recMapDispatchers(dispatch, dispatcher);
  });
