import { put } from 'redux-saga/effects';
import {
  createDatasetSlice,
  ON_SUCCESS,
  ResponseMapping,
} from 'src/redux/helpers';
import { pepApiRequest, RequestMethod } from 'src/services';
import { generateApiPath, loadDataFromStorage } from 'src/utils';
import {
  API_DEVICES,
  API_DEVICES_IOTH,
  SOLUTIONS_STORE,
  SOLUTIONS_STORE_DEVICE_DETAILS,
} from '../constants';
import solutionDeviceDepReleasesSlice from './solutionDeviceDepReleasesSlice';
import { SolutionType } from '../types';

const api = ({ solutionId, deviceId }) => {
  const solutionType = loadDataFromStorage('solutionType');
  return pepApiRequest({
    method: RequestMethod.Get,
    url:
      solutionType.toUpperCase() === SolutionType.ioTH.toUpperCase()
        ? generateApiPath(API_DEVICES_IOTH, { solutionId })
        : generateApiPath(API_DEVICES, { solutionId }),
    queryParams:
      solutionType.toUpperCase() === SolutionType.ioTH.toUpperCase()
        ? { filter: `${deviceId}` }
        : { filter: `id eq '${deviceId}'` },
  }).then(({ devices }) => devices[0]);
};

const solutionDeviceDetailsSlice = createDatasetSlice({
  api,
  storeName: SOLUTIONS_STORE,
  actionName: SOLUTIONS_STORE_DEVICE_DETAILS,
  responseMapping: ResponseMapping.Default,
  sagas: {
    *[ON_SUCCESS]({ payload, meta }) {
      const { deviceType } = payload.data;
      const { solutionId } = meta.identifier;
      yield put(
        solutionDeviceDepReleasesSlice.actions({
          deviceType,
          solutionId,
        })
      );
    },
  },
});

export default solutionDeviceDetailsSlice;
