import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import { SolutionSignatureWhitelistField } from '../types';

export const SignatureWhitelistFormFields = [
  {
    name: SolutionSignatureWhitelistField.Signature,
    label: 'OS signature',
    placeholder: 'Enter OS signature',
    Component: TextField,
    type: 'string',
    validation: Yup.string().required('OS signature is required'),
    required: true,
  },
  {
    name: SolutionSignatureWhitelistField.Description,
    label: 'Description',
    placeholder: 'Enter description',
    Component: TextField,
    multiline: true,
    rows: 3,
    type: 'string',
  },
];
