import { baseTheme } from '../baseTheme';

const {
  palette,
  spacing,
  transitions: { create, duration },
} = baseTheme;

export default {
  MuiTab: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        opacity: 1,
        textTransform: 'none',
        padding: spacing(0, 3),
        transition: create(['background-color', 'color'], {
          duration: duration.short,
        }),
      },
      textColorPrimary: {
        color: palette.primary.main,
        '&:hover': {
          color: palette.white,
          backgroundColor: palette.primary.light,
        },
        '&.Mui-disabled': {
          color: palette.neutral.main,
        },
        '&.Mui-selected': {
          color: palette.white,
          backgroundColor: palette.primary.dark,
          '&.Mui-disabled': {
            backgroundColor: palette.neutral.main,
          },
        },
      },
    },
  },
  MuiTabs: {
    defaultProps: {
      variant: 'scrollable',
      scrollButtons: 'auto',
      textColor: 'primary',
    },
    styleOverrides: {
      root: {
        '& .MuiTabs-indicator': {
          display: 'none',
        },
      },
    },
  },
};
