import React from 'react';
import ModalDialog, { ModalCloseHandler } from 'src/components/ModalDialog';
import { useForm } from 'src/components/Form';
import { SolutionJobItem } from '../../types';
import { useSolutionJobEditFormConfig } from '../hooks';

type Props = {
  item?: SolutionJobItem;
  open: boolean;
  onClose: ModalCloseHandler;
  title: string;
  onSubmit: (item: SolutionJobItem) => void;
};

function SolutionJobEditStep({ item, open, title, onClose, onSubmit }: Props) {
  const [fields, onChange] = useSolutionJobEditFormConfig(item);
  const [form, handleSubmit] = useForm({
    values: item,
    fields,
    onChange,
    onSubmit,
    visibleValuesOnly: true,
  });

  return (
    <ModalDialog
      open={open}
      title={title}
      onClose={onClose}
      onConfirm={handleSubmit}
      confirmBtnText="Next"
      fullWidth
    >
      {form}
    </ModalDialog>
  );
}

export default SolutionJobEditStep;
