import { useSelector } from 'react-redux';
import { State } from 'src/redux/types';
import { SolutionEdgeWorkloadItem } from 'src/pages/Solutions/types';
import { SiteItem, SiteDetailsItem, SiteClusterItem } from '../types';

export const useSitesList = (): [SiteItem[], boolean?] => {
  const { data, loading } = useSelector((state: State) => state.sites.list);

  return [data || [], loading];
};

export const useSiteDetails = (): [
  SiteDetailsItem | undefined,
  boolean | undefined
] => {
  const { data, loading } = useSelector((state: State) => state.sites.details);

  return [data, loading];
};

export const useCurrentSiteName = () => {
  const [data] = useSiteDetails();

  return data?.name;
};

export const useSiteEdgeWorkloadConfigList = (): [
  SolutionEdgeWorkloadItem[],
  boolean | undefined
] => {
  const { data, loading } = useSelector(
    (state: State) => state.sites.edgeWorkloadConfigurations
  );

  return [data || [], loading];
};

export const useSiteClusterOptionsist = (): [
  SiteClusterItem,
  boolean | undefined
] => {
  const { data, loading } = useSelector(
    (state: State) => state.sites.clusterDetails
  );

  return [data || [], loading];
};
