import { baseTheme } from '../baseTheme';

const { palette, typography } = baseTheme;

export default {
  MuiFormControlLabel: {
    defaultProps: {
      disableTypography: true,
    },
    styleOverrides: {
      root: {
        alignSelf: 'start',
      },
    },
  },
  MuiFormGroup: {
    defaultProps: {
      row: true,
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        color: palette.black,
        ...typography.body2,
      },
      asterisk: {
        color: palette.error.main,
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        color: palette.black,
        ...typography.small1,
      },
    },
  },
};
