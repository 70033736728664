import {
  SolutionFileSubscriptionType,
  SolutionLocation,
  SolutionTelemetryProcessingType,
  SubscriptionType,
} from '../types';

export const LOCATION_OPTIONS = [
  { value: SolutionLocation.eastus, label: 'Eastern US' },
  { value: SolutionLocation.southcentralus, label: 'South Central US' },
];

export const DEVICE_TYPE_OPTIONS = [
  { value: 'ioT', label: 'IoT' },
  { value: 'ioTEdge', label: 'IoT Edge' },
];

export const KIBANA_SPACE_TYPE_OPTIONS = [
  { value: 'global', label: 'Global' },
  { value: 'confidential', label: 'Confidential' },
];

export const SUBSCRIPTION_TYPE_OPTIONS = [
  {
    value: SubscriptionType.Messages,
    label: 'Messages',
  },
  {
    value: SubscriptionType.Files,
    label: 'Files',
  },
];

export const FILE_SUBSCRIPTION_OPTIONS = [
  {
    value: SolutionFileSubscriptionType.EDAPStorageAccount,
    label: 'EDAP storage account',
  },
  {
    value: SolutionFileSubscriptionType.PEPsenseStorageAccount,
    label: 'PEPsense storage account',
  },
];

export const TELEMETRY_STREAM_PROCESSING_OPTIONS = [
  {
    value: SolutionTelemetryProcessingType.Kafka,
    label: 'Kafka',
  },
  {
    value: SolutionTelemetryProcessingType.Eventhub,
    label: 'Eventhub',
  },
  {
    value: SolutionTelemetryProcessingType.Servicebus,
    label: 'Servicebus',
  },
];
