import React, { useEffect, useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import DataTable, {
  getActionsColConfig,
  useTableQueryParams,
} from 'src/components/DataTable';
import DataTableContext from 'src/components/DataTable/components/DataTableContext';
import DataTableSearchBar from 'src/components/DataTable/components/DataTableSearchBar';
import PageCardLayout from 'src/components/PageCardLayout';
import { Box } from '@mui/system';
import { useStoreUnitActions } from 'src/redux/helpers';
import CardHeader from '@mui/material/CardHeader';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { styled } from '@mui/material/styles';
import {
  capitalizeFirstletter,
  loadDataFromStorage,
  saveDataToStorage,
} from 'src/utils';
import { useUserRoles } from 'src/auth';
import solutionsStore, { useSolutionsList } from '../redux';
import SolutionsItemStatus from '../components/SolutionItemStatus';
import SolutionsListAction from './components/SolutionsListAction';
import { SolutionType, SolutionItemField } from '../types';
import { NEW_SOLUTION as ROUTE_NEW_SOLUTION } from '../constants';
import { SolutionsListTitle } from './components/SolutionsListTitle';

const MuiToggleButton = styled(ToggleButton)({
  '&:hover': {
    color: 'white',
    backgroundColor: '#3381FF',
  },
  '&.Mui-selected': {
    color: 'white',
    backgroundColor: '#0047BA',
  },
});

const buttonStyle = {
  textTransform: 'none',
  color: '#0047BA',
  border: '0rem',
  borderRadius: '0',
  width: '115px',
};

const industrialColumns = [
  { id: SolutionItemField.displayName, title: 'Solution name' },
  { id: SolutionItemField.CostCenter, title: 'Cost center' },
  {
    id: SolutionItemField.ClientId,
    title: 'Solution App/Client ID',
  },
  {
    id: SolutionItemField.BusinessOwner,
    title: 'Business Owner',
  },
  {
    id: SolutionItemField.Status,
    title: 'Status',
    Component: SolutionsItemStatus,
  },
  {
    id: SolutionItemField.ADGroup,
    title: 'Ad Group',
  },

  getActionsColConfig(SolutionsListAction),
];

const commonColumns = [
  { id: SolutionItemField.displayName, title: 'Solution name' },
  { id: SolutionItemField.Location, title: 'Location' },
  { id: SolutionItemField.CostCenter, title: 'Cost center' },
  {
    id: SolutionItemField.ClientId,
    title: 'Solution App/Client ID',
  },
  {
    id: SolutionItemField.BusinessOwner,
    title: 'Business Owner',
  },
  {
    id: SolutionItemField.deviceType,
    title: 'Device Type',
  },
  {
    id: SolutionItemField.Status,
    title: 'Status',
    Component: SolutionsItemStatus,
  },
  getActionsColConfig(SolutionsListAction),
];

const consumerColumns = [
  { id: SolutionItemField.displayName, title: 'Solution name' },
  { id: SolutionItemField.IoTCentralName, title: 'IoT Central name' },
  { id: SolutionItemField.Location, title: 'Location' },
  { id: SolutionItemField.CostCenter, title: 'Cost center' },
  {
    id: SolutionItemField.ClientId,
    title: 'Solution App/Client ID',
  },
  {
    id: SolutionItemField.BusinessOwner,
    title: 'Business Owner',
  },
  {
    id: SolutionItemField.deviceType,
    title: 'Device Type',
  },
  {
    id: SolutionItemField.Status,
    title: 'Status',
    Component: SolutionsItemStatus,
  },
  getActionsColConfig(SolutionsListAction),
];
const newConsumerColumns = [...commonColumns];

function SolutionsList() {
  const type =
    loadDataFromStorage('solutionType') !== ''
      ? loadDataFromStorage('solutionType')
      : SolutionType.Consumer.toString();
  const [switchValue, setSwitchValue] = useState(type);
  const [data, loading] = useSolutionsList();
  const { paging, search, sorting } = useTableQueryParams();
  const actions = useStoreUnitActions(solutionsStore);
  const { isPlatformReader } = useUserRoles();
  const { devicesPersist } = useStoreUnitActions(solutionsStore);

  useEffect(() => {
    actions.list({ switchValue });
  }, [switchValue]);

  useEffect(() => {
    devicesPersist.clear();
    const removeTypeFromStorage = () => {
      localStorage.removeItem('solutionType');
    };
    return () => removeTypeFromStorage();
  });
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    saveDataToStorage('solutionType', value[0]?.toString());
    setSwitchValue(loadDataFromStorage('solutionType'));
  };

  const fetchColumns = (): any => {
    let columns: any;
    switch (switchValue) {
      case SolutionType.Consumer:
        columns = consumerColumns;
        break;
      case SolutionType.Industrial:
        columns = industrialColumns;
        break;
      case SolutionType.ioTH:
        columns = newConsumerColumns;
        break;
      default:
        break;
    }
    return columns;
  };

  const updatedData = data.map((item) => ({
    ...item,
    businessOwner: item.businessOwner ? item.businessOwner : '–',
  }));

  return (
    <Box style={{ boxShadow: '3px 3px 5px #E8E8E8', borderWidth: '0px' }}>
      <CardHeader
        data-testid="solution-list-header"
        title={
          <SolutionsListTitle
            title="Solutions"
            url="/help-center/docs/IndustrialSetup/SolutionOnboarding/SolutionOnboardingProcess/"
          />
        }
        sx={{
          bgcolor: '#fff',
          border: '0.1rem solid #E8E8E8',
        }}
      />
      <CardHeader
        value={switchValue}
        title={
          <ToggleButtonGroup
            data-testid="solution-switch"
            aria-label="Solutions Switch"
            onChange={handleChange}
            size="small"
            sx={{ height: '49px' }}
          >
            <MuiToggleButton
              value={SolutionType.Consumer}
              key={SolutionType.Consumer}
              selected={switchValue === SolutionType.Consumer}
              sx={buttonStyle}
            >
              {capitalizeFirstletter(SolutionType.Consumer)}
            </MuiToggleButton>
            <MuiToggleButton
              value={SolutionType.Industrial}
              key={SolutionType.Industrial}
              selected={switchValue === SolutionType.Industrial}
              sx={buttonStyle}
            >
              {capitalizeFirstletter(SolutionType.Industrial)}
            </MuiToggleButton>
            <MuiToggleButton
              value={SolutionType.ioTH}
              key={SolutionType.NewConsumer}
              selected={switchValue === SolutionType.ioTH}
              sx={buttonStyle}
              style={{ width: 'auto' }}
            >
              {'New Consumer'}
            </MuiToggleButton>
          </ToggleButtonGroup>
        }
        sx={{
          bgcolor: '#fff',
          border: '0.1rem #E8E8E8',
          borderRadius: '0px',
          borderLeft: '0.1rem solid #E8E8E8',
          borderRight: '0.1rem solid #E8E8E8',
          padding: 'inherit',
          pt: 0,
          pb: 0,
        }}
      ></CardHeader>
      <DataTableContext {...search}>
        <PageCardLayout
          style={{ borderRadius: '0 0 4px 4px' }}
          actionsPanel={
            <>
              <DataTableSearchBar />
              <Button
                data-testid="add-new-solution-btn"
                component={Link}
                to={generatePath(ROUTE_NEW_SOLUTION, {
                  solutionType: switchValue,
                })}
                variant="outlined"
                size="small"
                startIcon={<AddIcon />}
                disabled={isPlatformReader}
              >
                Create New
              </Button>
            </>
          }
        >
          {updatedData && (
            <DataTable
              data={updatedData}
              columns={fetchColumns()}
              loading={loading}
              {...paging}
              {...sorting}
            />
          )}
        </PageCardLayout>
      </DataTableContext>
    </Box>
  );
}

export default SolutionsList;
