import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import './guidedTour.css';
import { ReactNode, useState } from 'react';
import Tour, { Dot, Navigation, ReactourStep } from 'reactour';

const iconStyle = {
  color: '#FFF',
};

type Props = {
  children?: ReactNode;
  steps: ReactourStep[];
  onClose: () => void;
  isOpen: boolean;
  activeStep: number | null;
  setActiveStep: (number) => void;
  className?: string;
};

function GuidedTour({
  steps,
  isOpen,
  onClose,
  children,
  activeStep,
  setActiveStep,
  className,
}: Props) {
  const [current, setCurrent] = useState(0);

  return (
    <Tour
      steps={steps}
      isOpen={isOpen}
      onRequestClose={onClose}
      rounded={7}
      lastStepNextButton={
        <NavigateNextIcon sx={{ ...iconStyle, opacity: '0.1' }} />
      }
      nextButton={<NavigateNextIcon sx={iconStyle} />}
      prevButton={current > 0 ? <NavigateBeforeIcon sx={iconStyle} /> : <></>}
      getCurrentStep={(currentStep) => {
        setCurrent(currentStep);
        setActiveStep(currentStep);
      }}
      showNavigationNumber={false}
      showNumber={false}
      startAt={0}
      accentColor="#FFF"
      disableInteraction={true}
      showNavigation={false}
      maskSpace={6}
      className={className}
      scrollDuration={10}
    >
      <>
        {children}
        <Navigation style={{ position: 'fixed', bottom: '26px' }}>
          {steps.map((s, i) => (
            <Dot
              key={`${s.selector ? s.selector : 'undef'}_${i}`}
              current={current}
              index={i}
              accentColor="#FFF"
              disabled={current === i}
              data-tour-elem="dot"
              style={{ color: i === current ? '#FFF' : '#ccc' }}
              aria-label={s.navDotAriaLabel}
            />
          ))}
        </Navigation>
      </>
    </Tour>
  );
}

export default GuidedTour;
