import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useStoreUnitActions } from 'src/redux/helpers';
import { RoutableTabsLayout } from 'src/router';
import sitesStore from '../redux';
import { routes } from './routes';

function SiteDetails() {
  const { siteId } = useParams();
  const actions = useStoreUnitActions(sitesStore);

  useEffect(() => {
    actions.details(siteId);
  }, [siteId]);

  return <RoutableTabsLayout routes={routes} />;
}

export default SiteDetails;
