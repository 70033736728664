import { useParams } from 'react-router-dom';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import ModalDialog, {
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { pepApiRequest, RequestMethod, useStatefulApi } from 'src/services';
import { generateApiPath, loadDataFromStorage } from 'src/utils';
import {
  API_DEVICE_GROUP_ITEM,
  API_DEVICE_GROUP_ITEM_IOTH,
} from '../../constants';
import { SolutionDeviceGroupItem, SolutionType } from '../../types';

type Props = {
  item: SolutionDeviceGroupItem;
  open: boolean;
  onClose: ModalCloseHandler;
};
export default function SolutionDeviceGroupDeleteModal({
  open,
  item,
  onClose,
}: Props) {
  const { showAlert } = useAlert();
  const { solutionId } = useParams();
  const [onSubmit, loading] = useStatefulApi(
    () => {
      const solutionType = loadDataFromStorage('solutionType');
      return pepApiRequest({
        method: RequestMethod.Delete,
        url: generateApiPath(
          solutionType.toUpperCase() === SolutionType.ioTH.toUpperCase()
            ? API_DEVICE_GROUP_ITEM_IOTH
            : API_DEVICE_GROUP_ITEM,
          {
            solutionId,
            companyId: item?.companyId,
            groupId: item.id,
          }
        ),
      });
    },
    () => {
      showAlert({
        type: AlertType.Info,
        title: `${item.displayName} is deleted`,
        text: 'Solution device group has been successfully deleted.',
      });
      onClose(null, ModalCloseReason.completeAction);
    }
  );

  return (
    <ModalDialog
      open={open}
      title="Delete device group"
      onClose={onClose}
      confirmBtnText="Yes"
      cancelBtnText="No"
      onConfirm={onSubmit}
      loading={loading}
    >
      Are you sure you want to delete this device group?
      <br />
      <b>{item.displayName}</b>
    </ModalDialog>
  );
}
