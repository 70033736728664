import React from 'react';
import { useParams } from 'react-router-dom';
import { DataTableActions } from 'src/components/DataTable';
import { isModalComplete } from 'src/components/ModalDialog';
import { useModal } from 'src/hooks';
import { useStoreUnitActions } from 'src/redux/helpers';
import { useUserRoles } from 'src/auth';
import solutionsStore from '../../redux';
import { SolutionUserItem } from '../../types';
import { useIsLastOwner } from '../hooks';
import SolutionUsersDeleteModal from './SolutionUsersDeleteModal';
import SolutionUsersEditModal from './SolutionUsersEditModal';

type Props = {
  item?: SolutionUserItem;
};

function SolutionUsersAction({ item }: Props) {
  const { solutionId } = useParams();
  const actions = useStoreUnitActions(solutionsStore);
  const isLastOwner = useIsLastOwner(item?.role);
  const { isPlatformReader, isPlatformEngineer } = useUserRoles();
  const isValidUser = isPlatformEngineer || isPlatformReader;

  const onClose = (event, reason) =>
    isModalComplete(reason) && actions.users(solutionId);

  const [EditDialog, handleEdit] = useModal(SolutionUsersEditModal, {
    item,
    onClose,
  });

  const [DeleteDialog, handleDelete] = useModal(SolutionUsersDeleteModal, {
    userId: item?.userId,
    onClose,
  });

  return (
    <DataTableActions
      onEdit={handleEdit}
      onDelete={handleDelete}
      canDelete={!isLastOwner && !isValidUser}
      canEdit={!isLastOwner && !isValidUser}
    >
      {EditDialog}
      {DeleteDialog}
    </DataTableActions>
  );
}

export default SolutionUsersAction;
