import { baseTheme } from '../baseTheme';

const { palette, spacing, shadows, typography } = baseTheme;

export default {
  MuiCard: {
    styleOverrides: {
      root: {
        borderStyle: 'solid',
        borderColor: palette.grayscale[400],
        borderWidth: spacing(0.1),
        boxShadow: shadows[3],
        overflow: 'initial',
      },
    },
  },
  MuiCardHeader: {
    defaultProps: {
      disableTypography: true,
    },
    styleOverrides: {
      root: {
        display: 'flex',
        borderBottomStyle: 'solid',
        borderWidth: spacing(0.2),
        borderColor: palette.grayscale[400],
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        padding: spacing(1.6, 2),
        overflow: 'hidden',
        ...typography.h4,
        '& .MuiCardHeader-action': {
          display: 'flex',
          gap: spacing(2),
          margin: 0,
          maxWidth: '100%',
        },
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        minHeight: '30vh',
        position: 'relative',
        padding: spacing(2),
      },
    },
  },
};
