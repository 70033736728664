import React from 'react';
import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import { generateApiPath } from 'src/utils';
import ModalDialog, { ModalCloseHandler } from 'src/components/ModalDialog';
import { useForm, FormField } from 'src/components/Form';
import {
  pepApiRequest,
  ContentType,
  RequestMethod,
  useStatefulApi,
} from 'src/services';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import { useStoreUnitActions } from 'src/redux/helpers';
import solutionsStore from '../../redux';

const formStyle = {
  flexDirection: 'row',
  '.MuiFormControl-root': {
    width: ({ spacing }) => spacing(90),
  },
};

type Props = {
  api: string;
  formFields: FormField[];
  title: string;
  open: boolean;
  onClose: ModalCloseHandler;
};

function SolutionDeviceConfigUploadModal({
  api,
  formFields,
  title,
  open,
  onClose,
}: Props) {
  const actions = useStoreUnitActions(solutionsStore);
  const { showAlert } = useAlert();
  const { solutionId } = useParams();
  const [onSubmit, loading] = useStatefulApi(
    (params) =>
      pepApiRequest({
        method: RequestMethod.Post,
        url: generateApiPath(api, { solutionId }),
        contentType: ContentType.FormData,
        params,
      }),
    () => {
      showAlert({
        type: AlertType.Success,
        title: 'Template file uploaded',
        text: 'Template model file has been uploaded successfully.',
      });
      actions.details(solutionId);
      onClose();
    },
    (err) => {
      if (typeof err === 'object' && err?.status === 400) {
        return;
      }
      showAlert({
        type: AlertType.Error,
        title: 'Template file not uploaded',
        text: 'Error while uploading template model.',
      });
    }
  );
  const [formComponent, handleSubmit] = useForm({
    sx: formStyle,
    fields: formFields,
    onSubmit,
  });

  return (
    <ModalDialog
      title={title}
      open={open}
      onClose={onClose}
      onConfirm={handleSubmit}
      confirmBtnText="Save"
      loading={loading}
      maxWidth="lg"
    >
      <Typography color="primary" variant="body2" mb={3}>
        <InfoIcon sx={{ mr: 1 }} />
        Uploaded device template file(s) can't be deleted.
      </Typography>
      {formComponent}
    </ModalDialog>
  );
}

export default SolutionDeviceConfigUploadModal;
