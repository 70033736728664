import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { SxProps } from 'src/theme/types';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { mapBytesToKb } from '../utils';

type Props = {
  error?: boolean;
  files: File[];
  onDeleteFile: (file: File) => void;
};

const getCardStyle = (error?: boolean): SxProps => ({
  position: 'relative',
  backgroundColor: 'neutral.light',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: 0.2,
  pl: 2,
  pr: 3,
  height: ({ spacing }) => spacing(6),
  width: ({ spacing }) => spacing(21),
  ...(error && {
    borderColor: 'error.main',
  }),
});

const getCloseButtonStyle = (error?: boolean): SxProps => ({
  position: 'absolute',
  top: 0,
  right: 0,
  ...(error && {
    color: 'error.main',
  }),
});

const fileNameStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

function FileUploadInputSelection({ error, files, onDeleteFile }: Props) {
  return (
    <Box display="flex" gap={2}>
      {files.map((file) => (
        <Card
          sx={getCardStyle(error)}
          key={file.name}
          onClick={(event) => event.stopPropagation()}
        >
          <IconButton
            sx={getCloseButtonStyle(error)}
            onClick={() => onDeleteFile(file)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <Box typography="small1bold" sx={fileNameStyle}>
            {file.name}
          </Box>
          <Box color="neutral.main" typography="small1">
            {mapBytesToKb(file.size)}
          </Box>
        </Card>
      ))}
    </Box>
  );
}

export default FileUploadInputSelection;
