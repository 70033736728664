import { ReactNode } from 'react';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DataTableActionsButton from './DataTableActionsButton';
import DataTableActionsWrapper from './DataTableActionsWrapper';
import { useRowClickHandler } from '../hooks';
import { RowActionHandler } from '../types';

type Props = {
  onView?: RowActionHandler;
  onEdit?: RowActionHandler;
  onDelete?: RowActionHandler;
  onObservablity?: RowActionHandler;
  onRowClick?: RowActionHandler;
  canView?: boolean;
  canEdit?: boolean;
  canDelete?: boolean;
  showView?: boolean;
  showEdit?: boolean;
  showDelete?: boolean;
  showObservability?: boolean;
  children?: ReactNode;
};

function DataTableActions({
  children,
  canView = true,
  canEdit = true,
  canDelete = true,
  showView = false,
  showEdit = true,
  showDelete = true,
  onView,
  onEdit,
  onDelete,
  onRowClick,
}: Props) {
  const viewRowClickHandler = showView && canView && onView;
  const editRowClickHandler = showEdit && canEdit && onEdit;
  useRowClickHandler(onRowClick || viewRowClickHandler || editRowClickHandler);

  return (
    <DataTableActionsWrapper>
      {showView && (
        <DataTableActionsButton
          data-testid="data-table-view-action"
          disabled={!canView}
          onClick={onView}
          icon={VisibilityOutlinedIcon}
          title="View"
        />
      )}
      {showEdit && (
        <DataTableActionsButton
          data-testid="data-table-edit-action"
          disabled={!canEdit}
          onClick={onEdit}
          icon={EditOutlinedIcon}
          title="Edit"
        />
      )}
      {showDelete && (
        <DataTableActionsButton
          data-testid="data-table-delete-action"
          disabled={!canDelete}
          onClick={onDelete}
          icon={DeleteOutlinedIcon}
          title="Delete"
        />
      )}
      {children}
    </DataTableActionsWrapper>
  );
}

export default DataTableActions;
