import React from 'react';
import Box from '@mui/material/Box';
import TableCell, { TableCellProps } from '@mui/material/TableCell';

const cellStyle = {
  display: 'flex',
  color: 'neutral.main',
  alignItems: 'center',
  justifyContent: 'center',
};

function DataTableBlankRow(props: TableCellProps) {
  return <TableCell component={Box} sx={cellStyle} {...props} />;
}

export default DataTableBlankRow;
