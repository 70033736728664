import { useEffect, useReducer } from 'react';
import slice from 'lodash/slice';
import { DEFAULT_PAGE_SIZE } from '../constants';
import { PagingType, Row } from '../types';
import { getPageSliceData, isPageOutOfRange } from '../utils';

type usePagingProps<TRow extends Row> = {
  inputData: TRow[];
  pageIndex?: number;
  pageSize?: number;
  paging?: PagingType | false;
  onPageIndexChange?: (index: number) => void;
  onPageSizeChange?: (size: number) => void;
  totalItems?: number;
};

type usePagingReturnProps<TRow extends Row> = {
  data: TRow[];
  showPaging?: boolean;
  pageIndex: number;
  pageSize: number;
  totalItems: number;
  goToPage: (index: number) => void;
  onPageSizeChange: (size: number) => void;
};

enum PagingAction {
  Init = 'init',
  SetPageIndex = 'setPageIndex',
  SetPageSize = 'setPageSize',
}

const initialPaging = {
  pageIndex: 0,
  pageSize: DEFAULT_PAGE_SIZE,
};

const localReducer = (state, { type, payload }) => {
  const {
    inputData,
    pageIndex = state.pageIndex,
    pageSize = state.pageSize,
  } = payload;
  const showPaging = inputData.length > DEFAULT_PAGE_SIZE;

  switch (type) {
    case PagingAction.Init:
      return {
        data: getPageSliceData(inputData, pageIndex, pageSize),
        totalItems: inputData.length,
        pageIndex,
        pageSize,
        showPaging,
      };
    case PagingAction.SetPageIndex:
      return {
        ...state,
        data: getPageSliceData(inputData, pageIndex, state.pageSize),
        pageIndex,
      };
    case PagingAction.SetPageSize:
      return {
        ...state,
        data: slice(inputData, 0, pageSize),
        pageIndex: 0,
        pageSize,
      };
    default:
      return state;
  }
};

const remoteReducer = (state, { type, payload }) => {
  const { inputData, paging, pageIndex, pageSize, totalItems } = payload;
  const showPaging = paging && totalItems > DEFAULT_PAGE_SIZE;

  switch (type) {
    case PagingAction.Init:
      return {
        data: inputData,
        pageIndex,
        pageSize,
        showPaging,
        totalItems,
      };
    default:
      return state;
  }
};

export const usePaging = <TRow extends Row>({
  inputData,
  paging,
  pageIndex,
  pageSize,
  onPageIndexChange,
  onPageSizeChange,
  totalItems,
}: usePagingProps<TRow>): usePagingReturnProps<TRow> => {
  const reducer = paging === PagingType.Local ? localReducer : remoteReducer;
  const initAction = {
    type: PagingAction.Init,
    payload: { inputData, paging, pageIndex, pageSize, totalItems },
  };
  const initialState = reducer(initialPaging, initAction);
  const [state, dispatch] = useReducer(reducer, initialState);

  const handlePageIndexChange = (pageIndex: number) => {
    if (onPageIndexChange) {
      onPageIndexChange(pageIndex);
    }
    dispatch({
      type: PagingAction.SetPageIndex,
      payload: { inputData, pageIndex },
    });
  };

  const handlePageSizeChange = (pageSize: number) => {
    if (onPageIndexChange) {
      onPageIndexChange(0);
    }
    if (onPageSizeChange) {
      onPageSizeChange(pageSize);
    }
    dispatch({
      type: PagingAction.SetPageSize,
      payload: { inputData, pageSize },
    });
  };

  useEffect(() => {
    dispatch(initAction);
  }, [inputData, paging, pageIndex, pageSize, totalItems]);
  useEffect(() => {
    if (isPageOutOfRange(inputData, totalItems, pageIndex, pageSize)) {
      handlePageIndexChange(0);
    }
  }, [totalItems, inputData.length]);

  return {
    ...state,
    goToPage: handlePageIndexChange,
    onPageSizeChange: handlePageSizeChange,
  };
};
