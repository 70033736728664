import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import ModalDialog from 'src/components/ModalDialog';
import DataTable, { getActionsColConfig } from 'src/components/DataTable';
import DateFormattedValue from 'src/components/DateFormattedValue';
import { PageCardSection } from 'src/components/PageCardLayout';
import Tabs from 'src/components/Tabs';
import { useStoreUnitActions } from 'src/redux/helpers';
import FieldsSet from 'src/components/FieldsSet';
import SolutionDeviceCertRenewalStatus from '../../components/SolutionDeviceCertRenewalStatus';
import solutionsStore, {
  useSolutionsDeviceCertDetails,
  useSolutionsDeviceCertAuditLogs,
} from '../../redux';
import {
  SolutionDeviceCertDetailsField,
  SolutionDeviceCertAuditLogsField,
  SolutionDeviceItem,
  CertificateType,
} from '../../types';
import SolutionDeviceCertDetailsActions from './SolutionDeviceCertDetailsActions';

type Props = {
  item: SolutionDeviceItem;
  certStatus: string;
  open: boolean;
  onClose: () => void;
};

const fields1 = [
  {
    id: SolutionDeviceCertDetailsField.CertThumbprint,
    label: 'Certificate Thumb Print',
  },
  { id: SolutionDeviceCertDetailsField.SerialNumber, label: 'Serial Number' },
  {
    id: SolutionDeviceCertDetailsField.CertificateStatus,
    label: 'Status',
  },
];
const fields2 = [
  {
    id: SolutionDeviceCertDetailsField.createdAt,
    label: 'Created',
    Component: DateFormattedValue,
  },
  {
    id: SolutionDeviceCertDetailsField.CertExpiryDate,
    label: 'Expiry',
    Component: DateFormattedValue,
  },
  {
    id: SolutionDeviceCertDetailsField.LastUpdatedAt,
    label: 'Last Updated',
    Component: DateFormattedValue,
  },
];

const tableStyle = {
  minHeight: 0,
};

const columns = [
  {
    id: SolutionDeviceCertAuditLogsField.DeviceId,
    sx: { width: '1px', padding: 0 },
  },
  {
    id: SolutionDeviceCertAuditLogsField.CertRenewalState,
    title: 'Status',
    sx: { paddingLeft: 0 },
    Component: SolutionDeviceCertRenewalStatus,
  },
  {
    id: SolutionDeviceCertAuditLogsField.CreatedAt,
    title: 'Started',
    Component: DateFormattedValue,
  },
  {
    id: SolutionDeviceCertAuditLogsField.CertRenewalRemark,
    title: 'Remarks',
    sortable: false,
  },
  getActionsColConfig(SolutionDeviceCertDetailsActions),
];

function SolutionDeviceCertificateViewModal({
  item,
  certStatus,
  open,
  onClose,
}: Props) {
  const { id: deviceId, enabled } = item;
  const { solutionId } = useParams();
  const actions = useStoreUnitActions(solutionsStore);
  const [data, loading] = useSolutionsDeviceCertDetails();
  const certDetais = { ...data[0], certificateStatus: certStatus };
  const [auditLogs, loadLogs] = useSolutionsDeviceCertAuditLogs();

  const [type, setType] = React.useState(CertificateType.IOTC);
  const handleChange = (event: React.SyntheticEvent, newValue) => {
    setType(newValue);
  };

  useEffect(() => {
    actions.deviceCertificateDetails({ solutionId, deviceId, type });
    actions.deviceCertificateAuditLogs({ solutionId, deviceId, type });
  }, [type]);

  return (
    <ModalDialog
      title={'Certificate Status'}
      open={open}
      onClose={onClose}
      onConfirm={onClose}
      loading={loading || loadLogs}
      showCancelBtn={false}
      confirmBtnText={'Close'}
      maxWidth="lg"
      fullWidth
    >
      <Tabs
        value={type}
        onChange={handleChange}
        aria-label="wrapped label tabs example"
        sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: 3 }}
      >
        <Tab value={CertificateType.IOTC} label="IoT" />
        <Tab value={CertificateType.Mender} label="Mender" />
      </Tabs>
      {data.length !== 0 ? (
        <Grid container spacing={5} columns={16}>
          <Grid item xs={10}>
            <FieldsSet data={certDetais} fields={fields1} gap={2} />
          </Grid>
          <Grid item xs={6}>
            <FieldsSet data={certDetais} fields={fields2} gap={2} />
          </Grid>
        </Grid>
      ) : (
        <Typography variant="body2" color="#A5A5A5" align="center">
          Certificate Data Not Available
        </Typography>
      )}
      {data.length !== 0 && enabled && (
        <PageCardSection title="Renewal Audit Logs" mt={6} mb={0}>
          <DataTable
            columns={columns}
            data={auditLogs}
            sx={tableStyle}
            groupBy={SolutionDeviceCertAuditLogsField.DeviceId}
          />
        </PageCardSection>
      )}
    </ModalDialog>
  );
}

export default SolutionDeviceCertificateViewModal;
