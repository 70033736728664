import { baseTheme } from '../baseTheme';

const { palette } = baseTheme;

export default {
  styleOverrides: {
    root: {
      backgroundColor: palette.neutral.light,
    },
  },
};
