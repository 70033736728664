import { useParams } from 'react-router-dom';
import {
  useState,
  useCallback,
  useMemo,
  SetStateAction,
  Dispatch,
} from 'react';
import { Box } from '@mui/system';
import { Button } from '@mui/material';
import { useForm } from 'src/components/Form';
import ModalDialog, { ModalCloseHandler } from 'src/components/ModalDialog';
import { getSubscriptionEditFormFields } from '../formFields';
import {
  SolutionIndustrialFeatures,
  SolutionSubscribeTo,
  SolutionSubscriptionItem,
  SolutionSubscriptionType,
  SolutionType,
  SubscriptionType,
} from '../../types';
import { useSolutionDetails } from '../../redux';
import SolutionFeatureInformation from '../../components/SolutionFeatureInfomation';

type Props = {
  item?: SolutionSubscriptionItem;
  title: string;
  loading?: boolean;
  open: boolean;
  onClose: ModalCloseHandler;
  onSubmit: (values: SolutionSubscriptionItem) => void;
  subscriptionType: string;
  setSubscriptionType: Dispatch<SetStateAction<SolutionSubscriptionType>>;
  isEditMsgSubscription?: boolean;
};

function SolutionSubscriptionEditModal({
  item,
  title,
  loading,
  open,
  onClose,
  onSubmit,
  subscriptionType,
  setSubscriptionType,
  isEditMsgSubscription = false,
}: Props) {
  const { solutionType } = useParams();
  const [solutionDetails] = useSolutionDetails();
  const { storageEnabled } = solutionDetails?.features?.storageSetup || {};
  const [useAuth, setUseAuth] = useState(item?.useAuth);
  const [subscribeTo, setsubscribeTo] = useState<string[] | undefined>([]);
  const [filters, setFilters] = useState<any[]>(item?.filters ?? []);
  const [adlsFilters, setAdlsFilters] = useState<any[]>(
    item?.adlsFilters ?? []
  );
  const [cosmosFilters, setCosmosFilters] = useState<any[]>(
    item?.cosmosFilters ?? []
  );

  const isIndustrialSolution = solutionType === SolutionType.Industrial;
  const isMessageSubscriptionService =
    solutionDetails?.subscriptionType?.includes(SubscriptionType.Messages);
  const isFileSubscriptionService = solutionDetails?.subscriptionType?.includes(
    SubscriptionType.Files
  );
  const isAlarmEventProcessing =
    solutionType === SolutionType.Industrial
      ? solutionDetails?.solutionIndustrialFeatures?.includes(
          SolutionIndustrialFeatures.IsAlarmEventProcessing
        )
      : true;
  const isTelemetryStreamProcessing =
    solutionType === SolutionType.Industrial
      ? solutionDetails?.solutionIndustrialFeatures?.includes(
          SolutionIndustrialFeatures.IsTelemetryStreamProcessing
        )
      : true;

  const SUBSCIPTION_TYPE_OPTIONS = [
    {
      value: SolutionSubscriptionType.Messages,
      label: 'Messages',
      disabled: !isMessageSubscriptionService && isIndustrialSolution,
    },
    {
      value: SolutionSubscriptionType.Files,
      label: 'Files',
      disabled: !isFileSubscriptionService,
    },
  ];

  const INDUSTRIAL_SUBSCRIPTION_TYPE_OPTIONS = [
    ...SUBSCIPTION_TYPE_OPTIONS,
    {
      value: SolutionSubscriptionType.ADLS,
      label: 'ADLS',
    },
    {
      value: SolutionSubscriptionType.Cosmos,
      label: 'Cosmos',
    },
  ];

  const SUBSCRIBE_TO_OPTIONS = [
    {
      value: SolutionSubscribeTo.Alarm,
      label: 'Alarm',
      disabled: !isAlarmEventProcessing,
    },
    {
      value: SolutionSubscribeTo.Event,
      label: 'Event',
      disabled: !isAlarmEventProcessing,
    },
    {
      value: SolutionSubscribeTo.PlatformEvent,
      label: 'Platform event',
      disabled: !isAlarmEventProcessing,
    },
  ];

  const SUBSCRIBE_TO_OPT_INDUSTRIAL = [
    ...SUBSCRIBE_TO_OPTIONS,
    {
      value: SolutionSubscribeTo.Telemetry,
      label: 'Telemetry',
      disabled: !isTelemetryStreamProcessing,
    },
  ];

  const handlePaste = () => {
    navigator.clipboard.readText().then((clipboardText) => {
      setValue('containerSaasURL', clipboardText.trim());
    });
  };

  const handlePasteConnectionString = () => {
    navigator.clipboard.readText().then((clipboardText) => {
      setValue('DestinationConnectionString', clipboardText.trim());
    });
  };

  const handlePasteAuthorizationToken = () => {
    navigator.clipboard.readText().then((clipboardText) => {
      setValue('Authorization', clipboardText.trim());
    });
  };

  const handleCosmosConnectionStringPaste = () => {
    navigator.clipboard.readText().then((clipboardText) => {
      setValue('connectionString', clipboardText.trim());
    });
  };

  const onChange = useCallback(
    ({
      useAuth,
      subscriptionType,
      type,
      filters,
      adlsFilters,
      cosmosFilters,
    }: SolutionSubscriptionItem) => {
      setUseAuth(useAuth);
      setSubscriptionType(
        subscriptionType ||
          (!isMessageSubscriptionService &&
            !isFileSubscriptionService &&
            solutionType === SolutionType.Industrial &&
            SolutionSubscriptionType.ADLS) ||
          (!isMessageSubscriptionService &&
          solutionType === SolutionType.Industrial
            ? SolutionSubscriptionType.Files
            : SolutionSubscriptionType.Messages)
      );
      setsubscribeTo(type);
      setFilters(filters);
      setAdlsFilters(adlsFilters);
      setCosmosFilters(cosmosFilters);
    },
    []
  );
  const fields = useMemo(
    () =>
      getSubscriptionEditFormFields(
        filters,
        adlsFilters,
        cosmosFilters,
        useAuth,
        subscriptionType,
        isEditMsgSubscription,
        storageEnabled,
        solutionType,
        subscribeTo,
        handlePaste,
        handlePasteConnectionString,
        handlePasteAuthorizationToken,
        SUBSCIPTION_TYPE_OPTIONS,
        INDUSTRIAL_SUBSCRIPTION_TYPE_OPTIONS,
        isFileSubscriptionService,
        isMessageSubscriptionService,
        SUBSCRIBE_TO_OPTIONS,
        SUBSCRIBE_TO_OPT_INDUSTRIAL,
        isAlarmEventProcessing,
        isTelemetryStreamProcessing,
        handleCosmosConnectionStringPaste
      ),
    [
      useAuth,
      subscriptionType,
      subscribeTo,
      filters,
      adlsFilters,
      cosmosFilters,
      handleCosmosConnectionStringPaste,
    ]
  );

  const [form, handleSubmit, setValue] = useForm({
    visibleValuesOnly: true,
    values: item,
    fields,
    onChange,
    onSubmit,
  });

  return (
    <ModalDialog
      open={open}
      title={title}
      showCancelBtn={false}
      showConfirmBtn={false}
      loading={loading}
      maxWidth={'md'}
      onClose={onClose}
      fullWidth
    >
      {solutionType === SolutionType.Consumer && !isFileSubscriptionService ? (
        <SolutionFeatureInformation
          featureInfo="File subscription is disabled since this feature was not opted
            during solution onboarding. To start accessing it, please edit the
            solution and select the feature."
        />
      ) : (
        solutionType === SolutionType.Industrial &&
        (!isMessageSubscriptionService ||
          !isFileSubscriptionService ||
          !isAlarmEventProcessing) && (
          <SolutionFeatureInformation featureInfo="Some options are disabled since respective features are not selected during solution onboarding. Edit the solution to enable the features. " />
        )
      )}
      {form}
      <Box textAlign="right" mt={3}>
        <Button onClick={onClose} variant="outlined" sx={{ mr: 2 }}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={
            subscriptionType === SolutionSubscriptionType.Files &&
            !storageEnabled
          }
        >
          Subscribe
        </Button>
      </Box>
    </ModalDialog>
  );
}

export default SolutionSubscriptionEditModal;
