import { DefaultDatasetUnit } from 'src/redux/helpers';
import { SiteItem } from '../types';
import { SiteNamespaceRscQuotasDetailItem } from '../types/siteNamespace';

export const mapSitesListData = (items): DefaultDatasetUnit<SiteItem[]> => ({
  data: items.items,
});

export const mapSiteNamespaceRscQuotasDetailData = (
  response,
  clusterId
): SiteNamespaceRscQuotasDetailItem => {
  const { data } = response;
  const result = data[0];

  return {
    id: result.id,
    clusterId,
    usedRequestsCpu: result.status.used['requests.cpu'],
    usedRequestsMemory: result.status.used['requests.memory'],
    usedLimitsCpu: result.status.used['limits.cpu'],
    usedLimitsMemory: result.status.used['limits.memory'],
    usedRequestsStorage: result.status.used['requests.storage'],
  };
};
