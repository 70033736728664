import { createStoreUnit } from 'src/redux/helpers';
import list from './companiesListSlice';
import details from './companyDetailsSlice';
import users from './comapnyUsersSlice';
import { COMPANIES_STORE } from '../constants';

const companiesListSlice = createStoreUnit({
  name: COMPANIES_STORE,
  slices: {
    list,
    details,
    users,
  },
});

export default companiesListSlice;
