import { useModal } from 'src/hooks/modalHooks';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { loadDataFromStorage } from 'src/utils';
import {
  DataTableActionsButton,
  useRowClickHandler,
} from 'src/components/DataTable';
import {
  SolutionDeviceConfigItem,
  SolutionType,
} from '../../types';
import SolutionDeviceConfigViewModal from './SolutionDeviceConfigViewModal';

type Props = {
  item?: SolutionDeviceConfigItem;
};

function SolutionDeviceConfigFilesAction({ item }: Props) {
  const solutionType = loadDataFromStorage('solutionType');
  const [DeviceConfigFilesViewModal, showDeviceConfigFiles] = useModal(
    SolutionDeviceConfigViewModal,
    { item }
  );
  useRowClickHandler(showDeviceConfigFiles);

  return (
    <>
      {solutionType.toUpperCase() === SolutionType.ioTH.toUpperCase() ? (
        <DataTableActionsButton
          data-testid="solution-device-config-EdgeDevice-files-view-action"
          onClick={showDeviceConfigFiles}
          icon={VisibilityOutlinedIcon}
          title="View"
        />
      ) : (
        <DataTableActionsButton
          data-testid="solution-device-config-files-view-action"
          icon={VisibilityOutlinedIcon}
          onClick={showDeviceConfigFiles}
          title="View"
        />
      )}
      {DeviceConfigFilesViewModal}
    </>
  );
}

export default SolutionDeviceConfigFilesAction;
