import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Button } from '@mui/material';
import DataTableSearchBar from 'src/components/DataTable/components/DataTableSearchBar';

import { Box } from '@mui/system';
import DataTable, {
  DataTableBoolCell,
  DataTableWrapper,
  getActionsColConfig,
} from 'src/components/DataTable';
import { useStoreUnitActions } from 'src/redux/helpers';

import { loadDataFromStorage } from 'src/utils';
import DateFormattedValue from 'src/components/DateFormattedValue';
import Switch from 'src/components/Switch/Switch';
import { FilterTableContext } from 'src/components/DataTable/components/FilterTableContext';
import { useModal } from 'src/hooks';
import SolutionDevicesActions from './SolutionDevicesActions';
import solutionsStore, {
  useSolutionsDevicesList,
  useSolutionDevicesPersist,
  useSolutionDetails,
} from '../../redux';
import {
  SolutionDeviceItem,
  SolutionDeviceItemField,
  SolutionType,
  SolutionMenderStatus as MenderStatus,
} from '../../types';
import SolutionDevicesHostOsCell from './SolutionDevicesHostOsCell';
import SolutionDevicesListObserver from './SolutionDevicesListObserver';
import MoreActionsMenu from './MoreActionsMenu';
import RevokeCertificationModal from './RevokeCertificationModal';
import SolutionDeviceDeleteModel from './SolutionDevicesDeleteModel';
import SolutionMenderStatus from './SolutionMenderStatus';
import SolutionDevicesAuthoriseModal from './SolutionDevicesAuthoriseModal';
import SolutionDevicesAssignCompanyModal from './SolutionDevicesAssignCompanyModal';
import SolutionConnectionStatus from './SolutionConnectionStatus';

const commonColumns = [
  { id: SolutionDeviceItemField.DisplayName, title: 'Device name' },
  { id: SolutionDeviceItemField.DeviceId, title: 'Device ID' },
  { id: SolutionDeviceItemField.CompanyName, title: 'Company' },
  { id: SolutionDeviceItemField.ModelId, title: 'Template' },
  {
    id: SolutionDeviceItemField.Enabled,
    title: 'Enabled',
    Component: DataTableBoolCell,
  },
  {
    id: SolutionDeviceItemField.Provisioned,
    title: 'Provisioned',
    Component: DataTableBoolCell,
  },
  {
    id: SolutionDeviceItemField.HostOsVersion,
    title: 'Host OS',
    Component: SolutionDevicesHostOsCell,
  },
];

const devicesColumns = [
  ...commonColumns,
  getActionsColConfig(SolutionDevicesActions),
];
const iotHubCommonColumns = [
  { id: SolutionDeviceItemField.DeviceId, title: 'Device ID' },
  { id: SolutionDeviceItemField.CompanyName, title: 'Company' },
  { id: SolutionDeviceItemField.ModelId, title: 'Template' },
  {
    id: SolutionDeviceItemField.Enabled,
    title: 'Enabled',
    Component: DataTableBoolCell,
  },
  {
    id: SolutionDeviceItemField.HostOsVersion,
    title: 'Host OS',
    Component: SolutionDevicesHostOsCell,
  },
  {
    id: SolutionDeviceItemField.ConnectionState,
    title: 'Connection State',
    Component: SolutionConnectionStatus,
  },
  {
    id: SolutionDeviceItemField.IotEdge,
    title: 'Edge Device',
    Component: DataTableBoolCell,
  },
];

const iotHubdevicesColumns = [
  ...iotHubCommonColumns,
  getActionsColConfig(SolutionDevicesActions),
];

const recentDevicesColumns = [
  ...commonColumns,
  {
    id: SolutionDeviceItemField.LastAccessed,
    title: 'Last accessed',
    Component: DateFormattedValue,
  },
  getActionsColConfig(SolutionDevicesActions),
];
const recentIOTHDevicesColumns = [
  ...iotHubCommonColumns,
  {
    id: SolutionDeviceItemField.LastAccessed,
    title: 'Last accessed',
    Component: DateFormattedValue,
  },
  getActionsColConfig(SolutionDevicesActions),
];
const menderColumns = [
  ...commonColumns,
  {
    id: SolutionDeviceItemField.MenderStatus,
    title: 'Mender Status',
    Component: SolutionMenderStatus,
  },
  getActionsColConfig(SolutionDevicesActions),
];
const iotHubMenderColumns = [
  ...iotHubCommonColumns,
  {
    id: SolutionDeviceItemField.MenderStatus,
    title: 'Mender Status',
    Component: SolutionMenderStatus,
  },
  getActionsColConfig(SolutionDevicesActions),
];

function SolutionDevicesList() {
  const { solutionId } = useParams();
  const actions = useStoreUnitActions(solutionsStore);
  const [data, loading, skipToken, sequence] = useSolutionsDevicesList();
  const [data1] = useSolutionDetails();
  const [dataPersist, persistUpdating] = useSolutionDevicesPersist();
  const { devices, devicesPersist } = useStoreUnitActions(solutionsStore);
  const [showRecent, setShowRecent] = useState<boolean>(false);
  const [filterString, setFilterStrings] = useState('');
  const [selectedItems, setSelectedItems] = useState<SolutionDeviceItem[]>([]);
  const [filteredDataPersist, setFilteredDataPersist] = useState<
    SolutionDeviceItem[]
  >([]);
  const solutionType = loadDataFromStorage('solutionType');

  useEffect(() => {
    actions.details(solutionId);
  }, [solutionId]);

  const showMender = data1?.features?.menderSetup?.menderEnabled ?? false;

  const onScrollToBottom = () =>
    !showRecent &&
    !loading &&
    skipToken &&
    devices.update({ solutionId, filterString, sequence, skipToken });

  useEffect(() => {
    devices.init({ solutionId, filterString });
    devicesPersist.update({ solutionId });
    return () => devices.clear();
  }, [solutionId]);

  const [showFilters, setShowFilter] = useState(false);

  const [RevokeDialog, showRevokeDialog] = useModal(RevokeCertificationModal, {
    selectedItems,
    filterString,
  });

  useEffect(() => {
    if (filterString && showRecent) {
      const filteredValues: SolutionDeviceItem[] = [];
      data.forEach((allData) => {
        const filterValue = dataPersist.filter(
          (device) => device.id === allData.id
        );
        if (filterValue.length > 0) filteredValues.push(filterValue[0]);
      });
      setFilteredDataPersist(filteredValues);
    }
  }, [data]);

  useEffect(() => {
    if (!showRecent) {
      setFilterStrings('');
      devices.init({ solutionId });
    }
  }, [showRecent]);

  const canRevoke = !selectedItems.some((items) => items.enabled !== true);

  const [DeleteDevicesDialog, showDeleteDialog] = useModal(
    SolutionDeviceDeleteModel,
    { selectedItems }
  );

  const [AuthoriseDevicesDialog, showAuthoriseDialog] = useModal(
    SolutionDevicesAuthoriseModal,
    { selectedItems }
  );
  const [AssignCompanyDialog, showAssignCompanyDialog] = useModal(
    SolutionDevicesAssignCompanyModal,
    { selectedItems }
  );

  const handleClick = (event) => {
    event.preventDefault();
    setShowFilter((prevs) => !prevs);
  };
  function toggleshowfilter() {
    setShowFilter((prevs) => !prevs);
  }
  function scrollerUpdateWithFilter(filterstrings) {
    setFilterStrings(filterstrings);
  }

  function onSelectItems(selectedItems) {
    const items = Object.keys(selectedItems).filter((k) => selectedItems[k]);
    const selectedDeviceItems: SolutionDeviceItem[] = [];

    items.forEach((item) => {
      const selectedItem = data.find((dataItem) => dataItem.id === item);
      if (selectedItem) {
        selectedDeviceItems.push(selectedItem);
      }
    });
    setSelectedItems(selectedDeviceItems);
  }

  const isShowAuthorisationRequest = selectedItems?.every(
    (selectItem) => selectItem?.menderStatus === MenderStatus.Pending
  );
  const updatedData = data.map((item) => ({
    ...item,
    companyName: item.companyName ? item.companyName : '–',
    modelId: item.modelId ? item.modelId : '–',
  }));

  return (
    <FilterTableContext.Provider
      value={{
        showFilters,
        toggleshowfilter,
        scrollerUpdateWithFilter,
        solutionType,
      }}
    >
      <DataTableWrapper
        title="Devices"
        searchBar={false}
        actionsPanel={
          <Box>
            <Switch
              onChange={({ target }) => setShowRecent(target.checked)}
              value={showRecent}
              label="Show only recently accessed devices"
              sx={{ paddingTop: '7px' }}
            />
            <Button
              onClick={handleClick}
              sx={{
                padding: '8px',
                marginRight: '7px',
                marginLeft: '-8px',
              }}
            >
              <FilterListIcon />
            </Button>
            <DataTableSearchBar style={{ paddingRight: '10px' }} />
            {selectedItems.length >= 1 && (
              <MoreActionsMenu
                onRevokeCertificateClick={showRevokeDialog}
                onDeleteClick={showDeleteDialog}
                onAuthoriseClick={showAuthoriseDialog}
                onAssociateCompanyClick={showAssignCompanyDialog}
                canRevoke={canRevoke}
                canAuthorise={isShowAuthorisationRequest}
              />
            )}
          </Box>
        }
      >
        {showRecent ? (
          <DataTable
            columns={
              solutionType.toUpperCase() === SolutionType.ioTH.toUpperCase()
                ? recentIOTHDevicesColumns
                : recentDevicesColumns
            }
            data={filterString ? filteredDataPersist : dataPersist}
            loading={filterString ? loading : persistUpdating}
            paging={false}
            checkboxes={true}
            onSelectItems={onSelectItems}
          />
        ) : (
          <SolutionDevicesListObserver
            deps={[solutionId, loading, sequence, skipToken]}
            onScrollToBottom={onScrollToBottom}
          >
            {solutionType.toUpperCase() === SolutionType.ioTH.toUpperCase() ? (
              <DataTable
                columns={
                  showMender ? iotHubMenderColumns : iotHubdevicesColumns
                }
                data={updatedData}
                loading={loading}
                paging={false}
                checkboxes={true}
                onSelectItems={onSelectItems}
              />
            ) : (
              <DataTable
                columns={showMender ? menderColumns : devicesColumns}
                data={updatedData}
                loading={loading}
                paging={false}
                checkboxes={true}
                onSelectItems={onSelectItems}
              />
            )}
          </SolutionDevicesListObserver>
        )}
        {selectedItems.length > 0 && RevokeDialog}
        {selectedItems.length > 0 && DeleteDevicesDialog}
        {selectedItems.length > 0 && AuthoriseDevicesDialog}
        {selectedItems.length > 0 &&
          solutionType.toUpperCase() === SolutionType.ioTH.toUpperCase() &&
          AssignCompanyDialog}
      </DataTableWrapper>
    </FilterTableContext.Provider>
  );
}

export default SolutionDevicesList;
