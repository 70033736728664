import React, { forwardRef, Ref } from 'react';
import Slide, { SlideProps } from '@mui/material/Slide';
import { baseTheme } from '../baseTheme';

const TransitionComponent = forwardRef(
  (props: SlideProps, ref: Ref<HTMLDivElement>) => (
    <Slide direction="left" ref={ref} {...props} />
  )
);

const { palette, spacing, typography } = baseTheme;
const dialogSpacing = spacing(2);

export default {
  MuiDialog: {
    defaultProps: {
      TransitionComponent,
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderColor: palette.grayscale[400],
        borderBottomWidth: spacing(0.2),
        borderBottomStyle: 'solid',
        padding: spacing(1.6, 2),
        ...typography.h4,
        '&.MuiDialogTitle-root+.MuiDialogContent-root': {
          paddingTop: dialogSpacing,
        },
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: dialogSpacing,
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: dialogSpacing,
        '&>:not(:first-of-type)': {
          marginLeft: dialogSpacing,
        },
      },
    },
  },
};
